import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Mutation,
  MutationDeleteOneKitchenAddressArgs,
  Query,
  QueryKitchenToAddressNodeArgs,
} from 'api'
import { CloseIcon } from 'components/Icons'
import { Button, NewLayout } from 'components/newUi'
import { Form } from 'screens/Settings/Addresses/Form'
import { useTheme } from 'styles/newUi'
import { cleanErrorMessage, encodeCursor } from 'utils'

import {
  getKitchentoAddressNodeQuery,
  deleteOneKitchenAddress,
} from './graphql'
import { useStyles } from './styles'

import { errorToast } from '../../../components/toasts'

export const EditAddress = () => {
  const { id } = useParams()
  const { theme } = useTheme()
  const [sendForm, setSendForm] = useState<boolean>(false)
  const [formDirty, setFormDirty] = useState<boolean>(false)
  const classes = useStyles()
  const navigate = useNavigate()

  const { data: kitchenToAddressData } = useQuery<
    {
      kitchenToAddressNode: Query['kitchenToAddressNode']
    },
    QueryKitchenToAddressNodeArgs
  >(getKitchentoAddressNodeQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      cursor: encodeCursor('Kitchen', +id),
    },
  })

  const [deleteOneAddress] = useMutation<
    { deleteOneKitchenAddress: Mutation['deleteOneKitchenAddress'] },
    MutationDeleteOneKitchenAddressArgs
  >(deleteOneKitchenAddress)

  const handleRemovingAddress = async () => {
    await deleteOneAddress({
      variables: {
        data: {
          id: +id,
        },
      },
    })
      .then(() => {
        errorToast(`Your address was removed!`)
        navigate(-1)
      })
      .catch((error) => errorToast(cleanErrorMessage(error.message)))
  }

  return (
    <>
      <NewLayout
        title="Address"
        subtitle="Settings"
        bottomBackgroundColor="white"
        rightContent={
          <Button
            className={classes.button}
            noFill={true}
            color="error"
            style={{ padding: `0 ${theme.spacing(1)}px` }}
            text="Remove Address"
            onClick={() => {
              handleRemovingAddress()
            }}
            rightAdornment={
              <CloseIcon style={{ marginLeft: 4, marginRight: -4 }} />
            }
          />
        }
        bottomContent={
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
          <div>
            {formDirty ? (
              <div
                style={{
                  alignItems: 'center',
                  borderTop: `1px solid ${theme.palette.primary[10].toHexString()}`,
                  cursor: 'pointer',
                  display: 'grid',
                  padding: theme.spacing(2),
                  width: '100%',
                }}
              >
                <Button
                  text="Update Address"
                  onClick={() => setSendForm(true)}
                ></Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        }
      />
      {kitchenToAddressData?.kitchenToAddressNode ? (
        <Form
          kitchenToAddressNode={kitchenToAddressData.kitchenToAddressNode}
          sendForm={sendForm}
          formDirty={(dirty) => setFormDirty(dirty)}
          create={false}
        ></Form>
      ) : (
        <></>
      )}
    </>
  )
}
