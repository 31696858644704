import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import {
  ErrorBoundary as SentryErrorBoundary,
  withProfiler as withSentryProfiler,
} from '@sentry/react'
import { normalize, setupPage } from 'csstips'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import { client } from 'api'
import { IS_DEBUG_MIXPANEL, IS_DEVELOPMENT, VITE_MIXPANEL_TOKEN } from 'app'
import { ChatProvider } from 'app/contexts/Chat'
import { IsDesktopProvider } from 'app/contexts/IsDesktop'
import { ReturnToPageProvider } from 'app/contexts/ReturnToPage'
import { ErrorBoundary, Loader, Trackers, WebSockets } from 'components'
import { GAAnalytics, Chat } from 'components/newUi'
import { cleanState as cleanMarketingState } from 'components/newUi/Marketing/store'
import { NewFormStoreProvider } from 'mobx/StateStore/Meta'
import { Routes } from 'routes'
import { cleanState as cleanOrderState } from 'screens/Order/store'
import { store } from 'store'
import { theme } from 'styles'
import { ThemeProvider as NewUIThemeProvider } from 'styles/newUi'

import { SelectedKitchenProvider } from './app/contexts/SelectedKitchen'
import { Compose } from './components/Compose'

import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'

normalize()
setupPage('#root')

const persistor = persistStore(store)

const InnerContainer = () => {
  useEffect(() => {
    store.dispatch(cleanOrderState())
    store.dispatch(cleanMarketingState())

    if (VITE_MIXPANEL_TOKEN) {
      mixpanel.init(VITE_MIXPANEL_TOKEN, {
        ...(IS_DEVELOPMENT || IS_DEBUG_MIXPANEL ? { debug: true } : {}),
      })

      mixpanel.track('Visit App')
    }
  }, [])

  return (
    <>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <SentryErrorBoundary fallback={<ErrorBoundary />} showDialog>
            <PersistGate loading={<Loader />} persistor={persistor}>
              <ThemeProvider theme={theme}>
                <NewUIThemeProvider>
                  <CssBaseline />
                  <BrowserRouter>
                    <Trackers />

                    <Compose
                      components={[
                        SelectedKitchenProvider,
                        ReturnToPageProvider,
                        NewFormStoreProvider,
                        ChatProvider,
                        IsDesktopProvider,
                      ]}
                    >
                      <WebSockets />
                      <Routes />
                      <Chat />
                    </Compose>
                  </BrowserRouter>

                  <ToastContainer autoClose={4000} />
                </NewUIThemeProvider>
              </ThemeProvider>
            </PersistGate>
          </SentryErrorBoundary>
        </ApolloProvider>
      </Provider>
      <GAAnalytics />
    </>
  )
}

export const Container = withSentryProfiler(InnerContainer)
