export function LogoEposNow() {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3681_8966"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.00598145H24V24.006H0V0.00598145Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3681_8966)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3314 22.2396C11.359 22.2396 8.13736 19.1121 8.13736 15.2549C8.13736 11.3983 11.359 8.27077 15.3314 8.27077C17.5914 8.27077 19.6072 9.2825 20.9261 10.8652L12.4802 18.5456C14.5641 20.4489 19.6128 19.9637 20.0152 14.1418L22.4163 14.037C22.4879 14.4322 22.5259 14.8398 22.5259 15.2549C22.5259 19.1121 19.3042 22.2396 15.3314 22.2396ZM12 0.00598145C5.37258 0.00598145 0 5.3785 0 12.006C0 18.6335 5.37258 24.006 12 24.006C18.6274 24.006 24 18.6335 24 12.006C24 5.3785 18.6274 0.00598145 12 0.00598145Z"
          fill="#041123"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0948 10.8081C12.726 10.9142 10.8049 12.7423 10.8049 14.8898C10.8049 15.4335 10.9283 15.945 11.1506 16.405L16.8076 11.0535C16.2829 10.8695 15.7039 10.7806 15.0948 10.8081Z"
        fill="#041123"
      />
    </svg>
  )
}
