import { useQuery, useMutation } from '@apollo/client'
import ReactPixel from 'react-facebook-pixel'

import {
  Query,
  QueryKitchenArgs,
  Mutation,
  MutationUpdateOneKitchenArgs,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'

import { getKitchenByIdQuery, updateOneKitchenMutation } from './graphql'
export const useTrackKitchenActivated = () => {
  const { selectedKitchen } = useKitchen()
  const [updateKitchen] = useMutation<
    { updateOneKitchen: Mutation['updateOneKitchen'] },
    MutationUpdateOneKitchenArgs
  >(updateOneKitchenMutation)

  const { data, stopPolling } = useQuery<
    {
      kitchen: Query['kitchen']
    },
    QueryKitchenArgs
  >(getKitchenByIdQuery, {
    //5 minutes
    fetchPolicy: 'network-only',

    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (import.meta.env.VITE_REACT_APP_SENTRY_ENV !== 'production') {
        stopPolling()
        return
      }
      if (
        !import.meta.env.VITE_REACT_APP_ACTIVATION_FB_PIXEL_ID ||
        data?.kitchen?.activatedPixelFiredTime
      ) {
        stopPolling()
        return
      }
      if (
        !data?.kitchen?.activatedPixelFiredTime &&
        data?.kitchen?.activatedTime
      ) {
        ReactPixel.init(import.meta.env.VITE_REACT_APP_ACTIVATION_FB_PIXEL_ID)
        ReactPixel.track('checkout')

        updateKitchen({
          variables: {
            data: {
              activatedPixelFiredTime: new Date(),
              id: selectedKitchen!.id,
            },
          },
        })
      }
    },
    pollInterval: 5 * 60 * 1000,
    skip:
      selectedKitchen === undefined ||
      !import.meta.env.VITE_REACT_APP_ACTIVATION_FB_PIXEL_ID,
    //put in check for prod
    variables: { where: { id: selectedKitchen?.id ?? -1 } },
  })
}
