import { useQuery } from '@apollo/client'
import { Button } from '@getjelly/jelly-ui'
import { IconDotsVertical } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

import { Recipe, Dish } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader, Typography } from 'components'
import { NewLayout } from 'components/newUi'
import { useNewStateStore } from 'mobx/StateStore/Meta'
import { dishSelector, recipeSelector } from 'screens/Create'
import { getRecipeByIdQuery } from 'screens/Create/graphql/recipes'
import { Print } from 'screens/View/Print'
import { useStyles } from 'screens/View/styles'
import { theme } from 'styles/newUi'

import { ActionsModal } from './ActionsModal'
import { Details } from './Details'

const pages = ['ingredients', 'method', 'allergens', 'costing']

export const ViewComponent = () => {
  const { id, type } = useParams()
  const state = useNewStateStore()
  const location = useLocation()
  const navigate = useNavigate()
  const store = state.createOrGet(location.pathname)
  const { selectedKitchen } = useKitchen()
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState<number>(type === 'dish' ? 0 : 1)
  const [showActionModal, setShowActionModal] = useState(false)
  const [actionOptions, setActionOptions] = useState<
    undefined | { print?: boolean }
  >()

  const { data, loading } = useQuery<{
    recipes?: Recipe[]
    dishes?: Dish[]
  }>(getRecipeByIdQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: { equals: +id! },
        kitchenId: { equals: selectedKitchen?.id },
      },
      whereDish: {
        id: { equals: +id! },
        kitchenId: { equals: selectedKitchen?.id },
      },
    },
  })

  useEffect(() => {
    if (store && data) {
      const recipe = recipeSelector(data, type!)
      store.init(recipe?.childRecipes ?? [], recipe?.ingredients ?? [])
    }
  }, [id, type, data, store])

  const [currentPage, setPageNumber] = useState(-1)

  const setCurrentPage = (page: number) => {
    if (page === -1) {
      navigate(-1)
      return
    }

    navigate(pages[page])
  }

  useEffect(() => {
    if (!location?.pathname) return

    const index = pages.findIndex((page) => location.pathname.includes(page))

    setPageNumber(index)
  }, [location])

  useEffect(() => {
    if (actionOptions?.print) {
      window.print()
    }

    setActionOptions(undefined)
  }, [actionOptions])

  if (!data || loading) return <Loader />

  const recipe: Recipe | undefined = recipeSelector(data, type!)

  const isDish = type === 'dish'

  const dish = dishSelector(type!, data.dishes)

  if (!recipe) {
    return null
  }

  return (
    <>
      <ActionsModal
        handleCancel={(options) => {
          setShowActionModal(false)
          setActionOptions(options)
        }}
        show={showActionModal}
        recipe={recipe}
        dish={dish}
      />

      <div className={classes.noPrint}>
        <NewLayout
          title={(isDish ? 'Dish Details' : 'Recipe Details') || `\u00a0`}
          hideMenu={currentPage !== -1}
          bottomContent={
            <div>
              {dish && currentTab === 0 ? (
                <div className="flex justify-between items-center p-3 text-white bg-secondary-400">
                  <div className="font-medium text-md font-rubik">
                    Menu price
                  </div>

                  <div className="text-md">£ {dish.price?.toFixed(2)}</div>
                </div>
              ) : (
                <></>
              )}

              {!dish && currentTab === 1 ? (
                <div
                  style={{
                    backgroundColor: theme.palette.secondary[100].toHexString(),
                    color: theme.palette.common.light.toHexString(),
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '15px',
                    width: '100%',
                  }}
                >
                  <div>
                    <Typography variant="caption">Recipe Makes</Typography>
                    <Typography data-testid="recipe-makes" variant="subtitle1">
                      {recipe?.amount} {recipe?.unit?.name}
                    </Typography>
                  </div>
                  <div
                    style={{
                      alignItems: 'flex-end',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="caption">Cost</Typography>
                    <Typography variant="subtitle1" data-testid="recipe-cost">
                      £{recipe?.unitCost?.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              ) : null}
            </div>
          }
          rightContent={
            selectedKitchen?.userPermissions?.includes(
              isDish ? 'update-dish' : 'update-recipe',
            ) ? (
              <div className="p-2">
                <Button
                  onClick={() => setShowActionModal(true)}
                  label="Actions"
                  icon={IconDotsVertical}
                />
              </div>
            ) : undefined
          }
        />

        {currentPage === -1 && (
          <Details
            id={id!}
            setCurrentPage={setCurrentPage}
            recipe={recipe}
            dish={isDish ? dish : undefined}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        )}
      </div>

      <Print data={data} />
    </>
  )
}
