import { useQuery, useMutation } from '@apollo/client'
import { Typography as JTypography } from '@getjelly/jelly-ui'
import { Grid, Typography } from '@mui/material'
import { includes, isEmpty, trim } from 'ramda'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AutoSizer, List } from 'react-virtualized'

import {
  Query,
  QuerySuppliersArgs,
  SortOrder,
  Status,
  Mutation,
  MutationUpdateOneKitchenArgs,
  MutationCreateOneSupplierArgs,
  Supplier,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { CloseIcon, NewTextField, SearchIcon, Loader } from 'components'
import { NewLayout, Icon, NewButton } from 'components/newUi'
import { LeaveConfirmationModal } from 'components/newUi/ConfirmationModal/LeaveConfirmationModal'
import { SectionTitle } from 'components/newUi/VirtualizedSectionList/SectionTitle'
import { useDebounce } from 'hooks'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord, cleanErrorMessage, logEvent } from 'utils'

import { Item } from './item'

import { errorToast } from '../../../components/toasts'
import {
  createOneSupplierMutation,
  getSuppliersQuery,
  updateOneKitchenMutation,
} from '../graphql'

export const OrderSupplierAdd = () => {
  const { theme } = useTheme()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()
  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const [addedSuppliers, setAddedSuppliers] = useState<Supplier[]>([])
  const [data, setData] = useState<Supplier[]>([])

  const [updateKitchen, { loading: updateKitchenLoading }] = useMutation<
    { updateOneKitchen: Mutation['updateOneKitchen'] },
    MutationUpdateOneKitchenArgs
  >(updateOneKitchenMutation)

  const [createSupplier] = useMutation<
    { createOneSupplier: Mutation['createOneSupplier'] },
    MutationCreateOneSupplierArgs
  >(createOneSupplierMutation)

  const {
    data: supplierData,
    loading,
    fetchMore,
  } = useQuery<
    {
      suppliers: Query['suppliers']
      suppliersCount: Query['suppliersCount']
    },
    QuerySuppliersArgs
  >(getSuppliersQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: !selectedKitchen,
    variables: {
      fuzzySearch: debouncedSearch,
      orderBy: debouncedSearch ? undefined : [{ name: SortOrder.Asc }],
      skip: 0,
      take: 48,
      where: {
        kitchens: {
          every: {
            kitchen: {
              id: {
                not: {
                  equals: selectedKitchen?.id ?? null,
                },
              },
            },
          },
        },
        products: {
          some: {
            ingredients: {
              every: {
                kitchenId: {
                  not: {
                    equals: selectedKitchen?.id ?? null,
                  },
                },
              },
            },
          },
        },
        status: {
          equals: Status.Active,
        },
      },
    },
  })

  useEffect(() => {
    setData(
      [...addedSuppliers, ...(supplierData?.suppliers ?? [])]
        .filter(
          (supplier) =>
            supplier.name.toLowerCase().indexOf(search.toLowerCase()) > -1,
        )
        .sort((a, b) =>
          trim(a.name.toLowerCase()) > trim(b.name.toLowerCase()) ? 1 : -1,
        ),
    )
  }, [supplierData, addedSuppliers, search])

  const setSelectedSupplier = (supplier: Supplier) => {
    if (!suppliers.includes(supplier)) {
      setSuppliers([...suppliers, supplier])
    } else {
      setSuppliers([...suppliers.filter((sup) => sup.id !== supplier.id)])
    }
  }

  const handleCreateSupplier = async () => {
    await createSupplier({
      variables: {
        data: {
          name: trim(capitaliseEachWord(debouncedSearch)),
        },
      },
    }).then(({ data, errors }) => {
      if (errors || !data?.createOneSupplier) return

      setAddedSuppliers([...addedSuppliers, data.createOneSupplier])
      setSelectedSupplier(data.createOneSupplier)
      setSearch('')
    })
  }

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <LeaveConfirmationModal
        action="leave"
        title="You have unsaved changes, are you sure you want to leave the screen?"
        text={`${suppliers.length} Supplier${
          suppliers.length > 1 ? 's' : ''
        } won't be added`}
        onConfirm={() => {
          setSuppliers([])
          navigate(-1)
        }}
        show={suppliers.length > 0 && !updateKitchenLoading}
      />
      <NewLayout
        hideMenu
        title="Add Your Suppliers"
        subtitle="Ordering"
        bottomContent={
          <div
            style={{
              backgroundColor: 'white',
              borderTopColor: theme.palette.primary[10].toHexString(),
              borderTopStyle: 'solid',
              borderTopWidth: 1,
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: 16,
              paddingTop: 17,
              width: '100%',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <NewButton
                text="Add Suppliers"
                style={{
                  maxWidth: 200,
                  padding: '8px 12px 8px 14px',
                  width: '50%',
                }}
                onClick={() =>
                  suppliers.length &&
                  updateKitchen({
                    variables: {
                      data: { id: selectedKitchen!.id },
                      suppliers: suppliers.map((supplier) => supplier.id),
                    },
                  })
                    .then(({ data }) => {
                      logEvent('tap_button', {
                        event_category: 'add_suppliers',
                        event_label: 'added_suppliers',
                        value: suppliers.length.toString(),
                      })

                      if (data) {
                        setSuppliers([])
                        // @ts-ignore -1 is allowed
                        navigate(-1, {
                          state: {
                            suppliersUpdated: true,
                          },
                        })
                      }
                    })
                    .catch((error) =>
                      errorToast(cleanErrorMessage(error.message)),
                    )
                }
              />
            </div>
          </div>
        }
      />
      <NewTextField
        style={{
          backgroundColor: theme.palette.primary[10].toHexString(),
          flex: 1,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }}
        inputProps={{
          'data-hj-allow': '',
          style: { borderRadius: 25, flex: 1 },
        }}
        placeholder="Search By Name"
        value={search}
        onChange={(value) => setSearch(value as unknown as string)}
        endAdornment={
          isEmpty(search) ? (
            <SearchIcon />
          ) : (
            <CloseIcon onClick={() => setSearch('')} />
          )
        }
      />

      <SectionTitle
        HEADER_HEIGHT={33}
        label="Suppliers on Jelly"
        rightAdornment={
          <JTypography style="subtitle2" className="text-primary-800">
            {supplierData?.suppliersCount}
          </JTypography>
        }
      />

      <div style={{ height: 'calc(100% - 100px)' }}>
        <div style={{ height: '100%', width: '100%' }}>
          {loading && !supplierData ? (
            <Loader />
          ) : (
            <AutoSizer>
              {({ height, width }) => {
                const itemsPerRow = 1
                const rowCount = data.length ?? 0

                return (
                  <List
                    containerStyle={{ overflow: 'hidden' }}
                    width={width}
                    height={height}
                    rowCount={rowCount}
                    rowHeight={65}
                    overscanRowCount={10}
                    noRowsRenderer={() =>
                      !isEmpty(debouncedSearch) &&
                      !isEmpty(search) &&
                      !loading ? (
                        <Grid
                          container
                          item
                          onClick={handleCreateSupplier}
                          xs={12}
                          style={{
                            alignItems: 'center',
                            backgroundColor:
                              theme.palette.primary[5].toHexString(),
                            borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
                            bottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 100,
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              alignItems: 'center',
                              cursor: 'pointer',
                              display: 'flex',
                              fontWeight: 600,
                              height: 40,
                            }}
                          >
                            Sorry! We don&apos;t have that supplier on our
                            system yet. Please add them:
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{
                              alignItems: 'center',
                              color: theme.palette.secondary[100].toHexString(),
                              cursor: 'pointer',
                              display: 'flex',
                              fontWeight: 600,
                              height: 40,
                            }}
                          >
                            Create &quot;
                            {capitaliseEachWord(debouncedSearch.toLowerCase())}
                            &quot;
                          </Typography>
                        </Grid>
                      ) : (
                        <div />
                      )
                    }
                    onRowsRendered={({ overscanStopIndex }) => {
                      if (overscanStopIndex === rowCount - 1 && !loading) {
                        fetchMore({
                          variables: {
                            skip: rowCount,
                            take: 48,
                          },
                        })
                        return
                      }
                    }}
                    rowRenderer={({ index, key, style }) => {
                      const items = []
                      const convertedIndex = index * itemsPerRow
                      for (
                        let i = convertedIndex;
                        i < convertedIndex + itemsPerRow;
                        i++
                      ) {
                        const item = data[i]
                        if (item) {
                          items.push(
                            <Item
                              key={index}
                              data={item}
                              search={search}
                              onClick={() => setSelectedSupplier(item)}
                              rightAdornment={
                                includes(item, suppliers) ? (
                                  <Icon
                                    iconName="tickCircle"
                                    style={{
                                      color:
                                        theme.palette.success[100].toHexString(),
                                      height: 25,
                                    }}
                                  />
                                ) : (
                                  <Icon
                                    iconName="addCircle"
                                    style={{
                                      color:
                                        theme.palette.secondary[100].toHexString(),
                                      height: 25,
                                    }}
                                  />
                                )
                              }
                            />,
                          )
                        }
                      }

                      return (
                        <div
                          style={{
                            ...style,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          key={key}
                        >
                          {items}
                        </div>
                      )
                    }}
                  />
                )
              }}
            </AutoSizer>
          )}
        </div>
      </div>
    </div>
  )
}
