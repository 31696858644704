import dayjs from 'dayjs'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useDeleteOneStockMutation } from 'api/types'
import { Typography, Icon, TextButton } from 'components/newUi'
import { routes } from 'routes/Paths'
import { NewModal } from 'screens/Create/NewModal'
import { clearStocktake, StockTakeStoreStockTake } from 'store/stocktake'
import { useTheme } from 'styles/newUi'

import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { useStyles } from '../styles'
const ROW_HEIGHT = 60

export type StockTakeItemStockList = {
  items: StockTakeStoreStockTake[]
  label: string
}

type ItemProps = {
  index: number
  style: React.CSSProperties
  data: StockTakeItemStockList
}

export const Item = (props: ItemProps) => {
  const { index, style, data } = props
  const rowData = data.items[index]
  const { theme } = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { selectedKitchen } = useKitchen()
  const [show, setShow] = useState(false)

  const [deleteStock] = useDeleteOneStockMutation({
    refetchQueries: ['stockList'],
    variables: {
      data: {
        id: rowData.id,
      },
    },
  })

  const handleClick = () => {
    if (rowData.isDraft || rowData.unsavedChanges) {
      dispatch(clearStocktake(rowData._cursor))
    } else {
      void deleteStock()
    }
  }

  const formattedDate = `${dayjs(rowData?.date)
    .toDate()
    .toLocaleDateString('en-GB', { weekday: 'long' })} ${dayjs(
    rowData?.date,
  ).format('DD MMM')}`

  const name = rowData?.name ? rowData.name : rowData?.date ? formattedDate : ''
  return (
    <>
      <div
        key={`${rowData.id}-${index}`}
        data-testid={`stocktake-row-${name}`}
        style={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
          display: 'flex',
          height: ROW_HEIGHT,
          paddingLeft: theme.spacing(2),
          width: '100%',
          ...style,
        }}
      >
        {selectedKitchen?.userPermissions?.includes('delete-stock') && (
          <Icon
            iconName="close"
            onClick={() => setShow(true)}
            className="text-error-400 cursor-pointer mr-2"
          />
        )}

        <div
          onClick={() => navigate(`${routes.Stock}/${rowData._cursor}`)}
          style={{
            alignItems: 'center',
            cursor: 'pointer',
            display: 'grid',
            gap: '0 5px',
            gridTemplateColumns:
              rowData.isDraft || rowData.unsavedChanges
                ? '1fr 0.5fr 0fr 40px'
                : '1fr 0fr 0fr 40px',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 500,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
                whiteSpace: 'nowrap',
              }}
            >
              {name}
            </Typography>
            {rowData?.name && (
              <Typography
                variant="subtitle2"
                style={{
                  color: theme.palette.primary[40].toHexString(),
                  fontSize: 12,
                  fontWeight: 700,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textTransform: 'capitalize',
                  whiteSpace: 'nowrap',
                }}
              >
                {formattedDate}
              </Typography>
            )}
          </div>
          <div
            style={{
              overflow: 'hidden',
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                color: theme.palette.primary[40].toHexString(),
                fontWeight: 500,
              }}
            >
              {(() => {
                if (rowData.isDraft) return 'DRAFT'
                if (rowData.unsavedChanges) return 'UNSAVED'
                return ''
              })()}
            </Typography>
          </div>
          <div
            style={{
              alignItems: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              £{rowData.total?.toFixed(2)}
            </Typography>
          </div>

          <div
            className={classes.itemInnerMobile}
            style={{
              alignItems: 'center',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              maxWidth: 50,
            }}
          >
            <Icon iconName="chevronRight" />
          </div>
        </div>
      </div>
      <NewModal
        title={`${
          rowData.isDraft || rowData.unsavedChanges ? 'Discard' : 'Delete'
        } ${name}`}
        handleCancel={() => setShow(false)}
        show={show}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
          }}
        >
          <TextButton
            onClick={() => setShow(false)}
            style={{ color: theme.palette.secondary[100].toHexString() }}
          >
            Cancel
          </TextButton>
          <TextButton
            onClick={handleClick}
            style={{ color: theme.palette.error[100].toHexString() }}
          >
            {(() => {
              if (rowData.isDraft) return 'Discard draft'
              if (rowData.unsavedChanges) return 'Discard changes'
              return 'Delete stocktake'
            })()}
          </TextButton>
        </div>
      </NewModal>
    </>
  )
}
