import { mapObjIndexed } from 'ramda'
import { style } from 'typestyle'
import { NestedCSSProperties } from 'typestyle/lib/types'

import { useIsDesktopPrivate } from 'app/contexts/IsDesktop'
import { useTheme, Theme } from 'styles/newUi/ThemeProvider'

type ClassNameMap<ClassKey extends string = string> = Record<
  ClassKey,
  NestedCSSProperties | string
>

export const makeStyles = <ClassKey extends string = string>(
  styleFunction: (theme: Theme, isDesktop: boolean) => ClassNameMap<ClassKey>,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { theme } = useTheme()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isDesktop = useIsDesktopPrivate()
  const styles = styleFunction(theme, isDesktop)
  return mapObjIndexed((value) => {
    if (typeof value === 'string') return value

    return style(value)
  }, styles)
}
