import { StyledLoaderWrapper, StyledCircularProgress } from './styled'

type LoaderWrapperProps = {
  loaderSize?: number
  children?: React.ReactNode
  customLoader?: React.ReactNode
  isLoading: boolean
}

export const LoaderWrapper = ({
  loaderSize = 60,
  customLoader,
  children,
  isLoading,
}: LoaderWrapperProps) => {
  if (isLoading) {
    return (
      <StyledLoaderWrapper>
        {customLoader ? (
          customLoader
        ) : (
          <StyledCircularProgress size={loaderSize} />
        )}
      </StyledLoaderWrapper>
    )
  }
  return <>{children}</>
}
