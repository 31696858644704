import { useState, useEffect, Dispatch, SetStateAction } from 'react'

type UseQueryParamsStateReturnType = [string, Dispatch<SetStateAction<string>>]

export const useQueryParamsState = (
  param: string,
  initialState: string,
): UseQueryParamsStateReturnType => {
  // State for managing the value derived from the query parameter
  const [value, setValue] = useState(() => {
    if (typeof window === 'undefined') return initialState

    // Parse query parameter value from the URL
    const searchParams = new URLSearchParams(window.location.search)
    const paramValue = searchParams.get(param)

    return paramValue ?? initialState
  })

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search)

    // Update the query parameter with the current state value
    if (value !== '') {
      currentSearchParams.set(param, value)
    } else {
      // Remove the query parameter if the value is null or empty
      currentSearchParams.delete(param)
    }

    // Update the URL with the modified search parameters
    const newUrl = [window.location.pathname, currentSearchParams.toString()]
      .filter(Boolean)
      .join('?')

    // Update the browser's history without triggering a page reload
    window.history.replaceState(window.history.state, '', newUrl)
  }, [param, value])

  return [value, setValue]
}
