import { gql } from '@apollo/client'

export const signupMutation = gql`
  mutation SignUp(
    $countryCode: String!
    $phoneNumber: String!
    $password: String!
  ) {
    signup(
      countryCode: $countryCode
      phoneNumber: $phoneNumber
      password: $password
    ) {
      token
    }
  }
`
