/* TODO: Change query, reduce page size and paginate on scroll */
import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { isEmpty } from 'ramda'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { Query, QuerySuppliersArgs, SortOrder, Supplier } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { CloseIcon, NewTextField, SearchIcon, Loader, Grid } from 'components'
import { NewLayout, Icon, AlertWithIcon, Button } from 'components/newUi'
import { SectionTitle } from 'components/newUi/VirtualizedSectionList/SectionTitle'
import { useDebounce } from 'hooks'
import { routes } from 'routes/Paths'
import { lightBlue } from 'styles/colors'
import { useTheme } from 'styles/newUi'

import { Item } from './item'

import { getSuppliersQuery } from '../graphql'
import { selectAllPendingOrdersForKitchen } from '../store'

export const OrderSupplier = () => {
  const { theme } = useTheme()
  const [search, setSearch] = useState('')
  const [showUpdated, setShowUpdated] = useState(false)
  const debouncedSearch = useDebounce(search, 500)
  const navigate = useNavigate()
  const { state, pathname } = useLocation()

  useEffect(() => {
    // @ts-ignore
    const updated = state?.suppliersUpdated
    if (updated) {
      setShowUpdated(true)
      navigate(pathname, {
        replace: true,
        state: {
          suppliersUpdated: false,
        },
      })
    }
  }, [])
  const { selectedKitchen } = useKitchen()
  const { data, loading } = useQuery<
    {
      suppliers: Query['suppliers']
    },
    QuerySuppliersArgs
  >(getSuppliersQuery, {
    fetchPolicy: 'no-cache',
    skip: !selectedKitchen,
    variables: {
      fuzzySearch: debouncedSearch,
      orderBy: search ? undefined : [{ name: SortOrder.Asc }],
      take: !isEmpty(debouncedSearch) ? 250 : 500,
      where: {
        kitchens: {
          some: {
            kitchen: { id: { equals: selectedKitchen?.id } },
          },
        },
      },
    },
  })

  const orders = useSelector(
    selectAllPendingOrdersForKitchen(`${selectedKitchen?.id || ''}`),
  )

  const renderList = () => {
    const activeSuppliers =
      data?.suppliers?.filter(
        (supplier: Supplier) =>
          (orders[supplier.id]?.quantity ?? 0) > 0 ||
          !isEmpty(orders[supplier.id]?.comments ?? ''),
      ) || []
    return !loading ? (
      <>
        {activeSuppliers.length ? (
          <>
            <SectionTitle
              HEADER_HEIGHT={33}
              label={`Active Baskets`}
              rightAdornment={
                <Typography
                  variant="subtitle2"
                  style={{ color: theme.palette.primary[60].toHexString() }}
                >
                  {activeSuppliers?.length}
                </Typography>
              }
            />
            {activeSuppliers.map((supplier, i) => (
              <Item
                key={i}
                data={supplier}
                search={debouncedSearch}
                rightAdornment={<Icon iconName="chevronRight" />}
                onClick={() =>
                  navigate(`${routes.Order}${routes.Supplier}/${supplier.id}`)
                }
              />
            ))}
          </>
        ) : undefined}
        <SectionTitle
          HEADER_HEIGHT={33}
          label={`Your Suppliers`}
          rightAdornment={
            <Typography
              variant="subtitle2"
              style={{ color: theme.palette.primary[60].toHexString() }}
            >
              {data?.suppliers.length}
            </Typography>
          }
        />
        {data?.suppliers
          ?.filter((supplier: Supplier) => !orders[supplier.id])
          ?.map((supplier, i) => (
            <Item
              key={i}
              data={supplier}
              search={debouncedSearch}
              rightAdornment={<Icon iconName="chevronRight" />}
              onClick={() =>
                navigate(`${routes.Order}${routes.Supplier}/${supplier.id}`)
              }
            />
          ))}
      </>
    ) : (
      <Loader />
    )
  }

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <NewLayout hideMenu title="Suppliers" subtitle="Ordering" />
      {showUpdated && (
        <AlertWithIcon hide={false} type="success" iconName="notifications">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              variant="subtitle2"
              style={{ color: theme.palette.success[100].toHexString() }}
            >
              Your suppliers were updated!
            </Typography>
          </div>
        </AlertWithIcon>
      )}
      <NewTextField
        style={{
          backgroundColor: theme.palette.primary[10].toHexString(),
          flex: 1,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }}
        inputProps={{
          'data-hj-allow': '',
          style: { borderRadius: 25, flex: 1 },
        }}
        placeholder="Search By Name"
        value={search}
        onChange={(value) => setSearch(value as unknown as string)}
        endAdornment={
          isEmpty(search) ? (
            <SearchIcon />
          ) : (
            <CloseIcon onClick={() => setSearch('')} />
          )
        }
      />
      <div
        style={{
          height: `calc(100% - ${showUpdated ? 130 : 70}px)`,
          overflow: 'scroll',
        }}
      >
        {renderList()}
        {debouncedSearch && (
          <Grid
            container
            item
            xs={12}
            style={{
              alignItems: 'center',
              backgroundColor: theme.palette.primary[5].toHexString(),
              borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              height: 100,
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="body2"
              style={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                fontWeight: 600,
                height: 40,
              }}
            >
              No more suppliers matching {`"`}
              {search}
              {`"`}
            </Typography>
            <Typography
              onClick={() => setSearch('')}
              variant="body2"
              style={{
                alignItems: 'center',
                color: lightBlue,
                cursor: 'pointer',
                display: 'flex',
                fontWeight: 600,
                height: 40,
              }}
            >
              Show All Suppliers
            </Typography>
          </Grid>
        )}
        {!loading && (
          <Grid
            container
            item
            xs={12}
            style={{
              alignItems: 'center',
              backgroundColor: theme.palette.primary[5].toHexString(),
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              height: 100,
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="body2"
              style={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                fontWeight: 600,
                height: 40,
              }}
            >
              Missing a supplier?
            </Typography>
            <Button
              color="white"
              onClick={() =>
                navigate(`${routes.Order}${routes.Supplier}${routes.Add}`)
              }
              style={{
                border: `2px solid ${theme.palette.primary[100].toHexString()}`,
                fontSize: 14,
              }}
            >
              <div>
                <Typography variant="button">Add your suppliers</Typography>
              </div>
            </Button>
          </Grid>
        )}
      </div>
    </div>
  )
}
