import { Typography } from '@getjelly/jelly-ui'
import { IconChevronRight } from '@tabler/icons-react'
import { ReactNode } from 'react'

import { Loader } from 'components'

type Props = {
  onClick: () => void
  text?: string | ReactNode
  subtext?: string | ReactNode
  loading?: boolean
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  className?: string
}

export function NavItem({
  onClick,
  text,
  subtext,
  loading,
  startAdornment,
  endAdornment,
  className = '',
}: Props) {
  return (
    <div
      onClick={onClick}
      className="bg-transparent border-0 block p-0 text-left w-full cursor-pointer"
    >
      <div
        className={`${className} pl-3 pr-2 py-4 rounded shadow flex justify-between items-center bg-white`}
      >
        {loading ? (
          <div className="flex justify-center w-full">
            <Loader />
          </div>
        ) : (
          <div className="flex items-center">
            <div className="flex space-x-2">
              {startAdornment && (
                <div className="flex items-center">{startAdornment}</div>
              )}

              <div className="flex flex-col justify-center">
                <Typography
                  style="subtitle1"
                  className="text-primary-900 line-clamp-1"
                >
                  {text}
                </Typography>

                {subtext && (
                  <Typography
                    style="subtitle2"
                    className="text-primary-900 line-clamp-1"
                  >
                    {subtext}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="shrink-0 flex items-center space-x-1">
          {endAdornment && (
            <div className="flex items-center">{endAdornment}</div>
          )}

          <IconChevronRight className="text-primary-400" />
        </div>
      </div>
    </div>
  )
}
