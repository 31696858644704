import clsx from 'clsx'
import { useRef, useEffect } from 'react'

import { Typography } from 'components/newUi'

import { useStyles } from './styles'
import { Props } from './types'

import { CircularProgress } from '../../CircularProgress'

export const Button = ({
  text,
  onClick,
  className,
  color = 'primary',
  style,
  disabled = false,
  loading,
  noFill,
  type,
  preventSubmission,
  disableLoaderStyles,
  focus,
  leftAdornment,
  rightAdornment,
  children,
  testId,
  ...rest
}: Props) => {
  const classes = useStyles()
  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (focus && buttonRef) buttonRef?.current?.focus()
  }, [focus])

  return (
    <button
      data-testid={testId}
      {...rest}
      ref={buttonRef}
      type={type}
      onClick={(event) => {
        if (preventSubmission || disabled) event.preventDefault()

        event.stopPropagation()

        onClick && !disabled && !loading && onClick()
      }}
      className={clsx([
        classes.button,
        className,
        (disabled || loading) && classes.disabled,
        color && classes[color],
        noFill && classes.noFill,
      ])}
      style={style}
    >
      {leftAdornment}
      {text && (
        <Typography variant="button" style={{ color: 'inherit' }}>
          {text}
        </Typography>
      )}
      {children}
      {loading && (
        <CircularProgress
          className={disableLoaderStyles ? classes.loaderUnset : classes.loader}
          size={27}
          color="inherit"
        />
      )}
      {rightAdornment}
    </button>
  )
}
