import { Typography } from '@getjelly/jelly-ui'
import { IconChevronRight } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom'

import { PendingInvoice } from 'api'
import { DATE_FORMATS } from 'app'
import { routes } from 'routes/Paths'
import { InvoiceRowItemContainer } from 'screens/Invoices/InvoiceRowItemContainer'

interface Props {
  invoice: PendingInvoice
  handleInvoiceSelect?: (item: PendingInvoice) => void
  style: CSSProperties
}

function PendingInvoiceRowItem({ style, invoice }: Props) {
  const navigate = useNavigate()
  return (
    <InvoiceRowItemContainer
      style={style}
      automatic={invoice.isAuto}
      primaryText={
        invoice.images.length > 0
          ? invoice.images[0].split('/').pop() ?? ''
          : ''
      }
      secondaryText={
        dayjs(invoice.createdAt).format(DATE_FORMATS.DATE_TIME) +
        ' by ' +
        invoice.createdBy.firstName
      }
      images={invoice.signedImages}
      action={
        <div className="flex items-center space-x-1">
          {invoice.status === 'rejected' && (
            <Typography style="subtitle1">Skipped</Typography>
          )}

          {invoice.status === 'merged' && (
            <Typography style="subtitle1">Merged</Typography>
          )}

          <IconChevronRight className="text-primary-400" />
        </div>
      }
      onClick={() =>
        navigate(
          `${routes.Spending}${routes.Invoice}${routes.Pending}/${invoice.id}`,
        )
      }
      pendingInvoiceId={invoice.id}
      notes={invoice.notes}
    />
  )
}

export default PendingInvoiceRowItem
