import { Box } from '@mui/material'
import styled from 'styled-components'

import { theme } from 'styles/newUi'

export const CARD_INNER_PADDING = theme.spacing(2)

export const CARD_INNER_LOGO_MARGIN = theme.spacing(2.5)

export const StyledCardOuter = styled(Box)({
  maxWidth: '100%',
  padding: CARD_INNER_PADDING,
  paddingBottom: CARD_INNER_PADDING * 2,
})

export const StyledCardDiv = styled(Box)({
  backgroundColor: theme.palette.common.light.toHexString(),
  borderRadius: theme.spacing(1),
  boxShadow: theme.elevation[2],
})

export const StyledMediumCardDiv = styled(StyledCardDiv)({
  maxWidth: '100%',
  width: 520,
})

export const StyledCardLogoWrapper = styled(Box)({
  padding: CARD_INNER_LOGO_MARGIN,
  display: 'flex',
  justifyContent: 'center',
})

export const StyledCardBody = styled(Box)({
  backgroundColor: theme.palette.grey[50].toHexString(),
  border: `2px solid ${theme.palette.grey[100].toHexString()}`,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  color: theme.palette.secondary[100].toHexString(),
  padding: '12px 16px',
})

export const StyledCardFooter = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  padding: CARD_INNER_PADDING,
})
