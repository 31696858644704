import { gql } from '@apollo/client'

export const verifyPasswordResetCodeQuery = gql`
  query VerifyPasswordResetCode(
    $phoneNumber: String!
    $passwordResetCode: String!
    $countryCode: String
  ) {
    verifyPasswordResetCode(
      passwordResetCode: $passwordResetCode
      phoneNumber: $phoneNumber
      countryCode: $countryCode
    ) {
      message
    }
  }
`

export const resetPasswordMutation = gql`
  mutation ResetPassword(
    $phoneNumber: String!
    $passwordResetCode: String!
    $newPassword: String!
    $newPasswordConfirm: String!
    $firstName: String
    $lastName: String
    $email: String
    $countryCode: String
  ) {
    resetPassword(
      phoneNumber: $phoneNumber
      passwordResetCode: $passwordResetCode
      newPassword: $newPassword
      newPasswordConfirm: $newPasswordConfirm
      firstName: $firstName
      lastName: $lastName
      email: $email
      countryCode: $countryCode
    ) {
      message
      token
    }
  }
`
