import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Query } from 'api'
import { meQuery } from 'screens/SignUpPhone/graphql'
import { clearToken } from 'store/auth'

import { selectUser, setUser } from '../../store/user'

export function useClearAccessJWT() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearToken())
  }, [dispatch])
}

export function useMe() {
  const dispatch = useDispatch()
  const reduxUser = useSelector(selectUser())
  const [loading, setLoading] = useState(false)

  const [getMe] = useLazyQuery<{ me: Query['me'] }>(meQuery)

  async function refetch(): Promise<void> {
    setLoading(true)

    try {
      const { data } = await getMe()

      if (!data?.me) {
        throw new Error('No data returned.')
      }

      dispatch(
        setUser({
          email: data.me.email || '',
          firstName: data.me.firstName || '',
          hasPassword: data.me.hasPassword ?? false,
          id: data.me.id || 0,
          imageUrl: data.me.imageUrl || '',
          jobResponsibilityIds: data.me.jobResponsibilityIds || [],
          jobRoleIds: data.me.jobRoleIds || [],
          lastName: data.me.lastName || '',
          phoneNumberNational: data.me.phoneNumberNational || '',
        }),
      )
    } catch (e) {
      console.error(e, 'Failed to get latest user info.')
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    refetch,
    user: reduxUser,
  }
}
