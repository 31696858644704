import { useQuery } from '@apollo/client'
import { Button } from '@getjelly/jelly-ui'
import { reduce } from 'ramda'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Query, Dish, QueryMenuArgs, Menu } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import {
  Icon,
  VirtualizedSectionList,
  NewLayout,
  Typography,
} from 'components/newUi'
import { routes } from 'routes/Paths'
import { calculateAverageGp } from 'screens/Menu'
import { getMenuByIdQuery } from 'screens/Menu/graphql'
import { Print } from 'screens/Menu/Print'
import { useStyles } from 'screens/Menu/styles'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord } from 'utils'

import { Item } from './Item'

const transformData = (menu: Menu) => {
  const grouped = reduce(
    (acc, el) => {
      const key = el.dish.type

      // @ts-ignore
      if (!acc[key]) {
        // @ts-ignore
        acc[key] = []
      }
      // @ts-ignore
      acc[key].push(el.dish)

      return acc
    },
    {},
    menu.dishList.nodes,
  )

  const transformedData: {
    label: string
    items: Dish[]
  }[] = Object.keys(grouped).map((key) => {
    return {
      // @ts-ignore
      items: grouped[key].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ) as Dish[],
      label: capitaliseEachWord(key),
    }
  })

  return transformedData
}

export const ViewMenu = () => {
  const { selectedKitchen } = useKitchen()
  const { id } = useParams()
  const classes = useStyles()

  const navigate = useNavigate()
  const { theme } = useTheme()
  const [data, setData] = useState<{ label: string; items: Dish[] }[]>([])

  const { data: queryResult, loading } = useQuery<
    { menu: Query['menu']; dishTypes: Query['dishTypes'] },
    QueryMenuArgs
  >(getMenuByIdQuery, {
    variables: {
      where: {
        id: parseInt(id),
      },
    },
  })

  useEffect(() => {
    if (!queryResult || !queryResult?.menu || loading) {
      return
    }

    const transformedData = transformData(queryResult.menu)

    return setData(transformedData)
  }, [queryResult, loading])

  const averageGP = calculateAverageGp(queryResult?.menu?.dishList.nodes ?? [])
  const positive = averageGP ?? 0 >= (queryResult?.menu?.gpTarget ?? 0)

  return (
    <>
      <NewLayout
        subtitle="Menus"
        title={queryResult?.menu?.name}
        hideMenu
        bottomContent={
          <div className="py-4 px-2 flex space-x-2">
            <Button
              onClick={() => window.print()}
              label="Print Menu"
              className="w-full"
              style="secondary"
            />

            <Button
              onClick={() =>
                navigate(`${routes.Book}${routes.Menu}${routes.Allergen}/${id}`)
              }
              label="See Allergens"
              className="w-full"
            />
          </div>
        }
        rightContent={
          selectedKitchen?.userPermissions?.includes('update-menu') ? (
            <div className="px-4">
              <Button
                onClick={() =>
                  navigate(`${routes.Book}${routes.Menu}${routes.Edit}/${id}`)
                }
                label="Edit Menu"
              />
            </div>
          ) : undefined
        }
      />
      <div className={classes.noPrint}>
        <div
          style={{
            alignItems: 'center',
            backgroundColor: 'white',
            display: 'grid',
            gridTemplateColumns: '1fr 0fr 0fr',
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2),
          }}
        >
          <div style={{ paddingLeft: theme.spacing(2) }}>
            <Typography style={{ fontWeight: 500 }} variant="subtitle1">
              GP Target
            </Typography>
          </div>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Typography
              style={{ fontWeight: 500, textAlign: 'right' }}
              variant="subtitle1"
            >
              {queryResult?.menu?.gpTarget}%
            </Typography>
            <Icon
              iconName="timeline"
              style={{
                color: positive
                  ? theme.palette.success[100].toHexString()
                  : theme.palette.error[100].toHexString(),
                width: 50,
              }}
            />
          </div>
        </div>
      </div>

      {!loading || queryResult?.menu ? (
        <div
          className={classes.noPrint}
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        >
          <VirtualizedSectionList
            ROW_HEIGHT={65}
            Item={(props: any) => {
              const index = props.index
              const item = props.data.items[index]

              return (
                <Item
                  {...props}
                  key={item.id}
                  className={classes.noPrint}
                  gpTarget={queryResult?.menu?.gpTarget}
                />
              )
            }}
            emptyMessage={
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: theme.spacing(1),
                  width: '100%',
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: theme.palette.primary[60].toHexString(),
                  }}
                >
                  No dishes added to this menu
                </Typography>
              </div>
            }
            data={data}
            loading={loading}
          />
        </div>
      ) : (
        <Loader />
      )}
      {queryResult?.menu && (
        <Print
          data={queryResult.menu}
          kitchenName={selectedKitchen?.name}
          dishTypes={queryResult?.dishTypes}
        />
      )}
    </>
  )
}
