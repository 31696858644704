import dayjs from 'dayjs'
import { omit } from 'ramda'
import { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { useGetBareStockLazyQuery, useGetStockQuery } from 'api/types'
import { DATE_FORMATS } from 'app'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader, NewTextField } from 'components'
import {
  Button,
  Icon,
  NewLayout,
  Typography,
  LeaveConfirmationModal,
  Alert,
} from 'components/newUi'
import { routes } from 'routes/Paths'
import { NewModal } from 'screens/Create/NewModal'
import { BottomContent } from 'screens/Stock/Edit/components/BottomContent'
import { CSVDownload } from 'screens/Stock/Edit/components/CSVDownload'
import { CustomTitle } from 'screens/Stock/Edit/components/CustomTitle'
import {
  selectStocktake,
  updateDateAndName,
  updateStocktake,
  clearStocktake,
  StockTakeStoreStockTake,
} from 'store/stocktake'
import { useTheme } from 'styles/newUi'

import { List } from './components/List'
import { useCreateOrUpdateOneStockMutation } from './useCreateOrUpdateOneStockMutation'

const connectionErrorString = `We couldn't save the stocktake - please check your connection`
const missingValueErrorString = `Please enter a value for all items`

export const EditStocktake = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()
  const { id } = useParams()
  const stocktake = useSelector(selectStocktake(id as string))
  // HACK - this is a hack to stop component from getting crazy between reseting stocktake and navigating back
  const [wasAlreadySaved, setWasAlreadySaved] = useState(false)
  const [showEditStockModal, setShowEditStockModal] = useState(false)
  const [wasUpdatedSinceBy, setWasUpdatedSinceBy] = useState<{
    updatedByName: string
    updatedAt: string
  } | null>(null)

  const [temporaryStocktakeData, setTemporaryStocktakeData] = useState<{
    date: Date | undefined
    name: string | null | undefined
  }>({
    date: undefined,
    name: undefined,
  })

  const validate = (s: StockTakeStoreStockTake) =>
    s?.entries?.every(
      (e) =>
        e.quantity === '' ||
        !Number.isNaN(Number.parseFloat(e.quantity as unknown as string)), // parseFloat can handle the options, its just not typed
    ) ?? true

  const [isValid, setIsValid] = useState(false)

  const { theme } = useTheme()

  const { data, loading: queryLoading } = useGetStockQuery({
    skip: (stocktake && stocktake.isDraft) || wasAlreadySaved,
    variables: {
      cursor: id as string,
    },
  })

  const [getBareStock] = useGetBareStockLazyQuery({
    variables: {
      cursor: id as string,
    },
  })

  const leavePage = () => {
    setWasAlreadySaved(true)
    dispatch(clearStocktake(stocktake._cursor))
    setTimeout(() => navigate(`${routes.Stock}${routes.Take}`))
  }

  const [
    createOrUpdateOneStock,
    { error: errorStockTakeUpdate, loading: loadingStockTakeUpdate },
  ] = useCreateOrUpdateOneStockMutation({
    onCompleted: leavePage,
  })

  const handleSubmit = async () => {
    if (!isValid) return

    // no stock id so no risk of overwrite
    if (!stocktake.id) return createOrUpdateOneStock()

    const freshStockResponse = await getBareStock()
    const freshStock = freshStockResponse.data?.stockNode
    const lastUpdatedAt = freshStock?.updatedAt
    const wasUpdatedSince = dayjs(stocktake.updatedAt).isBefore(lastUpdatedAt)

    if (wasUpdatedSince) {
      return setWasUpdatedSinceBy({
        updatedAt: dayjs(freshStock?.updatedAt).format(
          DATE_FORMATS.ANOTHER_DAY_FORMAT,
        ),
        updatedByName: `${freshStock?.updatedBy.firstName} ${freshStock?.updatedBy.lastName}`,
      })
    }
    return createOrUpdateOneStock()
  }

  useEffect(() => {
    const stock = data?.stockNode

    // do nothing
    if (!stock) return

    // do nothing if stocktake already exists in the store and has unsaved changes
    // if it doesn't have unsaved changes load new stock
    if (stocktake && stocktake.unsavedChanges) return

    // do nothing if existing stock is up to date
    if (stocktake && stocktake.updatedAt === stock.updatedAt) return

    dispatch(
      updateStocktake({
        ...omit(
          [
            'entryList',
            'createdAt',
            'createdBy',
            'kitchen',
            'updatedAt',
            'updatedBy',
          ],
          stock,
        ),
        entries: stock.entryList.nodes,
        kitchenId: selectedKitchen?.id ?? -1,
      }),
    )
    // The current functionality relies on this bad interaction between data and
    // outdated stocktake, adding stocktake as a dep breaks it. :'(
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    setTemporaryStocktakeData({
      date: dayjs(stocktake?.date).toDate(),
      name: stocktake?.name,
    })

    setIsValid(validate(stocktake))
  }, [stocktake])

  const alert: undefined | string = (() => {
    if (errorStockTakeUpdate) return connectionErrorString
    if (!isValid) return missingValueErrorString
    return undefined
  })()

  if (queryLoading) {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <div>
          <Typography variant="h5">Loading Stocktake</Typography>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <>
      <NewLayout
        hideMenu
        bottomContent={
          <BottomContent
            disabled={!isValid}
            loading={loadingStockTakeUpdate || queryLoading}
            stocktake={stocktake}
            submit={handleSubmit}
            id={id}
          />
        }
        customTitle={
          <CustomTitle
            setShowModal={setShowEditStockModal}
            stocktake={stocktake}
          />
        }
        rightContent={
          <CSVDownload
            stocktake={stocktake}
            temporaryStocktakeData={temporaryStocktakeData}
          />
        }
      />
      {alert && (
        <Alert type="error">
          {/* TODO it should a reusable component (with no inline styled) */}
          <div
            style={{
              alignItems: 'center',
              color: 'black',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Icon
              iconName="notifications"
              style={{
                color: theme.palette.error[100].toHexString(),
                marginLeft: -8,
                marginRight: 12,
              }}
            />
            <div
              style={{
                alignItems: 'center',
                color: theme.palette.primary[100].toHexString(),
                display: 'flex',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 'normal',
                lineHeight: '20px',
              }}
            >
              {alert}
            </div>
          </div>
        </Alert>
      )}
      <List />
      {stocktake && (
        <NewModal
          show={
            showEditStockModal &&
            !!selectedKitchen?.userPermissions?.includes('update-stock')
          }
          handleCancel={() => setShowEditStockModal(false)}
          title="Edit stocktake"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: theme.spacing(2),
            }}
          >
            <NewTextField
              label="Name"
              defaultValue={temporaryStocktakeData.name ?? undefined}
              onChange={(value) =>
                setTemporaryStocktakeData({
                  ...temporaryStocktakeData,
                  name: value as string,
                })
              }
            />
            <DatePicker
              calendarContainer={(props) => (
                <div
                  style={{ display: 'flex', justifyContent: 'center' }}
                  {...props}
                />
              )}
              customInput={
                <NewTextField
                  disabled
                  value={dayjs(temporaryStocktakeData.date).format(
                    DATE_FORMATS.DAY_FORMAT,
                  )}
                />
              }
              open
              inline
              selected={temporaryStocktakeData.date}
              onMonthChange={(date: Date) => {
                setTemporaryStocktakeData({ ...temporaryStocktakeData, date })
              }}
              onChange={(date: Date) => {
                setTemporaryStocktakeData({ ...temporaryStocktakeData, date })
              }}
            />
            <Button
              loading={loadingStockTakeUpdate}
              text="Save Stocktake"
              style={{ marginTop: theme.spacing(2) }}
              onClick={() => {
                dispatch(
                  updateDateAndName({
                    _cursor: stocktake._cursor,
                    ...temporaryStocktakeData,
                  }),
                )
                setShowEditStockModal(false)
              }}
            />
          </div>
        </NewModal>
      )}
      <LeaveConfirmationModal
        action="Yes, leave"
        title="Leave stocktake without saving?"
        cancelLabel="No, stay"
        text=""
        onConfirm={(state) => state?.retry()}
        show={!!stocktake?.unsavedChanges && !wasAlreadySaved}
        skip={(state) => !!state?.location.pathname.includes(routes.Add)}
      />
      <LeaveConfirmationModal
        action="Over-ride to my changes"
        cancelLabel="Discard my changes"
        title={`Stocktake has been updated by ${wasUpdatedSinceBy?.updatedByName} at ${wasUpdatedSinceBy?.updatedAt}`}
        text=""
        onConfirm={() => createOrUpdateOneStock()}
        onCancel={() => leavePage()}
        show={!!wasUpdatedSinceBy}
        block={false}
      />
    </>
  )
}
