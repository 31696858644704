import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles(() => ({
    _root: {
      backgroundColor: 'white',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    onlyPrint: {
      '@media print': {
        display: 'block',
      },
      display: 'none',
    },
  }))
