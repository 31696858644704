import dayjs from 'dayjs'
import { ReactElement } from 'react'

import { useStyles } from './styles'

type Props = {
  kitchenName: string
  title?: string
  component?: () => ReactElement
}

export const PrintHeader = ({ kitchenName, title, component }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.titleContainer}>
      <div className={classes.subTitle}>{kitchenName}</div>
      {component && component()}
      {title && <div className={classes.title}>{title}</div>}
      <div className={classes.subTitle}>
        {dayjs().utc().format('MMMM YYYY')}
      </div>
    </div>
  )
}
