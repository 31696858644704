import { useNavigate } from 'react-router-dom'

import { Icon, Typography } from 'components/newUi'
import { Image } from 'screens/Invoices/Add'
import { useTheme } from 'styles/newUi'

import { useStyles } from './styles'

interface IProps {
  imageUrl: string | undefined
  name: string
  route: string
}

export const ImageRow = ({ imageUrl, name, route }: IProps) => {
  const navigate = useNavigate()
  const { theme } = useTheme()
  const classes = useStyles()

  const handleNavigate = () => {
    navigate(route)
  }

  return (
    // eslint-disable-next-line
    <div
      onClick={handleNavigate}
      style={{
        backgroundColor: 'white',
        borderBottomColor: theme.palette.primary[10].toHexString(),
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: '0fr 1fr 0fr 0fr',
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Image
          imageSize={68}
          localImage={false}
          url={imageUrl ?? undefined}
          frame={false}
        />
      </div>
      <div
        className={classes.itemInnerMobile}
        style={{
          alignItems: 'center',
          display: 'flex',
          overflow: 'hidden',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            display: '-webkit-box',
            fontWeight: 500,
            overflow: 'hidden',
            textTransform: 'capitalize',
          }}
        >
          {name}
        </Typography>
      </div>
      <div
        className={classes.fontGrey}
        style={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          fontFamily: 'Rubik, sans-serif',
          fontWeight: 500,
          justifyContent: 'flex-end',
          textTransform: 'capitalize',
        }}
      ></div>
      <div
        className={classes.itemInnerMobile}
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: 50,
        }}
      >
        <Icon iconName="chevronRight" />
      </div>
    </div>
  )
}
