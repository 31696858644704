import { useNavigate } from 'react-router-dom'

import { SwapHorizIcon } from 'components'
import { routes } from 'routes/Paths'

import { useKitchen } from '../app/contexts/SelectedKitchen'

export function KitchenChange({ redirect }: { redirect: string }) {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const onClick = () =>
    navigate(`${routes.Settings + routes.Locations}?redirect=${redirect}`)

  return (
    <div className="flex flex-col items-center space-y-1 text-sm">
      <span className="font-rubik text-primary-600 font-medium">
        Current location
      </span>

      <div
        className="flex items-center space-x-0.5 rubik font-medium text-secondary-400"
        onClick={onClick}
      >
        <SwapHorizIcon />
        <span>{selectedKitchen?.name}</span>
      </div>
    </div>
  )
}
