import { gql } from '@apollo/client'

export const getAvailablePlans = gql`
  query availablePlans {
    availablePlans {
      name
      tier
      price
      description
      features
      payment_link
    }
  }
`
