import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useKitchen } from 'app/contexts/SelectedKitchen'
import { SyncAltIcon } from 'components'
import { Typography } from 'components/newUi'
import { selectProduct, updateSizeType } from 'screens/Order/store'

interface Props {
  size: string
  id: number
}

export const Size = ({ size, id: productId }: Props) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { selectedKitchen } = useKitchen()
  const [sizeType, setSizeType] = useState(0)

  const product = useSelector(
    selectProduct(`${selectedKitchen?.id}:${id}`, productId),
  )

  useEffect(() => {
    if (!product) return
    setSizeType(product.sizeType ?? 0)
    // eslint-disable-next-line
  }, [])

  const handleShuffle = () => {
    switch (sizeType) {
      case 0:
        setSizeType(1)
        dispatch(
          updateSizeType({
            id: `${selectedKitchen?.id}:${id}`,
            productId,
            sizeType: 1,
          }),
        )
        return
      case 1:
        setSizeType(2)
        dispatch(
          updateSizeType({
            id: `${selectedKitchen?.id}:${id}`,
            productId,
            sizeType: 2,
          }),
        )
        return
      case 2:
        setSizeType(0)
        dispatch(
          updateSizeType({
            id: `${selectedKitchen?.id}:${id}`,
            productId,
            sizeType: 0,
          }),
        )
        return
    }
  }

  const sizeDesc = useMemo(() => {
    switch (sizeType) {
      case 0:
        return size
      case 1:
        return 'BOX'
      case 2:
        return 'UNIT'
      default:
        return size
    }
  }, [sizeType, size])

  return (
    <div
      tabIndex={0}
      onKeyDown={() => {
        //do nothing
      }}
      role="button"
      onClick={handleShuffle}
      style={{
        alignItems: 'center',
        cursor: 'pointer',
        // color: theme.palette.grey[500],
        display: 'flex',
      }}
    >
      <SyncAltIcon style={{ fontSize: 20, marginRight: 16 }} />
      <Typography variant="button" style={{ color: 'inherit' }}>
        {sizeDesc}
      </Typography>
    </div>
  )
}
