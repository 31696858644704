import { useNavigate } from 'react-router-dom'

import { DishAndRecipe } from 'api'
import { Icon, Typography } from 'components/newUi'
import { buildViewRoute } from 'routes/Paths'
import { Image } from 'screens/Invoices/Add'
import { useTheme } from 'styles/newUi'

import { useStyles } from './styles'

export const Item = (props: any) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { theme } = useTheme()

  const { index, data, style, gpTarget } = props

  const dishOrRecipe: DishAndRecipe = data.items[index]

  const handleNavigate = () => {
    navigate(
      buildViewRoute(dishOrRecipe!.id, dishOrRecipe?.__typename === 'Dish'),
    )
  }

  return (
    // eslint-disable-next-line
    <div
      onClick={handleNavigate}
      style={{
        ...style,
        backgroundColor: 'white',
        borderBottomColor: theme.palette.primary[10].toHexString(),
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: '0fr 1fr 0fr 0fr',
      }}
    >
      <div className="flex w-16 h-16">
        <Image
          localImage={false}
          url={dishOrRecipe.imageUrl ?? undefined}
          frame={false}
          className="w-16 h-16"
        />
      </div>
      <div
        className={classes.itemInnerMobile}
        style={{
          alignItems: 'center',
          display: 'flex',
          overflow: 'hidden',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          {dishOrRecipe.name}
        </Typography>
      </div>
      <div
        className={classes.fontGrey}
        style={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          fontFamily: 'Rubik, sans-serif',
          fontWeight: 500,
          justifyContent: 'flex-end',
          textTransform: 'capitalize',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            textAlign: 'right',
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 500,
            }}
          >
            {/* @ts-expect-error */}
            {`£${dishOrRecipe.price.toFixed(2)}`}
          </Typography>
          <Typography
            variant="caption"
            style={{
              color:
                // @ts-ignore
                gpTarget > dishOrRecipe.gpTarget
                  ? theme.palette.error[100].toHexString()
                  : theme.palette.success[100].toHexString(),
            }}
          >
            {/* @ts-expect-error */}
            {`${dishOrRecipe.gpTarget.toFixed(2)}%`}
          </Typography>
        </div>
      </div>
      <div
        className={classes.itemInnerMobile}
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: 50,
        }}
      >
        <Icon iconName="chevronRight" />
      </div>
    </div>
  )
}
