import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'

import { QuerySuppliersArgs, SortOrder, Status, Supplier } from 'api'
import { capitaliseEachWord } from 'utils'

import { getSuppliersQuery } from './graphql'
import { Props } from './types'

import { Dropdown } from '../Dropdown'

export const SelectSupplier = (props: Props) => {
  const { data, loading } = useQuery<
    {
      suppliers: Supplier[]
    },
    QuerySuppliersArgs
  >(getSuppliersQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      orderBy: [{ name: SortOrder.Asc }],
      where: {
        kitchens: {
          some: {
            kitchenId: {
              equals: props.kitchenId,
            },
          },
        },
        status: {
          not: {
            equals: Status.Inactive,
          },
        },
      },
    },
  })

  if (loading) return <CircularProgress />

  const options: { label: string; value: number }[] = data?.suppliers
    ? data?.suppliers.map((u) => ({
        label: capitaliseEachWord(u.name),
        value: u.id,
      }))
    : []

  const defaultOption = options!.find(
    (option) => option.value === props.defaultValue,
  )

  return (
    <Dropdown
      {...props}
      options={[
        ...options,
        { label: `>>> ADD NEW SUPPLIER HERE <<<`, value: 'NEW' },
      ]}
      defaultOption={defaultOption!}
      onChange={(value, label) => {
        if (props.onChange)
          props.onChange(
            value,
            label,
            data?.suppliers.find((u) => u.id === value),
          )
      }}
    ></Dropdown>
  )
}
