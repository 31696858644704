import { DashboardNav, DashboardNavItem } from '@getjelly/jelly-ui'
import { IconReceipt, IconTrendingUp } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

import { Portal } from '../../components/newUi/NewLayout'
import { routes } from '../../routes/Paths'

export function FinanceNavigation() {
  const navigate = useNavigate()

  return (
    <Portal id="react-top-portal">
      <DashboardNav>
        <DashboardNavItem
          title="Invoices"
          icon={IconReceipt}
          onClick={() =>
            navigate(routes.Spending + routes.Invoice + routes.History)
          }
        />

        <DashboardNavItem
          title="Insight"
          icon={IconTrendingUp}
          onClick={() => navigate(routes.Spending + routes.History)}
        />
      </DashboardNav>
    </Portal>
  )
}
