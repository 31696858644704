import { FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { find, propEq } from 'ramda'
import { CSSProperties } from 'react'

import { Unit } from 'api'
import { FormControl, InputLabel, Select } from 'components'
import { FORM_GREY } from 'styles/colors'

interface IProps {
  data: Unit[] | undefined
  state: Unit | undefined
  label?: string
  handleChange: (unit: Unit | undefined) => void
  error?: boolean
  fullWidth?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  disabled?: boolean
  children?: unknown
  formColor: string
  margin?: Partial<{ marginLeft: number; marginRight: number }>
  additionalStyles?: Partial<CSSProperties>
  placeholder?: JSX.Element
  formHelperText?: string
}

const useStyle = makeStyles((theme) => ({
  formControl: {
    // backup, just in case
    // color: theme.palette.type === 'dark' ? 'white' : 'black',
    color: 'white',
    flex: 1,
  },
  label: {
    color: FORM_GREY,
  },
  select: {
    '&:disabled': {
      backgroundColor: undefined,
    },
    backgroundColor: theme.palette.background.default,
    color: 'black',
    // backup, just in case
    // color: theme.palette.type === 'dark' ? 'white' : 'black',
  },
}))

export const SelectUnitDropdown = ({
  data,
  state,
  label,
  handleChange,
  variant,
  children,
  formColor,
  margin,
  additionalStyles,
  placeholder,
  formHelperText,
  disabled,
  error,
  ...rest
}: IProps) => {
  const classes = useStyle()

  const onChange = (event: any) => {
    const unit = find(propEq('id', event.target.value), data ?? [])
    handleChange(unit)
  }

  return (
    <FormControl
      style={{ ...margin, ...additionalStyles }}
      variant={variant}
      className={classes.formControl}
      {...rest}
      required
    >
      {label ? (
        <InputLabel className={classes.label}>{label}</InputLabel>
      ) : (
        <></>
      )}
      <Select
        style={{ backgroundColor: !disabled ? formColor : undefined }}
        variant={variant}
        disabled={disabled}
        label={label}
        {...rest}
        MenuProps={{
          classes: {
            list: classes.select,
          },
        }}
        error={error}
        displayEmpty
        className={classes.select}
        value={state?.id ?? ''}
        onChange={onChange}
      >
        <>
          {placeholder}
          {children}
        </>
      </Select>
      <FormHelperText style={{ color: '#f44336' }}>
        {formHelperText}
      </FormHelperText>
    </FormControl>
  )
}
