import { CSVLink } from 'react-csv'

import { Recipe, RecipeToIngredient, RecipeToRecipe } from 'api'
import { Icon, Typography } from 'components/newUi'
import { calculateTotalCost } from 'screens/Create/helpers/dish'
import { RecipeOrIngredient } from 'screens/Create/Ingredients/types'
import { Row } from 'screens/View/Details/Row'
import { theme } from 'styles/newUi'

type Props = {
  totalCost: string
  recipe: Recipe
}

function DownloadCSV({ totalCost, recipe }: Props) {
  function getRow(component: any) {
    if (component.__typename === 'RecipeToIngredient') {
      const { amount, ingredient, unit, unitCost } =
        component as RecipeToIngredient

      return {
        amount,
        name: ingredient.product?.name ?? '',
        price: `£${unitCost?.toFixed(2)}`,
        unit: unit?.name,
      }
    }

    const { amount, childRecipe, unit, unitCost } = component as RecipeToRecipe

    return {
      amount,
      name: childRecipe.name,
      price: `£${unitCost?.toFixed(2)}`,
      unit: unit?.name,
    }
  }

  return (
    <div className="bg-gray-100 py-6">
      <CSVLink
        style={{ textDecoration: 'none', width: '100%' }}
        data={[
          {
            price: `£${totalCost}`,
            unit: 'Total Cost',
          },
          {},
          ...[...recipe.ingredients, ...recipe.childRecipes].map(getRow),
        ]}
        filename={`${recipe.name}.csv`}
        headers={[
          { key: 'name', label: 'Name' },
          { key: 'amount', label: 'Amount' },
          { key: 'unit', label: 'Unit' },
          { key: 'price', label: 'Price' },
        ]}
      >
        <Typography
          variant="button"
          style={{
            alignItems: 'center',
            color: theme.palette.secondary[100].toHexString(),
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Download costs as CSV <Icon iconName="download"></Icon>
        </Typography>
      </CSVLink>
    </div>
  )
}

export const Ingredients = ({ recipe }: { recipe: Recipe }) => {
  const totalCost = calculateTotalCost(recipe)
  if (recipe.ingredients.length === 0 && recipe.childRecipes.length === 0) {
    return null
  }

  return (
    <>
      {[...recipe.ingredients, ...recipe.childRecipes].map(
        (item: RecipeOrIngredient) => (
          <Row
            item={item}
            key={
              item.__typename === 'RecipeToIngredient'
                ? item.ingredient.id
                : (item as RecipeToRecipe).childRecipe.id
            }
          />
        ),
      )}

      <DownloadCSV totalCost={totalCost} recipe={recipe} />
    </>
  )
}
