import { useMutation } from '@apollo/client'
import { Button, Modal, Typography } from '@getjelly/jelly-ui'
import { useState } from 'react'

import {
  KitchenToPosProduct,
  Mutation,
  MutationLinkPosProductArgs,
} from '../../../../api'
import { errorToast } from '../../../../components/toasts'
import { unlinkPosProductMutation } from '../graphql'

type Props = {
  kto: KitchenToPosProduct
  open: boolean
  onClose: () => void
}

export function RemoveLinkModal({ open, onClose, kto }: Props) {
  const [loading, setLoading] = useState(false)

  const [unlinkPosProduct] = useMutation<
    { unlinkPosProduct: Mutation['unlinkPosProduct'] },
    MutationLinkPosProductArgs
  >(unlinkPosProductMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'kitchenToPosProductsCountQuery',
      'kitchenToPosProductsQuery',
    ],
  })

  return (
    <Modal open={open} onClose={onClose}>
      <div className="space-y-8">
        <div className="text-center px-2">
          <Typography style="h6" className="text-primary-900">
            Are you sure?
          </Typography>

          <Typography style="caption" className="text-primary-600">
            This item is already linked to another dish, do you want to break
            this link?
          </Typography>
        </div>

        <div className="space-y-2">
          <Button
            style="delete"
            onClick={async () => {
              if (loading) return

              if (!kto.dish) {
                errorToast('Unknown error occurred. This is already unlinked.')
                return
              }

              setLoading(true)

              try {
                await unlinkPosProduct({
                  variables: {
                    data: {
                      dishId: kto.dish.id,
                      ktoId: kto.id,
                    },
                  },
                })

                onClose()
              } catch {
                errorToast('Unknown error occurred. Unable to unlink.')
              } finally {
                setLoading(false)
              }
            }}
            label="Yes, Remove Link"
            className="w-full"
            loading={loading}
          />

          <Button
            style="secondary"
            onClick={onClose}
            label="Cancel"
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  )
}
