import { Typography } from '@getjelly/jelly-ui'

type Props = {
  HEADER_HEIGHT: number
  label: string
  rightAdornment?: React.ReactElement
}

export const SectionTitle = ({
  HEADER_HEIGHT,
  label,
  rightAdornment,
}: Props) => {
  return (
    <div
      style={{
        height: HEADER_HEIGHT,
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
      className="px-2 flex items-center w-full bg-primary-100"
    >
      <Typography style="subtitle2" className="text-primary-800">
        {label}
      </Typography>

      {rightAdornment && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            right: 20,
          }}
        >
          {rightAdornment}
        </div>
      )}
    </div>
  )
}
