import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  CreateOneStockMutation,
  useCreateOneStockMutation,
  useUpdateOneStockMutation,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { selectStocktake } from 'store/stocktake'

type UseCreateOrUpdateOneStockMutationReturnType = [
  () => void,
  { loading: boolean; error: Error | null },
]

type UseCreateOrUpdateOneStockMutationArgs = {
  onCompleted: (stocktake: CreateOneStockMutation['createOneStock']) => void
}

type UseCreateOrUpdateOneStockMutation = ({
  onCompleted,
}: UseCreateOrUpdateOneStockMutationArgs) => UseCreateOrUpdateOneStockMutationReturnType

export const useCreateOrUpdateOneStockMutation: UseCreateOrUpdateOneStockMutation =
  ({ onCompleted }) => {
    const { id } = useParams()
    const stocktake = useSelector(selectStocktake(id as string))
    const { selectedKitchen } = useKitchen()

    const [createOneStock, { error, loading }] = useCreateOneStockMutation({
      awaitRefetchQueries: true,
      onCompleted: ({ createOneStock }) => {
        onCompleted(createOneStock)
      },
      variables: stocktake
        ? {
            data: {
              date: stocktake.date,
              entries: stocktake.entries.map(
                ({
                  ingredientId,
                  recipeId,
                  unitId,
                  quantity,
                  price,
                  total,
                }) => ({
                  ingredientId,
                  price,
                  quantity: Number(quantity), // Previous 2-way binding of GQL and form was too inflexible
                  recipeId,
                  total,
                  unitId,
                }),
              ),
              kitchenId: selectedKitchen!.id,
              name: stocktake.name,
              total: stocktake.total,
            },
          }
        : undefined,
    })

    const [updateOneStock, { error: updateError, loading: updateLoading }] =
      useUpdateOneStockMutation({
        awaitRefetchQueries: true,
        onCompleted: ({ updateOneStock }) => {
          onCompleted(updateOneStock)
        },
        variables: stocktake
          ? {
              data: {
                date: stocktake.date,
                entries: stocktake.entries.map(
                  ({
                    ingredientId,
                    recipeId,
                    unitId,
                    quantity,
                    price,
                    total,
                  }) => ({
                    ingredientId,
                    price,
                    quantity: Number(quantity), // Previous 2-way binding of GQL and form was too inflexible
                    recipeId,
                    total,
                    unitId,
                  }),
                ),
                id: stocktake.id,
                name: stocktake.name,
                total: stocktake.total,
              },
            }
          : undefined,
      })

    if (!stocktake) return [() => undefined, { error: null, loading: false }]

    if (stocktake.id) {
      return [
        updateOneStock,
        { error: updateError, loading: updateLoading },
      ] as UseCreateOrUpdateOneStockMutationReturnType
    }
    return [
      createOneStock,
      { error, loading },
    ] as UseCreateOrUpdateOneStockMutationReturnType
  }
