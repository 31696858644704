import { Formik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Recipe } from 'api'
import { NewButton, NewTextField, Container } from 'components'
import { Typography, NewLayout, LeaveConfirmationModal } from 'components/newUi'
import { useWindowSize } from 'hooks'
import { useUpdateComponent } from 'screens/Create/Ingredients/hooks'
import { theme } from 'styles/newUi'

import { useStyles } from './styles'

import { errorToast, successToast } from '../../components/toasts'
import { cleanErrorMessage } from '../../utils'

export interface IProps {
  isDish: boolean
  recipe: Recipe
  id: number
}

export const Method = ({ recipe, isDish, id }: IProps) => {
  const { gte: isDesktop } = useWindowSize('md')
  const classes = useStyles()
  const [override, setOverride] = useState(false)

  const [updateComponent] = useUpdateComponent(isDish)
  const navigate = useNavigate()

  return (
    <Formik
      initialValues={{ instructions: recipe.instructions ?? '' }}
      onSubmit={async (state) => {
        await updateComponent({
          variables: {
            data: {
              id: id,
              instructions: state.instructions,
            },
          },
        })
          .then(({ data }) => {
            // @ts-ignore
            const { name } = (data?.updateOneRecipe ??
              // @ts-ignore
              data?.updateOneDish) as Recipe
            successToast(`Saved ${name}`)
            setOverride(true)
            navigate(-1)
          })
          .catch((error) => errorToast(cleanErrorMessage(error.message)))

        return
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <NewLayout
            title="Method & Notes"
            subtitle={isDish ? 'Create Dish' : 'Create Recipe'}
            bottomContent={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: 3,
                  paddingTop: 3,
                }}
              >
                <NewButton
                  disabled={!formik.dirty || formik.isSubmitting}
                  text={formik.isSubmitting ? undefined : 'Save Method & Notes'}
                  disableLoaderStyles
                  loading={formik.isSubmitting}
                  className={classes.button}
                  onClick={formik.handleSubmit}
                />
              </div>
            }
          />
          <LeaveConfirmationModal
            action="leave"
            title="You have unsaved changes, are you sure you want to leave the screen?"
            text=""
            onConfirm={() => {
              formik.resetForm()
              navigate(-1)
            }}
            show={formik.dirty && !formik.isSubmitting && !override}
          />

          <Container>
            <div style={{ display: 'flex' }}>
              <div style={{ height: '100%', width: '100%' }}>
                <div
                  style={{
                    backgroundColor: theme.palette.primary[10].toHexString(),
                    marginLeft: isDesktop ? -24 : -16,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingTop: 12,
                    width: '100vw',
                  }}
                >
                  <Typography variant="body1">
                    Use this space to add method steps or notes for you and your
                    team
                  </Typography>
                </div>
                <NewTextField
                  inputProps={{
                    'data-hj-allow': '',
                    rows: 15,
                    style: {
                      height: '100%',
                      width: '100%',
                    },
                    value: formik.values.instructions,
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('instructions', value)
                  }}
                  type="textarea"
                  placeholder="Enter text here..."
                  required
                  error={
                    !!formik.touched.instructions &&
                    !!formik.errors.instructions
                  }
                  style={{ height: '100%', width: '100%' }}
                />
              </div>
            </div>
          </Container>
        </form>
      )}
    </Formik>
  )
}
