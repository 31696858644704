import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import DatePicker from 'react-datepicker'

import { Kitchen } from 'api'
import { NewButton } from 'components'
import { Typography } from 'components/newUi'
import { NewModal } from 'screens/Create/NewModal'
import { Theme } from 'styles/newUi'

import { errorToast, successToast } from '../../../../components/toasts'
import {
  triggerInvoiceDownload,
  csvDownload,
} from '../rest/triggerInvoiceDownload'

interface ExportInvoiceData {
  invoiceDate: string
  invoiceId: string
  invoiceTotal: number
  supplierName: string
  vat: number
}

const buildCSV = (invoices: ExportInvoiceData[]) => {
  const csvRows = [
    [
      'AccountCode',
      'ContactName',
      'InvoiceNumber',
      'InvoiceDate',
      'DueDate',
      'Total',
      'Quantity',
      'UnitAmount',
      'LineAmount',
      'TaxType',
      'TaxAmount',
      'Description',
    ],
  ].concat(
    invoices.map((invoice) => {
      const invoiceDate = invoice!.invoiceDate!
        ? dayjs(invoice!.invoiceDate!)
        : ''
      const unitAmount = (
        (invoice!.invoiceTotal ?? 0) - (invoice!.vat ?? 0)
      ).toString()

      return [
        '310',
        invoice!.supplierName,
        invoice!.invoiceId!,
        invoiceDate ? invoiceDate.toISOString() : '',
        invoiceDate ? invoiceDate.add(30, 'day').toISOString() : '',
        (invoice!.invoiceTotal ?? 0).toString(),
        '1',
        unitAmount,
        unitAmount,
        invoice!.vat ? '20% (VAT on Expenses)' : 'Zero Rated Expenses',
        (invoice!.vat ?? 0).toString(),
        '',
      ]
    }),
  )

  return csvRows.map((e) => e.join(',')).join('\n')
}

export const ExportInvoicesModal = ({
  show,
  selectedKitchen,
  setDisplay,
  theme,
}: {
  show: boolean
  selectedKitchen: Kitchen
  setDisplay: (arg0: boolean) => void
  theme: Theme
}) => {
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(30, 'day').toDate(),
  )
  const [endDate, setEndDate] = useState(new Date())

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [emailAddress, setEmailAddress] = useState('')

  const [emailConfirmation, setEmailConfirmation] = useState(false)

  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined,
  )

  const exportCSV = () => {
    csvDownload(
      startDate,
      endDate,
      selectedKitchen?.id,
      function (data: any) {
        const csvContent = buildCSV(data.invoices)
        const blob = new Blob([csvContent], { type: 'text/csv' })
        window.open(window.URL.createObjectURL(blob))

        setDisplay(false)
      },
      function (errorMsg: string) {
        setIsSubmitting(false)
        errorToast(errorMsg)
        return
      },
    )
  }

  const mailInvoices = () => {
    if (!emailAddress) {
      errorToast('Please enter your email address.')
      return
    }
    triggerInvoiceDownload(
      startDate,
      endDate,
      emailAddress,
      selectedKitchen?.id,
      function () {
        setEmailConfirmation(false)
        setDisplay(false)
        successToast('You will recieve an email shortly with the raw images.')
      },
      function (errorMsg: string) {
        setIsSubmitting(false)
        errorToast(errorMsg)
        return
      },
    )
  }

  return (
    <NewModal
      containerStyle={{
        padding: '20px',
        paddingBottom: '10px',
        paddingTop: '0px',
      }}
      handleCancel={() => {
        setDisplay(false)
        setIsSubmitting(false)
        setEmailConfirmation(false)
      }}
      show={show}
      title="Export Invoices"
    >
      {emailConfirmation && (
        <Typography
          variant="body2"
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Raw images will be sent to this email address.
        </Typography>
      )}
      {!errorMessage && !emailConfirmation && (
        <Typography variant="body2">
          Please select the start and end dates for the invoices you wish to
          export.
        </Typography>
      )}
      {errorMessage && (
        <Typography style={{ color: '#d32f2f' }} variant="body2">
          {errorMessage}
        </Typography>
      )}
      {emailConfirmation && (
        <>
          <br />
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <br />
            <TextField
              placeholder="Email address"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              required
            />
          </div>
          <br />
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <NewButton
              loading={isSubmitting}
              onClick={() => {
                mailInvoices()
              }}
              text="Send"
            />
          </div>
        </>
      )}
      {!emailConfirmation && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingTop: theme.spacing(3),
            }}
          >
            <DatePicker
              dateFormat="dd MMM yyyy"
              selected={startDate}
              onChange={(date: Date) => {
                setStartDate(date)
                setErrorMessage(undefined)
              }}
              withPortal
              customInput={
                <TextField
                  id="export-from"
                  label="From"
                  variant="outlined"
                  style={{ padding: theme.spacing(1) }}
                  error={!!errorMessage}
                />
              }
            />
            <DatePicker
              dateFormat="dd MMM yyyy"
              selected={endDate}
              onChange={(date: Date) => {
                setEndDate(date)
                setErrorMessage(undefined)
              }}
              withPortal
              customInput={
                <TextField
                  id="export-to"
                  label="To"
                  variant="outlined"
                  style={{ padding: theme.spacing(1) }}
                  error={!!errorMessage}
                />
              }
            />
          </div>
          <br />
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <NewButton
              text="Export CSV for XERO"
              loading={isSubmitting}
              onClick={() => {
                setIsSubmitting(true)
                exportCSV()
              }}
            />
          </div>
          <br />
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <NewButton
              text="Export raw images"
              loading={isSubmitting}
              onClick={() => {
                const startDateFormatted = dayjs(startDate)
                const endDateFormatted = dayjs(endDate)
                if (endDateFormatted.diff(startDateFormatted, 'day') > 45) {
                  errorToast('Date range needs to be less than 45 days.')
                  return
                }
                setEmailConfirmation(true)
              }}
            />
          </div>
          <br />
        </>
      )}
    </NewModal>
  )
}
