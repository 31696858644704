import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Ingredient, QueryProductNodeArgs, Recipe, Dish } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import { Typography, Icon, Button } from 'components/newUi'
import { routes } from 'routes/Paths'
import { getProductQuery } from 'screens/Products/Costing/graphql'
import { changeColor } from 'screens/Products/PriceHistory'
import { useTheme } from 'styles/newUi'

import { ImageRow } from './ImageRow'

export const Costing = () => {
  const { id } = useParams()
  const [recipes, setRecipes] = useState<Recipe[]>([])
  const [dishes, setDishes] = useState<Dish[]>([])
  const { theme } = useTheme()
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()
  const { data, loading } = useQuery<
    {
      ingredientNode: Ingredient
    },
    QueryProductNodeArgs & { kitchenId: number }
  >(getProductQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      cursor: id,
      kitchenId: selectedKitchen?.id ?? -1,
    },
  })

  useEffect(() => {
    if (!data?.ingredientNode) return
    const product = data.ingredientNode.product

    const ingredients = product.ingredientList.nodes
    const recipesNodes = ingredients.flatMap((ing) => ing.recipeList.nodes)

    const { dishes, recipes } = recipesNodes.reduce(
      (acc, rec) => {
        const recipe = rec.recipe
        const dishes = recipe.dishList.nodes
        dishes.length
          ? (acc.dishes = acc.dishes.concat(dishes))
          : acc.recipes.push(recipe)
        return acc
      },
      { dishes: [], recipes: [] } as { dishes: Dish[]; recipes: Recipe[] },
    )
    setRecipes(recipes)
    setDishes(dishes)
  }, [data])

  if (loading) return <Loader />

  return (
    <>
      {recipes.map((recipe) => {
        return (
          <ImageRow
            key={recipe._cursor}
            imageUrl={recipe.imageUrl ?? undefined}
            route={`${routes.View}/${recipe.id}${routes.Recipe}`}
            name={recipe.name}
          />
        )
      })}
      {dishes.map((dish) => {
        return (
          <ImageRow
            key={dish._cursor}
            imageUrl={dish.imageUrl ?? undefined}
            route={`${routes.View}/${dish.id}${routes.Dish}`}
            name={dish.name}
          />
        )
      })}
      {!dishes.length && !recipes.length && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: theme.spacing(4),
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.primary[80].toHexString(),
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            Not used in any dishes or recipes
          </Typography>

          {selectedKitchen?.userPermissions?.includes('create-dish') && (
            <Button
              text="Create Dish/Recipe"
              onClick={() => navigate(`${routes.Book}${routes.Create}`)}
              style={{
                marginTop: theme.spacing(2),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

const ROW_HEIGHT = 49

export const Item = ({
  date,
  change,
  price,
}: {
  date: string
  change: number
  price: number
}) => {
  const { theme } = useTheme()

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        style={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
          display: 'grid',
          gap: '0 30px',
          gridTemplateColumns: '1fr 0.5fr 0.5fr 25px',
          height: ROW_HEIGHT,
          paddingLeft: theme.spacing(2),
          paddingRight: 20,
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        <div style={{ overflow: 'hidden' }}>
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.primary[60].toHexString(),
              marginBottom: 4,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
            }}
          >
            {date}
          </Typography>
        </div>

        <Typography
          variant="subtitle1"
          style={{
            alignItems: 'flex-end',
            color: changeColor(change),
            display: 'flex',
            justifyContent: 'flex-end',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          {change > 0 ? '+' : ''}
          {change}%
        </Typography>
        <div
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.secondary[100].toHexString(),
              fontWeight: 500,
            }}
          >
            £{price.toFixed(2)}
          </Typography>
        </div>
        <div style={{ alignItems: 'flex-end', display: 'flex' }}>
          <Icon
            iconName="chevronRight"
            style={{ marginLeft: 4, marginRight: -4 }}
          />
        </div>
      </div>
    </>
  )
}
