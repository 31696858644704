import { gql } from '@apollo/client'

export const createMenuMutation = gql`
  mutation createMenu($data: CreateOneMenuInputObject!, $kitchenId: Int!) {
    createOneMenu(data: $data, kitchenId: $kitchenId) {
      _cursor
      id
      name
      gpTarget
    }
  }
`
export const getDishTypesQuery = gql`
  query DishTypes {
    dishTypes {
      name
    }
  }
`

export const getSectionsQuery = gql`
  query Sections {
    sections {
      name
    }
  }
`
