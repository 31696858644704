import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { Query } from 'api'
import { DEFAULT_COUNTRY_CODE } from 'app'
import { countriesQuery } from 'screens/Join/graphql/query'
import { mapQueryCountryOptions } from 'utils/select'

export default function useQueryCountries() {
  return useQuery<{ countries: Query['countries'] }>(countriesQuery)
}

export function useQueryCountriesOptions({
  defaultValue,
}: { defaultValue?: string | null } = {}) {
  const result = useQueryCountries()
  const { data, loading } = result

  const { options, map } = useMemo(
    () => mapQueryCountryOptions(data?.countries),
    [data],
  )

  const defaultOption = useMemo(
    () => map[defaultValue || DEFAULT_COUNTRY_CODE],
    [defaultValue, map],
  )

  return {
    data,
    defaultOption,
    loading,
    map,
    options,
    result,
  }
}
