import { gql } from '@apollo/client'

export const supplierFragment = gql`
  fragment SupplierFields on Supplier {
    _cursor
    id
    emails
    name
    phone
    status
    productCount
    logoUrl
    deliveryDays
    tradingName
    website
    addressList(take: 100) {
      nodes {
        _cursor
        id
        address {
          _cursor
          id
          name
          line1
          city
          postCode
        }
      }
    }
    kitchenList(kitchenCursor: $kitchenCursor) {
      nodes {
        _cursor
        id
        emails
        accountReference
        notes
        contactName
        phone
      }
    }
    notes
  }
`
