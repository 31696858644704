import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  pendingInvoiceCount: number
  approvedInvoiceCount: number
  attentionInvoiceCount: number
}

const initialState: State = {
  approvedInvoiceCount: 0,
  attentionInvoiceCount: 0,
  pendingInvoiceCount: 0,
}

const invoiceSlice = createSlice({
  initialState,
  name: 'invoices',
  reducers: {
    setApprovedInvoiceCount(state, action: PayloadAction<number>) {
      state.approvedInvoiceCount = action.payload
    },
    setAttentionInvoiceCount(state, action: PayloadAction<number>) {
      state.attentionInvoiceCount = action.payload
    },
    setPendingInvoiceCount(state, action: PayloadAction<number>) {
      state.pendingInvoiceCount = action.payload
    },
  },
})

export const {
  setPendingInvoiceCount,
  setApprovedInvoiceCount,
  setAttentionInvoiceCount,
} = invoiceSlice.actions
export const reducer = invoiceSlice.reducer
