import { useMutation } from '@apollo/client'
import { ConfirmPhonePanel } from '@getjelly/jelly-ui'
import { ConfirmPhoneData } from '@getjelly/jelly-ui/dist/components/organisms/onboarding/ConfirmPhonePanel'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  Mutation,
  MutationSignupV2Args,
  MutationVerifySignUpPhoneArgs,
} from 'api'
import { routes } from 'routes/Paths'
import { VERIFY_SIGN_UP_PHONE_MUTATION } from 'screens/SignUpPhone/graphql'
import { setToken } from 'store/auth'

import { errorToast } from '../../components/toasts'
import { clearAuthFlow, selectAuthFlow } from '../../store/authFlow'
import { setKitchen } from '../../store/kitchen'
import { cleanErrorMessage } from '../../utils'
import { signUpMutation } from '../SignUp/mutation'

export function VerifyPhone() {
  const [lastSent, setLastSent] = useState(Date.now())
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<
    Partial<Record<keyof ConfirmPhoneData, string>> | undefined
  >(undefined)

  const { firstName, lastName, email, countryCode, phoneNumber, invite } =
    useSelector(selectAuthFlow())

  const [signup] = useMutation<
    { signupV2: Mutation['signupV2'] },
    MutationSignupV2Args
  >(signUpMutation)

  const [verifySignUpPhone] = useMutation<
    { verifySignUpPhone: Mutation['verifySignUpPhone'] },
    MutationVerifySignUpPhoneArgs
  >(VERIFY_SIGN_UP_PHONE_MUTATION)

  async function resendCode() {
    setErrors(undefined)

    if (Date.now() - lastSent < 15000) {
      setErrors({ code: 'Please wait 15 seconds before resending.' })
      return
    }

    if (
      !firstName ||
      !lastName ||
      !email ||
      !countryCode ||
      !phoneNumber ||
      !invite
    ) {
      errorToast('An unexpected error occurred. Missing sign up data.')
      return navigate(routes.SignUp)
    }

    setLastSent(Date.now())

    try {
      const { joinCode, inviteCode } = invite

      const { data } = await signup({
        variables: {
          countryCode,
          email,
          firstName,
          inviteCode,
          joinCode,
          lastName,
          phoneNumber,
        },
      })

      if (!data) {
        throw new Error('An unexpected error occurred. Missing data.')
      }
    } catch (e) {
      setErrors({ code: cleanErrorMessage((e as Error).message) })
    }
  }

  async function confirmCode({ code }: ConfirmPhoneData) {
    setErrors(undefined)

    if (!firstName || !lastName || !email || !countryCode || !phoneNumber) {
      setErrors({ code: 'An unexpected error occurred. Missing sign up data.' })
      return
    }

    try {
      const { data } = await verifySignUpPhone({
        variables: {
          countryCode,
          phoneNumber,
          pin: code,
        },
      })

      if (!data) {
        throw new Error('Unknown error occurred. Unexpected response from API.')
      }

      dispatch(setKitchen(undefined))
      dispatch(setToken(data.verifySignUpPhone.token))
      dispatch(clearAuthFlow())

      navigate(routes.SetPassword)
    } catch (e) {
      setErrors({ code: cleanErrorMessage((e as Error).message) })
    }
  }

  return (
    <ConfirmPhonePanel
      confirmCode={confirmCode}
      resendCodeClicked={resendCode}
      errors={errors}
      loginLinkClicked={() => navigate(routes.SignIn)}
    />
  )
}
