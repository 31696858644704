import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import { Product } from 'api'
import { RootState } from 'store'

type State = {
  product: Product | undefined
  search: string | undefined
}

const initialState: State = {
  product: undefined,
  search: undefined,
}

const productSlice = createSlice({
  initialState,
  name: 'product',
  reducers: {
    setProduct(state, action: PayloadAction<Product | undefined>) {
      state.product = action.payload
    },
    setProductSearch(state, action: PayloadAction<string | undefined>) {
      state.search = action.payload
    },
  },
})

export const { setProduct, setProductSearch } = productSlice.actions
export const reducer = productSlice.reducer

const selectState = (state: RootState) => state.product

export const selectProduct = () =>
  createSelector(selectState, (state) => state.product)

export const selectProductSearch = () =>
  createSelector(selectState, (state) => state.search)
