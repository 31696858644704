import { useQuery } from '@apollo/client'
import {
  IconCheck,
  IconChevronRight,
  IconCreditCard,
} from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

import { Query } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { NewLayout, Typography } from 'components/newUi'
import { routes } from 'routes/Paths'
import { useTheme } from 'styles/newUi'

import { getAvailablePlans } from './graphql'

import { Loader } from '../../../components'

export function Plans() {
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()
  const { theme } = useTheme()
  const navigate = useNavigate()

  const { data = { availablePlans: [] }, loading } =
    useQuery<{ availablePlans: Query['availablePlans'] }>(getAvailablePlans)

  refetchSelectedKitchen().catch((e) =>
    console.error('Failed to refetch selected kitchen.', e),
  )

  return (
    <>
      <NewLayout title="Plans" subtitle="Settings" />

      <div className="p-3 space-y-4">
        {loading && !data.availablePlans ? <Loader /> : <></>}

        {data.availablePlans.map((availablePlan) => {
          return (
            <div
              key={availablePlan.name}
              onClick={() =>
                navigate(
                  `${routes.Settings}${routes.Plans}/${availablePlan.tier}`,
                )
              }
              style={{
                backgroundColor: theme.palette.common.light.toHexString(),
                borderRadius: '0.5rem',
                boxShadow: theme.elevation[1],
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '2rem',
                }}
              >
                <div className="flex items-center space-x-2">
                  <IconCreditCard />

                  <Typography
                    variant="h2"
                    style={{
                      color: theme.palette.primary[100].toHexString(),
                      fontSize: '1rem',
                    }}
                  >
                    {availablePlan.name}
                  </Typography>
                </div>

                <div style={{ alignItems: 'center', display: 'flex' }}>
                  {availablePlan.tier === selectedKitchen?.permissions?.tier ? (
                    <IconCheck className="text-primary-800" />
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="flex justify-between space-x-3">
                <div className="space-y-2">
                  <Typography variant="body1" style={{ fontSize: '1rem' }}>
                    {`£${availablePlan.price} per site per month`}
                  </Typography>

                  <Typography variant="body2" style={{ fontSize: '1rem' }}>
                    {availablePlan.description}
                  </Typography>
                </div>

                <div className="flex items-center">
                  <IconChevronRight />
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div
        style={{
          marginBottom: theme.spacing(1),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }}
      ></div>

      <div
        style={{
          padding: theme.spacing(2),
          textAlign: 'center',
        }}
      >
        <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
          Need help with anything?
        </Typography>

        <Typography variant="subtitle1" style={{ marginTop: theme.spacing(1) }}>
          Contact{' '}
          <a
            href="mailto:letscook@getjelly.co.uk"
            style={{
              color: theme.palette.primary[100].toHexString(),
            }}
          >
            letscook@getjelly.co.uk
          </a>
        </Typography>
      </div>
    </>
  )
}
