import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import {
  Dish,
  Mutation,
  MutationCreateOneDishArgs,
  MutationCreateOneRecipeArgs,
  Recipe,
  RecipeToAllergen,
} from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { errorToast, successToast } from '../../../components/toasts'
import { buildCreateRoute } from '../../../routes/Paths'
import { cleanErrorMessage, logEvent } from '../../../utils'
import {
  transformIngredients,
  transformRecipes,
} from '../../Create/Ingredients'
import { createDishMutation } from '../graphql/dish'
import { createRecipeMutation } from '../graphql/recipes'

export function useDuplicator() {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const [createRecipe] = useMutation<
    { createOneRecipe: Mutation['createOneRecipe'] },
    MutationCreateOneRecipeArgs
  >(createRecipeMutation)

  const [createDish] = useMutation<
    { createOneDish: Mutation['createOneDish'] },
    MutationCreateOneDishArgs
  >(createDishMutation)

  const transformAllergens = (items: RecipeToAllergen[]) => {
    return items.map((link) => ({
      contains: link.contains,
      id: link.allergen.id,
      removable: link.removable,
    }))
  }

  function getNewName(name: string) {
    const date = new Date()

    return `${name} ${date.getFullYear()}/${date.getMonth()}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  }

  async function duplicateDish(dish: Dish) {
    try {
      const { data } = await createDish({
        variables: {
          allergens: transformAllergens(dish?.recipe.allergens),
          childRecipes: transformRecipes(dish?.recipe.childRecipes),
          data: {
            gpTarget: dish.gpTarget,
            hasNoAllergens: dish?.recipe.hasNoAllergens,
            imageUrl: dish.imageUrl,
            name: getNewName(dish?.recipe.name),
            overheads: dish.overheads,
            portion: dish.portion,
            price: dish.price,
            type: dish.type as string,
            vat: dish.vat,
          },
          ingredients: transformIngredients(dish?.recipe.ingredients),
          kitchenId: selectedKitchen!.id,
        },
      })

      if (!data?.createOneDish) {
        throw new Error('Unknown error occurred: Empty response.')
      }

      const { id, name } = data.createOneDish

      successToast(`Created ${name}`)
      navigate(buildCreateRoute(id, true), { replace: true })
    } catch (e) {
      errorToast(cleanErrorMessage((e as Error).message))
    }
  }

  async function duplicateRecipe(recipe: Recipe) {
    const { amount, instructions, section, unitCost, imageUrl, name } = recipe

    try {
      const { data } = await createRecipe({
        variables: {
          allergens: transformAllergens(recipe.allergens),
          childRecipes: transformRecipes(recipe.childRecipes),
          data: {
            amount,
            imageUrl,
            instructions,
            name: getNewName(name),
            section,
            unitCost: unitCost as number,
            unitId: recipe.unit?.id,
          },
          ingredients: transformIngredients(recipe.ingredients),
          kitchenId: selectedKitchen!.id,
        },
      })

      if (!data?.createOneRecipe) {
        throw new Error('Unknown error occurred: Empty response.')
      }

      const { id, name: newName } = data.createOneRecipe

      successToast(`Created ${newName}`)
      navigate(buildCreateRoute(id, false))
    } catch (e) {
      errorToast(cleanErrorMessage((e as Error).message))
    }
  }

  async function handleDuplicate(recipe: Recipe, dish?: Dish) {
    logEvent('tap_button', {
      event_category: dish ? 'view_dish' : 'view_recipe',
      event_label: dish ? 'duplicate_dish' : 'duplicate_recipe',
    })

    return dish ? await duplicateDish(dish) : await duplicateRecipe(recipe)
  }

  return { handleDuplicate }
}
