import { useRef, useEffect, useCallback } from 'react'

// This is basically just copy and pasted from ChatGPT.
export function useThrottleDebounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const lastArgsRef = useRef<Parameters<T> | null>(null)
  const throttledRef = useRef<boolean>(false)
  const lastCallRef = useRef<number>(0)

  const throttledDebouncedFunction = useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now()
      lastArgsRef.current = args

      if (!throttledRef.current || now - lastCallRef.current >= delay) {
        func(...args)
        lastCallRef.current = now
        throttledRef.current = true
        if (timeoutRef.current) clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(() => {
          throttledRef.current = false
          if (lastArgsRef.current) {
            func(...lastArgsRef.current)
            lastArgsRef.current = null
          }
        }, delay)
      } else {
        if (timeoutRef.current) clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(() => {
          func(...lastArgsRef.current!)
          lastArgsRef.current = null
        }, delay)
      }
    },
    [func, delay],
  )

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return throttledDebouncedFunction as T
}
