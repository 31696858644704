import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'store/store'

export type ReturnPath = { pathname: string; query: string } | null

export type TempState = {
  returnPath: ReturnPath
}

const initialState: TempState = {
  returnPath: null,
}

export const tempSlice = createSlice({
  initialState,
  name: 'temp',
  reducers: {
    setReturnPath(state, action: PayloadAction<ReturnPath>) {
      const returnPath = action.payload

      if (!returnPath) {
        state.returnPath = returnPath
        return
      }

      // Routes that you're not allowed to return to.
      const bannedRoutes = [
        /^\/set-password$/,
        /^\/set-profile-picture$/,
        /^\/set-job-roles$/,
        /^\/set-job-responsibilities$/,
        /^\/settings\/locations$/,
      ]

      // If any of the above match, don't set.
      if (bannedRoutes.some((route) => route.test(returnPath.pathname))) {
        return
      }

      state.returnPath = returnPath
    },
  },
})

const selectState = (state: RootState) => state.temp

export const { reducer: tempReducer } = tempSlice

export const { setReturnPath } = tempSlice.actions

export const selectReturnPath = () =>
  createSelector(selectState, (state) => state.returnPath)
