import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import dayjs from 'dayjs'
import { isEmpty, isNil, trim, indexBy, reject, find } from 'ramda'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import {
  KitchenToAddress,
  Query,
  QuerySupplierNodeArgs,
  Mutation,
  MutationCreateOnePurchaseOrderArgs,
  MutationUpdateOneUserArgs,
  PurchaseOrderEntryInput,
  PurchaseOrderSizeType,
  ProductWhereInput,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import {
  Button,
  HeaderSection,
  Icon,
  NewLayout,
  Scroller,
  Typography,
  AlertWithIcon,
} from 'components/newUi'
import { routes } from 'routes/Paths'
import { deleteOrder, selectPendingOrder } from 'screens/Order/store'
import { useTheme } from 'styles/newUi'
import {
  capitaliseEachWord,
  encodeCursor,
  dayMap,
  cleanErrorMessage,
  DeliveryDays,
} from 'utils'

import {
  getSupplierAndKitchenQuery,
  createPurchaseOrderMutation,
} from './graphql'

import { errorToast } from '../../../components/toasts'
import { getBasketProductsQuery } from '../graphql'

type Day = {
  title: string
  date: string
  dateTime: Date
}

const constructDeliveryDays = (deliveryDays?: DeliveryDays | null) => {
  const constructor = dayjs()
  const days: Day[] = []

  if (!deliveryDays) {
    const today = {
      date: constructor.format('D MMM'),
      dateTime: constructor.toDate(),
      title: 'today',
    }
    days.push(today)

    const tomorrow = {
      date: constructor.add(1, 'day').format('D MMM'),
      dateTime: constructor.add(1, 'day').toDate(),
      title: 'tomorrow',
    }
    days.push(tomorrow)

    for (let i = 0; i < 12; i++) {
      const daySet = constructor.add(i + 2, 'day')
      const day = daySet.day() === 0 ? 7 : daySet.day()
      days.push({
        date: daySet.format('D MMM'),
        dateTime: daySet.toDate(),
        title: dayMap[day % 7],
      })
    }

    return days
  }

  if (
    // @ts-ignore
    deliveryDays[dayMap[constructor.day()]]?.deliver &&
    // @ts-ignore
    deliveryDays[dayMap[constructor.day()]]?.deliver === true
  ) {
    const today = {
      date: constructor.format('D MMM'),
      dateTime: constructor.toDate(),
      title: 'today',
    }
    days.push(today)
  }
  if (
    // @ts-ignore
    deliveryDays[dayMap[constructor.add(1, 'day').day()]]?.deliver &&
    // @ts-ignore
    deliveryDays[dayMap[constructor.add(1, 'day').day()]]?.deliver === true
  ) {
    const tomorrow = {
      date: constructor.add(1, 'day').format('D MMM'),
      dateTime: constructor.add(1, 'day').toDate(),
      title: 'tomorrow',
    }
    days.push(tomorrow)
  }

  for (let i = 0; i < 12; i++) {
    const daySet = constructor.add(i + 2, 'day')
    const day = daySet.day() === 0 ? 7 : daySet.day()
    if (
      // @ts-ignore
      deliveryDays[dayMap[day]]?.deliver &&
      // @ts-ignore
      deliveryDays[dayMap[day]]?.deliver === true
    ) {
      days.push({
        date: daySet.format('D MMM'),
        dateTime: daySet.toDate(),
        title: dayMap[day],
      })
    }
  }

  return days
}

export const Checkout = () => {
  const client = useApolloClient()
  const dispatch = useDispatch()
  const [deliveryDay, setDeliveryDay] = useState(1)
  const [deliveryAddress, setDeliveryAddress] = useState<string | undefined>()
  const [deliveryAddresses, setDeliveryAddresses] = useState<
    KitchenToAddress[]
  >([])
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()

  useEffect(() => {
    // @ts-ignore
    const checkoutState = state?.checkoutState
    if (checkoutState) {
      setDeliveryDay(checkoutState?.deliveryDay)
      setDeliveryAddress(checkoutState?.deliveryAddress)
      navigate(pathname, {
        replace: true,
        state: {},
      })
    }
  }, [])

  const { id } = useParams()
  const skip = isNil(selectedKitchen?._cursor)
    ? isNil(selectedKitchen?.id)
      ? true
      : false
    : false
  const kitchenCursor = selectedKitchen?._cursor
    ? selectedKitchen?._cursor
    : selectedKitchen?.id
    ? encodeCursor('Kitchen', selectedKitchen?.id)
    : undefined

  const pendingOrderData = useSelector(
    selectPendingOrder(`${selectedKitchen?.id}:${id}`),
  )
  const { data, loading } = useQuery<
    {
      kitchenNode: Query['kitchenNode']
      lastOrderByUserNode: Query['lastOrderByUserNode']
      supplierNode: Query['supplierNode']
      me: Query['me']
    },
    QuerySupplierNodeArgs & { kitchenCursor?: string }
  >(getSupplierAndKitchenQuery, {
    fetchPolicy: 'no-cache',
    skip,
    variables: {
      cursor: encodeCursor('Supplier', parseInt(id)),
      kitchenCursor,
    },
  })

  // useEffect(() => {}, [data])

  const productIds = Object.keys(pendingOrderData?.products || {})
  const { data: productData } = useQuery<
    {
      products: Query['products']
    },
    {
      where: ProductWhereInput
      kitchenId: number
    }
  >(getBasketProductsQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !selectedKitchen?.id || !id || !productIds.length,
    variables: {
      kitchenId: selectedKitchen?.id ?? -1,
      where: {
        OR: productIds.map((id) => ({ id: { equals: parseInt(id) } })),
      },
    },
  })

  const [createOrder, { loading: submitLoading }] = useMutation<
    {
      createOnePurchaseOrder: Mutation['createOnePurchaseOrder']
      updateOneUser: Mutation['updateOneUser']
    },
    MutationCreateOnePurchaseOrderArgs | MutationUpdateOneUserArgs
  >(createPurchaseOrderMutation)

  const { theme } = useTheme()

  const supplier = data?.supplierNode
  const kitchen = data?.kitchenNode
  const me = data?.me
  const lastOrderAddressCursor = data?.lastOrderByUserNode?.address?._cursor

  useEffect(() => {
    const addressList = data?.kitchenNode?.deliveryAddressList?.nodes ?? []

    if (isNil(deliveryAddress)) {
      // use last order address is available
      if (lastOrderAddressCursor) {
        setDeliveryAddress(lastOrderAddressCursor)
        // if only one address available, automatically select it
      } else if (addressList.length === 1) {
        setDeliveryAddress(addressList[0].address._cursor)
      }
    }

    if (isNil(lastOrderAddressCursor)) {
      return setDeliveryAddresses([
        ...addressList,
        { _cursor: 'CREATE' } as KitchenToAddress,
      ])
    }

    setDeliveryAddresses([
      // @ts-ignore
      find(
        (address) => address?.address?._cursor === lastOrderAddressCursor,
        addressList,
      ),
      ...reject(
        (address: KitchenToAddress) =>
          address?.address._cursor === lastOrderAddressCursor,
        addressList,
      ),
      { _cursor: 'CREATE' } as KitchenToAddress,
    ])
  }, [lastOrderAddressCursor, deliveryAddress, data])

  const kitchenInfo = supplier?.kitchenList.nodes[0]

  const validSupplierEmail = !![
    ...(kitchenInfo?.emails ?? []),
    ...(supplier?.emails ?? []),
  ].find((email) => !!email)
  const deliveryDays = constructDeliveryDays(supplier?.deliveryDays)
  const indexedProductsData = indexBy(
    (product) => product.id,
    productData?.products ?? [],
  )
  const submitOrder = async () => {
    const productsPending = pendingOrderData?.products ?? []
    const items: PurchaseOrderEntryInput[] = []
    Object.keys(productsPending).forEach((key) => {
      const pendingProduct = productsPending[key]
      const product = indexedProductsData[parseInt(key)]
      const sizeType = () => {
        switch (pendingProduct.sizeType) {
          case 0:
            return PurchaseOrderSizeType.Default
          case 1:
            return PurchaseOrderSizeType.Box
          case 2:
            return PurchaseOrderSizeType.Unit
          default:
            return PurchaseOrderSizeType.Default
        }
      }
      if ((pendingProduct?.quantity ?? 0) > 0) {
        items.push({
          code: product.code ?? '',
          id: product.id,
          packSize: product.packSize,
          price: (product?.ingredients[0]?.price as number) ?? null,
          quantity: pendingProduct.quantity as number,
          sizeType: sizeType(),
          unitId: product.unit.id,
          unitValue: product.unitValue,
        })
      }
    })
    const notes = trim(pendingOrderData.comments)
    const address = deliveryAddresses.find(
      (a) => a?.address?._cursor === deliveryAddress,
    )?.address
    if (!kitchen || !me || !supplier || !deliveryAddress || !address) return

    await createOrder({
      variables: {
        addressName: address.name,
        data: {
          email: me.email,
          id: me.id,
        },
        deliveryAddress: [address.line1, address.city, address.postCode].join(
          ', ',
        ),
        deliveryDate: deliveryDays[deliveryDay].dateTime,
        items,
        kitchenAddressCursor: deliveryAddress,
        kitchenId: selectedKitchen?.id as number,
        notes: !isEmpty(notes) ? notes : undefined,
        siteName: kitchen.name as string,
        supplierEmail: (!isNil(kitchenInfo?.emails) &&
        !isEmpty(kitchenInfo?.emails)
          ? kitchenInfo?.emails
          : supplier?.emails
        )?.reduce((acc, cur) => `${acc}${cur};`, ''),
        supplierId: parseInt(id),
      },
    })
      .then(() => {
        dispatch(deleteOrder(`${selectedKitchen?.id}:${id}`))

        client.cache.evict({
          fieldName: 'fuzzySearchProductList',
          id: 'ROOT_QUERY',
        })
        client.cache.gc()

        navigate(routes.Order, {
          state: {
            orderPlaced: {
              supplierName: supplier.name,
            },
          },
        })
      })
      .catch((error) => {
        if (error.message.includes('Unique constraint')) {
          errorToast(
            'Email is already in use with another account, please use a different email address',
          )
        } else {
          errorToast(cleanErrorMessage(error.message), {
            autoClose: false,
            closeOnClick: true,
            draggable: false,
            hideProgressBar: true,
            onClick: () => navigate(`${routes.Settings}${routes.Me}`),
            pauseOnHover: false,
            progress: undefined,
          })
        }
      })
  }

  const today = dayjs()
  const cutoff =
    supplier?.deliveryDays &&
    supplier?.deliveryDays[today.format('ddd').toLowerCase()]?.cutoff
  const cutOffDay =
    cutoff &&
    dayjs()
      .hour(parseInt(cutoff.slice(0, 2)))
      .minute(parseInt(cutoff.slice(3, 5)))
  const afterCutOff = cutoff && today.isAfter(cutOffDay)

  const cutoff2 =
    supplier?.deliveryDays &&
    supplier?.deliveryDays[today.format('ddd').toLowerCase()]?.cutoff2
  const cutOff2Day =
    cutoff2 && dayjs().hour(cutoff2.slice(0, 2)).minute(cutoff2.slice(3, 5))
  const afterCutOff2 = cutoff2 && today.isAfter(cutOff2Day)

  return (
    <div>
      <NewLayout
        hideMenu
        subtitle={capitaliseEachWord(supplier?.name.toLowerCase())}
        title="Confirm Order"
        bottomContent={
          <div
            style={{
              backgroundColor: theme.palette.secondary[100].toHexString(),
              display: 'flex',
              justifyContent: 'center',
              padding: theme.spacing(2),
            }}
          >
            <Button
              data-testid="placeOrderButton"
              disabled={
                !deliveryAddress ||
                !kitchen ||
                !supplier ||
                !validSupplierEmail ||
                submitLoading
              }
              loading={submitLoading}
              onClick={() => submitOrder()}
              text="Place Order"
            />
          </div>
        }
      />
      {loading && isNil(supplier) && isNil(kitchen) ? (
        <div style={{ height: 'calc(100vh - 64px)' }}>
          <Loader />
        </div>
      ) : (
        <>
          {!validSupplierEmail && (
            <AlertWithIcon
              style={{ cursor: 'pointer' }}
              hide={false}
              type="error"
              iconName="warning"
              onClick={() => {
                navigate(pathname, {
                  replace: true,
                  state: {
                    checkoutState: {
                      deliveryAddress,
                      deliveryDay,
                    },
                  },
                })
                navigate(
                  `${routes.Order}${routes.Supplier}${routes.Edit}/${supplier?._cursor}`,
                )
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{ color: theme.palette.error[100].toHexString() }}
                >
                  Add the supplier&apos;s email address to place this order
                </Typography>
              </div>
              <Icon
                iconName="chevronRight"
                style={{ color: theme.palette.error[100].toHexString() }}
              />
            </AlertWithIcon>
          )}
          <Scroller title="When would you like this order?">
            {deliveryDays.map((day, index) => (
              // eslint-disable-next-line
              <div
                onClick={() => setDeliveryDay(index)}
                key={day.date}
                style={{
                  alignItems: 'center',
                  backgroundColor:
                    deliveryDay === index
                      ? theme.palette.secondary[100].toHexString()
                      : 'white',
                  borderRadius: 4,
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  height: 78,
                  justifyContent: 'center',
                  minWidth: 140,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    color:
                      deliveryDay === index
                        ? 'white'
                        : theme.palette.primary[60].toHexString(),
                    fontWeight: 500,
                    marginBottom: theme.spacing(1),
                    textTransform: 'capitalize',
                  }}
                >
                  {day.title}
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    color:
                      deliveryDay === index
                        ? 'white'
                        : theme.palette.primary[80].toHexString(),
                    fontWeight: 500,
                  }}
                >
                  {day.date}
                </Typography>
              </div>
            ))}
          </Scroller>
          {((deliveryDay === 0 && cutoff && afterCutOff) ||
            (deliveryDay === 1 && cutoff2 && afterCutOff2)) && (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
                textAlign: 'center',
              }}
            >
              <Typography
                variant="caption"
                style={{ fontWeight: 500, marginBottom: theme.spacing(1) }}
              >
                {capitaliseEachWord(supplier?.name)} may not be able to deliver
                this order{' '}
                {deliveryDay === 0 && cutoff && afterCutOff
                  ? 'today'
                  : 'tomorrow'}
                .
              </Typography>
              <a
                href={`tel:${kitchenInfo?.phone}`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  color="white"
                  style={{
                    border: `2px solid ${theme.palette.primary[100].toHexString()}`,
                    fontSize: 14,
                  }}
                  rightAdornment={
                    <Icon
                      iconName="phone"
                      style={{
                        marginLeft: theme.spacing(1),
                        marginRight: -theme.spacing(1),
                      }}
                    />
                  }
                >
                  <div>
                    {kitchenInfo?.contactName ? (
                      <Typography variant="button">{`Call ${
                        kitchenInfo?.contactName
                      } @ ${capitaliseEachWord(supplier?.name)}`}</Typography>
                    ) : (
                      <Typography variant="button">{`Call ${capitaliseEachWord(
                        supplier?.name,
                      )}`}</Typography>
                    )}
                  </div>
                </Button>
              </a>
            </div>
          )}
          <Scroller title="Where would you like this order?">
            {deliveryAddresses?.map((address) => {
              if (!address) return '' //edge case
              if (address._cursor === 'CREATE') {
                return (
                  // eslint-disable-next-line
                  <div
                    onClick={() => {
                      navigate(routes.Settings + routes.Addresses)
                    }}
                    key={'CREATE'}
                    style={{
                      alignItems: 'center',
                      backgroundColor: 'white',
                      borderRadius: 4,
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      height: 158,
                      justifyContent: 'center',
                      margin: `${theme.spacing(2)}px 0`,
                      padding: theme.spacing(3),
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: theme.palette.secondary[100].toHexString(),
                        fontWeight: 500,
                      }}
                    >
                      Manage Your Addresses
                    </Typography>
                  </div>
                )
              }
              return (
                // eslint-disable-next-line
                <div
                  onClick={() => {
                    setDeliveryAddress(address.address._cursor)
                  }}
                  key={address.address._cursor}
                  style={{
                    alignItems: 'flex-start',
                    backgroundColor:
                      deliveryAddress === address.address._cursor
                        ? theme.palette.secondary[100].toHexString()
                        : 'white',
                    borderRadius: 4,
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 158,
                    justifyContent: 'space-between',
                    margin: `${theme.spacing(2)}px ${theme.spacing(
                      1,
                    )}px ${theme.spacing(2)}px 0`,
                    maxWidth: 240,
                    minWidth: 240,
                    padding: theme.spacing(2),
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      color:
                        deliveryAddress === address.address._cursor
                          ? 'white'
                          : theme.palette.primary[100].toHexString(),
                      display: '-webkit-box',
                      fontWeight: 500,
                      overflow: 'hidden',
                    }}
                  >
                    {address.address.name}
                  </Typography>
                  <div>
                    <Typography
                      variant="body1"
                      style={{
                        color:
                          deliveryAddress === address.address._cursor
                            ? 'white'
                            : theme.palette.primary[80].toHexString(),
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {address.address.line1}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color:
                          deliveryAddress === address.address._cursor
                            ? 'white'
                            : theme.palette.primary[60].toHexString(),
                        fontWeight: 400,
                      }}
                    >
                      {address.address.city}, {address.address.postCode}
                    </Typography>
                  </div>
                  <Typography
                    variant="body2"
                    style={{
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      color:
                        deliveryAddress === address.address._cursor
                          ? 'white'
                          : theme.palette.primary[60].toHexString(),
                      display: '-webkit-box',
                      overflow: 'hidden',
                    }}
                  >
                    {address.address.deliveryInstructions}
                  </Typography>
                </div>
              )
            })}
          </Scroller>
          <HeaderSection
            title="Sending order to"
            action={
              <div
                style={{
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                {!isNil(kitchenInfo?.emails) && !isEmpty(kitchenInfo?.emails)
                  ? kitchenInfo?.emails?.map((email) => (
                      <Typography
                        variant="body2"
                        key={email}
                        style={{ padding: `${theme.spacing(0.25)}px 0` }}
                      >
                        {email}
                      </Typography>
                    ))
                  : supplier?.emails?.map((email) => (
                      <Typography
                        variant="body2"
                        key={email}
                        style={{ padding: `${theme.spacing(0.25)}px 0` }}
                      >
                        {email}
                      </Typography>
                    ))}
              </div>
            }
          />
          {kitchenInfo?.accountReference && (
            <div style={{ borderTop: '1px solid white' }}>
              <HeaderSection
                title="Account reference"
                action={
                  <Typography variant="body2">
                    {kitchenInfo.accountReference}
                  </Typography>
                }
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              text="Edit Account info"
              noFill
              onClick={() => {
                navigate(pathname, {
                  replace: true,
                  state: {
                    checkoutState: {
                      deliveryAddress,
                      deliveryDay,
                    },
                  },
                })
                navigate(
                  `${routes.Order}${routes.Supplier}${routes.Edit}/${supplier?._cursor}`,
                )
              }}
              style={{
                backgroundColor: 'unset',
                border: 'none',
                color: theme.palette.secondary[100].toHexString(),
                marginTop: theme.spacing(2),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
              }}
              rightAdornment={
                <Icon
                  iconName="chevronRight"
                  style={{ marginLeft: 4, marginRight: -4 }}
                />
              }
            />
          </div>
        </>
      )}
    </div>
  )
}
