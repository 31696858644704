import { gql } from '@apollo/client'

export const getUnitsQuery = gql`
  query getUnitsQuery {
    units {
      _cursor
      id
      name
      type
    }
  }
`
export const getSectionsQuery = gql`
  query sections {
    sections {
      name
    }
  }
`

export const getDishTypesQuery = gql`
  query dishTypes {
    dishTypes {
      name
    }
  }
`
