import { useState } from 'react'

import AddIcon from 'assets/ios-add-icon.png'
import ArrowIcon from 'assets/ios-arrow-icon.png'
import DeleteIcon from 'assets/ios-delete-icon.png'
import ShareIcon from 'assets/ios-share-icon.png'
import { isInStandaloneMode, isIos, logEvent } from 'utils'

export const IOSInstallBanner = () => {
  const [showInstall, setShowInstall] = useState(false)

  const dismissedInstallBanner = window.sessionStorage.getItem(
    'dismissedInstallBanner',
  )

  const handleClose = () => {
    window.sessionStorage.setItem('dismissedInstallBanner', 'true')
    setShowInstall(false)
    logEvent('banner', {
      event_category: 'install_app',
      event_label: 'dismiss',
    })
  }

  if (
    !showInstall &&
    !dismissedInstallBanner &&
    isIos() &&
    !isInStandaloneMode()
  ) {
    setShowInstall(true)
    logEvent('banner', {
      event_category: 'install_app',
      event_label: 'show',
    })
  }

  if (!showInstall) return null

  return (
    <div
      style={{
        alignItems: 'center',
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 10,
        position: 'fixed',
        zIndex: 10000,
      }}
    >
      <div
        style={{
          backgroundColor: '#edeef2',
          borderRadius: 5,
          fontFamily: 'sans-serif',
          padding: 10,
          paddingRight: 25,
          textAlign: 'center',
        }}
      >
        Install Jelly on your phone: in the menu below tap{' '}
        <img
          src={ShareIcon}
          alt="share icon"
          style={{ height: 20, verticalAlign: 'sub', width: 20 }}
        />{' '}
        and then{' '}
        <span style={{ color: '#0A84FF', whiteSpace: 'nowrap' }}>
          Add to Home Screen{' '}
          <img
            src={AddIcon}
            alt="share icon"
            style={{
              height: 20,
              verticalAlign: 'sub',
              width: 20,
            }}
          />
        </span>
        <div style={{ width: 'calc(100% + 15px)' }}>
          <img
            src={ArrowIcon}
            alt="arrow icon"
            style={{ height: 20, verticalAlign: 'sub', width: 20 }}
          />
        </div>
        <button
          onClick={handleClose}
          style={{
            background: 'none',
            border: 'none',
            height: 20,
            padding: 0,
            position: 'absolute',
            right: 5,
            top: 5,
            width: 20,
          }}
        >
          <img
            src={DeleteIcon}
            alt="share icon"
            style={{
              height: 20,
              width: 20,
            }}
          />
        </button>
      </div>
      <div
        style={{
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderTop: '10px solid #edeef2',
          height: 0,
          width: 0,
        }}
      />
    </div>
  )
}
