import { gql } from '@apollo/client'

export * from './signup'

export const SIGN_UP_PHONE_MUTATION = gql`
  mutation SignUpPhone($countryCode: String!, $phoneNumber: String!) {
    signUpPhone(countryCode: $countryCode, phoneNumber: $phoneNumber) {
      expiresAt
    }
  }
`

export const VERIFY_SIGN_UP_PHONE_MUTATION = gql`
  mutation VerifySignUpPhone(
    $countryCode: String!
    $phoneNumber: String!
    $pin: String!
  ) {
    verifySignUpPhone(
      countryCode: $countryCode
      phoneNumber: $phoneNumber
      pin: $pin
    ) {
      token
    }
  }
`

export const COMPLETE_SIGN_UP_PHONE_MUTATION = gql`
  mutation CompleteSignUpPhone(
    $firstName: String
    $lastName: String
    $kitchenName: String
    $email: String
    $password: String
  ) {
    completeSignUpPhone(
      firstName: $firstName
      lastName: $lastName
      kitchenName: $kitchenName
      email: $email
      password: $password
    )
  }
`
