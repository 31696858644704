import { useDispatch, useSelector } from 'react-redux'

import {
  selectCurrentVersion,
  selectLoading,
  selectUpdateRequired,
  setCurrentVersion,
  setLoading,
  setUpdateRequired,
} from 'store/currentVersion'

import { api } from '../api/rest'

const VERSION_REGEXP = /^\d+(\.\d+)*$/

async function fetchLatestVersion(): Promise<string> {
  const response = await api.get<{ current_version: string }>(
    `/rest/public/app-version`,
  )
  const currentVersion = response?.data.current_version

  if (currentVersion && VERSION_REGEXP.test(currentVersion)) {
    return currentVersion
  }

  throw new Error(`Version "${currentVersion}" didn't match regex.`)
}

/**
 * Compares two versions and returns a numerical value indicating their order.
 * Returns 1 if v1 is greater than v2, -1 if v1 is less than v2, and 0 if both
 * versions are equal.
 */
function compareVersions(v1: string, v2: string): number {
  const parts1 = v1.split('.').map(Number)
  const parts2 = v2.split('.').map(Number)

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const a = parts1[i] ?? 0
    const b = parts2[i] ?? 0
    if (a > b) return 1
    if (b > a) return -1
  }

  return 0
}

export function useVersionChecker() {
  const loading = useSelector(selectLoading)
  const currentVersion = useSelector(selectCurrentVersion)
  const updateRequired = useSelector(selectUpdateRequired)
  const dispatch = useDispatch()

  function doUpdate() {
    if (loading) {
      return
    }

    dispatch(setLoading(true))

    setTimeout(() => {
      dispatch(setCurrentVersion())
      localStorage.setItem('updateSuccess', 'true')
      dispatch(setLoading(false))
      window.location.reload()
    }, 500)
  }

  async function checkVersion() {
    if (loading) return
    dispatch(setLoading(true))

    try {
      const version = await fetchLatestVersion()

      if (!currentVersion) {
        dispatch(setCurrentVersion(version))
      } else if (compareVersions(version, currentVersion) === 1) {
        dispatch(setUpdateRequired(true))
      }
    } catch (e) {
      console.error('Unable to fetch latest version.', e)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return { checkVersion, doUpdate, updateRequired }
}
