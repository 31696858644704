import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

export const StyledLoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

export const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.secondary[100].toHexString()};
`
