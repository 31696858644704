import dayjs from 'dayjs'
import { groupBy } from 'ramda'

import {
  PendingInvoiceBaseFragment,
  PendingInvoiceGroupedConnection,
} from 'api'
import { DATE_FORMATS } from 'app'
import { dateDiff, getPendingInvoiceDate } from 'utils'

const currentYear = dayjs().year()

function getInvoiceByDateGroup(pi: PendingInvoiceBaseFragment) {
  const date = getPendingInvoiceDate(pi)
  if (dayjs(date).year() === currentYear) {
    return dayjs(date).format(DATE_FORMATS.DATE_SIMPLE_SHORT)
  }
  return dayjs(date).format(DATE_FORMATS.DATE_SIMPLE)
}

export function groupInvoices(
  pendingInvoices: PendingInvoiceGroupedConnection,
) {
  const sorted = pendingInvoices.nodes.sort((a, b) =>
    dateDiff(getPendingInvoiceDate(b), getPendingInvoiceDate(a)),
  )

  // group invoices by date
  const invoicesNotInProcessingGroupedByDate = groupBy(
    getInvoiceByDateGroup,
    sorted,
  )

  return Object.entries(invoicesNotInProcessingGroupedByDate).map(
    ([label, items]) => ({ items, label }),
  )
}
