import { IconChevronRight } from '@tabler/icons-react'

export function UnapprovedInvoiceSkeleton() {
  return (
    <div className="h-[113px] w-full px-2 border-b border-primary-50 flex justify-between items-center">
      <div className="space-y-1">
        <div className="rounded h-4 w-32 bg-primary-200" />
        <div className="rounded h-3 w-24 bg-primary-100" />
        <div className="rounded h-3 w-32 bg-primary-50" />
      </div>

      <div className="space-y-1 flex space-x-1">
        <div className="rounded-full bg-primary-100 h-10 w-24" />

        <div className="flex items-center">
          <IconChevronRight className="text-primary-100" />
        </div>
      </div>
    </div>
  )
}
