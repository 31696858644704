import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { forEach, keys, omit, pickBy } from 'ramda'

import { RootState } from 'store'

import { data, Data } from './data'

type State = {
  dismissed: {
    [key: string]: boolean
  }
  remindMe: {
    [key: string]: Date
  }
  expires: {
    [key: string]: {
      [key: string]: Date
    }
  }
}

const initialState: State = {
  dismissed: {},
  expires: {},
  remindMe: {},
}

const marketingSlice = createSlice({
  initialState,
  name: 'marketing',
  reducers: {
    cleanState(state) {
      const now = dayjs()
      const expiredItems: string[] = []
      const expiredKeys: string[] = []

      Object.keys(state.expires).forEach((key) => {
        const itemDate = dayjs(key)
        if (now.isAfter(itemDate)) {
          const item = state.expires[key]
          const ids = Object.keys(item)
          expiredItems.push(...ids)
          expiredKeys.push(key)
        }
      })
      state.remindMe = { ...omit(expiredItems, state.remindMe) }
      state.dismissed = { ...omit(expiredItems, state.dismissed) }
      state.expires = { ...omit(expiredKeys, state.expires) }
    },
    setDismissed(state, action: PayloadAction<string>) {
      const item = data[action.payload as keyof Data]

      state.dismissed[action.payload] = true

      const expiry = dayjs(item.endDate)
      const expiryKey = expiry.format('YYYY-MM-DD')
      const expiryDate = expiry.toDate()

      state.expires[expiryKey] = {
        ...state.expires[expiryKey],
        [item.id]: expiryDate,
      }
    },
    setRemindMe(state, action: PayloadAction<string>) {
      const item = data[action.payload as keyof Data]

      state.remindMe[action.payload] = dayjs()
        .startOf('day')
        .add(1, 'day')
        .toDate()

      const expiry = dayjs(item.endDate)
      const expiryKey = expiry.format('YYYY-MM-DD')
      const expiryDate = expiry.toDate()

      state.expires[expiryKey] = {
        ...(state.expires[expiryKey] ?? {}),
        [item.id]: expiryDate,
      }
    },
  },
})

export const { setDismissed, setRemindMe, cleanState } = marketingSlice.actions
export const reducer = marketingSlice.reducer

const selectState = (state: RootState) => state.marketing

export const selectDismissed = createSelector(
  selectState,
  (state) => state.dismissed,
)

export const selectDismissedItem = (id: string) =>
  createSelector(selectDismissed, (state) => state[id])

export const selectRemind = createSelector(
  selectState,
  (state) => state.remindMe,
)

export const selectRemindItem = (id: string) =>
  createSelector(selectRemind, (state) => state[id])

export const selectValidItem = (data: Data) =>
  createSelector(selectState, (state) => {
    const now = dayjs()
    const dateNow = now.toDate()
    const validItems: Data = pickBy(
      (val) => val.startDate <= dateNow && dateNow <= val.endDate,
      data,
    )

    const items: (Data[keyof Data] & { remindMe?: boolean })[] = []

    forEach((key) => {
      const item = validItems[key]
      const dismissed = state.dismissed[key as keyof Data]
      // const remindMe = dayjs(state.remindMe[key as keyof Data]).isAfter(now)
      const remindMe = Boolean(state.remindMe[key as keyof Data])

      if (!dismissed) {
        if (remindMe) {
          items.push({ ...item, remindMe: true })
        } else {
          items.push(item)
        }
      }
    }, keys(validItems))

    return items[0]
  })
