import { Button } from '@getjelly/jelly-ui'

import { Typography } from 'components/newUi'
import { InvoiceCodeButton } from 'screens/Spending/Invoices/components/InvoiceCodeButton'
import { shareInvoiceCodeHandler } from 'screens/Spending/Invoices/components/shareCodeHandler'

import { useKitchen } from '../../../../../app/contexts/SelectedKitchen'

interface AutomaticAddGuideProps {
  invoiceCode: string
}

export function AutomaticAddGuide({ invoiceCode }: AutomaticAddGuideProps) {
  const { selectedKitchen } = useKitchen()

  return (
    <div className="space-y-4 text-center">
      <div className="px-4">
        <Typography style={{ fontSize: 18 }} className="space-y-4" variant="h5">
          <p>
            <span style={{ fontWeight: 500 }}>NEW:</span> automate your invoice
            uploading with 2 simple steps. No more taking photos.
          </p>

          <p>
            <span style={{ fontWeight: 500 }}>Step 1:</span> Ask and ensure all
            your suppliers send invoices to this unique email address.
          </p>

          <p>
            <span style={{ fontWeight: 500 }}>Step 2:</span> Jelly automatically
            receives and uploads these invoices for you.
          </p>

          <p>
            <span className="w-full flex justify-center font-semibold">
              * PDF files only
            </span>
          </p>
        </Typography>
      </div>

      <InvoiceCodeButton invoiceCode={invoiceCode} />

      {selectedKitchen?.userPermissions?.includes('create-pending-invoice') && (
        <Button
          onClick={() => shareInvoiceCodeHandler(invoiceCode)}
          label="Inform"
          className="w-full"
        />
      )}
    </div>
  )
}
