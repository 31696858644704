import { Outlet } from 'react-router-dom'

export function AuthLayout() {
  return (
    <div className="min-h-full bg-primary-900 p-4 flex justify-center items-center">
      <div className="max-w-96 w-full">
        <Outlet />
      </div>
    </div>
  )
}
