import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@getjelly/jelly-ui'
import { useState } from 'react'

import { useKitchen } from 'app/contexts/SelectedKitchen'
import {
  NewLayout,
  Typography,
  AlertWithIcon,
  NewDropdown,
  NewTextField,
} from 'components/newUi'
import { routes } from 'routes/Paths'
import { useTheme } from 'styles/newUi'

import { BlockSelect } from './components/BlockSelect'
import { BlockSelectContainer } from './components/BlockSelectContainer'
import { createKitchenInvite, getRoles } from './graphql'

import {
  CreateKitchenInviteInput,
  MutationCreateKitchenInviteArgs,
  Query,
  QueryKitchenToUserArgs,
} from '../../../api'
import { KitchenChange, Loader } from '../../../components'
import { useQueryCountriesOptions } from '../../../hooks/options/useQueryCountries'

export const AddTeam = () => {
  const { selectedKitchen } = useKitchen()
  const { theme } = useTheme()

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [roleId, setRoleId] = useState(2)
  const [phoneNumberNational, setPhoneNumberNational] = useState('')

  const [countryCode, setCountryCode] = useState<{
    label: string
    value: string | number
  }>({ label: '+44 GB', value: '+44' })

  const { data, loading } = useQuery<
    {
      kitchenToUser: Query['kitchenToUser']
      roles: Query['roles']
    },
    QueryKitchenToUserArgs
  >(getRoles)

  const {
    options: countryOptions,
    defaultOption: defaultCountryOption,
    loading: optionsLoading,
  } = useQueryCountriesOptions()

  const [saveKitchenInvite, { loading: saving }] = useMutation<
    MutationCreateKitchenInviteArgs,
    { data: CreateKitchenInviteInput }
  >(createKitchenInvite)

  function getCountryCode(countryCode: string | number): string {
    countryCode = countryCode.toString()

    if (countryCode[0] === '+') {
      return countryCode.slice(1)
    }

    return countryCode
  }

  async function onClick() {
    if (!selectedKitchen || saving) {
      return
    }

    if (!phoneNumberNational) {
      setError('Phone Number is required.')
      return
    }

    if (!getCountryCode(countryCode.value)) {
      setError('Country Code is required.')
      return
    }

    const userRoleId = selectedKitchen.kitchenToCurrentUser.roleId

    if (roleId < userRoleId) {
      setError("Your role can't invite users with higher roles.")
      return
    }

    try {
      await saveKitchenInvite({
        variables: {
          data: {
            countryCode: getCountryCode(countryCode.value),
            kitchenId: selectedKitchen.id,
            phoneNumberNational,
            roleId,
          },
        },
      })

      setSuccess('Invite has been sent via text message.')
    } catch (e) {
      console.error(e)
      setError('Unable to send kitchen invite. Please contact support.')
    }
  }

  return (
    <>
      <NewLayout
        title="Add members"
        subtitle="Team"
        bottomContent={
          <div className="flex justify-center w-full h-full bg-gray-100 px-2 py-4">
            <Button onClick={onClick} label="Send Text" className="w-full" />
          </div>
        }
      />

      <AlertWithIcon hide={!success} type="success" iconName="notifications">
        <Typography variant="body1">{success}</Typography>
      </AlertWithIcon>

      <AlertWithIcon hide={!error} type="error" iconName="warning">
        <Typography variant="body1">{error}</Typography>
      </AlertWithIcon>

      <div className="flex flex-col items-center space-y-8 py-8 p-4">
        <KitchenChange redirect={routes.Settings + routes.Team + routes.Add} />

        <div className="flex flex-col items-center">
          <Typography
            variant="body1"
            style={{
              color: theme.palette.primary[80].toHexString(),
            }}
          >
            How to add members
          </Typography>

          <Typography
            variant="body1"
            style={{
              color: theme.palette.primary[80].toHexString(),
              fontWeight: 400,
            }}
          >
            <span className="font-medium">Step 1:</span> Enter their phone
            number
          </Typography>

          <Typography
            variant="body1"
            style={{
              color: theme.palette.primary[80].toHexString(),
              fontWeight: 400,
            }}
          >
            <span className="font-medium">Step 2:</span> Select their access
            level
          </Typography>
        </div>

        <div className="space-y-4 w-full">
          <div className="flex space-x-4">
            <NewDropdown
              className="w-28 flex-grow-0 flex-shrink-0"
              disabled={saving}
              defaultOption={defaultCountryOption}
              options={countryOptions}
              loading={optionsLoading}
              onChange={(value, label) => setCountryCode({ label, value })}
            />

            <NewTextField
              style={{ padding: 0 }}
              className="flex-grow"
              disabled={saving}
              defaultValue={phoneNumberNational}
              placeholder="Phone Number"
              onChange={(s) => setPhoneNumberNational((s || '').toString())}
            />
          </div>

          {loading ? (
            <Loader />
          ) : (
            <BlockSelectContainer>
              {data?.roles.map((role) => (
                <BlockSelect
                  key={role.id}
                  title={role.name}
                  subtitle={role.description}
                  iconName={roleId === role.id ? 'tick' : undefined}
                  onClick={() => setRoleId(role.id)}
                />
              ))}
            </BlockSelectContainer>
          )}
        </div>
      </div>
    </>
  )
}
