import clsx from 'clsx'
import { ChangeEvent, useEffect, useRef, useState } from 'react'

import useComposeRefs from 'hooks/useComposeRefs'
import { theme } from 'styles/newUi'
import { cleanErrorMessage } from 'utils'

import { useStyles } from './styles'
import { TextFieldProps } from './types'

import { Typography } from '../Typography'

export const TextField = ({
  inputRef: inputRefProp,
  min,
  style,
  className,
  label,
  labelStyle,
  labelClassName,
  inputClassName,
  placeholder,
  startAdornment,
  endAdornment,
  type,
  hideNumberControls,
  disabled,
  required,
  error,
  onBlur,
  onChange,
  onFocus,
  defaultValue,
  focus,
  inputProps,
  autoComplete = true,
  value,
  errorStyle,
  render,
  inputWrapStyles,
  noBorder = false,
  noNumberParse,
  ...rest
}: TextFieldProps<string | number | undefined>) => {
  const classes = useStyles({ noBorder })
  const startAdornRef = useRef<HTMLInputElement>(null)
  const endAdornRef = useRef<HTMLInputElement>(null)
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)
  const ref = useComposeRefs(inputRefProp, inputRef)
  const [paddingLeft, setPaddingLeft] = useState(startAdornment ? 28 : 0)
  const [paddingRight, setPaddingRight] = useState(0)
  const [showError, setShowError] = useState(false)
  const [calculateAdornment, setCalculateAdornment] = useState(false)

  useEffect(() => {
    if (focus && inputRef) inputRef?.current?.focus()
  }, [focus, render])

  useEffect(() => {
    if (startAdornRef.current && startAdornRef.current.clientWidth < 200)
      setPaddingLeft(startAdornRef.current.clientWidth + 10)
  }, [startAdornRef?.current?.clientWidth, calculateAdornment])

  useEffect(() => {
    if (endAdornRef.current && endAdornRef.current.clientWidth < 200)
      setPaddingRight(endAdornRef.current.clientWidth + 10)
  }, [endAdornRef.current?.clientWidth, calculateAdornment])

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setShowError(required ? !event.target.value : false)

    const targetValue =
      (type === undefined || type === 'text') && !event.target.checkValidity()
        ? event.target.defaultValue
        : event.target.value

    if (onChange) {
      if (type === 'number' && !noNumberParse) {
        const result = parseFloat(targetValue)
        if (isNaN(result)) {
          return onChange('')
        }

        return onChange(result)
      }
      return onChange(targetValue.trimLeft())
    }
  }

  const onEnter = (e: React.KeyboardEvent<unknown>) => {
    if (e.key === 'Enter') {
      // @ts-ignore
      e.target.blur()
    }
  }

  const finalIsError = error ? true : showError

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={(event) => event.stopPropagation()}
      className={clsx([
        classes.wrap,
        className,
        disabled && classes.disabled,
        finalIsError && classes.error,
      ])}
      style={style}
    >
      {label && (
        <Typography
          variant="label"
          style={{ ...(labelStyle ?? {}) }}
          className={clsx([classes.label, labelClassName])}
        >
          {label}
        </Typography>
      )}
      <div className={classes.inputWrap} style={{ ...inputWrapStyles }}>
        <div className={classes.startAdornment} ref={startAdornRef}>
          {startAdornment}
        </div>
        {type === 'textarea' ? (
          <textarea
            id={label as string}
            {...rest}
            autoComplete={
              autoComplete
                ? typeof autoComplete === 'string'
                  ? autoComplete
                  : 'new-password'
                : undefined
            }
            {...inputProps}
            defaultValue={defaultValue}
            onChange={handleOnChange}
            ref={ref}
            disabled={disabled}
            style={{
              paddingLeft: startAdornment && paddingLeft,
              paddingRight: endAdornment && paddingRight,
              ...(inputProps?.style ?? {}),
            }}
            data-hj-allow=""
            className={clsx([
              'data-hj-allow',
              classes.input,
              inputClassName,
              hideNumberControls && classes.hideNumberControls,
            ])}
            placeholder={placeholder}
            onFocus={(event) => {
              setCalculateAdornment(!calculateAdornment)
              // setShowError(false)
              onFocus && onFocus(event)
            }}
            onBlur={(event) => {
              if (required && !inputRef?.current?.value) setShowError(true)
              onBlur && onBlur(event)
            }}
            required={required}
          />
        ) : (
          <input
            id={label as string}
            {...rest}
            onKeyUp={onEnter}
            autoComplete={
              autoComplete
                ? typeof autoComplete === 'string'
                  ? autoComplete
                  : 'new-password'
                : undefined
            }
            {...inputProps}
            min={min}
            defaultValue={defaultValue}
            onChange={handleOnChange}
            ref={ref}
            disabled={disabled}
            style={{
              paddingLeft: startAdornment && paddingLeft,
              paddingRight: endAdornment && paddingRight,
              ...(inputProps?.style ?? {}),
            }}
            data-hj-allow=""
            type={type || 'text'}
            className={clsx([
              'data-hj-allow',
              classes.input,
              inputClassName,
              hideNumberControls && classes.hideNumberControls,
            ])}
            placeholder={placeholder}
            onFocus={(event) => {
              setCalculateAdornment(!calculateAdornment)
              // setShowError(false)
              onFocus && onFocus(event)
            }}
            value={value}
            onBlur={(event) => {
              if (required && !inputRef?.current?.value) setShowError(true)
              onBlur && onBlur(event)
            }}
            required={required}
          />
        )}
        <div className={classes.endAdornment} ref={endAdornRef}>
          {endAdornment}
        </div>
      </div>
      {error && typeof error === 'string' && (
        <div
          style={{
            ...errorStyle,
            fontSize: '0.8em',
            padding: `${theme.spacing(0.5)}px ${theme.spacing(
              1.5,
            )}px ${theme.spacing(1.25)}px`,
          }}
        >
          {cleanErrorMessage(error)}
        </div>
      )}
    </div>
  )
}
