import { User } from '../../../api'
import { ProfilePicture, Typography } from '../../../components/newUi'
import { useTheme } from '../../../styles/newUi'

export function UserDetails({ user }: { user?: User }) {
  const { theme } = useTheme()

  return (
    <div className="flex space-x-3">
      <div className="flex-shrink-0 flex-grow-0 min-w-16 flex items-center">
        <ProfilePicture imageUrl={user?.imageUrl} size="small" />
      </div>

      <div className="flex-grow space-y-2 flex flex-col justify-center">
        <Typography variant="body1">
          {user?.firstName} {user?.lastName}
        </Typography>

        <div className="space-y-1">
          <Typography
            variant="body2"
            className="break-all"
            style={{ color: theme.palette.primary[60].toHexString() }}
          >
            {user?.email}
          </Typography>

          <Typography
            variant="body2"
            className="break-all"
            style={{ color: theme.palette.primary[60].toHexString() }}
          >
            +{user?.countryCode} {user?.phoneNumberNational}
          </Typography>
        </div>
      </div>
    </div>
  )
}
