import { calc } from 'csx'

import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme, isDesktop) => ({
    allergenContains: {
      ' & svg': {
        height: 50,
        width: 50,
      },
      filter:
        'brightness(0) saturate(100%) invert(71%) sepia(43%) saturate(4687%) hue-rotate(323deg) brightness(100%) contrast(101%)',
      stroke: theme.palette.error[100].toHexString(),
    },
    allergenNone: {
      ' & svg': {
        height: 50,
        width: 50,
      },
      filter:
        'brightness(0) saturate(100%) invert(18%) sepia(11%) saturate(2357%) hue-rotate(177deg) brightness(91%) contrast(97%)',
      stroke: 'rgb(245, 245, 245)',
    },
    allergenRemovable: {
      ' & svg': {
        height: 50,
        width: 50,
      },
      filter:
        'brightness(0) saturate(100%) invert(18%) sepia(11%) saturate(2357%) hue-rotate(177deg) brightness(91%) contrast(97%)',
      stroke: theme.palette.tertiary[100].toHexString(),
    },
    backButton: {
      cursor: 'pointer',
      left: 16,
      position: 'absolute',
    },
    bottomButton: {
      backgroundColor: '#e0e0e0',
    },
    bottomField: {
      alignItems: 'center',
      backgroundColor: theme.palette.tertiary[100].toHexString(),
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
    },
    button: {
      borderRadius: 20,
    },
    checkboxContainer: {
      margin: ' 20px auto',
      position: 'relative',
      width: 20,
    },
    checkboxInput: {
      visibility: 'hidden',
    },
    checkboxLabel: {
      $nest: {
        '&::after': {
          background: 'transparent',
          border: '3px solid #fcfff4',
          borderRight: 'none',
          borderTop: 'none',
          content: '',
          filter: 'alpha(opacity=0)',
          height: 5,
          left: 4,
          opacity: 0,
          position: 'absolute',
          top: 4,
          transform: ' rotate(-45deg)',
          width: 9,
        },
      },
      background: theme.palette.common.light.toHexString(),
      borderRadius: 4,
      cursor: 'pointer',
      height: 20,
      position: 'absolute',
      top: 0,
      width: 20,
    },
    costingField: {
      flex: 1,
    },
    costingRow: {
      alignItems: 'center',
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
      cursor: 'pointer',
      display: 'grid',
      gap: '0 5px',
      gridTemplateColumns: '1fr 0fr 10px',
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      whiteSpace: 'nowrap',
      width: '100%',
    },
    divider: {
      backgroundColor: theme.palette.primary[20].toHexString(),
      display: isDesktop ? 'none' : undefined,
      height: 1,
      marginTop: -1,
      width: '100%',
    },
    item: {
      alignItems: 'center',
      border: '1px solid #e0e0e0',
      cursor: 'pointer',
      display: 'flex',
      flex: 1,
      marginLeft: -16,
      // marginBottom: 10,
      padding: 30,
      width: '100vw',
    },
    left: {
      flexGrow: 1,
    },
    leftBottom: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      justifyContent: 'space-evenly',
    },

    noAllergens: {
      ' & svg': {
        height: 50,
        width: 50,
      },
      filter:
        'invert(65%) sepia(79%) saturate(323%) hue-rotate(32deg) brightness(94%) contrast(92%)',
    },
    noPrint: {
      '@media print': {
        display: 'none',
      },
      display: 'undefined',
    },
    outer: {
      height: isDesktop ? calc('100vh - 56px') : '100vh',
    },
    pill: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary[40].toHexString(),
      border: 'none',
      borderRadius: 16,
      bottom: 0,
      color: 'white',
      display: 'flex',
      fontSize: 14,
      height: 30,
      justifyContent: 'center',
      marginBottom: -4,
      marginRight: 5,
      marginTop: 5,
      minWidth: 90,
      padding: 0,
      position: 'relative',
      // [theme.breakpoints.up('md')]: {
      //   display: 'none',
      // },
    },
    pillPot: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 500,
    },
    right: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      // padding: 20,
      textAlign: 'center',
    },
    rightBottom: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      justifyContent: 'space-evenly',
    },
  }))
