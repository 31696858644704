import { FormikProps } from 'formik'

import { UnitType } from 'api'
import { Button, Typography, NewSelectUnit } from 'components/newUi'
import { TextField } from 'components/newUi/TextField'
import { Modal } from 'screens/Create/Modal'
import { useTheme } from 'styles/newUi'

type MissingConversionModalProps = {
  setShowModal: (showModal: boolean) => void
  showModal: boolean
  formik: FormikProps<any>
  lhs: any
  rhs: any
}

export const MissingConversionModal = ({
  setShowModal,
  showModal,
  formik,
  lhs,
  rhs,
}: MissingConversionModalProps) => {
  const { theme } = useTheme()

  return (
    <Modal
      title="Conversion"
      handleCancel={() => {
        setShowModal(false)
      }}
      show={showModal}
      handleSubmit={() => {
        return
      }}
    >
      {showModal && (
        <div
          style={{
            display: 'grid',
            gap: '0px 0px',
            gridTemplateAreas: '"." "."',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 100px',
            width: '100%',
          }}
        >
          <div>
            <div
              style={{
                border: '2px solid #F0F0F0',
                borderRadius: 10,
                boxSizing: 'border-box',
                margin: 15,
                marginTop: 0,
                padding: 20,
              }}
            >
              <Typography variant="body2">
                To help us work out the cost in a different unit - please let us
                know how many <b>{lhs}</b> are in a <b>{rhs?.name}</b>.
              </Typography>
            </div>

            <div
              style={{
                border: '2px solid #F0F0F0',
                borderRadius: 10,
                boxSizing: 'border-box',
                margin: 15,
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                }}
              >
                <div>
                  <TextField
                    // @ts-ignore
                    error={formik.touched.unit && formik.errors.unit}
                    min="0.000001"
                    type="number"
                    errorStyle={{
                      display: 'none',
                    }}
                    inputProps={{
                      'data-hj-allow': '',
                      style: {
                        textAlign: 'center',
                      },
                    }}
                    defaultValue={formik.values.unit ?? ''}
                    onChange={(value) => {
                      formik.setFieldValue('unit', value)
                    }}
                    style={{ width: 90 }}
                  />
                </div>
                <div style={{ marginLeft: 10 }}>
                  <TextField
                    inputProps={{
                      'data-hj-allow': '',
                      style: {
                        textAlign: 'center',
                      },
                    }}
                    disabled
                    defaultValue={lhs}
                    style={{ width: 90 }}
                  />
                </div>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  background: theme.palette.grey[100].toHexString(),
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textTransform: 'uppercase',
                }}
              >
                <Typography variant="body1">Equals</Typography>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                }}
              >
                <div>
                  <TextField
                    // @ts-ignore
                    error={formik.touched.value && formik.errors.value}
                    min={'0.000001'}
                    type="number"
                    defaultValue={formik.values.value ?? ''}
                    onChange={(value) => {
                      formik.setFieldValue('value', value)
                    }}
                    errorStyle={{
                      display: 'none',
                    }}
                    inputProps={{
                      'data-hj-allow': '',
                      style: {
                        textAlign: 'center',
                      },
                    }}
                    style={{ width: 90 }}
                  />
                </div>
                <div style={{ marginLeft: 10 }}>
                  <NewSelectUnit
                    error={Boolean(formik.errors.type)}
                    hideCustom
                    defaultValue={
                      formik.values.type?.type === UnitType.Custom
                        ? 1
                        : formik.values.type?.id
                    }
                    onChange={(_value, _label, data) => {
                      formik.setFieldValue('type', data)
                    }}
                    style={{ paddingBottom: 14, paddingTop: 14, width: 90 }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: 'center',
              backgroundColor: theme.palette.grey[50].toHexString(),
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              text="Save Conversion"
              type="button"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}
