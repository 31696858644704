import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { Query, Kitchen } from 'api'
import { useReturnToPageContext } from 'app/contexts/ReturnToPage'
import { Icon, Typography, HighlightText } from 'components/newUi'
import { useWindowSize } from 'hooks'
import { useNewStateStore } from 'mobx/StateStore/Meta'
import { routes } from 'routes/Paths'
import { setProduct, setProductSearch } from 'store/product'
import { setSupplier } from 'store/supplier'
import { completeSize } from 'utils/text/completeSize'

import { useStyles } from './styles'

export const Item = ({
  item,
  kitchen,
  search,
}: {
  item: Query['products'][number]
  kitchen?: Kitchen
  search?: string
}) => {
  const dispatch = useDispatch()
  const newFormState = useNewStateStore()
  const returnToPageContext = useReturnToPageContext()
  const classes = useStyles()
  const { gte: isDesktop } = useWindowSize('md')
  const to = `${routes.Product}${routes.Create}`
  const kitchenIngredient =
    kitchen && item?.ingredients?.find((i) => i.kitchen.id === kitchen.id)
  const alreadybeingUsed = !!kitchenIngredient

  const handleOnClick = () => {
    if (kitchenIngredient) {
      const key = returnToPageContext.savedRoute
      const newState = newFormState.getFormState(key!)
      newState?.addIngredient(item.ingredients[0])
      dispatch(setProductSearch(undefined))
      dispatch(setSupplier(undefined))
      return
    }
    dispatch(setProduct(item))
  }

  const unitString = completeSize({
    packSize: item.packSize,
    unit: item.unit,
    unitValue: item.unitValue,
  })

  return (
    <Link
      replace
      to={alreadybeingUsed ? returnToPageContext.savedRoute! : to}
      className={classes.link}
      onClick={handleOnClick}
    >
      {isDesktop ? (
        <>
          <div
            data-testid={`product_${item?.name}`}
            className={classes.itemRootDesktop}
          >
            <Typography className={classes.productName} variant="body1">
              <HighlightText text={item?.name} search={search || ''} />
            </Typography>
            <Typography variant="body2" className={classes.subtitleDesktop}>
              {unitString}
            </Typography>
            <Icon iconName="chevronRight" />
          </div>
        </>
      ) : (
        <>
          <div className={classes.itemRoot}>
            <Typography variant="body1" className={classes.productName}>
              <HighlightText text={item?.name} search={search || ''} />
            </Typography>
            <Typography variant="body2" className={classes.subtitle}>
              {unitString}
            </Typography>
          </div>
          <div style={{ paddingRight: 10 }}>
            <Icon iconName="chevronRight" />
          </div>
        </>
      )}
    </Link>
  )
}
