import { gql } from '@apollo/client'

export const kitchensFromJoinCode = gql`
  query GetKitchens($skip: Int, $where: KitchenWhereInput) {
    kitchens(skip: $skip, where: $where) {
      id
    }
  }
`

export const kitchenNameFromJoinCodeQuery = gql`
  query kitchenNameFromJoinCode($code: String!) {
    kitchenNameFromJoinCode(code: $code)
  }
`

export const kitchenFromJoinCodeQuery = gql`
  query kitchenFromJoinCode($code: String!) {
    kitchenFromJoinCode(code: $code) {
      id
      name
    }
  }
`
export const countriesQuery = gql`
  query Countries {
    countries {
      code
      label
      phone
      suggested
    }
  }
`
