import { Modal } from '@getjelly/jelly-ui'
import { useSelector } from 'react-redux'

import { Typography } from 'components/newUi'

import { selectInvoiceCode } from '../../../../store/kitchen'
import { InvoiceCodeButton } from '../components/InvoiceCodeButton'

type Props = {
  show: boolean
  setDisplay: (arg0: boolean) => void
}

export function AutomateInvoiceModal({ show, setDisplay }: Props) {
  const invoiceCode = useSelector(selectInvoiceCode())

  return (
    <Modal
      onClose={() => {
        setDisplay(false)
      }}
      open={show}
    >
      <div className="space-y-6 text-center">
        <Typography variant="h6">Automated</Typography>

        <div className="text-center space-y-4">
          <Typography variant="body2">
            This supplier automatically shares your invoices with Jelly.
          </Typography>

          <Typography variant="body2">Via your unique site address:</Typography>

          <InvoiceCodeButton invoiceCode={invoiceCode} />
        </div>
      </div>
    </Modal>
  )
}
