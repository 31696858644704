import { Loader } from '../../../../components'
import { Icon, iconDictionary, Typography } from '../../../../components/newUi'
import { useTheme } from '../../../../styles/newUi'

type Props = {
  title: string
  subtitle: string
  onClick: () => void
  iconName?: 'loader' | keyof typeof iconDictionary
}

export function BlockSelect({ onClick, iconName, title, subtitle }: Props) {
  const { theme } = useTheme()

  return (
    <div
      className="p-4 flex justify-between border-b border-gray-100 space-x-2"
      onClick={onClick}
    >
      <div className="space-y-1">
        <Typography variant="body1">{title}</Typography>

        <Typography
          variant="body2"
          className="break-words"
          style={{ color: theme.palette.primary[60].toHexString() }}
        >
          {subtitle}
        </Typography>
      </div>

      {iconName ? (
        <div className="flex items-center">
          {iconName === 'loader' ? (
            <Loader size={24} />
          ) : (
            <Icon iconName={iconName} />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
