import { Typography } from '@getjelly/jelly-ui'

export function EnvironmentBanner() {
  const environment = import.meta.env.VITE_REACT_APP_ENV

  if (environment === 'local') {
    return (
      <div className="flex justify-center w-full bg-green-200 py-1">
        <Typography style="caption" className="text-green-800">
          LOCAL
        </Typography>
      </div>
    )
  }

  if (environment === 'staging') {
    return (
      <div className="flex justify-center w-full bg-purple-200 py-1">
        <Typography style="caption" className="text-purple-800">
          STAGING
        </Typography>
      </div>
    )
  }

  return <></>
}
