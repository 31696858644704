import { useQuery } from '@apollo/client'
import { isEmpty, reduce } from 'ramda'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Dish,
  Menu,
  MenuToDish,
  Query,
  QueryMenuArgs,
  RecipeToAllergen,
} from 'api'
import { Button, Icon, NewLayout, Typography } from 'components/newUi'
import { SectionTitle } from 'components/newUi/VirtualizedSectionList/SectionTitle'
import { Image } from 'screens/Invoices/Add'
import { getMenuByIdQuery } from 'screens/Menu/graphql'
import { useStyles } from 'screens/Menu/styles'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord, noAllergenDataList } from 'utils'

import { Print } from './Print'

const transformData = (menu: Menu) => {
  const grouped = reduce(
    (acc, el) => {
      const key = el.dish.type

      // @ts-ignore
      if (!acc[key]) {
        // @ts-ignore
        acc[key] = []
      }
      // @ts-ignore
      acc[key].push(el.dish)

      return acc
    },
    {},
    menu.dishList.nodes,
  )

  const transformedData: {
    label: string
    items: Dish[]
  }[] = Object.keys(grouped).map((key) => {
    return {
      // @ts-ignore
      items: grouped[key].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ) as Dish[],
      label: capitaliseEachWord(key),
    }
  })

  return transformedData
}

const Contains = () => {
  const { theme } = useTheme()
  return (
    <div
      style={{
        backgroundColor: theme.palette.error[100].toHexString(),
        borderRadius: '50%',
        height: 18,
        width: 18,
      }}
    />
  )
}

const Removable = () => {
  const { theme } = useTheme()
  return (
    <div
      style={{
        border: `3px solid ${theme.palette.error[100].toHexString()}`,
        borderRadius: '50%',
        height: 18,
        width: 18,
      }}
    />
  )
}

export const Allergens = () => {
  const { id } = useParams()
  const classes = useStyles()
  const { theme } = useTheme()
  const [data, setData] = useState<{ label: string; items: Dish[] }[]>([])
  const [formattedData, setFormattedData] = useState<any[]>()
  const [active, setActive] = useState<string | undefined>()

  const { data: queryResult, loading } = useQuery<
    {
      menu: Query['menu']
      dishTypes: Query['dishTypes']
      allergens: Query['allergens']
    },
    QueryMenuArgs
  >(getMenuByIdQuery, {
    variables: {
      where: {
        id: parseInt(id),
      },
    },
  })

  useEffect(() => {
    if (!queryResult || !queryResult?.menu || loading) {
      return
    }

    const transformedData = transformData(queryResult.menu)

    return setData(transformedData)
  }, [queryResult, loading])

  useEffect(() => {
    if (!queryResult) return
    const formatted =
      queryResult?.menu?.dishList.nodes &&
      queryResult?.menu?.dishList.nodes
        .map((item: MenuToDish) => {
          const allergenList = item?.dish?.recipe?.allergenList
          const sorted = [...(allergenList?.nodes ?? [])].sort((a, b) =>
            a.allergen.type > b.allergen.type ? 1 : -1,
          )
          const formattedAllergens = sorted.reduce(
            (acc: any, rta: RecipeToAllergen) => {
              if (rta.allergen)
                acc[rta.allergen.type] = {
                  allergen: rta.allergen,
                  contains: rta.contains,
                  removable: rta.removable,
                }
              return acc
            },
            {} as any,
          )
          return {
            name: item.dish.name,
            'no allergens': item.dish.recipe.hasNoAllergens,
            ...formattedAllergens,
            'no data': noAllergenDataList(item.dish.recipe),
          }
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    setFormattedData(formatted)
  }, [queryResult])

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            @media print {
              @page {
                margin: 20px !important;
              }
          }
          `,
        }}
      />
      <NewLayout
        subtitle={queryResult?.menu?.name}
        title="Allergens"
        hideMenu
        bottomContent={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              padding: theme.spacing(1.5),
              width: '100%',
            }}
          >
            <Button
              text="Print Allergens"
              noFill
              style={{
                padding: `0px ${theme.spacing(1.5)}px`,
                whiteSpace: 'nowrap',
              }}
              onClick={() => window.print()}
              rightAdornment={
                <Icon
                  iconName="print"
                  style={{ marginLeft: 4, marginRight: -4 }}
                />
              }
            />
          </div>
        }
      />

      <div className={classes.noPrint}>
        <div
          style={{
            alignItems: 'center',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-around',
            padding: theme.spacing(2),
          }}
        >
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Contains />
            <Typography
              variant="caption"
              style={{
                color: theme.palette.primary[80].toHexString(),
                marginLeft: theme.spacing(1),
              }}
            >
              Contains Allergen
            </Typography>
          </div>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Removable />
            <Typography
              variant="caption"
              style={{
                color: theme.palette.primary[80].toHexString(),
                marginLeft: theme.spacing(1),
              }}
            >
              Removable Allergen
            </Typography>
          </div>
        </div>
        <div>
          {data.map(({ label, items }) => (
            <div key={label}>
              <SectionTitle HEADER_HEIGHT={33} label={label} />
              {items.map((dish) => {
                const disableClick =
                  isEmpty(dish.recipe.allergenList.nodes) ||
                  dish.recipe.hasNoAllergens
                return (
                  <>
                    <div
                      data-testid={`allergen_${dish.name}`}
                      onClick={() => {
                        if (disableClick) return

                        if (active === dish._cursor) {
                          setActive(undefined)
                        } else {
                          setActive(dish._cursor)
                        }
                      }}
                      key={dish._cursor}
                      style={{
                        backgroundColor: 'white',
                        borderBottomColor:
                          theme.palette.primary[10].toHexString(),
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                        cursor: disableClick ? undefined : 'pointer',
                        display: 'grid',
                        gridTemplateColumns: '0fr 1fr 0fr 0fr',
                        height: 68,
                        pointerEvents: disableClick ? 'none' : undefined,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          height: '100%',
                        }}
                      >
                        <Image
                          imageSize={68}
                          localImage={false}
                          url={dish.imageUrl ?? undefined}
                          frame={false}
                        />
                      </div>
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          flex: 1,
                          overflow: 'hidden',
                          paddingLeft: theme.spacing(2),
                          paddingRight: theme.spacing(1),
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            display: '-webkit-box',
                            fontWeight: 500,
                            overflow: 'hidden',
                            textTransform: 'capitalize',
                          }}
                        >
                          {dish.name}
                        </Typography>
                      </div>
                      {dish.recipe.hasNoAllergens ? (
                        <div
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            marginRight: theme.spacing(2),
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{
                              color: theme.palette.success[100].toHexString(),
                              fontWeight: 500,
                            }}
                          >
                            NONE
                          </Typography>
                        </div>
                      ) : isEmpty(dish.recipe.allergenList.nodes) ? (
                        <div
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            marginRight: theme.spacing(2),
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{
                              color: theme.palette.primary[40].toHexString(),
                              fontWeight: 500,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            NO DATA
                          </Typography>
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              style={{
                                color: theme.palette.error[100].toHexString(),
                                fontWeight: 500,
                              }}
                            >
                              {
                                dish.recipe.allergenList.nodes.filter(
                                  (allergen) =>
                                    allergen.contains || allergen.removable,
                                ).length
                              }
                            </Typography>
                          </div>
                          <div
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              width: 50,
                            }}
                          >
                            <Icon
                              iconName={
                                active === dish._cursor
                                  ? 'chevronUp'
                                  : 'chevronDown'
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {active === dish._cursor && (
                      <div>
                        {dish.recipe.allergenList.nodes
                          .filter(
                            (allergen) =>
                              allergen.contains || allergen.removable,
                          )
                          .map((allergen) => (
                            <div
                              key={allergen._cursor}
                              style={{
                                alignItems: 'center',
                                borderBottomColor:
                                  theme.palette.primary[10].toHexString(),
                                borderBottomStyle: 'solid',
                                borderBottomWidth: 1,
                                display: 'grid',
                                gridTemplateColumns: '68px 1fr 50px',
                                height: 74,
                              }}
                            >
                              <div
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <img
                                  src={allergen.allergen.imageUrl ?? ''}
                                  alt={`${allergen.allergen.type} icon`}
                                />
                              </div>
                              <Typography
                                variant="subtitle1"
                                style={{
                                  fontWeight: 500,
                                  marginLeft: 12,
                                  textTransform: 'capitalize',
                                }}
                              >
                                {allergen.allergen.type}
                              </Typography>
                              {allergen.removable ? (
                                <Removable />
                              ) : (
                                <Contains />
                              )}
                            </div>
                          ))}
                      </div>
                    )}
                  </>
                )
              })}
            </div>
          ))}
        </div>
      </div>
      {queryResult?.menu && (
        <Print
          kitchenName={queryResult?.menu?.kitchen?.name}
          data={formattedData}
          menu={queryResult?.menu}
          allergens={queryResult?.allergens}
        />
      )}
    </>
  )
}
