import { any, isNil } from 'ramda'

import { Dish } from 'api'
import { useWindowSize } from 'hooks'
import { CostingRow } from 'screens/View/Details/CostingRow'
import { calculateGrossProfit } from 'utils/form/price'

export const Costing = ({
  dish,
  costPerPortion,
}: {
  dish: Dish
  costPerPortion: number
}) => {
  const { gte: isDesktop } = useWindowSize('md')

  if (any((val) => !val, [dish.gpTarget, dish.vat, dish.overheads, dish.price]))
    if (
      any(
        (val) => isNil(val),
        [dish.gpTarget, dish.vat, dish.overheads, dish.price],
      )
    )
      return null

  const gpValue = calculateGrossProfit(
    Number(dish?.price ?? 0),
    dish?.overheads ?? 0, // overheads
    costPerPortion,
    dish?.vat ?? 0, // vat
  )

  return (
    <div style={{ paddingTop: isDesktop ? 8 : undefined }}>
      <CostingRow left="Servings" right={dish.portion} />
      <CostingRow
        left="Cost per serving"
        right={`£ ${costPerPortion?.toFixed(2)}`}
      />
      <CostingRow left="VAT" right={`${dish.vat?.toFixed(2)}%`} />
      <CostingRow left="Overheads" right={`${dish.overheads?.toFixed(2)}%`} />
      <CostingRow left="GP" right={`${gpValue?.toFixed(2)}%`} />
    </div>
  )
}
