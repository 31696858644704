import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles(() => ({
    footer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 20,
    },
    madeWithContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    madeWithLogo: {
      borderRadius: 20,
      height: 40,
      objectFit: 'cover',
      width: 40,
    },
    madeWithText: {
      marginRight: 10,
    },
    madeWithTextLogo: {
      height: 30,
    },
  }))
