import { useQuery } from '@apollo/client'
import {
  Typography as JTypography,
  JellySupportModal,
} from '@getjelly/jelly-ui'
import {
  IconApps,
  IconBrandWhatsapp,
  IconBuildingStore,
  IconCreditCard,
  IconUsers,
} from '@tabler/icons-react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Query } from 'api'
import { VERSION } from 'app'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import {
  NewLayout,
  Icon,
  NavItem,
  Typography,
  AlertWithIcon,
  ProfilePicture,
} from 'components/newUi'
import { routes } from 'routes/Paths'
// import useIntegrations from 'screens/Settings/Integrations/useIntegrations'
import { clearToken } from 'store/auth'
import { useTheme } from 'styles/newUi'

import { getMeQuery } from './graphql'

import { useChat } from '../../app/contexts/Chat'
import { selectCurrentVersion } from '../../store/currentVersion'

export function Settings() {
  const { setOpen } = useChat()
  const currentVersion = useSelector(selectCurrentVersion)
  const dispatch = useDispatch()
  const { selectedKitchen, setSelectedKitchen } = useKitchen()
  const navigate = useNavigate()
  const { theme } = useTheme()
  const [showSupportModal, setShowSupportModal] = useState(false)

  const { data: { me: user } = {}, loading } =
    useQuery<{ me: Query['me'] }>(getMeQuery)

  // const { integrations } = useIntegrations(selectedKitchen?.id || 0)

  function handleLogout() {
    dispatch(clearToken())
    setSelectedKitchen(undefined)
    localStorage.clear()
    navigate(routes.SignIn)
    window.location.reload()
  }

  return (
    <>
      <NewLayout subtitle={selectedKitchen?.name} title="Settings" />

      <AlertWithIcon
        hide={Boolean(user?.email) || loading}
        type="error"
        iconName="warning"
        onClick={() => navigate(routes.Me)}
      >
        <Typography
          variant="subtitle2"
          style={{ color: theme.palette.error[100].toHexString() }}
        >
          Add your email to stay up to date with your spending
        </Typography>
        <Icon
          iconName="chevronRight"
          style={{ color: theme.palette.error[100].toHexString() }}
        />
      </AlertWithIcon>

      <JellySupportModal
        open={showSupportModal}
        onClose={() => setShowSupportModal(false)}
        ctaClicked={() => {
          if (selectedKitchen?.supportLink) {
            setShowSupportModal(false)
            window.open(selectedKitchen.supportLink, '_blank')?.focus()
          }
        }}
      />

      <div className="flex flex-col justify-between items-center h-full">
        <div className="w-full p-3 space-y-6">
          <div className="space-y-2">
            <div
              onClick={() => {
                selectedKitchen?.supportLink
                  ? setShowSupportModal(true)
                  : setOpen(true)
              }}
              className="px-3 py-4 bg-tertiary-400 flex items-center space-x-2 rounded w-full shadow"
            >
              <IconBrandWhatsapp />

              <JTypography style="subtitle1">JELLY support</JTypography>
            </div>

            <NavItem
              className="h-[5.75rem]"
              loading={loading && !user}
              text={user?.firstName + ' ' + (user?.lastName || '')}
              subtext="My profile, contact details"
              onClick={() =>
                navigate(`${routes.Settings}${routes.Me}${routes.Edit}`)
              }
              startAdornment={
                <ProfilePicture imageUrl={user?.imageUrl} size="small" />
              }
            />

            {/*<NavItem*/}
            {/*  className="h-[5.75rem]"*/}
            {/*  text={selectedKitchen?.name || ''}*/}
            {/*  subtext="Locations, teams, integrations..."*/}
            {/*  onClick={() =>*/}
            {/*    navigate(`${routes.Settings}${routes.Me}${routes.Edit}`)*/}
            {/*  }*/}
            {/*  startAdornment={*/}
            {/*    <IconBuildingStore className="text-primary-900" />*/}
            {/*  }*/}
            {/*/>*/}
          </div>

          <div className="space-y-2">
            <NavItem
              text="Locations"
              startAdornment={
                <IconBuildingStore className="text-primary-900" />
              }
              onClick={() => navigate(`${routes.Settings}${routes.Locations}`)}
            />

            <NavItem
              text="Plans"
              startAdornment={<IconCreditCard className="text-primary-900" />}
              onClick={() => navigate(`${routes.Settings}${routes.Plans}`)}
            />

            <NavItem
              text="Team"
              startAdornment={<IconUsers className="text-primary-900" />}
              onClick={() => navigate(`${routes.Settings}${routes.Team}`)}
            />

            <NavItem
              text="Integrations"
              startAdornment={<IconApps className="text-primary-900" />}
              onClick={() =>
                navigate(`${routes.Settings}${routes.Integrations}`)
              }
            />
          </div>
        </div>

        <div className="flex flex-col justify-center space-y-4 p-8">
          <button
            onClick={handleLogout}
            style={{ background: 'transparent', border: 'none' }}
          >
            <Typography
              style={{
                color: theme.palette.secondary[100].toHexString(),
                fontWeight: 500,
              }}
              variant="body1"
            >
              Sign out
            </Typography>
          </button>

          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.primary['60'].toHexString(),
              fontWeight: 500,
            }}
          >
            version {currentVersion || VERSION}
          </Typography>
        </div>
      </div>
    </>
  )
}
