import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from 'store/auth'

export const useHubspotChat = () => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [activeConversation, setActiveConversation] = useState(false)
  const [unreadCount, setUnreadCount] = useState(0)
  const [loadedContact, setLoadedContact] = useState(false)
  const [conversationSettings, setConversationSettings] = useState({
    inlineEmbedSelector: '#hubspot-chat',
  })
  const conversationIdEventRef = useRef()
  const unreadCountEventRef = useRef()
  const token = useSelector(selectToken())

  useEffect(() => {
    const handleFetchUserContact = async (userToken: string) => {
      try {
        const response = await fetch(`${import.meta.env.VITE_REACT_APP_API}`, {
          body: JSON.stringify({
            query: `
          query{
            hubspotContact {
              identificationEmail
              identificationToken
            }
          }
        `,
          }),
          credentials: 'include',
          headers: {
            authorization: userToken,
            'content-type': 'application/json',
          },
          method: 'POST',
        })

        const { data } = await response.json()

        setConversationSettings({
          ...conversationSettings,
          ...data.hubspotContact,
        })
        setLoadedContact(true)
      } catch {
        setLoadedContact(true)
      }
    }

    const userToken = token

    if (!userToken) {
      return setLoadedContact(true)
    }

    handleFetchUserContact(userToken)
  }, [])

  useEffect(() => {
    if (loadedContact === false) return
    // @ts-ignore
    window.hsConversationsSettings = conversationSettings
    // @ts-ignore
    window.hsConversationsOnReady = [
      () => {
        setHasLoaded(true)
      },
    ]

    // Create script component.
    const script = document.createElement('script')
    script.src = `//js.hs-scripts.com/8648061.js`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      // @ts-ignore
      window.hsConversationsOnReady = []
      setHasLoaded(false)
    }
  }, [loadedContact])

  useEffect(() => {
    // @ts-ignore
    conversationIdEventRef.current = (payload) => {
      setActiveConversation(payload.conversation.conversationId)
    }
    // @ts-ignore
    unreadCountEventRef.current = (payload) => {
      setUnreadCount(payload.unreadCount)
    }

    if (hasLoaded) {
      // @ts-ignore
      window.HubSpotConversations?.on(
        'conversationStarted',
        conversationIdEventRef.current,
      )
      // @ts-ignore
      window.HubSpotConversations?.on(
        'unreadConversationCountChanged',
        unreadCountEventRef.current,
      )
    }

    return () => {
      // @ts-ignore
      window.HubSpotConversations?.off(
        'conversationStarted',
        conversationIdEventRef.current,
      )
      // @ts-ignore
      window.HubSpotConversations?.off(
        'unreadConversationCountChanged',
        unreadCountEventRef.current,
      )
    }
  }, [hasLoaded])

  const openHandler = useCallback(() => {
    if (hasLoaded) {
      // @ts-ignore
      window.HubSpotConversations.widget.open()
    }
  }, [hasLoaded])

  const closeHandler = useCallback(() => {
    if (hasLoaded) {
      // @ts-ignore
      window.HubSpotConversations.widget.close()
    }
  }, [hasLoaded])

  const loadHandler = useCallback(() => {
    if (hasLoaded) {
      // @ts-ignore
      window.HubSpotConversations.widget.load()
    }
  }, [hasLoaded])

  const removeHandler = useCallback(() => {
    if (hasLoaded) {
      // @ts-ignore
      window.HubSpotConversations.widget.remove()
    }
  }, [hasLoaded])

  const refreshHandler = useCallback(() => {
    if (hasLoaded) {
      // @ts-ignore
      window.HubSpotConversations.widget.refresh()
    }
  }, [hasLoaded])

  return {
    activeConversation,
    closeHandler,
    hasLoaded,
    loadHandler,
    openHandler,
    refreshHandler,
    removeHandler,
    unreadCount,
  }
}
