import { useRegisterSW } from 'virtual:pwa-register/react'

// const buildDate = '__DATE__'

export const ReloadSW = () => {
  // const {
  //   offlineReady: [offlineReady, setOfflineReady],
  //   needRefresh: [needRefresh, setNeedRefresh],
  //   updateServiceWorker,
  // } = useRegisterSW({
  useRegisterSW({
    immediate: true,
    onRegisterError(error: Error) {
      // eslint-disable-next-line no-console
      console.log('SW registration error', error)
    },
    onRegisteredSW(r: any) {
      r &&
        setInterval(async () => {
          // eslint-disable-next-line no-console
          console.log('Checking for sw update')
          // r.update()
          try {
            const response = await fetch('/registerSW.js', {
              headers: {
                'Cache-Control': 'no-cache',
                cache: 'no-store',
              },
            })
            response && response.status === 200 && (await r.update())
          } catch (e) {
            console.log('cannot ping/update registerSW.js', e)
          }
        }, 3_600_000 /* 1 hour */)
    },
  })

  return <></>

  // Write toast component if we need to allow users to manually update instead?
  // const close = () => {
  //   setOfflineReady(false)
  //   setNeedRefresh(false)
  // }
  // return (
  //   <div className="ReloadPrompt-container">
  //     {(offlineReady || needRefresh) && (
  //       <div className="ReloadPrompt-toast">
  //         <div className="ReloadPrompt-message">
  //           {offlineReady ? (
  //             <span>App ready to work offline</span>
  //           ) : (
  //             <span>
  //               New content available, click on reload button to update.
  //             </span>
  //           )}
  //         </div>
  //         {needRefresh && (
  //           <button
  //             className="ReloadPrompt-toast-button"
  //             onClick={() => updateServiceWorker(true)}
  //           >
  //             Reload
  //           </button>
  //         )}
  //         <button className="ReloadPrompt-toast-button" onClick={() => close()}>
  //           Close
  //         </button>
  //       </div>
  //     )}
  //     <div className="ReloadPrompt-date">{buildDate}</div>
  //   </div>
  // )
}
