import { JSXElementConstructor, PropsWithChildren, FC, ReactNode } from 'react'

interface IProps {
  components: Array<JSXElementConstructor<PropsWithChildren<unknown>>>
  children?: ReactNode
}

export const Compose: FC<IProps> = (props) => {
  const { components = [] } = props

  return (
    <>
      {components.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>
      }, props.children)}
    </>
  )
}
