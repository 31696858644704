import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Skeleton,
} from '@mui/material'
import clsx from 'clsx'

import { useStyles } from '../styles'

export const Fallback = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.mobileList}>
        {Array.from({ length: 25 }).map((_, index) => (
          <div
            key={`PurchaseOrderEntryMobile-${index}`}
            className={clsx([
              index % 2 ? classes.grey : undefined,
              classes.mobileItem,
            ])}
          >
            <Skeleton height={'1.4rem'} width={150} />
            <div className={classes.mobileInfo}>
              <Skeleton height={'1.4rem'} width={100} />
              <Skeleton height={'1.4rem'} width={100} />
            </div>
          </div>
        ))}
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.supplierName}>
          <Skeleton height={36} width={150} />
        </div>
        <div className={classes.invoiceDetails}>
          <div>
            <Skeleton height={'1.4rem'} width={100} />
          </div>
          <div>
            <Skeleton height={'1.4rem'} width={150} />
          </div>
        </div>
      </div>
      <div className={classes.tableContainer}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ height: 40 }} />
              <TableCell className={classes.tableHeader} />
              <TableCell className={classes.tableHeader} />
              <TableCell className={classes.tableHeader} />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 25 }).map((_, index) => (
              <TableRow
                key={`PurchaseOrderEntry-${index}`}
                className={index % 2 ? undefined : classes.grey}
              >
                <TableCell>
                  <Skeleton height={'1.4rem'} />
                </TableCell>
                <TableCell>
                  <Skeleton height={'1.4rem'} />
                </TableCell>
                <TableCell>
                  <Skeleton height={'1.4rem'} />
                </TableCell>
                <TableCell>
                  <Skeleton height={'1.4rem'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
