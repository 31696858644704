import { useMutation } from '@apollo/client'

import { createProductActionAlertMutation } from './mutations'

import { Kitchen, Mutation, MutationCreateProductActionAlertArgs } from '../api'

export function useProductActionAlert() {
  const [createProductActionAlert] = useMutation<
    {
      createProductActionAlert: Mutation['createProductActionAlert']
    },
    MutationCreateProductActionAlertArgs
  >(createProductActionAlertMutation)

  return {
    async productActionAlert(action: string, kitchen?: Kitchen) {
      await createProductActionAlert({
        variables: {
          action,
          kitchenId: kitchen?.id ?? 0,
        },
      })
    },
  }
}
