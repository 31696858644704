import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { findIndex, isNil, propEq, remove } from 'ramda'

import {
  BasicIngredientFragmentFragment,
  BasicRecipeFragmentFragment,
  StockEntryFragmentFragment,
  StockFragmentFragment,
  UnitFragmentFragment,
} from 'api'
import { RootState } from 'store'
import { calculateStockEntryPrice } from 'utils/form/price'

// This is now our _local_ front-end type, and no longer a GraphQL type
// This gives us flexibility on handling the data on the front-end before
// sending it to the back-end
export type StockTakeEntry = Omit<
  StockEntryFragmentFragment,
  'id' | '_cursor' | 'quantity'
> & { quantity?: string | number | undefined | null }
export type StockTakeStoreStockTake = StockFragmentFragment & {
  entries: StockTakeEntry[]
  unsavedChanges?: boolean
  isDraft?: boolean
  kitchenId: number
  id?: number
  updatedAt?: string
  lastUpdated: StockTakeEntry
}

export type StockTakeStoreState = {
  [key: string]: StockTakeStoreStockTake
}

const initialState: StockTakeStoreState = {}

export function isIngredient(
  entry: BasicRecipeFragmentFragment | BasicIngredientFragmentFragment,
): entry is BasicIngredientFragmentFragment {
  return (entry as BasicIngredientFragmentFragment).product !== undefined
}

const getStockTotal = (entries: StockTakeEntry[]) =>
  entries.reduce((acc, cur) => acc + (cur?.total ?? 0), 0)

const stocktakeSlice = createSlice({
  initialState,
  name: 'stocktake',
  reducers: {
    addItem(
      state,
      action: PayloadAction<{
        _cursor: string
        type: 'ingredient' | 'recipe'
        entry: BasicRecipeFragmentFragment | BasicIngredientFragmentFragment
      }>,
    ) {
      const { _cursor, entry } = action.payload
      const stockTake = state[_cursor]
      if (isNil(stockTake)) return state

      const item = isIngredient(entry)
        ? {
            ingredient: entry,
            ingredientId: entry.id,
            price: entry.price,
            quantity: 0,
            recipe: undefined,
            recipeId: undefined,
            total: 0,
            unit: entry.product.unit,
            unitId: entry.product.unit.id,
          }
        : {
            ingredient: undefined,
            ingredientId: undefined,
            price: entry.unitCost,
            quantity: 0,
            recipe: entry,
            recipeId: entry.id,
            total: 0,
            // not sure if types are broken or code
            // but I'm assuming that recipe needs to have a unit
            unit: entry.unit as UnitFragmentFragment,
            unitId: entry.unit?.id as number,
          }

      const isExisting = isIngredient(entry)
        ? (e: StockTakeEntry) => e.ingredientId === entry.id
        : (e: StockTakeEntry) => e.recipeId === entry.id

      stockTake.lastUpdated = item

      if (stockTake.entries.some(isExisting)) {
        return state
      }

      stockTake.entries.push(item as StockTakeEntry)
      stockTake.unsavedChanges = true
      return
    },
    clearStocktake(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    deleteItem(
      state,
      action: PayloadAction<{
        _cursor: string
        id: number
        type: 'ingredient' | 'recipe'
      }>,
    ) {
      const { _cursor, id, type } = action.payload
      const stockTake = state[_cursor]

      if (isNil(stockTake)) return state

      if (type === 'recipe') {
        // @ts-ignore
        const index = findIndex(propEq('recipeId', id))(stockTake.entries)
        const newEntries = remove(index, 1, stockTake.entries)
        stockTake.total = getStockTotal(newEntries)
        stockTake.entries = newEntries
      }

      if (type === 'ingredient') {
        // @ts-ignore
        const index = findIndex(propEq('ingredientId', id))(stockTake.entries)
        const newEntries = remove(index, 1, stockTake.entries)
        stockTake.total = newEntries.reduce(
          (acc, cur) => acc + (cur?.total ?? 0),
          0,
        )
        stockTake.entries = newEntries
      }
      stockTake.unsavedChanges = true
      return
    },
    updateDateAndName(
      state,
      action: PayloadAction<{
        _cursor: string
        date?: Date
        name?: string | null
      }>,
    ) {
      const { _cursor, date, name } = action.payload
      const oldState = state[_cursor]

      if (!oldState) return

      if (date) {
        oldState.date = date
      }
      if (name) {
        oldState.name = name
      }
      oldState.updatedAt = new Date().toUTCString()
      oldState.unsavedChanges = true
    },
    updateItemUnit(
      state,
      action: PayloadAction<{
        _cursor: string
        id: number
        type: 'ingredient' | 'recipe'
        unit: UnitFragmentFragment
        conversionUnit?: number
        conversionUnitType?: UnitFragmentFragment
        conversionUnitValue?: number
      }>,
    ) {
      const {
        _cursor,
        id,
        type,
        unit,
        conversionUnit,
        conversionUnitType,
        conversionUnitValue,
      } = action.payload
      const stockTake = state[_cursor]

      if (isNil(stockTake)) return state

      if (type === 'recipe') {
        // @ts-ignore
        const index = findIndex(propEq('recipeId', id))(stockTake.entries)
        stockTake.entries[index].unit = unit
        stockTake.entries[index].unitId = unit.id

        stockTake.entries[index].total = calculateStockEntryPrice(
          stockTake.entries[index],
        )
      }

      if (type === 'ingredient') {
        // @ts-ignore
        const index = findIndex(propEq('ingredientId', id))(stockTake.entries)

        if (!isNil(stockTake.entries[index].ingredient)) {
          // @ts-ignore
          stockTake.entries[index].ingredient.conversionUnit = conversionUnit
          // @ts-ignore
          stockTake.entries[index].ingredient.conversionUnitType =
            conversionUnitType
          // @ts-ignore
          stockTake.entries[index].ingredient.conversionUnitValue =
            conversionUnitValue
        }

        stockTake.entries[index].unit = unit

        stockTake.entries[index].unitId = unit.id

        stockTake.entries[index].total = calculateStockEntryPrice(
          stockTake.entries[index],
        )
      }
      stockTake.total = getStockTotal(stockTake.entries)
      stockTake.unsavedChanges = true

      return
    },
    updateItemValue(
      state,
      action: PayloadAction<{
        _cursor: string
        id: number
        type: 'ingredient' | 'recipe'
        value: number | string | undefined
      }>,
    ) {
      const { _cursor, id, type, value } = action.payload
      const stockTake = state[_cursor]

      if (isNil(stockTake)) return state

      if (type === 'recipe') {
        // @ts-ignore
        const index = findIndex(propEq('recipeId', id))(stockTake.entries)

        stockTake.entries[index].quantity = value
        stockTake.entries[index].total = calculateStockEntryPrice(
          stockTake.entries[index],
        )
      }

      if (type === 'ingredient') {
        // @ts-ignore
        const index = findIndex(propEq('ingredientId', id))(stockTake.entries)
        stockTake.entries[index].quantity = value
        stockTake.entries[index].total = calculateStockEntryPrice(
          stockTake.entries[index],
        )
      }
      stockTake.total = getStockTotal(stockTake.entries)
      stockTake.unsavedChanges = true

      return
    },
    updateStocktake(
      state,
      action: PayloadAction<
        Partial<StockTakeStoreStockTake> & { _cursor: string }
      >,
    ) {
      const oldState = state[action.payload._cursor] ?? {}

      const newState = {
        ...oldState,
        ...action.payload,
        updatedAt: new Date().toUTCString(),
      }

      // recalculate totals on data load
      // HACKy solution for wrong calculations saved in the db
      const entriesUpdatedTotals = newState.entries.map((entry) => ({
        ...entry,
        total: calculateStockEntryPrice(entry),
      }))

      const newStateUpdatedTotals = {
        ...newState,
        entries: entriesUpdatedTotals,
        total: getStockTotal(entriesUpdatedTotals),
      }

      state[action.payload._cursor] = newStateUpdatedTotals
    },
  },
})

export const {
  addItem,
  deleteItem,
  updateDateAndName,
  updateItemValue,
  updateStocktake,
  clearStocktake,
  updateItemUnit,
} = stocktakeSlice.actions
export const reducer = stocktakeSlice.reducer

const selectState = (state: RootState) => state.stocktake

export const selectStocktake = (cursor: string) =>
  createSelector(selectState, (state: StockTakeStoreState) => state[cursor])

export const selectAllStocktakes = (kitchenId: number) =>
  createSelector(selectState, (state: StockTakeStoreState) =>
    Object.values(state).filter((item) => item.kitchenId === kitchenId),
  )

const NO_ENTRIES = [] as StockTakeEntry[]

export const selectStocktakeEntries = (cursor: string) =>
  createSelector(
    selectStocktake(cursor),
    (state) => state?.entries ?? NO_ENTRIES,
  )

export const selectStocktakeLastUpdated = (cursor: string) =>
  createSelector(selectStocktake(cursor), (state) => state?.lastUpdated)
