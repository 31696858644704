import { FormikErrors, FormikTouched } from 'formik'

export const cleanErrorMessage = (message: string | undefined) => {
  if (!message) return ''

  return message.replace(/\[GraphQL\] /g, '').replace(/\[Network\] /g, '')
}

interface IKeyValuePair<T> {
  [key: string]: T
}

export const getErrorsFromArray = <T>({
  errors,
  touched,
  index,
  fieldName,
}: IProps<T>) => {
  if (!errors || !touched) {
    return false
  }
  const touchedFields = touched[index] as unknown as IKeyValuePair<T>

  if (!touchedFields) return false

  const isTouched = touchedFields[fieldName]

  const fieldErrors = errors[index] as unknown as IKeyValuePair<T>

  if (!fieldErrors || !isTouched) return false

  return !!fieldErrors[fieldName]
}

export const getErrorMessageForArray = <T>({
  errors,
  touched,
  index,
  fieldName,
}: IProps<T>) => {
  if (!touched || !errors) return undefined

  const touchedFields = touched[index] as unknown as IKeyValuePair<T>

  if (!touchedFields) return false

  const isTouched = touchedFields[fieldName]

  const fieldErrors = errors[index] as unknown as IKeyValuePair<T>

  if (!fieldErrors || !isTouched) return undefined

  return fieldErrors[fieldName]
}

interface IProps<T> {
  errors: string | string[] | FormikErrors<T>[] | undefined
  touched: FormikTouched<T>[] | undefined
  index: number
  fieldName: string
}

export const getErrorAndHelperText = <T>(props: IProps<T>) => {
  return {
    error: getErrorsFromArray(props),
    helperText: getErrorMessageForArray(props),
  }
}
