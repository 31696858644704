import { makeStyles } from 'styles'
import { jellyRed } from 'styles/colors'

export const useStyles = makeStyles({
  badge: {
    '& span': {
      backgroundColor: jellyRed,
    },
    zIndex: 0,
  },
})
