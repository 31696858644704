import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme) => ({
    _root: {},
    allergenContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    costingContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    costingRow: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.primary[20].toHexString()}`,
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
    },
    costingRowAmount: {
      fontWeight: 600,
      textAlign: 'right',
    },
    costingRowTitle: {},
    costingRowTotal: {
      fontSize: 24,
      fontWeight: 600,
    },
    costingTitle: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary[10].toHexString(),
      borderBottom: `1px solid ${theme.palette.primary[20].toHexString()}`,
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '7px 15px',
    },
    costingTitleServings: {
      color: theme.palette.primary[80].toHexString(),
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'right',
    },
    costingTitleTitle: {
      fontSize: 24,
      fontWeight: 600,
    },
    costingTotal: {
      alignItems: 'center',
      backgroundColor: theme.palette.tertiary[100].toHexString(),
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 15px',
    },
    divider: {
      backgroundColor: theme.palette.primary[20].toHexString(),
      display: 'flex',
      height: 1,
      marginBottom: 30,
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20,
      marginLeft: 20,
    },
    infoContainer: {
      display: 'grid',
      gridTemplateColumns: '300px 1fr',
      gridTemplateRows: '1fr',
      marginBottom: 30,
    },
    infoText: {
      fontSize: 18,
      fontWeight: 600,
      marginRight: 10,
      textTransform: 'capitalize',
    },
    infoTitle: {
      color: theme.palette.primary[40].toHexString(),
      fontSize: 18,
      fontWeight: 600,
      marginTop: 20,
      textTransform: 'capitalize',
    },
    infoType: {
      color: theme.palette.primary[40].toHexString(),
      fontSize: 18,
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    ingredientRowAmount: {
      textAlign: 'right',
    },
    ingredientsContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 20,
    },
    ingredientsTable: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.primary[20].toHexString()}`,
      boxSizing: 'border-box',
      display: 'grid',
      gridTemplateColumns: '1fr 70px 70px',
      gridTemplateRows: '1fr',
      justifyContent: 'space-between',
      padding: 15,
    },
    ingredientsTitle: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary[10].toHexString(),
      borderBottom: `1px solid ${theme.palette.primary[20].toHexString()}`,
      boxSizing: 'border-box',
      display: 'grid',
      gridTemplateColumns: '1fr 70px 70px',
      gridTemplateRows: '1fr',
      justifyContent: 'space-between',
      padding: '10px 15px',
    },
    method: {
      display: 'flex',
      flexDirection: 'column',
    },
    methodContent: {
      marginLeft: 15,
      marginRight: 15,
    },
    methodTitle: {
      backgroundColor: theme.palette.primary[10].toHexString(),
      display: 'flex',
      fontSize: 24,
      fontWeight: 600,
      justifyContent: 'space-between',
      marginBottom: 20,
      padding: '7px 15px',
    },
    name: {
      fontSize: 28,
      fontWeight: 600,
      marginBottom: 20,
    },
    onlyPrint: {
      '@media print': {
        display: 'block',
      },
      display: 'none',
    },
    portions: {
      fontSize: 18,
      fontWeight: 600,
    },
    statsContainer: {
      display: 'grid',
      gridTemplateColumns: '300px 1fr',
      gridTemplateRows: '1fr',
      marginBottom: 30,
    },
  }))
