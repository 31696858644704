import { useQuery } from '@apollo/client'
import { Button } from '@getjelly/jelly-ui'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getUserListQuery } from './graphql'

import { KitchenToUser, Query, QueryUserListArgs } from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../components'
import {
  NewLayout,
  Icon,
  Typography,
  ProfilePicture,
} from '../../../components/newUi'
import { routes } from '../../../routes/Paths'
import { useTheme } from '../../../styles/newUi'
import { getMeQuery } from '../graphql'

export function Team() {
  const { theme } = useTheme()
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()
  const [kitchenToUsers, setKitchenToUsers] = useState<KitchenToUser[]>([])

  const { data: { me } = {} } = useQuery<{ me: Query['me'] }>(getMeQuery)

  const { data, loading } = useQuery<
    { kitchenNode: Query['kitchenNode'] },
    QueryUserListArgs
  >(getUserListQuery, {
    variables: { cursor: selectedKitchen?._cursor },
  })

  useEffect(() => {
    if (!data?.kitchenNode?.userList.nodes) {
      return
    }

    setKitchenToUsers(
      [...(data.kitchenNode.userList.nodes ?? [])]
        .filter((a) => a.user.type === 'kitchen')
        .sort((a) => (a.user.id === me?.id ? -1 : 1)),
    )
  }, [data, me])

  const canInviteTeam = useMemo(
    () => selectedKitchen?.userPermissions?.includes('create-kitchen-invite'),
    [selectedKitchen],
  )

  return (
    <>
      <NewLayout
        subtitle="Settings"
        title="Team"
        rightContent={
          canInviteTeam ? (
            <div className="px-2">
              <Button
                label="Add Member"
                onClick={() =>
                  navigate(`${routes.Settings}${routes.Team}${routes.Add}`)
                }
              />
            </div>
          ) : (
            <></>
          )
        }
      />

      <div className="p-3">
        <div className="bg-white rounded-md shadow">
          {loading && !data?.kitchenNode?.userList.nodes.length ? (
            <div className="flex justify-center items-center p-4">
              <Loader />
            </div>
          ) : (
            <></>
          )}

          {kitchenToUsers.map((ktu) => (
            <div
              key={ktu.user.id}
              className="p-4 flex justify-between border-t border-gray-100"
              onClick={() => {
                navigate(`${routes.Settings}${routes.Team}/${ktu.id}`)
              }}
            >
              <div className="flex space-x-2">
                <div className="flex-shrink-0 flex-grow-0 min-w-16">
                  <ProfilePicture imageUrl={ktu.user.imageUrl} size="small" />
                </div>

                <div className="flex-grow space-y-2 flex flex-col justify-center">
                  <Typography variant="body1">
                    {ktu.user.firstName +
                      ' ' +
                      ((ktu.user.lastName ?? '')[0] || '')}
                    {ktu.user.id === me?.id ? ' (You)' : ''}
                  </Typography>

                  <div className="space-y-1">
                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                      {ktu.role.name}
                    </Typography>

                    <Typography
                      variant="body2"
                      className="break-all"
                      style={{ color: theme.palette.primary[60].toHexString() }}
                    >
                      {ktu.user.email}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <Icon iconName="chevronRight" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
