import { Loader } from 'components/Loader'
import { Icon, Typography } from 'components/newUi'
import { useTheme } from 'styles/newUi'

import { Props } from './types'

export const MenuList = ({
  menu,
  options,
  activation,
  loading,
  invert,
}: Props) => {
  const { theme } = useTheme()

  return (
    <div>
      <div
        style={{
          backgroundColor: theme.palette.common.light.toHexString(),
          borderRadius: 4,
          boxShadow: theme.elevation[1],
          margin: 10,
          width: 'calc(100% - 20px)',
        }}
      >
        {loading ? (
          <div style={{ paddingBottom: 20, paddingTop: 20 }}>
            <Loader />
          </div>
        ) : (
          <>
            <div
              style={{
                borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {menu.icon && (
                  <Icon
                    iconName={menu.icon}
                    style={{
                      color: theme.palette.primary[40].toHexString(),
                      margin: 12,
                    }}
                  />
                )}
                <Typography
                  variant="body1"
                  style={{
                    margin: 12,
                    marginLeft: menu.icon ? 'unset' : 12,
                  }}
                >
                  {menu.name}
                </Typography>
                {menu.showCheck && (
                  <Icon
                    iconName={'tick'}
                    style={{
                      color: theme.palette.common.dark.toHexString(),
                      margin: 12,
                    }}
                  />
                )}
                <Typography
                  variant={invert ? 'subtitle1' : 'body1'}
                  style={{
                    color: invert
                      ? theme.palette.primary[60].toHexString()
                      : undefined,
                    fontWeight: 400,
                  }}
                >
                  {menu.subtext}
                </Typography>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {menu.action && (
                  // eslint-disable-next-line
                  <div
                    onClick={menu.action.onClick}
                    style={{
                      alignItems: 'center',
                      cursor: 'pointer',
                      display: 'flex',
                    }}
                  >
                    {menu.action.name && (
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.secondary[100].toHexString(),
                        }}
                      >
                        {menu.action.name}
                      </Typography>
                    )}
                    {menu.action.icon && (
                      <Icon
                        iconName={menu.action.icon}
                        style={{
                          color: theme.palette.secondary[100].toHexString(),
                          height: 16,
                          margin: 12,
                          width: 16,
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div>
              {options?.map((option) => (
                <a
                  href={option.link ?? undefined}
                  key={option.key}
                  style={{ textDecoration: 'none' }}
                >
                  {/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div
                    onClick={option.onClick}
                    style={{
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        margin: 12,
                      }}
                    >
                      {option.prefixIcon && (
                        <Icon
                          iconName={option.prefixIcon}
                          style={{
                            color: theme.palette.primary[40].toHexString(),
                            height: 20,
                            marginRight: 5,
                            width: 20,
                            ...option.prefixIconStyle,
                          }}
                        />
                      )}
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                          {option.title?.split('\n').map((str, index) => (
                            <Typography
                              key={index}
                              variant={invert ? 'subtitle1' : 'body1'}
                              style={{
                                color: option.link
                                  ? theme.palette.secondary[100].toHexString()
                                  : invert
                                  ? theme.palette.primary[60].toHexString()
                                  : undefined,
                                fontWeight: option.link ? 500 : 400,
                                ...option.titleStyle,
                              }}
                            >
                              {str}
                              {index !==
                                (option.title?.split('\n')?.length ?? 0) -
                                  1 && <br />}
                            </Typography>
                          ))}
                        </div>
                        <div>
                          {option.subtitle?.split('\n').map((str, index) => (
                            <Typography
                              key={index}
                              variant={invert ? 'body1' : 'subtitle1'}
                              style={{
                                color: option.link
                                  ? theme.palette.secondary[100].toHexString()
                                  : invert
                                  ? undefined
                                  : theme.palette.primary[60].toHexString(),
                                fontWeight: option.link ? 500 : 400,
                                ...option.subtitleStyle,
                              }}
                            >
                              {str}
                              {index !==
                                (option.subtitle?.split('\n')?.length ?? 0) -
                                  1 && <br />}
                            </Typography>
                          ))}
                        </div>
                      </div>
                    </div>
                    {option.onClick && (
                      <Icon
                        iconName={option.icon ?? 'chevronRight'}
                        style={{
                          color: theme.palette.primary[100].toHexString(),
                          margin: 5,
                          ...option.iconStyle,
                        }}
                      />
                    )}
                  </div>
                  {/* eslint-enable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                </a>
              ))}
            </div>
            {activation && (
              //  eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                onClick={activation.onClick}
                style={{
                  alignItems: 'center',
                  color: theme.palette.secondary[100].toHexString(),
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="body1"
                  style={{ color: 'inherit', margin: 12 }}
                >
                  {activation.title}
                </Typography>
                <Icon iconName={activation.icon} style={{ margin: 5 }} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
