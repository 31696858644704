import { gql } from '@apollo/client'

export const stockTakeList = gql`
  query flashStockTakeList($kitchenId: Int!) {
    stockList(kitchenId: $kitchenId, take: 7500) {
      nodes {
        id
        total
        name
        date
      }
    }
  }
`

export const flashPeriod = gql`
  query insightsFlashPeriod(
    $kitchenId: Int!
    $period: PeriodEnum!
    $startDate: DateTime!
  ) {
    flashPeriod(kitchenId: $kitchenId, period: $period, startDate: $startDate) {
      id
      openAdjustments
      closeAdjustments
      flashPeriodToStock {
        id
        type
        stock {
          id
          total
          name
          date
        }
      }
    }
  }
`

export const createOneFlashPeriod = gql`
  mutation CreateFlashPeriodMutation($data: CreateFlashPeriodInput!) {
    createOneFlashPeriod(data: $data) {
      id
    }
  }
`

export const updateOneFlashPeriod = gql`
  mutation UpdateFLashPeriodMutation($data: UpdateFlashPeriodInput!) {
    updateOneFlashPeriod(data: $data) {
      id
    }
  }
`

export const flashInsights = gql`
  query flashInsights(
    $kitchenId: Int!
    $start: DateTime!
    $period: PeriodEnum!
  ) {
    flashInsights(kitchenId: $kitchenId, start: $start, period: $period) {
      totalInvoicesNeedAttention
      salesData {
        date
        sales
        spend
      }
      sales {
        automated
        date
        total
      }
    }
  }
`

export const salesList = gql`
  query salesList($kitchenId: Int!, $where: SalesWhereInput) {
    salesList(kitchenId: $kitchenId, where: $where) {
      nodes {
        id
        sales
        salesDate
        _cursor
      }
    }
  }
`

export const createOrUpdateOneSale = gql`
  mutation createOrUpdateOneSale($data: CreateOrUpdateOneSalesInputObject!) {
    createOrUpdateOneSale(data: $data) {
      sales
      salesDate
      id
      _cursor
    }
  }
`
