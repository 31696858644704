import { useMutation } from '@apollo/client'
import { Box } from '@mui/material'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import {
  MutationCreateOneIngredientArgs,
  Mutation,
  Supplier,
  Product,
  Kitchen,
} from 'api'
import { useReturnToPageContext } from 'app/contexts/ReturnToPage'
import {
  AlertWithIcon,
  NewTextField,
  NewButton,
  NewSelectUnit,
  Typography,
  NewLayout,
} from 'components/newUi'
import { Icon } from 'components/newUi/Icon'
import { useWindowSize } from 'hooks'
import { useNewStateStore } from 'mobx/StateStore/Meta'
import { routes } from 'routes/Paths'
import {
  selectProductSearch,
  setProduct,
  setProductSearch,
} from 'store/product'
import { setSupplier } from 'store/supplier'
import { theme } from 'styles/newUi'
import { cleanErrorMessage, isEmptyOrNil } from 'utils'

import {
  createIngredientMutation,
  createIngredientWithSupplierMutation,
} from './graphql'
import { useStyles } from './styles'

import { errorToast, successToast } from '../../../components/toasts'

export const Form = ({
  kitchen,
  supplier,
  product,
}: {
  kitchen?: Kitchen
  supplier?: Supplier
  product?: Product
}) => {
  const dispatch = useDispatch()
  const newFormState = useNewStateStore()
  const returnToPageContext = useReturnToPageContext()
  const { gte: isDesktop } = useWindowSize('md')

  const classes = useStyles()
  const [showOptional, setShowOptional] = useState(false)
  const [conversionUnitLabel, setConversionUnitLabel] = useState('unit')

  const navigate = useNavigate()

  const mutation = supplier?.id
    ? createIngredientWithSupplierMutation
    : createIngredientMutation

  const [createIngredient, { loading }] = useMutation<
    { createOneIngredient: Mutation['createOneIngredient'] },
    MutationCreateOneIngredientArgs
  >(mutation)

  const productSearch = useSelector(selectProductSearch())

  const formik = useFormik({
    initialValues: product
      ? {
          code: product.code ?? undefined,
          conversionUnit: undefined,
          conversionUnitTypeId: undefined,
          conversionUnitValue: undefined,
          name: product.name,
          packSize: product.packSize,
          price: undefined,
          productId: product.id,
          supplierId: product.supplier.id ?? undefined,
          supplierName: product.supplier.name ?? undefined,
          unitId: product.unit.id,
          unitPrice: 0,
          unitValue: product.unitValue,
          wastage: 0,
        }
      : {
          code: undefined,
          conversionUnit: undefined,
          conversionUnitTypeId: undefined,
          conversionUnitValue: undefined,
          name: productSearch,
          packSize: 1,
          price: undefined,
          productId: undefined,
          supplierId: supplier ? supplier.id : undefined,
          supplierName: supplier ? supplier.name : undefined,
          unitId: undefined,
          unitPrice: 0,
          unitValue: undefined,
          wastage: 0,
        },
    isInitialValid: false,
    onSubmit: async (data) => {
      if (
        (!isEmptyOrNil(data.conversionUnit) &&
          (isEmptyOrNil(data.conversionUnitTypeId) ||
            isEmptyOrNil(data.conversionUnitValue))) ||
        (!isEmptyOrNil(data.conversionUnitTypeId) &&
          (isEmptyOrNil(data.conversionUnit) ||
            isEmptyOrNil(data.conversionUnitValue))) ||
        (!isEmptyOrNil(data.conversionUnitValue) &&
          (isEmptyOrNil(data.conversionUnitTypeId) ||
            isEmptyOrNil(data.conversionUnit)))
      ) {
        if (!data.conversionUnit) {
          formik.setFieldError('conversionUnit', 'Required')
        }
        if (!data.conversionUnitValue) {
          formik.setFieldError('conversionUnitValue', 'Required')
        }
        if (!data.conversionUnitTypeId) {
          formik.setFieldError('conversionUnitTypeId', 'Please select unit')
        }
        if (
          !data.conversionUnit ||
          !data.conversionUnitValue ||
          !data.conversionUnitTypeId
        ) {
          return
        }
      }

      const { supplierName, supplierId, productId, ...rest } = data

      // @ts-ignore
      rest.price = parseFloat(rest.price)
      // @ts-ignore
      rest.unitValue = parseFloat(rest.unitValue)
      // @ts-ignore
      rest.wastage = parseFloat(rest.wastage)
      // @ts-ignore
      rest.conversionUnit = parseFloat(rest.conversionUnit)
      // @ts-ignore
      rest.conversionUnitValue = parseFloat(rest.conversionUnitValue)

      const toSend = {
        data: rest,
        kitchenId: kitchen!.id,
        productId,
        supplierData: supplier
          ? undefined
          : {
              name: supplierName,
            },
        supplierId,
      }

      // @ts-ignore
      await createIngredient({ variables: toSend })
        .then(({ data }) => {
          if (!data) return

          dispatch(setProductSearch(undefined))
          dispatch(setSupplier(undefined))

          const { product } = data.createOneIngredient

          successToast(`Added ${product?.name}`)

          const key = returnToPageContext.savedRoute
          const newState = newFormState.getFormState(key!)
          newState?.addIngredient(data.createOneIngredient)
          returnToPageContext.returnToPage()
        })
        .catch((error) => errorToast(cleanErrorMessage(error.message)))
    },
    validationSchema: Yup.object({
      conversionUnit: Yup.number().nullable(),
      conversionUnitTypeId: Yup.number().nullable(),
      conversionUnitValue: Yup.number().nullable(),
      name: Yup.string().required('Required'),
      packSize: Yup.number()
        .required('Required')
        .min(0.000001, 'Must be greater than 0.000001'),
      price: Yup.number().required('Required'),
      productId: Yup.string().nullable(),
      supplierId: Yup.string().nullable(),
      supplierName: Yup.string().required('Required'),
      unitId: Yup.number().required('Required'),
      unitValue: Yup.number()
        .required('Required')
        .min(0.000001, 'Must be greater than 0.000001'),
      wastage: Yup.number()
        .min(0, 'Must be greater than or equal to 0 and less than 100')
        .max(101, 'Must be greater than or equal to 0 and less than 100'),
    }),
  })

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          maxWidth: '100%',
        }}
      >
        <div className={classes.root}>
          <NewLayout
            title="Add product"
            subtitle="Create"
            onBack={() => {
              dispatch(setProduct(undefined))
              navigate(`${routes.Supplier}/${supplier?.id}`, {
                replace: true,
              })
            }}
            rightContent={
              isDesktop ? (
                <div className={classes.buttonWrap}>
                  <NewButton
                    text="Cancel"
                    style={{
                      backgroundColor: 'white',
                      color: theme.palette.primary[100].toHexString(),
                    }}
                    disabled={loading}
                    loading={loading}
                    onClick={() => navigate(-1)}
                  />
                  <NewButton
                    text="Save Changes"
                    disabled={loading}
                    loading={loading}
                    onClick={() => formik.submitForm()}
                  />
                </div>
              ) : undefined
            }
          />
          <div className={classes.innerWrap}>
            <Box width={588} maxWidth="100%">
              {product && (
                <AlertWithIcon type="info" iconName="info">
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 400 }}
                  >{`As this is a catalogue product some details can't be changed.`}</Typography>
                </AlertWithIcon>
              )}
              <div
                style={{
                  alignItems: 'center',
                  backgroundColor: 'rgba(233, 234, 237, 1)',
                  display: 'flex',
                  height: 33,
                  marginBottom: 4,
                  paddingLeft: 18,
                }}
              >
                <Typography variant="button" className={classes.titleSlip}>
                  Product details
                </Typography>
              </div>
              <div
                style={{
                  borderBottomColor: theme.palette.primary[10].toHexString(),
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  padding: theme.spacing(2),
                  paddingBottom: 4,
                }}
              >
                <NewTextField
                  inputProps={{
                    'data-hj-allow': '',
                  }}
                  label="Supplier"
                  placeholder="Enter a Supplier Name..."
                  defaultValue={formik.values.supplierName}
                  disabled={!!supplier}
                  onChange={(value) =>
                    formik.setFieldValue('supplierName', value)
                  }
                  error={
                    !!(
                      formik.touched.supplierName && formik.errors.supplierName
                    )
                  }
                />
              </div>
              <div className={classes.boxWithPointer}>
                <NewTextField
                  inputProps={{
                    'data-hj-allow': '',
                  }}
                  disabled={!!product}
                  label="Product Name"
                  placeholder="Enter a Product Name..."
                  style={{ paddingTop: 0, width: '100%' }}
                  required
                  defaultValue={formik.values.name}
                  onChange={(value) => formik.setFieldValue('name', value)}
                  error={!!(formik.touched.name && formik.errors.name)}
                />
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    paddingRight: 8,
                    width: '100%',
                  }}
                >
                  <div style={{ flex: 7 }} className={classes.packAmountWrap}>
                    <NewTextField
                      min="0.000001"
                      inputProps={{
                        'data-hj-allow': '',
                        step: '0.000001',
                      }}
                      disabled={!!product}
                      style={{ flex: 3, paddingBottom: 3 }}
                      label="Pack Size"
                      type="number"
                      hideNumberControls
                      required
                      defaultValue={formik.values.packSize}
                      onChange={(value) =>
                        formik.setFieldValue('packSize', value)
                      }
                      error={
                        !!(formik.touched.packSize && formik.errors.packSize)
                      }
                    />
                    <Icon iconName="clear" className={classes.cross} />
                    <NewTextField
                      min="0.000001"
                      inputProps={{
                        'data-hj-allow': '',
                        step: '0.000001',
                      }}
                      disabled={!!product}
                      style={{ flex: 3, paddingBottom: 3 }}
                      label="Unit Value"
                      type="number"
                      hideNumberControls
                      required
                      defaultValue={formik.values.unitValue}
                      onChange={(value) =>
                        formik.setFieldValue('unitValue', value)
                      }
                      error={
                        !!(formik.touched.unitValue && formik.errors.unitValue)
                      }
                    />
                  </div>
                  <NewSelectUnit
                    disabled={!!product}
                    label="Unit"
                    style={{ flex: 3, marginBottom: 9 }}
                    defaultValue={formik.values.unitId}
                    showPortion
                    onChange={(value, label, data) => {
                      formik.setFieldValue('unitId', value)
                      setConversionUnitLabel(
                        data.type === 'custom' ? label : 'unit',
                      )
                    }}
                    error={!!(formik.touched.unitId && formik.errors.unitId)}
                    required
                  />
                </div>
              </div>
              <Link
                className={classes.link}
                onClick={(e) => {
                  e.preventDefault()
                  setShowOptional(!showOptional)
                }}
                to=""
                style={{
                  backgroundColor: 'rgba(233, 234, 237, 1)',
                  display: 'flex',
                  height: 40,
                  justifyContent: 'space-between',
                  marginTop: -18,
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                }}
              >
                <Typography variant="button" className={classes.titleSlip}>
                  Advanced options
                </Typography>
                <Icon
                  iconName={showOptional ? 'chevronUp' : 'chevronDown'}
                  style={{ color: 'rgba(102, 102, 102, 1)' }}
                />
              </Link>
              <div
                className={clsx([
                  classes.hideableFields,
                  showOptional && classes.show,
                ])}
                style={{
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                }}
              >
                <NewTextField
                  inputProps={{
                    'data-hj-allow': '',
                  }}
                  disabled={!!product}
                  label="Product code (Optional)"
                  style={{ width: '100%' }}
                  hideNumberControls
                  defaultValue={formik.values.code}
                  className={classes.productCode}
                  onChange={(value) => formik.setFieldValue('code', value)}
                  error={!!(formik.touched.code && formik.errors.code)}
                />
                <NewTextField
                  inputProps={{
                    'data-hj-allow': '',
                  }}
                  min="0"
                  label="Waste % (Optional)"
                  style={{ width: '100%' }}
                  placeholder="Enter percentage of waste..."
                  type="number"
                  hideNumberControls
                  endAdornment="%"
                  defaultValue={formik.values.wastage}
                  onChange={(value) => formik.setFieldValue('wastage', value)}
                  error={!!(formik.touched.wastage && formik.errors.wastage)}
                />
                {formik.errors.wastage && (
                  <Typography variant="body2" className={classes.errorMessage}>
                    {formik.errors.wastage}
                  </Typography>
                )}
                <div
                  style={{
                    alignItems: 'center',
                    borderTopColor: theme.palette.primary[10].toHexString(),
                    borderTopStyle: 'solid',
                    borderTopWidth: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginTop: theme.spacing(2),
                    paddingTop: theme.spacing(2),
                  }}
                >
                  <Typography
                    className={classes.unitConversionTitle}
                    variant="body1"
                  >
                    Unit Conversion{' '}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.unitConversionDescription}
                  >
                    To get accurate costings, please specify how many grams per
                    unit, if known.
                  </Typography>
                  <Box className={classes.packAmountWrap} gap={2}>
                    <NewTextField
                      inputProps={{
                        'data-hj-allow': '',
                        step: '0.01',
                      }}
                      min="0"
                      style={{ flex: 3 }}
                      label="Amount"
                      type="number"
                      endAdornment={conversionUnitLabel}
                      hideNumberControls
                      defaultValue={formik.values.conversionUnit}
                      onChange={(value) =>
                        formik.setFieldValue('conversionUnit', value)
                      }
                      error={
                        !!(
                          formik.touched.conversionUnit &&
                          formik.errors.conversionUnit
                        )
                      }
                    />

                    <div className={classes.equals}>=</div>

                    <NewTextField
                      inputProps={{
                        'data-hj-allow': '',
                      }}
                      min="0"
                      style={{ flex: 3 }}
                      label="Value"
                      type="number"
                      hideNumberControls
                      defaultValue={formik.values.conversionUnitValue}
                      onChange={(value) =>
                        formik.setFieldValue('conversionUnitValue', value)
                      }
                      error={
                        !!(
                          formik.touched.conversionUnitValue &&
                          formik.errors.conversionUnitValue
                        )
                      }
                    />

                    <NewSelectUnit
                      hideCustom
                      label="Unit"
                      style={{ flex: 3, margin: 0, padding: '10px 0' }}
                      placeholder=""
                      defaultValue={formik.values.conversionUnitTypeId}
                      showPortion={false}
                      onChange={(value) =>
                        formik.setFieldValue('conversionUnitTypeId', value)
                      }
                      className={classes.selectUnit}
                      error={
                        !!(
                          formik.touched.conversionUnitTypeId &&
                          formik.errors.conversionUnitTypeId
                        )
                      }
                    />
                  </Box>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <div>
          <div
            style={{
              backgroundColor: theme.palette.secondary[50].toHexString(),
              display: 'grid',
              gap: '5px 5px',
              gridTemplateColumns: '1fr 0.5fr',
              height: 76,
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              width: '100%',
            }}
            className={classes.mobileButtonWrap}
          >
            <div>
              <Typography variant="subtitle2">Pack price</Typography>
              <Typography variant="body2" style={{ paddingRight: 6 }}>
                Approx. how much you last paid for this
              </Typography>
            </div>

            <NewTextField
              min="0"
              inputProps={{
                'data-hj-allow': '',
                step: '0.01',
              }}
              style={{ width: '100%' }}
              startAdornment="£"
              type="number"
              hideNumberControls
              required
              defaultValue={formik.values.price}
              onChange={(value) => formik.setFieldValue('price', value)}
              error={!!(formik.touched.price && formik.errors.price)}
              focus={!!product}
            />
          </div>
          {!isDesktop && (
            <div className="flex justify-center">
              <div className="flex space-x-2">
                <NewButton
                  text="Cancel"
                  style={{
                    backgroundColor: 'white',
                    color: theme.palette.primary[100].toHexString(),
                  }}
                  disabled={loading}
                  loading={loading}
                  type="button"
                  onClick={() => navigate(-1)}
                />

                <NewButton
                  text="Save Changes"
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
