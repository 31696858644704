import { gql, useLazyQuery } from '@apollo/client'
import { Config, readAndCompressImage } from 'browser-image-resizer'
import { useEffect, useState } from 'react'

const query = gql`
  query SignedS3URL($kitchenId: Int!) {
    signedS3Url(kitchenId: $kitchenId) {
      unsignedURL
      signedURL
    }
  }
`

interface SignedS3Url {
  signedURL: string
  unsignedURL: string
}

interface s3UploadOptions {
  setImageUrl: (value: string) => void
  kitchenId?: number
  imageSource?: string
  getFileName?: (file: File) => string
  resizeConfig?: Config
}

async function resize(file: File, resizeConfig: Config): Promise<File> {
  console.log(`Resizing upload ${file.name}`)

  if (!file.type.includes('image')) {
    return Promise.resolve(file)
  }

  const blob = await readAndCompressImage(file, resizeConfig)

  return new File([blob], file.name, {
    lastModified: file.lastModified,
    type: file.type,
  })
}

export const useS3Upload = (options: s3UploadOptions) => {
  const {
    setImageUrl,
    kitchenId,
    imageSource,
    resizeConfig,
    getFileName = (file) => file.name,
  } = options

  const [imageHref, setImageHef] = useState(imageSource)
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [getUrl] = useLazyQuery<{
    signedS3Url: SignedS3Url
  }>(query, {
    fetchPolicy: 'network-only',
    onCompleted: ({ signedS3Url }) => uploadFile(signedS3Url),
    variables: { kitchenId },
  })

  useEffect(() => {
    if (!file) {
      return
    }

    getUrl({ variables: { filename: getFileName(file) } }).catch((e) =>
      console.error('Error generating signed url.', e),
    )
  }, [file])

  async function uploadFile({ signedURL, unsignedURL }: SignedS3Url) {
    if (!file) {
      return
    }

    try {
      await fetch(signedURL, {
        body: file,
        headers: {
          'Cache-Control': 'max-age=3153600',
          'Content-Type': file.type,
        },
        method: 'PUT',
        mode: 'cors',
      })

      setFile(null)
      setImageHef(unsignedURL)
      setImageUrl(unsignedURL)
      setLoading(false)
    } catch (e) {
      console.error(e)
      setFile(null)
      setLoading(false)
    }
  }

  const onChange = (fileList: FileList | null) => {
    setLoading(true)

    const rawFile = fileList ? fileList[0] : null
    if (!rawFile) {
      return
    }

    if (!resizeConfig) {
      return setFile(rawFile)
    }

    resize(rawFile, resizeConfig)
      .then((resized) => setFile(resized))
      .catch((e) => console.error('Error occurred resizing image.', e))
  }

  return { imageHref, loading, onChange }
}
