import dayjs from 'dayjs'
import { sort } from 'ramda'
import { useMemo } from 'react'
import { CSVLink } from 'react-csv'

import { Maybe } from 'api'
import { DATE_FORMATS } from 'app'
import { compareNames } from 'screens/Stock/Edit/components/List'
import { StockTakeStoreStockTake, StockTakeEntry } from 'store/stocktake'

import { StyledWrapper, StyledDownloadIcon } from './styled'

type CSVData = {
  supplier?: Maybe<string>
  code?: Maybe<string>
  name?: Maybe<string>
  packSize?: Maybe<number>
  amount?: Maybe<number>
  unit?: Maybe<string>
  unitValue?: Maybe<number>
  waste?: Maybe<number>
  update?: Maybe<string>
  cost?: Maybe<string>
  total?: Maybe<string>
}

const stockItemToCSVRow = (entry: StockTakeEntry): CSVData => ({
  amount: Number(entry.quantity),
  code: entry.ingredient?.product.code,
  cost: (entry.ingredient?.price ?? entry.recipe?.unitCost)?.toFixed(2),
  name: entry.ingredient?.product.name ?? entry.recipe?.name,
  packSize: entry.ingredient?.product.packSize,
  supplier: entry.ingredient?.product.supplier.name,
  total: entry.total?.toFixed(2),
  unit: entry.ingredient?.product.unit.name ?? entry.recipe?.unit?.name,
  unitValue: entry.ingredient?.product.unitValue ?? entry.recipe?.amount,
  update:
    dayjs(entry.ingredient?.updatedAt).format(
      DATE_FORMATS.ANOTHER_DAY_FORMAT,
    ) ?? dayjs(entry.recipe?.updatedAt).format(DATE_FORMATS.ANOTHER_DAY_FORMAT),
  waste: entry.ingredient?.wastage,
})

export const CSVDownload = ({
  stocktake,
  temporaryStocktakeData,
}: {
  stocktake: StockTakeStoreStockTake
  temporaryStocktakeData: {
    date: Date | undefined
    name: string | null | undefined
  }
}) => {
  const csvData = useMemo(() => {
    if (!stocktake) return undefined
    const sortedData = sort(compareNames, stocktake.entries)
    const data = sortedData.map(stockItemToCSVRow)
    return [...data, { total: stocktake.total?.toFixed(2) }]
  }, [stocktake])

  return (
    <StyledWrapper>
      {csvData && (
        <CSVLink
          data={csvData}
          filename={`${
            temporaryStocktakeData.name
              ?.concat(
                `-${dayjs(temporaryStocktakeData.date)
                  .format(DATE_FORMATS.EXCEL)
                  .replaceAll(' ', '_')
                  .toLowerCase()}`,
              )
              ?.replaceAll(' ', '-')
              .toLowerCase() ??
            dayjs(temporaryStocktakeData.date)
              .format(DATE_FORMATS.EXCEL)
              .toLowerCase()
          }.csv`}
          title={`${temporaryStocktakeData.name} Recipe Data`}
          headers={[
            { key: 'name', label: 'name' },
            { key: 'update', label: 'last update' },
            { key: 'supplier', label: 'supplier' },
            { key: 'code', label: 'product code' },
            { key: 'waste', label: 'waste' },
            { key: 'packSize', label: 'pack size' },
            { key: 'unitValue', label: 'amount' },
            { key: 'unit', label: 'unit' },
            { key: 'amount', label: 'quantity' },
            { key: 'cost', label: 'cost' },
            { key: 'total', label: 'total' },
          ]}
        >
          <StyledDownloadIcon />
        </CSVLink>
      )}
    </StyledWrapper>
  )
}
