import { createTheme, adaptV4Theme, Theme } from '@mui/material/styles'

import { theme as newTheme } from './newUi'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

/**
 * @deprecated
 */
export const theme = createTheme(
  // https://mui.com/material-ui/migration/v5-style-changes/
  adaptV4Theme({
    breakpoints: {
      values: {
        lg: 1280,
        md: 850,
        sm: 600,
        xl: 1920,
        xs: 0,
      },
    },
    palette: {
      action: {
        disabledBackground: '#e0e0e0',
      },
      background: {
        default: 'white',
      },
      primary: {
        main: newTheme.palette.primary[100].toHexString(),
      },
      secondary: {
        main: newTheme.palette.tertiary[100].toHexString(),
      },
      text: {
        primary: newTheme.palette.primary[100].toHexString(),
      },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButtonGroup: {
        disableRipple: true,
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
      fontFamily: 'Rubik, sans-serif',
      fontSize: 16,
    },
  }),
)

// TODO: I don't think we use this, to be confirmed
export const darkTheme = createTheme(
  // https://mui.com/material-ui/migration/v5-style-changes/
  adaptV4Theme({
    ...theme,
    palette: {
      ...theme.palette,
      background: {
        default: newTheme.palette.primary[100].toHexString(),
      },
      primary: {
        main: theme.palette.common.white,
      },
      secondary: {
        main: newTheme.palette.secondary[100].toHexString(),
      },
      text: {
        primary: theme.palette.common.white,
      },
      // type: 'dark',
    },
  }),
)
