export function LogoInverted() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 126 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63 22.21C54.7743 22.21 46.7333 24.6492 39.8938 29.2192C33.0544 33.7892 27.7237 40.2846 24.5758 47.8842C21.428 55.4838 20.6044 63.8462 22.2091 71.9138C23.8139 79.9815 27.775 87.3921 33.5914 93.2086C39.4079 99.0251 46.8185 102.986 54.8862 104.591C62.9539 106.196 71.3162 105.372 78.9158 102.224C86.5154 99.0763 93.0108 93.7456 97.5808 86.9062C102.151 80.0667 104.59 72.0257 104.59 63.8C104.59 52.7697 100.208 42.1911 92.4086 34.3915C84.6089 26.5918 74.0304 22.21 63 22.21Z"
        fill="#F5D94F"
      />
      <path
        d="M85.13 81.32H75.84V59.32H67.65V81.32H58.35V59.32H50.16V81.32H40.87V60.43C40.87 54.5608 43.2015 48.9319 47.3517 44.7817C51.5019 40.6316 57.1308 38.3 63 38.3C68.8692 38.3 74.4981 40.6316 78.6483 44.7817C82.7984 48.9319 85.13 54.5608 85.13 60.43V81.32Z"
        fill="#1F304A"
      />
    </svg>
  )
}
