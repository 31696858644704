import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme) => ({
    leftSectionWrapperIcon: {
      color: theme.palette.error[100].toHexString(),
      cursor: 'pointer',
    },
    leftSectionWrppaer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      paddingRight: theme.spacing(1),
    },
    wrapper: {
      backgroundColor: theme.palette.common.light.toHexString(),
      borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
      display: 'flex',
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
  }))
