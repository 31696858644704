import dayjs from 'dayjs'

import { routes } from 'routes/Paths'

export type Data = typeof data

export const data = {
  '2021-11-16_ordering': {
    action: `${routes.Order}${routes.Supplier}`,
    cta: 'Place an Order',
    description:
      'Save time & avoid mistakes. Get supplies delivered to your kitchen in just a few taps. Order in-app today',
    endDate: dayjs('2021-11-24').startOf('day').toDate(),
    id: '2021-11-16_ordering',
    image: 'ordering',
    startDate: dayjs('2021-11-16').startOf('day').toDate(),
    title: 'Order from all your usual suppliers',
  },
}
