export type JWTSegments = [
  header: string,
  data: string | undefined,
  signature: string | undefined,
]

export type JWTData = {
  [key: string]: any
  exp: number
  iat: number
}

export function isValidJWTData(data: any): data is JWTData {
  return (
    data?.exp &&
    typeof data.exp === 'number' &&
    data.iat &&
    typeof data.iat === 'number'
  )
}

export function decode(token: string) {
  const segments = token.split('.') as JWTSegments
  const encdodedData = segments[1]

  if (encdodedData) {
    const decoded = JSON.parse(window.atob(encdodedData))

    if (isValidJWTData(decoded)) {
      return {
        data: decoded,
        token,
      }
    }
  }

  return null
}
