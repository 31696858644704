import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  path: string
  params?: { replace?: boolean; state?: Record<string, unknown> | null }
}

export function Redirect({ path, params }: Props) {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(path, { replace: true, ...params })
  }, [navigate, params, path])

  return null
}
