import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { QueryPurchaseOrderListArgs, Query } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import { Typography, Icon, Button } from 'components/newUi'
import { routes } from 'routes/Paths'
import { PriceHistoryChart } from 'screens/Products/Chart'
import { getPurchaseOrdersWithProduct } from 'screens/Products/OrderHistory/graphql'
import { useTheme } from 'styles/newUi'
import { decodeCursor } from 'utils'

type OrderQuantity = {
  date: string
  count: number
  orderCursor: string
}

export const OrderHistory = () => {
  const { id } = useParams()
  const { selectedKitchen } = useKitchen()
  const [orders, setOrders] = useState<OrderQuantity[]>()
  const { theme } = useTheme()
  const navigate = useNavigate()

  const ingredientId = decodeCursor(id)

  const { data, loading } = useQuery<
    {
      purchaseOrderList: Query['purchaseOrderList']
      ingredientNode: Query['ingredientNode']
    },
    QueryPurchaseOrderListArgs & {
      ingredientCursor: string
      ingredientId: number
      kitchenId: number
    }
  >(getPurchaseOrdersWithProduct, {
    fetchPolicy: 'no-cache',
    skip: !selectedKitchen?.id,
    variables: {
      ingredientCursor: id,
      ingredientId,
      // @ts-ignore skips if id undefined
      kitchenId: selectedKitchen?.id,
      where: {
        entries: {
          some: {
            product: {
              ingredients: {
                some: {
                  id: {
                    equals: ingredientId,
                  },
                  kitchenId: {
                    equals: selectedKitchen?.id,
                  },
                },
              },
            },
          },
        },
        kitchenId: {
          equals: selectedKitchen?.id,
        },
      },
    },
  })

  useEffect(() => {
    if (!data?.purchaseOrderList.nodes) return

    const reduced: OrderQuantity[] = data.purchaseOrderList.nodes.reduce(
      (acc, cur) => {
        const date = dayjs(cur.createdAt).format('DD MMM YYYY')

        acc.push({
          count:
            cur.entryList.nodes.find((item) =>
              item.product.ingredientList.nodes.some(
                (i) => i.id === ingredientId,
              ),
            )?.quantity ?? 0,
          date,
          orderCursor: cur._cursor,
        })
        return acc
      },
      [] as OrderQuantity[],
    )
    setOrders(reduced)
  }, [data, ingredientId])

  const items = Array.from(orders ?? [])
    .sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime()
    })
    .map((item) => {
      const { date, orderCursor, count } = item
      return (
        <Item
          key={orderCursor}
          date={date}
          change={0}
          quantity={count ?? 0}
          orderCursor={orderCursor}
        />
      )
    })

  const orderQuantities = data?.purchaseOrderList.nodes
    .flatMap((a) => a.entryList.nodes)
    .filter((en) =>
      en.product.ingredientList.nodes.some((a) => a.id === ingredientId),
    )
    .reverse()
    .map((order) => ({ price: order.quantity }))

  if (loading) return <Loader />

  return (
    <>
      {items.length > 0 && (
        <PriceHistoryChart
          data={orderQuantities ?? [{ price: 0 }]}
          fill={theme.palette.primary[80].toHexString()}
        />
      )}
      {items.length ? (
        items
      ) : (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: theme.spacing(4),
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.primary[80].toHexString(),
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            You have not placed an order with this product yet
          </Typography>

          {selectedKitchen?.userPermissions?.includes(
            'create-purchase-order',
          ) && (
            <Button
              text="Place an order"
              onClick={() => {
                navigate(
                  `${routes.Order}${routes.Supplier}/${data?.ingredientNode?.product.supplier.id}`,
                )
              }}
              style={{
                marginTop: theme.spacing(2),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

const ROW_HEIGHT = 49

export const Item = ({
  date,
  quantity,
  orderCursor,
}: {
  date: string
  change: number
  quantity: number
  orderCursor: string
}) => {
  const { theme } = useTheme()
  const navigate = useNavigate()

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        style={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
          cursor: 'pointer',
          display: 'grid',
          gap: '0 30px',
          gridTemplateColumns: '1fr 0.4fr 25px',
          justifyContent: 'center',
          minHeight: ROW_HEIGHT,
          paddingLeft: theme.spacing(2),
          paddingRight: 20,
          whiteSpace: 'nowrap',
          width: '100%',
        }}
        onClick={() =>
          navigate(`${routes.Order}${routes.History}/${orderCursor}`)
        }
      >
        <div style={{ overflow: 'hidden' }}>
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.primary[60].toHexString(),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
            }}
          >
            {dayjs(date).format('DD MMM YYYY')}
          </Typography>
        </div>
        <div
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 500,
              marginRight: -12,
            }}
          >
            x{quantity}
          </Typography>
        </div>
        <div style={{ alignItems: 'flex-end', display: 'flex' }}>
          <Icon
            iconName="chevronRight"
            style={{ marginLeft: 4, marginRight: -4 }}
          />
        </div>
      </div>
    </>
  )
}
