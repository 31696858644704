import { gql } from '@apollo/client'

import { supplierFragment } from 'api/graphql'

/* eslint-disable graphql/required-fields */
export const getSupplierAndKitchenQuery = gql`
  query SupplierNodeAndKitchenNode($cursor: ID!, $kitchenCursor: ID!) {
    me {
      _cursor
      id
      email
    }
    supplierNode(cursor: $cursor) {
      ...SupplierFields
    }
    kitchenNode(cursor: $kitchenCursor) {
      _cursor
      id
      name
      deliveryAddressList(take: 100) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
          skip
          take
          currentPage
          totalCount
          totalPages
        }
        nodes {
          _cursor
          id
          address {
            _cursor
            id
            line1
            line2
            line3
            city
            deliveryInstructions
            postCode
            country
            name
          }
        }
      }
    }
    lastOrderByUserNode(kitchenCursor: $kitchenCursor) {
      _cursor
      id
      address {
        _cursor
        id
      }
    }
  }
  ${supplierFragment}
`
/* eslint-enable graphql/required-fields */

export const createPurchaseOrderMutation = gql`
  mutation createPurchaseOrder(
    $addressName: String
    $kitchenAddressCursor: String
    $deliveryAddress: String!
    $deliveryDate: DateTime!
    $items: [PurchaseOrderEntryInput!]!
    $kitchenId: Int!
    $notes: String
    $siteName: String!
    $supplierEmail: String!
    $supplierId: Int!
    $data: UpdateOneUserInputObject!
  ) {
    updateOneUser(data: $data) {
      _cursor
      id
    }
    createOnePurchaseOrder(
      addressName: $addressName
      kitchenAddressCursor: $kitchenAddressCursor
      deliveryAddress: $deliveryAddress
      deliveryDate: $deliveryDate
      items: $items
      kitchenId: $kitchenId
      notes: $notes
      siteName: $siteName
      supplierEmail: $supplierEmail
      supplierId: $supplierId
    ) {
      _cursor
      id
      deliverySiteName
      deliverySiteAddress
    }
  }
`
