import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useKitchen } from 'app/contexts/SelectedKitchen'
import { NewTextField, Icon } from 'components/newUi'
import { selectComments, updateComments } from 'screens/Order/store'
import { useTheme } from 'styles/newUi'

interface Props {
  fetching: boolean
  focus?: boolean
}

export const Comments = ({ fetching, focus }: Props) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { selectedKitchen } = useKitchen()
  const comments = useSelector(selectComments(`${selectedKitchen?.id}:${id}`))
  const [comment, setComment] = useState('')
  const [loaded, setLoaded] = useState(false)
  const { theme } = useTheme()

  useEffect(() => {
    if (focus) document.getElementById('commentsInput')?.focus()
  }, [focus])

  useEffect(() => {
    if (loaded || !comments || fetching) return
    setComment(comments)
    setLoaded(true)
  }, [fetching, comments, loaded])

  const handleUpdateComments = (value: string) => {
    setComment(value)
    dispatch(
      updateComments({ comments: value, id: `${selectedKitchen?.id}:${id}` }),
    )
  }

  return (
    <div
      style={{
        alignItems: 'center',
        color: theme.palette.primary[40].toHexString(),
        display: 'flex',
        justifyContent: 'left',
        padding: '0px 15px',
        width: '100%',
      }}
    >
      <Icon iconName="message" />
      <NewTextField
        focus={focus}
        key={1}
        value={comment}
        onChange={(value: any) => handleUpdateComments(value)}
        style={{ width: '100%' }}
        autoComplete={false}
        noBorder
        inputProps={{
          'data-hj-allow': '',
          id: 'commentsInput',
          style: { width: '100%' },
        }}
        placeholder="Add a message for the supplier..."
      />
    </div>
  )
}
