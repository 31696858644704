import { theme as newTheme } from './newUi'

/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
/**
 * @deprecated
 * READ THE DEPRECATION FLAG. DO NOT USE THESE. USE THE NEW UI THEME
 */
export const PRIMARY = newTheme.palette.primary[100].toHexString()
export const SECONDARY = newTheme.palette.tertiary[100].toHexString()

export const jellyBlue = newTheme.palette.primary[100].toHexString()
export const jellyRed = newTheme.palette.error[100].toHexString()
export const jellyYellow = newTheme.palette.tertiary[100].toHexString()

export const FORM_GREY = 'rgb(155, 155, 155)' // #9b9b9b

export const LIGHTER_GREY = '#E2E2E2'
export const LIGHT_GREY = '#EBEBEB'
export const GREY = newTheme.palette.primary[40].toHexString()
export const GREY2 = newTheme.palette.primary[20].toHexString()
export const DARK_GREY = newTheme.palette.primary[80].toHexString()
export const GREEN = '#D4EC94'
export const SELECTED_GREEN = '#6FCF97'
export const SUCCESS_GREEN = newTheme.palette.success[100].toHexString()
export const ALERT_GREEN = '#BACF6B'

export const panelBackgroundColour = '#f5f5f5'
export const fieldBackgroundColor = 'rgb(250, 250, 250)'
export const pannelBorderColor = 'rgb(221, 221, 221)'
export const labelBackgroundColor = '#F3F3F3'

export const navigationBlue = 'rgb(51, 102, 187)'
export const lightBlue = newTheme.palette.secondary[100].toHexString()
