import { Props } from 'components/newUi/Dropdown/types'

export function getCurrentOption(
  defaultOption: Props['defaultOption'],
  options: Props['options'],
) {
  if (defaultOption && options) {
    for (const option of options) {
      if (option.options) {
        for (const subOption of option.options) {
          if (defaultOption.value === subOption.value) {
            return subOption
          }
        }
      } else {
        if (defaultOption.value === option.value) {
          return option
        }
      }
    }
  }

  return undefined
}
