import { Typography } from '@mui/material'
import { ReactElement, forwardRef } from 'react'
import { AutoSizer, List, OnScrollParams } from 'react-virtualized'

import { PageInfo } from 'api'
import { Loader } from 'components/Loader'
import { FlatlistRow, HeaderRow } from 'screens/Order/Products'
import { useTheme } from 'styles/newUi'

import { SectionTitle } from './SectionTitle'

type Props = {
  data: FlatlistRow[]
  loading: boolean
  Item: (props: any) => ReactElement
  pageInfo?: PageInfo
  fetchMore?: () => void
  ROW_HEIGHT: number
  heightModification?: number
  emptyMessage?: React.ReactElement | string
  footer?: React.ReactElement
}

const HEADER_HEIGHT = 33

export const VirtualizedFlatList = forwardRef(
  function VirtualizedFlatListComponent(
    {
      ROW_HEIGHT,
      Item,
      data,
      loading,
      pageInfo,
      fetchMore,
      heightModification,
      emptyMessage,
      footer,
    }: Props,
    ref,
  ) {
    const { theme } = useTheme()
    const dataWithFooter = data.length
      ? footer
        ? [...data, { __typename: 'FooterRow', label: 'FOOTER' } as HeaderRow]
        : data
      : []

    return (
      <AutoSizer>
        {({ height, width }) => {
          const rowCount = dataWithFooter?.length ?? 0
          return (
            <List
              // @ts-ignore
              ref={ref}
              containerStyle={{ overflow: 'hidden' }}
              width={width}
              height={height + (heightModification ?? 0)}
              rowCount={rowCount}
              rowHeight={({ index }) => {
                if (
                  dataWithFooter[index] &&
                  dataWithFooter[index].__typename === 'HeaderRow'
                ) {
                  return HEADER_HEIGHT
                }
                if (
                  dataWithFooter[index] &&
                  dataWithFooter[index].__typename === 'FooterRow'
                ) {
                  return 105
                }
                return ROW_HEIGHT
              }}
              overscanRowCount={10}
              onScroll={({
                scrollTop,
                scrollHeight,
                clientHeight,
              }: OnScrollParams) => {
                if (
                  scrollTop > scrollHeight - clientHeight * 3 &&
                  !loading &&
                  pageInfo?.hasNextPage &&
                  fetchMore
                ) {
                  fetchMore()
                }
              }}
              noRowsRenderer={() => (
                <>
                  {loading ? (
                    <Loader />
                  ) : (
                    emptyMessage ||
                    footer || (
                      <>
                        <div
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: theme.spacing(1),
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{
                              color: theme.palette.primary[60].toHexString(),
                            }}
                          >
                            Could not find any items
                          </Typography>
                        </div>
                        {footer}
                      </>
                    )
                  )}
                </>
              )}
              rowRenderer={({ index, style, key, ...props }) => {
                if (!dataWithFooter[index]) return <div>empty row!!</div>

                const rowData = dataWithFooter[index]

                if (rowData.__typename === 'HeaderRow') {
                  return (
                    <SectionTitle
                      style={style}
                      key={key}
                      HEADER_HEIGHT={HEADER_HEIGHT}
                      label={`${rowData.label}`}
                    />
                  )
                }

                if (rowData.__typename === 'FooterRow') {
                  return (
                    <div style={style} key={key}>
                      {footer}
                    </div>
                  )
                }

                return (
                  <Item
                    data-testid={rowData.name}
                    key={key}
                    index={index}
                    {...props}
                    data={rowData}
                    style={{ ...rowData.itemStyle, ...style }}
                  />
                )
              }}
            />
          )
        }}
      </AutoSizer>
    )
  },
)
