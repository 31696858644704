import { Config, readAndCompressImage } from 'browser-image-resizer'

import { client, QueryMultipleSignedS3UrlsArgs, SignedS3Url } from 'api'

import { signMultipleURLS } from './graphql'

export type Upload =
  | {
      identifier: string
    } & (
      | {
          file: File
          status: 'pending' | 'processing' | 'failed'
        }
      | {
          status: 'complete'
          file: undefined
        }
    )

const uniqueIdentifier = (file: File): string =>
  `${file.name}-${file.lastModified.toString()}`

export abstract class Uploader {
  public isComplete = false
  public isProcessing = false

  public uploads: Upload[] = []

  constructor(
    private callback?: (uploads: Upload[]) => void,
    protected kitchenId?: number,
  ) {}

  abstract getResizeConfig(): Config

  abstract getFilename(upload: Upload): string

  abstract submitUploads(
    transfers: { upload: Upload; signedURL: string; unsignedURL: string }[],
  ): Promise<Upload[]>

  retry(identifier: string) {
    const toUpload = this.uploads.find(
      (upload) => identifier === upload.identifier,
    )

    if (!toUpload) return

    toUpload.status = 'pending'

    this.isComplete = false

    if (!this.isProcessing) {
      this.processFiles()
    }
  }

  addFiles(files: File[]) {
    const toAdd = files
      .map(
        (file) =>
          ({
            file,
            identifier: uniqueIdentifier(file),
            status: 'pending',
          } as Upload),
      )
      .filter(
        (upload) =>
          !this.uploads.find(
            (existing) => existing.identifier === upload.identifier,
          ),
      )

    this.callback!(toAdd)

    this.uploads = this.uploads.concat(toAdd)
    this.isComplete = false

    if (!this.isProcessing) {
      this.processFiles()
    }
  }

  async processFiles() {
    this.isProcessing = true

    while (!this.isComplete) {
      // Process in batches of 5 to keep memory usage down
      const toProcess = this.uploads
        .filter((upload) => upload.status === 'pending')
        .slice(0, 5)
        .map((upload) => {
          upload.status = 'processing'
          return upload
        })

      if (toProcess.length === 0) {
        this.isProcessing = false
        this.isComplete = true
        return
      }

      this.callback!(toProcess)

      const processing = toProcess.map((upload) => this.resize(upload))

      const resized = await Promise.all(processing)

      await this.uploadImages(resized)
    }
  }

  private async uploadImages(uploads: Upload[]) {
    const transfers = await this.generateAndInitiateUploads(uploads)

    await Promise.all(transfers)
      .then((results) => this.submitUploads(results))
      .then((results) => this.callback!(results))
  }

  getExtension(upload: Upload) {
    return upload.file!.type.split('/')[1]
  }

  getContentType(upload: Upload): string {
    return upload.file!.type
  }

  private async generateAndInitiateUploads(
    uploads: Upload[],
  ): Promise<
    Promise<{ signedURL: string; unsignedURL: string; upload: Upload }>[]
  > {
    const urls = uploads.map((upload) => ({
      extension: this.getExtension(upload),
      filename: this.getFilename(upload),
      kitchenId: this.kitchenId ?? 0,
      params: { ContentType: this.getContentType(upload) },
      secure: true,
    }))

    const { data, error } = await client.query<
      { MultipleSignedS3Urls: SignedS3Url[] },
      QueryMultipleSignedS3UrlsArgs
    >({ query: signMultipleURLS, variables: { urls } })

    if (error) {
      for (const upload of uploads) {
        upload.status = 'failed'
      }

      this.callback!(uploads)
    }

    return data.MultipleSignedS3Urls.map(
      async ({ signedURL, unsignedURL }, index) => {
        const upload = uploads[index]

        try {
          const response = await fetch(signedURL, {
            body: upload.file,
            headers: {
              'Cache-Control': 'max-age=3153600',
              'Content-Type': upload.file!.type,
            },
            method: 'PUT',
            mode: 'cors',
          })

          if (response.status !== 200) {
            upload.status = 'failed'
          }

          return { signedURL, unsignedURL, upload }
        } catch (e) {
          upload.status = 'failed'
          return { signedURL, unsignedURL, upload }
        }
      },
    )
  }

  private async resize(upload: Upload): Promise<Upload> {
    console.log(`Resizing upload ${upload.identifier}`)

    if (!upload.file!.type.includes('image')) {
      return Promise.resolve(upload)
    }

    const blob = await readAndCompressImage(
      upload.file!,
      this.getResizeConfig(),
    )

    upload.file = new File([blob], upload.file!.name, {
      lastModified: upload.file!.lastModified,
      type: upload.file!.type,
    })

    return upload
  }
}
