import { useDispatch, useSelector } from 'react-redux'

import { CountPayload, selectCounts, setCounts } from '../../store/kitchen'

import { RegisterListener } from './index'

type Props = {
  registerListener: RegisterListener
}

const listenerId = 'IncrementCounts.tsx'

export function IncrementCounts({ registerListener }: Props) {
  const dispatch = useDispatch()
  const counts = useSelector(selectCounts())

  function increment(key: keyof CountPayload) {
    dispatch(setCounts({ ...counts, [key]: counts[key] + 1 }))
  }

  registerListener(listenerId, 'dish-created', () => increment('dishCount'))
  registerListener(listenerId, 'ingredient-created', () => increment('ingredientCount'))
  registerListener(listenerId, 'menu-created', () => increment('menuCount'))
  registerListener(listenerId, 'order-created', () => increment('orderCount'))
  registerListener(listenerId, 'pending-invoice-created', () => increment('invoiceCount'))
  registerListener(listenerId, 'recipe-created', () => increment('recipeCount'))
  registerListener(listenerId, 'sale-created', () => increment('salesCount'))
  registerListener(listenerId, 'sale-updated', () => increment('salesCount'))
  registerListener(listenerId, 'stock-created', () => increment('stocktakeCount'))

  return <></>
}
