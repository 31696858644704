import { useQuery } from '@apollo/client'
import { Button } from '@getjelly/jelly-ui'
import { IconCheck } from '@tabler/icons-react'
import { useParams } from 'react-router-dom'

import { Query } from 'api'
import { AvailablePlan } from 'api/types'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { NewLayout, Typography } from 'components/newUi'
import { useTheme } from 'styles/newUi'

import { getAvailablePlans } from './graphql'

export function PlanDetails() {
  const { selectedKitchen } = useKitchen()
  const { theme } = useTheme()

  const { id } = useParams()
  let selectedPlan = {} as AvailablePlan

  const { data: plansData } = useQuery<{
    availablePlans: Query['availablePlans']
  }>(getAvailablePlans, { variables: { cursor: selectedKitchen?._cursor } })

  plansData?.availablePlans.forEach((availablePlan) => {
    if (availablePlan.tier === id) {
      selectedPlan = availablePlan
    }
  })

  return (
    <>
      <NewLayout
        title={selectedPlan?.name}
        subtitle={selectedKitchen?.name}
        bottomBackgroundColor="white"
        bottomContent={
          <div className="px-2 py-4">
            <Button
              onClick={() => {
                window.open(selectedPlan?.payment_link || '')
              }}
              disabled={
                !(
                  (selectedPlan.tier === 'PLUS' &&
                    ['TRIAL', 'STANDARD'].includes(
                      selectedKitchen?.permissions?.tier || '',
                    )) ||
                  (selectedPlan.tier === 'PRO' &&
                    ['TRIAL', 'STANDARD', 'PLUS'].includes(
                      selectedKitchen?.permissions?.tier || '',
                    ))
                )
              }
              className="w-full"
              label="Choose This Plan"
            />
          </div>
        }
      />

      <div>
        <div
          style={{
            backgroundColor: theme.palette.common.light.toHexString(),
            borderRadius: '0.5rem',
            boxShadow: theme.elevation[1],
            margin: 10,
            padding: '1rem',
            width: 'calc(100% - 20px)',
          }}
        >
          <div style={{ marginBottom: '2rem' }}>
            <Typography
              variant="body1"
              style={{
                marginBottom: '0.5rem',
              }}
            >
              {`£${selectedPlan.price} per site per month`}
            </Typography>

            <div
              style={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <Typography variant="subtitle1">
                    {selectedPlan.description}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            {selectedPlan?.features?.map((feature, index) => (
              <div key={index} className="flex items-center space-x-2">
                <IconCheck />

                <Typography variant="subtitle1">{feature}</Typography>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        style={{
          marginBottom: theme.spacing(1),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }}
      ></div>
      <div
        style={{
          padding: theme.spacing(2),
          textAlign: 'center',
        }}
      >
        <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
          Need help with anything?
        </Typography>

        <Typography variant="subtitle1" style={{ marginTop: theme.spacing(1) }}>
          Contact{' '}
          <a
            href="mailto:letscook@getjelly.co.uk"
            style={{
              color: theme.palette.primary[100].toHexString(),
            }}
          >
            letscook@getjelly.co.uk
          </a>
        </Typography>
      </div>
    </>
  )
}
