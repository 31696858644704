import { api } from 'api/rest'
import { VITE_REACT_APP_ENV } from 'app'

import { Kitchen } from '../../../../api'

export async function getInvoiceCode(kitchen: Kitchen): Promise<string> {
  const config = { params: { kitchenId: kitchen.id } }
  const response = await api.get<{ code: string }>(
    `/rest/invoices/code`,
    config,
  )

  const prefix = VITE_REACT_APP_ENV === 'production' ? 'invoices' : 'staging'

  return `${prefix}+${response.data.code}@getjelly.co.uk`
}
