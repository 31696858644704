import { DashboardNav, DashboardNavItem } from '@getjelly/jelly-ui'
import { IconBasket, IconBook2, IconBuildingStore } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

import { Portal } from '../../components/newUi/NewLayout'
import { routes } from '../../routes/Paths'

export function KitchenNavigation() {
  const navigate = useNavigate()

  return (
    <Portal id="react-top-portal">
      <DashboardNav>
        <DashboardNavItem
          title="Cookbook"
          icon={IconBook2}
          onClick={() => navigate(routes.Book + routes.List)}
        />

        <DashboardNavItem
          title="Stock"
          icon={IconBasket}
          onClick={() => navigate(routes.Stock + routes.Take)}
        />

        <DashboardNavItem
          title="Order"
          icon={IconBuildingStore}
          onClick={() => navigate(routes.Order + routes.History)}
        />
      </DashboardNav>
    </Portal>
  )
}
