import { gql } from '@apollo/client'

export const getPurchaseOrdersQuery = gql`
  query PurchaseOrders(
    $cursor: ID
    $orderBy: [PurchaseOrderOrderByInput!]
    $skip: Int
    $take: Int
    $where: PurchaseOrderWhereInput
  ) {
    purchaseOrderList(
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
        skip
        take
        currentPage
        totalCount
        totalPages
      }
      nodes {
        _cursor
        id
        createdAt
        createdBy {
          _cursor
          id
          firstName
          lastName
        }
        number
        supplier {
          _cursor
          id
          name
        }
        notes
      }
    }
  }
`

export const getPurchaseOrderQuery = gql`
  query PurchaseOrder($cursor: ID!, $kitchenId: Int) {
    purchaseOrderNode(cursor: $cursor) {
      _cursor
      id
      createdAt
      createdBy {
        _cursor
        id
        firstName
        lastName
      }
      number
      supplier {
        _cursor
        id
        name
      }
      notes
      deliverySiteName
      deliverySiteAddress
      deliveryDate
      entryList(take: 100) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
          skip
          take
          currentPage
          totalCount
          totalPages
        }
        nodes {
          _cursor
          id
          product {
            _cursor
            id
            name
            status
            ingredientList(where: { kitchenId: { equals: $kitchenId } }) {
              nodes {
                _cursor
                id
              }
            }
          }
          price
          packSize
          unitValue
          unit {
            _cursor
            id
            name
            type
          }
          quantity
        }
      }
    }
  }
`
