import { Typography } from '@mui/material'
import { startsWith } from 'ramda'
import { CSSProperties, ReactElement, useEffect, useRef, useState } from 'react'
import { AutoSizer, List } from 'react-virtualized'

import { Loader } from 'components/Loader'
import { useTheme } from 'styles/newUi'

import { SectionTitle } from './SectionTitle'

type Props<T> = {
  data: {
    label?: string
    items: T[]
    rightAdornment?: React.ReactElement
    itemStyle?: boolean
  }[]
  loading: boolean
  Item: (props: any) => ReactElement
  // TODO: look into it
  // other props don't seem to be used but a lot of the queries still queries for them
  // not sure how the pagination is handled
  pageInfo?: { hasNextPage: boolean } & Record<string, any>
  fetchMore?: () => void
  ROW_HEIGHT: number | ((item: T) => number)
  heightModification?: number
  emptyMessage?: React.ReactElement | string
  footer?: React.ReactElement
  footerHeight?: number
}

type SectionProps<T> = {
  index: number
  style: CSSProperties
  data: {
    label?: string
    items: T[]
    rightAdornment?: React.ReactElement
    itemStyle?: boolean
  }[]
  Item: (props: any) => ReactElement
  ROW_HEIGHT: number | ((item: T) => number)
  loading: boolean
  pageInfo?: { hasNextPage: boolean } & Record<string, any>
  fetchMore?: () => void
  footer?: React.ReactElement
  footerHeight?: number
}

const HEADER_HEIGHT = 33

export function VirtualizedSectionList<T>({
  ROW_HEIGHT,
  Item,
  data,
  loading,
  pageInfo,
  fetchMore,
  heightModification,
  emptyMessage,
  footer,
  footerHeight,
}: Props<T>) {
  const { theme } = useTheme()
  const [itemMap, setItemMap] = useState<number[]>([])
  const [getMoreScrollThreshold, setGetMoreScrollThreshold] = useState(85)
  const listRef = useRef()
  const dataWithFooter = data.length
    ? footer
      ? [...data, { items: [], label: 'FOOTER' }]
      : data
    : []

  useEffect(() => {
    const totalItems = data.reduce(
      (acc, section) => acc + section.items.length,
      0,
    )
    const newThreshold = Math.max(50, 100 - totalItems / 20) // Example calculation
    setGetMoreScrollThreshold(newThreshold)
    const items = data.map((item) => item.items.length)
    setItemMap(items)

    if (!startsWith(itemMap, items) || itemMap.length !== items.length) {
      // @ts-ignore
      listRef.current?.recomputeRowHeights()
    }
  }, [data])

  return (
    <AutoSizer>
      {({ height, width }) => {
        const rowCount = dataWithFooter?.length ?? 0
        return (
          <List
            // @ts-ignore
            ref={listRef}
            containerStyle={{ overflow: 'hidden' }}
            width={width}
            height={height + (heightModification ?? 0)}
            rowCount={rowCount}
            rowHeight={({ index }) => {
              if (dataWithFooter[index].label === 'FOOTER') {
                return HEADER_HEIGHT + (footerHeight || 80)
              }

              if (typeof ROW_HEIGHT === 'function') {
                return (
                  dataWithFooter[index].items.reduce(
                    (acc, item) => acc + ROW_HEIGHT(item),
                    0,
                  ) + (dataWithFooter[index].label ? HEADER_HEIGHT : 0)
                )
              }

              return (
                dataWithFooter[index].items.length * ROW_HEIGHT +
                (dataWithFooter[index].label ? HEADER_HEIGHT : 0)
              )
            }}
            overscanRowCount={10}
            onScroll={({ scrollTop, scrollHeight, clientHeight }) => {
              const scrollPercentage = Math.max(
                0,
                Math.min(
                  100,
                  (scrollTop / (scrollHeight - clientHeight)) * 100,
                ),
              )

              if (
                scrollPercentage > getMoreScrollThreshold &&
                !loading &&
                pageInfo?.hasNextPage
              ) {
                fetchMore?.()
              }
            }}
            noRowsRenderer={() => (
              <>
                {loading ? (
                  <Loader />
                ) : (
                  emptyMessage ||
                  footer || (
                    <>
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          padding: theme.spacing(1),
                          width: '100%',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: theme.palette.primary[60].toHexString(),
                          }}
                        >
                          Could not find any items
                        </Typography>
                      </div>
                      {footer}
                    </>
                  )
                )}
              </>
            )}
            rowRenderer={(props) => (
              <Section
                {...props}
                data={dataWithFooter}
                Item={Item}
                ROW_HEIGHT={ROW_HEIGHT}
                loading={loading}
                fetchMore={fetchMore}
                pageInfo={pageInfo}
                footer={footer}
              />
            )}
          />
        )
      }}
    </AutoSizer>
  )
}

export function Section<T>(props: SectionProps<T>) {
  const { index, style, data, Item, ROW_HEIGHT, footer } = props

  const rowData = data[index]
  if (rowData.label === 'FOOTER')
    return (
      <div style={style} key={`footer-key-${rowData.label}`}>
        {footer}
      </div>
    )
  return (
    <div style={style} key={`div-key-${rowData.label}`}>
      {rowData.label && (
        <SectionTitle
          HEADER_HEIGHT={HEADER_HEIGHT}
          label={rowData.label}
          rightAdornment={rowData.rightAdornment}
        />
      )}

      <AutoSizer>
        {({ height, width }) => (
          <List
            containerStyle={{ overflow: 'hidden' }}
            style={{
              overflowX: 'hidden',
            }}
            width={width}
            height={height}
            rowCount={rowData.items.length}
            rowHeight={
              typeof ROW_HEIGHT === 'function'
                ? ({ index: i }) => ROW_HEIGHT(rowData.items[i])
                : ROW_HEIGHT
            }
            rowRenderer={(props) => (
              <Item
                {...props}
                data-testid={rowData.label}
                data={rowData}
                // @ts-ignore
                style={{ ...props.style, ...rowData.itemStyle }}
                // @ts-ignore
                key={props.id || props.key || `wrapper-key-${rowData.label}`}
              />
            )}
          />
        )}
      </AutoSizer>
    </div>
  )
}
