import { gql } from '@apollo/client'

export const postVerifyEmailMutation = gql`
  mutation verifyEmail($emailVerificationCode: String!) {
    verifyEmail(emailVerificationCode: $emailVerificationCode) {
      _cursor
      id
    }
  }
`
