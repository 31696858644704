import { gql } from '@apollo/client'

export const getIngredientsQuery = gql`
  query IngredientsProductPage(
    $cursor: ID
    $kitchenId: Int!
    $orderBy: [IngredientOrderByInput!]
    $search: String
    $skip: Int
    $take: Int
    $where: IngredientWhereInput
  ) {
    ingredientList(
      cursor: $cursor
      kitchenId: $kitchenId
      orderBy: $orderBy
      search: $search
      skip: $skip
      take: $take
      where: $where
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
        skip
        take
        currentPage
        totalCount
        totalPages
      }
      nodes {
        _cursor
        id
        createdAt
        updatedAt
        price
        wastage
        unitPrice
        wasteAdjustedUnitPrice
        conversionUnit
        conversionUnitType {
          _cursor
          id
          name
          type
          gramsPerUnit
        }
        conversionUnitValue
        product {
          _cursor
          id
          name
          packSize
          status
          code
          unitValue
          unit {
            _cursor
            id
            name
            type
          }
          supplier {
            _cursor
            id
            name
          }
        }
      }
    }
  }
`

export const getIngredientQuery = gql`
  query Ingredient($cursor: ID!, $skip: Int, $take: Int) {
    ingredientNode(cursor: $cursor) {
      _cursor
      id
      price
      wastage
      conversionUnit
      conversionUnitType {
        _cursor
        id
        name
      }
      conversionUnitValue
      priceChangesList(skip: $skip, take: $take) {
        total
        priceChanges {
          changes {
            _cursor
            id
            price
            supplierName
            ingredientName
            percentageChange
          }
          date
        }
      }
      product {
        _cursor
        code
        id
        status
        name
        packSize
        unitValue
        unit {
          _cursor
          id
          name
          type
        }
        supplier {
          _cursor
          id
          name
        }
      }
    }
  }
`
