import { useMutation } from '@apollo/client'
import { useState } from 'react'

import {
  Recipe,
  Kitchen,
  Mutation,
  MutationDuplicateOneRecipeArgs,
} from '../../../api'
import { errorToast, successToast } from '../../../components/toasts'
import { cleanErrorMessage } from '../../../utils'
import { copyRecipeMutation } from '../graphql/recipes'

export function useCopier() {
  const [loading, setLoading] = useState(false)
  const [duplicateOneRecipe] = useMutation<
    { duplicateOneRecipe: Mutation['duplicateOneRecipe'] },
    MutationDuplicateOneRecipeArgs
  >(copyRecipeMutation)

  async function handleCopy(recipe: Recipe, kitchen: Kitchen) {
    if (loading) {
      return
    }

    setLoading(true)

    try {
      await duplicateOneRecipe({
        variables: {
          kitchenId: kitchen.id,
          recipeId: recipe.id,
        },
      })

      successToast('Successfully copied.')
    } catch (e) {
      console.error(e)

      if (e instanceof Error) {
        errorToast(cleanErrorMessage(e.message))
      } else {
        errorToast('Unknown error occurred.')
      }
    } finally {
      setLoading(false)
    }
  }

  return { handleCopy, loading }
}
