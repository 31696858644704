import { useQuery } from '@apollo/client'
import {
  AlertPriceChangeEmptyState,
  AlertPriceChangeNotPaidState,
  InsightsListGroup,
  InsightsListItem,
} from '@getjelly/jelly-ui'
import { IconTrendingDown, IconTrendingUp } from '@tabler/icons-react'
import {
  endOfWeek,
  format,
  isThisYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import { useState, useMemo, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AutoSizer, List } from 'react-virtualized'

import { PriceChange, Query } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader, SupplierSelect } from 'components'
import { routes } from 'routes/Paths'

import { ingredientChangesQuery } from './graphql'

import {
  CalendarGroupSelector,
  DashboardGroup,
  dashboardGroups,
} from '../../../components/Insights/CalendarGroupSelector'
import { useIsPaidKitchen } from '../../../hooks'
import { selectCounts } from '../../../store/kitchen'
import { selectSupplier } from '../../../store/supplier'
import { encodeCursor } from '../../../utils'

type TransformedPriceChange = {
  label: string
  items: PriceChange[]
}

type IngredientDifferent = { start: PriceChange; end?: PriceChange }
export const PriceAlert = () => {
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()
  const isFeatureAvailable = useIsPaidKitchen()
  const counts = useSelector(selectCounts())
  const supplier = useSelector(selectSupplier())

  const [grouping, setGrouping] = useState<DashboardGroup>(dashboardGroups[0])

  const { data: priceChangesQuery, loading } = useQuery<{
    kitchen: Query['kitchen']
  }>(ingredientChangesQuery, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !selectedKitchen,
    variables: {
      skip: 0,
      take: 50,
      where: {
        id: selectedKitchen?.id,
      },
    },
  })

  function getLabel(date: Date) {
    let label = ''
    if (grouping.id === 'day') {
      label += format(date, 'd MMM')
    }

    if (grouping.id === 'week') {
      label += `${format(date, 'd')} - ${format(
        endOfWeek(date, { weekStartsOn: 1 }),
        'd MMM',
      )}`
    }

    if (grouping.id === 'month') {
      label += format(date, 'MMM')
    }

    if (!isThisYear(date)) {
      label = `${label} ${format(date, 'yyyy')}`
    }

    return label
  }

  const data = useMemo(() => {
    if (!priceChangesQuery?.kitchen?.priceChangesList?.priceChanges) return []
    const { priceChanges } = priceChangesQuery.kitchen.priceChangesList

    // First, we need to bucket all the price changes by day / week / month.
    const bucketedData: Record<string, (PriceChange & { date: Date })[]> = {}
    for (const priceChange of priceChanges) {
      if (!priceChange) continue
      const { changes, date } = priceChange
      const dateObject = new Date(date)

      let bucketKey: string

      if (grouping.id === 'day') {
        bucketKey = format(startOfDay(dateObject), 'yyyy-MM-dd')
      } else if (grouping.id === 'week') {
        bucketKey = format(
          startOfWeek(dateObject, { weekStartsOn: 1 }),
          'yyyy-MM-dd',
        )
      } else if (grouping.id === 'month') {
        bucketKey = format(startOfMonth(dateObject), 'yyyy-MM-dd')
      } else {
        continue // Invalid grouping id, skip this price change
      }

      // When a new ingredient is created, it creates a price change with 0%.
      // We filter those out here.
      if (changes.every((c) => c && c.percentageChange === 0)) {
        continue
      }

      if (
        supplier?.id &&
        changes.every((c) => c && c.supplierId !== supplier.id)
      ) {
        continue
      }

      if (!bucketedData[bucketKey]) {
        bucketedData[bucketKey] = []
      }

      for (const change of changes) {
        if (!change) continue
        if (change.percentageChange === 0) continue
        if (supplier?.id && change.supplierId !== supplier.id) continue
        bucketedData[bucketKey].push({ ...change, date: dateObject })
      }
    }

    const transformedData: TransformedPriceChange[] = []
    for (const [date, changes] of Object.entries(bucketedData)) {
      const sortedChanges = changes.sort((a, b) => {
        if (a.date > b.date) return 1
        if (a.date < b.date) return -1
        return 0
      })

      const ingredientStore: Record<number, IngredientDifferent> = {}

      for (const change of sortedChanges) {
        if (!ingredientStore[change.id]) {
          ingredientStore[change.id] = { start: change }
        } else {
          ingredientStore[change.id].end = change
        }
      }

      let finalPriceChanges: PriceChange[] = []
      for (const difference of Object.values(ingredientStore)) {
        if (!difference.end) {
          finalPriceChanges.push(difference.start)
          continue
        }

        const initialPrice =
          difference.start.price / (difference.start.percentageChange / 100 + 1)

        const finalPrice = difference.end.price

        const pctChange = (finalPrice - initialPrice) / initialPrice

        finalPriceChanges.push({
          ...difference.end,
          percentageChange: pctChange * 100,
        })
      }

      finalPriceChanges = finalPriceChanges.sort((a, b) => {
        if (Math.abs(a.percentageChange) > Math.abs(b.percentageChange))
          return -1
        if (Math.abs(a.percentageChange) < Math.abs(b.percentageChange))
          return 1
        return 0
      })

      transformedData.push({
        items: finalPriceChanges,
        label: getLabel(new Date(date)),
      })
    }

    return transformedData
  }, [priceChangesQuery, grouping, supplier])

  const listRef = useRef<List>(null)

  useEffect(() => {
    if (listRef.current) {
      listRef.current.recomputeRowHeights()
    }
  }, [data])

  if (!isFeatureAvailable) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <AlertPriceChangeNotPaidState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  if (counts.priceChangeCount === 0 && (data.length ?? 0) === 0) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <AlertPriceChangeEmptyState
            ctaClicked={() =>
              navigate(routes.Spending + routes.Invoice + routes.Add)
            }
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="h-12 flex items-center bg-primary-50">
        <SupplierSelect title="Insights" />
        <CalendarGroupSelector value={grouping} onChange={setGrouping} />
      </div>

      <div className="h-full w-full">
        {!data.length && loading ? (
          <Loader />
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={listRef}
                width={width}
                height={height}
                rowCount={data.length}
                rowHeight={({ index }) =>
                  // Group title = 40
                  // Group items = 87
                  // The last item doesn't have a bottom border = -1
                  40 + data[index].items.length * 87 - 1
                }
                rowRenderer={({ index, key, style }) => {
                  const group = data[index]

                  return (
                    <div key={key} style={style}>
                      <InsightsListGroup title={group.label}>
                        {group.items.map((item, j) => (
                          <InsightsListItem
                            key={j}
                            className="capitalize"
                            title={item.ingredientName.toLowerCase()}
                            subtitle={item.supplierName.toLowerCase()}
                            accent={
                              item.percentageChange > 0 ? 'error' : 'success'
                            }
                            icon={
                              item.percentageChange > 0
                                ? IconTrendingUp
                                : IconTrendingDown
                            }
                            onClick={() =>
                              navigate(
                                `${routes.Product}/${encodeCursor(
                                  'Ingredient',
                                  item.id,
                                )}`,
                              )
                            }
                            data={
                              Math.round(Math.abs(item.percentageChange)) + '%'
                            }
                          />
                        ))}
                      </InsightsListGroup>
                    </div>
                  )
                }}
              />
            )}
          </AutoSizer>
        )}
      </div>
    </>
  )
}
