import { useMutation } from '@apollo/client'

import { Dish, Mutation, MutationUpdateOneMenuArgs } from 'api'
import { Icon, Typography } from 'components/newUi'
import { Image } from 'screens/Invoices/Add'
import { removeDishFromMenuMutation } from 'screens/Menu/EditMenu/graphql'
import { useTheme } from 'styles/newUi'

import { useStyles } from './styles'

import { successToast } from '../../../components/toasts'

export const Item = (props: any) => {
  const classes = useStyles()
  const menuId = props.menuId
  const { theme } = useTheme()

  const { index, data, gpTarget } = props
  const dish = data.items[index] as Dish

  const [removeDishFromMenuMutationAsync] = useMutation<
    { updateOneMenu: Mutation['updateOneMenu'] },
    MutationUpdateOneMenuArgs
  >(removeDishFromMenuMutation, {
    onCompleted: (data) => {
      if (data?.updateOneMenu)
        successToast(`Successfully removed ${dish.name} `)
    },
    refetchQueries: ['getMenuByIdQuery', 'dishList'],
  })

  const handleRemove = () =>
    removeDishFromMenuMutationAsync({
      variables: {
        data: { id: menuId },
        removeDishes: [dish.id],
      },
    })

  const handleDelete = () => {
    handleRemove()
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        borderBottomColor: theme.palette.primary[10].toHexString(),
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: '0fr 1fr 0fr 0fr',
        height: 68,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Image
          imageSize={68}
          localImage={false}
          url={dish.imageUrl ?? undefined}
          frame={false}
        />
      </div>
      <div
        className={classes.itemInnerMobile}
        style={{
          alignItems: 'center',
          display: 'flex',
          overflow: 'hidden',
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          {dish.name}
        </Typography>
      </div>
      <div
        className={classes.fontGrey}
        style={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          fontFamily: 'Rubik, sans-serif',
          fontWeight: 500,
          justifyContent: 'flex-end',
          textTransform: 'capitalize',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            textAlign: 'right',
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 500,
            }}
          >
            {/* @ts-expect-error */}
            {`£${dish.price.toFixed(2)}`}
          </Typography>
          <Typography
            variant="caption"
            style={{
              color:
                // @ts-ignore
                gpTarget > dish.gpTarget
                  ? theme.palette.error[100].toHexString()
                  : theme.palette.success[100].toHexString(),
            }}
          >
            {/* @ts-expect-error */}
            {`${dish.gpTarget.toFixed(2)}%`}
          </Typography>
        </div>
      </div>
      {/* eslint-disable-next-line */}
      <div
        className={classes.itemInnerMobile}
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: 50,
        }}
        onClick={handleDelete}
      >
        <Icon
          iconName="clear"
          style={{ color: theme.palette.error[100].toHexString() }}
        />
      </div>
    </div>
  )
}
