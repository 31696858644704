import {
  Button,
  Typography,
  ActionModal,
  Modal,
  DetailsSection,
} from '@getjelly/jelly-ui'
import {
  IconDotsVertical,
  IconDownload,
  IconTrash,
  IconFileExport,
} from '@tabler/icons-react'
import dayjs from 'dayjs'
import { isNil, sort } from 'ramda'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  useApproveOneInvoiceMutation,
  useDeleteOneInvoiceMutation,
  useGetDownloadLazyQuery,
  useGetInvoiceByIdQuery,
} from 'api'
import { DATE_FORMATS } from 'app'
import { Alert, Icon, NewLayout } from 'components/newUi'
import { Image } from 'screens/Invoices/Add'
import { NotesContainer } from 'screens/Spending/Invoices/SingleInvoice/components/NotesContainer'
import { DARK_GREY } from 'styles/colors'
import { getInvoiceDate } from 'utils'

import { Images } from './components/Images'
import { InvoiceEntry } from './components/InvoiceEntry'

import { useKitchen } from '../../../../../app/contexts/SelectedKitchen'
import { errorToast, successToast } from '../../../../../components/toasts'
import formatRejectionReasonAlert from '../../../../../utils/invoice/formatRejectionReasonAlert'
import { exportInvoiceToXero } from '../../rest/exportInvoiceToXero'
import { Fallback } from '../components/Fallback'
import { useStyles } from '../styles'

export const ViewInvoiceHistory = () => {
  const classes = useStyles()
  const id = parseInt(useParams().id)
  const [showDeleteInvoiceModal, setShowDeleteInvoiceModal] = useState(false)
  const [showActionModal, setShowActionModal] = useState(false)
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()

  const { data } = useGetInvoiceByIdQuery({
    fetchPolicy: 'cache-and-network',
    skip: isNil(id),
    variables: { where: { id: Number(id) } },
  })

  const [deleteInvoice] = useDeleteOneInvoiceMutation({
    refetchQueries: ['pendingInvoices'],
  })

  const [approveInvoice] = useApproveOneInvoiceMutation({
    refetchQueries: ['needsAttentionInvoiceList'],
  })

  const [getDownload] = useGetDownloadLazyQuery({
    fetchPolicy: 'network-only',
    variables: { files: [] },
  })

  const entries = useMemo(() => {
    if (isNil(data)) return []

    return sort(
      (a, b) =>
        a.product.name.toLowerCase() > b.product.name.toLowerCase() ? 1 : -1,
      data?.invoice?.entries ?? [],
    )
  }, [data])

  async function onDownloadClick(): Promise<void> {
    const { data: res } = await getDownload({
      variables: {
        files: [...(data?.invoice?.images ?? [])],
      },
    })

    if (!res) return void errorToast('Could not download files.')

    const linkSource = `data:application/zip;base64,${res.zip}`

    const a = document.createElement('a')
    a.setAttribute('href', linkSource)
    a.setAttribute(
      'download',
      `${data?.invoice?.supplier.name}-${data?.invoice?.invoiceId}-files`,
    )
    a.setAttribute('target', '_blank')
    a.click()

    setShowActionModal(false)
  }

  async function onExportClick(): Promise<void> {
    exportInvoiceToXero(
      id,
      () => {
        setShowActionModal(false)
        successToast('Invoice exported successfully.')
      },
      (errorMessage) => {
        errorToast(errorMessage)
        setShowActionModal(false)
      },
    )
  }

  async function handleApprove(approved: boolean) {
    await approveInvoice({
      awaitRefetchQueries: true,
      refetchQueries: ['pendingInvoiceListGrouped'],
      variables: {
        approved,
        id,
      },
    })
  }

  async function handleDelete() {
    await deleteInvoice({
      awaitRefetchQueries: true,
      refetchQueries: ['pendingInvoiceListGrouped'],
      variables: {
        data: {
          id,
        },
      },
    })

    navigate(-1)
  }

  const hideQuantities = useMemo(
    () => entries.every((e) => e.quantity === 0),
    [entries],
  )

  return (
    <>
      <NewLayout
        hideMenu
        subtitle="Spending"
        title={`Invoice ${data?.invoice?.invoiceId ?? ''}`}
        bottomContent={
          <div>
            {selectedKitchen?.userPermissions.includes('update-invoice') && (
              <>
                <div className="w-full py-4 px-2">
                  <div className="flex justify-center space-x-2">
                    <>
                      <Button
                        style="secondary"
                        className="w-full"
                        onClick={() => setShowActionModal(true)}
                        label="More"
                        icon={IconDotsVertical}
                      />

                      <Button
                        className="w-full"
                        disabled={data?.invoice?.approved ?? false}
                        onClick={() => handleApprove(true)}
                        label={data?.invoice?.approved ? 'Approved' : 'Approve'}
                      />
                    </>
                  </div>
                </div>
              </>
            )}
          </div>
        }
      />

      {!data || !data.invoice ? (
        <Fallback />
      ) : (
        <>
          {data.invoice.pendingInvoice?.status === 'rejected' && (
            <Alert type="error">
              <div className="flex items-center text-primary-900 space-x-2">
                <Icon iconName="notifications" className="text-error-400" />

                <div className="flex items-center text-primary-900 text-base leading-3">
                  {formatRejectionReasonAlert(
                    data.invoice.pendingInvoice.rejectionReason,
                    data.invoice.pendingInvoice.rejectionReasonNotes,
                  )}
                </div>
              </div>
            </Alert>
          )}

          <div
            className={classes.infoContainer}
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {data.invoice.signedImages && (
              <div style={{ paddingRight: 12 }}>
                {data?.invoice?.signedImages.length === 1 && (
                  <Image
                    imageSize={42}
                    localImage={false}
                    url={data?.invoice?.signedImages[0]}
                  />
                )}

                {data.invoice.signedImages?.length > 1 && (
                  <div
                    style={{
                      height: 55,
                      position: 'relative',
                      width: 50,
                    }}
                  >
                    <div
                      style={{
                        left: 0,
                        position: 'absolute',
                        top: 0,
                        zIndex: 10,
                      }}
                    >
                      <Image
                        imageSize={42}
                        localImage={false}
                        url={data.invoice.signedImages[0]}
                      />
                    </div>
                    <div
                      style={{
                        bottom: 0,
                        position: 'absolute',
                        right: 0,
                      }}
                    >
                      <Image
                        imageSize={42}
                        localImage={false}
                        url={data.invoice.signedImages[1]}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="flex flex-col justify-between flex-1 font-rubik">
              <Typography
                style="subtitle1"
                className="capitalize text-primary-800"
              >
                {data.invoice.supplier.name.toLowerCase()}
              </Typography>

              <div
                style={{
                  color: DARK_GREY,
                  fontSize: 14,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {dayjs(getInvoiceDate(data.invoice))
                  .utc()
                  .format(DATE_FORMATS.YET_ANOTHER_DATE_FORMAT)}
              </div>
            </div>

            <div
              className={classes.itemInnerMobile}
              style={{
                alignItems: 'flex-end',
                display: 'flex',
                flex: 0.4,
                flexDirection: 'column',
                marginRight: 8,
              }}
            >
              {data.invoice.invoiceTotal && (
                <p className="text-primary-900 font-medium text-base">
                  £{' '}
                  {data.invoice.vat
                    ? (data.invoice.invoiceTotal - data.invoice.vat)?.toFixed(2)
                    : data.invoice.invoiceTotal.toFixed(2)}
                </p>
              )}

              <p className="text-primary-900 font-normal text-base">
                + £{data.invoice.vat || 0} VAT
              </p>
            </div>
          </div>

          <NotesContainer
            pendingInvoiceId={data.invoice.pendingInvoice?.id ?? 0}
            invoiceId={data.invoice.invoiceId ?? undefined}
          />

          <DetailsSection
            title="Qty"
            rightTitle={`${entries.length} products`}
            className="" // reset the padding
          >
            {entries.map((entry) => {
              return (
                <InvoiceEntry
                  key={entry.id}
                  entry={entry}
                  hideQuantities={hideQuantities}
                />
              )
            })}
          </DetailsSection>

          <DetailsSection
            title="Uploaded invoices"
            rightTitle={`${data.invoice?.signedImages.length ?? 0} documents`}
          >
            <Images documents={data.invoice?.signedImages ?? []} />
          </DetailsSection>
        </>
      )}

      <Modal
        open={showDeleteInvoiceModal}
        onClose={() => setShowDeleteInvoiceModal(false)}
      >
        <div className="space-y-8">
          <div className="space-y-2">
            <Typography style="h6" className="text-primary-900 text-center">
              Delete invoice?
            </Typography>

            <Typography
              style="caption"
              className="text-primary-600 text-center"
            >
              Are you sure you want to delete this invoice? This action cannot
              be undone.
            </Typography>
          </div>

          <div className="flex flex-col space-y-2">
            <Button style="delete" onClick={handleDelete} label="Delete" />

            <Button
              style="secondary"
              onClick={() => setShowDeleteInvoiceModal(false)}
              label="Cancel"
            />
          </div>
        </div>
      </Modal>

      <ActionModal
        open={showActionModal}
        onClose={() => setShowActionModal(false)}
        actions={[
          {
            icon: IconDownload,
            onClick: onDownloadClick,
            subtitle: 'Download all files',
            title: 'Download',
          },
          {
            icon: IconTrash,
            onClick: () => {
              setShowActionModal(false)
              setShowDeleteInvoiceModal(true)
            },
            subtitle: 'Delete Invoice',
            title: 'Delete',
          },
          {
            icon: IconFileExport,
            onClick: onExportClick,
            subtitle: 'Export to accounting software',
            title: 'Export',
          },
        ]}
      />
    </>
  )
}
