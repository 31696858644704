import { gql } from '@apollo/client'

export const getSuppliersQuery = gql`
  query getSuppliers(
    $cursor: SupplierWhereUniqueInput
    $orderBy: [SupplierOrderByInput!]
    $skip: Int
    $take: Int
    $where: SupplierWhereInput
    $jellySkip: Int
    $jellyTake: Int
    $jellyWhere: SupplierWhereInput
  ) {
    kitchenSuppliersCount: suppliersCount(where: $where)
    kitchenSuppliers: suppliers(
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      _cursor
      id
      name
      catalogue
    }
    jellySuppliersCount: suppliersCount(where: $jellyWhere)
    jellySuppliers: suppliers(
      cursor: $cursor
      orderBy: $orderBy
      skip: $jellySkip
      take: $jellyTake
      where: $jellyWhere
    ) {
      _cursor
      id
      name
      catalogue
    }
  }
`
