import { Button, Typography } from '@getjelly/jelly-ui'
import { IconSelector } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

import { Kitchen } from '../../api'
import { NewLayout } from '../../components/newUi'
import { routes } from '../../routes/Paths'

type Action = {
  text: string
  onClick: () => void
}

type Props = {
  kitchen: Kitchen
  title: string
  action?: Action
}

export function HomeLayout({ kitchen, title, action }: Props) {
  const navigate = useNavigate()

  return (
    <NewLayout
      rightContent={
        <div className="px-4 flex items-center">
          {action && <Button onClick={action.onClick} label={action.text} />}
        </div>
      }
      hideBack
      leftContent={
        <div
          className="flex items-center px-4 py-2 space-x-1 cursor-pointer"
          onClick={() => navigate(routes.Settings + routes.Locations)}
        >
          <IconSelector className="text-secondary-400 shrink-0" />

          <div>
            <Typography style="subtitle1" className="text-primary-900">
              {title}
            </Typography>

            <Typography
              style="subtitle1"
              className="text-secondary-400 line-clamp-1"
            >
              {kitchen.name}
            </Typography>
          </div>
        </div>
      }
    />
  )
}
