import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { QueryKitchensArgs, SortOrder, Query } from 'api'
import { capitaliseEachWord } from 'utils'

import { getKitchensQuery } from './graphql'
import { Props } from './types'

import { Dropdown } from '../Dropdown'

export const SelectKitchen = (props: Props) => {
  const { data, loading } = useQuery<
    {
      kitchens: Query['kitchens']
    },
    QueryKitchensArgs
  >(getKitchensQuery, {
    variables: {
      orderBy: [{ name: SortOrder.Asc }],
    },
  })

  const options = useMemo(() => {
    if (!data?.kitchens) {
      return []
    }

    return data.kitchens
      .map((k) => ({
        label: capitaliseEachWord(k.name),
        value: k.id,
      }))
      .sort((a, b) => {
        if (a.label.trim() > b.label.trim()) return 1
        if (a.label.trim() < b.label.trim()) return -1
        return 0
      })
  }, [data])

  return (
    <Dropdown
      {...props}
      loading={loading}
      options={options}
      placeholder="Select a location"
      className="w-full"
      onChange={(value) => {
        props.kitchenChange?.(data?.kitchens.find((k) => k.id === value))
      }}
    />
  )
}
