import { makeStyles } from 'styles'

export const useStyles = makeStyles((theme) => ({
  quantityInput: {
    '& input': {
      padding: 6,
      textAlign: 'center',
    },
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: 10,
    width: 50,
  },
}))
