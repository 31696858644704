import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  DropdownInput,
  NumberInput,
  TextInput,
  Typography,
} from '@getjelly/jelly-ui'
import { IconPercentage, IconSearch } from '@tabler/icons-react'
import { reduce } from 'ramda'
import { useState, useEffect, ReactNode } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Query,
  Dish,
  QueryMenuArgs,
  Menu,
  MutationUpdateOneMenuArgs,
  Mutation,
  MutationDeleteOneMenuArgs,
  QueryDishesArgs,
  SortOrder,
  Status,
} from 'api'
import { Loader } from 'components'
import {
  VirtualizedSectionList,
  NewLayout,
  LeaveConfirmationModal,
} from 'components/newUi'
import { routes } from 'routes/Paths'
import {
  addDishToMenuMutation,
  deleteOneMenuMutation,
  updateMenuMutation,
} from 'screens/Menu/EditMenu/graphql'
import { getMenuByIdQuery } from 'screens/Menu/graphql'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord, cleanErrorMessage } from 'utils'

import { Item } from './Item'

import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { errorToast, successToast } from '../../../components/toasts'
import { getDishesByKitchenQuery } from '../../Create/graphql/dish'

const transformData = (menu: Menu) => {
  const grouped = reduce(
    (acc, el) => {
      const key = el.dish.type

      // @ts-ignore
      if (!acc[key]) {
        // @ts-ignore
        acc[key] = []
      }
      // @ts-ignore
      acc[key].push(el.dish)

      return acc
    },
    {},
    menu.dishList.nodes,
  )

  const transformedData: {
    label: string
    items: Dish[]
  }[] = Object.keys(grouped).map((key) => {
    return {
      // @ts-ignore
      items: grouped[key].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ) as Dish[],
      label: capitaliseEachWord(key),
    }
  })

  return transformedData
}

type MenuData = { label: string; items: Dish[] }[]

export const EditMenu = () => {
  const { id } = useParams()
  const [data, setData] = useState<MenuData>([])
  const { theme } = useTheme()

  const {
    data: queryResult,
    loading,
    refetch,
  } = useQuery<{ menu: Query['menu'] }, QueryMenuArgs>(getMenuByIdQuery, {
    variables: {
      where: {
        id: parseInt(id),
      },
    },
  })

  useEffect(() => {
    if (!queryResult || !queryResult?.menu || loading) {
      return
    }

    const transformedData = transformData(queryResult.menu)

    return setData(transformedData)
  }, [queryResult, loading])

  if (!queryResult?.menu) return <Loader />

  return (
    <EditMenuTwo menu={queryResult.menu} refetch={refetch}>
      <VirtualizedSectionList
        ROW_HEIGHT={68}
        Item={(props: any) => {
          const index = props.index
          const item = props.data.items[index]

          return (
            <Item
              {...props}
              key={item.id}
              menuId={parseInt(id)}
              gpTarget={queryResult.menu?.gpTarget}
            />
          )
        }}
        emptyMessage={
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              padding: theme.spacing(1),
              width: '100%',
            }}
          >
            <Typography style="subtitle2" className="text-primary-600">
              Search to add dishes to this menu
            </Typography>
          </div>
        }
        data={data}
        loading={loading}
        fetchMore={() => {
          return
        }}
      />
    </EditMenuTwo>
  )
}

function EditMenuTwo({
  menu,
  refetch,
  children,
}: {
  menu: Menu
  refetch: () => void
  children?: ReactNode
}) {
  const { selectedKitchen } = useKitchen()
  const { id } = useParams()
  const navigate = useNavigate()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [name, setName] = useState(menu.name)
  const [gpTarget, setGpTarget] = useState(menu.gpTarget.toString())
  const [dish, setDish] = useState<Dish | null>(null)
  const [saving, setSaving] = useState(false)

  const [updateMenu, { loading }] = useMutation<
    { updateOneMenu: Mutation['updateOneMenu'] },
    MutationUpdateOneMenuArgs
  >(updateMenuMutation, {
    onCompleted: (data) => {
      if (data?.updateOneMenu)
        successToast(`Successfully updated ${data.updateOneMenu.name} `)
    },
    refetchQueries: ['getMenuByIdQuery', 'dishList'],
  })

  const [deleteMenu] = useMutation<
    { deleteOneMenu: Mutation['deleteOneMenu'] },
    MutationDeleteOneMenuArgs
  >(deleteOneMenuMutation, {
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data?.deleteOneMenu) {
        successToast(`Successfully deleted ${data.deleteOneMenu.name} `)
        navigate(`${routes.Book}${routes.Menu}`)
      }
    },
    refetchQueries: ['dishList', 'getMenus', 'menuList'],
  })

  async function saveMenu() {
    if (saving) {
      return
    }

    setSaving(true)

    try {
      await updateMenu({
        variables: {
          data: {
            gpTarget: parseInt(gpTarget),
            id: parseInt(id),
            name: name,
          },
        },
      })

      navigate(-1)
    } catch {
      errorToast('Failed to save menu.')
    } finally {
      setSaving(false)
    }
  }

  const { data: dishData, loading: dishesLoading } = useQuery<
    {
      dishes: Query['dishes']
    },
    QueryDishesArgs
  >(getDishesByKitchenQuery, {
    skip: !selectedKitchen,
    variables: {
      orderBy: [{ name: SortOrder.Asc }],
      where: {
        isDraft: {
          equals: false,
        },
        kitchenId: {
          equals: selectedKitchen?.id ?? 0,
        },
        status: {
          equals: Status.Active,
        },
      },
    },
  })

  const [addDishToMenuMutationAsync, { loading: addingDish }] = useMutation<
    { updateOneMenu: Mutation['updateOneMenu'] },
    MutationUpdateOneMenuArgs
  >(addDishToMenuMutation)

  useEffect(() => {
    if (!dish) {
      return
    }

    addDishToMenuMutationAsync({
      variables: {
        data: { id: menu.id },
        dishes: [{ id: dish.id }],
      },
    })
      .then(({ data }) => {
        if (data) {
          successToast(
            `${
              data.updateOneMenu?.dishes?.find(
                (item) => item.dish?.id === dish.id,
              )?.dish.name
            } was added to ${menu?.name ?? ''}`,
          )
          refetch()
        }
      })
      .catch((error) => errorToast(cleanErrorMessage(error.message)))

    setDish(null)
  }, [dish])

  return (
    <>
      <NewLayout
        subtitle="Menus"
        title={menu?.name}
        hideMenu
        bottomContent={
          <div className="px-2 py-4">
            <Button
              className="w-full"
              label="Save Menu"
              onClick={saveMenu}
              loading={saving}
            />
          </div>
        }
        rightContent={
          <div className="px-4">
            <Button
              onClick={() => setShowDeleteModal(true)}
              label="Delete"
              style="delete"
            />
          </div>
        }
      />

      <div className="px-3 py-4">
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-3">
            <div className="flex-1">
              <div className="space-y-2">
                <Typography style="caption" className="text-primary-800">
                  Name
                </Typography>

                <TextInput value={name} onChange={(v) => setName(v)} />
              </div>
            </div>

            <div className="w-24 shrink-0">
              <div className="space-y-2">
                <Typography style="caption" className="text-primary-800">
                  GP Target
                </Typography>

                <NumberInput
                  value={gpTarget}
                  onChange={(v) => setGpTarget(v)}
                  icon={IconPercentage}
                />
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <DropdownInput
              placeholder="Search to add dishes to this menu"
              value={dish}
              options={dishData?.dishes ?? []}
              optionToId={(v) => v.id}
              optionToLabel={(v) => v.name}
              onChange={setDish}
              loading={(dishesLoading && dishData === undefined) || addingDish}
              icon={IconSearch}
            />
          </div>
        </div>
      </div>

      {!loading || menu ? (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          {children}
          <LeaveConfirmationModal
            action="Yes, delete"
            block={false}
            title="Are you sure you want to delete this menu?"
            text=""
            onConfirm={() => {
              setShowDeleteModal(false)
              deleteMenu({
                variables: {
                  where: {
                    id: menu.id,
                  },
                },
              })
            }}
            onCancel={() => {
              setShowDeleteModal(false)
            }}
            show={showDeleteModal}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}
