import { gql } from '@apollo/client'

export const postUpdateOneStockMutation = gql`
  mutation UpdateOneStock($data: UpdateOneStockInputObject!) {
    updateOneStock(data: $data) {
      _cursor
      id
      date
      name
      total
    }
  }
`
