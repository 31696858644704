import { Button, Typography } from '@getjelly/jelly-ui'
import { Transition, State } from 'history'
import { useEffect, useRef } from 'react'
import { useBlocker } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'

import { useWindowSize } from 'hooks'

interface LeaveConfirmationModalProps {
  action: string
  cancelLabel?: string
  onCancel?: (state?: Transition<State>) => unknown
  onConfirm: (state?: Transition<State>) => unknown
  show: boolean
  title: string
  text: string
  block?: boolean
  skip?: (state?: Transition<State>) => boolean
}

export const LeaveConfirmationModal = ({
  action,
  cancelLabel = "No, don't",
  onCancel,
  onConfirm,
  show,
  title,
  text,
  block = true,
  skip,
}: LeaveConfirmationModalProps) => {
  const toastId = useRef<string | number | undefined>(undefined)
  const { gte: isDesktop } = useWindowSize('md')

  useEffect(() => {
    if (!show) toast.dismiss(toastId.current)
  }, [show])

  const toastFn = (state?: Transition<State>) =>
    toast.warn(
      <ConfirmationModal
        cancelLabel={cancelLabel}
        action={action}
        show={show}
        title={title}
        text={text}
        onConfirm={() => {
          toast.dismiss(toastId.current)
          onConfirm(state)
        }}
        onClose={() => {
          toast.dismiss(toastId.current)
          if (onCancel) onCancel(state)
        }}
      />,
      {
        autoClose: false,
        bodyStyle: {
          alignSelf: 'center',
          backgroundColor: 'white',
          borderRadius: 10,
          justifySelf: 'center',
          margin: isDesktop ? 'auto' : '0px 0px 0px 5%',
          maxWidth: isDesktop ? '50%' : '90%',
          minHeight: 180,
          padding: 0,
          width: isDesktop ? '50%' : '90%',
        },
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        icon: false,
        style: {
          backgroundColor: 'rgba(31,48,74, 0.75)',
          bottom: '0 !important',
          marginTop: -56,
          minHeight: '100vh',
          minWidth: '100vw',
          position: 'absolute',
        },
        toastId: 'warning-toast',
        transition: Flip,
      },
    )

  useEffect(() => {
    if (!block && show) toastFn()
  }, [block, show])

  useBlocker((state) => {
    if (skip && skip(state)) return state.retry()
    return toastFn(state)
  }, block && show)

  return null
}

interface ConfirmProps {
  action: string
  cancelLabel: string
  onClose: () => unknown
  onConfirm: () => unknown
  show: boolean
  title: string
  text: string
}

export function ConfirmationModal({
  action,
  cancelLabel,
  onClose,
  onConfirm,
  title,
  text,
}: ConfirmProps) {
  return (
    <div className="py-8 px-4 space-y-8">
      <div className="space-y-2">
        <Typography style="h6" className="text-primary-900">
          {title}
        </Typography>

        {text && (
          <Typography style="caption" className="text-primary-600">
            {text}
          </Typography>
        )}
      </div>

      <div className="space-y-2">
        <Button
          style="primary"
          onClick={onConfirm}
          label={action}
          className="w-full"
        />

        <Button
          style="secondary"
          onClick={onClose}
          label={cancelLabel}
          className="w-full"
        />
      </div>
    </div>
  )
}
