import { FileCopy as fileCopy } from '@mui/icons-material'
import person from '@mui/icons-material/Accessibility'
import accountCircle from '@mui/icons-material/AccountCircleOutlined'
import add from '@mui/icons-material/Add'
import addAPhoto from '@mui/icons-material/AddAPhoto'
import addCircle from '@mui/icons-material/AddCircle'
import addShoppingCart from '@mui/icons-material/AddShoppingCart'
import arrowBack from '@mui/icons-material/ArrowBack'
import arrowDown from '@mui/icons-material/ArrowDownward'
import arrowForward from '@mui/icons-material/ArrowForward'
import arrowUp from '@mui/icons-material/ArrowUpward'
import cake from '@mui/icons-material/Cake'
import camera from '@mui/icons-material/CameraAlt'
import tick from '@mui/icons-material/Check'
import checkCircle from '@mui/icons-material/CheckCircle'
import chevronLeft from '@mui/icons-material/ChevronLeft'
import chevronRight from '@mui/icons-material/ChevronRight'
import clear from '@mui/icons-material/Clear'
import convertArrows from '@mui/icons-material/CompareArrows'
import contactSupport from '@mui/icons-material/ContactSupportTwoTone'
import contentCopy from '@mui/icons-material/ContentCopy'
import paste from '@mui/icons-material/ContentPaste'
import edit from '@mui/icons-material/Create'
import creditCard from '@mui/icons-material/CreditCard'
import imageOutline from '@mui/icons-material/CropOriginal'
import deleteIcon from '@mui/icons-material/Delete'
import file from '@mui/icons-material/Description'
import email from '@mui/icons-material/Email'
import chevronUp from '@mui/icons-material/ExpandLess'
import chevronDown from '@mui/icons-material/ExpandMore'
import face from '@mui/icons-material/Face'
import fastfoodIcon from '@mui/icons-material/Fastfood'
import heart from '@mui/icons-material/Favorite'
import heartOutline from '@mui/icons-material/FavoriteBorder'
import fileDownload from '@mui/icons-material/FileDownload'
import fileUpload from '@mui/icons-material/FileUpload'
import formatListBulleted from '@mui/icons-material/FormatListBulleted'
import download from '@mui/icons-material/GetApp'
import home from '@mui/icons-material/Home'
import image from '@mui/icons-material/Image'
import book from '@mui/icons-material/ImportContacts'
import info from '@mui/icons-material/Info'
import kitchen from '@mui/icons-material/Kitchen'
import launchIcon from '@mui/icons-material/Launch'
import libraryBooks from '@mui/icons-material/LibraryBooks'
import link from '@mui/icons-material/Link'
import listAlt from '@mui/icons-material/ListAlt'
import cookbook from '@mui/icons-material/LocalDining'
import lock from '@mui/icons-material/Lock'
import menu from '@mui/icons-material/Menu'
import menuIconBook from '@mui/icons-material/MenuBook'
import message from '@mui/icons-material/Message'
import more from '@mui/icons-material/MoreHoriz'
import moreVert from '@mui/icons-material/MoreVert'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import notifications from '@mui/icons-material/Notifications'
import notInterested from '@mui/icons-material/NotInterested'
import payment from '@mui/icons-material/Payment'
import people from '@mui/icons-material/People'
import person2 from '@mui/icons-material/Person'
import phone from '@mui/icons-material/Phone'
import costing from '@mui/icons-material/PieChart'
import place from '@mui/icons-material/Place'
import publish from '@mui/icons-material/Publish'
import conversation from '@mui/icons-material/QuestionAnswer'
import products from '@mui/icons-material/Receipt'
import refresh from '@mui/icons-material/Refresh'
import minus from '@mui/icons-material/Remove'
import eye from '@mui/icons-material/RemoveRedEye'
import reportProblem from '@mui/icons-material/ReportProblem'
import restaurantMenuIcon from '@mui/icons-material/RestaurantMenu'
import dish from '@mui/icons-material/RoomService'
import search from '@mui/icons-material/Search'
import searchOff from '@mui/icons-material/SearchOff'
import settings from '@mui/icons-material/Settings'
import share from '@mui/icons-material/Share'
import shoppingBasket from '@mui/icons-material/ShoppingBasket'
import shoppingCartIcon from '@mui/icons-material/ShoppingCart'
import swapHoriz from '@mui/icons-material/SwapHoriz'
import timeline from '@mui/icons-material/Timeline'
import trendingUp from '@mui/icons-material/TrendingUp'
import warning from '@mui/icons-material/Warning'

import { CloseIcon as close, PrintIcon as print } from 'components/Icons'

import { Props } from './types'

// allias
const allergen = warning

export const iconDictionary = {
  accountCircle,
  add,
  addAPhoto,
  addCircle,
  addShoppingCart,
  allergen,
  arrowBack,
  arrowDown,
  arrowForward,
  arrowUp,
  book,
  cake,
  camera,
  checkCircle,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  clear,
  close,
  contactSupport,
  contentCopy,
  conversation,
  convertArrows,
  cookbook,
  costing,
  creditCard,
  delete: deleteIcon,
  dish,
  download,
  edit,
  email,
  eye,
  face,
  fastfoodIcon,
  file,
  fileCopy,
  fileDownload,
  fileUpload,
  formatListBulleted,
  heart,
  heartOutline,
  home,
  image,
  imageOutline,
  info,
  kitchen,
  launchIcon,
  libraryBooks,
  link,
  listAlt,
  lock,
  menu,
  menuIconBook,
  message,
  minus,
  more,
  moreVert,
  newReleases: NewReleasesIcon,
  notInterested,
  notifications,
  paste,
  payment,
  people,
  person,
  person2,
  phone,
  place,
  print,
  products,
  publish,
  refresh,
  reportProblem,
  restaurantMenuIcon,
  search,
  searchOff,
  settings,
  share,
  shoppingBasket,
  shoppingCartIcon,
  swapHoriz,
  tick,
  tickCircle: checkCircle,
  timeline,
  trendingUp,
  warning,
}

export const Icon = ({ iconName, ...rest }: Props) => {
  const icon = iconDictionary[iconName]

  if (!icon) {
    return null
  }

  // @ts-ignore
  return icon.type.render(rest)
}
