import { createContext, useState, useContext, FC, useEffect, ReactNode } from 'react'

import { useHubspotChat } from 'hooks'

export interface Chat {
  open: boolean | undefined
  setOpen: (value: boolean | undefined) => void
  activeConversation: boolean
  closeHandler: () => void
  hasLoaded: boolean
  loadHandler: () => void
  openHandler: () => void
  refreshHandler: () => void
  removeHandler: () => void
  unreadCount: number
}

const ChatDefaults: Chat = {
  activeConversation: false,
  closeHandler: () => undefined,
  hasLoaded: false,
  loadHandler: () => undefined,
  open: undefined,
  openHandler: () => undefined,
  refreshHandler: () => undefined,
  removeHandler: () => undefined,
  setOpen: () => {
    return
  },
  unreadCount: 0,
}

const ChatContext = createContext(ChatDefaults)

export const useChat = () => {
  const context = useContext(ChatContext)

  return context
}

export interface IProps {
  children?: ReactNode
}

export const ChatProvider: FC<IProps> = (props) => {
  const [open, setOpen] = useState<boolean | undefined>(ChatDefaults.open)
  const hubspotProps = useHubspotChat()

  useEffect(() => {
    if (open) {
      hubspotProps.refreshHandler()
    }
  }, [open])

  return (
    <ChatContext.Provider value={{ open, setOpen, ...hubspotProps }}>
      <>{props?.children}</>
    </ChatContext.Provider>
  )
}
