import {
  FlatlistRow,
  FuzzySearchResultProduct,
  HeaderRow,
  ProductRow,
} from 'screens/Order/Products/types'
import { Theme } from 'styles/newUi'
import { capitaliseEachWord } from 'utils'

export const processFlatData = ({
  data,
  recentCount,
  kitchenCount,
  catalogueCount,
  theme,
}: {
  data: FuzzySearchResultProduct[]
  recentCount: number
  kitchenCount: number
  catalogueCount: number
  theme: Theme
}) => {
  const groupedWithServerKeys: {
    label?: string | undefined
    count?: number | undefined
    items: unknown[]
    itemStyle?: boolean | undefined
  }[] =
    data?.reduce((acc: any, cur) => {
      const page = acc.find(
        // @ts-ignore
        (page: any) => page.label === capitaliseEachWord(cur?.category),
      )
      if (page) {
        acc = acc.map((page: any) => {
          // @ts-ignore
          if (page.label === capitaliseEachWord(cur?.category)) {
            page.items.push(cur)
          }
          return page
        })
      } else {
        const items = [cur]
        const count =
          cur?.category === 'supplier catalogue'
            ? catalogueCount
            : cur?.category === 'kitchen'
            ? kitchenCount
            : recentCount
        acc.push({
          count,
          itemStyle: {
            background:
              cur?.category === 'supplier catalogue'
                ? theme.palette.primary[5]
                : 'white',
          },
          items,
          label: capitaliseEachWord(cur?.category as string),
        })
      }
      return acc
    }, []) ?? []

  for (const group of groupedWithServerKeys as any[]) {
    if (group.label === 'Recent') {
      group.count = group.items.length
      group.label = 'Recently Ordered'
    }
    if (group.label === 'Kitchen') {
      group.count = kitchenCount
      group.label = 'Your Products'
    }
  }

  const flattenedList: FlatlistRow[] = []

  for (const key in groupedWithServerKeys) {
    const group = groupedWithServerKeys[key]

    // Don't add a header if there are no items
    if (group.items.length <= 0) {
      continue
    }

    const label = `${group.label} (${group.count})`
    flattenedList.push({
      __typename: 'HeaderRow',
      // id: group.label,
      // itemStyle: group.itemStyle,
      label,
    } as HeaderRow)
    group.items.forEach((item: unknown) => {
      flattenedList.push({
        ...(item as FuzzySearchResultProduct),
        itemStyle: group.itemStyle,
      } as ProductRow)
    })
  }

  return flattenedList
}
