import { makeStyles } from 'styles/newUi'

interface Props {
  type?: 'error' | 'info' | 'success' | 'warning'
}

export const useStyles = ({ type }: Props) => {
  return makeStyles((theme) => {
    const color =
      type === 'error'
        ? theme.palette.error[100].toHexString()
        : type === 'info'
        ? theme.palette.info[100].toHexString()
        : type === 'success'
        ? theme.palette.success[100].toHexString()
        : type === 'warning'
        ? theme.palette.tertiary[100].toHexString()
        : theme.palette.primary[100].toHexString()
    const backgroundColor =
      type === 'error'
        ? theme.palette.error[25].toHexString()
        : type === 'info'
        ? theme.palette.info[25].toHexString()
        : type === 'success'
        ? theme.palette.success[25].toHexString()
        : type === 'warning'
        ? theme.palette.tertiary[25].toHexString()
        : theme.palette.primary[20].toHexString()

    return {
      _root: {
        backgroundColor,
        color: theme.palette.common.light.toHexString(),
        padding: theme.spacing(2),
      },
      icon: {
        color: color,
      },
    }
  })
}
