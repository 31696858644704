import referImage from 'assets/refer-image.png'

import { Button, NewLayout, Typography } from '../../../components/newUi'
import { useTheme } from '../../../styles/newUi'

export function Refer() {
  const { theme } = useTheme()

  return (
    <>
      <NewLayout
        title="Refer a friend"
        subtitle="Settings"
        bottomBackgroundColor={theme.palette.primary['100'].toHexString()}
        bottomContent={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={async () => {
                if (!navigator.share) {
                  window.open('https://getjelly.co.uk')
                  return
                }

                await navigator.share({
                  text: 'Get £100 when you join Jelly!',
                  title: 'Jelly',
                  url: 'https://getjelly.co.uk',
                })
              }}
              text="Share"
            />
          </div>
        }
      />

      <div style={{ padding: '0.75rem', textAlign: 'center' }}>
        <img
          src={referImage}
          alt="glasses of milk with straws"
          style={{ marginBottom: '2rem', width: '100%' }}
        />

        <Typography variant="body1">
          Refer and let us thank you with
          <br />
          £100 for you,
          <br />
          £100 for a friend
        </Typography>
      </div>
    </>
  )
}
