/* @TODO: Rewrite this with new queries, components and styles */
import { useMutation, useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import { observer } from 'mobx-react-lite'
import { isNil, isEmpty } from 'ramda'
import { useEffect, useRef, useState, ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  QueryMode,
  QueryIngredientsAndRecipesArgs,
  IngredientAndRecipe,
  Ingredient,
  Recipe,
  Status,
  Mutation,
  MutationUpdateOneIngredientArgs,
} from 'api'
import { useReturnToPageContext } from 'app/contexts/ReturnToPage'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader, Grid } from 'components'
import {
  SearchBar,
  SectionList,
  Icon,
  Button,
  NewLayout,
  BottomModal,
} from 'components/newUi'
import { TextField } from 'components/newUi/TextField'
import { useWindowSize } from 'hooks'
import { useNewStateStore } from 'mobx/StateStore/Meta'
import { routes } from 'routes/Paths'
import { Modal } from 'screens/Create/Modal'
import { updateIngredientMutation } from 'screens/Ingredient/graphql'
import { setProductSearch } from 'store/product'
import { useTheme } from 'styles/newUi'

import { ingredientsAndRecipesByKitchenQuery } from './graphql'
import { Item } from './Item'
import { useStyles } from './styles'

import { successToast } from '../../../components/toasts'

export const ListIngredients = observer(() => {
  const dispatch = useDispatch()
  const inputEl = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const classes = useStyles()
  const { gte: isDesktop } = useWindowSize('md')
  const { selectedKitchen } = useKitchen()
  const { theme } = useTheme()
  const [search, setSearch] = useState('')
  const [, setCurrentId] = useState<number>()
  const [, setButtonFocus] = useState(false)

  const take = 10
  const [ingredientTake, setIngredientTake] = useState(take)
  const [showCatalog, setShowCatalog] = useState(false)
  const [data, setData] = useState<
    { count: number; name: string; data: (Recipe | Ingredient)[] }[]
  >([])
  const [showAllRecipes, setShowAllRecipes] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const newFormState = useNewStateStore()
  const returnToPageContext = useReturnToPageContext()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [itemWithNoPrice, setItemWithNoPrice] = useState<Ingredient>()
  const [itemPrice, setItemPrice] = useState<number>()

  const {
    data: ingredientData,
    loading,
    error,
  } = useQuery<
    {
      ingredientsAndRecipes: IngredientAndRecipe
    },
    QueryIngredientsAndRecipesArgs
  >(ingredientsAndRecipesByKitchenQuery, {
    variables: {
      ingredientWhere: {
        kitchenId: {
          equals: selectedKitchen?.id,
        },
        product: isEmpty(search)
          ? undefined
          : {
              OR: [
                {
                  name: {
                    contains: isEmpty(search) ? null : search,
                    mode: QueryMode.Insensitive,
                  },
                },
                {
                  supplier: {
                    name: {
                      contains: isEmpty(search) ? null : search,
                      mode: QueryMode.Insensitive,
                    },
                  },
                },
              ],
            },
      },
      orderByName: true,
      recipeTake: 100,
      recipeWhere: {
        NOT: [
          {
            status: { equals: Status.Inactive },
          },
          {
            isDraft: {
              equals: null,
            },
          },
        ],
        OR: [
          {
            isDraft: {
              equals: false,
            },
          },
        ],
        dishes: {
          none: {},
        },
        kitchenId: {
          equals: selectedKitchen?.id,
        },
        name: isEmpty(search)
          ? undefined
          : {
              contains: isEmpty(search) ? null : search,
              mode: QueryMode.Insensitive,
            },
      },
      searchTerm: isEmpty(search) ? undefined : search,
      take: ingredientTake,
    },
  })
  useEffect(() => {
    inputEl.current?.focus()
  }, [])
  useEffect(() => {
    if (returnToPageContext.savedRoute?.includes('/create/')) {
      setCurrentId(parseInt(returnToPageContext.savedRoute.split('/')[2]))
    }
  }, [returnToPageContext.savedRoute])

  const [updateIngredient] = useMutation<
    { updateOneIngredient: Mutation['updateOneIngredient'] },
    MutationUpdateOneIngredientArgs
  >(updateIngredientMutation)

  const handleSubmitSetPrice = async () => {
    if (itemWithNoPrice && selectedKitchen) {
      await updateIngredient({
        variables: {
          data: {
            id: itemWithNoPrice?.id,
            price: itemPrice,
          },
          kitchenId: selectedKitchen?.id,
        },
      })
        .then((result) => {
          setShowModal(false)
          if (result.data?.updateOneIngredient) {
            handleSubmit(result.data?.updateOneIngredient)
          }
          setItemWithNoPrice(undefined)
          setItemPrice(undefined)

          successToast(`Updated price successfully`)
        })
        .catch(() => {
          successToast(`Failed to update price`)
        })
    }
  }

  const handleSubmit = (item: Ingredient | Recipe) => {
    const key = returnToPageContext.savedRoute
    const newState = newFormState.getFormState(key!)
    if (item.__typename === 'Ingredient')
      newState?.addIngredient(item as Ingredient)
    else newState?.addRecipe(item as Recipe)
    returnToPageContext.returnToPage()
  }

  useEffect(() => {
    if (!ingredientData) return
    if (!search) return setData([])
    const rpes = ingredientData?.ingredientsAndRecipes.recipes ?? []
    const ings = ingredientData?.ingredientsAndRecipes.ingredients ?? []
    const draftCount = rpes.filter((r) => r.isCalculatedDraft).length
    const rpeFiltered = rpes.filter((r) => !r.isCalculatedDraft)
    const rpesData =
      rpeFiltered.length > 3 && !showAllRecipes
        ? rpeFiltered.slice(0, 3)
        : rpeFiltered
    const recipes = {
      count: rpes.length - draftCount,
      data: rpesData,
      name: 'Your Recipes',
    }
    const ingredients = {
      count: ingredientData.ingredientsCount,
      data: ings,
      name: 'Your Products',
    }
    const list: {
      count: number
      name: string
      data: (Recipe | Ingredient)[]
      append?: ReactElement
    }[] = [
      {
        ...recipes,
        append:
          rpeFiltered.length > 3 ? recipesButtons(recipes, loading) : undefined,
      },
      {
        ...ingredients,
        append: ingredientButtons(ingredients, showCatalog),
      },
    ]
    setData(list)
    setShowCatalog(
      !isEmpty(search) && ingredients.count === ingredients.data.length,
    )
  }, [ingredientData, search, showCatalog, showAllRecipes])

  useEffect(() => {
    setShowLoader(true)
  }, [search])

  useEffect(() => {
    setShowAllRecipes(false)
  }, [ingredientData])

  const recipesButtons = (recipes: any, load: boolean) => {
    return !isEmpty(search) && !showAllRecipes ? (
      <Grid container item xs={12} className={classes.mobileButtonWrap}>
        <Typography
          onClick={() => {
            setShowAllRecipes(true)
          }}
          variant="body2"
          style={{
            alignItems: 'center',
            color: theme.palette.secondary[100].toHexString(),
            cursor: 'pointer',
            display: 'flex',
            fontWeight: 600,
            height: 40,
          }}
        >
          Show More Recipes
          <Icon iconName="chevronDown" />
        </Typography>
      </Grid>
    ) : undefined
  }
  const ingredientButtons = (_ingredients: any, show: boolean) => {
    return show ? (
      <Grid
        container
        item
        xs={12}
        style={{
          alignItems: 'center',
          backgroundColor: theme.palette.primary[5].toHexString(),
          borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 20,
          textAlign: 'center',
        }}
      >
        <Typography
          variant="body2"
          style={{
            alignItems: 'center',
            color: theme.palette.primary[80].toHexString(),
            cursor: 'pointer',
            display: 'flex',
            fontWeight: 600,
          }}
        >
          No more products or recipes matching {`"`}
          {search}
          {`"`}
        </Typography>
        <Typography
          onClick={() => {
            setSearch('')
          }}
          variant="body2"
          style={{
            alignItems: 'center',
            color: theme.palette.secondary[100].toHexString(),
            cursor: 'pointer',
            display: 'flex',
            fontWeight: 600,
            height: 40,
          }}
        >
          Show All {'>'}
        </Typography>
      </Grid>
    ) : undefined
  }

  const onSearch = debounce((search: string) => {
    if (search.length < 2) return
    setSearch(search)
  }, 500)

  return (
    <>
      <NewLayout title="Select Ingredients" />

      <div
        style={{
          backgroundColor: theme.palette.primary[10].toHexString(),
          paddingBottom: theme.spacing(1),
          paddingTop: theme.spacing(1),
        }}
      >
        <SearchBar
          search={search}
          searchPlaceholder="Start searching to add product or recipe"
          onSearch={onSearch}
          ref={inputEl}
          onFocus={() => {
            setButtonFocus(false)
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              setButtonFocus(true)
              // @ts-ignore
              e.target.blur()
            }
          }}
        />
      </div>
      <div
        className={classes.list}
        onScroll={(e) => {
          const listContainer = document.getElementsByClassName(
            classes.listContainer,
          )[0]
          if (!listContainer || showCatalog) return
          const endOfScroll =
            // @ts-ignore
            listContainer.offsetHeight -
            // @ts-ignore
            (e.target.scrollTop + e.target.offsetHeight)
          if (endOfScroll < 200 && !loading) {
            setShowLoader(false)
            setIngredientTake(ingredientTake + 10)
          }
        }}
      >
        {loading && showLoader ? (
          <Loader />
        ) : !isNil(error) ? (
          <div className={classes.info}>
            <Typography variant="body2">Something went wrong</Typography>
          </div>
        ) : (
          <SectionList
            placeholder={'true'}
            data={data}
            rootClass={classes.listContainer}
            itemClass={isDesktop ? classes.listItemDesktop : classes.listItem}
            sectionClass={classes.sectionClass}
            item={({ item }: { item: Recipe | Ingredient }) => (
              <Item
                search={search}
                item={item}
                handleOnClick={(item) => {
                  if (item.__typename === 'Ingredient' && !item.price) {
                    setItemWithNoPrice(item)
                    setShowModal(true)
                  } else {
                    handleSubmit(item)
                  }
                }}
              />
            )}
            empty={() => (
              <div className={classes.info}>
                <Typography variant="body2">
                  Could not find any items
                </Typography>
              </div>
            )}
            showItems={!isEmpty(search)}
            showActive
            append={
              isEmpty(search) ? (
                <div className={classes.type}>
                  <Typography
                    variant="body1"
                    style={{ color: theme.palette.grey[500].toHexString() }}
                  >
                    Search for a product or recipe
                  </Typography>
                </div>
              ) : (
                <BottomModal
                  buttonVariant="white"
                  text="Can't find a product?"
                  buttonText={'Search Catalogue'}
                  onClick={() => {
                    dispatch(setProductSearch(search))
                    navigate(routes.Supplier, { replace: true })
                  }}
                />
              )
            }
          />
        )}
      </div>
      <Modal
        title="Missing Price"
        handleCancel={() => {
          setShowModal(false)
        }}
        show={showModal}
        handleSubmit={() => {
          return
        }}
      >
        {showModal && (
          <div
            style={{
              display: 'grid',
              gap: '0px 0px',
              gridTemplateAreas: '"." "."',
              gridTemplateColumns: '1fr',
              gridTemplateRows: '1fr 100px',
              height: '100%',
              width: '100%',
            }}
          >
            <div style={{ overflow: 'scroll' }}>
              <div
                style={{
                  border: '2px solid #F0F0F0',
                  borderRadius: 10,
                  boxSizing: 'border-box',
                  margin: 15,
                  marginTop: 0,
                  padding: 20,
                }}
              >
                <Typography variant="body2">
                  You haven’t costed with this product before.
                </Typography>
                <br />
                <Typography variant="body2">
                  Please enter the price (an estimate is fine).
                </Typography>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 15,
                  padding: 10,
                }}
              >
                <Typography variant="body1">
                  {` ${itemWithNoPrice?.product?.name} ${
                    itemWithNoPrice?.product?.packSize
                  }x${itemWithNoPrice?.product?.unitValue} ${
                    itemWithNoPrice?.product?.unit.name || 'N/A'
                  }`}
                </Typography>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0 15px',
                  padding: '0 10px',
                }}
              >
                <TextField
                  startAdornment="£"
                  min={'0.01'}
                  type="number"
                  errorStyle={{
                    display: 'none',
                  }}
                  inputProps={{
                    'data-hj-allow': '',
                    style: {
                      textAlign: 'center',
                    },
                  }}
                  onChange={(value) => {
                    setItemPrice(value as unknown as number)
                  }}
                  style={{ width: 90 }}
                />
              </div>
            </div>
            <div
              style={{
                alignItems: 'center',
                backgroundColor: theme.palette.grey[50].toHexString(),
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                text="Save Price"
                type="button"
                onClick={handleSubmitSetPrice}
                disabled={!itemPrice}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  )
})
