import { makeStyles } from 'styles'

export const useStyles = makeStyles(() => ({
  container: {
    '& :hover svg ': {
      color: 'rgb(31, 48, 74)',
    },
  },
  editIcon: {
    color: 'slategray',
    fontSize: 22,
    paddingRight: 5,
  },
}))
