import { useStyles } from './styles'
import { Props } from './types'

import { Button } from '../Button'
import { Typography } from '../Typography'

export const BottomModal = ({
  text,
  buttonText,
  onClick,
  hideButton,
  buttonVariant,
}: Props) => {
  const classes = useStyles()

  return (
    <div className={classes._root}>
      <Typography variant="body1" className={classes.text}>
        {text}
      </Typography>
      {!hideButton && (
        <Button
          text={buttonText}
          onClick={onClick}
          className={classes.button}
          color={buttonVariant}
        />
      )}
    </div>
  )
}
