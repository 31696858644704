import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  actionContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    maxWidth: 50,
  },
  chipAutomatic: {
    '&:hover': {
      background: '#8cc8de',
    },
    backgroundColor: '#A3DBF1',
  },
  chipContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
  },
  chipManual: {
    '&:hover': {
      background: '#f5e08f',
    },
    backgroundColor: '#FAECA7', // Background for manual
  },
  chipStyle: {
    border: 'none',
    color: '#1F304A',
    filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25))',
    fontFamily: 'Rubik, sans-serif',
    fontWeight: '400',
    height: '1.2rem',
    marginRight: 10,
    marginTop: '0.25rem',
    width: '6rem',
  },
  date: {
    width: 150,
  },
  fontGrey: {
    color: theme.palette.grey[500],
  },
  grey: {
    backgroundColor: '#F7F7F7',
  },
  imageContainer: {
    paddingRight: 12,
  },
  imageFrame: {
    backgroundColor: 'white',
    borderColor: 'white',
    borderStyle: 'solid',
    borderWidth: '2px',
    boxSizing: 'border-box',
    // filter:
    //   'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.05))',
    filter: 'drop-shadow(0px 2px 8px rgba(76, 89, 110, 0.25));',
    marginRight: 5,
  },
  imageRowContainer: {
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    cursor: 'pointer',
  },
  info: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: '100%',
  },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 3,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: 'unset',
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'unset',
      margin: 0,
    },
  },
  itemContainerMobile: {
    alignItems: 'center',
    display: 'flex',
    gridTemplateColumns: '60px 1fr 90px 35px',
    height: 120,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  itemDesktop: {
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  itemInner: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: theme.spacing(3),
    textTransform: 'uppercase',
  },
  itemInnerMobile: {
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  // itemMobile: {
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
  itemsContainer: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 3,
    height: 'calc(100vh - 155px)',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: 'scroll',
    paddingBottom: 1,
    paddingTop: 1,
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'unset',
      borderRadius: 0,
      height: 'calc(100vh - 115px)',
      margin: 0,
      padding: 0,
    },
  },
  list: {
    // height: 'calc(100vh - 40px)', // isDesktop ? 'calc(100vh - 230px)' : 'calc(100vh - 170px)',
    // display: 'flex',
    height: 'calc(100vh - 40px)',
  },
  primaryText: {
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    width: '24rem', // Default width
    [theme.breakpoints.down('sm')]: {
      width: '10rem', // Adjust for mobile
    },
  },
  primaryTextContainer: {
    width: '20vw',
  },
  root: {
    // height: '100%',
    // flex: 1,
    marginTop: theme.spacing(5),
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
    },
  },

  searchBar: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },
  secondaryText: {
    color: theme.palette.grey[500],
    fontWeight: 400,
    marginBottom: '0.05rem',
    marginTop: '0.05rem',
    overflow: 'hidden',
    paddingTop: 4,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '50vw', // Default width
    [theme.breakpoints.down('sm')]: {
      width: '40vw', // Adjust for mobile
    },
  },
  statusStyle: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    fontFamily: 'Rubik, sans-serif',
    fontWeight: 500,
    justifyContent: 'flex-end',
  },
  textContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))
