import { cssRaw, cssRule } from 'typestyle'

import { Theme } from './ThemeProvider'

interface Props {
  theme: Theme
}

export const Global = ({ theme }: Props) => {
  const { global } = theme

  cssRule('html, body', {
    ...global,
  })

  // Remove the arrow up and down from number input fields
  // Chrome, Safari, Edge, Opera
  cssRule(
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button',
    {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  )

  // Remove the arrow up and down from number input fields
  // Firefox
  cssRule('input[type=number] ', {
    '-moz-appearance': 'textfield',
  })

  cssRule('#hubspot-messages-iframe-container', {
    display: 'none !important',
  })

  cssRule('#hubspot-conversations-inline-iframe', {
    border: 'none',
    height: '100%',
    width: '100%',
  })

  cssRule('#hubspot-conversations-inline-parent', {
    borderBottom: `8px solid ${theme.palette.common.light.toHexString()}`,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    height: '100%',
    position: 'absolute',
    width: '100%',
  })

  cssRule('.react-datepicker', {
    fontFamily: 'Rubik, sans-serif',
  })

  cssRule('.react-datepicker__current-month', {
    fontWeight: 500,
  })

  cssRule('.react-datepicker__day--selected', {
    backgroundColor: theme.palette.text.light.primary.toHexString(),
  })

  cssRule('.react-datepicker__day--in-range', {
    backgroundColor: theme.palette.text.light.primary.toHexString(),
  })

  cssRaw(`
@media print {
  @page {
    margin: 14mm;
  }

  .noPrint {
    display: none;
  }

  .onlyPrint {
    display: block;
  }

  #root {
    width: 1170px;
  }

  header {
    display: none !important;
  }
}

.noPrint {
  display: unset;
}

.onlyPrint {
  display: none;
}

body {
  overscroll-behavior: none;
}

* {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.MuiSkeleton-pulse {
  animation: MuiSkeleton-keyframes-pulse 3s ease-in-out 1s infinite !important;
}

.Toastify__toast-container {
  margin: 0px;
  padding: 0px;
  right: 0px;
  top: 56px;
  width: 100%;
}

.Toastify__toast {
  min-height: unset;
  text-align: center;
}

.Toastify__toast--success {
  color: black;
  background-color: #D4EC94;
}

.Toastify__toast--warning {
  color: black;
  background-color: #f1c40f;
}

.ReactVirtualized__Grid,
.ReactVirtualized__List {
  outline: none;
}

a {
  color: #f5d94e
}
`)

  return null
}
