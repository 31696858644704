import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { AlertWithIcon } from '../../Alert'

export function DemoAccount() {
  const { selectedKitchen } = useKitchen()

  if (!selectedKitchen?.isDemoAccount) {
    return <></>
  }
  return (
    <AlertWithIcon iconName="reportProblem" type="error" style={{ height: 60 }}>
      This is a Demo account. Changes won&#39;t be saved.
    </AlertWithIcon>
  )
}
