import { gql } from '@apollo/client'

import {
  // DishToAllergen,
  RecipeToAllergen,
  DishToAllergenInputObject,
  RecipeToIngredientInputObject,
  RecipeToRecipeInputObject,
  MutationCreateOneDishArgs,
  SortOrder,
  Status,
} from 'api'

export const createDishMutation = gql`
  mutation createDish(
    $data: CreateOneDishInputObject!
    $kitchenId: Int!
    $allergens: [RecipeToAllergenInputObject!]
    $ingredients: [RecipeToIngredientInputObject!]
    $childRecipes: [RecipeToRecipeInputObject!]
  ) {
    createOneDish(
      data: $data
      kitchenId: $kitchenId
      allergens: $allergens
      ingredients: $ingredients
      childRecipes: $childRecipes
    ) {
      _cursor
      id
      name
      gpTarget
      imageUrl
      overheads
      portion
      price
      type
      vat
      recipe {
        unitCost
        instructions
        ingredients {
          _cursor
          id
          amount
          unitCost
          unit {
            _cursor
            id
            name
            type
          }
          ingredient {
            _cursor
            id
            price
            unitPrice
            wastage
            conversionUnit
            conversionUnitType {
              _cursor
              id
              name
              type
              gramsPerUnit
            }
            conversionUnitValue
            product {
              _cursor
              id
              code
              name
              price
              unit {
                _cursor
                id
                name
                type
              }
              status
              unitValue
              packSize
              supplier {
                _cursor
                id
                name
              }
            }
          }
        }
        hasNoAllergens
        allergens {
          _cursor
          id
          contains
          removable
          allergen {
            _cursor
            id
            imageUrl
            type
          }
        }
        _cursor
        id
        name
        amount
        unit {
          _cursor
          id
          name
          type
        }
        unitCost
        childRecipes {
          _cursor
          id
          amount
          unit {
            _cursor
            id
            name
            type
          }
          unitCost
          childRecipe {
            _cursor
            id
            name
            amount
            unit {
              _cursor
              id
              name
              type
            }
            unitCost
          }
        }
      }
    }
  }
`

export const createDishMutationVariables = (
  kitchenId: number,
  name: string,
  gpTarget: number,
  overheads: number | null | undefined,
  vat: number,
  price: number,
  type: string,
  portion: number,
  unitCost: number,
  hasNoAllergens = true,
  instructions: string,
  imageUrl: string | undefined,
  allergens: Partial<RecipeToAllergen>[],
  recipes: RecipeToRecipeInputObject[],
  ingredients: RecipeToIngredientInputObject[],
  removeIngredients?: number[],
  removeRecipes?: number[],
): { variables: MutationCreateOneDishArgs } => {
  // filter removed recipes
  const recipesToAdd = removeRecipes
    ? recipes.filter((recipe) => !removeRecipes?.includes(recipe.id))
    : recipes

  // filter removed ingredients
  const ingredientsToAdd = removeIngredients
    ? ingredients.filter(
        (ingredient) => !removeIngredients.includes(ingredient.id),
      )
    : ingredients

  return {
    variables: {
      allergens: allergens.map((link) => {
        const { allergen, contains, removable } = link
        return {
          contains,
          id: allergen!.id,
          removable,
        } as DishToAllergenInputObject
      }),
      childRecipes: recipesToAdd,
      data: {
        gpTarget: gpTarget,
        hasNoAllergens: hasNoAllergens,
        imageUrl: imageUrl,
        instructions: instructions,
        name,
        overheads: overheads || 0,
        portion: portion,
        price: Number(price),
        type: type,
        unitCost: unitCost,
        vat: vat,
      },
      ingredients: ingredientsToAdd,
      kitchenId: kitchenId,
    },
  }
}

export const getDishesByKitchenQuery = gql`
  query dishes($orderBy: [DishOrderByInput!], $where: DishWhereInput!) {
    dishes(orderBy: $orderBy, where: $where) {
      name
      _cursor
      id
      menus(take: 100) {
        _cursor
        id
        dish {
          _cursor
          id
        }
        menu {
          _cursor
          id
          name
        }
      }
    }
  }
`

export const getDishesByKitchenQueryVariables = (kitchenId: number) => ({
  orderBy: [{ name: SortOrder.Asc }],
  where: {
    kitchenId: {
      equals: kitchenId,
    },
    status: {
      equals: Status.Active,
    },
  },
})

export const getAllergensQuery = gql`
  query allergens {
    allergens {
      _cursor
      id
      imageUrl
      type
    }
  }
`
