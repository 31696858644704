import { gql } from '@apollo/client'

export const getUnitsQuery = gql`
  query getUnitsQuery {
    units(orderBy: { name: asc }) {
      _cursor
      id
      name
      type
      measurementSystem
    }
  }
`
