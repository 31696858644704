import { Query } from 'api'

function queryCountryToOption(country: Query['countries'][number]) {
  return {
    label: `+${country.phone} ${country.code}`,
    value: country.phone.replace('-', ''),
  }
}

export const mapQueryCountryOptions = (
  countries: Query['countries'] | undefined,
) => {
  const map: {
    [countryCode: string]: { label: string; value: string } | undefined
  } = {}

  return {
    map,
    options: (countries || []).map((country) => {
      const option = queryCountryToOption(country)

      map[option.value] = option

      return option
    }),
  }
}
