import { MutableRefObject, Ref, useMemo } from 'react'

function getNormalizedRef<T>(ref: Ref<T> | undefined) {
  if (ref) {
    if (typeof ref === 'function') {
      return (instance: T | null) => ref(instance)
    }

    return (instance: T | null) => {
      ;(ref as MutableRefObject<T | null>).current = instance
    }
  }

  return () => {
    // pass
  }
}

export default function useComposeRefs<T>(...refs: (Ref<T> | undefined)[]) {
  return useMemo(() => {
    const [first, ...rest] = refs

    let newRef = getNormalizedRef(first)

    rest.forEach((ref) => {
      if (ref) {
        const oldRef = newRef
        const nextRef = getNormalizedRef(ref)
        newRef = (instance) => {
          oldRef(instance)
          nextRef(instance)
        }
      }
    })

    return newRef
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
