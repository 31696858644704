import { Modal, Typography } from '@getjelly/jelly-ui'
import { Divider } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ADD_INVOICE_PATH } from 'routes/Paths'
import { AutomaticAddGuide } from 'screens/Spending/Invoices/components/Guides/AutomaticAddGuide'
import { ManualAddGuide } from 'screens/Spending/Invoices/components/Guides/ManualAddGuide'

import { selectInvoiceCode } from '../../../../store/kitchen'

type Props = {
  show: boolean
  setDisplay: (arg0: boolean) => void
}

export function AddInvoiceModal({ show, setDisplay }: Props) {
  const navigate = useNavigate()
  const manualAddHandler = () => navigate(ADD_INVOICE_PATH)

  const invoiceCode = useSelector(selectInvoiceCode())

  return (
    <Modal onClose={() => setDisplay(false)} open={show}>
      <div className="space-y-4 text-center">
        <Typography style="h6">Automated</Typography>

        <AutomaticAddGuide invoiceCode={invoiceCode} />

        <div className="mb-8 mt-4 w-full">
          <Divider className="w-full">
            <p className="text-xl font-medium">or</p>
          </Divider>
        </div>

        <Typography style="h6">Manual</Typography>

        <ManualAddGuide manualAddHandler={manualAddHandler} />
      </div>
    </Modal>
  )
}
