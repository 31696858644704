import { PeriodSelector } from '@getjelly/jelly-ui'
import { IconCalendarMonth } from '@tabler/icons-react'
import { ComponentType, useMemo } from 'react'

import { PeriodEnum } from '../../api'

type Props = {
  value: PeriodEnum
  onChange: (period: PeriodEnum) => void
  className?: string
}

type DashboardPeriod = {
  id: string
  label: string
  title: string
  subtitle: string
  icon: ComponentType<{ className?: string }>
}

export function CalendarPeriodSelector({
  value,
  onChange,
  className = '',
}: Props) {
  const periods: DashboardPeriod[] = useMemo(
    () => [
      {
        icon: IconCalendarMonth,
        id: 'week',
        label: 'This Calendar Week',
        subtitle: 'From Monday to Sunday',
        title: 'Calendar Week',
      },
      {
        icon: IconCalendarMonth,
        id: 'month',
        label: 'This Calendar Month',
        subtitle: 'From the 1st to the last day of each month',
        title: 'Calendar Month',
      },
      {
        icon: IconCalendarMonth,
        id: 'quarter',
        label: 'This Calendar Quarter',
        subtitle: 'From the 1st of Month 1 to the last day of Month 3',
        title: 'Calendar Quarter',
      },
    ],
    [],
  )

  const period = useMemo(() => {
    if (value === PeriodEnum.Week) return periods[0]
    if (value === PeriodEnum.Month) return periods[1]
    if (value === PeriodEnum.Quarter) return periods[2]
    return periods[0]
  }, [periods, value])

  function setPeriod(newPeriod: DashboardPeriod) {
    if (newPeriod.id === 'week') return onChange(PeriodEnum.Week)
    if (newPeriod.id === 'month') return onChange(PeriodEnum.Month)
    if (newPeriod.id === 'quarter') return onChange(PeriodEnum.Quarter)
    return onChange(PeriodEnum.Week)
  }

  return (
    <div
      className={`h-12 w-full flex items-center justify-center bg-primary-50 ${className}`}
    >
      <PeriodSelector
        periods={periods}
        value={period}
        onChange={setPeriod}
        labelExtractor={(p) => p.label}
        titleExtractor={(p) => p.title}
        subtitleExtractor={(p) => p.subtitle}
        iconExtractor={(p) => p.icon}
      />
    </div>
  )
}
