import { useTheme } from 'styles/newUi'
import { ReactNode } from 'react'

export const Header: React.FC<{children?: ReactNode}> = ({ children }) => {
  const { theme } = useTheme()
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: theme.palette.primary[10].toHexString(),
        borderBottomColor: '#c7c7c7',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        display: 'flex',
        height: 33,
        paddingLeft: 16,
        width: '100%',
      }}
    >
      {children}
    </div>
  )
}
