import { isNil } from 'ramda'

export const toFixedIfNecessary = (
  value: number | string | undefined | null,
  dp: number,
) => {
  if (isNil(value)) return

  return +parseFloat(value as string)?.toFixed(dp)
}
