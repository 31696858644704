import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import { Supplier } from 'api'
import { RootState } from 'store'

type State = {
  supplier: Supplier | undefined
}

const initialState: State = {
  supplier: undefined,
}

const supplierSlice = createSlice({
  initialState,
  name: 'supplier',
  reducers: {
    setSupplier(state, action: PayloadAction<Supplier | undefined>) {
      state.supplier = action.payload
    },
  },
})

export const { setSupplier } = supplierSlice.actions
export const reducer = supplierSlice.reducer

const selectState = (state: RootState) => state.supplier

export const selectSupplier = () =>
  createSelector(selectState, (state) => state.supplier)
