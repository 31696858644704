import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles(() => ({
    subTitle: {
      fontSize: 20,
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 30,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
    },
  }))
