import clsx from 'clsx'
import { isEmpty, isNil } from 'ramda'

import { Typography } from 'components/newUi/Typography'

import { useStyles } from './styles'
import { Props } from './types'

/**
 *
 * @deprecated Due to needs to virtualise, it will create such massive complexity that we should implement on a case by case basis
 *
 */
export const SectionList = ({
  data,
  item: Item,
  append: Append,
  prepend: Prepend,
  rootClass,
  itemClass,
  sectionClass,
  right,
  selectedIds,
  idSelector,
  showItems = true,
  showActive,
  active,
}: Props) => {
  const classes = useStyles()

  return (
    <div
      className={clsx([classes._root, rootClass])}
      style={{ maxWidth: '100%', overflow: 'hidden' }}
    >
      {Prepend}
      {data.map((item) => (
        <div key={`section-${item.name}`} className={sectionClass}>
          {!isEmpty(item.name) && (
            <div className={classes.titleTab}>
              <Typography
                variant="body2"
                style={{ color: 'inherit', fontWeight: 600 }}
              >
                {`${item.name} ${
                  !isNil(item.count) && right ? `(${item.count})` : ''
                }`}
              </Typography>
              {showActive && active && active(item?.name, selectedIds)}
              {right ? (
                <div className={classes.right}>{right}</div>
              ) : (
                <Typography
                  variant="body2"
                  style={{ color: 'inherit', fontWeight: 600 }}
                >
                  {item.count}
                </Typography>
              )}
            </div>
          )}
          {showItems &&
            item.data.map((subItem) => (
              <div
                className={clsx([classes.item, itemClass])}
                key={`listItem-${
                  // @ts-ignore
                  idSelector ? idSelector(subItem) : subItem.id
                }`}
              >
                {Item({
                  // @ts-ignore
                  active: selectedIds?.includes(subItem.id),
                  item: subItem,
                })}
              </div>
            ))}
          {item.append}
        </div>
      ))}
      {Append}
    </div>
  )
}
