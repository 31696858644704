import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { Query } from 'api'
import { Icon, HighlightText } from 'components/newUi'
import { Typography } from 'components/newUi/Typography'
import { routes } from 'routes/Paths'
import { setSupplier } from 'store/supplier'
import { capitaliseEachWord } from 'utils'

import { useStyles } from './styles'

export const Item = ({
  item,
  search,
}: {
  search: string
  item: Query['suppliers'][number]
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const to = `${routes.Supplier}/${String(item.id)}`

  const handleOnClick = () => {
    dispatch(setSupplier(item))
  }

  return (
    <Link to={to} replace className={classes.link} onClick={handleOnClick}>
      <div className={classes.itemRoot}>
        <div>
          <Typography variant="h6" className={classes.supplierName}>
            <HighlightText
              search={search}
              text={capitaliseEachWord(item.name)}
            />
          </Typography>
          {item.catalogue && (
            <Typography variant="body2">
              Catalogue partially available
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.arrow}>
        <Icon iconName="chevronRight" />
      </div>
    </Link>
  )
}
