import { useApolloClient } from '@apollo/client'
import { Button, JellyLogoPrimary, Typography } from '@getjelly/jelly-ui'

// @ts-ignore
import { ReactComponent as JellyDead } from 'assets/jelly-dead.svg'

export const ErrorBoundary = () => {
  const client = useApolloClient()

  const handleReset = () => {
    client.resetStore()
    window.location.reload()
  }

  return (
    <div className="h-full bg-primary-900 flex items-center justify-center">
      <div className="flex flex-col">
        <div className="bg-white p-4 flex justify-center rounded-t-md">
          <JellyLogoPrimary />
        </div>

        <div className="bg-primary-50 py-8 px-4 w-80 rounded-b-md space-y-6 flex flex-col items-center">
          <div className="text-center">
            <Typography style="h6">Sorry!</Typography>
            <Typography style="caption">Something went wrong.</Typography>
            <Typography style="caption">We are on it!</Typography>
          </div>

          <JellyDead className="w-32" />

          <Button
            onClick={() => handleReset()}
            label="REFRESH"
            className="w-full"
          />
        </div>
      </div>
    </div>
  )
}
