import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Button } from '@getjelly/jelly-ui'
import { IconX } from '@tabler/icons-react'
import { useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import {
  Ingredient,
  Mutation,
  MutationDeleteOneIngredientArgs,
  QueryIngredientNodeArgs,
  Status,
} from 'api'
import { useIsDesktop } from 'app/contexts/IsDesktop'
import { Icon, NewButton, NewLayout } from 'components/newUi'
import { routes } from 'routes/Paths'
import { Modal } from 'screens/Create/Modal'
import {
  deleteOneIngredient,
  relatedItemsQuery,
} from 'screens/Products/PriceHistory/graphql'
import { ViewProduct } from 'screens/Products/View'

import { useKitchen } from '../../app/contexts/SelectedKitchen'
import { errorToast, successToast } from '../../components/toasts'

const EditButton = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <NewButton
      noFill
      text="Edit Product"
      style={{ padding: '8px 12px 8px 20px', whiteSpace: 'nowrap' }}
      onClick={() => navigate(`${routes.Product}${routes.Update}/${id}`)}
      rightAdornment={
        <Icon iconName="edit" style={{ marginLeft: 4, marginRight: -4 }} />
      }
    />
  )
}

const DeleteButton = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const isDesktop = useIsDesktop()
  const { cache } = useApolloClient()

  const { data } = useQuery<
    {
      ingredientNode: Ingredient
    },
    QueryIngredientNodeArgs
  >(relatedItemsQuery, {
    variables: {
      cursor: id,
    },
  })

  const name = data?.ingredientNode.product.name ?? ''

  const [deleteIngredient] = useMutation<
    {
      deleteOneIngredient: Mutation['deleteOneIngredient']
    },
    MutationDeleteOneIngredientArgs
  >(deleteOneIngredient, {
    onCompleted: () => {
      successToast(`${name} has been deleted`)

      const normalizedId = cache.identify({
        __typename: 'Ingredient',
        id: data?.ingredientNode.id,
      })
      cache.evict({ id: normalizedId })
      cache.gc()

      navigate(-1)
    },
    onError: (error) => {
      errorToast(String(error))
      console.error(error)
    },
  })

  const handleDelete = () => {
    if (!data?.ingredientNode.id) return

    deleteIngredient({ variables: { data: { id: data?.ingredientNode.id } } })
  }

  const deleteable = data?.ingredientNode.recipeList.nodes.every(
    (n) => n.recipe.status === Status.Inactive,
  )

  if (!deleteable) return null

  return (
    <>
      <div className="px-2">
        <Button
          onClick={() => setShowDeleteModal(true)}
          label="Delete"
          style="delete"
          icon={IconX}
        />
      </div>

      <Modal
        title={`Delete ${name}?`}
        show={showDeleteModal}
        handleSubmit={handleDelete}
        handleCancel={() => {
          setShowDeleteModal(false)
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: isDesktop ? '100%' : '100vh',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          It’ll be removed from your kitchen and can’t be used in dishes and
          recipes
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}>
            <NewButton
              noFill
              text="Cancel"
              onClick={() => setShowDeleteModal(false)}
            />
            <NewButton
              noFill
              color="orange"
              text="Delete Product"
              onClick={() => {
                setShowDeleteModal(false)
                handleDelete()
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export const ProductLayout = () => {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  return (
    <>
      <NewLayout
        onBack={() => navigate(-1)}
        title="Product"
        bottomContent={
          selectedKitchen?.userPermissions?.includes('update-product') ? (
            <div className="flex justify-center">
              <EditButton />
            </div>
          ) : undefined
        }
        rightContent={
          selectedKitchen?.userPermissions?.includes('delete-product') ? (
            <DeleteButton />
          ) : undefined
        }
      />
      <ViewProduct />

      <div className="flex-1 flex flex-col overflow-auto w-full">
        <Outlet />
      </div>
    </>
  )
}
