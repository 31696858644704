import { Maybe } from 'api'

const invoiceRejectionReasonToLabel = (text: string) =>
  text
    .replace(/___/, ' (')
    .replace(/___/, ') ')
    .replace(/__/g, ': ')
    .replace(/_/g, ' ')

function formatRejectionReasonAlert(
  rejectionReason: Array<string>,
  customRejectionReasonNote: Maybe<string> | undefined,
) {
  if (rejectionReason.length && !customRejectionReasonNote) {
    return invoiceRejectionReasonToLabel(
      `Sorry, we skipped this image because it was ${rejectionReason.join(
        ', ',
      )}`,
    )
  }

  if (!rejectionReason.length && customRejectionReasonNote) {
    return `Sorry, we skipped this image. Reason: ${customRejectionReasonNote}`
  }

  return invoiceRejectionReasonToLabel(
    `Sorry, we skipped this image because it was ${rejectionReason.join(
      ', ',
    )}. Additional notes: ${customRejectionReasonNote}`,
  )
}

export default formatRejectionReasonAlert
