import clsx from 'clsx'
import { isEmpty, isNil } from 'ramda'
import { useState, useEffect, ChangeEvent, forwardRef, RefObject } from 'react'

import { Icon } from 'components/newUi/Icon'

import { useStyles } from './styles'
import { Props } from './types'

// eslint-disable-next-line react/display-name
export const SearchBar = forwardRef((props: Props, ref) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!isNil(props.search)) {
      setSearch(props.search)
    }
  }, [props.search])

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearch(value)
    props.onSearch(value)
  }

  const handleOnClear = () => {
    setSearch('')
    props.onSearch('')
  }

  return (
    <div className={clsx([classes._root, props.className])}>
      <input
        onFocus={(e) => props?.onFocus && props?.onFocus(e)}
        onKeyUp={(e) => props?.onKeyUp && props?.onKeyUp(e)}
        className={classes.input}
        placeholder={props.searchPlaceholder}
        value={search}
        onChange={handleOnChange}
        data-hj-allow=""
        ref={ref as RefObject<HTMLInputElement>}
      />
      {!isEmpty(search) ? (
        <Icon
          data-testid="clearButton"
          iconName="clear"
          className={classes.clear}
          onClick={handleOnClear}
        />
      ) : (
        <Icon iconName="search" className={classes.clear} />
      )}
    </div>
  )
})
