import { useQuery } from '@apollo/client'
import { useContext, useMemo } from 'react'

import { Query, QueryKitchenToPosProductsArgs } from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { CategoryContext } from '../CategoryContext'
import { KTOCard } from '../components/KTOCard'
import { kitchenToPosProductsQuery } from '../graphql'

export function Linked() {
  const { selectedKitchen } = useKitchen()

  const { data } = useQuery<
    { kitchenToPosProducts: Query['kitchenToPosProducts'] },
    QueryKitchenToPosProductsArgs
  >(kitchenToPosProductsQuery, {
    variables: {
      kitchenId: selectedKitchen?.id || 0,
      linked: true,
    },
  })

  const posCategory = useContext(CategoryContext)

  const ktos = useMemo(() => {
    if (!data?.kitchenToPosProducts) {
      return []
    }

    return data.kitchenToPosProducts.filter((kto) => {
      if (!posCategory || posCategory.externalId === 'all') return true
      return kto.posProduct.posCategoryId === posCategory.id
    })
  }, [data, posCategory])

  return (
    <div className="p-4 space-y-6">
      {ktos.map((kto) => (
        <KTOCard key={kto.id} kto={kto} />
      ))}
    </div>
  )
}
