import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { BlockSelectContainer } from './components/BlockSelectContainer'
import { BlockSelectUpdateRole } from './components/BlockSelectUpdateRole'
import { DeleteTeamModal } from './DeleteTeamModal'
import { deleteKitchenToUser, getKitchenToUser } from './graphql'
import { UserDetails } from './UserDetails'

import {
  Mutation,
  MutationDeleteKitchenToUserArgs,
  Query,
  QueryKitchenToUserArgs,
} from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../components'
import { AlertWithIcon, NewLayout, Typography } from '../../../components/newUi'
import { routes } from '../../../routes/Paths'
import { useTheme } from '../../../styles/newUi'

export function EditTeam() {
  const navigate = useNavigate()
  const { theme } = useTheme()
  const { id } = useParams()
  const { selectedKitchen } = useKitchen()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [updatingKTU, setUpdatingKTU] = useState<number | undefined>(undefined)

  const { data, loading } = useQuery<
    {
      kitchenToUser: Query['kitchenToUser']
      roles: Query['roles']
    },
    QueryKitchenToUserArgs
  >(getKitchenToUser, {
    variables: { id: Number(id) },
  })

  const [deleteKTU] = useMutation<
    { deleteKitchenToUser: Mutation['deleteKitchenToUser'] },
    MutationDeleteKitchenToUserArgs
  >(deleteKitchenToUser)

  return (
    <>
      <NewLayout subtitle="Team" title="Profile" />

      <DeleteTeamModal
        handleCancel={() => setShowDeleteModal(false)}
        handleConfirm={async () => {
          if (!data?.kitchenToUser) {
            return
          }

          try {
            await deleteKTU({
              variables: {
                data: {
                  id: data.kitchenToUser.id,
                },
              },
            })

            navigate(`${routes.Settings}${routes.Team}`)
          } catch {
            setError('An error occurred while removing the user from the site ')
          }
        }}
        show={
          showDeleteModal &&
          !!selectedKitchen?.userPermissions?.includes(
            'delete-connection-kitchen-user',
          )
        }
        user={data?.kitchenToUser?.user}
        kitchen={data?.kitchenToUser?.kitchen}
      />

      <AlertWithIcon hide={!error} type="error" iconName="warning">
        <Typography style={{ fontWeight: 400 }} variant="body1">
          {error}
        </Typography>
      </AlertWithIcon>

      <AlertWithIcon hide={!success} iconName="notifications" type="success">
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          {success}
        </Typography>
      </AlertWithIcon>

      {loading && !data ? (
        <Loader />
      ) : (
        <div className="h-full p-3 flex flex-col justify-between">
          <div className="space-y-6">
            <UserDetails user={data?.kitchenToUser?.user} />

            <BlockSelectContainer>
              {data?.roles.map((role) => (
                <BlockSelectUpdateRole
                  key={role.id}
                  role={role}
                  kitchenToUser={data?.kitchenToUser}
                  updatingKTU={updatingKTU}
                  setUpdatingKTU={setUpdatingKTU}
                  setError={setError}
                  setSuccess={setSuccess}
                />
              ))}
            </BlockSelectContainer>
          </div>

          <button
            className="py-8 text-[1rem] font-medium w-full"
            onClick={() => setShowDeleteModal(true)}
          >
            <Typography
              variant="body1"
              style={{
                color: selectedKitchen?.userPermissions?.includes(
                  'delete-connection-kitchen-user',
                )
                  ? theme.palette.error[100].toHexString()
                  : theme.palette.error[50].toHexString(),
              }}
            >
              Remove from location
            </Typography>
          </button>
        </div>
      )}
    </>
  )
}
