import {
  InsightsBarChart,
  InsightsDayChart,
  InsightsLineChart,
} from '@getjelly/jelly-ui'
import { differenceInMonths, format, parse, startOfMonth } from 'date-fns'
import { useMemo } from 'react'

import { FlashSalesData, PeriodEnum } from '../../../api'

type Props = {
  periodEnum: PeriodEnum
  startDate: Date
  endDate: Date
  salesData: FlashSalesData[]
}

export function FoodFlashGraphs({ periodEnum, startDate, salesData }: Props) {
  const dataForBarChart = useMemo(() => {
    return salesData.map((d) => ({
      date: parse(d.date, 'yyyy-MM-dd', new Date()),
      sales: d.sales,
      spend: d.spend,
    }))
  }, [salesData])

  const dataForDayChart = useMemo(() => {
    return salesData.map((d) => ({
      date: parse(d.date, 'yyyy-MM-dd', new Date()),
      sales: d.sales,
      spend: d.spend,
    }))
  }, [salesData])

  const dataForLineChart = useMemo(() => {
    const salesDataByMonth = salesData.reduce((acc, d) => {
      const monthStart = startOfMonth(parse(d.date, 'yyyy-MM-dd', new Date()))
      const monthKey = format(monthStart, 'yyyy-MM')

      if (!acc[monthKey]) {
        acc[monthKey] = { date: monthStart, sales: 0, spend: 0 }
      }

      acc[monthKey].sales += d.sales
      acc[monthKey].spend += d.spend

      return acc
    }, {} as Record<string, { date: Date; sales: number; spend: number }>)

    return Object.values(salesDataByMonth).sort((a, b) =>
      differenceInMonths(a.date, b.date),
    )
  }, [salesData])

  return (
    <>
      {periodEnum === 'week' && <InsightsBarChart data={dataForBarChart} />}

      {periodEnum === 'month' && (
        <InsightsDayChart startDate={startDate} data={dataForDayChart} />
      )}

      {periodEnum === 'quarter' && (
        <InsightsLineChart data={dataForLineChart} />
      )}
    </>
  )
}
