import { createContext, useState, useContext, FC, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { routes } from 'routes/Paths'

export interface IReturnToPage {
  saveRouteAndNavigate: (route: string, replace?: boolean) => void
  returnToPage: () => void
  savedRoute: string | undefined
  componentId: number
}

export const ReturnToPageContext = createContext({} as IReturnToPage)

export const useReturnToPageContext = () => {
  return useContext(ReturnToPageContext)
}

export interface IProps {
  children?: ReactNode
}

export const ReturnToPageProvider: FC<IProps> = (props) => {
  const [savedRoute, setSavedRoute] = useState<string | undefined>(undefined)
  const navigate = useNavigate()
  const location = useLocation()

  const saveRouteAndNavigate = (route: string, replace?: boolean) => {
    setSavedRoute(location.pathname)
    navigate(route, {
      replace: replace ?? true,
    })
  }

  const returnToPage = () => {
    if (savedRoute)
      navigate(savedRoute, {
        replace: true,
      })

    setSavedRoute(undefined)
  }

  const componentId = savedRoute
    ? parseInt(getIdFromPath(savedRoute) as string)
    : -1

  return (
    <ReturnToPageContext.Provider
      value={{ componentId, returnToPage, saveRouteAndNavigate, savedRoute }}
    >
      <>{props?.children}</>
    </ReturnToPageContext.Provider>
  )
}

export const getIdFromPath = (path: string) => {
  if (path.includes(routes.Ingredient) || path.includes(routes.Allergen)) {
    return 0
  }

  if (path.includes(routes.Stock)) {
    return 'stock'
  }

  if (path.includes('update')) {
    const values = path.split('/')
    return values[values.length - 1]
  }

  if (path.includes('create')) {
    return 'create'
  }

  throw new Error(`getIdFromPath - Unhandled path ${path}`)
}
