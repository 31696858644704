import {
  useState,
  useLayoutEffect,
  createContext,
  useContext,
  useMemo,
  ReactNode
} from 'react'

import { theme } from 'styles/newUi'

export const DesktopContext = createContext(false)

export interface IProps {
  children?: ReactNode
}

export const IsDesktopProvider: React.FunctionComponent<IProps> = ({ children }) => {
  const isDesktop = useIsDesktopPrivate()

  return (
    <DesktopContext.Provider value={isDesktop}>
      {children}
    </DesktopContext.Provider>
  )
}

export const useIsDesktop = () => {
  return useContext(DesktopContext)
}

export const useIsDesktopPrivate = () => {
  const [size, setSize] = useState({ height: 0, width: 0 })

  const isSize = theme.breakpoints.values['md']

  const updateSize = () => {
    setSize({ height: window.innerHeight, width: window.innerWidth })
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize)

    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const value = useMemo(() => size.width >= isSize, [size, isSize])

  return value
}
