import { useSelector } from 'react-redux'

import { useKitchen } from 'app/contexts/SelectedKitchen'
import { selectProduct } from 'store/product'
import { selectSupplier } from 'store/supplier'

import { Form } from './Form'

export const CreateUpdate = () => {
  const { selectedKitchen } = useKitchen()
  const selectedProduct = useSelector(selectProduct())
  const selectedSupplier = useSelector(selectSupplier())

  return (
    <Form
      kitchen={selectedKitchen}
      product={selectedProduct}
      supplier={selectedSupplier}
    />
  )
}
