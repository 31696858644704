import { createContext, useContext, RefObject, FC, ReactNode } from 'react'

export interface ITabContext {
  inputRefs: RefObject<HTMLInputElement>[]
  removeRef: (index: number) => void
  addRef: (ref: RefObject<HTMLInputElement>) => void
}

const tabContextDefaults: ITabContext = {
  addRef: () => undefined,
  inputRefs: [],
  removeRef: () => undefined,
}

export const TabContext = createContext(tabContextDefaults)

let inputRefs: RefObject<HTMLInputElement>[] = []

export interface IProps {
  children?: ReactNode
}

export const TabContextProvider: FC<IProps> = (props) => {
  const addRef = (ref: RefObject<HTMLInputElement>) => {
    inputRefs = [...inputRefs, ref]
  }

  const removeRef = (index: number) => {
    inputRefs = [...inputRefs.filter((_, i) => i !== index)]
  }

  return (
    <TabContext.Provider value={{ addRef, inputRefs, removeRef }}>
      <>{props?.children}</>
    </TabContext.Provider>
  )
}

export const useTabContext = () => {
  const context = useContext(TabContext)

  return context
}
