import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'store'

const initialState: {
  currentVersion: string | undefined
  latestVersion: string | undefined
  updateRequired: boolean
  loading: boolean
  success: boolean
} = {
  currentVersion: undefined,
  latestVersion: undefined,
  loading: false,
  success: false,
  updateRequired: false,
}

const currentVersionSlice = createSlice({
  initialState,
  name: 'currentVersion',
  reducers: {
    setCurrentVersion(state, action: PayloadAction<string | undefined>) {
      localStorage.setItem('installedVersion', action.payload ?? '')
      state.currentVersion = action.payload
    },
    setLatestVersion(state, action: PayloadAction<string | undefined>) {
      localStorage.setItem('installedVersion', action.payload ?? '')
      state.latestVersion = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSuccess(state, action: PayloadAction<boolean>) {
      state.success = action.payload
    },
    setUpdateRequired(state, action: PayloadAction<boolean>) {
      state.updateRequired = action.payload
    },
  },
})

export const { setCurrentVersion, setLoading, setUpdateRequired } =
  currentVersionSlice.actions
export const selectCurrentVersion = (state: RootState) =>
  state.currentVersion.currentVersion
export const selectUpdateRequired = (state: RootState) =>
  state.currentVersion.updateRequired
export const selectLoading = (state: RootState) => state.currentVersion.loading

export const reducer = currentVersionSlice.reducer
