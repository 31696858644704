import { useMutation, useQuery } from '@apollo/client'
import { FastestCostingOnPlanetEmptyState } from '@getjelly/jelly-ui'
import { isEmpty, isNil } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AutoSizer, List } from 'react-virtualized'

import { Query, QueryMode, QueryRecipeListArgs, SortOrder, Status } from 'api'
import { userTrackingMutation } from 'api/graphql'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { SearchIcon, CloseIcon } from 'components'
import { NewTextField, Radio, Typography } from 'components/newUi'
import { LoaderWrapper } from 'components/newUi/LoadingWrapper'
import { useDebounce } from 'hooks'
import { recipeList } from 'screens/Book/graphql'
import { useTheme } from 'styles/newUi'

import { Item } from './Item'

import { routes } from '../../../routes/Paths'
import { selectCounts } from '../../../store/kitchen'

export const NewBook = () => {
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()
  const [filter, setFilter] = useState('')
  const [scrollIndex, setScrollIndex] = useState<number>()
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('query') ?? ''

  const debouncedSearch = useDebounce(search, 500)

  const { theme } = useTheme()

  const {
    data: queryResult,
    loading,
    fetchMore,
  } = useQuery<{ recipeList: Query['recipeList'] }, QueryRecipeListArgs>(
    recipeList,
    {
      notifyOnNetworkStatusChange: true,
      skip: !selectedKitchen?.id,
      variables: {
        orderBy: [
          {
            name: SortOrder.Asc,
          },
        ],
        where: {
          NOT: [
            {
              status: { equals: Status.Inactive },
            },
          ],
          dishes:
            filter === 'dish'
              ? {
                  some: {
                    id: {
                      gte: 0,
                    },
                  },
                }
              : filter === 'recipe'
              ? {
                  none: {},
                }
              : undefined,
          kitchenId: {
            equals: selectedKitchen?.id,
          },
          name: {
            contains: debouncedSearch,
            mode: QueryMode.Insensitive,
          },
        },
      },
    },
  )

  const [userTracking] = useMutation(userTrackingMutation, {
    variables: {
      data: {
        feature: 'UserFeatureCookbook',
        kitchenId: selectedKitchen?.id,
      },
    },
  })

  useEffect(() => {
    if (!selectedKitchen) return
    userTracking()
  }, [selectedKitchen])

  const data = useMemo(
    () => (!queryResult ? [] : queryResult.recipeList.nodes),
    [queryResult],
  )

  useEffect(() => {
    setScrollIndex(0)
  }, [debouncedSearch])

  useEffect(() => {
    if (isNil(scrollIndex)) return
    if (scrollIndex === 0) {
      setScrollIndex(undefined)
    }
  }, [scrollIndex])

  const counts = useSelector(selectCounts())

  if (!counts.dishCount && !counts.recipeCount && !data.length) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <FastestCostingOnPlanetEmptyState
            ctaClicked={() => navigate(routes.Book + routes.Create)}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <>
        <div>
          <NewTextField
            style={{
              backgroundColor: 'white',
              flex: 1,
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            }}
            inputProps={{
              'data-hj-allow': '',
              style: { borderRadius: 25, flex: 1 },
            }}
            placeholder="Search by name"
            value={search}
            onChange={(value) =>
              setSearchParams({ query: value as string }, { replace: true })
            }
            endAdornment={
              isEmpty(search) ? (
                <SearchIcon />
              ) : (
                <CloseIcon
                  onClick={() =>
                    setSearchParams({ query: '' }, { replace: true })
                  }
                />
              )
            }
          />
        </div>

        <div
          style={{
            borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
            display: 'flex',
            flexDirection: 'row',
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
          }}
        >
          <Typography
            variant="caption"
            style={{
              color: theme.palette.primary[60].toHexString(),
              fontWeight: 700,
              marginRight: theme.spacing(3),
            }}
          >
            Show:
          </Typography>
          <div
            style={{
              marginRight: theme.spacing(3),
            }}
          >
            <Radio
              active={filter === ''}
              label="All"
              onClick={() => {
                if (filter !== '') {
                  setFilter('')
                  setScrollIndex(0)
                }
              }}
            />
          </div>
          <div
            style={{
              marginRight: theme.spacing(3),
            }}
          >
            <Radio
              active={filter === 'dish'}
              label="Dish"
              onClick={() => {
                if (filter !== 'dish') {
                  setFilter('dish')
                  setScrollIndex(0)
                }
              }}
            />
          </div>
          <div
            style={{
              marginRight: theme.spacing(3),
            }}
          >
            <Radio
              active={filter === 'recipe'}
              label="Recipe"
              onClick={() => {
                if (filter !== 'recipe') {
                  setFilter('recipe')
                  setScrollIndex(0)
                }
              }}
            />
          </div>
        </div>
      </>

      <LoaderWrapper isLoading={loading && !queryResult?.recipeList}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <AutoSizer>
            {({ height, width }) => {
              const rowCount = data?.length ?? 0
              return (
                <List
                  containerStyle={{ overflow: 'hidden' }}
                  noRowsRenderer={() => (
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: theme.spacing(1),
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{
                          color: theme.palette.primary[60].toHexString(),
                        }}
                      >
                        Could not find any items
                      </Typography>
                    </div>
                  )}
                  scrollToIndex={scrollIndex}
                  width={width}
                  height={height - 1}
                  rowCount={rowCount}
                  rowHeight={65}
                  overscanRowCount={5}
                  onRowsRendered={({ overscanStopIndex }) => {
                    if (
                      overscanStopIndex === rowCount - 1 &&
                      !loading &&
                      queryResult?.recipeList.pageInfo?.hasNextPage &&
                      fetchMore
                    ) {
                      fetchMore({
                        variables: {
                          cursor: queryResult.recipeList.pageInfo.endCursor,
                        },
                      })

                      return
                    }
                  }}
                  rowRenderer={(props) => <Item {...props} data={data} />}
                />
              )
            }}
          </AutoSizer>
        </div>
      </LoaderWrapper>
    </>
  )
}
