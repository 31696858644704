import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Kitchen } from 'api'
import { NewButton } from 'components/newUi'
import { routes } from 'routes/Paths'
import { selectQuantity, selectComments } from 'screens/Order/store'
export const Confirm = ({
  selectedKitchen,
  id,
}: {
  selectedKitchen?: Kitchen
  id: string
}) => {
  const navigate = useNavigate()
  const quantity = useSelector(selectQuantity(`${selectedKitchen?.id}:${id}`))
  const comments = useSelector(selectComments(`${selectedKitchen?.id}:${id}`))
  return (
    <NewButton
      testId="confirmOrderButton"
      disabled={!quantity && !comments}
      text="Confirm Order"
      onClick={() => navigate(`${routes.Order}${routes.Checkout}/${id}`)}
      style={{
        maxWidth: 200,
        padding: '8px 12px 8px 14px',
        width: '50%',
      }}
    />
  )
}
