export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

export const isInStandaloneMode = () =>
  // @ts-ignore standalone exists
  navigator.standalone ||
  window.matchMedia('(display-mode: standalone)').matches

export const isIos15 = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  if (isIos() && /15\.[\d]{1,3} mobile/.test(userAgent)) {
    return true
  }
  return false
}

export const shouldFixIOS15 = () => {
  if (isInStandaloneMode()) return false

  return isIos15()
}
