import { Typography } from '@getjelly/jelly-ui'
import { IconArrowRight } from '@tabler/icons-react'

import { useKitchen } from '../../../../../app/contexts/SelectedKitchen'
import { LogoEposNow } from '../../../../../components/Logo/LogoEposNow'
import { LogoInverted } from '../../../../../components/Logo/LogoInverted'

export function Header() {
  const { selectedKitchen } = useKitchen()

  return (
    <>
      <Typography style="subtitle1" className="text-primary-900">
        Connect {selectedKitchen?.name} with Epos Now
      </Typography>

      <div className="flex justify-center">
        <div className="flex items-center space-x-4">
          <div className="bg-white rounded-xl h-[4.5rem] shadow-low w-[4.5rem] flex items-center justify-center">
            <LogoInverted />
          </div>

          <IconArrowRight className="text-secondary-400" />

          <div className="bg-white rounded-xl h-[4.5rem] shadow-low w-[4.5rem] flex items-center justify-center">
            <LogoEposNow />
          </div>
        </div>
      </div>
    </>
  )
}
