import { gql } from '@apollo/client'

export const getStockNodeQuery = gql`
  query StockNode($cursor: ID!) {
    stockNode(cursor: $cursor) {
      _cursor
      id
      date
      name
    }
  }
`
