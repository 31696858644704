import { gql } from '@apollo/client'

export const resetPasswordMutation = gql`
  mutation ResetPassword(
    $phoneNumber: String!
    $passwordResetCode: String!
    $newPassword: String!
    $newPasswordConfirm: String!
    $countryCode: String
  ) {
    resetPassword(
      phoneNumber: $phoneNumber
      passwordResetCode: $passwordResetCode
      newPassword: $newPassword
      newPasswordConfirm: $newPasswordConfirm
      countryCode: $countryCode
    ) {
      message
      token
    }
  }
`
