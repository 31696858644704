import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme, isDesktop) => ({
    _root: {
      display: !isDesktop ? 'grid' : undefined,
      gap: '0px 0px',
      gridTemplateAreas: '"." "."',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '105px 1fr',
      height: '100vh',
    },
    actionBar: {
      display: isDesktop ? 'flex' : undefined,
      justifyContent: isDesktop ? 'space-between' : undefined,
      marginTop: isDesktop ? theme.spacing(4) : undefined,
      width: isDesktop ? '100%' : undefined,
    },
    alert: {
      marginBottom: isDesktop ? theme.spacing(2) : undefined,
      marginTop: isDesktop ? theme.spacing(2) : undefined,
    },
    arrow: {
      display: 'flex',
      paddingRight: 10,
    },
    divider: {
      backgroundColor: theme.palette.primary[20].toHexString(),
      display: isDesktop ? 'none' : undefined,
      height: 1,
      marginTop: theme.spacing(1),
      width: '100%',
    },
    info: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[100].toHexString()}`,
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
      width: '100%',
    },
    itemRoot: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[50].toHexString()}`,
      display: 'flex',
      height: 70,
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      width: '100%',
    },
    itemRootDesktop: {
      backgroundColor: theme.palette.grey[50].toHexString(),
      border: `1px solid ${theme.palette.primary[20].toHexString()}`,
      borderRadius: 10,
      height: 126,
      margin: theme.spacing(1),
      padding: theme.spacing(3),
      width: '100%',
    },
    link: {
      alignItems: 'center',
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.primary[20].toHexString()}`,
      color: 'unset',
      display: 'flex',
      justifyContent: 'space-between',
      textDecoration: 'none',
      width: '100%',
    },
    list: {
      height: 'calc(100% - 150px)',
      // overflow: 'scroll',
    },
    listContainer: {},
    listItem: {},
    listItemDesktop: {
      width: '25% !important',
    },
    search: {
      width: isDesktop ? '60%' : undefined,
    },
    sectionClass: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    supplierName: {
      paddingBottom: 10,
      textTransform: 'capitalize',
    },
  }))
