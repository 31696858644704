import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Kitchen } from 'api'

import { selectPendingOrder, updateState } from '../../store'

export const PendingOrder = ({
  supplierData,
  supplierLoading,
  id,
  selectedKitchen,
}: {
  supplierLoading: boolean
  supplierData: any
  id: string
  selectedKitchen?: Kitchen
}) => {
  const dispatch = useDispatch()
  const pendingOrder = useSelector(
    selectPendingOrder(`${selectedKitchen?.id}:${id}`),
  )

  useEffect(() => {
    if (supplierLoading && supplierData?.supplier) return

    if (!supplierData?.supplier) return

    if (!pendingOrder) {
      dispatch(
        updateState({
          id: `${selectedKitchen?.id}:${id}`,
          state: {
            comments: '',
            products: {},
            quantity: 0,
          },
        }),
      )
    }
  }, [
    supplierData,
    pendingOrder,
    supplierLoading,
    dispatch,
    id,
    selectedKitchen,
  ])

  return <div />
}
