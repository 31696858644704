import { Button } from '@getjelly/jelly-ui'
import { IconCopy } from '@tabler/icons-react'

import { errorToast, successToast } from '../../../../components/toasts'

type InvoiceCodeButtonProps = {
  invoiceCode: string
}

export function InvoiceCodeButton({ invoiceCode }: InvoiceCodeButtonProps) {
  async function handleCopyClick() {
    try {
      await navigator.clipboard.writeText(invoiceCode)
      successToast('Copied to clipboard')
    } catch (e) {
      errorToast('Unable to copy to clipboard')
    }
  }

  return (
    <>
      <Button
        style="ghost"
        onClick={handleCopyClick}
        icon={IconCopy}
        className="w-full break-all"
        label={invoiceCode}
      />
    </>
  )
}
