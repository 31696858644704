import { gql } from '@apollo/client'

export const getProductQuery = gql`
  query product($cursor: ID!, $kitchenId: Int!) {
    ingredientNode(cursor: $cursor) {
      id
      _cursor
      product {
        id
        _cursor
        status
        ingredientList(skip: 0, take: 50) {
          nodes {
            _cursor
            recipeList(
              where: {
                recipe: {
                  status: { not: { equals: inactive } }
                  kitchenId: { equals: $kitchenId }
                }
              }
            ) {
              nodes {
                _cursor
                id
                recipe {
                  _cursor
                  id
                  name
                  imageUrl
                  dishList {
                    nodes {
                      _cursor
                      name
                      imageUrl
                      id
                      name
                    }
                  }
                }
                __typename
              }
            }
            id
          }
        }
      }
    }
  }
`
