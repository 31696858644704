import clsx from 'clsx'

import {
  RecipeToAllergen,
  Dish,
  Recipe,
  RecipeToIngredient,
  RecipeToRecipe,
} from 'api'
import { PrintFooter, PrintHeader } from 'components/newUi'
import { ImageContainer } from 'screens/Create/ImageContainer'

import { useStyles } from './styles'

type Props = {
  data: {
    recipes?: Recipe[]
    dishes?: Dish[]
  }
}

export const Print = (props: Props) => {
  const classes = useStyles()

  const isDish = (props.data.dishes?.length ?? 0) > 0
  const isRecipe = (props.data.recipes?.length ?? 0) > 0
  const entity = isDish
    ? props.data.dishes![0]
    : isRecipe
    ? props.data.recipes![0]
    : undefined

  if (!entity) return null

  // @ts-ignore
  const type = isDish ? entity.type : entity.section
  // @ts-ignore
  const allergens = isDish ? entity.recipe.allergens : entity.allergens
  // @ts-ignore
  const portion = isDish ? entity.portion : entity.amount
  // @ts-ignore
  const cost = isDish ? entity.recipe.unitCost : entity.unitCost
  // @ts-ignore
  const gpTarget = isDish ? entity.gpTarget : 0
  // @ts-ignore
  const overheads = isDish ? entity.overheads : 0
  // @ts-ignore
  const vat = isDish ? entity.vat : 0
  // @ts-ignore
  const price = isDish ? entity.price : 0
  const childRecipes = isDish
    ? // @ts-ignore
      entity.recipe.childRecipes
    : // @ts-ignore
      entity.childRecipes
  const childIngredients = isDish
    ? // @ts-ignore
      entity.recipe.ingredients
    : // @ts-ignore
      entity.ingredients
  const children = [
    ...childIngredients.map((ingredient: RecipeToIngredient) => ({
      amount: `${ingredient.amount}${ingredient.unit?.name}`,
      cost: ingredient.unitCost?.toFixed(2),
      id: ingredient.id,
      key: `ingredient_${ingredient.id}`,
      name: ingredient.ingredient.product.name,
    })),
    ...childRecipes.map((recipe: RecipeToRecipe) => ({
      amount: `${recipe.amount}${recipe.unit?.name}`,
      cost: recipe.unitCost?.toFixed(2),
      id: recipe.id,
      key: `recipe_${recipe.id}`,
      name: recipe.childRecipe.name,
    })),
  ]
  // @ts-ignore
  const method = isDish ? entity.recipe.instructions : entity.instructions
  // @ts-ignore
  const updatedAt = isDish ? entity.recipe.updatedAt : entity.updatedAt

  return (
    <div className={clsx([classes.onlyPrint, classes._root])}>
      <div className={classes.container}>
        <PrintHeader kitchenName={entity.kitchen.name} title={entity.name} />
        <div className={classes.divider} />
        <div className={classes.infoContainer}>
          <ImageContainer
            style={{
              minHeight: 300,
              minWidth: 300,
            }}
            mode="view"
            loading={false}
            imageSource={entity.imageUrl ?? undefined}
            altText={entity.name}
            setImageUrl={() => {
              return
            }}
          />
          <div className={classes.info}>
            <div className={classes.infoType}>{type}</div>
            <div className={classes.name}>{entity.name}</div>
            <div className={classes.portions}>
              [ {portion}{' '}
              {isDish
                ? `serving${portion > 1 ? 's' : ''}
                `
                : // @ts-ignore
                  entity?.unit?.name}{' '}
              ]
            </div>
            <div className={classes.infoTitle}>Allergens</div>
            <div className={classes.allergenContainer}>
              {allergens
                .filter(
                  (allergen: RecipeToAllergen) =>
                    allergen.contains === true && allergen.removable === false,
                )
                .map((allergen: RecipeToAllergen) => (
                  <div
                    key={`allergen_fixed_${allergen.id}`}
                    className={classes.infoText}
                  >
                    {allergen.allergen.type}
                  </div>
                ))}
            </div>

            <div className={classes.infoTitle}>Removable Allergens</div>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              {allergens
                .filter(
                  (recipeToAllergen: RecipeToAllergen) =>
                    recipeToAllergen.removable === true,
                )
                .map((recipeToAllergen: RecipeToAllergen) => (
                  <div
                    key={`allergen_removable_${recipeToAllergen.id}`}
                    className={classes.infoText}
                  >
                    {recipeToAllergen.allergen.type}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.statsContainer}>
          <div className={classes.costingContainer}>
            <div className={classes.costingTitle}>
              <div className={classes.costingTitleTitle}>Costing</div>
              <div className={classes.costingTitleServings}>
                based on
                <br />
                {portion}{' '}
                {isDish
                  ? `serving${portion > 1 ? 's' : ''}
                `
                  : // @ts-ignore
                    entity?.unit?.name}
              </div>
            </div>
            <div className={classes.costingRow}>
              <div className={classes.costingRowTitle}>
                {isDish ? 'Per serving' : `Total`}
              </div>
              <div className={classes.costingRowAmount}>
                £{' '}
                {cost
                  ? isDish
                    ? (cost / portion).toFixed(2)
                    : cost.toFixed(2)
                  : 0}
              </div>
            </div>
            {isDish && (
              <>
                <div className={classes.costingRow}>
                  <div className={classes.costingRowTitle}>Gross profit</div>
                  <div className={classes.costingRowAmount}>{gpTarget}%</div>
                </div>
                <div className={classes.costingRow}>
                  <div className={classes.costingRowTitle}>VAT</div>
                  <div className={classes.costingRowAmount}>{vat}%</div>
                </div>
                <div className={classes.costingRow}>
                  <div className={classes.costingRowTitle}>Overheads</div>
                  <div className={classes.costingRowAmount}>{overheads}%</div>
                </div>
                <div className={classes.costingTotal}>
                  <div className={classes.costingRowTitle}>Menu Price</div>
                  <div className={classes.costingRowTotal}>
                    £ {price?.toFixed(2)}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={classes.ingredientsContainer}>
            <div className={classes.ingredientsTitle}>
              <div className={classes.costingTitleTitle}>Ingredients</div>
              <div className={classes.costingTitleServings}>Amount</div>
              <div className={classes.costingTitleServings}>Cost</div>
            </div>
            {children.map((child) => (
              <div key={child.key} className={classes.ingredientsTable}>
                <div>{child.name}</div>
                <div className={classes.ingredientRowAmount}>
                  {child.amount}
                </div>
                <div className={classes.ingredientRowAmount}>
                  £ {child.cost}
                </div>
              </div>
            ))}
          </div>
        </div>
        {method && (
          <>
            <div className={classes.divider} />
            <div className={classes.method}>
              <div className={classes.methodTitle}>Method & Notes</div>
              <div className={classes.methodContent}>
                {method.split('\n').map((str: string, index: number) => (
                  <div key={index}>
                    {str}
                    <br />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <PrintFooter updatedAt={updatedAt} />
    </div>
  )
}
