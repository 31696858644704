import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { selectToken } from '../../store/auth'
import { setReturnPath } from '../../store/temp'
import { routes } from '../Paths'

export function SignInRedirect() {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const token = useSelector(selectToken())

  const tokenSet = useMemo(() => !!token, [token])

  useEffect(() => {
    if (tokenSet) {
      return
    }

    dispatch(
      setReturnPath({
        pathname: location.pathname,
        query: location.search,
      }),
    )

    navigate(routes.SignIn, {
      replace: true,
    })
  }, [tokenSet])

  return null
}
