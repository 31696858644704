import { useMutation, useQuery } from '@apollo/client'
import { Button, Modal, TextInput, Typography } from '@getjelly/jelly-ui'
import { IconSearch } from '@tabler/icons-react'
import { useMemo, useState } from 'react'

import { CreateDishModal } from './CreateDishModal'
import { DishList } from './DishList'

import {
  Dish,
  KitchenToPosProduct,
  Mutation,
  MutationLinkPosProductArgs,
  Query,
  QueryDishesArgs,
  QueryMode,
  SortOrder,
  Status,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { errorToast } from '../../../../components/toasts'
import { useDebounce } from '../../../../hooks'
import { dishesQuery, linkPosProductMutation } from '../graphql'

type Props = {
  kto: KitchenToPosProduct
  open: boolean
  onClose: () => void
}

function currencyConvert(value: number | undefined | null, pence = true) {
  if (value === undefined || value === null) {
    return '£ --'
  }

  const price = value || 0
  const adjusted = pence ? price / 100 : price

  return adjusted.toLocaleString(undefined, {
    currency: 'GBP',
    style: 'currency',
  })
}

export function CreateLinkModal({ open, onClose, kto }: Props) {
  const [linking, setLinking] = useState(false)
  const [selectedDish, setSelectedDish] = useState<Dish | null>(null)
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showCreateDishModal, setShowCreateDishModal] = useState(false)

  const debouncedSearch = useDebounce(search, 500)
  const { selectedKitchen } = useKitchen()

  const { data, previousData, loading } = useQuery<
    { dishes: Query['dishes'] },
    QueryDishesArgs
  >(dishesQuery, {
    skip: !selectedKitchen?.id || debouncedSearch.length < 3,
    variables: {
      orderBy: [{ name: SortOrder.Asc }],
      where: {
        NOT: [{ status: { equals: Status.Inactive } }],
        kitchenId: {
          equals: selectedKitchen?.id,
        },
        name: {
          contains: debouncedSearch,
          mode: QueryMode.Insensitive,
        },
      },
    },
  })

  const dishes = useMemo(() => {
    if (data?.dishes) {
      return data.dishes
    }

    if (previousData?.dishes) {
      return previousData.dishes
    }

    return []
  }, [data, previousData])

  const [linkPosProduct] = useMutation<
    { linkPosProduct: Mutation['linkPosProduct'] },
    MutationLinkPosProductArgs
  >(linkPosProductMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'kitchenToPosProductsCountQuery',
      'kitchenToPosProductsQuery',
    ],
  })

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="space-y-8">
          <div className="text-center px-2">
            <Typography style="h6" className="text-primary-900">
              Dish is already linked
            </Typography>

            <Typography style="caption" className="text-primary-600">
              This dish is already linked to another POS item, do you want to
              break that link and link it to this new one?
            </Typography>
          </div>

          <div className="space-y-2">
            <Button
              onClick={async () => {
                if (linking) return

                if (!selectedDish) {
                  errorToast('Unknown error occurred. No dish selected.')
                  return
                }

                setLinking(true)

                try {
                  await linkPosProduct({
                    variables: {
                      data: {
                        dishId: selectedDish.id,
                        ktoId: kto.id,
                      },
                    },
                  })

                  onClose()
                } catch {
                  errorToast('Unknown error occurred. Unable to unlink.')
                } finally {
                  setLinking(false)
                }
              }}
              label="Yes, Link Item"
              className="w-full"
              loading={linking}
            />

            <Button
              style="secondary"
              onClick={() => setShowModal(false)}
              label="Cancel"
              className="w-full"
            />
          </div>
        </div>
      </Modal>

      <CreateDishModal
        open={showCreateDishModal}
        onClose={(created) => {
          setShowCreateDishModal(false)

          if (created) {
            onClose()
          }
        }}
        kto={kto}
      />

      <Modal
        open={open && !showModal && !showCreateDishModal}
        onClose={() => {
          setSelectedDish(null)
          setSearch('')
          onClose()
        }}
        className="!p-0"
      >
        <div className="py-6">
          <div className="px-4">
            <Typography style="h6" className="text-primary-900">
              Link item
            </Typography>
          </div>

          <div className="space-y-3 py-4 border-b border-gray-200 px-4">
            <div className="flex items-center justify-between">
              <div>
                <Typography style="caption" className="text-primary-900">
                  POS
                </Typography>

                <Typography style="body1" className="text-primary-900">
                  {kto.posProduct.name}
                </Typography>
              </div>

              <Typography style="caption" className="text-primary-900 shrink-0">
                {currencyConvert(kto.posProduct.price)}
              </Typography>
            </div>

            <div>
              <div className="space-y-2">
                <Typography style="caption" className="text-primary-900">
                  Dish in Jelly
                </Typography>

                <TextInput
                  value={search}
                  onChange={setSearch}
                  loading={loading}
                  placeholder="Search..."
                  icon={IconSearch}
                />
              </div>
            </div>
          </div>

          <div>
            <DishList
              search={debouncedSearch}
              loading={loading}
              dishes={dishes}
              selectedDish={selectedDish}
              setSelectedDish={setSelectedDish}
            />
          </div>

          <div className="px-4 pt-4 space-y-2 border-t border-primary-200">
            <Button
              onClick={async () => {
                if (linking || !selectedDish) return

                if (selectedDish.isMatched) {
                  setShowModal(true)
                  return
                }

                setLinking(true)

                try {
                  const { data: result } = await linkPosProduct({
                    variables: {
                      data: {
                        dishId: selectedDish?.id,
                        ktoId: kto.id,
                      },
                    },
                  })

                  if (!result?.linkPosProduct) {
                    throw new Error('Unknown error occurred.')
                  }

                  setSelectedDish(null)
                  setSearch('')
                  onClose()
                } catch {
                  errorToast('Unable to link dish to POS product.')
                } finally {
                  setLinking(false)
                }
              }}
              label="Link Item"
              className="w-full"
              loading={linking}
              disabled={!selectedDish}
            />

            <Button
              onClick={() => setShowCreateDishModal(true)}
              label="Create As New Dish In Jelly"
              style="secondary"
              className="w-full"
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
