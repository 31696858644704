import { useQuery } from '@apollo/client'
import {
  AllProductsTrackedEmptyState,
  AllProductsTrackedNotPaidState,
} from '@getjelly/jelly-ui'
import { isEmpty, isNil } from 'ramda'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AutoSizer, List } from 'react-virtualized'

import {
  Ingredient,
  Query,
  QueryIngredientListArgs,
  SortOrder,
  UnitType,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import {
  CloseIcon,
  Loader,
  NewTextField,
  SearchIcon,
  SupplierSelect,
} from 'components'
import { Button, HighlightText, Icon, Typography } from 'components/newUi'
import { useDebounce, useIsPaidKitchen } from 'hooks'
import { routes } from 'routes/Paths'
import { selectSupplier, setSupplier } from 'store/supplier'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord } from 'utils'

import { getIngredientsQuery } from './graphql'

const ROW_HEIGHT = 76

export const Products = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { selectedKitchen } = useKitchen()
  const { theme } = useTheme()
  const listRef = useRef()

  const [scrollIndex, setScrollIndex] = useState<number>()
  const [search, setSearch] = useState('')
  const [data, setData] = useState<Ingredient[]>([])
  const debouncedSearch = useDebounce(search, 500)

  const isFeatureAvailable = useIsPaidKitchen()

  const selectedSupplier = useSelector(selectSupplier())

  const {
    data: ingredientsQuery,
    loading,
    fetchMore,
  } = useQuery<
    { ingredientList: Query['ingredientList'] },
    QueryIngredientListArgs
  >(getIngredientsQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: !selectedKitchen?.id,
    variables: {
      kitchenId: selectedKitchen?.id ?? -1,
      orderBy: [
        {
          product: {
            name: SortOrder.Asc,
          },
        },
      ],
      search: !isEmpty(debouncedSearch) ? debouncedSearch : undefined,
      skip: 0,
      take: 48,
      where: {
        kitchenId: {
          equals: selectedKitchen?.id ?? -1,
        },
        product: selectedSupplier
          ? {
              supplierId: { equals: selectedSupplier.id },
            }
          : undefined,
      },
    },
  })

  useEffect(() => {
    if (isNil(ingredientsQuery)) return

    if (
      ingredientsQuery.ingredientList.nodes.length > 0 &&
      !ingredientsQuery.ingredientList.pageInfo.hasNextPage
    ) {
      setData([
        ...ingredientsQuery.ingredientList.nodes,
        {
          _cursor: 'FOOTER',
          product: { name: debouncedSearch, supplier: selectedSupplier },
        } as Ingredient,
      ])
    } else {
      setData(ingredientsQuery.ingredientList.nodes)
    }

    // @ts-ignore
    listRef.current?.recomputeRowHeights()
  }, [ingredientsQuery, search])

  useEffect(() => {
    setScrollIndex(0)
  }, [debouncedSearch])

  useEffect(() => {
    if (isNil(scrollIndex)) return
    if (scrollIndex === 0) {
      setScrollIndex(undefined)
    }
  }, [scrollIndex])

  const showEmpty =
    !loading &&
    (ingredientsQuery?.ingredientList.nodes.length ?? 0) < 2 &&
    isEmpty(search) &&
    !selectedSupplier

  const handleSetSupplier = () => {
    dispatch(setSupplier(undefined))
  }

  if (!isFeatureAvailable) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <AllProductsTrackedNotPaidState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  if (showEmpty) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <AllProductsTrackedEmptyState
            ctaClicked={() =>
              navigate(routes.Spending + routes.Invoice + routes.Add)
            }
          />
        </div>
      </div>
    )
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div className="h-12 flex items-center bg-primary-50">
        <SupplierSelect title="Products" />
      </div>

      <div className="p-4 !space-y-3 bg-white border-b border-gray-200">
        <NewTextField
          className="!p-0 !m-0"
          inputProps={{
            'data-hj-allow': '',
            style: { borderRadius: 25, flex: 1 },
          }}
          placeholder="Search by name"
          value={search}
          onChange={(value) => setSearch(value as unknown as string)}
          endAdornment={
            isEmpty(search) ? (
              <SearchIcon />
            ) : (
              <CloseIcon onClick={() => setSearch('')} />
            )
          }
        />
      </div>

      <AutoSizer>
        {({ height, width }) => {
          const rowCount = data?.length ?? 0
          return (
            <List
              // @ts-ignore
              ref={listRef}
              scrollToIndex={scrollIndex}
              containerStyle={{ overflow: 'hidden' }}
              width={width}
              height={height - 113}
              rowCount={rowCount}
              rowHeight={({ index }) => {
                if (data[index]._cursor === 'FOOTER') {
                  return 180
                }
                return ROW_HEIGHT
              }}
              overscanRowCount={5}
              onRowsRendered={({ overscanStopIndex }) => {
                if (
                  overscanStopIndex === rowCount - 1 &&
                  !loading &&
                  ingredientsQuery?.ingredientList.pageInfo?.hasNextPage &&
                  fetchMore
                ) {
                  fetchMore({
                    variables: {
                      cursor:
                        ingredientsQuery.ingredientList.pageInfo.endCursor,
                    },
                  })
                  return
                }
              }}
              noRowsRenderer={() => (
                <>
                  {loading ? (
                    <Loader />
                  ) : (
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center',
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2),
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: theme.palette.primary[80].toHexString(),
                          fontWeight: 500,
                          textAlign: 'center',
                        }}
                      >
                        {`No products ${
                          debouncedSearch ? `matching "${debouncedSearch}"` : ''
                        }${
                          selectedSupplier
                            ? ` in ${capitaliseEachWord(
                                selectedSupplier.name.toLowerCase(),
                              )}`
                            : ''
                        }`}
                      </Typography>
                      {selectedSupplier ? (
                        <Button
                          text="Show Products from All Suppliers"
                          noFill
                          onClick={handleSetSupplier}
                          style={{
                            backgroundColor: 'unset',
                            border: 'none',
                            color: theme.palette.secondary[100].toHexString(),
                            marginTop: theme.spacing(2),
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                          }}
                          rightAdornment={
                            <Icon
                              iconName="chevronRight"
                              style={{ marginLeft: 4, marginRight: -4 }}
                            />
                          }
                        />
                      ) : (
                        <Button
                          text="Show all Products"
                          noFill
                          onClick={() => {
                            setSearch('')
                          }}
                          style={{
                            backgroundColor: 'unset',
                            border: 'none',
                            color: theme.palette.secondary[100].toHexString(),
                            marginTop: theme.spacing(2),
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                          }}
                          rightAdornment={
                            <Icon
                              iconName="chevronRight"
                              style={{ marginLeft: 4, marginRight: -4 }}
                            />
                          }
                        />
                      )}
                    </div>
                  )}
                </>
              )}
              rowRenderer={(props) => (
                <Item
                  {...props}
                  setSearch={setSearch}
                  setSelectedSupplier={handleSetSupplier}
                  data={data}
                  debouncedSearch={debouncedSearch}
                />
              )}
            />
          )
        }}
      </AutoSizer>
    </div>
  )
}

const Item = (props: any) => {
  const { index, style, data, setSelectedSupplier } = props
  const rowData: Ingredient = data[index]
  const { theme } = useTheme()
  const navigate = useNavigate()

  if (data[index]._cursor === 'FOOTER') {
    const { debouncedSearch } = props
    const selectedSupplier = rowData.product.supplier

    return (
      <div
        style={{
          ...style,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            color: theme.palette.primary[80].toHexString(),
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          {`No more products ${
            debouncedSearch ? `matching "${debouncedSearch}"` : ''
          }${
            selectedSupplier
              ? ` in ${capitaliseEachWord(selectedSupplier.name.toLowerCase())}`
              : ''
          }`}
        </Typography>
        <Button
          text="Show all Products"
          noFill
          onClick={() => {
            if (!isEmpty(debouncedSearch)) {
              props.setSearch('')
            } else {
              setSelectedSupplier()
            }
          }}
          style={{
            backgroundColor: 'unset',
            border: 'none',
            color: theme.palette.secondary[100].toHexString(),
            marginTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          }}
          rightAdornment={
            <Icon
              iconName="chevronRight"
              style={{ marginLeft: 4, marginRight: -4 }}
            />
          }
        />
      </div>
    )
  }

  const packSizeText =
    rowData.product.packSize !== 1 ? `${rowData.product.packSize} x ` : ''
  const unitName = rowData.product.unit
    ? rowData.product.unit.type === UnitType.Custom &&
      rowData.product.unitValue !== 1
      ? ` ${rowData.product.unit.name}s`
      : ` ${rowData.product.unit.name}`
    : ''

  return (
    <div
      key={rowData._cursor}
      onClick={() => {
        navigate(`${routes.Product}/${rowData._cursor}`)
      }}
      style={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
        cursor: 'pointer',
        display: 'grid',
        gap: '0 5px',
        gridTemplateColumns: '1fr 0fr 40px',
        height: ROW_HEIGHT,
        paddingLeft: theme.spacing(2),
        whiteSpace: 'nowrap',
        width: '100%',
        ...style,
      }}
    >
      <div style={{ overflow: 'hidden' }}>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 500,
            marginBottom: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          <HighlightText
            text={rowData?.product?.name?.toLowerCase()}
            search={props.debouncedSearch}
          />
        </Typography>
        <Typography
          variant="body2"
          style={{
            color: theme.palette.primary[60].toHexString(),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          {rowData.product?.supplier?.name?.toLowerCase()}
        </Typography>
      </div>
      <div
        style={{
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 500, marginBottom: 4 }}
        >
          {rowData.price ? `£${rowData.price?.toFixed(2)}` : 'No Price'}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: theme.palette.primary[60].toHexString() }}
        >
          {`${packSizeText}${rowData.product.unitValue}${unitName}`}
        </Typography>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Icon iconName="chevronRight" />
      </div>
    </div>
  )
}
