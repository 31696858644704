import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { Unit, UnitType } from 'api'
import { Dropdown } from 'components/newUi/Dropdown'

import { getUnitsQuery } from './graphql'
import { Props } from './types'

const EMPTY_UNIT = {
  label: 'Select a unit',
  options: [],
}

export const SelectUnit = (props: Props) => {
  const { data } = useQuery<{
    units: Unit[]
  }>(getUnitsQuery, {
    fetchPolicy: 'cache-and-network',
  })

  const structuredData = useMemo(() => {
    if (!data?.units) return [EMPTY_UNIT]

    return [
      EMPTY_UNIT,
      {
        label: 'Weight',
        options: data.units
          ?.filter((unit) => unit.type === UnitType.Weight)
          .map((x) => ({ label: x.name, value: x.id })),
      },
      {
        label: 'Volume',
        options: data.units
          ?.filter((unit) => unit.type === UnitType.Volume)
          .map((x) => ({ label: x.name, value: x.id })),
      },
      {
        label: 'Custom',
        options: data.units
          ?.filter(
            (unit) =>
              unit.type === UnitType.Custom &&
              !props.hideCustom &&
              !(!props.showPortion && unit.name === 'portion'),
          )
          .map((x) => ({ label: x.name, value: x.id })),
      },
    ]
  }, [data, props.hideCustom, props.showPortion])

  const defaultVal = useMemo(() => {
    const currentval = data?.units.find((x) => x.id === props.defaultValue)
    if (currentval) return { label: currentval.name, value: currentval.id }
    return structuredData[0].options?.find((x) => x.label === 'g')
  }, [data, props.defaultValue, structuredData])

  const isLoading = Boolean(props.defaultValue) && !defaultVal

  return (
    <Dropdown
      {...props}
      options={structuredData}
      defaultOption={defaultVal}
      searchable={false}
      loading={isLoading}
      disabled={props.disabled || isLoading}
      onChange={(value, label) => {
        if (props.onChange)
          props.onChange(
            value,
            label,
            data?.units.find((u) => u.id === value),
          )
      }}
    />
  )
}
