import { gql } from '@apollo/client'

export const kitchenTodo = gql`
  query kitchenTodo($id: Int!) {
    kitchenTodo(id: $id) {
      notes {
        id
        type
        content
        createdBy {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      assignee {
        id
        isMe
        firstName
        lastName
      }
      id
      title
      description
      completedAt
      createdAt
      updatedAt
    }
  }
`

export const updateKitchenTodoMutation = gql`
  mutation UpdateKitchenTodo($data: UpdateKitchenTodoInput!) {
    updateOneKitchenTodo(data: $data) {
      id
    }
  }
`

export const createTodoNoteMutation = gql`
  mutation CreateTodoNote($data: CreateTodoNoteInput!) {
    createOneTodoNote(data: $data) {
      id
    }
  }
`
