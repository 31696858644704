import { useLazyQuery, useMutation } from '@apollo/client'
import { ProfilePicturePanel } from '@getjelly/jelly-ui'
import { ProfilePictureData } from '@getjelly/jelly-ui/dist/components/organisms/onboarding/ProfilePicturePanel'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { updateOneUserMutation, signedProfilePictureQuery } from './graphql'

import { Mutation, MutationUpdateOneUserArgs, SignedS3Url } from '../../api'
import { useMe } from '../../hooks/auth'
import { routes } from '../../routes/Paths'
import { cleanErrorMessage, resizeImage } from '../../utils'

export function SetProfilePicture() {
  const navigate = useNavigate()

  const [getSignedProfilePicture] = useLazyQuery<{
    signedProfilePictureUpload: SignedS3Url
  }>(signedProfilePictureQuery)

  function getFileName(file: File) {
    return `ProfilePicture/${user?.id || 0}/${file.name}`
  }

  async function uploadFile(
    file: File,
    { signedURL, unsignedURL }: SignedS3Url,
  ): Promise<string> {
    await fetch(signedURL, {
      body: file,
      headers: {
        'Cache-Control': 'max-age=3153600',
        'Content-Type': file.type,
      },
      method: 'PUT',
      mode: 'cors',
    })

    return unsignedURL
  }

  const { user, refetch } = useMe()
  const [errors, setErrors] = useState<
    Partial<Record<keyof ProfilePictureData, string>> | undefined
  >(undefined)

  const [updateOneUser, { loading }] = useMutation<
    { updateOneUser: Mutation['updateOneUser'] },
    MutationUpdateOneUserArgs
  >(updateOneUserMutation)

  async function getUrl(data: ProfilePictureData): Promise<string> {
    if ('url' in data.image) {
      return data.image.url
    }

    const file = await resizeImage(data.image.file, {
      maxHeight: 512,
      maxWidth: 512,
      quality: 0.8,
    })

    const { data: result } = await getSignedProfilePicture({
      variables: { filename: getFileName(file) },
    })

    if (!result?.signedProfilePictureUpload) {
      throw new Error('Unable to get signed s3 url.')
    }

    const imageUrl = await uploadFile(file, result.signedProfilePictureUpload)

    if (!imageUrl) {
      throw new Error('Unable to get upload image to s3.')
    }

    return imageUrl
  }

  async function submit(data: ProfilePictureData) {
    if (!user) return

    try {
      await updateOneUser({
        variables: {
          data: {
            id: user.id,
            imageUrl: await getUrl(data),
          },
        },
      })

      await refetch()

      navigate(routes.Settings + routes.Locations)
    } catch (e) {
      setErrors({ image: cleanErrorMessage((e as Error).message) })
    }
  }

  return (
    <ProfilePicturePanel submit={submit} errors={errors} loading={loading} />
  )
}
