import { ReactElement, ReactNode } from 'react'

import { HeaderSection } from 'components/newUi/HeaderSection'
import { useTheme } from 'styles/newUi'

export const Scroller: React.FunctionComponent<{
  title: string
  action?: ReactElement
  noPrimaryContent?: string
  noSecondaryContent?: string
  children?: ReactNode
}> = (props) => {
  const { theme } = useTheme()
  const { title, action, noPrimaryContent, noSecondaryContent } = props
  return (
    <>
      <HeaderSection title={title} action={action} />
      <div
        style={{
          alignItems: 'center',
          backgroundColor: theme.palette.primary[5].toHexString(),
          display: 'flex',
          gap: 18,
          minHeight: 100,
          overflowY: 'auto',
          paddingLeft: 8,
          paddingRight: 8,
          width: '100%',
        }}
      >
        {!props.children && (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              fontSize: 14,
              margin: 'auto',
            }}
          >
            <div style={{ fontWeight: 'bold', marginBottom: 8 }}>
              {noPrimaryContent}
            </div>
            <div>{noSecondaryContent}</div>
          </div>
        )}

        {props.children}
      </div>
    </>
  )
}
