import { gql } from '@apollo/client'

export const updateRecipeMutation = gql`
  mutation updateRecipe(
    $data: UpdateOneRecipeInputObject!
    $ingredients: [RecipeToIngredientInputObject!]
    $childRecipes: [RecipeToRecipeInputObject!]
    $removeChildRecipes: [Int!]
    $removeIngredients: [Int!]
    $updateChildRecipes: [UpdateRecipeToRecipeInputObject!]
    $updateIngredients: [UpdateRecipeToIngredientInputObject!]
    $allergens: [RecipeToAllergenInputObject!]
    $updateAllergens: [UpdateRecipeToAllergenInputObject!]
  ) {
    updateOneRecipe(
      data: $data
      ingredients: $ingredients
      childRecipes: $childRecipes
      removeChildRecipes: $removeChildRecipes
      removeIngredients: $removeIngredients
      updateChildRecipes: $updateChildRecipes
      updateIngredients: $updateIngredients
      allergens: $allergens
      updateAllergens: $updateAllergens
    ) {
      _cursor
      id
      name
      amount
      imageUrl
      instructions
      name
      unitCost
      unit {
        _cursor
        id
        name
        type
      }
      section
      kitchen {
        _cursor
        id
        name
      }
      ingredients {
        _cursor
        id
        amount
        unitCost
        unit {
          _cursor
          id
          name
          type
        }
        recipe {
          _cursor
          id
        }
        ingredient {
          _cursor
          id
          price
          unitPrice
          wastage
          conversionUnit
          conversionUnitType {
            _cursor
            id
            name
            type
            gramsPerUnit
          }
          conversionUnitValue
          product {
            _cursor
            id
            name
            code
            price
            status
            unit {
              _cursor
              id
              name
              type
            }
            unitValue
            packSize
            supplier {
              _cursor
              id
              name
            }
          }
        }
      }
      childRecipes {
        _cursor
        id
        parentRecipe {
          _cursor
          id
        }
        amount
        unit {
          _cursor
          id
          name
          type
        }
        unitCost
        childRecipe {
          _cursor
          id
          name
          amount
          unit {
            _cursor
            id
            name
            type
          }
          unitCost
        }
      }
    }
  }
`

export const updateDishMutation = gql`
  mutation updateDish(
    $data: UpdateOneDishInputObject!
    $ingredients: [RecipeToIngredientInputObject!]
    $childRecipes: [RecipeToRecipeInputObject!]
    $removeChildRecipes: [Int!]
    $removeIngredients: [Int!]
    $updateChildRecipes: [UpdateRecipeToRecipeInputObject!]
    $updateIngredients: [UpdateRecipeToIngredientInputObject!]
    $allergens: [RecipeToAllergenInputObject!]
    $updateAllergens: [UpdateRecipeToAllergenInputObject!]
  ) {
    updateOneDish(
      data: $data
      ingredients: $ingredients
      childRecipes: $childRecipes
      removeChildRecipes: $removeChildRecipes
      removeIngredients: $removeIngredients
      updateChildRecipes: $updateChildRecipes
      updateIngredients: $updateIngredients
      allergens: $allergens
      updateAllergens: $updateAllergens
    ) {
      _cursor
      id
      name
      gpTarget
      imageUrl
      overheads
      portion
      price
      type
      vat
      recipe {
        unitCost
        instructions
        ingredients {
          _cursor
          id
          amount
          unitCost
          unit {
            _cursor
            id
            name
            type
          }
          ingredient {
            _cursor
            id
            price
            unitPrice
            wastage
            conversionUnit
            conversionUnitType {
              _cursor
              id
              name
              type
              gramsPerUnit
            }
            conversionUnitValue
            product {
              _cursor
              id
              code
              name
              price
              unit {
                _cursor
                id
                name
                type
              }
              status
              unitValue
              packSize
              supplier {
                _cursor
                id
                name
              }
            }
          }
        }
        hasNoAllergens
        allergens {
          _cursor
          id
          contains
          removable
          allergen {
            _cursor
            id
            imageUrl
            type
          }
        }
        _cursor
        id
        name
        amount
        unit {
          _cursor
          id
          name
          type
        }
        unitCost
        childRecipes {
          _cursor
          id
          amount
          unit {
            _cursor
            id
            name
            type
          }
          unitCost
          childRecipe {
            _cursor
            id
            name
            amount
            unit {
              _cursor
              id
              name
              type
            }
            unitCost
          }
        }
      }
    }
  }
`

export const deleteRecipeMutation = gql`
  mutation deleteOneRecipe($id: Int!) {
    deleteOneRecipe(id: $id) {
      id
      _cursor
      status
    }
  }
`

export const deleteDishMutation = gql`
  mutation deleteOneDish($id: Int!) {
    deleteOneDish(id: $id) {
      _cursor
      id
      name
      status
      recipe {
        _cursor
        id
        status
      }
    }
  }
`
