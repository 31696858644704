/* @TODO: Rewrite this with new queries, components and styles */
import { useQuery } from '@apollo/client'
import debounce from 'lodash.debounce'
import { isEmpty, isNil } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { Product, Query, QueryFuzzySearchProductsArgs } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader, Grid } from 'components'
import {
  BottomModal,
  SearchBar,
  NewLayout,
  VirtualizedSectionList,
} from 'components/newUi'
import { Typography } from 'components/newUi/Typography'
import { useWindowSize } from 'hooks'
import { routes } from 'routes/Paths'
import { selectProductSearch, setProductSearch } from 'store/product'
import { selectSupplier, setSupplier } from 'store/supplier'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord } from 'utils'

import { getProductsQuery } from './graphql'
import { Item } from './Item'
import { useStyles } from './styles'

export const Products = () => {
  const dispatch = useDispatch()
  const productSearch = useSelector(selectProductSearch())
  const selectedSupplier = useSelector(selectSupplier())
  const inputEl = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const classes = useStyles()
  const { gte: isDesktop } = useWindowSize('md')
  const { selectedKitchen } = useKitchen()
  const { id } = useParams()
  const { theme } = useTheme()
  const [search, setSearch] = useState(productSearch || '')
  const [hasSearched, setHasSearched] = useState(false)
  const [count, setCount] = useState(0)
  const [data, setData] = useState<Product[]>()

  const {
    data: productsData,
    loading,
    fetchMore,
  } = useQuery<
    {
      fuzzySearchProducts: Query['fuzzySearchProducts']
    },
    Omit<QueryFuzzySearchProductsArgs, 'kitchenId'> & {
      kitchenId: { equals?: number }
      supplierId: number
      searchTerm: string
      kitchenIdFuzzySearch?: number
    }
  >(getProductsQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: !selectedKitchen || !id,
    variables: {
      kitchenId: { equals: selectedKitchen?.id },
      kitchenIdFuzzySearch: selectedKitchen?.id,
      searchTerm: search,
      skip: 0,
      supplierId: +id,
      take: 48,
    },
  })

  useEffect(() => {
    if (search !== '') {
      setHasSearched(true)
    }
    if (
      isNil(productsData?.fuzzySearchProducts.count) ||
      isNil(productsData?.fuzzySearchProducts.products)
    )
      return
    setCount(productsData?.fuzzySearchProducts.count ?? 0)
    setData(productsData?.fuzzySearchProducts.products ?? [])
  }, [productsData, search])

  const onSearch = debounce((search: string) => {
    setHasSearched(true)
    setSearch(search)
    dispatch(setProductSearch(search))
  }, 500)

  const focusSearch = () => {
    if (inputEl && inputEl.current) {
      inputEl.current.focus()
    }
  }

  return (
    <>
      <NewLayout
        subtitle="Choose Supplier"
        title={capitaliseEachWord(selectedSupplier?.name)}
        onBack={() => {
          dispatch(setSupplier(undefined))
          navigate(routes.Supplier, { replace: true })
        }}
      />
      <div
        style={{
          backgroundColor: theme.palette.primary[10].toHexString(),
          paddingBottom: theme.spacing(2),
          paddingTop: theme.spacing(2),
        }}
      >
        <SearchBar
          search={search}
          searchPlaceholder="Search for a product"
          onSearch={onSearch}
          ref={inputEl}
          className={classes.search}
        />
      </div>
      {/* <Alert severity="info" className={classes.alert}>
        If the product is not in this catalogue yet, you can add it directly
      </Alert> */}
      <div className={classes.list}>
        {loading && (isEmpty(data) || isNil(data)) ? (
          <Loader />
        ) : isNil(data) ? (
          <div className={classes.info}>
            <Typography variant="body2">Something went wrong</Typography>
          </div>
        ) : (
          <>
            <VirtualizedSectionList
              key={search}
              pageInfo={{
                hasNextPage: data.length !== count,
                hasPreviousPage: false,
                skip: data?.length ?? 0,
                take: 48,
              }}
              fetchMore={() => {
                fetchMore({
                  variables: {
                    skip: data?.length,
                    take: 48,
                  },
                })
              }}
              data={[
                {
                  items: data ?? [],
                  label: 'Catalogue Products',
                  rightAdornment: <div>{count ?? 0}</div>,
                },
              ]}
              loading={loading}
              ROW_HEIGHT={isDesktop ? 52 : 82}
              Item={(props: any) => {
                const item = props.data.items[props.index]
                return (
                  <Item item={item} kitchen={selectedKitchen} search={search} />
                )
              }}
              footerHeight={search ? 250 : undefined}
              footer={
                <>
                  {search ? (
                    <Grid
                      onClick={() => {
                        setSearch('')
                      }}
                      container
                      item
                      xs={12}
                      style={{
                        alignItems: 'center',
                        backgroundColor: theme.palette.primary[5].toHexString(),
                        borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 100,
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          alignItems: 'center',
                          cursor: 'pointer',
                          display: 'flex',
                          fontWeight: 600,
                          height: 40,
                          paddingLeft: theme.spacing(2),
                          paddingRight: theme.spacing(2),
                          textAlign: 'center',
                        }}
                      >
                        No more products matching {`'${search}'`}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          alignItems: 'center',
                          color: theme.palette.secondary[100].toHexString(),
                          cursor: 'pointer',
                          display: 'flex',
                          fontWeight: 600,
                          height: 40,
                        }}
                      >
                        Show All Products {'>'}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <BottomModal
                    buttonVariant="white"
                    text="Can't find a product?"
                    buttonText="Add a Product"
                    onClick={
                      !hasSearched
                        ? focusSearch
                        : () => {
                            navigate(`${routes.Product}${routes.Create}`, {
                              replace: true,
                            })
                          }
                    }
                  />
                </>
              }
            />
          </>
        )}
      </div>
    </>
  )
}
