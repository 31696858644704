import { useKitchen } from '../../app/contexts/SelectedKitchen'

import { RegisterListener } from './index'

type Props = {
  registerListener: RegisterListener
}

const listenerId = 'RefreshKitchen.tsx'

export function RefreshKitchen({ registerListener }: Props) {
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()

  // When a kitchen-to-user is updated, refetch the kitchen.
  registerListener(listenerId, 'kitchen-to-user-updated', async (data) => {
    if (data?.kitchenId !== selectedKitchen?.id) {
      return
    }

    console.log('Refetching Kitchen. [WEBSOCKET-EVENT]')
    await refetchSelectedKitchen()
  })

  // When a new websocket connection is started, refetch the kitchen to ensure
  // kitchen is up-to-date.
  registerListener(listenerId, 'websocket-opened', async () => {
    console.log('Refetching Kitchen. [WEBSOCKET-OPENED]')
    await refetchSelectedKitchen()
  })

  return <></>
}
