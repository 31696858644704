import { calc } from 'csx'

import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme, isDesktop) => ({
    allergenContains: {
      ' & svg': {
        height: 50,
        width: 50,
      },
      filter:
        'invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);',
      stroke: 'red',
    },
    allergenNone: {
      ' & svg': {
        height: 50,
        width: 50,
      },
      filter:
        'invert(69%) sepia(1%) saturate(0%) hue-rotate(335deg) brightness(89%) contrast(95%)',
      stroke: 'rgb(245, 245, 245)',
    },
    allergenRemovable: {
      ' & svg': {
        height: 50,
        width: 50,
      },
      filter:
        'invert(93%) sepia(94%) saturate(1079%) hue-rotate(320deg) brightness(109%) contrast(92%)',
      stroke: theme.palette.tertiary[100].toHexString(),
    },
    backButton: {
      cursor: 'pointer',
      left: 10,
      position: 'absolute',
    },
    bottomButton: {
      backgroundColor: '#e0e0e0',
    },
    bottomField: {
      alignItems: 'center',
      backgroundColor: theme.palette.tertiary[100].toHexString(),
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
    },
    button: {
      borderRadius: 20,
    },
    costingField: {
      flex: 1,
    },
    divider: {
      backgroundColor: theme.palette.primary[20].toHexString(),
      display: isDesktop ? 'none' : undefined,
      height: 1,
      marginTop: -1,
      width: '100%',
    },
    item: {
      alignItems: 'center',
      border: '1px solid #e0e0e0',
      cursor: 'pointer',
      display: 'flex',
      flex: 1,
      marginLeft: -16,
      // marginBottom: 10,
      padding: 30,
      width: '100vw',
    },
    left: {
      flexGrow: 1,
    },
    leftBottom: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      justifyContent: 'space-evenly',
    },
    noPrint: {
      '@media print': {
        display: 'none',
      },
      display: 'undefined',
    },
    outer: {
      height: isDesktop ? calc('100vh - 56px') : '100vh',
    },

    pill: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary[40].toHexString(),
      border: 'none',
      borderRadius: 16,
      bottom: 0,
      color: 'white',
      display: 'flex',
      fontSize: 14,
      height: 30,
      justifyContent: 'center',
      marginBottom: -4,
      marginRight: 5,
      marginTop: 5,
      minWidth: 90,
      padding: 0,
      position: 'relative',
      // [theme.breakpoints.up('md')]: {
      //   display: 'none',
      // },
    },
    pillPot: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 500,
    },
    right: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      // padding: 20,
      textAlign: 'center',
    },
    rightBottom: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      justifyContent: 'space-evenly',
    },
  }))
