import { gql } from '@apollo/client'

export const createOneSquareAuthoriseUrlMutation = gql`
  mutation createOneSquareAuthoriseUrl($kitchenId: Int!) {
    createOneSquareAuthoriseUrl(kitchenId: $kitchenId) {
      url
    }
  }
`

export const createPosAccountForSquareMutation = gql`
  mutation createOnePosAccountForSquare($data: CreatePosAccountSquareInput!) {
    createOnePosAccountForSquare(data: $data) {
      id
    }
  }
`

export const kitchenToPosAccountsQuery = gql`
  query kitchenToPosAccounts($kitchenId: Int!) {
    kitchenToPosAccounts(kitchenId: $kitchenId) {
      id
      posAccountId
      posAccount {
        id
        posTypeId
      }
      createdAt
    }
  }
`

export const kitchenToPosLocationsQuery = gql`
  query kitchenToPosLocations($kitchenId: Int!, $posAccountId: Int!) {
    kitchenToPosLocations(kitchenId: $kitchenId, posAccountId: $posAccountId) {
      id
      posLocation {
        id
        name
      }
    }
  }
`

export const posTypeQuery = gql`
  query posType($name: String!) {
    posType(name: $name) {
      id
    }
  }
`

export const posLocationsQuery = gql`
  query posLocations($posAccountId: Int!) {
    posLocations(posAccountId: $posAccountId) {
      id
      name
      kitchenId
    }
  }
`

export const linkPosLocationMutation = gql`
  mutation linkPosLocation($data: LinkPosLocationInput!) {
    linkPosLocation(data: $data) {
      id
    }
  }
`

export const deleteKitchenToPosAccountsMutation = gql`
  mutation deleteOneKitchenToPosAccount($kitchenId: Int!, $posAccountId: Int!) {
    deleteOneKitchenToPosAccount(
      kitchenId: $kitchenId
      posAccountId: $posAccountId
    ) {
      id
    }
  }
`
