import { gql } from '@apollo/client'

export const updateOneUserMutation = gql`
  mutation SetJobResponsibilities($data: UpdateOneUserInputObject!) {
    updateOneUser(data: $data) {
      id
    }
  }
`

export const jobResponsibilitiesQuery = gql`
  query JobResponsibilities {
    jobResponsibilities {
      id
      name
      description
    }
  }
`
