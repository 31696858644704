import clsx from 'clsx'
import { isNil } from 'ramda'
import { useEffect, useState } from 'react'

import { Allergen, Menu } from 'api'
import { Icon, PrintFooter, PrintHeader } from 'components/newUi'
import { useTheme } from 'styles/newUi'

import { useStyles } from './styles'

type Props = {
  data?: any[]
  kitchenName?: string
  menu: Menu
  allergens: Allergen[]
}

export const Print = ({ data, kitchenName, menu, allergens }: Props) => {
  const classes = useStyles()
  const { theme } = useTheme()
  const [indexedAllergens, setIndexedAllergens] = useState({})

  const gridTemplateColumns = `${`1fr ${Array.from({
    length: allergens.length,
  }).map(() => '60px ')}`.replace(/[, ]+/g, ' ')}`

  useEffect(() => {
    const holdingObject: Record<string, number> = {}
    allergens.forEach((allergen, index) => {
      holdingObject[allergen.type] = index
    })
    setIndexedAllergens(holdingObject)
  }, [allergens])

  return (
    <div className={clsx([classes.onlyPrint, classes._root])}>
      <div className={classes.container}>
        <PrintHeader
          kitchenName={kitchenName as string}
          title={`${menu.name} - Allergen List`}
          component={() => (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginRight: 20,
                }}
              >
                <div
                  style={{
                    borderRadius: 15,
                    boxShadow: `inset -1px -1px 30px 30px ${theme.palette.error[100].toHexString()}`,
                    height: 30,
                    marginRight: 10,
                    width: 30,
                  }}
                />
                <div>Has Allergen</div>
              </div>
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <div
                  style={{
                    border: `3px solid ${theme.palette.error[100].toHexString()}`,
                    borderRadius: 15,
                    height: 30,
                    marginRight: 10,
                    width: 30,
                  }}
                />
                <div>Removable Allergen</div>
              </div>
            </div>
          )}
        />
        <div
          style={{
            display: 'grid',
            gridTemplateAreas: '. .',
            gridTemplateColumns,
          }}
        >
          <div
            style={{
              backgroundColor: theme.palette.primary[10].toHexString(),
            }}
          />
          {allergens.map((allergen) => (
            <div
              key={`allergen-${allergen.id}`}
              style={{
                alignItems: 'center',
                backgroundColor: theme.palette.primary[10].toHexString(),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 5,
              }}
            >
              <img src={allergen.imageUrl ?? ''} alt={allergen.type} />
              <div style={{ fontSize: 12, textTransform: 'capitalize' }}>
                {allergen.type}
              </div>
            </div>
          ))}
          {data?.map((item, outerIndex) => (
            <>
              <div
                style={{
                  alignItems: 'center',
                  borderBottom:
                    outerIndex === data.length - 1
                      ? `1px solid ${theme.palette.primary[60].toHexString()}`
                      : undefined,
                  borderTop: `1px solid ${theme.palette.primary[60].toHexString()}`,
                  display: 'flex',
                  padding: 5,
                  paddingBottom: 15,
                  paddingTop: 15,
                  textTransform: 'capitalize',
                }}
              >
                {item.name}
              </div>
              {item['no data'] ? (
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: theme.palette.primary[10].toHexString(),
                    borderBottom:
                      outerIndex === data.length - 1
                        ? `1px solid ${theme.palette.primary[60].toHexString()}`
                        : undefined,
                    borderTop: `1px solid ${theme.palette.primary[60].toHexString()}`,
                    color: theme.palette.error[100].toHexString(),
                    display: 'flex',
                    gridColumnStart: `span ${allergens.length}`,
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Icon iconName="allergen" style={{ marginRight: 5 }} />
                  NO DATA
                </div>
              ) : item['no allergens'] ? (
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: theme.palette.primary[10].toHexString(),
                    borderBottom:
                      outerIndex === data.length - 1
                        ? `1px solid ${theme.palette.primary[60].toHexString()}`
                        : undefined,
                    borderTop: `1px solid ${theme.palette.primary[60].toHexString()}`,
                    color: theme.palette.primary[60].toHexString(),
                    display: 'flex',
                    gridColumnStart: `span ${allergens.length}`,
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Icon iconName="info" style={{ marginRight: 5 }} />
                  NO ALLERGENS
                </div>
              ) : (
                Object.keys(indexedAllergens).map((allergenKey, index) => {
                  const hasAllergen =
                    (!isNil(item[allergenKey]) &&
                      item[allergenKey]?.removable) ||
                    item[allergenKey]?.contains
                  const allergenInfo = hasAllergen && item[allergenKey]
                  const removable = allergenInfo?.removable
                  const contains = allergenInfo?.contains
                  return (
                    <div
                      key={`dish-${item.name}-${allergenKey}`}
                      style={{
                        alignItems: 'center',
                        backgroundColor:
                          index % 2 === 0
                            ? theme.palette.primary[10].toHexString()
                            : undefined,
                        borderBottom:
                          outerIndex === data.length - 1
                            ? `1px solid ${theme.palette.primary[60].toHexString()}`
                            : undefined,
                        borderLeft:
                          index === 0
                            ? `1px solid ${theme.palette.primary[10].toHexString()}`
                            : undefined,
                        borderRight: `1px solid ${theme.palette.primary[10].toHexString()}`,
                        borderTop: `1px solid ${theme.palette.primary[60].toHexString()}`,
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 5,
                      }}
                    >
                      <div
                        style={{
                          border: removable
                            ? `3px solid ${theme.palette.error[100].toHexString()}`
                            : undefined,
                          borderRadius: 15,
                          boxShadow:
                            contains && !removable
                              ? `inset -1px -1px 30px 30px ${theme.palette.error[100].toHexString()}`
                              : undefined,
                          height: 30,
                          width: 30,
                        }}
                      />
                    </div>
                  )
                })
              )}
            </>
          ))}
        </div>
        <PrintFooter updatedAt={menu.updatedAt} />
      </div>
    </div>
  )
}
