import { Tooltip } from '@mui/material'
import { useState } from 'react'

import { Product, Kitchen } from 'api'
import { HighlightText } from 'components/newUi'
import { useWindowSize } from 'hooks'
import { useTheme } from 'styles/newUi'

import { Price } from './Price'
import { Quantity } from './Quantity'
import { Size } from './Size'

export const Item = ({
  rowData,
  search,
  includeCost,
  supplierId,
  selectedKitchen,
  style,
}: {
  rowData: Product
  search?: string
  selectedKitchen?: Kitchen
  supplierId: string
  includeCost?: boolean
  style?: any
}) => {
  const { gte: isDesktop } = useWindowSize('md')
  const { theme } = useTheme()
  const [open, setOpen] = useState(-1)
  const packSizeFormat = rowData.packSize > 1 ? `${rowData.packSize}x` : ''
  const product = {
    cost: rowData?.ingredients[0]?.price,
    id: rowData.id,
    item: rowData?.name ?? '',
    packSize: `${packSizeFormat}${rowData.unitValue} ${rowData.unit.name}`,
  }

  return (
    <div
      key={product.id}
      data-testid={rowData.name}
      style={{
        // background: dark ? theme.palette.primary[5].toHexString() : 'white',
        background: 'white',
        borderBottom: `2px solid ${theme.palette.grey[100].toHexString()}`,
        display: 'flex',
        flexDirection: isDesktop ? 'row' : 'column',
        height: isDesktop ? 65 : 92,
        justifyContent: 'space-between',
        padding: '16px 16px 13px 16px',
        ...style,
      }}
    >
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => {
          setOpen(-1)
        }}
        open={open === product.id}
        title={product.item?.toUpperCase()}
      >
        <div
          style={{
            alignItems: isDesktop ? 'center' : 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
            lineHeight: '15px',
            marginBottom: theme.spacing(1),
            overflow: 'hidden',
            paddingRight: isDesktop ? 15 : undefined,
            width: isDesktop ? '40%' : undefined,
          }}
        >
          {/* eslint-disable-next-line */}
          <div
            onClick={() => {
              setOpen(product.id)
            }}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
            }}
          >
            <span style={{ width: '80%' }}>
              <HighlightText
                text={product.item.toLowerCase()}
                search={search || ''}
              />
            </span>
          </div>
          <Price
            product={product}
            supplierId={supplierId}
            includeCost={includeCost}
            selectedKitchen={selectedKitchen}
          />
        </div>
      </Tooltip>
      <div
        style={{
          alignItems: 'center',
          color: theme.palette.secondary[100].toHexString(),
          display: 'flex',
          height: 30,
          justifyContent: 'space-between',
          paddingLeft: isDesktop ? 15 : undefined,
          width: isDesktop ? '35%' : undefined,
        }}
      >
        <Size size={product.packSize} id={product.id} />
        <Quantity id={product.id} />
      </div>
    </div>
  )
}
