import {
  KnowYourSpendingEmptyState,
  KnowYourSpendingNotPaidState,
} from '@getjelly/jelly-ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useIsPaidKitchen } from 'hooks'
import { routes } from 'routes/Paths'

import { SpendMain } from './SpendMain'

import { selectCounts } from '../../../store/kitchen'

export const SpendingHistory = () => {
  dayjs.extend(customParseFormat)
  dayjs.extend(isBetween)
  const navigate = useNavigate()
  const isFeatureAvailable = useIsPaidKitchen()

  const counts = useSelector(selectCounts())

  if (!isFeatureAvailable) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <KnowYourSpendingNotPaidState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  if (counts.invoiceCount === 0) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <KnowYourSpendingEmptyState
            ctaClicked={() =>
              navigate(routes.Spending + routes.Invoice + routes.Add)
            }
          />
        </div>
      </div>
    )
  }

  return <SpendMain />
}
