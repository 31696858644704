import { Typography } from 'components'
import { AutomatedBadge } from 'screens/Spending/Invoices/badges/AutomatedBadge'
import { useTheme } from 'styles/newUi/ThemeProvider'

type RowSupplier = {
  name: string
  automated: boolean
  count?: number
  automatedCount?: number
  productCount: number
}

type Props = {
  supplier: RowSupplier
  selected: boolean
  onClick: () => void
}

export function Row({ supplier, selected, onClick }: Props) {
  const { theme } = useTheme()

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => {
        //do nothing
      }}
      onClick={onClick}
      style={{
        backgroundColor: selected
          ? theme.palette.primary[10].toHexString()
          : 'white',
        borderRadius: 5,
        boxShadow: selected
          ? 'inset 1px 1px 4px rgba(0, 0, 0, 0.1)'
          : '0px 2px 3px rgba(76, 89, 110, 0.1)',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0',
        padding: 15,
      }}
    >
      <div className="capitalize">
        <Typography
          variant="h6"
          style={{
            color: selected
              ? undefined
              : theme.palette.secondary[100].toHexString(),
          }}
        >
          {supplier.name.toLowerCase()}
        </Typography>

        <Typography
          style={{
            color: theme.palette.primary[60].toHexString(),
            fontSize: 14,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {supplier?.productCount} Products
        </Typography>
      </div>

      {supplier.count !== undefined && supplier.automatedCount !== undefined ? (
        <div className="flex items-center">
          <p className="text-primary-600 font-medium text-sm">
            {supplier.automatedCount} out of {supplier.count} Automated
          </p>
        </div>
      ) : (
        <></>
      )}

      {supplier.count === undefined && supplier.automatedCount === undefined ? (
        <div className="flex items-center">
          {supplier.automated ? <AutomatedBadge /> : null}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
