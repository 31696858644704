import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store'

export type ReduxUser = {
  id: number
  firstName: string
  lastName: string
  email: string
  imageUrl: string
  phoneNumberNational: string
  hasPassword: boolean
  jobResponsibilityIds: number[]
  jobRoleIds: number[]
}

type State = {
  user: ReduxUser | null
}

const initialState: State = {
  user: null,
}

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setUser(state, action: PayloadAction<ReduxUser | null>) {
      state.user = action.payload
    },
  },
})

export const { setUser } = userSlice.actions
export const reducer = userSlice.reducer

const selectState = (state: RootState) => state.user

export const selectUser = () =>
  createSelector(selectState, (state) => state.user)
