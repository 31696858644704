import { useQuery } from '@apollo/client'
import { isEmpty, isNil } from 'ramda'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Query, QuerySupplierNodeArgs } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import {
  MenuList,
  NewLayout,
  iconDictionary,
  Typography,
} from 'components/newUi'
import { routes } from 'routes/Paths'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord, deliveryDaysFromTo, encodeCursor } from 'utils'

import { getSupplierQuery } from './graphql'

export const SupplierView = () => {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()
  const { id } = useParams()
  const [data, setData] = useState<
    { supplierNode: Query['supplierNode'] } | undefined
  >()
  const skip = isNil(selectedKitchen?._cursor)
    ? isNil(selectedKitchen?.id)
      ? true
      : false
    : false
  const kitchenCursor = selectedKitchen?._cursor
    ? selectedKitchen?._cursor
    : selectedKitchen?.id
    ? encodeCursor('Kitchen', selectedKitchen?.id)
    : undefined
  const { data: supplierData, loading } = useQuery<
    { supplierNode: Query['supplierNode'] },
    QuerySupplierNodeArgs & { kitchenCursor?: string }
  >(getSupplierQuery, {
    skip,
    variables: {
      cursor: id!,
      kitchenCursor,
    },
  })

  useEffect(() => {
    setData(supplierData)
  }, [supplierData])
  const { theme } = useTheme()

  const supplier = data?.supplierNode

  const kitchenInfo = supplier?.kitchenList.nodes[0]

  const supplierContactDetails = [
    ...(!isEmpty(kitchenInfo?.emails) && !isNil(kitchenInfo?.emails)
      ? [
          {
            icon: 'edit' as keyof typeof iconDictionary,
            iconStyle: {
              color: theme.palette.secondary[100].toHexString(),
              fontSize: 21,
              marginRight: 8,
            },
            key: 'emails',
            onClick: () =>
              navigate(
                `${routes.Order}${routes.Supplier}${routes.Edit}/${supplier?._cursor}`,
              ),
            subtitle: kitchenInfo?.emails.join('\n'),
            title: 'Orders will be sent to',
          },
        ]
      : [
          !isEmpty(supplier?.emails) && !isNil(supplier?.emails)
            ? {
                key: 'supplier-emails',
                subtitle: supplier?.emails.join('\n'),
                title: 'Orders will be sent to',
              }
            : {
                key: 'supplier-emails',
                prefixIcon: 'warning' as keyof typeof iconDictionary,
                prefixIconStyle: {
                  color: theme.palette.error[100].toHexString(),
                },
                title: 'No supplier email set',
                titleStyle: {
                  color: theme.palette.error[100].toHexString(),
                  fontWeight: 500,
                },
              },
          {
            icon: 'add' as keyof typeof iconDictionary,
            iconStyle: { color: theme.palette.secondary[100].toHexString() },
            key: 'emails',
            onClick: () =>
              navigate(
                `${routes.Order}${routes.Supplier}${routes.Edit}/${supplier?._cursor}`,
              ),
            subtitle:
              isEmpty(supplier?.emails) || isNil(supplier?.emails)
                ? 'Set email address'
                : 'Override default email address',
            subtitleStyle: {
              color: theme.palette.secondary[100].toHexString(),
              fontWeight: 500,
            },
          },
        ]),
    kitchenInfo?.contactName && kitchenInfo?.phone
      ? {
          icon: 'edit' as keyof typeof iconDictionary,
          iconStyle: {
            color: theme.palette.secondary[100].toHexString(),
            fontSize: 21,
            marginRight: 8,
          },
          key: 'contact',
          onClick: () =>
            navigate(
              `${routes.Order}${routes.Supplier}${routes.Edit}/${supplier?._cursor}`,
            ),
          subtitle:
            kitchenInfo?.contactName && kitchenInfo?.phone
              ? `${kitchenInfo?.contactName} - ${kitchenInfo?.phone}`
              : '',
          title: 'Your supplier contact',
        }
      : {
          icon: 'add' as keyof typeof iconDictionary,
          iconStyle: { color: theme.palette.secondary[100].toHexString() },
          key: 'contact',
          onClick: () =>
            navigate(
              `${routes.Order}${routes.Supplier}${routes.Edit}/${supplier?._cursor}`,
            ),
          subtitle: 'Add a supplier contact',
          subtitleStyle: {
            color: theme.palette.secondary[100].toHexString(),
            fontWeight: 500,
          },
        },
  ]

  const kitchenNotes = [
    kitchenInfo?.accountReference
      ? {
          icon: 'edit' as keyof typeof iconDictionary,
          iconStyle: {
            color: theme.palette.secondary[100].toHexString(),
            fontSize: 21,
            marginRight: 8,
          },
          key: 'accountReference',
          onClick: () =>
            navigate(
              `${routes.Order}${routes.Supplier}${routes.Edit}/${supplier?._cursor}`,
            ),
          subtitle: kitchenInfo?.accountReference,
          title: 'Your kitchens account reference',
        }
      : {
          icon: 'add' as keyof typeof iconDictionary,
          iconStyle: { color: theme.palette.secondary[100].toHexString() },
          key: 'accountReference',
          onClick: () =>
            navigate(
              `${routes.Order}${routes.Supplier}${routes.Edit}/${supplier?._cursor}`,
            ),
          subtitle: "Add your kitchen's account reference",
          subtitleStyle: {
            color: theme.palette.secondary[100].toHexString(),
            fontWeight: 500,
          },
        },
    kitchenInfo?.notes
      ? {
          icon: 'edit' as keyof typeof iconDictionary,
          iconStyle: {
            color: theme.palette.secondary[100].toHexString(),
            fontSize: 21,
            marginRight: 8,
          },
          key: 'notes',
          onClick: () =>
            navigate(
              `${routes.Order}${routes.Supplier}${routes.Edit}/${supplier?._cursor}`,
            ),
          subtitle: kitchenInfo?.notes,
          title: 'Your kitchen’s notes on this supplier',
        }
      : {
          icon: 'add' as keyof typeof iconDictionary,
          iconStyle: { color: theme.palette.secondary[100].toHexString() },
          key: 'notes',
          onClick: () =>
            navigate(
              `${routes.Order}${routes.Supplier}${routes.Edit}/${supplier?._cursor}`,
            ),
          subtitle: 'Add notes on this supplier',
          subtitleStyle: {
            color: theme.palette.secondary[100].toHexString(),
            fontWeight: 500,
          },
        },
  ]

  const addresses = supplier?.addressList.nodes ?? []

  const supplierOptions = [
    {
      key: 'notes',
      prefixIcon: 'file' as keyof typeof iconDictionary,
      title: supplier?.notes ?? undefined,
    },
    ...addresses.map((node) => ({
      key: node._cursor,
      link: `https://www.google.com/maps/search/${node.address.name} ${node.address.line1} ${node.address.city} ${node.address.postCode}`,
      prefixIcon: 'place' as keyof typeof iconDictionary,
      title: `${node.address.name}\n${node.address.line1}\n${node.address.city}, ${node.address.postCode}`,
    })),
    {
      key: 'phone',
      link: `tel:${supplier?.phone}`,
      prefixIcon: 'phone' as keyof typeof iconDictionary,
      title: supplier?.phone ?? undefined,
    },
    {
      key: 'email',
      link: `mailto:${supplier?.emails.join(';')}`,
      prefixIcon: 'email' as keyof typeof iconDictionary,
      title: supplier?.emails.join('\n'),
    },
    {
      key: 'website',
      link: supplier?.website,
      prefixIcon: 'link' as keyof typeof iconDictionary,
      title: supplier?.website?.replace(/^(https?):\/\//, ''),
    },
  ]

  return (
    <div>
      <NewLayout
        hideMenu
        title={capitaliseEachWord(supplier?.name.toLowerCase())}
        subtitle="Place an Order"
        rightContent={
          supplier?.logoUrl ? (
            <div style={{ height: 64, padding: theme.spacing(1), width: 74 }}>
              <img
                style={{ height: '100%', objectFit: 'fill', width: '100%' }}
                alt={`${supplier?.name} logo`}
                src={supplier?.logoUrl}
              />
            </div>
          ) : undefined
        }
      />
      {loading && isNil(supplier) ? (
        <div style={{ height: 'calc(100vh - 64px)' }}>
          <Loader />
        </div>
      ) : (
        <>
          {supplier?.deliveryDays && (
            <div
              style={{
                backgroundColor: 'white',
                borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
                padding: theme.spacing(2),
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 4,
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{ color: theme.palette.primary[60].toHexString() }}
                >
                  Delivers
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ color: theme.palette.primary[60].toHexString() }}
                >
                  Cut off times
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                  {deliveryDaysFromTo(supplier?.deliveryDays)?.days.map(
                    (day) => (
                      <span
                        key={day.day}
                        style={{
                          color: day.deliver
                            ? theme.palette.secondary[100].toHexString()
                            : theme.palette.primary[20].toHexString(),
                          fontWeight: 'inherit',
                          marginRight: 4,
                        }}
                      >
                        {day.day[0].toUpperCase()}
                      </span>
                    ),
                  )}
                </Typography>
                <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                  {deliveryDaysFromTo(supplier?.deliveryDays)?.cutoffs}
                </Typography>
              </div>
            </div>
          )}
          <MenuList
            menu={{
              name: 'Kitchen Notes',
            }}
            options={kitchenNotes}
            invert
          />
          <MenuList
            menu={{
              name: 'Supplier Contact Details',
            }}
            options={supplierContactDetails}
            invert
          />
          <MenuList
            menu={{
              name: 'Supplier Information',
            }}
            options={supplierOptions}
          />
          <div style={{ paddingBottom: theme.spacing(10) }} />
        </>
      )}
    </div>
  )
}
