import { useMutation, useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { isNil } from 'ramda'
import { useState } from 'react'
import * as Yup from 'yup'

import { MeasurementSystem, Mutation, Unit, UnitType } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Typography } from 'components/newUi'
import { getUnitsQuery } from 'components/newUi/SelectUnit/graphql'
import { MissingConversionModal } from 'screens/Create/Ingredients/components/SelectUnit/MissingConversionModal'
import { updateIngredientMutation } from 'screens/Ingredient/graphql'
import { StockTakeEntry } from 'store/stocktake'
import { useTheme } from 'styles/newUi'
import { cleanErrorMessage } from 'utils'

import { errorToast, successToast } from '../../../../../components/toasts'
import { useStyles } from '../../../../Create/Ingredients/styles'

const validationSchema = Yup.object({
  selectedUnit: Yup.object({
    id: Yup.number(),
    name: Yup.string(),
    type: Yup.string(),
  }),
  type: Yup.object({
    id: Yup.number().required(),
    name: Yup.string().required(),
    type: Yup.string().required(),
  }).required('Required'),
  unit: Yup.number().required('Required'),
  value: Yup.number().required('Required'),
})

export const SelectUnit = ({
  stockItem,
  isRecipe,
  handleUpdateUnit,
}: {
  stockItem: StockTakeEntry
  isRecipe?: boolean
  handleUpdateUnit: (unit: Unit, stockItem: StockTakeEntry) => void
}) => {
  const ingredient = isRecipe ? undefined : stockItem?.ingredient
  const recipe = isRecipe ? undefined : stockItem?.recipe

  const { theme } = useTheme()
  const { selectedKitchen } = useKitchen()
  const classes = useStyles()
  const [showModal, setShowModal] = useState(false)
  const customUnit = (() => {
    if (ingredient?.product?.unit?.type === UnitType.Custom) {
      return ingredient?.product?.unit?.name
    }
    if (isRecipe) return 'portion'
    return 'unit'
  })()

  const recipeUnitType = recipe?.unit?.type
  const lockWeightAndVolume = isRecipe && recipeUnitType === UnitType.Custom
  const lockCustom = isRecipe && recipeUnitType !== UnitType.Custom

  const { data } = useQuery<{
    units: Unit[]
  }>(getUnitsQuery, {
    fetchPolicy: 'cache-and-network',
  })

  const [updateIngredient] = useMutation<{
    updateOneIngredient: Mutation['updateOneIngredient']
  }>(updateIngredientMutation)

  const weightMetric = data?.units
    ?.filter(
      (unit) =>
        unit.type === UnitType.Weight &&
        unit.measurementSystem === MeasurementSystem.Metric,
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1))
  const weightImperial = data?.units
    ?.filter(
      (unit) =>
        unit.type === UnitType.Weight &&
        unit.measurementSystem === MeasurementSystem.Imperial,
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  const volumeMetric = data?.units
    ?.filter(
      (unit) =>
        unit.type === UnitType.Volume &&
        unit.measurementSystem === MeasurementSystem.Metric,
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1))
  const volumeImperial = data?.units
    ?.filter(
      (unit) =>
        unit.type === UnitType.Volume &&
        unit.measurementSystem === MeasurementSystem.Imperial,
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  const custom = data?.units
    ?.filter((unit) => unit.name === customUnit)
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  const currentUnit = stockItem.unit
  // TODO it's not current value
  const currentval = data?.units.find((x) => x.id === currentUnit.id)

  const handleSelectUnit = (unit: Unit) => {
    const conversionUnit = ingredient?.conversionUnit
    const conversionValue = ingredient?.conversionUnitValue
    const conversionUnitType = ingredient?.conversionUnitType

    const unitMap: Record<string, string> = {
      unit: 'custom',
      volume: 'standard',
      weight: 'standard',
    }

    const shouldConvert =
      unitMap[unit.type] !== unitMap[currentUnit.type] && !isRecipe

    if (shouldConvert) {
      formik.setFieldValue('selectedUnit', unit)

      formik.setFieldValue(
        'type',
        conversionUnitType ?? weightMetric?.find((unit) => unit.name === 'g'),
      )

      formik.setFieldValue('unit', conversionUnit ?? 1)
      formik.setFieldValue('value', conversionValue)
      setShowModal(true)
    } else {
      handleUpdateUnit(unit, stockItem)
    }
  }

  const formik = useFormik({
    initialValues: {
      selectedUnit: ingredient?.conversionUnitType,
      type: ingredient?.conversionUnitType,
      unit: ingredient?.conversionUnit,
      value: ingredient?.conversionUnitValue,
    },
    onSubmit: async (values) => {
      try {
        const { data } = await updateIngredient({
          variables: {
            data: {
              conversionUnit: values.unit,
              conversionUnitTypeId: values.type?.id as number,
              conversionUnitValue: values.value,
              id: ingredient?.id as number,
            },
            kitchenId: selectedKitchen!.id,
          },
        })

        if (data) {
          const {
            product,
            conversionUnit,
            conversionUnitType,
            conversionUnitValue,
          } = data.updateOneIngredient

          successToast(`Updated ${product?.name}`)

          const newStockItem: StockTakeEntry = ingredient
            ? {
                ...stockItem,
                ingredient: {
                  ...ingredient,
                  conversionUnit,
                  conversionUnitType,
                  conversionUnitValue,
                },
              }
            : stockItem

          handleUpdateUnit(values?.selectedUnit as Unit, newStockItem)
        }

        setShowModal(false)
      } catch (error) {
        errorToast(cleanErrorMessage((error as Error).message))
      }
    },
    validationSchema,
  })

  const lhs =
    ingredient?.product?.unit?.type === UnitType.Custom
      ? ingredient?.product?.unit?.name
      : 'unit'
  const rhs = formik.values.type

  return (
    <div>
      {!lockWeightAndVolume && (
        <>
          <div className={classes.unitGroupHeader}>
            <Typography
              variant="button"
              style={{ color: theme.palette.primary[60].toHexString() }}
            >
              Weight
            </Typography>
          </div>

          <div data-testid="unit-group" className={classes.unitGroup}>
            {weightMetric?.map((unit) => {
              return (
                <UnitButton
                  key={unit.id}
                  text={unit.name}
                  isSelected={unit.id === currentval?.id}
                  handleOnClick={() => {
                    handleSelectUnit(unit)
                  }}
                />
              )
            })}
          </div>
          <div className={classes.unitGroup}>
            {weightImperial?.map((unit) => {
              return (
                <UnitButton
                  key={unit.id}
                  text={unit.name}
                  isSelected={unit.id === currentval?.id}
                  handleOnClick={() => {
                    handleSelectUnit(unit)
                  }}
                />
              )
            })}
          </div>
        </>
      )}

      {!lockWeightAndVolume && (
        <>
          <div className={classes.unitGroupHeader}>
            <Typography
              variant="button"
              style={{ color: theme.palette.primary[60].toHexString() }}
            >
              Volume
            </Typography>
          </div>
          <div className={classes.unitGroup}>
            {volumeMetric?.map((unit) => {
              return (
                <UnitButton
                  key={unit.id}
                  text={unit.name}
                  isSelected={unit.id === currentval?.id}
                  handleOnClick={() => {
                    handleSelectUnit(unit)
                  }}
                />
              )
            })}
          </div>
          <div className={classes.unitGroup}>
            {volumeImperial?.map((unit) => {
              return (
                <UnitButton
                  key={unit.id}
                  text={unit.name}
                  isSelected={unit.id === currentval?.id}
                  handleOnClick={() => {
                    handleSelectUnit(unit)
                  }}
                />
              )
            })}
          </div>
        </>
      )}

      {((isRecipe && !lockCustom) || !isNil(ingredient)) && (
        <>
          <div className={classes.unitGroupHeader}>
            <Typography
              variant="button"
              style={{ color: theme.palette.primary[60].toHexString() }}
            >
              Custom
            </Typography>
          </div>
          <div className={classes.unitGroup}>
            {custom?.map((unit) => {
              return (
                <UnitButton
                  key={unit.id}
                  text={unit.name}
                  isSelected={unit.id === currentval?.id}
                  handleOnClick={() => {
                    handleSelectUnit(unit)
                  }}
                />
              )
            })}
          </div>
        </>
      )}

      <MissingConversionModal
        formik={formik}
        showModal={showModal}
        setShowModal={setShowModal}
        lhs={lhs}
        rhs={rhs}
      />
    </div>
  )
}

const UnitButton = ({
  text,
  handleOnClick,
  isSelected,
}: {
  text: string
  handleOnClick: () => void
  isSelected: boolean
}) => {
  const classes = useStyles()

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={
          !isSelected ? classes.unitButtonRow : classes.unitButtonRowSelected
        }
        onClick={handleOnClick}
      >
        <Typography
          variant="button"
          style={{ color: isSelected ? 'white' : '#48B7E3' }}
          testId="unit-select"
        >
          {text}
        </Typography>
      </div>
    </div>
  )
}
