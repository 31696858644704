import { Pill, Typography } from '@getjelly/jelly-ui'
import { IconCheck } from '@tabler/icons-react'
import { MouseEventHandler } from 'react'

import { Dish } from '../../../../api'

type Props = {
  dish: Dish
  active: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}

export function DishRow({ dish, active, onClick }: Props) {
  function currencyConvert(value: number | undefined | null) {
    if (value === undefined || value === null) {
      return '£ --'
    }

    return value.toLocaleString(undefined, {
      currency: 'GBP',
      style: 'currency',
    })
  }

  return (
    <div className="p-4 cursor-pointer" onClick={onClick}>
      <div className="flex items-center justify-between">
        <div className="space-y-2">
          <div>
            <Typography style="subtitle1" className="text-primary-900">
              {dish.name}
            </Typography>

            <Typography style="subtitle2" className="text-primary-600">
              {dish.ingredientCount} ingredients; Price{' '}
              {currencyConvert(dish.price)}
            </Typography>
          </div>

          {dish.isMatched && (
            <Pill variant="secondary" label="Already linked" />
          )}
        </div>

        {active && (
          <div className="flex items-center shrink-0">
            <IconCheck className="text-primary-900" />
          </div>
        )}
      </div>
    </div>
  )
}
