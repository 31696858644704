import { Button as B } from '@mui/material'

import { CircularProgress } from 'components'

export const Button = (props: any) => {
  return (
    <B
      disableElevation
      disableRipple
      {...{
        ...props,
        children: props.loading ? (
          <CircularProgress size={27} color="inherit" />
        ) : (
          props.children
        ),
        disabled: props.loading || props.disabled,
      }}
    />
  )
}
