import { gql } from '@apollo/client'

export const getProductQuery = gql`
  query product($cursor: ID!) {
    productNode(cursor: $cursor) {
      id
      _cursor
      name
      ingredients(skip: 0, take: 100) {
        _cursor
        id
        priceChangesList(skip: 0, take: 100) {
          priceChanges {
            date
            changes {
              _cursor
              id
              ingredientName
              percentageChange
            }
          }
        }
      }
    }
  }
`

export const getPriceChanges = gql`
  query priceChanges($cursor: ID!) {
    ingredientNode(cursor: $cursor) {
      _cursor
      id
      price
      conversionUnit
      conversionUnitValue
      product {
        id
        _cursor
        status
        name
        supplier {
          _cursor
          id
          name
        }
        unit {
          _cursor
          id
          name
        }
        unitValue
      }
    }
  }
`

export const getPurchaseOrdersWithProduct = gql`
  query getPurchaseOrdersWithProduct(
    $where: PurchaseOrderWhereInput!
    $ingredientCursor: ID!
    $ingredientId: Int!
    $kitchenId: Int!
  ) {
    ingredientNode(cursor: $ingredientCursor) {
      _cursor
      id
      product {
        _cursor
        id
        status
        supplier {
          _cursor
          id
        }
      }
    }
    purchaseOrderList(skip: 0, take: 100, where: $where) {
      nodes {
        _cursor
        createdAt
        id
        notes
        entryList(
          where: {
            product: {
              ingredients: {
                some: {
                  AND: [
                    { id: { equals: $ingredientId } }
                    { kitchenId: { equals: $kitchenId } }
                  ]
                }
              }
            }
          }
        ) {
          nodes {
            _cursor
            id
            quantity
            product {
              _cursor
              id
              name
              ingredientList {
                nodes {
                  _cursor
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`
