import { captureException } from '@sentry/react'

import {
  client,
  MutationCreateMultiplePendingInvoiceArgs,
  PendingInvoice,
} from 'api'

import { createMultiplePendingInvoice } from './graphql'

import { Uploader, Upload } from '../../utils/uploader'

export type { Upload } from '../../utils/uploader'

export class InvoiceUploader extends Uploader {
  getResizeConfig() {
    return {
      maxHeight: 2048,
      maxWidth: 2048,
      quality: 0.8,
    }
  }

  getFilename(upload: Upload): string {
    return `PendingInvoice/${upload.file!.name.split('.')[0]}`
  }

  async submitUploads(
    transfers: { upload: Upload; signedURL: string; unsignedURL: string }[],
  ) {
    const kitchenId = this.kitchenId!

    const imageUrls = transfers
      .filter((t) => t.upload.status !== 'failed')
      .map((t) => t.unsignedURL)

    try {
      await client.mutate<
        {
          createMultiplePendingInvoice: PendingInvoice[]
        },
        MutationCreateMultiplePendingInvoiceArgs
      >({
        mutation: createMultiplePendingInvoice,
        variables: { imageUrls, kitchenId },
      })

      return transfers.map(({ upload }) => {
        if (upload.status === 'processing') {
          return { ...upload, file: undefined, status: 'complete' } as Upload
        }

        return upload
      })
    } catch (e) {
      console.log('error', { e })
      const uploads = transfers.map((t) => {
        t.upload.status = 'failed'
        return t.upload
      })
      captureException(e)

      return uploads
    }
  }
}
