import { style } from 'typestyle'

import { makeStyles } from 'styles/newUi'

export const useStyles = (props: { noBorder: boolean }) =>
  makeStyles((theme) => {
    const error = style({
      background: 'inherit',
      color: theme.palette.error[100].toHexString(),
    })

    const disabled = style({
      color: 'inherit',
      width: 'inherit',
    })

    return {
      disabled,
      endAdornment: {
        alignItems: 'center',
        color: theme.palette.primary[40].toHexString(),
        display: 'flex',
        justifyContent: 'center',
        padding: 3,
        position: 'absolute',
        right: 10,
        top: '50%',
        transform: 'translateY(-50%)',
      },
      error,
      hideNumberControls: {
        $nest: {
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
          },
          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
          },
        },
        '-moz-appearance': 'none',
      },
      input: {
        $nest: {
          [`.${disabled} &`]: {
            backgroundColor: theme.palette.grey[100].toHexString(),
            color: theme.palette.primary[40].toHexString(),
            cursor: 'not-allowed',
          },
          '&::placeholder': {
            color: theme.palette.primary[40].toHexString(),
          },
          '&:focus': {
            border: props.noBorder
              ? 'none !important'
              : `2px solid ${theme.palette.secondary[100].toHexString()} !important`,
          },
          [`.${error} &`]: {
            border: props.noBorder
              ? 'none !important'
              : `2px solid ${theme.palette.error[100].toHexString()} !important`,
          },
        },
        '-webkit-appearance': 'none',
        MozBoxShadow: 'none',
        WebkitBoxShadow: 'none',
        backgroundColor: theme.palette.common.light.toHexString(),
        backgroundImage: 'none',
        border: props.noBorder
          ? 'none !important'
          : `2px solid ${theme.palette.primary[20].toHexString()} !important`,
        borderRadius: '10px',
        boxShadow: 'none',
        color: theme.palette.primary[100].toHexString(),
        fontFamily: 'inherit',
        fontSize: 16,
        height: 45,
        outline: '0 !important',
        padding: 10,
        width: '100%',
      },
      inputWrap: {
        position: 'relative',
      },
      label: {
        $nest: {
          [`.${disabled} &`]: {
            color: theme.palette.primary[40].toHexString(),
          },
        },
        paddingBottom: `${theme.spacing(1)}px !important`,
        paddingLeft: `${theme.spacing(1)}px !important`,
        paddingRight: `${theme.spacing(1)}px !important`,
      },
      startAdornment: {
        alignItems: 'center',
        color: theme.palette.primary[40].toHexString(),
        display: 'flex',
        justifyContent: 'center',
        left: 10,
        padding: 3,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      },
      wrap: {
        padding: '10px 0',
      },
    }
  })
