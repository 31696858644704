/* @TODO: Rewrite this with new queries, components and styles */
import { useQuery } from '@apollo/client'
import debounce from 'lodash.debounce'
import { isNil, isEmpty } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Query, Status, QueryMode, QuerySuppliersArgs, SortOrder } from 'api'
import { useReturnToPageContext } from 'app/contexts/ReturnToPage'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import {
  BottomModal,
  NewLayout,
  SearchBar,
  SectionList,
} from 'components/newUi'
import { Typography } from 'components/newUi/Typography'
import { useWindowSize } from 'hooks'
import { useTheme } from 'styles/newUi'

import { getSuppliersQuery } from './graphql'
import { Item } from './Item'
import { useStyles } from './styles'

export const SelectSuppliers = () => {
  const inputEl = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const classes = useStyles()
  const { theme } = useTheme()
  const { gte: isDesktop } = useWindowSize('md')
  const { selectedKitchen } = useKitchen()
  const [search, setSearch] = useState('')
  const [data, setData] = useState<
    { name: string; data: Query['suppliers'] }[]
  >([
    { data: [], name: 'My suppliers' },
    { data: [], name: 'Suppliers on Jelly' },
  ])

  const navigationContext = useReturnToPageContext()

  const { data: supplierData, loading } = useQuery<
    {
      kitchenSuppliers: Query['suppliers']
      jellySuppliers: Query['suppliers']
    },
    QuerySuppliersArgs & {
      jellySkip: QuerySuppliersArgs['skip']
      jellyTake: QuerySuppliersArgs['take']
      jellyWhere: QuerySuppliersArgs['where']
    }
  >(getSuppliersQuery, {
    skip: !selectedKitchen,
    variables: {
      jellySkip: 0,
      jellyTake: !isEmpty(search) ? 250 : 0,
      jellyWhere: {
        kitchens: {
          every: {
            kitchen: {
              id: {
                not: {
                  equals: selectedKitchen?.id ?? null,
                },
              },
            },
          },
        },
        name: {
          contains: isEmpty(search) ? undefined : search,
          mode: QueryMode.Insensitive,
        },
        products: {
          some: {
            ingredients: {
              every: {
                kitchenId: {
                  not: {
                    equals: selectedKitchen?.id ?? null,
                  },
                },
              },
            },
          },
        },
        status: {
          equals: Status.Active,
        },
      },
      orderBy: [{ name: SortOrder.Asc }],
      take: !isEmpty(search) ? 250 : 500,
      where: {
        AND: [
          {
            OR: [
              {
                kitchens: {
                  some: {
                    kitchen: {
                      id: { equals: selectedKitchen?.id ?? null },
                    },
                  },
                },
              },
              {
                products: {
                  some: {
                    ingredients: {
                      some: {
                        kitchenId: { equals: selectedKitchen?.id ?? null },
                      },
                    },
                  },
                },
              },
            ],
          },
          { name: { contains: search, mode: QueryMode.Insensitive } },
        ],
      },
    },
  })

  useEffect(() => {
    setData([
      { data: supplierData?.kitchenSuppliers ?? [], name: 'My suppliers' },
      {
        data: isEmpty(search) ? [] : supplierData?.jellySuppliers ?? [],
        name: 'Suppliers on Jelly',
      },
    ])
  }, [supplierData, search])

  const onSearch = debounce((search: string) => {
    setSearch(search)
  }, 500)

  return (
    <>
      <NewLayout
        title="Select Supplier"
        onBack={() => {
          navigationContext.returnToPage()
        }}
      />
      <div
        style={{
          paddingBottom: theme.spacing(2),
          paddingTop: theme.spacing(2),
        }}
      >
        <SearchBar
          searchPlaceholder="Search supplier..."
          onSearch={onSearch}
          ref={inputEl}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.list}>
        {loading ? (
          <Loader />
        ) : isNil(supplierData) ? (
          <div className={classes.info}>
            <Typography variant="body2">Something went wrong</Typography>
          </div>
        ) : (
          <SectionList
            data={data}
            rootClass={classes.listContainer}
            itemClass={isDesktop ? classes.listItemDesktop : classes.listItem}
            sectionClass={classes.sectionClass}
            item={({ item }: { item: Query['suppliers'][number] }) => (
              <Item item={item} />
            )}
            empty={() => (
              <div className={classes.info}>
                <Typography variant="body2">
                  Could not find any items
                </Typography>
              </div>
            )}
            append={
              <BottomModal
                buttonVariant="white"
                text={"Didn't find what you were looking for?"}
                buttonText={'FIND SUPPLIER'}
                onClick={() => navigate('/suppliers/selectall')}
              />
            }
          />
        )}
      </div>
    </>
  )
}
