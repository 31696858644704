import escapeRegExp from 'lodash.escaperegexp'

import { useTheme } from 'styles/newUi'

type Props = { text: string; search: string }

export const HighlightText = ({ text, search }: Props) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${escapeRegExp(search)})`, 'gi'))
  const { theme } = useTheme()
  return (
    <span style={{ fontWeight: 'inherit' }}>
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === search.toLowerCase()
              ? {
                  color: theme.palette.secondary[100].toHexString(),
                  fontWeight: 'bold',
                }
              : { fontWeight: 'inherit' }
          }
        >
          {part}
        </span>
      ))}
    </span>
  )
}
