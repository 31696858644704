import { CSSProperties, FC, ReactNode } from 'react'

import { Icon, iconDictionary } from 'components/newUi'
import { useTheme } from 'styles/newUi'

import { useStyles } from './styles'

interface Props {
  onClick?: () => void
  children: ReactNode
  type?: 'error' | 'info' | 'success' | 'warning'
  hide?: boolean
  style?: CSSProperties
}

export const Alert = ({ children, type, hide, onClick, style }: Props) => {
  const classes = useStyles({ type })
  if (hide) return null

  return (
    //  eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={onClick} className={classes._root} style={style}>
      {children}
    </div>
  )
}

export interface IIconProps {
  children: ReactNode
  onClick?: () => void
  type?: 'error' | 'info' | 'success' | 'warning'
  hide?: boolean
  IconView?: FC<{ children: ReactNode }>
  iconStyles?: CSSProperties
  iconName: keyof typeof iconDictionary
  style?: CSSProperties
  after?: ReactNode
}

export const AlertWithIcon = (props: IIconProps) => {
  const {
    iconName,
    onClick,
    iconStyles,
    after,
    IconView = ({ children }) => <>{children}</>,
    ...rest
  } = props
  const classes = useStyles({ type: props.type })
  const { theme } = useTheme()

  return (
    <Alert {...rest}>
      <div
        style={{
          alignItems: 'center',
          color: 'black',
          display: 'flex',
          width: '100%',
        }}
      >
        <IconView>
          <Icon
            iconName={iconName}
            className={classes.icon}
            style={{ marginRight: '0.75rem', ...iconStyles }}
            onClick={onClick}
          />
        </IconView>

        <div
          onClick={onClick}
          style={{
            alignItems: 'center',
            color: theme.palette.primary[100].toHexString(),
            display: 'flex',
            flex: 1,
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '20px',
          }}
        >
          {props.children}
        </div>

        {after}
      </div>
    </Alert>
  )
}
