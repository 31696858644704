import { gql } from '@apollo/client'

export const invoiceOverviewQuery = gql`
  query invoiceOverview($kitchenId: Int!) {
    invoiceOverview(kitchenId: $kitchenId) {
      all
      approved
      needsAttention
      processing
    }
  }
`
