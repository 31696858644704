import OutsideClickHandler from 'react-outside-click-handler'

import { NewButton, Typography } from 'components'
import { useWindowSize } from 'hooks'
import * as React from 'react'

export interface IProps {
  title: string
  handleSubmit: () => void
  handleCancel: () => void
  show: boolean
  children?: React.ReactNode
}

export const Modal: React.FunctionComponent<IProps>  = ({
  title,
  show,
  handleCancel,
  children,
}) => {
  const { gte: isDesktop } = useWindowSize('md')
  if (!show) return null

  return (
    <div
      style={{
        background: 'rgba(0,0,0,0.5)',
        bottom: 0,
        height: '100vh',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 9999,
      }}
    >
      <OutsideClickHandler onOutsideClick={handleCancel}>
        <div
          style={{
            background: 'rgba(255, 255, 255, 1)',
            borderRadius: isDesktop ? 22 : undefined,
            bottom: isDesktop ? 0 : undefined,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: isDesktop ? '660px' : '100vh',
            left: 0,
            margin: 'auto',
            overflow: 'hidden',
            paddingLeft: isDesktop ? 16 : undefined,
            paddingRight: isDesktop ? 16 : undefined,
            position: 'absolute',
            right: isDesktop ? 0 : undefined,
            top: 0,
            width: isDesktop ? '750px' : '100vw',
            zIndex: 9999,
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: 90,
              justifyContent: 'flex-start',
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <NewButton
              text="X"
              onClick={handleCancel}
              style={{
                alignItems: 'center',
                borderRadius: 22,
                color: 'white',
                height: 44,
                marginRight: 10,
                minWidth: 10,
                padding: 0,
                width: 44,
              }}
            />
            <div
              style={{
                width: 'calc(100% - 60px)',
              }}
            >
              <Typography variant="body1">{title}</Typography>
            </div>
          </div>
          <div style={{ flex: 1 }}>{children}</div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}
