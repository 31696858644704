import { style } from 'typestyle'

import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme) => {
    const noFill = style({})

    return {
      button: {
        $nest: {
          '& span': {
            lineHeight: '25px',
          },
          '&:active': {
            transform: 'scale(0.99)',
          },
          '&:focus': {
            outline: 'none',
          },
        },
        '-moz-appearance': 'none',
        '-webkit-appearance': 'none',
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
        alignItems: 'center',
        border: 'none',
        borderRadius: 50,
        color: theme.palette.common.light.toHexString(),
        cursor: 'pointer',
        display: 'flex',
        fontFamily: 'Rubik',
        height: 45,
        justifyContent: 'center',
        // minWidth: 200,
        padding: '10px 40px 10px 40px',
        position: 'relative',
        textAlign: 'center',
        textDecoration: '',
        transition: 'background 250ms ease-in-out, transform 150ms ease',
      },
      disabled: {
        $nest: {
          '&:active': {
            transform: 'none',
          },
          '&:hover': {
            background: theme.palette.primary[20].toHexString(),
            color: 'white !important',
          },
        },
        background: `${theme.palette.primary[20].toHexString()} !important`,
        borderColor: `transparent !important`,
        cursor: 'not-allowed',
      },
      error: {
        $nest: {
          [`&.${noFill}`]: {
            $nest: {
              '&:hover': {
                background: theme.palette.error[100].toHexString(),
                color: theme.palette.common.light.toHexString(),
              },
            },
            background: 'transparent',
            color: theme.palette.error[100].toHexString(),
          },
          '&:focus': {
            outline: 'none',
          },
          '&:hover': {
            background: 'transparent',
            color: theme.palette.error[100].toHexString(),
          },
        },

        background: theme.palette.error[100].toHexString(),
        border: `2px solid ${theme.palette.error[100].toHexString()}`,
      },
      grey: {
        $nest: {
          [`&.${noFill}`]: {
            $nest: {
              '&:hover': {
                background: theme.palette.grey[100].toHexString(),
                color: theme.palette.primary[100].toHexString(),
              },
            },
            background: 'transparent',
            color: theme.palette.grey[100].toHexString(),
          },
          '&:hover': {
            background: 'transparent',
            color: theme.palette.grey[100].toHexString(),
          },
        },
        background: theme.palette.grey[100].toHexString(),
        color: theme.palette.primary[100].toHexString(),
      },
      loader: {
        position: 'absolute',
        right: 10,
        top: 7,
      },
      loaderUnset: {
        position: 'absolute',
        top: 7,
      },
      noFill,
      orange: {
        $nest: {
          [`&.${noFill}`]: {
            $nest: {
              '&:hover': {
                background: theme.palette.error[100].toHexString(),
                color: theme.palette.common.light.toHexString(),
              },
            },
            background: 'transparent',
            color: theme.palette.error[100].toHexString(),
          },
          '&:hover': {
            background: 'transparent',
            color: theme.palette.error[100].toHexString(),
          },
        },
        background: theme.palette.error[100].toHexString(),
        border: `2px solid ${theme.palette.error[100].toHexString()}`,
      },
      primary: {
        $nest: {
          [`&.${noFill}`]: {
            $nest: {
              '&:hover': {
                background: theme.palette.primary[100].toHexString(),
                color: theme.palette.primary[100].toHexString(),
                opacity: 0.8,
              },
            },
            background: 'transparent',
            color: theme.palette.primary[100].toHexString(),
          },
          '&:focus': {
            outline: 'none',
          },
          '&:hover': {
            background: 'transparent',
            color: theme.palette.primary[100].toHexString(),
            opacity: 0.8,
          },
        },

        background: theme.palette.primary[100].toHexString(),
        border: `2px solid ${theme.palette.primary[100].toHexString()}`,
      },
      secondary: {
        $nest: {
          [`&.${noFill}`]: {
            $nest: {
              '&:hover': {
                background: theme.palette.secondary[100].toHexString(),
                color: theme.palette.common.light.toHexString(),
                opacity: 0.8,
              },
            },
            background: 'transparent',
            color: theme.palette.secondary[100].toHexString(),
          },
          '&:hover': {
            background: 'transparent',
            color: theme.palette.secondary[100].toHexString(),
            opacity: 0.8,
          },
        },
        background: theme.palette.secondary[100].toHexString(),
        border: `2px solid ${theme.palette.secondary[100].toHexString()}`,
      },
      tertiary: {
        $nest: {
          [`&.${noFill}`]: {
            $nest: {
              '&:hover': {
                background: theme.palette.tertiary[100].toHexString(),
                color: theme.palette.common.light.toHexString(),
                opacity: 0.8,
              },
            },
            background: 'transparent',
            color: theme.palette.tertiary[100].toHexString(),
          },
          '&:hover': {
            background: 'transparent',
            color: theme.palette.tertiary[100].toHexString(),
            opacity: 0.8,
          },
        },
        background: theme.palette.tertiary[100].toHexString(),
        border: `2px solid ${theme.palette.tertiary[100].toHexString()}`,
      },
      white: {
        $nest: {
          [`&.${noFill}`]: {
            $nest: {
              '&:hover': {
                background: theme.palette.common.light.toHexString(),
                color: theme.palette.primary[100].toHexString(),
                opacity: 0.8,
              },
            },
            background: 'transparent',
            color: theme.palette.primary[100].toHexString(),
          },
          '&:hover': {
            background: 'transparent',
            color: theme.palette.primary[100].toHexString(),
            opacity: 0.8,
          },
        },
        background: theme.palette.common.light.toHexString(),
        color: theme.palette.primary[100].toHexString(),
      },
    }
  })
