import { isEmpty, isNil } from 'ramda'
import { useNavigate } from 'react-router-dom'

import { Dish, Recipe } from 'api'
import { Icon, Typography } from 'components/newUi'
import { buildViewRoute } from 'routes/Paths'
import { Image } from 'screens/Invoices/Add'
import { useTheme } from 'styles/newUi'

import { useStyles } from './styles'

export const Item = (props: any) => {
  const navigate = useNavigate()
  const { theme } = useTheme()
  const classes = useStyles()

  const { index, style, data } = props
  const item: Recipe = data[index]
  const isDish = !isNil(item.dishList.nodes) && !isEmpty(item.dishList.nodes)
  const rowData: Recipe | Dish = isDish ? item.dishList.nodes[0] : item

  const handleNavigate = () => {
    navigate(buildViewRoute(rowData.id, isDish))
  }

  return (
    // eslint-disable-next-line
    <div
      data-testid={`item_${rowData.name}`}
      onClick={handleNavigate}
      style={{
        ...style,
        backgroundColor: 'white',
        borderBottomColor: theme.palette.primary[10].toHexString(),
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: '0fr 1fr 0fr 0fr',
      }}
    >
      <div className="flex w-16 h-16">
        <Image
          localImage={false}
          url={rowData.imageUrl ?? undefined}
          frame={false}
          className="w-16 h-16"
        />
      </div>

      <div
        className={classes.itemInnerMobile}
        style={{
          alignItems: 'center',
          display: 'flex',
          overflow: 'hidden',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            display: '-webkit-box',
            fontWeight: 500,
            overflow: 'hidden',
            textTransform: 'capitalize',
          }}
        >
          {rowData.name}
        </Typography>
      </div>
      <div
        className={classes.fontGrey}
        style={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          fontFamily: 'Rubik, sans-serif',
          fontWeight: 500,
          justifyContent: 'flex-end',
          textTransform: 'capitalize',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.primary[40].toHexString(),
              fontWeight: 500,
            }}
          >
            {rowData.isDraft === false ? '' : 'DRAFT'}
          </Typography>
        </div>
      </div>
      <div
        className={classes.itemInnerMobile}
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: 50,
        }}
      >
        <Icon iconName="chevronRight" />
      </div>
    </div>
  )
}
