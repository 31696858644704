import { gql } from '@apollo/client'

export const requestPasswordResetCodeMutation = gql`
  mutation RequestPasswordResetCode(
    $phoneNumber: String!
    $countryCode: String
  ) {
    requestPasswordResetCode(
      phoneNumber: $phoneNumber
      countryCode: $countryCode
    ) {
      message
    }
  }
`
