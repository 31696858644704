import { api } from 'api/rest'

export const triggerInvoiceDownload = async (
  startDate: Date,
  endDate: Date,
  emailAddress: string,
  kitchenId: number,
  successCallback: () => void,
  errorCallback: (errorMessage: string) => void,
) => {
  try {
    await api.post(
      '/rest/v2/invoice-download',
      {
        emailAddress,
        endDate,
        kitchenId,
        startDate,
      },
      {},
    )
    successCallback()
  } catch (error: any) {
    errorCallback(error?.response?.data?.message || error?.message || '')
  }
}

export const csvDownload = async (
  startDate: Date,
  endDate: Date,
  kitchenId: number,
  successCallback: (data: any) => void,
  errorCallback: (errorMessage: string) => void,
) => {
  try {
    const response = await api.post(
      '/rest/invoices/export-csv',
      {
        endDate,
        kitchenId,
        startDate,
      },
      {},
    )
    successCallback(response.data)
  } catch (error: any) {
    errorCallback(error?.response?.data?.message || error?.message || '')
  }
}
