import { useNavigate } from 'react-router-dom'

import { Button, Icon, Typography } from 'components/newUi'
import { routes } from 'routes/Paths'
import { StockTakeStoreStockTake } from 'store/stocktake'
import { useTheme } from 'styles/newUi'

import { useKitchen } from '../../../../app/contexts/SelectedKitchen'

export const BottomContent = ({
  stocktake,
  loading,
  disabled,
  id,
  submit,
}: {
  stocktake: StockTakeStoreStockTake
  loading: boolean
  disabled?: boolean
  id: string
  submit: () => void
}) => {
  const { selectedKitchen } = useKitchen()
  const { theme } = useTheme()
  const navigate = useNavigate()

  return (
    <>
      <div className="flex justify-between items-center p-4 bg-secondary-300">
        <div>
          <Typography
            variant="subtitle1"
            style={{ fontSize: 16, fontWeight: 500 }}
          >
            Total
          </Typography>

          <Typography
            variant="subtitle2"
            style={{
              color: theme.palette.primary[80].toHexString(),
              fontSize: 16,
              fontWeight: 400,
              marginTop: theme.spacing(0.5),
            }}
          >
            {stocktake?.entries.length} Items
          </Typography>
        </div>

        <Typography variant="h6" style={{ fontSize: 20, fontWeight: 500 }}>
          £{stocktake?.total?.toFixed(2)}
        </Typography>
      </div>

      {selectedKitchen?.userPermissions?.includes('update-stock') && (
        <div className="bg-secondary-400 flex justify-center p-2">
          <Button
            text="Add Items"
            noFill
            style={{ backgroundColor: 'white' }}
            onClick={() => navigate(`${routes.Stock}/${id}${routes.Add}`)}
            rightAdornment={
              <Icon iconName="add" style={{ marginLeft: 4, marginRight: -4 }} />
            }
          />

          <Button
            disabled={disabled}
            loading={loading}
            text="Save Stocktake"
            onClick={() => submit()}
          />
        </div>
      )}
    </>
  )
}
