import { useQuery } from '@apollo/client'
import { isNil } from 'ramda'
import { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

import { Recipe, Dish, Allergen } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import { useNewStateStore } from 'mobx/StateStore/Meta'
import { Allergens } from 'screens/Create/Allergens'
import { Costing } from 'screens/Create/Costing'
import { getAllergensQuery } from 'screens/Create/graphql/dish'
import { getRecipeByIdQuery } from 'screens/Create/graphql/recipes'
import { NewIngredients } from 'screens/Create/Ingredients/index'
import { Method } from 'screens/Create/Method'

import { Details } from './Details'

const pages = ['ingredients', 'method', 'allergens', 'costing']

export const CreateComponent = () => {
  const { id, type } = useParams()
  const state = useNewStateStore()
  const location = useLocation()
  const navigate = useNavigate()
  const store = state.createOrGet(location.pathname)
  const { selectedKitchen } = useKitchen()

  const { data, loading } = useQuery<{
    recipes?: Recipe[]
    dishes?: Dish[]
  }>(getRecipeByIdQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: { equals: +id },
        kitchenId: { equals: selectedKitchen?.id },
      },
      whereDish: {
        id: { equals: +id },
        kitchenId: { equals: selectedKitchen?.id },
      },
    },
  })

  useEffect(() => {
    if (store && data) {
      const recipe = recipeSelector(data, type)
      store.init(recipe?.childRecipes ?? [], recipe?.ingredients ?? [])
    }
  }, [id, data, store])

  const { data: ad } = useQuery<{
    allergens: Allergen[]
  }>(getAllergensQuery, {
    fetchPolicy: 'no-cache',
  })

  const [currentPage, setPageNumber] = useState(-1)

  const setCurrentPage = (page: number) => {
    if (page === -1) {
      navigate(-1)
      return
    }

    navigate(pages[page])
  }

  useEffect(() => {
    if (!location?.pathname) return

    const index = pages.findIndex((page) => location.pathname.includes(page))

    setPageNumber(index)
  }, [location])

  if (!data || loading) return <Loader />

  const recipe: Recipe | undefined = recipeSelector(data, type)

  const isDish = type === 'dish'

  const dish = dishSelector(type, data.dishes)

  if (!recipe) return null

  return (
    <>
      {currentPage === -1 && (
        <Details
          setCurrentPage={setCurrentPage}
          recipe={recipe}
          dish={isDish ? dish : undefined}
        />
      )}
      {currentPage === 0 && (
        <NewIngredients
          id={id}
          store={store}
          recipe={recipe}
          dish={isDish ? dish : undefined}
          isDish={isDish}
        />
      )}
      {currentPage === 1 && (
        <Method recipe={recipe} isDish={isDish} id={Number(id)} />
      )}
      {currentPage === 2 && (
        <Allergens
          id={Number(id)}
          allergens={ad?.allergens ?? []}
          recipe={recipe}
          isDish={isDish}
        />
      )}
      {currentPage === 3 && <Costing id={dish?.id} dish={dish} />}
    </>
  )
}

export const recipeSelector = (
  data:
    | {
        recipes?: Recipe[]
        dishes?: Dish[]
      }
    | undefined,
  route: string,
) => {
  if (isNil(data)) return

  if (route === 'dish') {
    if (isNil(data.dishes) || isNil(data.dishes[0])) {
      return
    }

    return data.dishes[0].recipe
  } else {
    if (isNil(data.recipes) || isNil(data.recipes[0])) {
      return
    }

    return data.recipes[0]
  }
}

export const dishSelector = (
  route: string,
  dishes: Dish[] | undefined,
): Dish | undefined => {
  if (route !== 'dish') return undefined

  if (isNil(dishes)) return undefined

  return dishes[0]
}
