import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'
import Div100vh from 'react-div-100vh'
import { useNavigate } from 'react-router-dom'

import { Container } from 'components'
import { Icon, Typography } from 'components/newUi'
import { useWindowSize } from 'hooks'
import { useTheme } from 'styles/newUi'
import { shouldFixIOS15 } from 'utils'

import { useStyles } from './styles'

export * from './PublicAuthLayout'

export interface IProps {
  onBack?: () => void
  title: string
  bottomContent?: ReactElement
  rightContent?: ReactElement
  header?: boolean
  bottomBackgroundColor?: string
  leftContent?: ReactElement
  scrollable?: boolean
  subtitle?: string
  customTitle?: ReactElement
  children?: ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Div: React.FunctionComponent<any> = ({ children, ...rest }) => {
  const { gte: isDesktop } = useWindowSize('md')

  if (isDesktop)
    return (
      <Container
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        {...rest}
      >
        {children}
      </Container>
    )
  if (shouldFixIOS15()) {
    return (
      <div
        {...rest}
        style={{
          ...rest.style,
          height: 'calc(100vh - env(safe-area-inset-bottom))',
        }}
      >
        {children}
      </div>
    )
  }

  return <Div100vh {...rest}>{children}</Div100vh>
}

export const Layout: React.FunctionComponent<IProps> = ({
  onBack,
  title,
  children,
  bottomContent,
  rightContent,
  header = true,
  bottomBackgroundColor,
  leftContent,
  scrollable = true,
  subtitle,
}) => {
  const classes = useStyles()
  const { theme } = useTheme()
  const navigate = useNavigate()

  return (
    <Div
      className={classes.outer}
      style={{
        backgroundColor: theme.palette.primary[5].toHexString(),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100vw',
      }}
    >
      {header && (
        <div
          style={{
            backgroundColor: theme.palette.common.light.toHexString(),
            display: 'flex',
            height: 64,
          }}
        >
          <div className={classes.left}>
            <div
              style={{
                alignItems: 'center',
                display: 'grid',
                gridTemplateColumns: '0fr 1fr 0fr',
                gridTemplateRows: '1fr',
                height: '100%',
              }}
            >
              {
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
                <div
                  onClick={() => (onBack ? onBack() : navigate(-1))}
                  style={{
                    alignItems: 'center',
                    color: theme.palette.primary[60].toHexString(),
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'relative',
                    width: 50,
                  }}
                >
                  <Icon
                    iconName="arrowBack"
                    className={clsx([classes.backButton, classes.noPrint])}
                  />
                </div>
              }
              <div style={{ position: 'absolute' }}>{leftContent}</div>

              <div
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {subtitle && (
                  <Typography
                    variant="body1"
                    style={{
                      color: theme.palette.primary[40].toHexString(),
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {subtitle}
                  </Typography>
                )}
                <Typography
                  style={{
                    margin: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  variant="h6"
                >
                  {title}
                </Typography>
              </div>
              <div
                style={{
                  height: '100%',
                  position: 'relative',
                }}
              >
                {rightContent}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classes.divider} />
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: scrollable ? 'auto' : 'hidden',
          width: '100%',
        }}
      >
        {children}
      </div>
      {bottomContent && (
        <div
          className={classes.bottomField}
          style={{
            backgroundColor: bottomBackgroundColor,
            width: '100%',
          }}
        >
          {bottomContent}
        </div>
      )}
    </Div>
  )
}

export interface ITitleProps {
  first: string
  second: string
  hiddenBackButton?: boolean
}
export const CustomTitle = ({
  first,
  second,
  hiddenBackButton = false,
}: ITitleProps) => {
  const { theme } = useTheme()
  return (
    <div style={{ marginLeft: !hiddenBackButton ? 55 : 12 }}>
      <div
        style={{
          alignItems: 'center',
          color: theme.palette.primary[60].toHexString(),
          display: 'flex',
          fontFamily: 'Rubik',
          fontSize: '16px',
          fontStyle: 'normalize',
          lineHeight: '19px',
        }}
      >
        {first}
      </div>
      <div
        style={{
          alignItems: 'center',
          color: theme.palette.primary[100].toHexString(),
          display: 'flex',
          fontFamily: 'Rubik',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
        }}
      >
        {second}
      </div>
    </div>
  )
}
