import { Config, readAndCompressImage } from 'browser-image-resizer'

export async function resizeImage(
  file: File,
  resizeConfig: Config,
): Promise<File> {
  console.log(`Resizing upload ${file.name}`)

  if (!file.type.includes('image')) {
    return Promise.resolve(file)
  }

  const blob = await readAndCompressImage(file, resizeConfig)

  return new File([blob], file.name, {
    lastModified: file.lastModified,
    type: file.type,
  })
}
