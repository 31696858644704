import { IconAlertTriangle, IconCheck } from '@tabler/icons-react'
import { toast, ToastOptions } from 'react-toastify'

export function errorToast(msg: string, options?: ToastOptions) {
  return toast.error(
    <div className="flex items-center text-base space-x-2">
      <IconAlertTriangle className="text-error-400 shrink-0" />

      <div>{msg}</div>
    </div>,
    {
      hideProgressBar: true,
      icon: false,
      ...options,
    },
  )
}

export function successToast(msg: string, options?: ToastOptions) {
  return toast.error(
    <div className="flex items-center text-base space-x-2">
      <IconCheck className="text-success-400 shrink-0" />

      <div>{msg}</div>
    </div>,
    {
      hideProgressBar: true,
      icon: false,
      ...options,
    },
  )
}
