import { Typography, Badge } from '@mui/material'
import { useSelector } from 'react-redux'

import { Supplier } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { HighlightText } from 'components/newUi'
import { useTheme } from 'styles/newUi'

import { useStyles } from './styles'

import { selectQuantity } from '../store'

export const Item = (props: any) => {
  const { style, data } = props
  const rowData: Supplier = data
  const { theme } = useTheme()
  const classes = useStyles()
  const { selectedKitchen } = useKitchen()
  const quantity = useSelector(
    selectQuantity(`${selectedKitchen?.id}:${rowData.id}`),
  )
  return (
    // eslint-disable-next-line
    <div
      key={rowData._cursor}
      onClick={() => props.onClick && props.onClick()}
      style={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
        cursor: 'pointer',
        display: 'flex',
        gap: '0 5px',
        gridTemplateColumns: '1fr 0fr 40px',
        height: 65,
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        position: 'relative',
        whiteSpace: 'nowrap',
        width: '100%',
        ...style,
      }}
    >
      <div style={{ overflow: 'hidden', width: '80%' }}>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 500,
            marginBottom: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          <HighlightText
            text={rowData.name.toLowerCase()}
            search={props.search || ''}
          />
        </Typography>
      </div>
      <Badge
        badgeContent={quantity}
        className={classes.badge}
        color={'primary'}
      />
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        {props.rightAdornment}
      </div>
    </div>
  )
}
