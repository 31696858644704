import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { errorToast, successToast } from '../components/toasts'
import { dequeueToast, selectToastQueue } from '../store/toastQueue'

export function useToastQueue() {
  const dispatch = useDispatch()
  const toasts = useSelector(selectToastQueue())

  const processToast = useCallback(() => {
    if (toasts.length === 0) return

    const currentToast = toasts[0]

    if (currentToast) {
      const { message, type } = currentToast

      switch (type) {
        case 'success':
          successToast(message)
          break
        case 'error':
          errorToast(message)
          break
        case 'info':
          toast.info(message)
          break
        case 'warning':
          toast.warning(message)
          break
        default:
          break
      }

      dispatch(dequeueToast())
    }
  }, [toasts, dispatch])

  useEffect(() => {
    // I don't know why, but it seems that everything in our app gets rendered
    // twice. This is to ensure this logic only executes once. Otherwise. we end
    // up sending duplicate toasts.
    const debounceTimeout = setTimeout(() => {
      processToast()
    }, 200)

    return () => clearTimeout(debounceTimeout)
  }, [toasts, processToast])
}
