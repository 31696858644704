import { useMutation } from '@apollo/client'
import { NewPasswordPanel } from '@getjelly/jelly-ui'
import { NewPasswordData } from '@getjelly/jelly-ui/dist/components/organisms/onboarding/NewPasswordPanel'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Mutation, MutationResetPasswordArgs } from 'api'
import { cleanErrorMessage } from 'utils'

import { resetPasswordMutation } from './graphql'

import { successToast } from '../../components/toasts'
import { routes } from '../../routes/Paths'
import { selectAuthFlow, setResetPin } from '../../store/authFlow'

export function NewPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authFlow = useSelector(selectAuthFlow())

  const [errors, setErrors] = useState<
    Partial<Record<keyof NewPasswordData, string>> | undefined
  >(undefined)

  useEffect(() => {
    // This should be super rare. Just send them back to the sign-in page.
    if (!authFlow.phoneNumber || !authFlow.countryCode || !authFlow.resetPin)
      navigate(routes.SignIn)
  }, [authFlow, navigate])

  const [requestCodeReset, { loading }] = useMutation<
    { resetPassword: Mutation['resetPassword'] },
    MutationResetPasswordArgs
  >(resetPasswordMutation)

  async function newPassword(data: NewPasswordData) {
    setErrors(undefined)
    if (loading) return

    if (!authFlow.phoneNumber || !authFlow.resetPin) {
      return setErrors({ confirmPassword: 'An unknown error occurred.' })
    }

    try {
      const { data: response } = await requestCodeReset({
        variables: {
          countryCode: authFlow.countryCode,
          newPassword: data.password,
          newPasswordConfirm: data.confirmPassword,
          passwordResetCode: authFlow.resetPin,
          phoneNumber: authFlow.phoneNumber,
        },
      })

      if (!response) {
        return setErrors({ confirmPassword: 'An unknown error occurred.' })
      }

      successToast('Password successfully reset.')

      dispatch(setResetPin())
      navigate(routes.SignIn)
    } catch (e) {
      setErrors({ confirmPassword: cleanErrorMessage((e as Error).message) })
    }
  }

  return <NewPasswordPanel newPassword={newPassword} errors={errors} />
}
