import dayjs from 'dayjs'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useNavigate } from 'react-router-dom'

import { StockFragmentFragment } from 'api'
import { NewButton, NewTextField, TextButton } from 'components/newUi'
import { routes } from 'routes/Paths'
import { useTheme } from 'styles/newUi'

import { TemplateItem } from './TemplateItem'

type TempStockTake = {
  cursor: string | undefined
  name: string | undefined
  date: Date
}

const defaultStockTake = {
  cursor: undefined,
  date: new Date(),
  name: undefined,
}

export const TemplateSelection = ({
  handleCancel,
  stockTakeNodes,
}: {
  handleCancel: () => void
  stockTakeNodes: StockFragmentFragment[]
}) => {
  const { theme } = useTheme()
  const navigate = useNavigate()

  const [tempStockTake, setTempStockTake] =
    useState<TempStockTake>(defaultStockTake)

  const selectItem = (name: string, cursor: string) => {
    if (name === '') {
      const today = dayjs()
      name = `StockTake-${today.format('DD-MMM-YYYY')}`
    }

    setTempStockTake((prev) => ({ ...prev, cursor, name }))
  }

  const clearSelection = () => setTempStockTake(defaultStockTake)

  const navigateToStockTake = () => {
    const suffix = `/${tempStockTake.cursor!}`
      .replace(/\/$/, '')
      .replace(/^\/all$/, '/') // Remove "all" query to match existing routing - TODO: remove this when we update the routing

    const params = new URLSearchParams({
      date: tempStockTake.date!.toISOString(),
      name: tempStockTake.name!,
    })

    return navigate(
      `${routes.Stock}${routes.Take}${
        routes.Create
      }${suffix}?${params.toString()}`,
    )
  }

  return (
    <div>
      {!tempStockTake.cursor && (
        <>
          <div>
            <TemplateItem
              key="all"
              onClick={() => selectItem('', 'all')}
              title="All"
              subtitle="All products and recipes"
            />
            <TemplateItem
              key="blank"
              onClick={() => selectItem('', 'blank')}
              title="Blank"
              subtitle="Empty list"
            />
            {stockTakeNodes.map((node) => (
              <TemplateItem
                key={node._cursor}
                onClick={() => selectItem(node.name || '', node._cursor)}
                title={
                  node.name ??
                  `${dayjs(node.date)
                    .toDate()
                    .toLocaleDateString('en-UK', { weekday: 'long' })} ${dayjs(
                    node.date,
                  ).format('DD MMM')}`
                }
                subtitle={
                  node.name
                    ? `${dayjs(node.date).toDate().toLocaleDateString('en-UK', {
                        weekday: 'long',
                      })} ${dayjs(node.date).format('DD MMM')}`
                    : undefined
                }
              />
            ))}
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              padding: theme.spacing(2),
            }}
          >
            <TextButton
              onClick={handleCancel}
              style={{ color: theme.palette.secondary[100].toHexString() }}
            >
              Cancel
            </TextButton>
          </div>
        </>
      )}
      {tempStockTake.cursor && (
        <form>
          <div className="flex flex-col h-full">
            <NewTextField
              focus
              label="Name"
              defaultValue={tempStockTake.name}
              style={{ padding: 10 }}
              onChange={(value) =>
                setTempStockTake({
                  ...tempStockTake,
                  name: value as string,
                })
              }
            />

            <div className="flex justify-center">
              <DatePicker
                maxDate={tempStockTake.date}
                minDate={tempStockTake.date}
                open
                onChange={() => null}
                inline
                readOnly
                selected={tempStockTake.date}
              />
            </div>

            <div className="p-4 flex justify-center items-center">
              <NewButton text="Back" onClick={clearSelection} color="error" />
              <NewButton text="Create" onClick={navigateToStockTake} />
            </div>
          </div>
        </form>
      )}
    </div>
  )
}
