import { Typography } from 'components/newUi/Typography'
import { useTheme } from 'styles/newUi'

type Props = {
  active?: boolean
  disabled?: boolean
  label?: string
  onClick?: () => void
}

export const Radio = ({ active, disabled, label, onClick }: Props) => {
  const { theme } = useTheme()

  return (
    // eslint-disable-next-line
    <div
      onClick={() => {
        if (disabled) return
        onClick?.()
      }}
      style={{
        alignItems: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <div style={{ height: 24, position: 'relative', width: 24 }}>
        <div
          style={{
            border: `2px solid ${theme.palette.primary[10].toHexString()}`,
            borderRadius: '50%',
            boxSizing: 'border-box',
            height: '100%',
            position: 'absolute',
            width: '100%',
          }}
        />
        <div
          style={{
            backgroundColor: active
              ? disabled
                ? theme.palette.primary[20].toHexString()
                : theme.palette.secondary[100].toHexString()
              : undefined,
            borderRadius: '50%',
            height: '60%',
            left: '20.5%',
            position: 'absolute',
            top: '20.5%',
            width: '60%',
          }}
        />
      </div>
      <Typography
        variant="caption"
        style={{
          color: disabled
            ? theme.palette.primary[20].toHexString()
            : theme.palette.primary[80].toHexString(),
          marginLeft: theme.spacing(1),
        }}
      >
        {label}
      </Typography>
    </div>
  )
}
