import { Typography } from 'components/newUi'
import { useTheme } from 'styles/newUi'

export const TemplateItem = ({
  onClick,
  title,
  subtitle,
}: {
  onClick: () => void
  title: string
  subtitle?: string
}) => {
  const { theme } = useTheme()

  return (
    <div
      onClick={onClick}
      style={{
        borderBottom: `1px solid ${theme.palette.primary[5].toHexString()}`,
        borderTop: `1px solid ${theme.palette.primary[5].toHexString()}`,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        height: 50,
        justifyContent: 'center',
        overflow: 'hidden',
        padding: theme.spacing(1),
      }}
    >
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 500,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="subtitle2"
          style={{
            color: theme.palette.primary[40].toHexString(),
            fontSize: 12,
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {subtitle}
        </Typography>
      )}
    </div>
  )
}
