import { routes } from 'routes/Paths'

export type GaEvent = {
  event_category: string
  event_label: string
  value?: string
}

export const logEvent = (action: string, event: GaEvent | undefined) => {
  try {
    // @ts-ignore
    if (window.gtag) gtag('event', action, { ...event })
  } catch (ex) {
    console.error('An error occured logging analytics', ex)
  }
}

// maps the routes for each menu item to desired value for use in analytics
export const menuItemMapper = (item: string) => {
  const map = new Map()

  map.set(`${routes.Order}${routes.History}`, 'order_history')
  map.set(routes.Home, 'menus')
  map.set(routes.Book, 'book')
  map.set(routes.Ingredient, 'ingredients')
  map.set(routes.Kitchen, 'change_kitchen')

  return map.get(item)
}
