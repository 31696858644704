import { gql } from '@apollo/client'

export const getProductsQuery = gql`
  query getProducts(
    $kitchenId: IntFilter!
    $kitchenIdFuzzySearch: Int
    $supplierId: Int
    $searchTerm: String
    $skip: Int
    $take: Int
  ) {
    fuzzySearchProducts(
      supplierId: $supplierId
      searchTerm: $searchTerm
      skip: $skip
      take: $take
      kitchenId: $kitchenIdFuzzySearch
    ) {
      products {
        _cursor
        id
        name
        code
        packSize
        price
        status
        supplier {
          _cursor
          id
          name
        }
        unitValue
        unit {
          _cursor
          id
          name
          type
        }
        ingredients(
          where: { kitchenId: $kitchenId, NOT: { status: inactive } }
        ) {
          _cursor
          id
          createdAt
          updatedAt
          price
          wastage
          unitPrice
          wasteAdjustedUnitPrice
          product {
            _cursor
            id
            name
            code
            packSize
            price
            status
            supplier {
              _cursor
              id
              name
            }
            unitValue
            unit {
              _cursor
              id
              name
              type
            }
          }
          conversionUnit
          conversionUnitType {
            _cursor
            id
            name
            type
            gramsPerUnit
          }
          conversionUnitValue
          kitchen {
            _cursor
            id
            name
          }
        }
      }
      count
    }
  }
`
