import { Button, Typography } from '@getjelly/jelly-ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Header } from './components/Header'

import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { NewLayout } from '../../../../components/newUi'
import { errorToast } from '../../../../components/toasts'
import { useProductActionAlert } from '../../../../hooks/useProductActionAlert'
import { routes } from '../../../../routes/Paths'

export function EposNowIntro() {
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()
  const { productActionAlert } = useProductActionAlert()

  const [loading, setLoading] = useState(false)

  return (
    <>
      <NewLayout
        onBack={() => navigate(routes.Settings + routes.Integrations)}
        subtitle="Integrations"
        title="Epos Now"
        bottomContent={
          <div className="px-2 py-4 border-t border-primary-100">
            <Button
              onClick={async () => {
                if (loading) return
                setLoading(true)

                try {
                  await productActionAlert(
                    'User has clicked Getting Started on Epos Now.',
                    selectedKitchen,
                  )

                  navigate(
                    routes.Settings +
                      routes.Integrations +
                      routes.EposNow +
                      routes.Install,
                  )
                } catch {
                  errorToast('An unknown error occurred.')
                } finally {
                  setLoading(false)
                }
              }}
              loading={loading}
              label="Get Started"
              className="w-full"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6 bg-primary-50">
        <Header />

        <Typography style="subtitle2" className="text-primary-900">
          On average it takes 5 minutes to connect with your Epos Now account.
        </Typography>

        <Typography style="subtitle2" className="text-primary-900">
          Once connected, you can start cutting back on manual tasks as sales
          data automatically feed from your POS.
        </Typography>
      </div>
    </>
  )
}
