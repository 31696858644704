import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useMe } from '../../hooks/auth'
import { selectToken } from '../../store/auth'
import { ReduxUser } from '../../store/user'
import { routes } from '../Paths'

export function OnboardingRedirect() {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, refetch } = useMe()
  const token = useSelector(selectToken())

  function getOnboardingRoute(userData: ReduxUser | null) {
    if (!userData) return null
    if (!userData.hasPassword) return routes.SetPassword
    if (!userData.jobRoleIds.length) return routes.SetJobRoles
    // if (!userData.jobResponsibilityIds.length) return routes.SetJobResponsibilities
    if (!userData.imageUrl) return routes.SetProfilePicture
    return null
  }

  useEffect(() => {
    if (!token) {
      return
    }

    void refetch()
  }, [token])

  useEffect(() => {
    const onboardRoute = getOnboardingRoute(user)

    if (onboardRoute && location.pathname !== onboardRoute) {
      navigate(onboardRoute, {
        replace: true,
      })
    }
  }, [user, navigate, location.pathname])

  return <Outlet />
}
