import { useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import { Recipe } from 'api'
import { Icon, NewButton, Typography } from 'components/newUi'
import { buildCreateRoute } from 'routes/Paths'
import { capitaliseEachWord } from 'utils'

import { useStyles } from './styles'

import { useKitchen } from '../../../app/contexts/SelectedKitchen'

export const Allergens = ({
  recipe,
  id,
  isDish,
}: {
  recipe: Recipe
  id: number
  isDish: boolean
}) => {
  const { selectedKitchen } = useKitchen()
  const classes = useStyles()
  const navigate = useNavigate()

  const hasAllergens = recipe.allergens.filter(
    (al) => al.contains === true || al.removable === true,
  ).length

  return (
    <div>
      {recipe.hasNoAllergens !== undefined && recipe.hasNoAllergens === true && (
        <div>
          <div
            style={{
              alignItems: 'center',
              display: 'grid',
              gridTemplateColumns: '70px 1fr',
            }}
          >
            <div style={{ padding: '0px 10px' }}>
              <ReactSVG
                width="50"
                height="50"
                aria-label="no-allergens"
                className={classes.noAllergens}
                src="https://iinu-pictures-production.s3.eu-west-2.amazonaws.com/96E187F8261B477494CD6B69E5C4B17F/noAllergens.svg"
              />
            </div>

            <div>
              <Typography variant="subtitle1">No Allergens</Typography>
            </div>
          </div>
        </div>
      )}

      {hasAllergens > 0 ? (
        <>
          <div className="flex space-x-4 p-4 border-b border-gray-100">
            <div className="flex space-x-2 items-center">
              <div className="border-4 border-error-400 w-5 h-5 rounded-full bg-error-400" />

              <Typography variant="caption">Contains</Typography>
            </div>

            <div className="flex space-x-2 items-center">
              <div className="border-4 border-error-400 w-5 h-5 rounded-full" />

              <Typography variant="caption">Removable</Typography>
            </div>
          </div>

          <div className="flex flex-col">
            {recipe.allergens
              .filter(
                (allergen) =>
                  allergen.contains === true && allergen.removable === false,
              )
              .map((allergen) => (
                <div key={allergen.allergen.id} className={classes.allergenRow}>
                  <div className="flex items-center">
                    <ReactSVG
                      className={classes.allergen}
                      src={allergen!.allergen!.imageUrl!}
                    />

                    <Typography variant="subtitle1">
                      {capitaliseEachWord(allergen.allergen.type)}
                    </Typography>
                  </div>

                  <div className="border-4 border-error-400 w-5 h-5 rounded-full bg-error-400" />
                </div>
              ))}
          </div>

          <div className="flex flex-col">
            {recipe.allergens
              .filter((allergen) => allergen.removable === true)
              .map((allergen) => (
                <div key={allergen.allergen.id} className={classes.allergenRow}>
                  <div className="flex items-center">
                    <ReactSVG
                      className={classes.allergen}
                      src={allergen!.allergen!.imageUrl!}
                    />

                    <Typography variant="subtitle1">
                      {capitaliseEachWord(allergen.allergen.type)}
                    </Typography>
                  </div>

                  <div className="border-4 border-error-400 w-5 h-5 rounded-full" />
                </div>
              ))}
          </div>
        </>
      ) : null}

      {!recipe.hasNoAllergens && hasAllergens === 0 && (
        <div className="flex flex-col items-center space-y-4 bg-gray-100 p-4">
          <Typography variant="subtitle1" style={{ paddingBottom: 10 }}>
            No allergen data for this {isDish ? 'dish' : 'recipe'}
          </Typography>

          {selectedKitchen?.userPermissions?.includes('create-allergen') && (
            <NewButton
              noFill
              text="Add allergens"
              rightAdornment={<Icon iconName="add" />}
              onClick={() => {
                navigate(buildCreateRoute(id, isDish) + '/allergens')
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}
