import { useKitchen } from 'app/contexts/SelectedKitchen'
import { CircularProgress, Loader, Typography } from 'components'
import { Icon } from 'components/newUi'
import { resizeUrl } from 'utils'

import { useS3Upload } from './hooks/useS3Upload'

export interface IProps {
  imageSource?: string
  altText?: string
  setImageUrl: (value: string) => void
  mode: string
  loading?: boolean
  style?: React.CSSProperties
}

export const ImageContainer = ({
  imageSource,
  altText,
  setImageUrl,
  mode,
  loading: imageLoading,
  style,
}: IProps) => {
  const { selectedKitchen } = useKitchen()
  const { imageHref, onChange, loading } = useS3Upload({
    imageSource,
    kitchenId: selectedKitchen?.id,
    setImageUrl,
    resizeConfig: { maxHeight: 1024, maxWidth: 1024, quality: 0.8 },
  })

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        fontSize: 10,
        height: 133,
        justifyContent: 'center',
        margin: 0,
        position: 'relative',
        width: '100%',
        ...style,
      }}
    >
      <div
        style={{
          alignContent: 'center',
          alignSelf: 'center',
          backgroundColor: !imageHref ? '#e0e0e0' : undefined,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          width: '100%',
          ...style,
        }}
      >
        {loading ? (
          <Loader />
        ) : imageHref !== undefined ? (
          <>
            <img
              src={resizeUrl(imageHref, 300, 300)}
              alt={altText}
              style={{
                cursor: 'pointer',
                height: '100%',
                objectFit: 'cover',
                width: '100%',
                ...style,
              }}
            />
            {mode === 'edit' ? (
              <div
                style={{
                  background: 'rgba(255, 255, 255, 0.75)',
                  borderRadius: 8,
                  bottom: 10,
                  padding: '3px 5px',
                  position: 'absolute',
                  right: 10,
                }}
              >
                <Typography variant="caption" style={{ fontWeight: 600 }}>
                  Change Photo
                </Typography>
              </div>
            ) : null}
          </>
        ) : imageLoading ? (
          <CircularProgress size={80} style={{ alignSelf: 'center' }} />
        ) : (
          <>
            <Icon
              iconName="addAPhoto"
              style={{ alignSelf: 'center', color: '#798392', fontSize: 50 }}
            />
            <Typography
              variant="subtitle2"
              style={{ alignSelf: 'center', fontWeight: 500 }}
            >
              Add a Photo
            </Typography>
          </>
        )}
        {mode === 'edit' && (
          <input
            accept="image/*"
            autoComplete="off"
            onChange={(event) => onChange(event.target.files)}
            style={{
              cursor: 'pointer',
              height: 133,
              left: 0,
              opacity: 0,
              position: 'absolute',
              width: '100%',
              ...style,
            }}
            type="file"
            value=""
          />
        )}
      </div>
    </div>
  )
}
