import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme, isDesktop) => ({
    _root: {
      alignItems: 'center',
      backgroundColor: isDesktop
        ? undefined
        : theme.palette.grey[50].toHexString(),
      display: 'flex',
      flexDirection: isDesktop ? 'row' : 'column',
      height: 111,
      justifyContent: 'center',
      width: '100%',
    },
    button: {
      alignItems: 'center',
      backgroundColor: theme.palette.common.light.toHexString(),
      border: `2px solid ${theme.palette.primary[100].toHexString()}`,
      borderRadius: 22,
      display: 'flex',
      fontSize: 14,
      height: 36,
      justifyContent: 'center',
      marginTop: theme.spacing(1),
      minWidth: 185,
      padding: theme.spacing(1),
    },
    text: {
      alignItems: 'center',
      color: theme.palette.primary[80].toHexString(),
      cursor: 'pointer',
      display: 'flex',
      fontWeight: 600,
      height: 40,
      marginRight: isDesktop ? theme.spacing(4) : undefined,
      paddingBottom: isDesktop ? undefined : theme.spacing(1),
    },
  }))
