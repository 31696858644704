import {
  ActualGPFlashEmptyState,
  ActualGPFlashNotPaidState,
} from '@getjelly/jelly-ui'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useIsPaidKitchen } from 'hooks'

import { FoodFlashMain } from './FoodFlashMain'

import { selectCounts } from '../../../store/kitchen'

export function FoodFlash() {
  const isPaidKitchen = useIsPaidKitchen()

  const counts = useSelector(selectCounts())
  const [bypassEmptyState, setBypassEmptyState] = useState(false)

  if (!isPaidKitchen) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <ActualGPFlashNotPaidState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  if (counts.salesCount === 0 && !bypassEmptyState) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <ActualGPFlashEmptyState
            ctaClicked={() => setBypassEmptyState(true)}
          />
        </div>
      </div>
    )
  }

  return <FoodFlashMain />
}
