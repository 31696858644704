import { Button, Typography } from '@getjelly/jelly-ui'
import { useState } from 'react'

import { CreateLinkModal } from './CreateLinkModal'
import { RemoveLinkModal } from './RemoveLinkModal'

import { KitchenToPosProduct } from '../../../../api'

type Props = {
  kto: KitchenToPosProduct
}

export function KTOCard({ kto }: Props) {
  const [showModal, setShowModal] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)

  function currencyConvert(value: number | undefined | null, pence = true) {
    if (value === undefined || value === null) {
      return '£ --'
    }

    const price = value || 0
    const adjusted = pence ? price / 100 : price

    return adjusted.toLocaleString(undefined, {
      currency: 'GBP',
      style: 'currency',
    })
  }

  return (
    <>
      <CreateLinkModal
        kto={kto}
        open={showModal}
        onClose={() => setShowModal(false)}
      />

      <RemoveLinkModal
        kto={kto}
        open={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
      />

      <div className="p-4 space-y-4 bg-white rounded shadow-low">
        <div className="flex items-center justify-between">
          <div>
            <Typography style="caption" className="text-primary-900">
              POS
            </Typography>

            <Typography style="body1" className="text-primary-900">
              {kto.posProduct.name}
            </Typography>
          </div>

          <Typography style="caption" className="text-primary-900 shrink-0">
            {currencyConvert(kto.posProduct.price)}
          </Typography>
        </div>

        <div className="flex items-center justify-between">
          <div>
            <Typography style="caption" className="text-primary-900">
              Jelly
            </Typography>

            <Typography style="body1" className="text-primary-900">
              {kto.dish?.name ?? '--'}
            </Typography>
          </div>

          <div className="shrink-0 text-right">
            <Typography style="caption" className="text-primary-900">
              {currencyConvert(kto.dish?.price, false)}
            </Typography>

            <Typography style="caption" className="text-primary-900">
              {kto.dish ? kto.dish.ingredientCount : '--'} ingredients
            </Typography>
          </div>
        </div>

        <div className="flex space-x-2">
          {kto.dish ? (
            <>
              <Button
                style="secondary"
                onClick={() => setShowRemoveModal(true)}
                label="Remove Link"
                className="w-full"
              />

              <Button
                onClick={() => setShowModal(true)}
                label="Change"
                className="w-full"
              />
            </>
          ) : (
            <Button
              onClick={() => setShowModal(true)}
              label="Link Item"
              className="w-full"
            />
          )}
        </div>
      </div>
    </>
  )
}
