import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme) => ({
    _root: {
      height: 44,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      position: 'relative',
    },
    clear: {
      color: theme.palette.primary[40].toHexString(),
      position: 'absolute',
      right: theme.spacing(2),
      top: 'calc(50% - 0.5em)',
    },
    input: {
      $nest: {
        '&:focus': {
          border: `1px solid ${theme.palette.secondary}`,
        },
      },
      border: `1px solid ${theme.palette.grey[100].toHexString()}`,
      borderRadius: 22,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
      fontSize: theme.typography.subtitle1.fontSize,
      height: 44,
      outlineColor: 'transparent',
      outlineWidth: 0,
      padding: theme.spacing(2),
      paddingRight: theme.spacing(5),
      width: '100%',
    },
  }))
