import {
  LineChart,
  Line,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceDot,
} from 'recharts'

import { useIsDesktop } from 'app/contexts/IsDesktop'
import { theme } from 'styles/newUi'

export const PriceHistoryChart = ({
  data,
  fill = theme.palette.secondary[100].toHexString(),
}: {
  data: { price: number }[]
  fill?: string
}) => {
  const avg = data.map((t) => t.price).reduce((a, b) => a + b, 0) / data.length
  const isDesktop = useIsDesktop()
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: data.length > 0 ? 'white' : undefined,
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 15,
        paddingTop: 15,
      }}
    >
      <div
        style={{
          backgroundColor: data.length > 0 ? 'white' : undefined,
          height: 106,
          width: isDesktop ? '100%' : 360,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              bottom: 5,
              left: 20,
              right: 30,
              top: 5,
            }}
          >
            <ReferenceLine
              y={avg}
              stroke={theme.palette.primary[10].toHexString()}
            />
            {data.length > 0 && (
              <ReferenceDot
                x={data.length - 1}
                y={data[data.length - 1].price ?? 0}
                r={5.5}
                fill={fill}
                stroke="none"
              />
            )}
            <Line
              dot={false}
              isAnimationActive={false}
              type="monotone"
              dataKey="price"
              stroke={fill}
              strokeWidth={2.5}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
