import { Product, Unit } from 'api'

interface Params {
  packSize: Product['packSize']
  unitValue: Product['unitValue']
  unit: Unit
}

export const completeSize = ({ packSize, unitValue, unit }: Params) =>
  `${packSize !== 1 ? `${packSize} x ` : ''}${unitValue} ${unit.name}`
