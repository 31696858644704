import { useEffect } from 'react'
import useWebSocket from 'react-use-websocket'

import { IncrementCounts } from './IncrementCounts'
import { RefreshKitchen } from './RefreshKitchen'

import { VITE_REACT_APP_VERBOSE_WEBSOCKETS } from '../../app'

import { Listener, RegisterListener } from './index'

const eventListeners: Record<string, Record<string, Listener>> = {}

function logInfo(...args: any) {
  if (VITE_REACT_APP_VERBOSE_WEBSOCKETS === 'true') {
    console.log(...args)
  }
}

function logError(...args: any) {
  // Always show errors
  console.error(...args)
}

export function EventHandler({ websocketUrl }: { websocketUrl: string }) {
  // Register listener function.
  const registerListener: RegisterListener = (
    listenerId,
    eventName,
    listener,
  ) => {
    logInfo('Registering WebSocket event listener:', listenerId, eventName)
    if (eventListeners[eventName]) {
      eventListeners[eventName][listenerId] = listener
    } else {
      eventListeners[eventName] = { [listenerId]: listener }
    }
  }

  // Handle websockets message that has type: 'event'
  function handleEvent(message: { type: 'event'; event: any }) {
    logInfo('Handling WebSocket event:', message)

    // Get all listeners for event.
    const listeners = Object.values(eventListeners[message.event.name] ?? {})

    // Fire them all async.
    Promise.allSettled(listeners.map((l) => l(message.event.data))).then(
      (promises) =>
        promises.forEach((promise) => {
          if (promise.status === 'fulfilled') {
            return
          }

          // If any fail, show error message.
          logError(
            'Failed to execute WebSocket event listener.',
            promise.reason,
          )
        }),
    )
  }

  // Set up the websocket connection.
  const { sendJsonMessage } = useWebSocket(websocketUrl, {
    onClose: () =>
      handleEvent({
        event: { name: 'websocket-closed' },
        type: 'event',
      }),
    onError: logError,
    onMessage({ data }) {
      const message = JSON.parse(data)

      if (message.type !== 'event') {
        return
      }

      handleEvent(message)
    },
    onOpen: () =>
      handleEvent({
        event: { name: 'websocket-opened' },
        type: 'event',
      }),
  })

  useEffect(() => {
    const interval = setInterval(
      () => sendJsonMessage({ action: 'keepAlive' }, false),
      30000,
    )

    return () => {
      clearInterval(interval)
    }
  }, [sendJsonMessage])

  return (
    <>
      <RefreshKitchen registerListener={registerListener} />
      <IncrementCounts registerListener={registerListener} />
    </>
  )
}
