import styled from 'styled-components'

import { Icon } from 'components/newUi'

export const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: center;
  margin-right: 4px;
  width: 44px;
`

export const StyledDownloadIcon = styled(Icon).attrs({ iconName: 'download' })`
  color: ${({ theme }) => theme.palette.secondary[100].toHexString()};
  cursor: pointer;
`
