import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme) => ({
    _root: {
      backgroundColor: 'white',
    },
    alert: {
      color: theme.palette.error[100].toHexString(),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 30,
    },
    cost: {
      fontWeight: 600,
    },
    endTitle: {
      color: theme.palette.primary[60].toHexString(),
      display: 'grid',
      gridTemplateColumns: '83px 116px 136px',
      gridTemplateRows: '1fr',
      textAlign: 'right',
    },
    name: {
      marginLeft: 20,
    },
    onlyPrint: {
      '@media print': {
        display: 'block',
      },
      display: 'none',
    },
    price: {
      fontWeight: 600,
    },
    profit: {
      alignItems: 'center',
      display: 'flex',
      fontWeight: 600,
      justifyContent: 'flex-end',
    },
    row: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.primary[20].toHexString()}`,
      display: 'grid',
      gridTemplateColumns: '1fr 335px',
      gridTemplateRows: '1fr',
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
    },
    rowCosting: {
      display: 'grid',
      gridTemplateColumns: '83px 116px 136px',
      gridTemplateRows: '1fr',
      textAlign: 'right',
    },
    rowInfo: {
      alignItems: 'center',
      display: 'flex',
    },
    section: {},
    sectionTitle: {
      borderBottom: `1px solid ${theme.palette.primary[20].toHexString()}`,
      boxSizing: 'border-box',
      color: theme.palette.secondary[100].toHexString(),
      fontSize: 18,
      paddingBottom: 10,
      paddingLeft: 20,
      paddingTop: 20,
      textTransform: 'capitalize',
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
    },
    titleBottomRow: {
      fontSize: 12,
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    titleRow: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary[10].toHexString(),
      display: 'grid',
      gridTemplateColumns: '1fr 335px',
      gridTemplateRows: '1fr',
      padding: 20,
    },
    titleTopRow: {
      fontSize: 14,
      fontWeight: 600,
    },
  }))
