import { style } from 'typestyle'

import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme) => ({
    body1: style({
      ...theme.typography.body1,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
    body2: style({
      ...theme.typography.body2,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
    button: style({
      ...theme.typography.button,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
    caption: style({
      ...theme.typography.caption,
      color: theme.palette.primary[60].toHexString(),
    }),
    h1: style({
      ...theme.typography.h1,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
    h2: style({
      ...theme.typography.h2,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
    h3: style({
      ...theme.typography.h3,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
    h4: style({
      ...theme.typography.h4,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
    h5: style({
      ...theme.typography.h5,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
    h6: style({
      ...theme.typography.h6,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
    label: style({
      ...theme.typography.caption,
      color: theme.palette.primary[60].toHexString(),
    }),
    subtitle1: style({
      ...theme.typography.subtitle1,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
    subtitle2: style({
      ...theme.typography.subtitle2,
      color: theme.palette.text[theme.palette.type].primary.toHexString(),
    }),
  }))
