import { gql } from '@apollo/client'

export const dashboardFinanceQuery = gql`
  query dashboardFinanceQuery($period: PeriodEnum!, $kitchenId: Int!) {
    dashboardFinance(period: $period, kitchenId: $kitchenId) {
      topNegativePriceChange {
        productName
        supplierName
        percentageChange
      }
      topPositivePriceChange {
        productName
        supplierName
        percentageChange
      }
      totalSales
      totalSpend
      daysOfMissingSales
      totalInvoicesNeedAttention
    }
  }
`
