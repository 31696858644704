import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { routes } from 'routes/Paths'

import { Query } from '../../../api'
import { Loader } from '../../../components'
import {
  Icon,
  NewLayout,
  ProfilePicture,
  Typography,
} from '../../../components/newUi'
import { getMeQuery } from '../graphql'

export function Me() {
  const navigate = useNavigate()

  const { data: { me: user } = {}, loading } =
    useQuery<{ me: Query['me'] }>(getMeQuery)

  return (
    <>
      <NewLayout title="My Profile" subtitle="Settings" />

      <div className="p-3">
        {loading && !user ? (
          <Loader />
        ) : (
          <div
            className="bg-white rounded-lg shadow"
            onClick={() => navigate(routes.Settings + routes.Me + routes.Edit)}
          >
            <div className="border-b border-gray-100 flex justify-between items-center p-4">
              <div className="flex space-x-3 items-center">
                <ProfilePicture imageUrl={user?.imageUrl} size="medium" />

                <Typography variant="body1" style={{ fontWeight: 400 }}>
                  {user?.firstName + ' ' + user?.lastName}
                </Typography>
              </div>

              <Icon iconName="chevronRight" />
            </div>

            <div className="border-b border-gray-100 flex p-4 justify-between">
              <div className="flex space-x-3 items-center">
                <Icon iconName="phone" className="text-gray-400" />

                <Typography variant="body1" style={{ fontWeight: 400 }}>
                  {'+44 ' + user?.phoneNumberNational}
                </Typography>
              </div>

              <Icon iconName="chevronRight" />
            </div>

            <div className="flex p-4 justify-between">
              <div className="flex space-x-3 items-center">
                <Icon iconName="email" className="text-gray-400" />

                <Typography variant="body1" style={{ fontWeight: 400 }}>
                  {'' + user?.email}
                </Typography>
              </div>

              <Icon iconName="chevronRight" />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
