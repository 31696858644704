import dayjs from 'dayjs'

import { Typography } from 'components/newUi'
import { StockTakeStoreStockTake } from 'store/stocktake'
import { useTheme } from 'styles/newUi'

const dateFormat = 'ddd Do MMM'

export const CustomTitle = ({
  stocktake,
  setShowModal,
}: {
  stocktake: StockTakeStoreStockTake
  setShowModal: (shouldShow: boolean) => void
}) => {
  const { theme } = useTheme()

  const date = dayjs(stocktake?.date)

  const formattedDate = date.format(dateFormat)

  return (
    <div
      data-testid="stocktake-title"
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <Typography
          variant="body1"
          style={{
            color: theme.palette.primary[40].toHexString(),
            marginRight: theme.spacing(1),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {stocktake?.name ? `Stocktake -` : 'Stocktake'}
        </Typography>
        {stocktake?.name && (
          <Typography
            variant="subtitle2"
            style={{
              color: theme.palette.primary[40].toHexString(),
              fontSize: 12,
              fontWeight: 700,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
            }}
          >
            {formattedDate}
          </Typography>
        )}
      </div>
      {/* eslint-disable-next-line */}
      <div
        style={{ display: 'grid', gridTemplateColumns: '1fr' }}
        onClick={() => setShowModal(true)}
      >
        <Typography
          style={{
            color: theme.palette.secondary[100].toHexString(),
            cursor: 'pointer',
            marginRight: theme.spacing(1),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="h6"
        >
          {stocktake?.name ?? formattedDate}
        </Typography>
      </div>
    </div>
  )
}
