import { Pill } from '@getjelly/jelly-ui'
import { useState } from 'react'

import { InvoiceNote } from 'api'
import { api } from 'api/rest'
import { NotesModalForm } from 'screens/Spending/Invoices/SingleInvoice/components/NotesForm'

import { successToast } from '../../../../components/toasts'

export function NotesBadge({
  notes: initialNotes,
  pendingInvoiceId,
  invoiceNumber,
}: {
  notes: InvoiceNote[]
  pendingInvoiceId: number
  invoiceNumber?: string
}) {
  const [showNotesModal, setShowNotesModal] = useState(false)
  const [notes, setNotes] = useState<InvoiceNote[]>(initialNotes)

  async function handleAddNote(note: string) {
    const { data: newNote } = await api.post<{ note: InvoiceNote }>(
      `/rest/invoices/${pendingInvoiceId}/notes`,
      { note },
    )

    setShowNotesModal(false)
    successToast('Note added')

    if (!note) return

    setNotes([...notes, newNote.note])
  }

  return (
    <>
      <Pill
        variant="outlined"
        label={`Notes (${notes.length})`}
        className="whitespace-nowrap"
        onClick={(e) => {
          e.stopPropagation()
          setShowNotesModal(true)
        }}
      />

      <NotesModalForm
        id={invoiceNumber}
        handleAddNote={handleAddNote}
        notes={notes}
        setShowModal={setShowNotesModal}
        showModal={showNotesModal}
      />
    </>
  )
}
