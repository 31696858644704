import {
  InsightsBarChart,
  InsightsDayChart,
  InsightsLineChart,
} from '@getjelly/jelly-ui'
import { differenceInMonths, format, parse, startOfMonth } from 'date-fns'
import { useMemo } from 'react'

import { PeriodEnum, SpendData } from '../../../api'

type Props = {
  periodEnum: PeriodEnum
  startDate: Date
  spendData: SpendData[]
}

export function SpendGraphs({ periodEnum, startDate, spendData }: Props) {
  const dataForBarChart = useMemo(() => {
    return spendData.map((d) => ({
      date: parse(d.date, 'yyyy-MM-dd', new Date()),
      spend: d.spend,
    }))
  }, [spendData])

  const dataForDayChart = useMemo(() => {
    return spendData.map((d) => ({
      date: parse(d.date, 'yyyy-MM-dd', new Date()),
      spend: d.spend,
    }))
  }, [spendData])

  const dataForLineChart = useMemo(() => {
    const spendDataByMonth = spendData.reduce((acc, d) => {
      const monthStart = startOfMonth(parse(d.date, 'yyyy-MM-dd', new Date()))
      const monthKey = format(monthStart, 'yyyy-MM')

      if (!acc[monthKey]) {
        acc[monthKey] = { date: monthStart, spend: 0 }
      }

      acc[monthKey].spend += d.spend

      return acc
    }, {} as Record<string, { date: Date; spend: number }>)

    return Object.values(spendDataByMonth).sort((a, b) =>
      differenceInMonths(a.date, b.date),
    )
  }, [spendData])

  return (
    <>
      {periodEnum === 'week' && <InsightsBarChart data={dataForBarChart} />}

      {periodEnum === 'month' && (
        <InsightsDayChart startDate={startDate} data={dataForDayChart} />
      )}

      {periodEnum === 'quarter' && (
        <InsightsLineChart data={dataForLineChart} />
      )}
    </>
  )
}
