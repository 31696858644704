import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import Select from 'react-select'

import { CoverBox } from 'components/newUi/Dropdown/styled'
import { getCurrentOption } from 'components/newUi/Dropdown/utils'
import { useTheme } from 'styles/newUi/ThemeProvider'

import { useStyles } from './styles'
import { Props } from './types'

import { Typography } from '../Typography'

export const Dropdown = ({
  style,
  className,
  label,
  labelClassName,
  startAdornment,
  options,
  onChange,
  disabled,
  error,
  defaultOption,
  placeholder,
  searchable,
  controlStyles,
  loading,
}: Props) => {
  const classes = useStyles()
  const startAdornRef = useRef<HTMLInputElement>(null)
  const [showError] = useState(false)
  const [current, setCurrent] = useState(() => {
    return loading ? undefined : getCurrentOption(defaultOption, options)
  })

  // SECTION Start loading hack

  const [, setLoaded] = useState(!loading)

  useEffect(() => {
    if (loading) return

    setLoaded((currentLoaded) => {
      if (!currentLoaded) {
        // Set for the 2nd time on loaded

        setCurrent(getCurrentOption(defaultOption, options))
      }

      return true
    })
  }, [defaultOption, loading, options])

  // !SECTION End loading hack

  const { theme } = useTheme()

  const dropdownStyles = {
    container: (styles: any) => ({
      ...styles,
    }),
    //control = box before clicked
    control: (styles: any, { isFocused }: any) => ({
      ...styles,
      border: isFocused
        ? `2px solid ${theme.palette.secondary[100].toHexString()} !important`
        : error
        ? `2px solid ${theme.palette.error[100].toHexString()} !important`
        : `2px solid ${theme.palette.primary[20].toHexString()} !important`,
      borderRadius: '10px',
      boxShadow: 'none',
      color: theme.palette.primary[100].toHexString(),
      cursor: 'pointer',
      height: 45,
      width: 'calc(100% + 4px)',
      ...controlStyles,
    }),
    dropdownIndicator: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: undefined,
      color: 'rgba(102, 102, 102, 1)',
      display: 'flex',
      pointerEvents: 'none',
      position: 'absolute',
      right: 5,
      top: '50%',
      transform: state.selectProps.menuIsOpen
        ? 'translateY(-50%) rotate(180deg)'
        : 'translateY(-50%)',
      transition: '0.2s',
    }),
    groupHeading: (styles: any) => ({
      ...styles,
      color: theme.palette.primary[100].toHexString(),
      fontSize: 16,
      fontWeight: 500,
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: 'none',
    }),
    input: (styles: any) => ({
      ...styles,
      MozBoxShadow: 'none',
      WebkitBoxShadow: 'none',
      alignItems: 'center',
      backgroundImage: 'none',
      boxShadow: 'none',
      color: theme.palette.primary[100].toHexString(),
      cursor: 'pointer',
      display: 'flex',
      fontSize: 16,
      height: 40,
      margin: 0,
      padding: theme.spacing(2),
      width: '100%',
    }),
    menu: (styles: any) => ({
      ...styles,
      left: 2,
      marginTop: 0,
    }),
    menuList: (styles: any) => ({
      ...styles,
      borderRadius: '10px 10px 10px 10px',
    }),
    option: (styles: any, { isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor:
          isFocused | isSelected
            ? theme.palette.primary[20].toHexString()
            : 'white',
      }
    },
    valueContainer: (styles: any) => ({
      ...styles,
      borderRadius: '10px 10px 10px 10px',
      padding: '0px 8px',
      textAlign: 'left',
    }),
  }

  const onOptionClick = (label: string, value: string | number) => {
    if (disabled || !options) return
    setCurrent({ label, value })

    if (onChange) onChange(value, label)
  }

  return (
    <div
      className={clsx([
        classes.wrap,
        className,
        disabled && classes.disabled,
        (error || showError) && classes.error,
      ])}
      style={style}
    >
      {label && (
        <Typography
          variant="label"
          className={clsx([classes.label, labelClassName])}
        >
          {label}
        </Typography>
      )}
      <div className={classes.inputWrap}>
        <div className={classes.startAdornment} ref={startAdornRef}>
          {startAdornment}
        </div>

        <Select
          inputId={label as string}
          options={options}
          styles={dropdownStyles}
          value={current}
          menuPlacement="auto"
          placeholder={placeholder}
          isSearchable={searchable}
          onChange={(value) => {
            if (value?.value) onOptionClick(value.label, value.value)
          }}
          isDisabled={disabled}
        />

        {loading ? (
          <CoverBox>
            <CircularProgress size={24} />
          </CoverBox>
        ) : null}
      </div>
    </div>
  )
}
