import { gql } from '@apollo/client'

export const updateMenuMutation = gql`
  mutation updateMenu($data: UpdateOneMenuInputObject!) {
    updateOneMenu(data: $data) {
      _cursor
      id
      name
      gpTarget
    }
  }
`

export const addDishToMenuMutation = gql`
  mutation updateDish(
    $data: UpdateOneMenuInputObject!
    $dishes: [MenuToDishInputObject!]
  ) {
    updateOneMenu(data: $data, dishes: $dishes) {
      _cursor
      id
      name
      dishes {
        _cursor
        id
        menu {
          _cursor
          id
          name
        }
        dish {
          _cursor
          id
          name
        }
      }
    }
  }
`

export const removeDishFromMenuMutation = gql`
  mutation updateDish($data: UpdateOneMenuInputObject!, $removeDishes: [Int!]) {
    updateOneMenu(data: $data, removeDishes: $removeDishes) {
      _cursor
      id
      name
      dishList(skip: 0, take: 90) {
        nodes {
          _cursor
          id
          dish {
            _cursor
            id
            imageUrl
            name
            price
            gpTarget
            type
            overheads
            vat
            portion
            recipe {
              _cursor
              id
              unitCost
            }
          }
        }
      }
    }
  }
`

export const deleteOneMenuMutation = gql`
  mutation DeleteOneMenu($where: MenuWhereUniqueInput!) {
    deleteOneMenu(where: $where) {
      _cursor
      id
      name
    }
  }
`
