import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { Query } from 'api'
import { Icon } from 'components/newUi/Icon'
import { Typography } from 'components/newUi/Typography'
import { useWindowSize } from 'hooks'
import { routes } from 'routes/Paths'
import { setSupplier } from 'store/supplier'
import { capitaliseEachWord } from 'utils'

import { useStyles } from './styles'

export const Item = ({ item }: { item: Query['suppliers'][number] }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { gte: isDesktop } = useWindowSize('md')

  const to = `${routes.Supplier}/${String(item.id)}${routes.Product}${
    routes.List
  }`

  const handleOnClick = () => {
    dispatch(setSupplier(item))
  }

  return (
    <Link replace to={to} className={classes.link} onClick={handleOnClick}>
      <div className={isDesktop ? classes.itemRootDesktop : classes.itemRoot}>
        <div>
          <Typography variant="h6" className={classes.supplierName}>
            {capitaliseEachWord(item.name)}
          </Typography>
        </div>
      </div>
      {!isDesktop && (
        <div className={classes.arrow}>
          <Icon iconName="chevronRight" />
        </div>
      )}
    </Link>
  )
}
