import { equals } from 'ramda'

export const updatedData = <TVariables>(
  variables: TVariables,
  initialValues: TVariables,
) => {
  const keys = Object.keys(variables) as Array<keyof TVariables>
  const variablesToUpdate: Partial<typeof variables> = {}

  keys.forEach((key) => {
    if (equals(variables[key], initialValues[key])) {
      return
    }
    variablesToUpdate[key] = variables[key]
  })

  return variablesToUpdate
}
