import { gql } from '@apollo/client'

export const updateOneKitchenToSupplierMutation = gql`
  mutation UpdateOneKitchenToSupplier(
    $data: KitchenToSupplierUpdateInput!
    $where: KitchenToSupplierWhereUniqueInput!
  ) {
    updateOneKitchenToSupplier(data: $data, where: $where) {
      _cursor
      id
      accountReference
      contactName
      emails
      notes
      phone
    }
  }
`
