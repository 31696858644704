import { useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs'

import {
  Query,
  QueryPendingInvoicesArgs,
  MutationMergePendingInvoicesArgs,
  MutationUpdateOnePendingInvoiceArgs,
  PendingInvoice,
  Kitchen,
  InvoiceStatus,
  QueryMode,
  SortOrder,
  MutationDeleteOnePendingInvoiceArgs,
} from 'api'

import {
  pendingInvoices,
  mergePendingInvoices,
  updateOnePendingInvoice,
  deleteOnePendingInvoice,
} from './graphql'

import { errorToast, successToast } from '../../components/toasts'

export const useHooks = (params?: {
  selectedKitchen?: Kitchen
  setToMerge?: (list: number[]) => void
  pendingSearch?: string
  historySearch?: string
  uploadFilesCallback?: (files: File[], failed: boolean) => void
}) => {
  const selectedKitchen = params?.selectedKitchen
  const setToMerge = params?.setToMerge
  const pendingSearch = params?.pendingSearch
  const historySearch = params?.historySearch

  const {
    data: pending,
    loading: pendingLoading,
    refetch: refetchPending,
  } = useQuery<
    { pendingInvoices: Query['pendingInvoices'] },
    QueryPendingInvoicesArgs
  >(pendingInvoices, {
    fetchPolicy: 'network-only',
    skip: !selectedKitchen,
    variables: {
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      where: {
        OR: pendingSearch
          ? [
              {
                createdBy: {
                  OR: [
                    {
                      firstName: {
                        contains: pendingSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      lastName: {
                        contains: pendingSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
            ]
          : undefined,
        kitchenId: {
          equals: selectedKitchen?.id || null,
        },
        status: {
          in: [InvoiceStatus.Pending, InvoiceStatus.Processing],
        },
      },
    },
  })

  const {
    data: history,
    loading: historyLoading,
    refetch: refetchHistory,
    fetchMore,
  } = useQuery<
    {
      pendingInvoices: Query['pendingInvoices']
    },
    QueryPendingInvoicesArgs
  >(pendingInvoices, {
    fetchPolicy: 'network-only',
    skip: !selectedKitchen,
    variables: {
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      skip: 0,
      take: 48,
      where: {
        OR: historySearch
          ? [
              {
                createdBy: {
                  OR: [
                    {
                      firstName: {
                        contains: historySearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      lastName: {
                        contains: historySearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
              {
                invoice: {
                  some: {
                    supplier: {
                      name: {
                        contains: historySearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            ]
          : undefined,
        createdAt: {
          gte: dayjs(Date.now()).subtract(24, 'hours').startOf('day').toDate(),
        },
        kitchenId: {
          equals: selectedKitchen?.id || null,
        },
        status: {
          notIn: [InvoiceStatus.Pending, InvoiceStatus.Processing],
        },
      },
    },
  })

  const refetch = async () => {
    await refetchPending()
    await refetchHistory()
  }

  const [merge] = useMutation<
    {
      mergePendingInvoices: PendingInvoice
    },
    MutationMergePendingInvoicesArgs
  >(mergePendingInvoices, {
    onCompleted: async () => {
      await refetch()
      successToast(`Successfully merged invoices`)
      if (setToMerge) setToMerge([])
    },
    onError: (error) => {
      errorToast(error.message)
    },
  })

  const [updateOne] = useMutation<
    {
      updateOnePendingInvoice: PendingInvoice
    },
    MutationUpdateOnePendingInvoiceArgs
  >(updateOnePendingInvoice, {
    onCompleted: async () => {
      successToast(`Invoice Updated`)
      await refetch()
    },
    onError: (error) => {
      errorToast(error.message)
    },
  })

  const [deletePendingInvoice, { loading: deleteLoading }] = useMutation<
    {
      deleteOnePendingInvoice: PendingInvoice
    },
    MutationDeleteOnePendingInvoiceArgs
  >(deleteOnePendingInvoice, {
    onCompleted: async () => {
      successToast(`Image deleted`)
      await refetch()
    },
    onError: (error) => {
      errorToast(error.message)
    },
    update: (cache, _, options) => {
      const id = cache.identify({
        __typename: 'PendingInvoice',
        id: options.variables?.id,
      })
      cache.evict({ id })
      cache.gc()
    },
  })

  return {
    deleteLoading,
    deletePendingInvoice,
    fetchMore,
    history: history?.pendingInvoices,
    loading: pendingLoading || historyLoading,
    merge,
    pending: pending?.pendingInvoices,
    updateOne,
  }
}
