import { GREY, jellyRed, SELECTED_GREEN } from 'styles/colors'
import { makeStyles } from 'styles/newUi'

export const useStyles = () => {
  return makeStyles((theme, isDesktop) => ({
    bottomRow: {
      color: GREY,
      justifyContent: isDesktop ? 'start' : 'space-between',
      maxHeight: isDesktop ? 100 : 0,
      overflow: 'hidden',
      transition: 'all 0.5s',
      width: isDesktop ? '35%' : '100%',
    },
    changeWrap: {
      // flexGrow: 1,
      // overflow: 'auto',
    },
    flexCol: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      height: '100%',
    },
    header: {
      padding: isDesktop ? undefined : 10,
    },
    icon: {
      transition: 'all 0.5s',
    },
    info: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[100].toHexString()}`,
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
      width: '100%',
    },
    innerRow: {
      alignItems: 'center',
      color: 'unset',
      display: 'flex',
      justifyContent: 'space-between',
      textDecoration: 'none',
      width: '100%',
    },
    itemExpand: {
      maxHeight: '100px !important',
    },
    itemInnerMobile: {
      flex: 1,
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    itemLeft: {
      maxWidth: isDesktop ? '35%' : '80%',
      minWidth: isDesktop ? '35%' : '80%',
    },
    itemRight: {
      alignContent: 'center',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    itemRoot: {
      borderBottom: `1px solid ${theme.palette.grey[50].toHexString()}`,
      padding: theme.spacing(2),
      width: '100%',
    },
    itemRootDesktop: {
      alignContent: 'center',
      borderTop: `1px solid ${theme.palette.primary[20].toHexString()}`,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '100%',
      justifyItems: 'center',
      padding: 15,
      width: '100%',
    },
    link: {
      display: 'flex',
    },
    list: {
      // display: 'flex',
      // flexDirection: 'column',
      height: '100%',
      // overflow: 'auto',
      width: '100%',
    },
    listContainer: {},
    listItem: {},
    listItemDesktop: {
      backgroundColor: '#FFFFFF',
    },
    negative: {
      color: SELECTED_GREEN,
    },
    positive: {
      color: jellyRed,
    },
    root: {
      marginTop: isDesktop ? theme.spacing(5) : 0,
      padding: isDesktop ? 20 : 0,
    },
    rotate: {
      transform: 'rotate(180deg)',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 10,
    },
    sectionClass: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    supplierName: {
      textTransform: 'capitalize',
    },
    title: {},
  }))
}
