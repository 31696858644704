import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useKitchen } from '../../app/contexts/SelectedKitchen'
import { selectToken } from '../../store/auth'
import { routes } from '../Paths'

export function KitchenSelectorRedirect() {
  const token = useSelector(selectToken())
  const tokenSet = useMemo(() => !!token, [token])

  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()

  useEffect(() => {
    if (!tokenSet) return

    if (!selectedKitchen) {
      navigate(routes.Settings + routes.Locations, {
        replace: true,
      })
    }
  }, [selectedKitchen, navigate])

  return null
}
