import { useMutation, useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { isEmpty, isNil, trim } from 'ramda'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import {
  Mutation,
  Query,
  QuerySupplierNodeArgs,
  MutationUpdateOneKitchenToSupplierArgs,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import { Alert, Button, NewLayout, Typography } from 'components/newUi'
import { TextField } from 'components/newUi/TextField'
import { updateOneKitchenToSupplierMutation } from 'screens/Supplier/Edit/graphql'
import { getSupplierQuery } from 'screens/Supplier/View/graphql'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord, encodeCursor } from 'utils'

const validationSchema = Yup.object({
  accountReference: Yup.string(),
  contactName: Yup.string(),
  emails: Yup.array().of(Yup.string().email()),
  notes: Yup.string(),
  phone: Yup.string(),
})

export const SupplierEdit = () => {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()
  const { id } = useParams()
  const skip = isNil(selectedKitchen?._cursor)
    ? isNil(selectedKitchen?.id)
      ? true
      : false
    : false
  const kitchenCursor = selectedKitchen?._cursor
    ? selectedKitchen?._cursor
    : selectedKitchen?.id
    ? encodeCursor('Kitchen', selectedKitchen?.id)
    : undefined
  const [updateKitchenToSupplier] = useMutation<
    { updateOneKitchenToSupplier: Mutation['updateOneKitchenToSupplier'] },
    MutationUpdateOneKitchenToSupplierArgs
  >(updateOneKitchenToSupplierMutation)
  const { data, loading } = useQuery<
    { supplierNode: Query['supplierNode'] },
    QuerySupplierNodeArgs & { kitchenCursor?: string }
  >(getSupplierQuery, {
    skip,
    variables: {
      cursor: id!,
      kitchenCursor,
    },
  })
  const { theme } = useTheme()

  const supplier = data?.supplierNode
  const kitchenInfo = supplier?.kitchenList.nodes[0]

  const formik = useFormik({
    initialValues: {
      accountReference: kitchenInfo?.accountReference ?? '',
      contactName: kitchenInfo?.contactName ?? '',
      emails: kitchenInfo?.emails ?? [],
      notes: kitchenInfo?.notes ?? '',
      phone: kitchenInfo?.phone ?? '',
    },
    onSubmit: async ({
      accountReference,
      contactName,
      emails,
      notes,
      phone,
    }) => {
      if (selectedKitchen?.id && supplier?.id) {
        await updateKitchenToSupplier({
          variables: {
            data: {
              accountReference: trim(accountReference),
              contactName: trim(contactName),
              emails: emails
                .filter((email) => !isEmpty(trim(email)))
                .map((email) => trim(email)),
              notes: trim(notes),
              phone: trim(phone),
            },
            where: {
              kitchenId_supplierId: {
                kitchenId: selectedKitchen?.id,
                supplierId: supplier?.id,
              },
            },
          },
        })
        navigate(-1)
      }
    },
    validationSchema,
  })

  const emailErrors =
    formik.errors.emails &&
    // @ts-ignore
    `${formik.errors.emails.map((_error: string, index: number) => {
      return `${formik.values.emails[index]} is not a valid email`
    })}`.replaceAll(',', ' ')

  return (
    <div>
      <NewLayout
        hideMenu
        subtitle={capitaliseEachWord(supplier?.name.toLowerCase())}
        title="Edit Account Info"
        bottomContent={
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              padding: theme.spacing(2),
            }}
          >
            <Button
              disabled={!formik.dirty}
              text="Update Supplier Info"
              onClick={formik.handleSubmit}
            />
          </div>
        }
      />
      {loading && isNil(supplier) ? (
        <div style={{ height: 'calc(100vh - 64px)' }}>
          <Loader />
        </div>
      ) : (
        <div>
          <form>
            <div
              style={{
                backgroundColor: theme.palette.primary[10].toHexString(),
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                width: '100%',
              }}
            >
              <Typography
                style={{ color: theme.palette.primary[60].toHexString() }}
                variant="button"
              >
                Kitchen Notes
              </Typography>
            </div>
            <div
              style={{
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
              }}
            >
              <TextField
                label="Your kitchen’s account reference"
                defaultValue={formik.values.accountReference}
                error={formik.errors.accountReference}
                onChange={(value) =>
                  formik.setFieldValue('accountReference', value)
                }
                placeholder="Your account or reference number"
                inputProps={{
                  'data-hj-allow': '',
                  value: formik.values.accountReference,
                }}
              />
              <TextField
                label="Your kitchen’s notes on this supplier"
                defaultValue={formik.values.notes}
                error={formik.errors.notes}
                onChange={(value) => formik.setFieldValue('notes', value)}
                placeholder="Add any notes your team has on this supplier - e.g. same day shellfish only on Tuesdays"
                type="textarea"
                inputProps={{
                  'data-hj-allow': '',
                  style: {
                    minHeight: 85,
                  },
                  value: formik.values.notes,
                }}
              />
            </div>
            <div
              style={{
                backgroundColor: theme.palette.primary[10].toHexString(),
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                width: '100%',
              }}
            >
              <Typography
                style={{ color: theme.palette.primary[60].toHexString() }}
                variant="button"
              >
                Supplier Contact Details
              </Typography>
            </div>
            <div
              style={{
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
              }}
            >
              {!isEmpty(data?.supplierNode?.emails) &&
                !isNil(data?.supplierNode?.emails) && (
                  <>
                    <TextField
                      label="Orders will be sent to"
                      labelStyle={{
                        color: theme.palette.primary[60].toHexString(),
                      }}
                      defaultValue={data?.supplierNode?.emails.join(';')}
                      disabled
                    />
                    <Alert type="info">
                      <Typography
                        variant="caption"
                        style={{
                          color: theme.palette.primary[60].toHexString(),
                        }}
                      >
                        Orders to this supplier will be emailed to{' '}
                        {data?.supplierNode?.emails.join(';')}. If you want to
                        send the orders to another address instead, enter it
                        below.
                      </Typography>
                    </Alert>
                  </>
                )}
              <TextField
                label={
                  isEmpty(data?.supplierNode?.emails) ||
                  isNil(data?.supplierNode?.emails)
                    ? 'Orders will be sent to'
                    : ''
                }
                labelStyle={{ color: theme.palette.primary[60].toHexString() }}
                defaultValue={formik.values.emails.join('\n')}
                error={emailErrors}
                type="textarea"
                onChange={(value) => {
                  // @ts-ignore
                  const array = value.split('\n')
                  formik.setFieldValue('emails', array)
                }}
                placeholder="Separate emails with a new line"
                inputProps={{
                  'data-hj-allow': '',
                  style: {
                    minHeight: 85,
                  },
                  value: formik.values.emails.join('\n'),
                }}
              />
              <TextField
                label="Your Supplier Contact (for any questions)"
                defaultValue={formik.values.contactName}
                error={formik.errors.contactName}
                onChange={(value) => formik.setFieldValue('contactName', value)}
                placeholder="Supplier contact name"
                inputProps={{
                  'data-hj-allow': '',
                  value: formik.values.contactName,
                }}
              />
              <TextField
                defaultValue={formik.values.phone}
                error={formik.errors.phone}
                onChange={(value) => formik.setFieldValue('phone', value)}
                placeholder="Supplier contact phone number"
                inputProps={{
                  'data-hj-allow': '',
                  value: formik.values.phone,
                }}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  )
}
