import { gql } from '@apollo/client'

export const getSuppliersQuery = gql`
  query getSuppliers(
    $cursor: SupplierWhereUniqueInput
    $orderBy: [SupplierOrderByInput!]
    $skip: Int
    $take: Int
    $where: SupplierWhereInput
    $fuzzySearch: String
  ) {
    suppliersCount(where: $where)
    suppliers(
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
      fuzzySearch: $fuzzySearch
    ) {
      _cursor
      id
      name
    }
  }
`

export const createOneSupplierMutation = gql`
  mutation createOneSupplier($data: CreateOneSupplierInputObject!) {
    createOneSupplier(data: $data) {
      _cursor
      id
      name
      status
    }
  }
`

export const updateOneKitchenMutation = gql`
  mutation updateOneKitchen(
    $data: UpdateOneKitchenInputObject!
    $suppliers: [Int!]
  ) {
    updateOneKitchen(data: $data, suppliers: $suppliers) {
      _cursor
      id
    }
  }
`

export const getBasketProductsQuery = gql`
  query getProducts($where: ProductWhereInput, $kitchenId: Int) {
    products(where: $where) {
      _cursor
      id
      category
      code
      packSize
      unitValue
      name
      unit {
        _cursor
        id
        name
        type
      }
      ingredients(where: { kitchen: { id: { equals: $kitchenId } } }) {
        _cursor
        id
        price
      }
    }
  }
`
