import url from 'url'

export const resizeUrl = (
  bucketLink: string,
  width: number,
  height: number,
) => {
  return bucketLink
  if (!bucketLink.match(/(s3-|s3\.)?(.*)\.amazonaws\.com\/(.*)/g))
    return bucketLink

  const urlObject = url.parse(bucketLink)
  const region = urlObject
    .hostname!.replace('s3.', '')
    ?.replace('.amazonaws.com', '')
  const bucket = urlObject.path!.split('/')?.[1]
  const key = urlObject.path!.replace(`/${bucket}`, '')
  const extension = key?.match(/\.[0-9a-z]+$/)?.[0] ?? ''
  const newBucketLink = `http://${bucket}.s3-website.${region}.amazonaws.com${key!.replace(
    extension,
    '',
  )}_${width}x${height}${extension}`

  return newBucketLink
}
