import { gql } from '@apollo/client'

import { supplierFragment } from 'api/graphql'

/* eslint-disable graphql/required-fields, graphql/template-strings */
export const getSupplierQuery = gql`
  query SupplierNode($cursor: ID!, $kitchenCursor: ID!) {
    supplierNode(cursor: $cursor) {
      ...SupplierFields
    }
  }
  ${supplierFragment}
`
/* eslint-enable graphql/required-fields, graphql/template-strings */
