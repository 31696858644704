import dayjs from 'dayjs'

import Icon from 'assets/icon.png'
import { ReactComponent as JellyLogo } from 'assets/jelly-logo-blue.svg'

import { useStyles } from './styles'

type Props = {
  updatedAt: string
}

export const PrintFooter = ({ updatedAt }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.footer}>
      <div>Last updated: {dayjs(updatedAt).format('Do MMMM YYYY')}</div>
      <div className={classes.madeWithContainer}>
        <div className={classes.madeWithText}>Made with</div>
        <img src={Icon} alt="logo" className={classes.madeWithLogo} />
        <JellyLogo className={classes.madeWithTextLogo} />
      </div>
    </div>
  )
}
