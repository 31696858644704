import { Modal } from '@mui/material'
import * as React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import { Typography } from 'components/newUi'
import { useWindowSize } from 'hooks'
import { useTheme } from 'styles/newUi'

export interface IProps {
  title?: string
  handleCancel: () => void
  show: boolean
  containerStyle?: React.CSSProperties
  actionRow?: React.ReactNode
  children?: React.ReactNode
}

export const NewModal: React.FunctionComponent<IProps> = ({
  title,
  show,
  handleCancel,
  actionRow,
  children,
  containerStyle = {},
}) => {
  const { gte: isDesktop } = useWindowSize('md')
  const { theme } = useTheme()
  if (!show) return null

  return (
    <Modal open={show}>
      <div
        style={{
          alignItems: 'center',
          bottom: 0,
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          left: 0,
          position: 'fixed',
          top: 0,
          width: '100vw',
          zIndex: 9999,
        }}
      >
        <OutsideClickHandler onOutsideClick={handleCancel}>
          <div
            style={{
              background: 'rgba(255, 255, 255, 1)',
              borderRadius: 8,
              bottom: isDesktop ? 0 : undefined,
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              margin: 'auto',
              maxHeight: '90vh',
              maxWidth: '480px',
              minWidth: 'calc(100% - 10vw)',
              overflowY: 'scroll',
              position: 'relative',
              right: isDesktop ? 0 : undefined,
              width: '90vw',
              zIndex: 9999,
              ...containerStyle,
            }}
          >
            {title && (
              <div className="bg-white pt-4 pb-2">
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 500,
                    marginBottom: theme.spacing(1),
                    textAlign: 'center',
                  }}
                >
                  {title}
                </Typography>
              </div>
            )}
            <div style={{ flex: 1 }}>{children}</div>
            {actionRow && (
              <div className="sticky bottom-0 bg-white z-10 shadow-t">
                {actionRow}
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </Modal>
  )
}
