import { ProfilePicture as JProfilePicture } from '@getjelly/jelly-ui'
import { MouseEventHandler } from 'react'

import DefaultPicture from 'assets/default-profile-picture.jpeg'

type Size = 'xs' | 'small' | 'medium' | 'large'
type SizeStyling = { classes: string }

type Props = {
  imageUrl?: string | null
  size: Size
  loading?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

const sizes: Record<Size, SizeStyling> = {
  large: { classes: '!w-40 !h-40' },
  medium: { classes: '!w-20 !h-20' },
  small: { classes: '!w-16 !h-16' },
  xs: { classes: '!w-12 !h-12 !p-0.5' },
}

export function ProfilePicture({ imageUrl, size, onClick }: Props) {
  const selectedSize = sizes[size]

  return (
    <JProfilePicture
      onClick={onClick}
      src={imageUrl || DefaultPicture}
      className={selectedSize.classes}
    />
  )
}
