import { useState, useEffect } from 'react'

// https://stackoverflow.com/questions/48048957/react-long-press-event
export function useLongPress(
  callback = () => {
    return
  },
  ms = 300,
) {
  const [startLongPress, setStartLongPress] = useState(false)

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (startLongPress) {
      timerId = setTimeout(callback, ms)
    } else {
      // @ts-ignore
      clearTimeout(timerId)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [callback, ms, startLongPress])

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseLeave: () => setStartLongPress(false),
    onMouseUp: () => setStartLongPress(false),
    onTouchEnd: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
  }
}
