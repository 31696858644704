import { makeStyles } from 'styles'
import { GREY } from 'styles/colors'

export const useStyles = makeStyles((theme) => ({
  container: {
    '&>$pill': {
      visibility: 'hidden',
    },
    '&>div': {
      color: GREY,
      userSelect: 'none',
    },
    '&>svg': {
      color: GREY,
    },
    '&[data-selected="true"]': {
      '&>$pill': {
        visibility: 'visible',
      },
      '&>div': {
        color: theme.palette.primary.main,
      },
      '&>svg': {
        color: theme.palette.primary.main,
      },
    },
    MozUserSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
    WebkitUserSelect: 'none',
    alignItems: 'center',
    display: 'flex',
    flex: 0.25,
    flexDirection: 'column',
    justifyContent: 'center',
    msUserSelect: 'none',
    outline: 'none',
    userSelect: 'none',
  },
  pill: {
    backgroundColor: theme.palette.secondary.main,
    border: 'none',
    borderRadius: 16,
    bottom: 0,
    color: 'black',
    height: 4,
    marginBottom: -4,
    padding: 0,
    position: 'relative',
    width: 32,
    // @ts-ignore
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))
