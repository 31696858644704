import { style } from 'typestyle'

import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme) => {
    const error = style({
      background: 'test',
    })

    const expanded = style({
      width: 'test',
    })

    const disabled = style({
      color: 'test',
    })

    return {
      arrow: {
        $nest: {
          [`.${expanded} &`]: {
            transform: 'translateY(-50%) rotate(180deg)',
          },
        },
        backgroundColor: theme.palette.grey[100].toHexString(),
        color: theme.palette.primary[40].toHexString(),
        display: 'flex',
        pointerEvents: 'none',
        position: 'absolute',
        right: 5,
        top: '50%',
        transform: 'translateY(-50%)',
        transition: '0.2s',
      },
      disabled,
      dropdown: {
        $nest: {
          [`.${expanded} &`]: {
            display: 'block',
          },
        },
        backgroundColor: theme.palette.grey[100].toHexString(),
        border: `2px solid ${theme.palette.secondary[100].toHexString()}`,
        borderRadius: '0 0 10px 10px',
        borderTopColor: theme.palette.grey[100].toHexString(),
        color: theme.palette.primary[100].toHexString(),
        cursor: 'pointer',
        display: 'none',
        fontSize: 16,
        position: 'absolute',
        width: 'calc(100% + 4px)',
        zIndex: 10000,
      },
      error,
      expanded,
      hideNumberControls: {
        $nest: {
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
          },
          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
          },
        },
        '-moz-appearance': 'none',
      },
      input: {
        $nest: {
          [`.${error} &`]: {
            outline: 0,
          },
          [`.${expanded} &`]: {
            borderRadius: '10px 10px 0 0',
          },
          [`.${disabled} &`]: {
            backgroundColor: theme.palette.grey[100].toHexString(),
            color: theme.palette.primary[40].toHexString(),
            cursor: 'not-allowed',
          },
          '&::placeholder': {
            color: theme.palette.primary[40].toHexString(),
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.palette.secondary[100].toHexString()}`,
            outline: 0,
          },
        },
        MozBoxShadow: 'none',
        WebkitBoxShadow: 'none',
        alignItems: 'center',
        backgroundColor: theme.palette.common.light.toHexString(),
        backgroundImage: 'none',
        border: 'none',
        borderRadius: '10px',
        boxShadow: 'none',
        color: theme.palette.primary[100].toHexString(),
        cursor: 'pointer',
        display: 'flex',
        fontSize: 16,
        height: 45,
        padding: theme.spacing(2),
        width: '100%',
      },
      inputWrap: {
        position: 'relative',
      },
      label: {
        $nest: {
          [`.${disabled} &`]: {
            color: theme.palette.primary[40].toHexString(),
          },
        },
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
      option: {
        $nest: {
          '&:hover': {
            backgroundColor: theme.palette.primary[40].toHexString(),
          },
        },
        borderRadius: 5,
        padding: theme.spacing(2),
      },
      optionWrap: {
        maxHeight: 300,
        overflowY: 'scroll',
      },
      placeHolder: {
        color: theme.palette.primary[40].toHexString(),
      },
      startAdornment: {
        color: theme.palette.primary[40].toHexString(),
        left: 10,
        padding: 3,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      },
      wrap: {
        $nest: {
          '& select': {
            display: 'none',
          },
        },
      },
    }
  })
