import { AddNoteModal } from '@getjelly/jelly-ui'
import { format } from 'date-fns'

import { InvoiceNote } from 'api'

type Props = {
  id?: string
  handleAddNote: (note: string) => void
  showModal: boolean
  setShowModal: (show: boolean) => void
  notes: InvoiceNote[]
}

export function NotesModalForm({
  id,
  handleAddNote,
  showModal,
  setShowModal,
  notes,
}: Props) {
  return (
    <AddNoteModal
      title={id ? `Notes on ${id}` : `Notes`}
      open={showModal}
      onClose={() => setShowModal(false)}
      saveNote={async ({ note }) => handleAddNote(note)}
      notes={notes}
      titleExtractor={(note) =>
        `${note.user.firstName} ${note.user.lastName} on ${format(
          note.createdAt,
          'E dd/MM/yyyy',
        )} at ${format(note.createdAt, 'hh:mm a')}`
      }
      bodyExtractor={(note) => note.content}
      typeExtractor={() => 'default'}
    />
  )
}
