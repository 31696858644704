import { Modal, Typography } from '@getjelly/jelly-ui'
import { IconCircleCheckFilled, IconHelpCircle } from '@tabler/icons-react'
import { ReactNode, useState } from 'react'

type Info = {
  title?: string
  body: ReactNode
}

type Props = {
  title: string
  subtitle?: ReactNode
  info?: Info
}

export function CheckedItem({ title, subtitle, info }: Props) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="space-y-6">
          <Typography style="h6" className="text-primary-900">
            {info?.title || title}
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            {info?.body}
          </Typography>
        </div>
      </Modal>

      <div
        className="py-4 border-b border-primary-200 flex justify-between items-center cursor-pointer"
        onClick={() => info && setShowModal(true)}
      >
        <div className="space-x-2 flex items-center">
          <IconCircleCheckFilled className="text-success-400 shrink-0" />

          <div>
            <Typography style="subtitle1" className="text-primary-900">
              {title}
            </Typography>

            {subtitle && (
              <Typography style="subtitle2" className="text-primary-400">
                {subtitle}
              </Typography>
            )}
          </div>
        </div>

        {info && (
          <div className="shrink-0">
            <IconHelpCircle className="text-secondary-400" />
          </div>
        )}
      </div>
    </>
  )
}
