import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme, isDesktop) => ({
    detailContainer: {
      // display: isDesktop ? 'flex' : undefined,
      // flexDirection: isDesktop ? 'row-reverse' : undefined,
      justifyContent: isDesktop ? 'space-between' : undefined,
    },
    error: {
      color: `${theme.palette.error[100].toHexString()} !important`,
    },
    icon: {
      color: theme.palette.primary[60].toHexString(),
      marginRight: 20,
    },
    item: {
      alignItems: 'center',
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
      cursor: 'pointer',
      display: 'flex',
      flex: 1,
      height: 90,
      justifyContent: 'space-between',
      paddingLeft: 20,
      paddingRight: 20,
      width: '100%',
    },
    itemContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    photoBox: {
      alignItems: 'center',
      backgroundColor: '#e0e0e0',
      display: 'flex',
      flexDirection: 'column',
      fontSize: 10,
      height: 133,
      justifyContent: 'center',
      margin: 0,
      width: '100%',
    },
    photoContainer: {
      // width: isDesktop ? '25%' : undefined,
    },
    rowContainer: {
      // width: isDesktop ? '70%' : undefined,
    },
    subtext: {
      color: 'rgba(165, 172, 183, 1)',
      display: 'flex',
      flex: 1,
      fontSize: 14,
    },
    title: {
      display: 'flex',
      flex: 1,
      fontSize: 20,
      fontWeight: 500,
    },

    warning: {
      boxSizing: 'border-box',
      color: `${theme.palette.secondary[100].toHexString()} !important`,
    },
  }))
