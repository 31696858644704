import { gql } from '@apollo/client'

export const kitchenInvoiceRule = gql`
  query kitchenInvoiceRule($kitchenId: Int!) {
    kitchenInvoiceRule(kitchenId: $kitchenId) {
      id
      approvalThresholdValue
      approveAllInvoices
    }
  }
`

export const createOneKitchenInvoiceRule = gql`
  mutation createOneKitchenInvoiceRule($data: CreateKitchenInvoiceRuleInput!) {
    createOneKitchenInvoiceRule(data: $data) {
      id
      approveAllInvoices
      approvalThresholdValue
    }
  }
`

export const updateOneKitchenInvoiceRule = gql`
  mutation updateOneKitchenInvoiceRule($data: UpdateKitchenInvoiceRuleInput!) {
    updateOneKitchenInvoiceRule(data: $data) {
      id
      approveAllInvoices
      approvalThresholdValue
    }
  }
`
