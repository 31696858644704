import { ReactNode, CSSProperties } from 'react'

import { Typography } from 'components/newUi/Typography'

type Props = {
  onClick: () => void
  style?: CSSProperties
  children: ReactNode
}

export const TextButton = ({ onClick, style, children }: Props) => (
  //  eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div onClick={onClick} style={{ cursor: 'pointer', height: '100%' }}>
    <Typography
      variant="button"
      style={{
        alignItems: 'center',
        display: 'flex',
        ...style,
      }}
    >
      {children}
    </Typography>
  </div>
)
