import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { useParams, useNavigate } from 'react-router-dom'

import { PurchaseOrderEntry, Query, QueryPurchaseOrderNodeArgs } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import { NewLayout, Typography } from 'components/newUi'
import { SectionTitle } from 'components/newUi/VirtualizedSectionList/SectionTitle'
import { routes } from 'routes/Paths'
import { useTheme } from 'styles/newUi'

import { getPurchaseOrderQuery } from './graphql'

type Props = {
  cursor?: string
  open?: boolean
  close?: () => void
}

const ROW_HEIGHT = 76

export const OrderHistoryItem = ({ cursor }: Props) => {
  const { id } = useParams()

  const combinedCursor = cursor ?? id
  const { theme } = useTheme()
  const { selectedKitchen } = useKitchen()

  const { data, loading } = useQuery<
    { purchaseOrderNode: Query['purchaseOrderNode'] },
    QueryPurchaseOrderNodeArgs & { entryCursor?: string; kitchenId: number }
  >(getPurchaseOrderQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !combinedCursor || !selectedKitchen?.id,
    variables: {
      cursor: combinedCursor as string,
      kitchenId: selectedKitchen?.id as number,
    },
  })

  const rowData = data?.purchaseOrderNode
  const currentYear = dayjs().year()

  return (
    <>
      <NewLayout hideMenu title="View Order" subtitle="Ordering" />
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div
            style={{
              alignItems: 'center',
              backgroundColor: theme.palette.tertiary[50].toHexString(),
              borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
              display: 'grid',
              gap: '0 5px',
              gridTemplateColumns: '1fr 0fr',
              height: ROW_HEIGHT,
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            <div style={{ overflow: 'hidden' }}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 500,
                  marginBottom: 4,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textTransform: 'capitalize',
                  whiteSpace: 'nowrap',
                }}
              >
                {rowData?.supplier.name.toLowerCase()}
              </Typography>
              <Typography
                variant="body2"
                style={{ color: theme.palette.primary[60].toHexString() }}
              >
                {`#${rowData?.number}`}
              </Typography>
            </div>
            <div
              style={{
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 500, marginBottom: 4 }}
              >
                {dayjs(rowData?.createdAt).format(
                  dayjs(rowData?.createdAt).year() === currentYear
                    ? 'D MMM'
                    : 'D MMM YYYY',
                )}
              </Typography>
              <Typography
                variant="body2"
                style={{ color: theme.palette.primary[60].toHexString() }}
              >
                {`${rowData?.createdBy.firstName} ${
                  rowData?.createdBy.lastName
                } - ${dayjs(rowData?.createdAt).format('HH:mm')}`}
              </Typography>
            </div>
          </div>
          <SectionTitle
            HEADER_HEIGHT={33}
            label={`${rowData?.entryList.pageInfo.totalCount} products`}
          />
          {rowData?.entryList.nodes.map((entry) => (
            <Item entry={entry} key={entry._cursor} />
          ))}
          {data?.purchaseOrderNode?.notes && (
            <div>
              <SectionTitle
                HEADER_HEIGHT={33}
                label="Additional products and notes"
              />
              <Typography
                variant="subtitle1"
                style={{
                  backgroundColor: 'white',
                  fontWeight: 500,
                  padding: theme.spacing(2),
                }}
              >
                {data?.purchaseOrderNode?.notes}
              </Typography>
            </div>
          )}
          {data?.purchaseOrderNode?.deliveryDate && (
            <div>
              <SectionTitle
                HEADER_HEIGHT={33}
                label="Preferred Delivery Date"
              />
              <Typography
                variant="subtitle1"
                style={{
                  backgroundColor: 'white',
                  fontWeight: 500,
                  padding: theme.spacing(2),
                }}
              >
                {dayjs(data?.purchaseOrderNode?.deliveryDate)
                  .format('dddd Do MMMM')
                  .toUpperCase()}
              </Typography>
            </div>
          )}
          <div>
            <SectionTitle HEADER_HEIGHT={33} label="Delivery Address" />
            <div
              style={{
                backgroundColor: 'white',
                padding: theme.spacing(2),
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 700,
                }}
              >
                {data?.purchaseOrderNode?.deliverySiteName}
              </Typography>
              {data?.purchaseOrderNode?.deliverySiteAddress
                .split(',')
                .map((string, index) => (
                  <Typography
                    key={`${string}-${index}`}
                    variant="subtitle1"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {string}
                  </Typography>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const Item = ({ entry }: any) => {
  const navigate = useNavigate()
  const rowData: PurchaseOrderEntry = entry
  const { theme } = useTheme()
  const packSizeText =
    rowData.product.packSize !== 1 ? `${rowData.packSize} x ` : ''
  const unitName = rowData.unit.name

  const unitString =
    rowData.unit.name === 'box' || rowData.unit.name === 'unit'
      ? unitName
      : `${packSizeText}${rowData.unitValue}${unitName}`

  return (
    // eslint-disable-next-line
    <div
      onClick={() =>
        navigate(
          `${routes.Product}/${rowData.product.ingredientList.nodes[0]._cursor}`,
          { replace: true },
        )
      }
      style={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
        cursor: 'pointer',
        display: 'grid',
        gap: '0 5px',
        gridTemplateColumns: '1fr 0fr',
        height: ROW_HEIGHT,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        whiteSpace: 'nowrap',
        width: '100%',
      }}
    >
      <div style={{ overflow: 'hidden' }}>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 500,
            marginBottom: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          {rowData.product.name.toLowerCase()}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: theme.palette.primary[60].toHexString() }}
        >
          {unitString}
        </Typography>
      </div>
      <div
        style={{
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" style={{ fontWeight: 500, marginBottom: 4 }}>
          {`${rowData.quantity}`}
        </Typography>
      </div>
    </div>
  )
}
