import { Typography } from '@getjelly/jelly-ui'
import { CSSProperties, ReactNode } from 'react'

import { InvoiceNote } from 'api'
import { AutomatedBadge } from 'screens/Spending/Invoices/badges/AutomatedBadge'
import { NotesBadge } from 'screens/Spending/Invoices/badges/NotesBadge'

export interface Props {
  automatic: boolean
  images: string[]
  primaryText: string
  subtitle?: string
  secondaryText: string | ReactNode
  action: ReactNode
  notes: InvoiceNote[]
  pendingInvoiceId: number
  invoiceNumber?: string
  onClick?: () => void
  style: CSSProperties
}

export function InvoiceRowItemContainer({
  style,
  automatic,
  primaryText,
  subtitle,
  secondaryText,
  action,
  onClick,
  notes,
  pendingInvoiceId,
  invoiceNumber,
}: Props) {
  const base = 'border-b border-primary-100 cursor-pointer pl-3 pr-2 relative'

  return (
    <div
      className={`${base}`}
      style={style}
      onClick={(e) => {
        e.stopPropagation()
        onClick?.()
      }}
    >
      <div className="h-full flex flex-col justify-center items-start space-y-1">
        <div className="w-full">
          <Typography
            style="subtitle1"
            className="text-primary-800 line-clamp-1 capitalize pr-20"
          >
            {primaryText}
          </Typography>

          {subtitle && (
            <Typography
              style="body2"
              className="text-primary-900 line-clamp-1 pr-20"
            >
              {subtitle}
            </Typography>
          )}

          <Typography
            style="body2"
            className="text-primary-400 whitespace-nowrap line-clamp-1"
          >
            {secondaryText}
          </Typography>
        </div>

        <div className="flex space-x-1">
          {automatic && <AutomatedBadge />}
          {notes.length > 0 && (
            <NotesBadge
              notes={notes}
              pendingInvoiceId={pendingInvoiceId}
              invoiceNumber={invoiceNumber}
            />
          )}
        </div>
      </div>

      <div className="h-full absolute top-0 right-0 flex items-center pr-2">
        <div>{action}</div>
      </div>
    </div>
  )
}
