import { Kitchen, User } from '../../../api'
import { NewButton, Typography } from '../../../components/newUi'
import { NewModal } from '../../Create/NewModal'

export interface IProps {
  handleCancel: () => void
  handleConfirm: () => void
  show: boolean
  user?: User
  kitchen?: Kitchen
}

export function DeleteTeamModal({
  show,
  handleCancel,
  handleConfirm,
  user,
  kitchen,
}: IProps) {
  return (
    <NewModal handleCancel={handleCancel} show={show}>
      <div className="flex flex-col justify-center items-center text-center space-y-4 py-8 p-3">
        <Typography variant="body1">
          Remove {user?.firstName} {user?.lastName} from {kitchen?.name}?
        </Typography>
      </div>

      <div className="flex p-3 space-x-3 border-t">
        <div className="w-full">
          <NewButton
            noFill
            style={{ margin: 0, padding: '0.75rem', width: '100%' }}
            onClick={handleCancel}
            text="NO"
          />
        </div>

        <div className="w-full">
          <NewButton
            noFill
            style={{ margin: 0, padding: '0.75rem', width: '100%' }}
            color="orange"
            onClick={handleConfirm}
            text="YES, REMOVE"
          />
        </div>
      </div>
    </NewModal>
  )
}
