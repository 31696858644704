import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  infoContainer: {
    alignItems: 'center',
    backgroundColor: `#FFFFFF`,
    borderRadius: 3,
    display: 'flex',
    height: 80,
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    top: 0,
  },
  invoiceDetails: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'row-reverse',
      fontSize: 14,
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      textAlign: 'unset',
      width: '100%',
    },
  },
  itemInnerMobile: {
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  messageContainer: {
    alignItems: 'center',
    display: 'flex',
    height: 80,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  mobileEntry: {
    '& > div ': {
      alignSelf: 'center',
      flex: 1,
      marginRight: 6,
    },
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    fontSize: 14,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 8,
  },
  mobileHeader: {
    '& > div ': {
      flex: 1,
      fontWeight: 600,
      marginRight: 6,
    },
    display: 'flex',
    flex: 1,
    flexDirection: 'row',

    marginTop: 8,
  },
  mobileInfo: {
    color: theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  mobileItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  mobileList: {
    '& $mobileEntry:nth-child(2n+3)': {
      backgroundColor: theme.palette.grey[300],
    },
    height: 'calc(100vh - 250px)',
    maxWidth: 'calc(100vw - 8)',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    paddingLeft: 5,
    paddingRight: 5,
  },
  notesContainer: {
    alignItems: 'flex-start',
    backgroundColor: theme.palette.grey[200],
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    minHeight: 80,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.common.white,
      borderTop: `1px dotted ${theme.palette.common.black}`,
      height: 102,
      marginBottom: 'unset',
      marginTop: 'unset',
      minHeight: 'unset',
      overflow: 'scroll',
      padding: theme.spacing(1),
    },
  },
  rowPadding: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  supplierContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  supplierName: {
    fontSize: 24,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontStyle: 'unset',
      fontWeight: 'bold',
      textTransform: 'lowercase',
      width: '100%',
    },
  },
  supplierNote: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  table: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderCollapse: 'separate',
    borderRadius: 3,
  },
  tableContainer: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 3,
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableHeader: {},
  title: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  // eslint-disable-next-line
  grey: {
    backgroundColor: theme.palette.grey[200],
  },
}))
