import { useQuery } from '@apollo/client'
import { DropdownInput, Typography } from '@getjelly/jelly-ui'
import { useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { CategoryContext } from './CategoryContext'
import { kitchenToPosProductsCountQuery } from './graphql'

import { Query, QueryKitchenToPosProductsArgs } from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import {
  NewLayout,
  ScrollMenu,
  ScrollMenuItem,
} from '../../../components/newUi'
import { usePathname } from '../../../hooks'
import { routes } from '../../../routes/Paths'

export type CategoryFragment = {
  id: number
  externalId: string
  name: string
  linkedCount: number
  unlinkedCount: number
}

const allCategory = {
  externalId: 'all',
  id: 0,
  linkedCount: 0,
  name: 'All',
  unlinkedCount: 0,
}

export function ImportLayout() {
  const navigate = useNavigate()
  const pathname = usePathname()
  const { selectedKitchen } = useKitchen()

  const [posCategory, setPosCategory] = useState<CategoryFragment>(allCategory)

  const { data, loading } = useQuery<
    {
      linkedCount: Query['kitchenToPosProductsCount']
      unlinkedCount: Query['kitchenToPosProductsCount']
      kitchenToPosCategories: CategoryFragment[]
    },
    QueryKitchenToPosProductsArgs
  >(kitchenToPosProductsCountQuery, {
    variables: {
      kitchenId: selectedKitchen?.id || 0,
    },
  })

  const counts = useMemo(() => {
    if (data?.unlinkedCount === undefined || data?.linkedCount === undefined) {
      return { linked: 0, unlinked: 0 }
    }

    return { linked: data.linkedCount, unlinked: data.unlinkedCount }
  }, [data])

  const posCategories: CategoryFragment[] = useMemo(() => {
    if (data?.kitchenToPosCategories === undefined) {
      return [allCategory]
    }

    allCategory.unlinkedCount = data.kitchenToPosCategories.reduce(
      (acc, koc) => acc + koc.unlinkedCount,
      0,
    )
    allCategory.linkedCount = data.kitchenToPosCategories.reduce(
      (acc, koc) => acc + koc.linkedCount,
      0,
    )

    const sortedCategories = [allCategory, ...data.kitchenToPosCategories].sort(
      (a, b) => {
        if (a.externalId === 'all') return -1
        if (a.externalId === 'other') return 1
        return a.name > b.name ? 1 : -1
      },
    )

    const current = sortedCategories.find((c) => c.id === posCategory?.id)

    if (!posCategory || !current) {
      setPosCategory(sortedCategories[0] || null)
    } else {
      setPosCategory(current)
    }

    return sortedCategories
  }, [posCategory, data])

  const isEmpty = useMemo(() => {
    const [all] = posCategories

    if (
      pathname === routes.Kitchen + routes.Import + routes.Linked &&
      all.linkedCount === 0
    ) {
      return true
    }

    return (
      pathname === routes.Kitchen + routes.Import + routes.Unlinked &&
      all.unlinkedCount === 0
    )
  }, [pathname, posCategories])

  return (
    <>
      <NewLayout
        title="Import from POS"
        subtitle="Kitchen"
        onBack={() => navigate(-1)}
      />

      <div className="space-y-1">
        <ScrollMenu>
          <ScrollMenuItem
            text={`Unlinked (${counts.unlinked})`}
            selected={
              pathname === routes.Kitchen + routes.Import + routes.Unlinked
            }
            fullWidth={true}
            route={routes.Kitchen + routes.Import + routes.Unlinked}
          />

          <ScrollMenuItem
            text={`Linked (${counts.linked})`}
            selected={
              pathname === routes.Kitchen + routes.Import + routes.Linked
            }
            fullWidth={true}
            route={routes.Kitchen + routes.Import + routes.Linked}
          />
        </ScrollMenu>
      </div>

      {isEmpty ? (
        <>
          {pathname === routes.Kitchen + routes.Import + routes.Linked ? (
            <div className="py-6 px-8 text-center space-y-2">
              <Typography style="subtitle1" className="text-primary-900">
                You have not linked any items yet
              </Typography>

              <Typography style="subtitle2" className="text-primary-600">
                Here is where you will be able to see POS items that have been
                linked to a Jelly dish!
              </Typography>
            </div>
          ) : (
            <div className="py-6 px-8 text-center space-y-2">
              <Typography style="subtitle1" className="text-primary-900">
                No more items to link
              </Typography>

              <Typography style="subtitle2" className="text-primary-600">
                It seems you have gone through all the pending items that
                required your approval, you are a star!
              </Typography>
            </div>
          )}
        </>
      ) : (
        <div className="p-4 border-b border-gray-200 space-y-2">
          <Typography style="caption" className="text-primary-900">
            Category
          </Typography>

          <DropdownInput
            placeholder="Filter by category"
            value={posCategory}
            options={posCategories}
            optionToId={(o) => o.id}
            optionToLabel={(o) => {
              if (pathname === routes.Kitchen + routes.Import + routes.Linked) {
                return `${o.name} (${o.linkedCount})`
              }

              return `${o.name} (${o.unlinkedCount})`
            }}
            onChange={(c) => c && setPosCategory(c)}
            loading={loading}
          />
        </div>
      )}

      <CategoryContext.Provider value={posCategory}>
        <Outlet />
      </CategoryContext.Provider>
    </>
  )
}
