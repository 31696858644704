import { resizeUrl } from 'utils'

type Props = {
  documents: string[]
}

export function Images({ documents }: Props) {
  return (
    <div className="flex justify-center">
      <div className="grid grid-cols-2 gap-2.5">
        {documents?.map((document) => (
          <div
            key={document}
            className="relative w-36 h-36 border-2 border-white shadow-medium"
          >
            {document.toLowerCase().includes('.pdf') ? (
              <>
                <embed
                  src={document}
                  type="application/pdf"
                  className="w-full h-full object-cover"
                />

                <div
                  className="absolute top-0 left-0 w-full h-full cursor-pointer z-10"
                  onClick={() => window.open(document, '_blank')}
                />
              </>
            ) : (
              <div
                className="cursor-pointer w-full h-full"
                onClick={() => window.open(document, '_blank')}
              >
                <img
                  alt="Invoice Document"
                  src={resizeUrl(document, 256, 256)}
                  className="h-full w-full object-cover"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
