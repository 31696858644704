import { useEffect, useState } from 'react'

import { useVersionChecker } from '../../../../hooks/useVersionChecker'
import { AlertWithIcon } from '../../Alert'
import { Typography } from '../../Typography'

export function UpdateAlert() {
  const [showSuccessBanner, setShowSuccessBanner] = useState(false)
  const { updateRequired, doUpdate } = useVersionChecker()

  useEffect(() => {
    if (localStorage.getItem('updateSuccess') === 'true') {
      setShowSuccessBanner(true)
      setTimeout(() => localStorage.removeItem('updateSuccess'), 1000)
    }
  }, [])

  if (!updateRequired && !showSuccessBanner) {
    return <></>
  }

  return showSuccessBanner ? (
    <AlertWithIcon iconName="notifications" type="success">
      <Typography variant="body1" style={{ fontWeight: 400 }}>
        You are now on the latest version. 🥳
      </Typography>
    </AlertWithIcon>
  ) : (
    <AlertWithIcon onClick={doUpdate} iconName="warning" type="error">
      <Typography
        variant="body1"
        style={{ cursor: 'pointer', fontWeight: 600 }}
      >
        New updates on Jelly available! Click here to get the latest version.
      </Typography>
    </AlertWithIcon>
  )
}
