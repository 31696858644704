import { useMutation } from '@apollo/client'
import { captureException } from '@sentry/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { postVerifyEmailMutation } from 'api/graphql'
import { routes } from 'routes/Paths'
import { selectToken } from 'store/auth'

import { errorToast, successToast } from '../../components/toasts'

export const VerifyEmail = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const getParams = new URLSearchParams(location.search) || null
  const emailVerificationCode = getParams.get('emailVerificationCode')
  const [verify] = useMutation(postVerifyEmailMutation)
  const token = useSelector(selectToken())

  useEffect(() => {
    verify({
      variables: {
        emailVerificationCode,
      },
    })
      .then(() => {
        if (token) {
          navigate(`${routes.Dashboard}?verifiedEmail=true`)
        } else {
          successToast('Email verified')
          navigate(routes.SignIn)
        }
      })
      .catch((error) => {
        captureException(error)
        errorToast('Something went wrong')
        navigate(token ? routes.Dashboard : routes.SignIn)
      })
  }, [])

  return null
}
