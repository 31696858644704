import { isEmpty, isNil } from 'ramda'

import {
  InvoiceWithPendingInvoiceFragment,
  PendingInvoiceBaseFragment,
} from 'api'

type DeliveryDay = {
  cutoff?: string
  cutoff2?: string
  deliver?: boolean
}

export type DeliveryDays = {
  mon?: DeliveryDay
  tue?: DeliveryDay
  wed?: DeliveryDay
  thu?: DeliveryDay
  fri?: DeliveryDay
  sat?: DeliveryDay
  sun?: DeliveryDay
}

export const dayValue = {
  fri: 5,
  mon: 1,
  sat: 6,
  sun: 0,
  thu: 4,
  tue: 2,
  wed: 3,
}

export const dayMap: { [key: number]: string } = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
}

export const deliveryDaysFromTo = (
  deliveryDays?: DeliveryDays,
):
  | {
      days: {
        deliver: boolean
        day: string
      }[]
      cutoffs: string
    }
  | undefined => {
  if (isNil(deliveryDays) || isEmpty(deliveryDays)) return

  const daysDelivering: {
    deliver: boolean
    day: string
  }[] = []

  Object.keys(deliveryDays).forEach((key) => {
    daysDelivering.push({
      day: key,
      deliver: deliveryDays[key as keyof DeliveryDays]?.deliver ?? true,
    })
  })

  const sortedData = daysDelivering.sort((a, b) => {
    return (
      dayValue[a.day as keyof DeliveryDays] -
      dayValue[b.day as keyof DeliveryDays]
    )
  })

  return {
    cutoffs: `${
      deliveryDays[sortedData[0].day as keyof DeliveryDays]?.cutoff ?? ''
    } ${deliveryDays[sortedData[0].day as keyof DeliveryDays]?.cutoff2 ?? ''}`,
    days: daysDelivering,
  }
}

export const dateDiff = (dateA: Date, dateB: Date) =>
  new Date(dateA).getTime() - new Date(dateB).getTime()

export const getPendingInvoiceDate = (
  pendingInvoice: PendingInvoiceBaseFragment,
) => {
  // invoices have invoice date only after they are processed
  // fallback to pending invoice creation date (upload date)
  return pendingInvoice?.invoice?.invoiceDate ?? pendingInvoice.createdAt
}

export const getInvoiceDate = (
  invoice: InvoiceWithPendingInvoiceFragment,
): Date | undefined | null => {
  // invoices have invoice date only after they are processed
  // fallback to pending invoice creation date (upload date)
  return invoice?.invoiceDate ?? invoice?.pendingInvoice?.createdAt
}

export function toUTCStartOfDay(date: Date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}
