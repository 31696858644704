import { Button } from '@getjelly/jelly-ui'

import { Typography } from 'components/newUi'

interface ManualAddGuideProps {
  manualAddHandler: () => void
}

export function ManualAddGuide({ manualAddHandler }: ManualAddGuideProps) {
  return (
    <>
      <div className="px-4">
        <Typography style={{ fontSize: 18 }} variant="h5">
          Upload invoices manually from your device
        </Typography>
      </div>

      <Button label="Upload" onClick={manualAddHandler} className="w-full" />
    </>
  )
}
