import { Button } from '@getjelly/jelly-ui'
import { IconLock } from '@tabler/icons-react'
import { useEffect, useState, useRef, ReactNode } from 'react'
import { useNavigate, useBlocker } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useKitchen } from 'app/contexts/SelectedKitchen'
import NoImage from 'assets/recipe-no-image.jpg'
import { KitchenChange, Typography, Loader } from 'components'
import {
  LeaveConfirmationModal,
  Icon,
  NewButton,
  NewLayout as Layout,
} from 'components/newUi'
import { useWindowSize } from 'hooks'
import { useLongPress } from 'hooks/useLongPress'
import { routes } from 'routes/Paths'
import { Header } from 'screens/Invoices/Header'
import { pannelBorderColor, GREY2, lightBlue } from 'styles/colors'
import { useTheme } from 'styles/newUi'
import { logEvent } from 'utils'

import { InvoiceUploader, Upload } from './InvoiceUploader'
import { useStyles } from './styles'

import { errorToast, successToast } from '../../components/toasts'

export const Add = () => {
  const { selectedKitchen } = useKitchen()
  const toastId = useRef<string | number | undefined>(undefined)
  const [block, setBlock] = useState(false)
  const [uploader, setUploader] = useState<InvoiceUploader | null>(null)
  const { gte: isDesktop } = useWindowSize('md')
  const { theme } = useTheme()
  const [uploadStatuses, setUploadStatuses] = useState<
    {
      identifier: string
      status: Upload['status']
    }[]
  >([])

  useEffect(() => {
    const uploader = new InvoiceUploader((uploads: Upload[]) => {
      setUploadStatuses((prevState) => {
        const identifiers = uploads.map((u) => u.identifier)
        const old = prevState.filter(
          ({ identifier }) => !identifiers.includes(identifier),
        )

        return old.concat(uploads).reverse()
      })
    }, selectedKitchen?.id)

    setUploader(uploader)
  }, [selectedKitchen])

  useEffect(() => {
    const inProgress = !!uploadStatuses.find((u) => u.status !== 'complete')

    setBlock(inProgress)
  }, [uploadStatuses])

  const navigate = useNavigate()

  const handleChange = async (files: File[]) => {
    await processImages(files)
  }

  const handleRetry = (identifier: string) => {
    if (!uploader) {
      console.error('Invoice uploader not initialised.')
      return
    }

    uploader.retry(identifier)
  }

  const processImages = async (files: File[]) => {
    if (!uploader) {
      console.error('Invoice uploader not initialised.')
      return
    }

    uploader.addFiles(files)
  }

  useBlocker(
    ({ retry }) =>
      toast.warn(
        <div
          style={{
            alignItems: 'center',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: isDesktop ? '100vw' : undefined,
          }}
        >
          Some invoices didn&#39;t upload, are you sure you want to leave?
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              marginTop: 8,
            }}
          >
            <NewButton
              onClick={() => {
                retry()
                toast.dismiss(toastId.current)
              }}
              text="Ok"
            />
            <NewButton
              onClick={() => {
                toast.dismiss(toastId.current)
              }}
              text="Cancel"
            />
          </div>
        </div>,
        {
          autoClose: false,
          closeOnClick: false,
          draggable: true,
          toastId: 'warning-toast',
        },
      ),
    block,
  )

  return (
    <>
      <LeaveConfirmationModal
        action="leave"
        title="You have unsaved changes, are you sure you want to leave the screen?"
        text={`${
          uploadStatuses.filter((x) => x.status !== 'complete').length
        } Invoice${
          uploadStatuses.filter((x) => x.status !== 'complete').length > 1
            ? 's'
            : ''
        } won't be added`}
        onConfirm={() => {
          setUploadStatuses([])
          window.history.back()
        }}
        show={uploadStatuses.length > 0 && block}
      />
      <Layout
        title="Add Invoices"
        subtitle="Spending"
        hideMenu
        bottomContent={
          <>
            {uploadStatuses.length > 0 && (
              <div
                style={{
                  alignContent: 'center',
                  backgroundColor: 'white',
                  borderTop: `1px solid ${GREY2}`,
                  bottom: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  left: 0,
                  padding: 15,
                  width: '100%',
                }}
              >
                <NewButton
                  noFill
                  disabled={!uploadStatuses.length}
                  style={{ alignItems: 'center', padding: 10, width: '50%' }}
                  onClick={() => {
                    logEvent('tap_button', {
                      event_category: 'upload',
                      event_label: 'more',
                    })
                    document.getElementById('files')?.click()
                  }}
                  text="Select More"
                  rightAdornment={
                    <Icon
                      iconName="publish"
                      style={{ marginLeft: 4, marginRight: -4 }}
                    />
                  }
                />

                <NewButton
                  disabled={!uploadStatuses.length}
                  style={{
                    padding: 10,
                    whiteSpace: 'nowrap',
                    width: '50%',
                  }}
                  onClick={async () => {
                    logEvent('tap_button', {
                      event_category: 'upload',
                      event_label: 'done',
                    })

                    try {
                      const itemCount = uploadStatuses.filter(
                        (upload) => upload.status === 'complete',
                      ).length

                      const descriptor = itemCount > 1 ? 'files' : 'file'

                      successToast(
                        `${itemCount} ${descriptor} uploaded. We’ll process these overnight and ready for you first thing in the morning!`,
                      )

                      navigate(
                        `${routes.Spending}${routes.Invoice}${routes.Pending}`,
                      )
                    } catch (ex) {
                      setBlock(true)
                      errorToast((ex as Error).message)
                    }
                  }}
                  text="Done"
                  rightAdornment={
                    <Icon
                      iconName="chevronRight"
                      style={{ marginLeft: 4, marginRight: -4 }}
                    />
                  }
                />
              </div>
            )}
          </>
        }
      />
      {uploadStatuses.length === 0 && (
        <div className="flex items-center flex-col flex-1 p-3 space-y-3">
          <div className="py-6">
            <KitchenChange
              redirect={`${routes.Spending}${routes.Invoice}${routes.Add}`}
            />
          </div>

          <div
            style={{
              alignItems: 'center',
              backgroundColor: theme.palette.common.light.toHexString(),
              borderRadius: 4,
              boxShadow: theme.elevation[1],
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                padding: theme.spacing(2),
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: 16,
                }}
              >
                All your products and prices will be magically imported into
                Jelly overnight!
              </Typography>
            </div>
            {isDesktop ? (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => ''}
                onDragOver={(e) => {
                  e.preventDefault()
                }}
                onDrop={async (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  const files = e.dataTransfer.files
                  const f = []
                  for (let i = 0; i < files?.length; i++) {
                    const file = files.item(i)
                    if (!file) continue
                    if (
                      file.type !== 'application/pdf' &&
                      !file.type.includes('image/')
                    ) {
                      errorToast(`Invalid file type "${file.type}"`)
                      return
                    }
                    f.push(file)
                  }
                  await handleChange(f)
                }}
                style={{
                  border: `2px solid ${pannelBorderColor}`,
                  borderRadius: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  margin: 20,
                  padding: '50px 150px',
                  textAlign: 'center',
                }}
              >
                <Typography>Drag and drop files here</Typography>
                <Typography>or</Typography>
                <NewButton
                  text="Add files or photos"
                  onClick={() => {
                    logEvent('tap_button', {
                      event_category: 'upload',
                      event_label: 'select',
                    })
                    document.getElementById('files')?.click()
                  }}
                />
              </div>
            ) : (
              <>
                <div className="border-t border-primary-100 py-4 w-full px-3 flex justify-center">
                  <Button
                    onClick={() => {
                      logEvent('tap_button', {
                        event_category: 'upload',
                        event_label: 'select',
                      })
                      document.getElementById('files')?.click()
                    }}
                    label="Add Files Or Photos"
                    className="w-full"
                  />
                </div>
              </>
            )}
          </div>

          <div className="flex items-center space-x-1 p-4">
            <IconLock />

            <Typography
              style={{
                color: theme.palette.primary[60].toHexString(),
                fontSize: 12,
                fontWeight: 500,
              }}
              variant="body1"
            >
              All your data always stays secure in your account
            </Typography>
          </div>
        </div>
      )}

      {uploadStatuses.length > 0 && (
        <Header>{uploadStatuses.length} files uploading</Header>
      )}
      <div>
        {uploadStatuses.map((upload, index) => {
          return (
            <InvoiceRow
              image={upload}
              key={index}
              index={index}
              retryUpload={() => {
                handleRetry(upload.identifier)
              }}
            />
          )
        })}
      </div>
      <input
        onChange={(e) => {
          // @ts-ignore
          handleChange([...e.target.files])
        }}
        multiple
        id="files"
        type="file"
        accept="image/*,application/pdf"
        className=""
        style={{ display: 'none' }}
      />
    </>
  )
}

export interface IProps {
  message: string
}

const Row: React.FC<{ index?: number; children?: ReactNode }> = ({
  index,
  children,
}) => {
  return (
    <div
      key={index}
      style={{
        alignItems: 'center',
        borderBottomColor: 'rgba(233, 234, 237, 1)',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        display: 'flex',
        flexDirection: 'row',
        height: 66,
        justifyContent: 'space-between',
        paddingLeft: 16,
        width: '100%',
      }}
    >
      {children}
    </div>
  )
}

const InvoiceRow = ({
  image,
  index,
  retryUpload,
}: {
  image: {
    identifier: string
    status: Upload['status']
    file?: File
  }
  index: number
  retryUpload: (file: File) => void
}) => {
  const { theme } = useTheme()
  return (
    <Row index={index}>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: '95%',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex' }}>
          {image.status === 'processing' ? (
            <div style={{ marginRight: 10 }}>
              <Loader size={24} />
            </div>
          ) : null}
          {image.status === 'failed' ? (
            <div style={{ marginRight: 10 }}>
              <Icon
                style={{ color: theme.palette.error[100].toHexString() }}
                iconName="warning"
              />
            </div>
          ) : null}
          {image.status === 'complete' ? (
            <div style={{ marginRight: 10 }}>
              <Icon
                style={{ color: theme.palette.success[100].toHexString() }}
                iconName="tick"
              />
            </div>
          ) : null}
          <div
            style={{
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              display: '-webkit-box',
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: 'calc(100%)',
              }}
            >
              {image.identifier}
            </div>
            <div>
              {image.status === 'failed' ? (
                <div style={{ color: theme.palette.error[100].toHexString() }}>
                  Failed
                </div>
              ) : null}
              {image.status === 'complete' ? (
                <div
                  style={{ color: theme.palette.success[100].toHexString() }}
                >
                  Uploaded
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {image.status === 'failed' ? (
          <>
            <Typography
              onClick={() => {
                logEvent('tap_button', {
                  event_category: 'upload',
                  event_label: 'retry',
                })
                if (image.file) {
                  retryUpload(image.file)
                } else {
                  errorToast(
                    'Error retrying this file, please refresh screen and try again.',
                  )
                }
              }}
              variant="body2"
              style={{
                alignItems: 'center',
                color: lightBlue,
                cursor: 'pointer',
                display: 'flex',
                fontWeight: 600,
                height: 20,
                marginLeft: 15,
              }}
            >
              Retry?
              <Icon iconName="refresh" />
            </Typography>
          </>
        ) : null}
      </div>
    </Row>
  )
}

export const Image = ({
  url,
  imageSize,
  localImage,
  frame = true,
  className,
}: {
  url?: string
  imageSize?: number
  localImage?: boolean
  frame?: boolean
  className?: string
}) => {
  const classes = useStyles()
  let isPdf = false
  const longPress = useLongPress(() => {
    if (localImage) return
    window.open(url, '_blank')
  })

  if (!url)
    return (
      <img
        className={(frame ? classes.imageFrame : '') + ` ${className}`}
        {...longPress}
        src={NoImage}
        height={imageSize}
        width={imageSize}
        style={{ objectFit: 'cover', opacity: 1 }}
        alt="preview..."
      ></img>
    )

  if (localImage) {
    isPdf = !url.toLowerCase().includes('data:image')
  } else {
    isPdf = url.includes('.pdf')
  }

  return isPdf ? (
    <>
      <embed
        className={frame ? classes.imageFrame : undefined}
        {...longPress}
        src={url}
        width={imageSize}
        height={imageSize}
        type="application/pdf"
        style={{
          height: imageSize,
          objectFit: 'contain',
          width: imageSize,
        }}
      />
    </>
  ) : (
    <img
      className={`${frame ? classes.imageFrame : ''} ${className}`}
      {...longPress}
      src={url}
      height={imageSize}
      width={imageSize}
      style={{ objectFit: 'cover' }}
      alt="preview..."
    ></img>
  )
}
