import { gql } from '@apollo/client'

import {
  MutationCreateOneRecipeArgs,
  RecipeToIngredientInputObject,
  RecipeToRecipeInputObject,
  Unit,
} from 'api'

export const createRecipeMutationVariables = (
  name: string,
  imageUrl: string | undefined,
  section: string | undefined | null,
  unitCost: number,
  instructions: string,
  amount: number,
  unit: Unit | undefined | null,
  childRecipes: RecipeToRecipeInputObject[],
  ingredients: RecipeToIngredientInputObject[],
  kitchenId: number,
  removeIngredients?: number[],
  removeRecipes?: number[],
): { variables: MutationCreateOneRecipeArgs } => {
  // filter removed recipes
  const recipesToAdd = removeRecipes
    ? childRecipes.filter((recipe) => !removeRecipes?.includes(recipe.id))
    : childRecipes

  // filter removed ingredients
  const ingredientsToAdd = removeIngredients
    ? ingredients.filter(
        (ingredient) => !removeIngredients.includes(ingredient.id),
      )
    : ingredients

  return {
    variables: {
      childRecipes: recipesToAdd,
      data: {
        amount: amount,
        imageUrl: imageUrl,
        instructions: instructions,
        name: name,
        section: section,
        unitCost: unitCost,
        unitId: unit?.id,
      },
      ingredients: ingredientsToAdd,
      kitchenId,
    },
  }
}

export const createRecipeMutation = gql`
  mutation createRecipe(
    $data: CreateOneRecipeInputObject!
    $kitchenId: Int!
    $ingredients: [RecipeToIngredientInputObject!]
    $childRecipes: [RecipeToRecipeInputObject!]
  ) {
    createOneRecipe(
      data: $data
      kitchenId: $kitchenId
      ingredients: $ingredients
      childRecipes: $childRecipes
    ) {
      name
      _cursor
      id
      unit {
        _cursor
        id
        name
        type
      }
      unitCost
    }
  }
`

export const copyRecipeMutation = gql`
  mutation copyRecipe($kitchenId: Int!, $recipeId: Int!) {
    duplicateOneRecipe(kitchenId: $kitchenId, recipeId: $recipeId) {
      _cursor
      id
    }
  }
`
