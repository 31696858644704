import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { routes } from 'routes/Paths'

import { useCopier } from './hooks/useCopier'
import { useDuplicator } from './hooks/useDuplicator'

import { Dish, Kitchen, Recipe } from '../../api'
import { NewButton } from '../../components/newUi'
import { SelectKitchen } from '../../components/newUi/SelectKitchen'
import { Typography } from '../../components/Typography'
import { logEvent } from '../../utils'
import { NewModal } from '../Create/NewModal'

export interface IProps {
  handleCancel: (options?: { print?: boolean }) => void
  show: boolean
  dish?: Dish
  recipe: Recipe
}

export function ActionsModal({ show, handleCancel, dish, recipe }: IProps) {
  const navigate = useNavigate()
  const { handleDuplicate } = useDuplicator()
  const { handleCopy, loading: copying } = useCopier()
  const [showCopy, setShowCopy] = useState(false)
  const [kitchen, setKitchen] = useState<Kitchen | undefined>()

  if (showCopy) {
    return (
      <NewModal
        handleCancel={() => setShowCopy(false)}
        show={show}
        containerStyle={{ overflowY: 'visible' }}
      >
        <div className="flex flex-col justify-center items-center mx-4 text-center space-y-8 py-8 px-4">
          <Typography variant="body1" className="!font-rubik !font-medium">
            Copy to other location
          </Typography>

          <Typography variant="body1" className="!font-rubik !font-medium">
            {dish ? dish.name : recipe?.name}
          </Typography>

          <Typography variant="body2">
            *If available, existing items in the other location will be used to
            avoid duplicates.
          </Typography>

          <SelectKitchen kitchenChange={(k) => setKitchen(k)} />
        </div>

        <hr />

        <div className="flex flex-col justify-center items-center text-center space-y-4 py-4 px-4">
          <div className="flex justify-center">
            <NewButton
              className="!h-auto !py-3 !w-32 !m-0"
              text="CONFIRM"
              disabled={!kitchen || copying}
              onClick={() => {
                if (!kitchen) return

                handleCopy(recipe, kitchen)
                  .then(() => handleCancel())
                  .catch((e) => console.error(e))
              }}
            />
          </div>
        </div>
      </NewModal>
    )
  }

  return (
    <NewModal handleCancel={handleCancel} show={show}>
      <div className="flex flex-col justify-center items-center mx-4 text-center space-y-4 py-8 px-4">
        <Typography variant="body2">Make changes to this dish.</Typography>

        <div className="flex justify-center">
          <NewButton
            className="!h-auto !py-3 !w-32 !m-0"
            text="Edit"
            onClick={() => {
              logEvent('tap_button', {
                event_category: dish ? 'view_dish' : 'view_recipe',
                event_label: dish ? 'edit_dish' : 'edit_recipe',
              })
              navigate(
                `${routes.Create}/${dish ? dish.id : recipe?.id}/${
                  dish ? 'dish' : 'recipe'
                }`,
              )
            }}
            noFill={true}
          />
        </div>
      </div>

      <hr />

      <div className="flex flex-col justify-center items-center mx-4 text-center space-y-4 py-8 px-4">
        <Typography variant="body2">
          Make a copy for modifying. The original will be unchanged.
        </Typography>

        <div className="flex justify-center">
          <NewButton
            className="!h-auto !py-3 !w-32 !m-0"
            text="Duplicate"
            onClick={() => {
              handleDuplicate(recipe, dish)
                .then(() => handleCancel())
                .catch((e) => console.error(e))
            }}
            noFill={true}
          />
        </div>
      </div>

      <hr />

      <div className="flex flex-col justify-center items-center mx-4 text-center space-y-4 py-8 px-4">
        <Typography variant="body2">Send a copy to other locations.</Typography>

        <div className="flex justify-center">
          <NewButton
            className="!h-auto !py-3 !w-32 !m-0"
            text="Copy"
            onClick={() => setShowCopy(true)}
            noFill={true}
          />
        </div>
      </div>

      <hr />

      <div className="flex flex-col justify-center items-center mx-4 text-center space-y-4 py-8 px-4">
        <Typography variant="body2">Print in a PDF file.</Typography>

        <div className="flex justify-center">
          <NewButton
            className="!h-auto !py-3 !w-32 !m-0"
            text="Print"
            onClick={() => {
              logEvent('tap_button', {
                event_category: dish ? 'view_dish' : 'view_recipe',
                event_label: dish ? 'print_dish' : 'print_recipe',
              })

              handleCancel({ print: true })
            }}
            noFill={true}
          />
        </div>
      </div>
    </NewModal>
  )
}
