export const capitaliseEachWord = (value?: string): string => {
  if (!value) return ''

  const separateWord = value.toLowerCase().split(' ')
  const regex = /^x\d/

  for (let i = 0; i < separateWord.length; i++) {
    if (!regex.test(separateWord[i])) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
    }
  }

  return separateWord.join(' ')
}
