import { NavbarDesktop, NavbarMobile } from '@getjelly/jelly-ui'
import {
  IconAdjustmentsHorizontal,
  IconCreditCard,
  IconHome,
  IconToolsKitchen2,
} from '@tabler/icons-react'
import clsx from 'clsx'
import {
  ReactElement,
  useEffect,
  useState,
  ReactNode,
  ComponentType,
  useMemo,
} from 'react'
import * as React from 'react'
import Div100vh from 'react-div-100vh'
import { createPortal } from 'react-dom'
import { useResizeDetector } from 'react-resize-detector'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import { useIsDesktop } from 'app/contexts/IsDesktop'
import { Container } from 'components'
import { Icon, Typography } from 'components/newUi'
import { DemoAccount, UpdateAlert } from 'components/newUi/NewLayout/Alerts'
import { useTrackKitchenActivated } from 'hooks'
import { useTheme } from 'styles/newUi'
import { shouldFixIOS15 } from 'utils'

import { EnvironmentBanner } from './EnvironmentBanner'
import { useStyles } from './styles'
import { ViewOnlyBanner } from './ViewOnlyBanner'

import { routes } from '../../../routes/Paths'

export interface IProps {
  onBack?: () => void
  hideBack?: boolean
  title?: string | ReactElement | (string | ReactElement)[]
  bottomContent?: ReactElement
  rightContent?: ReactElement
  bottomBackgroundColor?: string
  leftContent?: ReactElement
  subtitle?: string | (string | ReactElement)[]
  customTitle?: ReactElement
  additionalStyle?: React.CSSProperties
  hideMenu?: boolean
  children?: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Div({ children, ...rest }: any) {
  const isDesktop = useIsDesktop()

  if (window.matchMedia('print').matches) {
    if (shouldFixIOS15()) {
      return (
        <div
          {...rest}
          style={{
            ...rest.style,
            height: 'calc(100vh - env(safe-area-inset-bottom))',
          }}
        >
          {children}
        </div>
      )
    }

    return <Div100vh {...rest}>{children}</Div100vh>
  }

  if (isDesktop)
    return (
      <div
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        {...rest}
      >
        {children}
      </div>
    )

  if (shouldFixIOS15()) {
    return (
      <div {...rest} style={{ ...rest.style, height: '100vh' }}>
        {children}
      </div>
    )
  }

  return <Div100vh {...rest}>{children}</Div100vh>
}

export function Portal({ children, id }: { id: string; children?: ReactNode }) {
  const mount = document.getElementById(id)
  const el = document.createElement('div')

  useEffect(() => {
    if (!mount) return

    mount.appendChild(el)

    return () => void mount.removeChild(el)
  }, [el, mount])

  return createPortal(children, el)
}

export type Tab = {
  id: string
  text: string
  route: string
  icon: ComponentType<{ size?: string | number }>
}

export function NewLayout({
  onBack,
  hideBack,
  title,
  bottomContent,
  rightContent,
  leftContent,
  subtitle,
  customTitle,
  hideMenu,
}: IProps) {
  const classes = useStyles()
  const navigate = useNavigate()
  const { theme } = useTheme()
  const location = useLocation()
  const isDesktop = useIsDesktop()

  const tabs: Tab[] = useMemo(
    () => [
      {
        icon: IconHome,
        id: 'home',
        route: routes.Home,
        text: 'home',
      },
      {
        icon: IconCreditCard,
        id: 'finance',
        route: routes.Finance,
        text: 'finance',
      },
      {
        icon: IconToolsKitchen2,
        id: 'kitchen',
        route: routes.Kitchen,
        text: 'kitchen',
      },
      {
        icon: IconAdjustmentsHorizontal,
        id: 'settings',
        route: routes.Settings,
        text: 'settings',
      },
    ],
    [],
  )

  // The inconsistency in the regex patterns below is to match the old logic.
  // Some regex patterns use ^...$ to strictly match routes. For example,
  // /^\/blah$/ ensures that the string exactly matches `/blah` with nothing
  // before or after. In contrast, others use /\/blah/, which will match any
  // string that contains `/blah` anywhere in it. The old code used a
  // combination of exact string comparisons, `str.includes` and
  // `str.starsWith`. Unifying this all as RegEx has been a good start, but we
  // should also standardize the RegEx patterns at some point.

  const selected: Tab = useMemo(() => {
    if (location.pathname.match(/^\/product/)) return tabs[0]
    if (location.pathname.match(/^\/spending/)) return tabs[1]
    if (location.pathname.match(/^\/finance/)) return tabs[1]
    if (location.pathname.match(/^\/book/)) return tabs[2]
    if (location.pathname.match(/^\/order/)) return tabs[2]
    if (location.pathname.match(/^\/stock/)) return tabs[2]
    if (location.pathname.match(/^\/kitchen/)) return tabs[2]
    if (location.pathname.match(/^\/settings/)) return tabs[3]
    return tabs[0]
  }, [location.pathname, tabs])

  const shouldDisplayDesktopMenu = useMemo(() => {
    // Exclude
    if (location.pathname.match(/^\/ingredient\/list$/)) return false
    if (location.pathname.match(/^\/supplier\//)) return false
    if (location.pathname.match(/^\/supplier$/)) return false
    if (location.pathname.match(/^\/product\/create$/)) return false

    // Allow
    return true
  }, [location.pathname])

  const shouldDisplayMobileMenu = useMemo(() => {
    if (hideMenu) {
      return false
    }

    // Exclude
    if (location.pathname.match(/\/spending\/invoice\/add/)) return false
    // if (location.pathname.match(/\/settings\/team/)) return false
    // if (location.pathname.match(/\/settings\/me/)) return false
    // if (location.pathname.match(/\/settings\/location/)) return false
    // if (location.pathname.match(/\/settings\/plans/)) return false

    // Allow
    if (location.pathname.match(/\/book/)) return true
    if (location.pathname.match(/\/order\/history/)) return true
    if (location.pathname.match(/\/order\/product/)) return true
    if (location.pathname.match(/\/dashboard/)) return true
    if (location.pathname.match(/\/spending/)) return true
    if (location.pathname.match(/\/stock/)) return true
    if (location.pathname.match(/\/onboard/)) return true
    if (location.pathname.match(/\/finance/)) return true
    if (location.pathname.match(/\/kitchen/)) return true
    if (location.pathname.match(/^\/settings$/)) return true
    if (location.pathname.match(/^\/$/)) return true

    return false
  }, [location.pathname, hideMenu])

  const showBackButton = hideBack ? false : !shouldDisplayMobileMenu || onBack

  return (
    <>
      {isDesktop && (
        <>
          <Portal id="react-desktop-portal">
            {shouldDisplayDesktopMenu && (
              <NavbarDesktop<Tab>
                value={selected}
                tabs={tabs}
                tabToId={(t) => t.id}
                tabToText={(t) => t.text}
                tabToIcon={(t) => t.icon}
                onChange={(t) => navigate(t.route)}
              />
            )}
          </Portal>

          <UpdateAlert />
          <DemoAccount />
        </>
      )}

      <Portal id="react-left-portal">
        <div
          style={{
            alignItems: 'center',
            display: 'grid',
            gridTemplateColumns: !showBackButton ? '1fr 0fr' : '0fr 1fr 0fr',
            gridTemplateRows: '1fr',
            height: '100%',
          }}
        >
          {showBackButton && (
            <div
              onClick={() => (onBack ? onBack() : navigate(-1))}
              style={{
                alignItems: 'center',
                color: theme.palette.primary[60].toHexString(),
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
              }}
            >
              <Icon
                iconName="arrowBack"
                className={clsx([classes.backButton, classes.noPrint])}
              />
            </div>
          )}
          {leftContent || <></>}
          {customTitle || (
            <div
              style={{
                marginLeft: !showBackButton ? theme.spacing(2) : undefined,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {subtitle && (
                <Typography
                  variant="body1"
                  style={{
                    color: theme.palette.primary[40].toHexString(),
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {subtitle}
                </Typography>
              )}

              <Typography
                style={{
                  margin: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                variant="h6"
              >
                {title}
              </Typography>
            </div>
          )}
        </div>
      </Portal>

      <Portal id="react-bottom-portal">
        {bottomContent && <div className="pb-safe">{bottomContent}</div>}
      </Portal>

      <Portal id="react-right-portal">{rightContent}</Portal>

      {!isDesktop && (
        <Portal id="react-mobile-nav-portal">
          <UpdateAlert />

          {shouldDisplayMobileMenu && (
            <NavbarMobile<Tab>
              value={selected}
              tabs={tabs}
              onChange={(t) => navigate(t.route)}
              tabToId={(t) => t.id}
              tabToText={(t) => t.text}
              tabToIcon={(t) => t.icon}
            />
          )}
        </Portal>
      )}
    </>
  )
}

export function TrackKitchenActivated() {
  useTrackKitchenActivated()
  return null
}

export function MainLayout({ additionalStyle }: IProps) {
  const { height, ref } = useResizeDetector()

  const classes = useStyles()
  const { theme } = useTheme()
  const [bottomHeight, setBottomHeight] = useState(0)

  useEffect(() => {
    setBottomHeight(height || 0)
  }, [height])
  return (
    <Div
      className={classes.outer}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
      }}
    >
      <ViewOnlyBanner />
      <EnvironmentBanner />
      <TrackKitchenActivated />

      <div className={classes.noPrint}>
        <div id="react-desktop-portal"></div>

        <div
          style={{
            backgroundColor: theme.palette.common.light.toHexString(),
          }}
        >
          <Container
            style={{
              alignItems: 'center',
              display: 'flex',
              height: 64,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <div id="react-left-portal" className={classes.left}></div>

            <div id="react-right-portal"></div>
          </Container>
        </div>

        <div className={classes.divider} />
      </div>

      <div id="react-top-portal"></div>

      <div className="flex flex-1 overflow-auto bg-primary-50">
        <Container
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            paddingLeft: 0,
            paddingRight: 0,
            width: '100%',
            ...additionalStyle,
          }}
        >
          <Outlet />
        </Container>
      </div>

      <div className={classes.noPrint}>
        <div style={{ height: bottomHeight }} />

        <div style={{ bottom: 0, position: 'fixed', width: '100%' }} ref={ref}>
          <div
            style={{
              backgroundColor: 'white',
              width: '100%',
            }}
          >
            <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
              <div
                id="react-bottom-portal"
                style={{
                  display: 'grid',
                  width: '100%',
                }}
              />
            </Container>
          </div>

          <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div id="react-mobile-nav-portal" />
          </Container>
        </div>
      </div>
    </Div>
  )
}
