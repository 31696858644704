import { useMutation } from '@apollo/client'
import { JobRolePanel } from '@getjelly/jelly-ui'
import { JobRoleData } from '@getjelly/jelly-ui/dist/components/organisms/onboarding/JobRolePanel'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { updateOneUserMutation } from './graphql'

import { Mutation, MutationUpdateOneUserArgs } from '../../api'
import { useMe } from '../../hooks/auth'
import { routes } from '../../routes/Paths'
import { cleanErrorMessage } from '../../utils'

export function SetJobRoles() {
  const navigate = useNavigate()

  const { user, refetch } = useMe()
  const [errors, setErrors] = useState<
    Partial<Record<keyof JobRoleData, string>> | undefined
  >(undefined)

  const [updateOneUser, { loading }] = useMutation<
    { updateOneUser: Mutation['updateOneUser'] },
    MutationUpdateOneUserArgs
  >(updateOneUserMutation)

  // Map to database IDs
  const jobRolesMap: Record<JobRoleData['roles'][number], number> = {
    accounting: 4,
    chef: 2,
    foh: 5,
    'head-chef': 1,
    manager: 3,
    owner: 6,
  }

  async function jobRoles(data: JobRoleData) {
    if (!user) return

    try {
      await updateOneUser({
        variables: {
          data: {
            id: user.id,
            jobRoles: data.roles.map((r) => jobRolesMap[r]),
          },
        },
      })

      await refetch()

      navigate(routes.Settings + routes.Locations)
    } catch (e) {
      setErrors({ roles: cleanErrorMessage((e as Error).message) })
    }
  }

  return (
    <JobRolePanel
      jobRoles={jobRoles}
      errors={errors}
      loading={loading}
      onboarding={false}
    />
  )
}
