import { gql } from '@apollo/client'

export const getSuppliersQuery = gql`
  query getSuppliers(
    $kitchenId: Int!
    $cursor: SupplierWhereUniqueInput
    $orderBy: [SupplierOrderByInput!]
    $skip: Int
    $take: Int
    $where: SupplierWhereInput
    $productWhere: ProductWhereInput
    $fuzzySearch: String
  ) {
    suppliers(
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
      fuzzySearch: $fuzzySearch
    ) {
      _cursor
      id
      name
      status
      productCount(where: $productWhere)
      automated(kitchenId: $kitchenId)
    }
    all: suppliers(where: $where) {
      _cursor
      id
      name
      status
      productCount(where: $productWhere)
      automated(kitchenId: $kitchenId)
    }
  }
`
