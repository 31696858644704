import { ReactNode } from 'react'

import { InvoiceGroupHeaderSkeleton } from './InvoiceGroupHeaderSkeleton'

type Props = {
  showHeaders?: boolean
  invoiceSkeleton: ReactNode
}

export function GroupedInvoicesSkeleton({
  showHeaders = true,
  invoiceSkeleton,
}: Props) {
  return (
    <div className="flex-1 overflow-hidden animate-pulse">
      {showHeaders && <InvoiceGroupHeaderSkeleton />}

      {Array.from({ length: 2 }).map((_, i) => (
        <div key={i}>{invoiceSkeleton}</div>
      ))}

      {showHeaders && <InvoiceGroupHeaderSkeleton />}

      {Array.from({ length: 3 }).map((_, i) => (
        <div key={i}>{invoiceSkeleton}</div>
      ))}

      {showHeaders && <InvoiceGroupHeaderSkeleton />}

      {Array.from({ length: 10 }).map((_, i) => (
        <div key={i}>{invoiceSkeleton}</div>
      ))}
    </div>
  )
}
