import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import { Kitchen } from 'api'
import { RootState } from 'store'

type State = {
  kitchen: Kitchen | undefined
  invoiceCode: string
  menuCount: number
  recipeCount: number
  dishCount: number
  stocktakeCount: number
  ingredientCount: number
  orderCount: number
  salesCount: number
  priceChangeCount: number
  invoiceCount: number
  todoCount: number
}

export type CountPayload = {
  menuCount: number
  recipeCount: number
  dishCount: number
  stocktakeCount: number
  ingredientCount: number
  orderCount: number
  salesCount: number
  priceChangeCount: number
  invoiceCount: number
  todoCount: number
}

const initialState: State = {
  dishCount: 0,
  ingredientCount: 0,
  invoiceCode: '',
  invoiceCount: 0,
  kitchen: undefined,
  menuCount: 0,
  orderCount: 0,
  priceChangeCount: 0,
  recipeCount: 0,
  salesCount: 0,
  stocktakeCount: 0,
  todoCount: 0,
}

const kitchenSlice = createSlice({
  initialState,
  name: 'kitchen',
  reducers: {
    setCounts(state, action: PayloadAction<CountPayload>) {
      state.dishCount = action.payload.dishCount
      state.ingredientCount = action.payload.ingredientCount
      state.invoiceCount = action.payload.invoiceCount
      state.menuCount = action.payload.menuCount
      state.orderCount = action.payload.orderCount
      state.priceChangeCount = action.payload.priceChangeCount
      state.recipeCount = action.payload.recipeCount
      state.salesCount = action.payload.salesCount
      state.stocktakeCount = action.payload.stocktakeCount
      state.todoCount = action.payload.todoCount
    },
    setInvoiceCode(state, action: PayloadAction<string>) {
      state.invoiceCode = action.payload
    },
    setKitchen(state, action: PayloadAction<Kitchen | undefined>) {
      state.kitchen = action.payload
    },
  },
})

export const { setKitchen, setInvoiceCode, setCounts } = kitchenSlice.actions
export const reducer = kitchenSlice.reducer

const selectState = (state: RootState) => state.kitchen

export const selectKitchen = () =>
  createSelector(selectState, (state) => state.kitchen)

export const selectInvoiceCode = () =>
  createSelector(selectState, (state) => state.invoiceCode)

export const selectCounts = () =>
  createSelector(selectState, (state) => ({
    dishCount: state.dishCount,
    ingredientCount: state.ingredientCount,
    invoiceCount: state.invoiceCount,
    menuCount: state.menuCount,
    orderCount: state.orderCount,
    priceChangeCount: state.priceChangeCount,
    recipeCount: state.recipeCount,
    salesCount: state.salesCount,
    stocktakeCount: state.stocktakeCount,
    todoCount: state.todoCount,
  }))
