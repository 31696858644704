import { useLayoutEffect, useMemo, useState } from 'react'

import { useTheme, Theme } from 'styles/newUi'

export const useWindowSize = (key: Theme['breakpoints']['keys'][number]) => {
  const [size, setSize] = useState({ height: 0, width: 0 })
  const { theme } = useTheme()

  const isSize = theme.breakpoints.values[key]

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize({ height: window.innerHeight, width: window.innerWidth })
    }

    window.addEventListener('resize', updateSize)

    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const value = useMemo(
    () => ({
      gt: size.width > isSize,
      gte: size.width >= isSize,
      lt: size.width < isSize,
      lte: size.width <= isSize,
      size,
    }),
    [size, isSize],
  )

  return value
}
