import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { EventHandler } from './EventHandler'

import { VITE_REACT_APP_WEBSOCKETS_URL } from '../../app'
import { selectToken } from '../../store/auth'

export type Listener = (data?: any) => Promise<void> | void
export type RegisterListener = (
  listenerId: string,
  eventName: string,
  listener: Listener,
) => void

export function WebSockets() {
  const token = useSelector(selectToken())

  // Only have a websocketUrl if we have a token set.
  const websocketUrl = useMemo(() => {
    if (!token || !VITE_REACT_APP_WEBSOCKETS_URL) {
      return ''
    }

    const params = new URLSearchParams({ token })
    return `${VITE_REACT_APP_WEBSOCKETS_URL}?${params.toString()}`
  }, [token])

  if (!websocketUrl) {
    return <></>
  }

  return <EventHandler websocketUrl={websocketUrl} />
}
