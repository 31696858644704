import { useMutation, useQuery } from '@apollo/client'
import { JobResponsibilitiesPanel } from '@getjelly/jelly-ui'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { jobResponsibilitiesQuery, updateOneUserMutation } from './graphql'

import {
  JobResponsibility,
  Mutation,
  MutationUpdateOneUserArgs,
  Query,
} from '../../api'
import { Loader } from '../../components'
import { useMe } from '../../hooks/auth'
import { routes } from '../../routes/Paths'
import { cleanErrorMessage } from '../../utils'

export function SetJobResponsibilities() {
  const navigate = useNavigate()

  const { user, refetch } = useMe()
  const [errors, setErrors] = useState<
    { responsibilities: string } | undefined
  >(undefined)

  const { data, loading: responsibilitiesLoading } = useQuery<{
    jobResponsibilities: Query['jobResponsibilities']
  }>(jobResponsibilitiesQuery)

  const [updateOneUser, { loading }] = useMutation<
    { updateOneUser: Mutation['updateOneUser'] },
    MutationUpdateOneUserArgs
  >(updateOneUserMutation)

  async function submit(selectedResponsibilities: JobResponsibility[]) {
    if (!user) return

    try {
      await updateOneUser({
        variables: {
          data: {
            id: user.id,
            jobResponsibilities: selectedResponsibilities.map((r) => r.id),
          },
        },
      })

      await refetch()

      navigate(routes.Settings + routes.Locations)
    } catch (e) {
      setErrors({
        responsibilities: cleanErrorMessage((e as Error).message) || '',
      })
    }
  }

  const responsibilities = useMemo(() => {
    if (!data?.jobResponsibilities) {
      return []
    }

    return data.jobResponsibilities.map((r) => ({ isChecked: false, item: r }))
  }, [data])

  if (responsibilitiesLoading) {
    return <Loader />
  }

  if (!data?.jobResponsibilities) {
    throw new Error('No job responsibilities.')
  }

  return (
    <JobResponsibilitiesPanel<JobResponsibility>
      submit={submit}
      responsibilities={responsibilities}
      getText={(r) => r.name}
      errors={errors}
      loading={loading}
    />
  )
}
