import { useSelector } from 'react-redux'

import { Kitchen } from 'api'
import { selectProduct } from 'screens/Order/store'
export const Price = ({
  product,
  includeCost,
  selectedKitchen,
  supplierId,
}: {
  product: any
  includeCost?: boolean
  selectedKitchen?: Kitchen
  supplierId: string
}) => {
  const storeData = useSelector(
    selectProduct(`${selectedKitchen?.id}:${supplierId}`, product.id),
  )
  return (
    <div
      style={{
        display: !includeCost ? 'none' : undefined,
        whiteSpace: 'nowrap',
      }}
    >
      {storeData?.sizeType === 1 ||
      storeData?.sizeType === 2 ||
      product?.cost === undefined
        ? 'No Price'
        : `£${(
            (product?.cost ?? 0) *
            (!storeData?.quantity || isNaN(storeData?.quantity)
              ? 1
              : storeData?.quantity)
          ).toFixed(2)}`}
    </div>
  )
}
