import { useMutation, useQuery } from '@apollo/client'
import { InsightsStockRangeSelect } from '@getjelly/jelly-ui'
import { StockSelectorData } from '@getjelly/jelly-ui/dist/components/molecules/InsightsStockSelector'
import { format, isBefore } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'

import {
  createOneFlashPeriod,
  flashPeriod,
  stockTakeList,
  updateOneFlashPeriod,
} from './graphql'

import {
  Mutation,
  MutationCreateOneFlashPeriodArgs,
  MutationUpdateOneFlashPeriodArgs,
  PeriodEnum,
  Query,
  QueryFlashPeriodArgs,
  Stock,
  StockListQueryVariables,
} from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../components'
import { errorToast } from '../../../components/toasts'
import { nullable, toUTCStartOfDay } from '../../../utils'

type Data = StockSelectorData<Stock>

type Props = {
  period: PeriodEnum
  startDate: Date
  onChange: (open: number | null, close: number | null) => void
}

export function FoodFlashStockSelector({ period, startDate, onChange }: Props) {
  const { selectedKitchen } = useKitchen()

  function blankData(): Data {
    return {
      adjustments: null,
      selectedStock: [],
    }
  }

  const [openData, setOpenData] = useState<Data>(blankData())
  const [closeData, setCloseData] = useState<Data>(blankData())

  function getValue(data: Data): number | null {
    if (data.adjustments === null && data.selectedStock.length === 0) {
      return null
    }

    return (
      (data.adjustments ?? 0) +
      data.selectedStock.reduce((acc, s) => acc + s.total, 0)
    )
  }

  const readonly = useMemo(
    () => !selectedKitchen?.userPermissions.includes('update-sale'),
    [selectedKitchen],
  )

  useEffect(() => {
    onChange(getValue(openData), getValue(closeData))
  }, [onChange, openData, closeData])

  const { data: stockTakeData } = useQuery<
    {
      stockList: Query['stockList']
    },
    StockListQueryVariables
  >(stockTakeList, {
    skip: !selectedKitchen,
    variables: {
      kitchenId: selectedKitchen?.id || 0,
    },
  })

  const { data, loading } = useQuery<
    {
      flashPeriod: Query['flashPeriod']
    },
    QueryFlashPeriodArgs
  >(flashPeriod, {
    skip: !selectedKitchen,
    variables: {
      kitchenId: selectedKitchen?.id || 0,
      period: period,
      startDate: toUTCStartOfDay(startDate),
    },
  })

  const [createFlashPeriod] = useMutation<
    { createOneFlashPeriod: Mutation['createOneFlashPeriod'] },
    MutationCreateOneFlashPeriodArgs
  >(createOneFlashPeriod, {
    awaitRefetchQueries: true,
    refetchQueries: ['insightsFlashPeriod'],
  })

  const [updateFlashPeriod] = useMutation<
    { updateOneFlashPeriod: Mutation['updateOneFlashPeriod'] },
    MutationUpdateOneFlashPeriodArgs
  >(updateOneFlashPeriod, {
    awaitRefetchQueries: true,
    refetchQueries: ['insightsFlashPeriod'],
  })

  const stockList = useMemo(() => {
    if (!stockTakeData) return []
    const nodes = [...stockTakeData.stockList.nodes]

    return nodes.sort((a, b) => {
      if (isBefore(a.date, b.date)) return 1
      if (isBefore(b.date, a.date)) return -1
      return 0
    })
  }, [stockTakeData])

  function optionToLabel(s: Stock) {
    const name = `${s.name || 'Unnamed'}`
    const date = format(s.date, 'E d LLL yy')
    const total = s.total.toLocaleString(undefined, {
      currency: 'GBP',
      style: 'currency',
    })

    return `${name} (${date} ${total})`
  }

  useEffect(() => {
    if (!data?.flashPeriod) {
      setOpenData(blankData())
      setCloseData(blankData())
      return
    }

    const openStock: Stock[] = []
    const closeStock: Stock[] = []

    for (const pts of data.flashPeriod.flashPeriodToStock) {
      if (pts.type === 'open') {
        openStock.push(pts.stock)
      } else {
        closeStock.push(pts.stock)
      }
    }

    setOpenData({
      adjustments: nullable(data.flashPeriod.openAdjustments),
      selectedStock: openStock,
    })

    setCloseData({
      adjustments: nullable(data.flashPeriod.closeAdjustments),
      selectedStock: closeStock,
    })
  }, [data])

  async function saveData(open: Data, close: Data) {
    try {
      if (!data?.flashPeriod?.id) {
        await createFlashPeriod({
          variables: {
            data: {
              closeAdjustments: close.adjustments,
              closeStockIds: close.selectedStock.map((s) => s.id),
              kitchenId: selectedKitchen?.id ?? 0,
              openAdjustments: open.adjustments,
              openStockIds: open.selectedStock.map((s) => s.id),
              period: period,
              startDate: toUTCStartOfDay(startDate),
            },
          },
        })
      } else {
        await updateFlashPeriod({
          variables: {
            data: {
              closeAdjustments: close.adjustments,
              closeStockIds: close.selectedStock.map((s) => s.id),
              id: data.flashPeriod.id,
              openAdjustments: open.adjustments,
              openStockIds: open.selectedStock.map((s) => s.id),
            },
          },
        })
      }
    } catch (e) {
      errorToast('Unable to save stock info for this period.')
      throw e
    }
  }

  if (data === undefined && loading) {
    return (
      <div className="min-h-[5.5rem] w-full bg-white">
        <Loader></Loader>
      </div>
    )
  }

  return (
    <InsightsStockRangeSelect<Stock>
      readonly={readonly}
      stockTakes={stockList}
      onOpenSubmit={(open) => saveData(open, closeData)}
      onCloseSubmit={(close) => saveData(openData, close)}
      open={openData}
      close={closeData}
      optionToId={(s) => s.id}
      optionToLabel={optionToLabel}
      optionToValue={(s) => s.total}
    />
  )
}
