import { gql } from '@apollo/client'

export const getProductQuery = gql`
  query product($cursor: ID!) {
    productNode(cursor: $cursor) {
      id
      _cursor
      name
      ingredients(skip: 0, take: 100) {
        _cursor
        id
        priceChangesList(skip: 0, take: 100) {
          priceChanges {
            date
            changes {
              _cursor
              id
              ingredientName
              percentageChange
            }
          }
        }
      }
    }
  }
`

export const relatedItemsQuery = gql`
  query relatedItems($cursor: ID!) {
    ingredientNode(cursor: $cursor) {
      _cursor
      id
      product {
        id
        _cursor
        status
        name
      }
      recipeList(where: { recipe: { status: { not: { equals: inactive } } } }) {
        nodes {
          _cursor
          id
          recipe {
            _cursor
            id
            name
            status
          }
        }
      }
    }
  }
`

export const deleteOneIngredient = gql`
  mutation deleteOneIngredient($data: DeleteOneIngredientInputObject!) {
    deleteOneIngredient(data: $data) {
      _cursor
      id
    }
  }
`
