import { find, isEmpty, isNil } from 'ramda'

import { Recipe } from 'api'

export const noAllergenData = (recipe?: Recipe) => {
  if (isNil(recipe)) return true

  return (
    (isEmpty(recipe.allergens) ||
      isNil(
        find(
          (allergen) => allergen.removable || allergen.contains,
          recipe.allergens,
        ),
      )) &&
    !recipe.hasNoAllergens
  )
}

export const noAllergenDataList = (recipe: Recipe) =>
  (isEmpty(recipe.allergenList?.nodes) ||
    isNil(
      find(
        (allergen) => allergen.removable || allergen.contains,
        recipe.allergenList?.nodes,
      ),
    )) &&
  !recipe.hasNoAllergens
