import { Decimal } from 'decimal.js'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useKitchen } from 'app/contexts/SelectedKitchen'
import { AddIcon, Input, RemoveIcon } from 'components'
import { selectProduct, updateQuantity } from 'screens/Order/store'

import { useStyles } from '../styles'

interface Props {
  id: number
}

export const Quantity = ({ id: productId }: Props) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { selectedKitchen } = useKitchen()
  const [focus, setFocus] = useState(false)
  const classes = useStyles()

  const product = useSelector(
    selectProduct(`${selectedKitchen?.id}:${id}`, productId),
  )

  const [quantity, setQuantity] = useState<string | undefined>()

  useEffect(() => {
    if (!product) return
    setQuantity(product?.quantity?.toString() ?? '0')
    // eslint-disable-next-line
  }, [])

  const handleSetState = (newQuantity: string) => {
    if (parseFloat(newQuantity) < 0) return setQuantity(undefined)
    setQuantity(newQuantity)

    if (focus) return

    dispatch(
      updateQuantity({
        id: `${selectedKitchen?.id}:${id}`,
        productId,
        quantity: isNaN(parseFloat(newQuantity)) ? 0 : parseFloat(newQuantity),
      }),
    )
  }

  const handleIncrementQuantity = () => {
    if (!quantity) {
      setQuantity('1')
      return dispatch(
        updateQuantity({
          id: `${selectedKitchen?.id}:${id}`,
          productId,
          quantity: 1,
        }),
      )
    }

    const value = new Decimal(parseFloat(quantity))
    const newValue = value.plus(1)

    setQuantity(newValue.toString())
    return dispatch(
      updateQuantity({
        id: `${selectedKitchen?.id}:${id}`,
        productId,
        quantity: newValue.toNumber(),
      }),
    )
  }

  const handleDecrementQuantity = () => {
    if (!quantity) {
      setQuantity(undefined)
      return dispatch(
        updateQuantity({
          id: `${selectedKitchen?.id}:${id}`,
          productId,
          quantity: 0,
        }),
      )
    }

    const value = new Decimal(parseFloat(quantity))
    const newValue = value.minus(1)

    setQuantity(newValue.toString())

    return dispatch(
      updateQuantity({
        id: `${selectedKitchen?.id}:${id}`,
        productId,
        quantity: newValue.toNumber(),
      }),
    )
  }

  const handleSetFocus = () => {
    setFocus(true)
  }

  const handleClearFocus = () => {
    setFocus(false)

    dispatch(
      updateQuantity({
        id: `${selectedKitchen?.id}:${id}`,
        productId,
        quantity: isNaN(parseFloat(quantity ?? '0'))
          ? 0
          : parseFloat(quantity ?? '0'),
      }),
    )
  }

  return (
    <div
      style={{
        alignItems: 'center',
        // color: theme.palette.grey[500],
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {((quantity && parseFloat(quantity) > 0) || focus) && (
        <>
          <RemoveIcon
            onClick={handleDecrementQuantity}
            style={{ cursor: 'pointer', height: 35, width: 35 }}
          />
          <Input
            disableUnderline
            className={classes.quantityInput}
            type="number"
            value={quantity}
            style={{ width: 60 }}
            inputProps={{ step: '1' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleSetState(event.target.value)
            }}
            onBlur={handleClearFocus}
            onFocus={handleSetFocus}
          />
        </>
      )}
      <AddIcon
        data-testid="increaseQuantityButton"
        onClick={handleIncrementQuantity}
        style={{
          // color: theme.palette.grey[500],
          cursor: 'pointer',
          height: 35,
          width: 35,
        }}
      />
    </div>
  )
}
