import { useMutation, useLazyQuery } from '@apollo/client'
import { ConfirmPhonePanel } from '@getjelly/jelly-ui'
import { ConfirmPhoneData } from '@getjelly/jelly-ui/dist/components/organisms/onboarding/ConfirmPhonePanel'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  Mutation,
  MutationRequestPasswordResetCodeArgs,
  QueryVerifyPasswordResetCodeArgs,
} from 'api'
import { cleanErrorMessage } from 'utils'

import { verifyPasswordResetCodeQuery } from './graphql'

import { successToast } from '../../components/toasts'
import { routes } from '../../routes/Paths'
import { selectAuthFlow, setResetPin } from '../../store/authFlow'
import { requestPasswordResetCodeMutation } from '../ForgotPassword/graphql'

export function ResetPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authFlow = useSelector(selectAuthFlow())

  const [errors, setErrors] = useState<
    Partial<Record<keyof ConfirmPhoneData, string>> | undefined
  >(undefined)

  const [requestCodeReset, { loading }] = useMutation<
    { requestCodeReset: Mutation['requestPasswordResetCode'] },
    MutationRequestPasswordResetCodeArgs
  >(requestPasswordResetCodeMutation)

  useEffect(() => {
    // This should be super rare. Just send them back to the forgot password
    // page.
    if (!authFlow.phoneNumber || !authFlow.countryCode)
      navigate(routes.ForgotPassword)
  }, [authFlow, navigate])

  async function resendCodeRequest() {
    setErrors(undefined)
    if (loading) return

    if (!authFlow.phoneNumber) {
      return setErrors({ code: 'An unknown error occurred.' })
    }

    try {
      const { data: response } = await requestCodeReset({
        variables: { phoneNumber: authFlow.phoneNumber },
      })

      if (!response) {
        return setErrors({ code: 'An unknown error occurred.' })
      }

      successToast('Please check your SMS messages.')
    } catch (e) {
      setErrors({ code: cleanErrorMessage((e as Error).message) })
    }
  }

  const [verifyPasswordResetCode] = useLazyQuery<
    { requestCodeReset: Mutation['requestPasswordResetCode'] },
    QueryVerifyPasswordResetCodeArgs
  >(verifyPasswordResetCodeQuery)

  async function confirmCode(data: ConfirmPhoneData) {
    setErrors(undefined)

    if (!authFlow.phoneNumber) {
      return setErrors({ code: 'An unknown error occurred.' })
    }

    try {
      const { data: response, error } = await verifyPasswordResetCode({
        variables: {
          countryCode: authFlow.countryCode || '',
          passwordResetCode: data.code,
          phoneNumber: authFlow.phoneNumber || '',
        },
      })

      if (error) {
        throw error
      }

      if (!response) {
        return setErrors({ code: 'An unknown error occurred.' })
      }

      dispatch(setResetPin(data.code))
      navigate(routes.NewPassword)
    } catch (e) {
      setErrors({ code: cleanErrorMessage((e as Error).message) })
    }
  }

  return (
    <ConfirmPhonePanel
      confirmCode={confirmCode}
      resendCodeClicked={resendCodeRequest}
      errors={errors}
    />
  )
}
