import { useState } from 'react'

import { isInStandaloneMode } from 'utils'

export const GAAnalytics = () => {
  const [initialized, setInitialized] = useState(false)

  const checkIfAnalyticsLoaded = () => {
    // @ts-ignore ga is global
    if (typeof ga !== 'undefined' && !initialized) {
      // @ts-ignore ga is global
      ga('set', 'dimension1', isInStandaloneMode() ? 1 : 0)
      setInitialized(true)
    } else {
      setTimeout(checkIfAnalyticsLoaded, 500)
    }
  }

  return <>{checkIfAnalyticsLoaded()}</>
}
