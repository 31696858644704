import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { getRefreshToken } from 'api/client'
import { getToken } from 'api/token'
import { API } from 'app'
import { store } from 'store'
import { clearToken, setToken } from 'store/auth'

interface ApiResponse<T> {
  data: T
  status: number
  statusText: string
  headers: any
  config: AxiosRequestConfig
}

class RestApi {
  private readonly axiosInstance: AxiosInstance

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          const response = await getRefreshToken()
          const token = (await response.json()) as {
            data: { refresh: { token: string } } | null
          }
          if (token.data?.refresh.token) {
            store.dispatch(setToken(token.data.refresh.token))
            originalRequest.headers.Authorization = `Bearer ${token.data.refresh.token}`
            return axios(originalRequest)
          }
          store.dispatch(clearToken())
        }

        throw error
      },
    )
  }

  public async get<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    const result = getToken()
    const authConfig = {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${result?.token}`,
      },
    }
    const response: AxiosResponse<T> = await this.axiosInstance.get(
      url,
      authConfig,
    )
    return this.buildApiResponse(response)
  }

  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    const result = getToken()
    const authConfig = {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${result?.token}`,
      },
    }

    const response: AxiosResponse<T> = await this.axiosInstance.post(
      url,
      data,
      authConfig,
    )
    return this.buildApiResponse(response)
  }

  public async put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    const response: AxiosResponse<T> = await this.axiosInstance.put(
      url,
      data,
      config,
    )
    return this.buildApiResponse(response)
  }

  public async delete<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    const response: AxiosResponse<T> = await this.axiosInstance.delete(
      url,
      config,
    )
    return this.buildApiResponse(response)
  }

  private buildApiResponse<T>(response: AxiosResponse<T>): ApiResponse<T> {
    return {
      config: response.config,
      data: response.data,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
    }
  }
}

export const api = new RestApi(API || '')
