/* @TODO: Rewrite this with new queries, components and styles */
import { useQuery } from '@apollo/client'
// import { Alert } from '@mui/material'
import { Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import { isEmpty } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  Query,
  Status,
  QueryMode,
  QuerySuppliersArgs,
  SortOrder,
  Supplier,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader, Grid } from 'components'
import {
  BottomModal,
  SearchBar,
  NewLayout,
  AlertWithIcon,
  VirtualizedSectionList,
} from 'components/newUi'
import { routes } from 'routes/Paths'
import { setProduct } from 'store/product'
import { useTheme } from 'styles/newUi'

import { getSuppliersQuery } from './graphql'
import { Item } from './Item'
import { useStyles } from './styles'

export const Suppliers = () => {
  const dispatch = useDispatch()
  const inputEl = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const classes = useStyles()
  const { theme } = useTheme()
  const { selectedKitchen } = useKitchen()
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('query') ?? ''
  const [jellyTake, setJellyTake] = useState(25)
  const [data, setData] = useState<
    {
      label: string
      items: Supplier[]
      rightAdornment?: React.ReactElement
    }[]
  >([
    {
      items: [],
      label: 'Your suppliers',
    },
    // {
    //   items: [],
    //   label: 'Suppliers on Jelly',
    // },
  ])

  useEffect(() => {
    dispatch(setProduct(undefined))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: supplierData, loading } = useQuery<
    {
      kitchenSuppliersCount: Query['suppliersCount']
      kitchenSuppliers: Query['suppliers']
      jellySuppliersCount: Query['suppliersCount']
      jellySuppliers: Query['suppliers']
    },
    QuerySuppliersArgs & {
      jellySkip: QuerySuppliersArgs['skip']
      jellyTake: QuerySuppliersArgs['take']
      jellyWhere: QuerySuppliersArgs['where']
    }
  >(getSuppliersQuery, {
    skip: !selectedKitchen,
    variables: {
      jellySkip: 0,
      jellyTake: jellyTake,
      jellyWhere: {
        kitchens: {
          every: {
            kitchen: {
              id: {
                not: {
                  equals: selectedKitchen?.id ?? null,
                },
              },
            },
          },
        },
        name: {
          contains: isEmpty(search) ? undefined : search,
          mode: QueryMode.Insensitive,
        },
        products: {
          some: {
            ingredients: {
              every: {
                kitchenId: {
                  not: {
                    equals: selectedKitchen?.id ?? null,
                  },
                },
              },
            },
          },
        },
        status: {
          equals: Status.Active,
        },
      },
      orderBy: [{ name: SortOrder.Asc }],
      take: 500,
      where: {
        AND: [
          {
            OR: [
              {
                kitchens: {
                  some: {
                    kitchen: {
                      id: { equals: selectedKitchen?.id ?? null },
                    },
                  },
                },
              },
              {
                products: {
                  some: {
                    ingredients: {
                      some: {
                        kitchenId: { equals: selectedKitchen?.id ?? null },
                      },
                    },
                  },
                },
              },
            ],
          },
          { name: { contains: search, mode: QueryMode.Insensitive } },
        ],
      },
    },
  })

  useEffect(() => {
    const data = [
      {
        items: supplierData?.kitchenSuppliers ?? [],
        label: 'Your suppliers',
        rightAdornment: (
          <Typography
            variant="subtitle2"
            style={{ color: theme.palette.primary[60].toHexString() }}
          >
            {supplierData?.kitchenSuppliersCount ?? 0}
          </Typography>
        ),
      },
    ] as {
      label: string
      items: Supplier[]
      rightAdornment?: React.ReactElement
    }[]
    if (search) {
      data.push({
        items: supplierData?.jellySuppliers ?? [],

        label: 'Suppliers on Jelly',
        rightAdornment: (
          <Typography
            variant="subtitle2"
            style={{ color: theme.palette.primary[60].toHexString() }}
          >
            {supplierData?.jellySuppliersCount ?? 0}
          </Typography>
        ),
      })
    }
    setData(data)
  }, [supplierData, search])

  const onSearch = debounce((query: string) => {
    setSearchParams({ query }, { replace: true })
  }, 500)

  return (
    <>
      <NewLayout subtitle="Add Ingredients" title="Choose Supplier" />

      <AlertWithIcon type="info" iconName="info">
        <div
          style={{
            alignItems: 'center',
            color: 'black',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              color: theme.palette.primary[100].toHexString(),
              display: 'flex',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 'normal',
              lineHeight: '20px',
            }}
          >
            Which supplier does this product come from?
          </div>
        </div>
      </AlertWithIcon>
      <div
        style={{
          backgroundColor: theme.palette.primary[10].toHexString(),
          paddingBottom: theme.spacing(2),
          paddingTop: theme.spacing(2),
        }}
      >
        <SearchBar
          search={search}
          searchPlaceholder="Search by supplier name"
          onSearch={onSearch}
          ref={inputEl}
        />
      </div>
      <div className={classes.list}>
        {loading && !supplierData ? (
          <Loader />
        ) : (
          <VirtualizedSectionList
            pageInfo={{
              hasNextPage: !(
                supplierData?.jellySuppliersCount ===
                supplierData?.jellySuppliers.length
              ),
              hasPreviousPage: false,
              skip: 0,
              take: jellyTake,
            }}
            fetchMore={() => search && setJellyTake(jellyTake + 25)}
            loading={loading}
            ROW_HEIGHT={71}
            data={data}
            Item={(props) => {
              const item = props.data.items[props.index]
              return (
                <Item item={item} search={search} key={`item-key-${item.id}`} />
              )
            }}
            footer={
              loading ? (
                <Loader />
              ) : (
                <>
                  {search && (
                    <Grid
                      container
                      item
                      xs={12}
                      style={{
                        alignItems: 'center',
                        backgroundColor: theme.palette.primary[5].toHexString(),
                        borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 20,
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          alignItems: 'center',
                          color: theme.palette.primary[80].toHexString(),
                          cursor: 'pointer',
                          display: 'flex',
                          fontWeight: 600,
                        }}
                      >
                        No more suppliers matching {`"`}
                        {search}
                        {`"`}
                      </Typography>
                      <Typography
                        onClick={() => {
                          setSearchParams({ query: '' }, { replace: true })
                        }}
                        variant="body2"
                        style={{
                          alignItems: 'center',
                          color: theme.palette.secondary[100].toHexString(),
                          cursor: 'pointer',
                          display: 'flex',
                          fontWeight: 600,
                          height: 40,
                        }}
                      >
                        Show all Suppliers {'>'}
                      </Typography>
                    </Grid>
                  )}
                  <BottomModal
                    buttonVariant="white"
                    text="Can't find the supplier?"
                    buttonText={'Add a Product'}
                    onClick={() =>
                      navigate(`${routes.Product}${routes.Create}`, {
                        replace: true,
                      })
                    }
                  />
                </>
              )
            }
          />
        )}
      </div>
    </>
  )
}
