import { api } from 'api/rest'

export const exportInvoiceToXero = async (
  invoiceId: number,
  successCallback: () => void,
  errorCallback: (errorMessage: string) => void,
) => {
  try {
    await api.post(
      '/rest/v2/invoice/sync',
      {
        invoiceId,
      },
      {},
    )
    successCallback()
  } catch (error: any) {
    console.log(error)
    errorCallback(
      error?.response?.data?.message ||
        error?.response?.data?.error ||
        error?.message ||
        '',
    )
  }
}
