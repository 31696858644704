import { useNavigate } from 'react-router-dom'

import { Recipe } from 'api'
import { Icon, NewButton, Typography } from 'components/newUi'
import { buildCreateRoute } from 'routes/Paths'

import { useKitchen } from '../../../app/contexts/SelectedKitchen'

export const Method = ({
  recipe,
  isDish,
  id,
}: {
  recipe: Recipe
  isDish: boolean
  id: number
}) => {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  return (
    <div>
      {recipe.instructions ? (
        <div className="p-4">
          {recipe?.instructions.split('\n').map((str, index) => (
            <Typography variant="body2" key={index}>
              {str}
              <br />
            </Typography>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-4 bg-gray-100 p-4">
          <Typography variant="subtitle1">
            No method for this {isDish ? 'dish' : 'recipe'}
          </Typography>

          {selectedKitchen?.userPermissions?.includes('create-method') && (
            <NewButton
              noFill
              text="Add a method"
              rightAdornment={<Icon iconName="add"></Icon>}
              onClick={() => {
                navigate(buildCreateRoute(id, isDish) + '/method')
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}
