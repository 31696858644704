import { gql } from '@apollo/client'

import { MutationCreateOneIngredientArgs } from 'api'

export const createIngredientMutation = gql`
  mutation createIngredient(
    $data: CreateOneIngredientInputObject!
    $kitchenId: Int!
    $supplierId: Int
    $supplierData: CreateOneSupplierInputObject
  ) {
    createOneIngredient(
      data: $data
      kitchenId: $kitchenId
      supplierId: $supplierId
      supplierData: $supplierData
    ) {
      _cursor
      id
      wastage
      price
      wastage
      unitPrice
      wasteAdjustedUnitPrice
      conversionUnit
      conversionUnitType {
        _cursor
        id
        name
        type
        gramsPerUnit
      }
      conversionUnitValue
      product {
        _cursor
        id
        code
        name
        packSize
        price
        status
        unit {
          _cursor
          id
          name
          type
        }
        unitValue
        supplier {
          _cursor
          id
          name
        }
      }
    }
  }
`

export const updateIngredientMutation = gql`
  mutation updateOneIngredient(
    $data: UpdateOneIngredientInputObject!
    $kitchenId: Int!
    $supplierData: CreateOneSupplierInputObject
    $supplierId: Int
  ) {
    updateOneIngredient(
      data: $data
      kitchenId: $kitchenId
      supplierData: $supplierData
      supplierId: $supplierId
    ) {
      _cursor
      id
      wastage
      price
      wastage
      unitPrice
      wasteAdjustedUnitPrice
      conversionUnit
      conversionUnitType {
        _cursor
        id
        name
        type
        gramsPerUnit
      }
      conversionUnitValue
      product {
        _cursor
        id
        name
        code
        price
        packSize
        status
        unit {
          _cursor
          id
          name
          type
        }
        unitValue
        supplier {
          _cursor
          id
          name
        }
      }
    }
  }
`

export const createIngredientMutationVariables = (
  kitchenId: number,
  name: string,
  unitId: number,
  waste: string,
  supplierId: number,
): MutationCreateOneIngredientArgs => ({
  data: {
    name: name,
    packSize: 0,
    price: 0,
    unitId,
    unitPrice: 0,
    unitValue: 0,
    wastage: parseInt(waste),
  },
  kitchenId: kitchenId,
  supplierId: supplierId,
})
