import clsx from 'clsx'
import { isEmpty, isNil, keys } from 'ramda'
import { useEffect, useState } from 'react'

import { Dish, DishType, Menu } from 'api'
import { Icon, PrintFooter, PrintHeader } from 'components/newUi'
import { ImageContainer } from 'screens/Create/ImageContainer'

import { useStyles } from './styles'

type Props = {
  data?: Menu
  dishTypes?: DishType[]
  kitchenName?: string
}

export const Print = ({ data, kitchenName, dishTypes }: Props) => {
  const classes = useStyles()
  const [indexedDishes, setIndexedDishes] = useState<Record<
    string,
    Dish[]
  > | null>(null)

  useEffect(() => {
    if (!dishTypes) return

    const types = dishTypes
    const indexedTypes: Record<string, Dish[]> = {}

    types.forEach((type) => {
      const key = type?.name

      if (!key) return

      indexedTypes[key] = []
    })

    data?.dishList?.nodes?.forEach((item) => {
      if (!item?.dish?.type) {
        return
      }
      indexedTypes[item.dish.type].push(item.dish)
    })

    setIndexedDishes(indexedTypes)
    // eslint-disable-next-line
  }, [data, dishTypes])

  if (isNil(data)) return null

  return (
    <div className={clsx([classes.onlyPrint, classes._root])}>
      <div className={classes.container}>
        <PrintHeader kitchenName={kitchenName as string} title={data.name} />
        <div className={classes.content}>
          <div className={classes.titleRow}>
            <div className={classes.title}>Dishes</div>
            <div className={classes.endTitle}>
              <div className={classes.titleContainer}>
                <div className={classes.titleTopRow}>Cost</div>
                <div className={classes.titleBottomRow}>per serving</div>
              </div>
              <div className={classes.titleContainer}>
                <div className={classes.titleTopRow}>Gross Profit %</div>
                <div className={classes.titleBottomRow}>
                  Target: {data.gpTarget}%
                </div>
              </div>
              <div className={classes.titleContainer}>
                <div className={classes.titleTopRow}>Menu price</div>
                <div className={classes.titleBottomRow}>
                  inc. VAT & overheads
                </div>
              </div>
            </div>
          </div>
          {indexedDishes &&
            keys(indexedDishes).map((key) => {
              if (isEmpty(indexedDishes[key])) return null

              const dishes = indexedDishes[key]

              return (
                <div className={classes.section} key={key}>
                  <div className={classes.sectionTitle}>{key}</div>
                  {dishes.map((dish) => (
                    <div className={classes.row} key={`dish-${dish.id}`}>
                      <div className={classes.rowInfo}>
                        <ImageContainer
                          style={{
                            height: 50,
                            width: 50,
                          }}
                          mode="view"
                          loading={false}
                          imageSource={dish.imageUrl ?? undefined}
                          altText={dish.name}
                          setImageUrl={() => {
                            return
                          }}
                        />
                        <div className={classes.name}>{dish.name}</div>
                      </div>
                      <div className={classes.rowCosting}>
                        <div className={classes.cost}>
                          £{' '}
                          {dish.recipe.unitCost && dish.portion
                            ? (dish.recipe.unitCost / dish.portion)?.toFixed(2)
                            : 0}
                        </div>
                        <div
                          className={clsx([
                            classes.profit,
                            (dish?.gpTarget ?? 999) < data.gpTarget
                              ? classes.alert
                              : undefined,
                          ])}
                        >
                          {(dish?.gpTarget ?? 999) < data.gpTarget && (
                            <Icon
                              iconName="allergen"
                              style={{ marginRight: 5 }}
                            />
                          )}
                          {dish.gpTarget?.toFixed(2)}%
                        </div>
                        <div className={classes.price}>
                          £ {dish.price?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
            })}
        </div>
        <PrintFooter updatedAt={data.updatedAt} />
      </div>
    </div>
  )
}
