import styled from 'styled-components'

import { theme } from 'styles/newUi'

export const PublicAuthOuterDiv = styled('div')({
  alignItems: 'center',
  backgroundColor: theme.palette.secondary[100].toHexString(),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '100vh',
  width: '100%',
})
