import { setContext, init as initSentry, setTag, setUser } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { createRoot } from 'react-dom/client'

// Import styles
import './styles.css'
import '@getjelly/jelly-ui/dist/style.css'

import { getToken } from 'api/token'
import { VERSION } from 'app'

import { Container } from './Container'
import { ReloadSW } from './ReloadSW'
import { reportWebVitals } from './reportWebVitals'

// import * as serviceWorkerRegistration from './serviceWorkerRegistration'

initSentry({
  beforeSend(event) {
    // filter out UnhandledRejection errors that have no information
    if (
      event !== undefined &&
      event.exception !== undefined &&
      event.exception.values !== undefined &&
      event.exception.values.length === 1
    ) {
      const e = event.exception.values[0]

      if (
        e.type === 'UnhandledRejection' &&
        e.value === 'Non-Error promise rejection captured with value: '
      ) {
        return null
      }
    }
    return event
  },
  dsn: import.meta.env.VITE_REACT_APP_SENTRY_DSN,
  enabled:
    import.meta.env.VITE_REACT_APP_SENTRY_ENV === 'production' ||
    import.meta.env.VITE_REACT_APP_SENTRY_ENV === 'staging',
  environment: import.meta.env.VITE_REACT_APP_SENTRY_ENV,
  integrations: [new Integrations.BrowserTracing()],
  release: `kitchen-ui@${VERSION}`,
  tracesSampleRate: 0.01,
})

setContext('app settings', {
  version: VERSION,
})

setTag('app_version', VERSION)

const token = getToken()

if (token) {
  const id = token.data.userId
  setUser({ id })
}

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <Container tab="Home" />
      <ReloadSW />
    </React.StrictMode>,
  )
}

// serviceWorkerRegistration.register({
//   onUpdate: async (registration) => {
//     if (registration && registration.waiting) {
//       await registration.unregister()
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' })
//       window.location.reload()
//     }
//   },
// })

reportWebVitals()
