import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { getDeliveryAddressList } from './graphql'

import { Query } from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { MenuList, NewLayout } from '../../../components/newUi'
import { routes } from '../../../routes/Paths'

export function Addresses() {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const { data, loading } = useQuery<{ kitchenNode: Query['kitchenNode'] }>(
    getDeliveryAddressList,
    { variables: { cursor: selectedKitchen?._cursor } },
  )

  return (
    <>
      <NewLayout subtitle="Settings" title="Addresses" />

      <MenuList
        loading={loading}
        menu={{
          icon: 'email',
          name: 'Addresses',
        }}
        options={
          data?.kitchenNode?.deliveryAddressList?.nodes?.map(
            (kitchenToAddress) => ({
              key: `${kitchenToAddress.address._cursor}`,
              onClick: () =>
                navigate(
                  `${routes.Settings}${routes.Addresses}${
                    routes.Edit
                  }/${kitchenToAddress.id.toString()}`,
                ),
              title: `${kitchenToAddress.address.name}, ${kitchenToAddress.address.line1},
                 ${kitchenToAddress.address.postCode}
              `,
            }),
          ) ?? []
        }
        activation={{
          icon: 'add',
          onClick() {
            navigate(`${routes.Settings}${routes.Addresses}${routes.Add}`)
          },
          title: 'Add address',
        }}
      />
    </>
  )
}
