import { gql } from '@apollo/client'

export const getKitchensQuery = gql`
  query kitchensSelect($where: KitchenWhereInput) {
    kitchens(where: $where, take: 99999) {
      _cursor
      id
      name
    }
  }
`
