import { gql } from '@apollo/client'

export const getDeliveryAddressList = gql`
  query deliveryAddresses($cursor: ID!) {
    kitchenNode(cursor: $cursor) {
      _cursor
      id
      deliveryAddressList {
        nodes {
          _cursor
          id
          address {
            _cursor
            id
            name
            line1
            country
            postCode
          }
        }
      }
    }
  }
`

export const updateOneKitchenAddress = gql`
  mutation updateOneKitchenAddress($data: UpdateOneKitchenAddressInputObject!) {
    updateOneKitchenAddress(data: $data) {
      _cursor
      id
    }
  }
`
export const getKitchentoAddressNodeQuery = gql`
  query kitchenToAddressNode($cursor: ID!) {
    kitchenToAddressNode(cursor: $cursor) {
      _cursor
      id
      mainAddress
      address {
        _cursor
        id
        name
        line1
        city
        postCode
        deliveryInstructions
      }
    }
  }
`
export const deleteOneKitchenAddress = gql`
  mutation deleteOneKitchenAddress($data: DeleteOneKitchenAddressInputObject!) {
    deleteOneKitchenAddress(data: $data)
  }
`

export const createOneKitchenAddress = gql`
  mutation createOneKitchenAddress($data: CreateOneKitchenAddressInputObject!) {
    createOneKitchenAddress(data: $data) {
      _cursor
      id
      name
      joinCode
    }
  }
`
export const placesAutoCompleteListQuery = gql`
  query placesAutoCompleteList($where: PlacesAutoCompleteListWhereInput!) {
    placesAutoCompleteList(where: $where) {
      placeId
      description
    }
  }
`

export const placesDetailsQuery = gql`
  query placeDetails($where: PlacesDetailsListWhereInput!) {
    placeDetails(where: $where) {
      placeId
      name
      line1
      city
      postCode
    }
  }
`
