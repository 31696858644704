import { style } from 'typestyle'

import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme, isDesktop) => {
    const show = style({
      width: 'inherit',
    })

    return {
      alert: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: isDesktop ? undefined : -8,
        marginRight: isDesktop ? undefined : -8,
        marginTop: -12,
      },
      arrow: {
        position: 'absolute',
        right: 'calc(100% + 20px)',
      },
      boxWithPointer: {
        $nest: {
          '&::after': {
            // background: theme.palette.primary[20].toHexString(),
            color: theme.palette.primary[20].toHexString(),
            content: "'0'",
            height: 20,
            left: '50%',
            position: 'absolute',
            top: 'calc(100% - 10px)',
            transform: 'translateX(-50%) rotate(45deg)',
            width: 20,
            zIndex: -1,
          },
          '&::before': {
            background: theme.palette.primary[20].toHexString(),
            color: theme.palette.primary[20].toHexString(),
            content: isDesktop ? undefined : "'0'",
            height: '100%',
            left: -50,
            position: 'absolute',
            top: 0,
            width: 'calc(100vw + 50px)',
            zIndex: -1,
          },
        },
        // background: theme.palette.primary[20].toHexString(),
        borderRadius: 10,
        marginBottom: theme.spacing(2),
        padding: 12,
        paddingBottom: 0,
        // paddingRight: 12,
        position: 'relative',
      },
      buttonWrap: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
      },
      container: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
      cross: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        fontSize: 30,
        justifyContent: 'center',
        margin: theme.spacing(1),
        marginTop: theme.spacing(5),
      },
      divider: {
        backgroundColor: theme.palette.primary[20].toHexString(),
        bottom: 0,
        display: isDesktop ? 'none' : undefined,
        height: 1,
        left: '-50px',
        marginTop: theme.spacing(1),
        position: 'absolute',
        width: 'calc(100% + 100px)',
      },
      equals: {
        alignItems: 'center',
        display: 'flex',
        fontSize: 30,
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(4),
      },
      errorMessage: {
        color: theme.palette.error[100].toHexString(),
      },
      form: {
        display: 'flex',
        flex: 1,
        overflow: isDesktop ? undefined : 'hidden',
      },
      header: {
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
      },
      hideableFields: {
        $nest: {
          [`&.${show}`]: {
            maxHeight: 1000,
            overflow: 'inherit',
          },
        },
        marginTop: theme.spacing(2),
        maxHeight: 0,
        overflow: 'hidden',
        width: '100%',
      },
      innerWrap: {
        display: 'flex',
        justifyContent: 'center',
      },
      link: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        textAlign: 'center',
        textDecoration: 'none',
      },
      mobileButtonWrap: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 3,
        paddingTop: 3,
      },
      packAmountWrap: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
      },
      productCode: {
        marginBottom: theme.spacing(2),
      },
      root: {
        $nest: {
          '& a': {
            color: theme.palette.secondary[100].toHexString(),
          },
        },
        backgroundColor: theme.palette.primary[5].toHexString(),
        flex: 1,
        justifyContent: 'center',
        overflow: 'scroll',
      },
      selectUnit: {
        marginBottom: theme.spacing(2),
      },
      show,
      title: {},
      titleSlip: {
        color: theme.palette.primary[60].toHexString(),
        textDecoration: 'none',
      },
      topWrap: {
        display: isDesktop ? 'flex' : undefined,
        justifyContent: isDesktop ? 'space-between' : undefined,
        marginTop: isDesktop ? `calc(${theme.spacing(4)}px - 3px)` : undefined,
        position: 'relative',
        width: isDesktop ? '100%' : undefined,
      },
      unitConversion: {
        display: 'flex',
      },
      unitConversionDescription: {
        marginBottom: theme.spacing(2),
      },
      unitConversionTitle: {
        color: `${theme.palette.primary[100].toHexString()}!important`,
        marginBottom: theme.spacing(2),
        width: '100%',
      },
    }
  })
