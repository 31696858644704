import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { selectToken } from '../../store/auth'
import { routes } from '../Paths'

export function DashboardRedirect() {
  const navigate = useNavigate()
  const token = useSelector(selectToken())

  useEffect(() => {
    if (!token) {
      return
    }

    navigate(routes.Home, {
      replace: true,
    })
  }, [])

  return null
}
