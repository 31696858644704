import { RecipeToRecipe, RecipeToIngredient, Recipe } from 'api'
import {
  RecipeToRecipeInput,
  ComponentType,
} from 'screens/Create/helpers/types'
import {
  calculateRecipeIngredientPrice,
  calculateRecipeToRecipePrice,
} from 'utils/form/price'

export const SelectComponentsExceptType = (
  components: ComponentType[],
  type:
    | 'RecipeToRecipe'
    | 'RecipeToRecipeInput'
    | 'RecipeToIngredientInput'
    | 'RecipeToIngredient',
): ComponentType[] => {
  const filtered = components.filter(
    (component: ComponentType) => component.__typename !== type,
  )

  return filtered
}

export const SelectAddedIngredients = (): [] => {
  return []
}

export const SelectIngredients = (
  components: ComponentType[],
): RecipeToIngredient[] => {
  return components.filter(
    (component: ComponentType) => component.__typename === 'RecipeToIngredient',
  ) as RecipeToIngredient[]
}

export const SelectAddedRecipes = (
  components: ComponentType[],
): RecipeToRecipeInput[] => {
  const filtered = components.filter(
    (component: ComponentType) =>
      component.__typename === 'RecipeToRecipeInput',
  ) as RecipeToRecipeInput[]

  return filtered.map((recipe) => {
    const { name, ...rest } = recipe

    return rest
  }) as RecipeToRecipeInput[]
}

export const SelectRecipes = (
  components: ComponentType[],
): RecipeToRecipe[] => {
  return components.filter(
    (component: ComponentType) => component.__typename === 'RecipeToRecipe',
  ) as RecipeToRecipe[]
}

const calculateTotal = <T,>(items: T[], fn: (item: T) => number) => {
  const values = items.map((item) => fn(item))
  return values.length > 0 ? values.reduce((a, b) => a + b) : 0
}

export const calculateTotalCost = (recipe?: Recipe) => {
  if (!recipe) return '0.00'

  const { ingredients, childRecipes: recipes } = recipe

  const ingredientTotal = calculateTotal(
    ingredients,
    calculateRecipeIngredientPrice,
  )

  const recipeTotal = calculateTotal(recipes, calculateRecipeToRecipePrice)

  const total = recipeTotal + ingredientTotal

  return total?.toFixed(2)
}

// export const calculateTotalCostStore = (values: FormState) => {
//   const { ingredients, recipes } = values

//   return calculateTotalCost(
//     Array.from(ingredients.values()),
//     Array.from(recipes.values()),
//   )
// }
