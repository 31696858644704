export const encodeCursor = (entity: string, id: number) =>
  btoa(`{"id":"${entity}-${id}"}`)

export const decodeCursor = (cursor: string): number => {
  const dataString = Buffer.from(cursor, 'base64').toString('ascii')
  const dataJson = JSON.parse(dataString)
  const id = Number.parseInt(
    dataJson.id.slice(
      (dataJson.id.indexOf('-') as number) + 1,
      dataJson.id.length,
    ),
  )

  return id
}
