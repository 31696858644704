import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme) => ({
    _root: {
      display: 'flex',
      flexDirection: 'column',
    },
    item: {
      width: '100%',
    },
    right: {},
    titleTab: {
      backgroundColor: theme.palette.primary[10].toHexString(),
      color: theme.palette.grey[500].toHexString(),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      width: '100%',
    },
  }))
