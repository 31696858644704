import { Typography } from 'components/newUi/Typography'
import { useTheme } from 'styles/newUi'

type Props = {
  HEADER_HEIGHT: number
  label: string
  rightAdornment?: React.ReactElement
  style?: React.CSSProperties
}

export const SectionTitle = ({
  HEADER_HEIGHT,
  label,
  rightAdornment,
  style,
  ...rest
}: Props) => {
  const { theme } = useTheme()
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: theme.palette.primary[10].toHexString(),
        display: 'flex',
        height: HEADER_HEIGHT,
        left: 0,
        padding: '8px 12px',
        width: '100%',
        zIndex: 1,
        ...style,
      }}
      {...rest}
    >
      <Typography
        style={{
          color: theme.palette.primary[60].toHexString(),
        }}
        variant="subtitle2"
      >
        {label}
      </Typography>
      {rightAdornment && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            right: 20,
          }}
        >
          {rightAdornment}
        </div>
      )}
    </div>
  )
}
