import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { configureScope, setUser } from '@sentry/react'
import { isNil } from 'ramda'

import { RootState } from 'store'
import { decode } from 'utils'

type State = {
  token?: string | null
  signUpPhoneResult: {
    countryCode: string
    phoneNumber: string
    pinExpiresAt: string
  } | null
  roles: string[]
}

const initialState: State = {
  roles: [],
  signUpPhoneResult: null,
  token: null,
}

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    clearToken(state) {
      configureScope((scope) => scope.setUser(null))
      state.token = null
    },
    setSignUpPhoneResult(
      state,
      action: PayloadAction<State['signUpPhoneResult']>,
    ) {
      state.signUpPhoneResult = action.payload
    },
    setToken(state, action: PayloadAction<string | null>) {
      const token = action.payload

      if (isNil(token)) return

      const data = decode(token)?.data
      const id = data?.userId
      const roles = data?.roles

      setUser({ id })
      state.token = token
      state.roles = roles
    },
  },
})

export const { clearToken, setToken, setSignUpPhoneResult } = authSlice.actions
export const reducer = authSlice.reducer

const selectState = (state: RootState) => state.auth

export const selectToken = () =>
  createSelector(selectState, (state) => state.token)

export const selectRoles = () =>
  createSelector(selectState, (state) => state.roles)

export const selectSignUpPhoneResult = (state: RootState) =>
  state.auth.signUpPhoneResult
