import { useMutation, useQuery } from '@apollo/client'
import { ConfettiModal, KitchenSetup } from '@getjelly/jelly-ui'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { kitchenSetupStateQuery, updateOneKitchenMutation } from './graphql'
import { HomeLayout } from './HomeLayout'

import {
  Kitchen,
  Mutation,
  MutationUpdateOneKitchenArgs,
  Query,
} from '../../api'
import { useKitchen } from '../../app/contexts/SelectedKitchen'
import { Loader } from '../../components'
import { errorToast } from '../../components/toasts'
import { routes } from '../../routes/Paths'
import { AddInvoiceModal } from '../Spending/Invoices/modals/AddInvoiceModal'

type Action =
  | {
      type: 'redirect'
      route: string
    }
  | {
      type: 'custom'
      handler: () => void
    }

type Step = {
  text: string
  completed: boolean
  action: Action
}

type Props = {
  kitchen: Kitchen
}

export function HomeOnboarding({ kitchen }: Props) {
  const { refetchSelectedKitchen } = useKitchen()
  const navigate = useNavigate()

  refetchSelectedKitchen().catch(() =>
    errorToast('Failed to refetch selected kitchen.'),
  )

  const [showConfettiModal, setShowConfettiModal] = useState(false)
  const [showInvoiceModal, setShowInvoiceModal] = useState(false)

  const { data, loading } = useQuery<{
    kitchenSetupState: Query['kitchenSetupState']
  }>(kitchenSetupStateQuery, { variables: { kitchenId: kitchen.id } })

  const [updateKitchen] = useMutation<
    {
      updateOneKitchen: Mutation['updateOneKitchen']
    },
    MutationUpdateOneKitchenArgs
  >(updateOneKitchenMutation, {
    variables: {
      data: {
        id: kitchen.id,
        setupCompleted: true,
      },
    },
  })

  const steps: Step[] = useMemo(() => {
    if (loading || !data?.kitchenSetupState) {
      return []
    }

    const kitchenSetupState = data.kitchenSetupState

    return [
      {
        action: { route: routes.Settings + routes.Team, type: 'redirect' },
        completed: kitchenSetupState.addedOneTeamMember,
        text: 'Add team members',
      },
      {
        action: {
          route: routes.Spending + routes.Invoice + routes.Add,
          type: 'redirect',
        },
        completed: kitchenSetupState.addedSixInvoices,
        text: 'Upload 6 invoice photos',
      },
      {
        action: {
          handler: () => setShowInvoiceModal(true),
          type: 'custom',
        },
        completed: kitchenSetupState.automatedOneInvoice,
        text: 'Automate invoice direct from 1 supplier',
      },
      {
        action: { route: routes.Spending + routes.Changes, type: 'redirect' },
        completed: kitchenSetupState.checkedPriceChanges,
        text: 'Check big price changes',
      },
      {
        action: { route: routes.Spending + routes.FoodFlash, type: 'redirect' },
        completed: kitchenSetupState.addedOneSale,
        text: 'Get the flash report',
      },
      {
        action: { route: routes.Book + routes.Create, type: 'redirect' },
        completed: kitchenSetupState.addedOneDish,
        text: 'Cost your cookbook',
      },
      {
        action: { route: routes.Book + routes.Create, type: 'redirect' },
        completed: kitchenSetupState.addedOneMenu,
        text: 'Build a menu',
      },
      {
        action: { route: routes.Stock + routes.Take, type: 'redirect' },
        completed: kitchenSetupState.addedOneStockSheet,
        text: 'Count a stock sheet',
      },
      {
        action: { route: routes.Order + routes.History, type: 'redirect' },
        completed: kitchenSetupState.addedOneOrder,
        text: 'Place an order',
      },
    ]
  }, [data, loading])

  useEffect(() => {
    if (!steps.length) {
      return
    }

    if (steps.filter((s) => s.completed).length !== steps.length) {
      return
    }

    setShowConfettiModal(true)
  }, [steps])

  function onClick(step: Step) {
    if (step.action.type === 'redirect') {
      navigate(step.action.route)
    }

    if (step.action.type === 'custom') {
      step.action.handler()
    }
  }

  if (!steps.length) {
    return (
      <>
        <HomeLayout kitchen={kitchen} title="Onboarding" />
        <Loader />
      </>
    )
  }

  return (
    <>
      <HomeLayout kitchen={kitchen} title="Onboarding" />

      <ConfettiModal
        open={showConfettiModal}
        onClose={async () => {
          try {
            setShowConfettiModal(false)

            await updateKitchen()
            await refetchSelectedKitchen()
          } catch {
            errorToast('Unable to mark onboarding as complete.')
          }
        }}
      />

      <AddInvoiceModal
        show={showInvoiceModal}
        setDisplay={() => setShowInvoiceModal(false)}
      />

      <div className="min-h-full p-4 flex justify-center items-center">
        <div className="max-w-[32rem] w-full">
          <KitchenSetup<Step> steps={steps} onClick={onClick} />
        </div>
      </div>
    </>
  )
}
