import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import {
  CreateOneKitchenAddressInputObject,
  GooglePlaceDetailsApi,
  KitchenToAddress,
  Mutation,
  MutationCreateOneKitchenAddressArgs,
  MutationUpdateOneKitchenAddressArgs,
  UpdateOneKitchenAddressInputObject,
} from 'api'
import { NewTextField } from 'components/newUi'
import { useTheme } from 'styles/newUi'
import { cleanErrorMessage } from 'utils'

import { updateOneKitchenAddress, createOneKitchenAddress } from './graphql'

import { errorToast, successToast } from '../../../components/toasts'

export interface IProps {
  create: boolean
  sendForm: boolean
  kitchenId?: number
  kitchenToAddressNode?: KitchenToAddress
  formDirty?: (dirty: boolean) => void
  place?: GooglePlaceDetailsApi
}

export const Form = ({
  kitchenToAddressNode,
  create,
  sendForm,
  formDirty,
  place,
  kitchenId,
}: IProps) => {
  const { id } = useParams()
  const { theme } = useTheme()
  const navigate = useNavigate()

  const [updateOneAddress] = useMutation<
    { updateOneKitchenAddress: Mutation['updateOneKitchenAddress'] },
    MutationUpdateOneKitchenAddressArgs
  >(updateOneKitchenAddress)

  const [createOneAddress] = useMutation<
    { createOneKitchenAddress: Mutation['createOneKitchenAddress'] },
    MutationCreateOneKitchenAddressArgs
  >(createOneKitchenAddress)

  useEffect(() => {
    if (sendForm) {
      formik.submitForm()
    }
  }, [sendForm])

  const formik = useFormik({
    initialValues: {
      city: kitchenToAddressNode?.address.city ?? place?.city ?? '',
      deliveryInstructions:
        kitchenToAddressNode?.address.deliveryInstructions ?? undefined,
      line1: kitchenToAddressNode?.address.line1 ?? place?.line1 ?? '',
      mainAddress: kitchenToAddressNode?.mainAddress ?? false,
      name: kitchenToAddressNode?.address.name ?? place?.name ?? '',
      postCode: kitchenToAddressNode?.address.postCode ?? place?.postCode ?? '',
    },
    onSubmit: async (data) => {
      if (create) {
        const createObject: CreateOneKitchenAddressInputObject = {
          ...data,
          kitchenId: kitchenId ?? 0,
        }
        await createOneAddress({
          variables: {
            data: createObject,
          },
        })
          .then(() => {
            successToast(`A new address was added!`)
            navigate(-1)
          })
          .catch((error) => errorToast(cleanErrorMessage(error.message)))
      } else {
        const updateObject: UpdateOneKitchenAddressInputObject = {
          ...data,
          id: +id,
        }
        await updateOneAddress({
          variables: {
            data: updateObject,
          },
        })
          .then(() => {
            successToast(`Your address was updated!`)
            navigate(-1)
          })
          .catch((error) => errorToast(cleanErrorMessage(error.message)))
      }
    },
    validationSchema: Yup.object({
      city: Yup.string().required('Required'),
      deliveryInstructions: Yup.string().nullable(),
      line1: Yup.string().required('Required'),
      mainAddress: Yup.boolean(),
      name: Yup.string().required('Required'),
      postCode: Yup.string().required('Required'),
    }),
  })

  useEffect(() => {
    if (formDirty) {
      formDirty(formik.dirty)
    }
  }, [formik.dirty])

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        padding: theme.spacing(2),
      }}
    >
      <NewTextField
        inputProps={{
          'data-hj-allow': '',
        }}
        onChange={(value) => {
          formik.setFieldValue('name', value)
        }}
        value={formik.values.name}
        type="text"
        label="Name"
        required
        error={!!formik.touched.name && !!formik.errors.name}
        style={{ width: '100%' }}
      />

      <NewTextField
        inputProps={{
          'data-hj-allow': '',
        }}
        onChange={(value) => {
          formik.setFieldValue('line1', value)
        }}
        type="text"
        label="Street Address"
        value={formik.values.line1}
        required
        error={!!formik.touched.line1 && !!formik.errors.line1}
        style={{ width: '100%' }}
      />

      <NewTextField
        inputProps={{
          'data-hj-allow': '',
        }}
        onChange={(value) => {
          formik.setFieldValue('city', value)
        }}
        type="text"
        label="City"
        value={formik.values.city}
        required
        error={!!formik.touched.city && !!formik.errors.city}
        style={{ width: '100%' }}
      />

      <NewTextField
        inputProps={{
          'data-hj-allow': '',
        }}
        onChange={(value) => {
          formik.setFieldValue('postCode', value)
        }}
        type="text"
        value={formik.values.postCode}
        label="Postcode"
        required
        error={!!formik.touched.postCode && !!formik.errors.postCode}
        style={{ width: '100%' }}
      />
      <NewTextField
        inputProps={{
          'data-hj-allow': '',
          rows: 15,
          style: {
            height: 100,
            width: '100%',
          },
          value: formik.values.deliveryInstructions,
        }}
        onChange={(value) => {
          formik.setFieldValue('deliveryInstructions', value)
        }}
        type="textarea"
        // value={formik.values.deliveryInstructions}
        placeholder="Add any notes to help with delivery..."
        label="Delivery instructions"
        error={
          !!formik.touched.deliveryInstructions &&
          !!formik.errors.deliveryInstructions
        }
        style={{ width: '100%' }}
      />
    </div>
  )
}
