import Fuse from 'fuse.js'
import { sort, trim } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AutoSizer, List as VList } from 'react-virtualized'

import { SearchBar } from 'components/newUi'
import { useDebounce } from 'hooks'
import {
  selectStocktakeEntries,
  selectStocktakeLastUpdated,
} from 'store/stocktake'
import { useTheme } from 'styles/newUi'

import { Item } from './Item'

export const compareNames = (a: any, b: any) => {
  const aName =
    a.recipe?.name?.toLowerCase() ??
    a.ingredient?.product.name.toLowerCase() ??
    ''
  const bName =
    b.recipe?.name?.toLowerCase() ??
    b.ingredient?.product.name.toLowerCase() ??
    ''
  const aSupplierName =
    a.ingredient?.product?.supplier?.name.toLowerCase() ?? ''
  const bSupplierName =
    b.ingredient?.product?.supplier?.name.toLowerCase() ?? ''
  const aIsRecipe = Boolean(a.recipe)
  const bIsRecipe = Boolean(b.recipe)

  if (aIsRecipe && !bIsRecipe) return 1
  if (!aIsRecipe && bIsRecipe) return -1

  if (
    aSupplierName &&
    bSupplierName &&
    trim(aSupplierName) < trim(bSupplierName)
  )
    return -1
  if (
    aSupplierName &&
    bSupplierName &&
    trim(aSupplierName) > trim(bSupplierName)
  )
    return 1

  if (trim(aName) < trim(bName)) return -1
  if (trim(aName) > trim(bName)) return 1
  return 0
}

export const List = () => {
  const { id } = useParams()
  const entries = useSelector(selectStocktakeEntries(id))
  const lastUpdated = useSelector(selectStocktakeLastUpdated(id))
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [data, setData] = useState(sort(compareNames, entries))
  const { theme } = useTheme()
  const listRef = useRef<VList>(null)

  // TODO shouldn't use useState but useMemo
  useEffect(() => {
    const fuseOptions = {
      keys: [
        'ingredient.product.name',
        'recipe.name',
        'ingredient?.product.supplier.name',
      ],
    }

    const fuse = new Fuse(entries, fuseOptions)
    if (debouncedSearch.toLowerCase()) {
      const result = fuse.search(debouncedSearch.toLowerCase())
      setData(result.map((r) => r.item))
    } else {
      setData(sort(compareNames, entries))
    }
  }, [debouncedSearch, entries])

  useEffect(() => {
    const currentList = listRef.current
    if (currentList) {
      currentList.scrollToPosition(0)
    }
  }, [debouncedSearch])

  const scrollIndex =
    data.findIndex(
      (item) =>
        item.__typename === lastUpdated?.__typename &&
        item.ingredientId === lastUpdated?.ingredientId &&
        item.recipeId === lastUpdated?.recipeId,
    ) ?? 0

  return (
    <>
      <div style={{ padding: theme.spacing(2) }}>
        <SearchBar onSearch={setSearch} />
      </div>
      <AutoSizer>
        {({ height, width }) => {
          const rowCount = data.length ?? 0
          return (
            <VList
              containerStyle={{ overflow: 'hidden' }}
              ref={listRef}
              width={width}
              height={height - 76}
              rowCount={rowCount}
              rowHeight={() => 80}
              overscanRowCount={10}
              rowRenderer={({ index, style, key, ...props }) => {
                const rowData = data[index]
                return (
                  <Item
                    _cursor={id}
                    key={key}
                    index={index}
                    {...props}
                    data={rowData}
                    style={style}
                  />
                )
              }}
              scrollToIndex={scrollIndex}
            />
          )
        }}
      </AutoSizer>
    </>
  )
}
