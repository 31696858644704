import { useMutation, useQuery } from '@apollo/client'
import {
  TodoCreateModal,
  TodoKitchenClear,
  TodoNoTasks,
  TodoSection,
  TodoTask,
  TodoTaskProgress,
} from '@getjelly/jelly-ui'
import { format, isSameDay, isSameWeek, isSameYear } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AutoSizer, List } from 'react-virtualized'

import { kitchenTodosQuery, createKitchenTodoMutation } from './graphql'
import { HomeLayout } from './HomeLayout'

import {
  Kitchen,
  KitchenTodo,
  Mutation,
  MutationCreateOneKitchenTodoArgs,
  Query,
  QueryUserListArgs,
} from '../../api'
import { useThrottleDebounce } from '../../hooks/useThrottleDebounce'
import { routes } from '../../routes/Paths'
import { selectCounts } from '../../store/kitchen'
import { capitaliseEachWord } from '../../utils'
import { getUserListQuery } from '../Settings/Team/graphql'

type Props = {
  kitchen: Kitchen
}

type GroupedTodos = { date: Date | null; todos: KitchenTodo[] }

export function HomeTodo({ kitchen }: Props) {
  const listRef = useRef<List>(null)
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)

  const { data: userData } = useQuery<
    { kitchenNode: Query['kitchenNode'] },
    QueryUserListArgs
  >(getUserListQuery, {
    variables: { cursor: kitchen?._cursor },
  })

  const {
    data: todosData,
    fetchMore,
    loading,
    client,
  } = useQuery<{
    kitchenTodos: Query['kitchenTodos']
  }>(kitchenTodosQuery, {
    variables: {
      kitchenId: kitchen.id,
      skip: 0,
      take: 20,
    },
  })

  useEffect(() => {
    client.cache.evict({ fieldName: 'kitchenTodos' })
    client.cache.gc()
  }, [])

  const [createTodo] = useMutation<
    { createOneKitchenTodo: Mutation['createOneKitchenTodo'] },
    MutationCreateOneKitchenTodoArgs
  >(createKitchenTodoMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['kitchenTodosQuery'],
  })

  const assignees = useMemo(() => {
    if (!userData?.kitchenNode?.userList.nodes) {
      return []
    }

    return userData.kitchenNode.userList.nodes
  }, [userData])

  const groupedTodos = useMemo(() => {
    if (!todosData) return []

    const result: GroupedTodos[] = []

    const sortedTodos = [...todosData.kitchenTodos].sort((a, b) => {
      if (a.completedAt === null && b.completedAt !== null) return -1
      if (a.completedAt !== null && b.completedAt === null) return 1

      if (a.completedAt === null && b.completedAt === null) {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      }

      if (a.completedAt !== null && b.completedAt !== null) {
        return (
          new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime()
        )
      }

      return 0
    })

    for (const todo of sortedTodos) {
      const lastGroup = result[result.length - 1]
      if (!lastGroup) {
        result.push({
          date: todo.completedAt,
          todos: [todo],
        })

        continue
      }

      if (lastGroup.date === null && todo.completedAt === null) {
        result[result.length - 1].todos.push(todo)
        continue
      }

      if (
        lastGroup.date !== null &&
        isSameDay(todo.completedAt, lastGroup.date)
      ) {
        result[result.length - 1].todos.push(todo)
        continue
      }

      result.push({
        date: todo.completedAt,
        todos: [todo],
      })
    }

    return result
  }, [todosData])

  function getTitle(groupedTodo: GroupedTodos) {
    if (groupedTodo.date === null) {
      return 'To do'
    }

    if (isSameYear(groupedTodo.date, new Date())) {
      return `Completed on ${format(groupedTodo.date, 'do')} of ${format(
        groupedTodo.date,
        'LLLL',
      )}`
    }

    return `Completed on ${format(groupedTodo.date, 'do')} of ${format(
      groupedTodo.date,
      'LLLL YYYY',
    )}`
  }

  const handleScroll = useThrottleDebounce(
    ({
      scrollTop,
      scrollHeight,
      clientHeight,
    }: {
      scrollTop: number
      scrollHeight: number
      clientHeight: number
    }) => {
      if (loading) {
        return
      }

      // Fetch more if user is in bottom 90% of scroll.
      if (scrollTop + clientHeight >= scrollHeight * 0.9) {
        fetchMore({
          variables: {
            kitchenId: kitchen.id,
            skip: todosData?.kitchenTodos.length,
            take: 20,
          },
        })
      }
    },
    500,
  )

  useEffect(() => {
    if (listRef.current) {
      listRef.current.recomputeRowHeights()
    }
  }, [todosData])

  const totals = useMemo(() => {
    let completed = 0
    let all = 0

    for (const groupedTodo of groupedTodos) {
      if (!groupedTodo.date) {
        all += groupedTodo.todos.length
        continue
      }

      if (
        !isSameWeek(groupedTodo.date, new Date(), {
          weekStartsOn: 1,
        })
      ) {
        if (all === 0) all = 1
        break
      }

      all += groupedTodo.todos.length
      completed += groupedTodo.todos.length
    }

    return { all, completed }
  }, [groupedTodos])

  const counts = useSelector(selectCounts())

  return (
    <>
      <HomeLayout
        kitchen={kitchen}
        title="To-do"
        action={{
          onClick: () => setShowModal(true),
          text: 'Create Task',
        }}
      />

      <TodoCreateModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onSave={async (data) => {
          await createTodo({
            variables: {
              data: {
                assigneeId: data.assignee ? data.assignee.user.id : null,
                description: data.description,
                kitchenId: kitchen.id,
                title: capitaliseEachWord(data.title),
              },
            },
          })

          setShowModal(false)
        }}
        assignees={assignees}
        assigneeToId={(a) => a.id}
        assigneeToLabel={(a) => `${a.user.firstName} ${a.user.lastName}`}
      />

      {totals.all === 0 && counts.todoCount === 0 ? (
        <div className="w-full h-full bg-primary-50 flex items-center justify-center pb-4">
          <div className="max-w-[32rem] p-4 text-center flex flex-col items-center">
            <TodoNoTasks />
          </div>
        </div>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <List
              ref={listRef}
              rowCount={groupedTodos.length + 1}
              height={height}
              rowHeight={({ index }) => {
                // Progress
                if (index === 0) {
                  return totals.completed === totals.all ? 318 : 184
                }

                // Complete list.
                const todoHeights = groupedTodos[index - 1].todos.reduce(
                  (acc, todo) => acc + (todo.assignee ? 69 : 57),
                  0,
                )

                return 72 + todoHeights - 1
              }}
              rowRenderer={({ index, key, style }) => {
                if (index === 0 && totals.completed === totals.all) {
                  return (
                    <div key={key} style={style} className="px-4 bg-primary-50">
                      <TodoKitchenClear />
                    </div>
                  )
                }

                if (index === 0 && totals.completed !== totals.all) {
                  return (
                    <div key={key} style={style} className="px-4 pt-6">
                      <TodoTaskProgress
                        completed={totals.completed}
                        total={totals.all}
                      />
                    </div>
                  )
                }

                return (
                  <div key={key} style={style} className="px-4">
                    <TodoSection title={getTitle(groupedTodos[index - 1])}>
                      {groupedTodos[index - 1].todos.map((todo) => (
                        <TodoTask
                          key={todo.id}
                          title={todo.title}
                          subtitle={
                            todo.assignee
                              ? `${todo.assignee.firstName} ${todo.assignee.lastName}`
                              : ''
                          }
                          completed={!!todo.completedAt}
                          onClick={() => navigate(`${routes.Todo}/${todo.id}`)}
                        />
                      ))}
                    </TodoSection>
                  </div>
                )
              }}
              onScroll={handleScroll}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </>
  )
}
