import { Typography } from '@getjelly/jelly-ui'

import { InvoiceEntryBaseFragment } from 'api'

export interface IRowProps {
  hideQuantities: boolean
  entry: InvoiceEntryBaseFragment
}

export function InvoiceEntry({ entry, hideQuantities }: IRowProps) {
  return (
    <div className="flex space-x-3 px-4 bg-white border-b border-primary-100">
      {!hideQuantities && (
        <div className="w-10 flex items-center justify-start">
          <Typography style="subtitle2" className="text-primary-900">
            x{entry.quantity}
          </Typography>
        </div>
      )}

      <div className="flex-1 flex justify-between py-3">
        <div className="flex flex-col justify-center space-y-1">
          <Typography
            style="body2"
            className="capitalize text-primary-900 line-clamp-1"
          >
            {entry.product.name.toLowerCase()}
          </Typography>

          <p className="font-lato text-xs font-black uppercase text-primary-400 tracking-widest">
            {entry.packSize}x{entry.unitValue}
            {entry.unit.name} = £{entry.price?.toFixed(2)}
          </p>
        </div>

        {!hideQuantities && (
          <div className="text-right flex flex-col justify-center shrink-0">
            <Typography style="subtitle1" className="text-primary-900">
              £{(entry.price * entry.quantity).toFixed(2)}
            </Typography>

            <Typography style="body2" className="text-primary-400">
              (excl. VAT)
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}
