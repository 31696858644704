import { Button } from '@getjelly/jelly-ui'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import { NewLayout, ScrollMenu, ScrollMenuItem } from 'components/newUi'
import { routes } from 'routes/Paths'
import { useTheme } from 'styles/newUi'

import { useKitchen } from '../../app/contexts/SelectedKitchen'

export const BookLayout = () => {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { theme } = useTheme()

  return (
    <>
      <NewLayout
        title="Cookbook"
        onBack={() => navigate(-1)}
        rightContent={
          selectedKitchen?.userPermissions?.includes('create-recipe') &&
          selectedKitchen?.userPermissions?.includes('create-dish') &&
          selectedKitchen?.userPermissions?.includes('create-menu') ? (
            <div className="px-2">
              <Button
                onClick={() => navigate(`${routes.Book}${routes.Create}`)}
                label="Create New"
              />
            </div>
          ) : undefined
        }
      />

      <ScrollMenu>
        <ScrollMenuItem
          text="Dishes & Recipes"
          selected={pathname.includes(`${routes.Book}${routes.List}`)}
          route={`${routes.Book}${routes.List}`}
        />

        <ScrollMenuItem
          text="Menus"
          selected={pathname.includes(`${routes.Book}${routes.Menu}`)}
          route={`${routes.Book}${routes.Menu}`}
        />
      </ScrollMenu>

      <div
        style={{
          backgroundColor: theme.palette.common.light.toHexString(),
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Outlet />
      </div>
    </>
  )
}
