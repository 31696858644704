import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { v4 } from 'uuid'

import { RootState } from 'store'

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type Toast = {
  id: string
  message: string
  type: 'success' | 'error' | 'info' | 'warning'
}

type State = {
  toasts: Toast[]
}

const initialState: State = {
  toasts: [],
}

const toastSlice = createSlice({
  initialState,
  name: 'toastQueue',
  reducers: {
    clearToasts(state) {
      state.toasts = []
    },

    dequeueToast(state) {
      state.toasts.shift()
    },

    enqueueToast(state, action: PayloadAction<Optional<Toast, 'id'>>) {
      state.toasts.push({
        id: action.payload.id ?? v4(),
        message: action.payload.message,
        type: action.payload.type,
      })
    },
  },
})

export const { enqueueToast, dequeueToast, clearToasts } = toastSlice.actions
export const reducer = toastSlice.reducer

const selectState = (state: RootState) => state.toastQueue

export const selectToastQueue = () =>
  createSelector(selectState, (state) => state.toasts)
