import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme, isDesktop) => ({
    _root: {
      display: !isDesktop ? 'grid' : undefined,
      gap: '0px 0px',
      gridTemplateAreas: '"." "."',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '105px 1fr',
      height: '100vh',
    },
    actionBar: {
      display: isDesktop ? 'flex' : undefined,
      justifyContent: isDesktop ? 'space-between' : undefined,
      marginTop: isDesktop ? theme.spacing(4) : undefined,
      width: isDesktop ? '100%' : undefined,
    },
    alert: {
      marginBottom: isDesktop ? theme.spacing(2) : undefined,
      marginTop: isDesktop ? theme.spacing(2) : undefined,
    },
    arrow: {
      color: theme.palette.secondary[100].toHexString(),
      display: 'flex',
      marginRight: theme.spacing(1),
    },
    buttonWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      minWidth: 240,
      width: '100%',
    },
    divider: {
      backgroundColor: theme.palette.primary[20].toHexString(),
      display: isDesktop ? 'none' : undefined,
      height: 1,
      marginTop: theme.spacing(1),
      width: '100%',
    },
    flexCol: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      height: '100%',
    },
    info: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[100].toHexString()}`,
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
      width: '100%',
    },
    itemRoot: {
      alignItems: 'center',
      // borderBottom: `1px solid ${theme.palette.grey[500].toHexString()}`,
      display: 'grid',
      gridTemplateColumns: '1fr 50px',
      height: 80,
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      width: '100%',
    },
    itemRootDesktop: {
      alignContent: 'center',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.primary[20].toHexString()}`,
      cursor: 'pointer',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      height: '100%',
      justifyItems: 'center',
      width: '100%',
    },
    link: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.primary[20].toHexString()}`,
      color: 'unset',
      display: 'flex',
      justifyContent: 'space-between',
      textDecoration: 'none',
      width: '100%',
    },
    list: {
      height: '100%',
      overflow: 'hidden',
      overflowY: 'scroll',
    },
    listContainer: {
      // backgroundColor: '#FFFFFF',
    },
    listItem: {},
    listItemDesktop: {
      // backgroundColor: '#FFFFFF',
      height: 50,
    },
    mobileButtonWrap: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey[50].toHexString(),
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      height: 80,
      justifyContent: 'center',
    },
    search: {
      marginTop: isDesktop ? 5 : undefined,
      minWidth: 220,
      width: isDesktop ? '100%' : undefined,
    },
    sectionClass: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    selected: {
      fontWeight: 'bold',
    },
    supplierName: {
      fontSize: 16,
      textTransform: 'capitalize',
    },
    type: {
      display: 'flex',
      justifyContent: 'center',
      padding: 50,
      textAlign: 'center',
    },
  }))
