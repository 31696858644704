/* @TODO: Rewrite this with new queries, components and styles */
import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import { observer } from 'mobx-react-lite'
import { isNil, isEmpty } from 'ramda'
import { useEffect, useRef, useState, ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom'

import {
  QueryMode,
  QueryIngredientsAndRecipesArgs,
  IngredientAndRecipe,
  Ingredient,
  Recipe,
  Status,
} from 'api'
import { useReturnToPageContext } from 'app/contexts/ReturnToPage'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader, Grid } from 'components'
import {
  BottomModal,
  SearchBar,
  SectionList,
  Icon,
  Button,
  NewLayout,
} from 'components/newUi'
import { useWindowSize } from 'hooks'
import { useNewStateStore } from 'mobx/StateStore/Meta'
import { routes } from 'routes/Paths'
import { addItem } from 'store/stocktake'
import { useTheme } from 'styles/newUi'

import { ingredientsAndRecipesByKitchenQuery } from './graphql'
import { Item } from './Item'
import { useStyles } from './styles'

export const AddItem = observer(() => {
  const dispatch = useDispatch()
  const inputEl = useRef<HTMLInputElement>(null)
  const { id: _cursor } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('query') ?? ''

  const navigate = useNavigate()
  const classes = useStyles()
  const { gte: isDesktop } = useWindowSize('md')
  const { selectedKitchen } = useKitchen()
  const { theme } = useTheme()
  const [, setButtonFocus] = useState(false)

  const location = useLocation()
  const formStateStore = useNewStateStore()
  const returnToPageContext = useReturnToPageContext()

  const formState = formStateStore.createOrGet(location.pathname)

  useEffect(() => {
    formState.init([], [])
  }, [formState])

  const take = 10
  const [ingredientTake, setIngredientTake] = useState(take)
  const [showAllRecipes, setShowAllRecipes] = useState(false)
  const [showCatalog, setShowCatalog] = useState(false)
  const [data, setData] = useState<
    { count: number; name: string; data: (Recipe | Ingredient)[] }[]
  >([])
  const [showLoader, setShowLoader] = useState(false)

  const {
    data: ingredientData,
    loading,
    error,
  } = useQuery<
    {
      ingredientsAndRecipes: IngredientAndRecipe
    },
    QueryIngredientsAndRecipesArgs
  >(ingredientsAndRecipesByKitchenQuery, {
    variables: {
      ingredientWhere: {
        kitchenId: {
          equals: selectedKitchen?.id,
        },
        product: isEmpty(search)
          ? undefined
          : {
              OR: [
                {
                  name: {
                    contains: isEmpty(search) ? null : search,
                    mode: QueryMode.Insensitive,
                  },
                },
                {
                  supplier: {
                    name: {
                      contains: isEmpty(search) ? null : search,
                      mode: QueryMode.Insensitive,
                    },
                  },
                },
              ],
            },
      },
      orderByName: true,
      recipeTake: 100,
      recipeWhere: {
        NOT: [
          {
            status: { equals: Status.Inactive },
          },
          {
            isDraft: {
              equals: null,
            },
          },
        ],
        OR: [
          {
            isDraft: {
              equals: false,
            },
          },
        ],
        dishes: {
          none: {},
        },
        kitchenId: {
          equals: selectedKitchen?.id,
        },
        name: isEmpty(search)
          ? undefined
          : {
              contains: isEmpty(search) ? null : search,
              mode: QueryMode.Insensitive,
            },
      },
      searchTerm: isEmpty(search) ? undefined : search,
      take: ingredientTake,
    },
  })
  useEffect(() => {
    inputEl.current?.focus()
  }, [])

  const handleSubmit = () => {
    formState.items.forEach((entry) => {
      const type = entry.__typename!.toLowerCase() as 'ingredient' | 'recipe'

      dispatch(
        addItem({
          _cursor,
          entry,
          type,
        }),
      )
    })
    formState.init([], [], true)
    navigate(`${routes.Stock}/${_cursor}`, { replace: true })
  }

  useEffect(() => {
    if (!ingredientData) return
    if (!search) return setData([])
    const rpes = ingredientData?.ingredientsAndRecipes.recipes ?? []
    const ings = ingredientData?.ingredientsAndRecipes.ingredients ?? []
    const draftCount = rpes.filter((r) => r.isCalculatedDraft).length
    const rpeFiltered = rpes.filter((r) => !r.isCalculatedDraft)
    const rpesData =
      rpeFiltered.length > 3 && !showAllRecipes
        ? rpeFiltered.slice(0, 3)
        : rpeFiltered
    const recipes = {
      count: rpes.length - draftCount,
      data: rpesData,
      name: 'Recipes',
    }
    const ingredients = {
      count: ingredientData.ingredientsCount,
      data: ings,
      name: 'Products',
    }
    const list: {
      count: number
      name: string
      data: (Recipe | Ingredient)[]
      append?: ReactElement
    }[] = [
      {
        ...recipes,
        append: rpeFiltered.length > 3 ? recipesButtons() : undefined,
      },
      {
        ...ingredients,
        append: ingredientButtons(ingredients, showCatalog),
      },
    ]
    setData(list)
    setShowCatalog(
      !isEmpty(search) && ingredients.count === ingredients.data.length,
    )
  }, [ingredientData, search, showCatalog, showAllRecipes])

  useEffect(() => {
    setShowLoader(true)
  }, [search])

  useEffect(() => {
    setShowAllRecipes(false)
  }, [ingredientData])

  const recipesButtons = () => {
    return !isEmpty(search) && !showAllRecipes ? (
      <Grid container item xs={12} className={classes.mobileButtonWrap}>
        <Typography
          onClick={() => {
            setShowAllRecipes(true)
          }}
          variant="body2"
          style={{
            alignItems: 'center',
            color: theme.palette.secondary[100].toHexString(),
            cursor: 'pointer',
            display: 'flex',
            fontWeight: 600,
            height: 40,
          }}
        >
          Show More Recipes
          <Icon iconName="chevronDown" />
        </Typography>
      </Grid>
    ) : undefined
  }
  const ingredientButtons = (_ingredients: any, show: boolean) => {
    return show ? (
      <Grid
        container
        item
        xs={12}
        style={{
          alignItems: 'center',
          backgroundColor: theme.palette.primary[5].toHexString(),
          borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 20,
          textAlign: 'center',
        }}
      >
        <Typography
          variant="body2"
          style={{
            alignItems: 'center',
            color: theme.palette.primary[80].toHexString(),
            cursor: 'pointer',
            display: 'flex',
            fontWeight: 600,
          }}
        >
          No more products or recipes matching {`"`}
          {search}
          {`"`}
        </Typography>
        <Typography
          onClick={() => {
            setSearchParams({ query: '' }, { replace: true })
          }}
          variant="body2"
          style={{
            alignItems: 'center',
            color: theme.palette.secondary[100].toHexString(),
            cursor: 'pointer',
            display: 'flex',
            fontWeight: 600,
            height: 40,
          }}
        >
          Show All {'>'}
        </Typography>
      </Grid>
    ) : undefined
  }

  const onSearch = debounce((query: string) => {
    if (query.length < 2) return
    setSearchParams({ query }, { replace: true })
  }, 500)

  return (
    <>
      <NewLayout
        title="Add Items"
        subtitle="Stocktake"
        hideMenu
        bottomContent={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              disabled={!formState.items.length}
              text={`Add ${formState.items.length} Item(s)`}
              rightAdornment={
                <Icon
                  iconName="add"
                  style={{ marginLeft: 4, marginRight: -4 }}
                />
              }
              onClick={() => handleSubmit()}
            />
          </div>
        }
      />
      <div
        style={{
          backgroundColor: theme.palette.primary[10].toHexString(),
          paddingBottom: theme.spacing(1),
          paddingTop: theme.spacing(1),
        }}
      >
        <SearchBar
          search={search}
          searchPlaceholder="Search products or recipes"
          onSearch={onSearch}
          ref={inputEl}
          onFocus={() => {
            setButtonFocus(false)
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              setButtonFocus(true)
              // @ts-ignore
              e.target.blur()
            }
          }}
        />
      </div>
      <div
        className={classes.list}
        onScroll={(e) => {
          const listContainer = document.getElementsByClassName(
            classes.listContainer,
          )[0]
          if (!listContainer || showCatalog) return
          const endOfScroll =
            // @ts-ignore
            listContainer.offsetHeight -
            // @ts-ignore
            (e.target.scrollTop + e.target.offsetHeight)
          if (endOfScroll < 200 && !loading) {
            setShowLoader(false)
            setIngredientTake(ingredientTake + 10)
          }
        }}
      >
        {loading && showLoader ? (
          <Loader />
        ) : !isNil(error) ? (
          <div className={classes.info}>
            <Typography variant="body2">Something went wrong</Typography>
          </div>
        ) : (
          <SectionList
            placeholder={'true'}
            data={data}
            rootClass={classes.listContainer}
            itemClass={isDesktop ? classes.listItemDesktop : classes.listItem}
            sectionClass={classes.sectionClass}
            item={({ item }: { item: Recipe | Ingredient }) => {
              const selected = !!(item.__typename === 'Recipe'
                ? formState.recipes.get(item.id)
                : formState.ingredients.get(item.id))

              return (
                <Item
                  selected={selected}
                  search={search}
                  item={item}
                  handleOnClick={(clicked) => {
                    selected
                      ? formState.removeItem(clicked)
                      : formState.addItem(clicked)
                  }}
                />
              )
            }}
            empty={() => (
              <div className={classes.info}>
                <Typography variant="body2">
                  Could not find any items
                </Typography>
              </div>
            )}
            showItems={!isEmpty(search)}
            showActive
            append={
              isEmpty(search) ? (
                <div className={classes.type}>
                  <Typography
                    variant="body1"
                    style={{ color: theme.palette.grey[500].toHexString() }}
                  >
                    Search for products or recipes to add to this stocktake
                  </Typography>
                </div>
              ) : (
                <BottomModal
                  buttonVariant="white"
                  text="Can't find a product?"
                  buttonText={'Search Catalogue'}
                  onClick={() => {
                    // dispatch(setProductSearch(search))
                    returnToPageContext.saveRouteAndNavigate(routes.Supplier)
                  }}
                />
              )
            }
          />
        )}
      </div>
    </>
  )
})
