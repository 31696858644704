import { gql } from '@apollo/client'

export const spendInsights = gql`
  query spendInsights(
    $kitchenId: Int!
    $start: DateTime!
    $period: PeriodEnum!
  ) {
    spendInsights(kitchenId: $kitchenId, start: $start, period: $period) {
      totalInvoicesNeedAttention
      totalSpend
      previousSpend
      spendData {
        date
        spend
      }
      spendTotal {
        supplierId
        supplierName
        total
        invoiceCount
      }
    }
  }
`

export const invoiceAggregate = gql`
  query invoiceAggregate(
    $kitchenId: Int!
    $startDate: Date!
    $endDate: Date!
    $previousStartDate: Date!
    $previousEndDate: Date!
    $where: PendingInvoiceWhereInput
  ) {
    invoiceAggregate(
      kitchenId: $kitchenId
      startDate: $startDate
      endDate: $endDate
    ) {
      totalSpend
      totalVat
      invoicesBySupplier {
        id
        name
        totalSpend
        totalVat
        invoices {
          _cursor
          id
          invoiceDate
          invoiceTotal
          invoiceId
          createdAt
        }
      }
      invoicesByDate {
        date
        totalSpend
        totalVat
        invoices {
          _cursor
          id
          invoiceDate
          invoiceTotal
          createdAt
        }
      }
    }
    previous: invoiceAggregate(
      kitchenId: $kitchenId
      startDate: $previousStartDate
      endDate: $previousEndDate
    ) {
      totalSpend
      totalVat
      invoicesByDate {
        date
        totalSpend
        totalVat
        invoices {
          _cursor
          id
          invoiceDate
          invoiceTotal
          createdAt
        }
      }
    }
    pendingInvoices(take: 1, where: $where) {
      _cursor
      id
    }
  }
`
