import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { selectToken } from '../../store/auth'
import { routes } from '../Paths'

export function NotFoundRedirect() {
  const navigate = useNavigate()
  const token = useSelector(selectToken())

  useEffect(() => {
    if (token) {
      navigate(routes.Home, {
        replace: true,
      })
    } else {
      navigate(routes.SignIn, {
        replace: true,
      })
    }
  }, [navigate])

  return null
}
