import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme, isDesktop) => ({
    actionButtons: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-around',
      maxWidth: '100vw',
      paddingBottom: 8,
      paddingTop: 8,
    },
    actionTrayButton: {
      width: 100,
      // [theme.breakpoints('sm')]: {
      //   width: '20%',
      // },
    },
    allergen: {
      ' & svg': {
        height: 50,
        width: 50,
      },
      filter:
        'invert(69%) sepia(1%) saturate(0%) hue-rotate(335deg) brightness(89%) contrast(95%)',
      paddingRight: 10,
      stroke: theme.palette.grey[50].toHexString(),
    },
    allergenRow: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[100].toHexString()}`,
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: 100,
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
    },
    container: {
      backgroundColor: theme.palette.common.light.toHexString(),
      height: '100%',
    },
    divider: {
      backgroundColor: theme.palette.primary[20].toHexString(),
      display: isDesktop ? 'none' : undefined,
      height: 1,
      width: '100%',
    },
    icon: {},
    info: {
      textAlign: 'center',
    },
    item: {
      alignItems: 'center',
      border: '1px solid #e0e0e0',
      cursor: 'pointer',
      display: 'flex',
      flex: 1,
      width: '100%',
    },
    itemContainer: {
      alignItems: 'center',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderColor: theme.palette.primary[20].toHexString(),
      display: 'flex',
      height: 52,
      justifyContent: 'space-between',
      padding: '15px',
      width: '100%',
    },
    noAllergens: {
      ' & svg': {
        height: 50,
        width: 50,
      },
      filter:
        'invert(65%) sepia(79%) saturate(323%) hue-rotate(32deg) brightness(94%) contrast(92%)',
    },
    photoBox: {
      alignItems: 'center',
      backgroundColor: '#e0e0e0',
      display: 'flex',
      fontSize: 10,
      height: isDesktop ? 180 : 133,
      justifyContent: 'center',
      width: '100%',
    },
    subtext: {
      color: theme.palette.primary[40].toHexString(),
      display: 'flex',
      flex: 1,
      fontSize: 14,
      paddingTop: 2,
    },
    title: {},
  }))
