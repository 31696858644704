import { useNavigate } from 'react-router-dom'

import { Typography } from 'components'
import { NewLayout } from 'components/newUi'
import { Icon, iconDictionary } from 'components/newUi/Icon'
import { routes } from 'routes/Paths'
import { useTheme } from 'styles/newUi'

import { useKitchen } from '../../app/contexts/SelectedKitchen'

export const Create = () => {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  return (
    <div style={{ paddingTop: 12 }}>
      <NewLayout hideMenu title="Create New" subtitle="Costing" />

      {selectedKitchen?.userPermissions?.includes('create-recipe') && (
        <Item
          title="Recipe"
          iconName="libraryBooks"
          description="Something made in batches, like a pasta sauce"
          onClick={() =>
            navigate(`${routes.Book}${routes.Recipe}${routes.Create}`, {
              replace: true,
            })
          }
        />
      )}

      {selectedKitchen?.userPermissions?.includes('create-dish') && (
        <Item
          title="Dish"
          iconName="fastfoodIcon"
          description="Something you sell to a customer, like Spaghetti Carbonara"
          onClick={() =>
            navigate(`${routes.Book}${routes.Dish}${routes.Create}`, {
              replace: true,
            })
          }
        />
      )}

      {selectedKitchen?.userPermissions?.includes('create-menu') && (
        <Item
          title="Menu"
          iconName="menuIconBook"
          description="Collections of dishes, like a Dinner menu"
          onClick={() =>
            navigate(`${routes.Book}${routes.Menu}${routes.Create}`, {
              replace: true,
            })
          }
        />
      )}
    </div>
  )
}

export interface IProps {
  title: string
  iconName: keyof typeof iconDictionary
  description: string
  onClick: () => void
}

const Item = ({ title, iconName, description, onClick }: IProps) => {
  const { theme } = useTheme()

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={onClick}
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: '0px 2px 3px rgba(76, 89, 110, 0.1)',
        cursor: 'pointer',
        marginBottom: 16,
        marginLeft: 12,
        marginRight: 12,
        minHeight: 126,
        paddingBottom: 16,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 16,
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
        className="space-y-4 p-4"
      >
        <Icon
          iconName={iconName}
          style={{
            color: theme.palette.primary[60].toHexString(),
            fontSize: 35,
          }}
        />

        <div className="flex flex-col items-center w-full">
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {title}
          </Typography>

          <Typography
            variant="caption"
            style={{ textAlign: 'center', width: '92%' }}
          >
            {description}
          </Typography>
        </div>
      </div>
    </div>
  )
}
