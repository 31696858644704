import { useChat } from 'app/contexts/Chat'
import { Icon } from 'components/newUi/Icon'
import { Typography } from 'components/newUi/Typography'
import { useWindowSize } from 'hooks'
import { useTheme } from 'styles/newUi'

export const Chat = () => {
  const { open, setOpen } = useChat()
  const { theme } = useTheme()
  const { gte: isDesktop } = useWindowSize('md')

  return (
    <div
      style={{
        borderBottom: isDesktop ? undefined : `12px solid white`,
        display: open ? 'block' : 'none',
        height: isDesktop ? '100%' : 'calc(100% - 47px)',
        left: 0,
        padding: isDesktop ? '5vh 20vw 10vh' : '5vh 5vw',
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 9999,
      }}
    >
      {/* eslint-disable-next-line */}
      <div
        onClick={() => setOpen(false)}
        style={{
          backgroundColor: theme.palette.primary[100].fade(0.6).toString(),
          cursor: 'pointer',
          height: '100vh',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100vw',
          zIndex: -1,
        }}
      />
      <div
        style={{
          height: '100%',
          position: 'relative',
          width: '100%',
        }}
        id="hubspot-chat"
      >
        {/* eslint-disable-next-line */}
        <div
          style={{
            alignItems: 'center',
            backgroundColor: theme.palette.primary[100].toHexString(),
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '8px 8px 0',
            position: 'relative',
            width: '100%',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Icon
            iconName="close"
            style={{
              color: theme.palette.common.light.toHexString(),
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          style={{
            alignItems: 'center',
            backgroundColor: theme.palette.primary[5].toHexString(),
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            padding: theme.spacing(2),
            position: 'absolute',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.primary[80].toHexString(),
              fontWeight: 500,
            }}
          >
            Sorry, there&apos;s a problem showing our help chatbox
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.primary[80].toHexString(),
              marginTop: theme.spacing(1),
            }}
          >
            Email us instead at{' '}
            <a
              href="mailto:letscook@getjelly.co.uk"
              style={{
                color: theme.palette.primary[80].toHexString(),
              }}
            >
              letscook@getjelly.co.uk
            </a>{' '}
            and we&apos;ll try to help.
          </Typography>
        </div>
      </div>
    </div>
  )
}
