import { gql } from '@apollo/client'

export const ingredientChangesQuery = gql`
  query IngredientChanges(
    $where: KitchenWhereUniqueInput!
    $skip: Int!
    $take: Int!
  ) {
    kitchen(where: $where) {
      _cursor
      id
      priceChangesList(skip: $skip, take: $take) {
        priceChanges {
          changes {
            _cursor
            id
            price
            supplierId
            supplierName
            ingredientName
            percentageChange
          }
          date
        }
        total
      }
    }
  }
`
