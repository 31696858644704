import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'

import { UnitType, useGetPriceChangesQuery } from 'api'
import { ScrollMenu, ScrollMenuItem } from 'components/newUi'
import { Typography } from 'components/newUi/Typography'
import { usePathname } from 'hooks'
import { routes } from 'routes/Paths'
import { changeColor } from 'screens/Products/PriceHistory'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord } from 'utils'

export const ViewProduct = () => {
  const pathname = usePathname()
  const { id } = useParams()

  const { data } = useGetPriceChangesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      cursor: id,
    },
  })

  const product = data?.ingredientNode?.product ?? {
    packSize: undefined,
    unit: undefined,
    unitValue: undefined,
  }

  const packSizeText = product.packSize !== 1 ? `${product.packSize} x ` : ''
  const unitName = product.unit
    ? product.unit.type === UnitType.Custom && product.unitValue !== 1
      ? ` ${product.unit.name}s`
      : ` ${product.unit.name}`
    : ''
  return (
    <>
      <Item
        leftText={data?.ingredientNode?.product.name ?? ''}
        subtext={`${data?.ingredientNode?.product.supplier.name}`}
        rightText={`${packSizeText}${product.unitValue} ${unitName}`}
        productCode={data?.ingredientNode?.product.code ?? 'No Code'}
      />
      <Info
        lastUpdated={dayjs(
          data?.ingredientNode?.priceChangesList.priceChanges[0]?.date ||
            data?.ingredientNode?.updatedAt,
        ).format('DD MMM YYYY')}
        change={
          data?.ingredientNode?.priceChangesList.priceChanges[0]?.changes[0]
            ?.percentageChange ?? 0
        }
        latestPrice={
          data?.ingredientNode?.price
            ? `£${data.ingredientNode.price.toFixed(2)}`
            : 'No Price'
        }
      />
      <ScrollMenu>
        <ScrollMenuItem
          text="Price History"
          selected={
            pathname === routes.Product ||
            (pathname.includes(`${routes.Product}/${id}`) &&
              !pathname.includes(`${routes.Order}`) &&
              !pathname.includes(`${routes.Costing}`))
          }
          route={`${routes.Product}/${id}${routes.Price}${routes.History}`}
        />
        <ScrollMenuItem
          text="Order History"
          selected={pathname.includes(
            `${routes.Product}/${id}${routes.Order}${routes.History}`,
          )}
          route={`${routes.Product}/${id}${routes.Order}${routes.History}`}
        />
        <ScrollMenuItem
          text="Costing"
          selected={pathname.includes(
            `${routes.Product}/${id}${routes.Costing}`,
          )}
          route={`${routes.Product}/${id}${routes.Costing}`}
        />
      </ScrollMenu>
    </>
  )
}

const ROW_HEIGHT = 76

export const Item = ({
  leftText,
  subtext,
  rightText,
  productCode,
}: {
  leftText: string
  subtext: string
  rightText: string
  productCode: string
}) => {
  const { theme } = useTheme()

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        style={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
          display: 'grid',
          gap: '0 5px',
          gridTemplateColumns: '1fr 0.25fr',
          height: ROW_HEIGHT,
          paddingLeft: theme.spacing(2),
          paddingRight: 12,
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="h6"
            style={{
              marginBottom: 4,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
            }}
          >
            {capitaliseEachWord(leftText)}
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: theme.palette.primary[60].toHexString(),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
            }}
          >
            {capitaliseEachWord(subtext)}
          </Typography>
        </div>
        <div
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 500,
              overflow: 'hidden',
              textAlign: 'end',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            {capitaliseEachWord(rightText)}
          </Typography>
          <Typography variant="subtitle1">{productCode}</Typography>
        </div>
      </div>
    </>
  )
}

export const Info = ({
  lastUpdated,
  change,
  latestPrice,
}: {
  lastUpdated: string
  change: number
  latestPrice: string
}) => {
  const { theme } = useTheme()

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        style={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
          display: 'grid',
          gridTemplateColumns: '1fr 0.7fr 25px',
          height: ROW_HEIGHT,
          paddingLeft: theme.spacing(2),
          paddingRight: 12,
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        <div style={{ overflow: 'hidden' }}>
          <Typography
            variant="caption"
            style={{ fontWeight: 500, marginBottom: 4 }}
          >
            Last Updated
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              color: theme.palette.primary[60].toHexString(),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
            }}
          >
            {lastUpdated}
          </Typography>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <Typography
            variant="caption"
            style={{ fontWeight: 500, marginBottom: 4 }}
          >
            Change
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              color: changeColor(change),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
            }}
          >
            {change > 0 ? '+' : ''}
            {change}%
          </Typography>
        </div>
        <div
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            style={{ fontWeight: 500, marginBottom: 4 }}
          >
            Latest price
          </Typography>

          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.secondary[100].toHexString(),
              fontWeight: 500,
            }}
          >
            {latestPrice}
          </Typography>
        </div>
      </div>
    </>
  )
}
