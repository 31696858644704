import { isNil } from 'ramda'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { UnitType, Unit } from 'api'
import { AddIcon, RemoveIcon } from 'components'
import { Icon, Typography } from 'components/newUi'
import { TextField } from 'components/newUi/TextField'
import { NewModal } from 'screens/Create/NewModal'
import {
  deleteItem,
  updateItemValue,
  updateItemUnit,
  StockTakeEntry,
} from 'store/stocktake'
import { useTheme } from 'styles/newUi'
import { formatCurrency } from 'utils/currency/formatCurrency'

import { useStyles } from './styles'

import { useKitchen } from '../../../../../app/contexts/SelectedKitchen'
import { SelectUnit } from '../SelectUnit/SelectUnit'

type Props = {
  _cursor: string
  data: StockTakeEntry
  index: number
  style: React.CSSProperties
}

export const Item = ({ _cursor, data, style }: Props) => {
  const { theme } = useTheme()
  const { selectedKitchen } = useKitchen()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const classes = useStyles()

  const name =
    data.ingredient?.product?.name.toLowerCase() ??
    data.recipe?.name.toLowerCase()

  const isRecipe = Boolean(data?.recipeId)
  const supplier = isRecipe
    ? 'recipe'
    : data?.ingredient?.product?.supplier?.name.toLowerCase()

  const amountInt = isRecipe
    ? data?.recipe?.amount ?? 1
    : (data?.ingredient?.product.packSize ?? 1) *
      (data?.ingredient?.product.unitValue ?? 1)

  const price = !data.price
    ? 'No Price'
    : `£${(data.price / amountInt)?.toFixed(2)}`

  const unitValue = data.unit.name

  const unitType = data.unit.type

  const quantity = data.quantity ?? 0

  const unit =
    quantity > 1 && unitType === UnitType.Custom
      ? ` ${unitValue}s`
      : unitType === UnitType.Custom
      ? ` ${unitValue}`
      : unitValue

  const type = isRecipe ? 'recipe' : 'ingredient'
  const id = (isRecipe ? data.recipeId : data.ingredientId) as number

  return (
    <>
      <div
        data-testid={`row_${name}`}
        className={classes.wrapper}
        style={{
          ...style,
        }}
      >
        {selectedKitchen?.userPermissions?.includes('delete-stock-entry') && (
          <div className={classes.leftSectionWrppaer}>
            <Icon
              onClick={() => dispatch(deleteItem({ _cursor, id, type }))}
              iconName="close"
              className={classes.leftSectionWrapperIcon}
              style={{
                color: selectedKitchen?.userPermissions?.includes(
                  'delete-stock-entry',
                )
                  ? theme.palette.error[100].toHexString()
                  : theme.palette.error[50].toHexString(),
              }}
            />
          </div>
        )}

        <div className="flex flex-1 flex-col">
          <div
            style={{
              alignItems: 'center',
              display: 'grid',
              gridTemplateColumns: '1fr 0fr',
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontSize: 16,
                fontWeight: 500,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
                whiteSpace: 'nowrap',
              }}
            >
              {name}
            </Typography>

            <div className="flex items-center">
              {selectedKitchen?.userPermissions?.includes(
                'update-stock-entry',
              ) && (
                <RemoveIcon
                  onClick={() => {
                    if (!quantity) return

                    dispatch(
                      updateItemValue({
                        _cursor,
                        id,
                        type,
                        value: Number(quantity) - 1,
                      }),
                    )
                  }}
                  style={{
                    color:
                      quantity > 0 &&
                      selectedKitchen?.userPermissions?.includes(
                        'update-stock-entry',
                      )
                        ? theme.palette.secondary[100].toHexString()
                        : theme.palette.secondary[50].toHexString(),
                    cursor: quantity > 0 ? 'pointer' : undefined,
                    height: 35,
                    pointerEvents: quantity > 0 ? undefined : 'none',
                    width: 35,
                  }}
                />
              )}

              <TextField
                style={{
                  height: 36,
                  padding: 0,
                  width: 70,
                }}
                error={isNil(quantity) || !data.price}
                min="0"
                type="text"
                value={quantity}
                inputProps={{
                  inputMode: 'decimal',
                  maxLength: 5,
                  pattern: '[0-9]+\\.{0,1}[0-9]*',
                  style: { height: 36, textAlign: 'center', width: 70 },
                }}
                onChange={(value) =>
                  selectedKitchen?.userPermissions?.includes(
                    'update-stock-entry',
                  ) &&
                  dispatch(
                    updateItemValue({
                      _cursor,
                      id,
                      type,
                      value,
                    }),
                  )
                }
              />

              {selectedKitchen?.userPermissions?.includes(
                'update-stock-entry',
              ) && (
                <AddIcon
                  data-testid="increaseQuantityButton"
                  onClick={() => {
                    dispatch(
                      updateItemValue({
                        _cursor,
                        id,
                        type,
                        value: Number(quantity) + 1,
                      }),
                    )
                  }}
                  style={{
                    color: selectedKitchen?.userPermissions?.includes(
                      'update-stock-entry',
                    )
                      ? theme.palette.secondary[100].toHexString()
                      : theme.palette.secondary[50].toHexString(),
                    cursor: 'pointer',
                    height: 35,
                    width: 35,
                  }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: theme.spacing(1),
            }}
          >
            <Typography
              variant="body2"
              style={{
                color: isRecipe
                  ? theme.palette.success[100].toHexString()
                  : theme.palette.primary[60].toHexString(),
                fontSize: 14,
                fontWeight: 400,
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
                whiteSpace: 'nowrap',
              }}
            >
              {supplier}
            </Typography>

            <div className="flex items-center">
              {selectedKitchen?.userPermissions?.includes('update-stock') &&
                !(unitType === UnitType.Custom && isRecipe) && (
                  <Icon
                    onClick={() => {
                      setShowModal(true)
                    }}
                    iconName="swapHoriz"
                    style={{
                      color: theme.palette.secondary[100].toHexString(),
                      cursor: 'pointer',
                      paddingLeft: 4,
                    }}
                  />
                )}

              <Typography
                variant="subtitle1"
                style={{
                  color:
                    price === 'No Price'
                      ? theme.palette.error[100].toHexString()
                      : theme.palette.primary[60].toHexString(),
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {price === 'No Price' ? price : `${Number(quantity)}${unit} =`}
              </Typography>

              {price !== 'No Price' && (
                <Typography
                  variant="subtitle1"
                  style={{
                    color: theme.palette.primary[80].toHexString(),
                    fontSize: 14,
                    fontWeight: 500,
                    marginLeft: 2,
                  }}
                >
                  {`£${
                    data.total && quantity ? formatCurrency(data.total) : 0
                  }`}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewModal
        title="Change Unit"
        handleCancel={() => {
          setShowModal(false)
        }}
        show={showModal}
      >
        {showModal && data && (
          <SelectUnit
            stockItem={data}
            isRecipe={isRecipe}
            handleUpdateUnit={(unit: Unit, stockItem: StockTakeEntry) => {
              if (stockItem && stockItem.ingredient) {
                const {
                  conversionUnit,
                  conversionUnitType,
                  conversionUnitValue,
                } = stockItem.ingredient

                dispatch(
                  updateItemUnit({
                    _cursor,
                    conversionUnit: conversionUnit ?? undefined,
                    conversionUnitType: conversionUnitType ?? undefined,
                    conversionUnitValue: conversionUnitValue ?? undefined,
                    id,
                    type,
                    unit,
                  }),
                )
              } else {
                dispatch(
                  updateItemUnit({
                    _cursor,
                    id,
                    type,
                    unit,
                  }),
                )
              }

              setShowModal(false)
            }}
          />
        )}
      </NewModal>
    </>
  )
}
