import { useQuery } from '@apollo/client'
import { Button, Typography } from '@getjelly/jelly-ui'
import { Modal } from '@mui/material'
import { IconSelector } from '@tabler/icons-react'
import { isEmpty } from 'ramda'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  Query,
  QuerySuppliersArgs,
  SortOrder,
  ProductWhereInput,
  Supplier,
  Status,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { NewTextField, SearchIcon, Loader } from 'components'
import { Layout } from 'components/newUi'
import { useDebounce } from 'hooks'
import { routes } from 'routes/Paths'
import { selectSupplier, setSupplier } from 'store/supplier'

import { getSuppliersQuery } from './graphql'
import { Row } from './Row'

type Props = {
  onChange?: (supplier: Supplier) => void
  title: string
}

export function SupplierSelect({ onChange, title }: Props) {
  const dispatch = useDispatch()
  const { selectedKitchen } = useKitchen()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const navigate = useNavigate()
  const defaultLabel = 'All Suppliers'

  const selectedSupplier = useSelector(selectSupplier())

  const { data, previousData, loading } = useQuery<
    {
      suppliers: Query['suppliers']
      all: Query['suppliers']
    },
    QuerySuppliersArgs & {
      productWhere: ProductWhereInput
      kitchenId: number
    }
  >(getSuppliersQuery, {
    fetchPolicy: 'no-cache',
    skip: !selectedKitchen || !open,
    variables: {
      fuzzySearch: debouncedSearch,
      kitchenId: selectedKitchen?.id || 0,
      orderBy: search ? undefined : [{ name: SortOrder.Asc }],
      productWhere: {
        ingredients: {
          some: {
            kitchenId: {
              equals: selectedKitchen?.id ?? null,
            },
            status: Status.Active,
          },
        },
      },
      take: !isEmpty(debouncedSearch) ? 250 : 500,
      where: {
        AND: [
          {
            products: {
              some: {
                ingredients: {
                  some: {
                    kitchenId: { equals: selectedKitchen?.id ?? null },
                    status: Status.Active,
                  },
                },
              },
            },
          },
        ],
      },
    },
  })

  const suppliers: Supplier[] = useMemo(() => {
    const result = data || previousData

    return [
      {
        _cursor: '',
        automated: false,
        automatedCount: result?.all.filter((s) => s.automated).length ?? 0,
        count: result?.all.length ?? 0,
        name: 'All Suppliers',
        productCount:
          result?.all.reduce((acc, s) => acc + s.productCount, 0) ?? 0,
      } as unknown as Supplier,
      ...(result?.suppliers ?? []),
    ]
  }, [data])

  const close = () => {
    setOpen(false)
    setSearch('')
  }

  return (
    <>
      <Modal open={open} onClose={() => close()}>
        <div
          style={{
            backgroundColor: 'white',
            height: '100%',
            width: '100%',
          }}
        >
          <Layout
            title="Choose Supplier"
            subtitle={title}
            onBack={() => close()}
          >
            <div className="flex justify-between items-center bg-white cursor-pointer px-2">
              <NewTextField
                style={{ flex: 1 }}
                inputProps={{
                  'data-hj-allow': '',
                  style: { borderRadius: 25, flex: 1 },
                }}
                placeholder="Search by name"
                value={search}
                onChange={(value) => setSearch(value as unknown as string)}
                endAdornment={<SearchIcon />}
              />
            </div>
            <div
              style={{
                padding: '0 10px',
              }}
            >
              <div
                style={{
                  height: `calc(100vh - 129px)`,
                  overflow: 'scroll',
                }}
              >
                {suppliers.map((supplier, i) => (
                  <Row
                    key={i}
                    supplier={supplier}
                    selected={supplier.id === selectedSupplier?.id}
                    onClick={() => {
                      dispatch(setSupplier(supplier))
                      onChange?.(supplier)
                      close()
                    }}
                  />
                ))}

                {loading && (
                  <div className="flex justify-center py-4">
                    <Loader />
                  </div>
                )}

                {selectedKitchen?.userPermissions?.includes(
                  'create-connection-kitchen-supplier',
                ) && (
                  <div className="flex flex-col justify-center py-4 space-y-4 text-center">
                    <Typography style="subtitle1" className="text-primary-900">
                      Missing suppliers?
                    </Typography>

                    <div className="flex justify-center">
                      <Button
                        style="secondary"
                        label="Add My Suppliers"
                        onClick={() => {
                          navigate(
                            `${routes.Supplier}${routes.Select}${routes.All}`,
                          )
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Layout>
        </div>
      </Modal>

      <div
        role="button"
        tabIndex={0}
        onClick={() => !open && setOpen(true)}
        className="w-full h-12 flex justify-center items-center bg-primary-50 text-secondary-400 cursor-pointer min-w-0"
      >
        <div className="flex space-x-1 items-center min-w-0 px-2">
          <Typography
            style="button"
            className="w-full text-ellipsis whitespace-nowrap overflow-hidden"
          >
            {selectedSupplier?.name ?? defaultLabel}
          </Typography>

          <IconSelector />
        </div>
      </div>
    </>
  )
}
