import { useQuery } from '@apollo/client'

import { Section } from 'api'
import { CircularProgress } from 'components'
import { Dropdown } from 'components/newUi/Dropdown'
import { getSectionsQuery } from 'components/SelectList/graphql'
import { capitaliseEachWord } from 'utils'

interface IProps {
  state: string | undefined | null
  label?: string
  handleChange: (val: string) => void
  disabled?: boolean
  size?: string
  error?: boolean
}

export const SelectSection = ({ handleChange, state }: IProps) => {
  const { data, loading } = useQuery<{
    sections: Section[]
  }>(getSectionsQuery)

  if (loading) return <CircularProgress />

  const rows = data?.sections.map((unit) => ({
    label: capitaliseEachWord(unit.name),
    value: unit.name,
  }))

  return (
    <Dropdown
      label="Category"
      defaultOption={
        state ? { label: capitaliseEachWord(state), value: state } : undefined
      }
      style={{ paddingBottom: 10 }}
      options={rows}
      onChange={(val: unknown) => {
        // @ts-ignore
        handleChange({ name: val, value: val })
      }}
    ></Dropdown>
  )
}
