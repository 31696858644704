import { gql } from '@apollo/client'

export const signUpMutation = gql`
  mutation SignupV2(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $countryCode: String!
    $joinCode: String!
    $inviteCode: String
  ) {
    signupV2(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      countryCode: $countryCode
      joinCode: $joinCode
      inviteCode: $inviteCode
    ) {
      expiresAt
    }
  }
`
