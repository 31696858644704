import { Typography } from '@getjelly/jelly-ui'
import { IconLoader2 } from '@tabler/icons-react'
import { Dispatch, SetStateAction } from 'react'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import { DishRow } from './DishRow'

import { Dish } from '../../../../api'

type Props = {
  search: string
  loading: boolean
  dishes: Dish[]
  selectedDish: Dish | null
  setSelectedDish: Dispatch<SetStateAction<Dish | null>>
}

export function DishList({
  dishes,
  loading,
  selectedDish,
  setSelectedDish,
  search,
}: Props) {
  const cache = new CellMeasurerCache({
    defaultHeight: 100,
    fixedWidth: true,
  })

  if (!search) {
    return (
      <div className="text-center py-8 px-8 space-y-2">
        <Typography style="h6">Start your search</Typography>

        <Typography style="caption" className="text-primary-600">
          Search for a menu item in your cookbook to link to this POS item.
        </Typography>
      </div>
    )
  }

  if (dishes.length <= 0 && loading) {
    return (
      <div className="flex justify-center py-12 px-8 space-y-2">
        <IconLoader2 size={32} className="text-primary-900 animate-spin" />
      </div>
    )
  }

  if (dishes.length <= 0) {
    return (
      <div className="text-center py-8 px-8 space-y-2">
        <Typography style="h6">No dishes found</Typography>

        <Typography style="caption" className="text-primary-600">
          We could not find any dishes for that search.
        </Typography>
      </div>
    )
  }

  return (
    <div className="h-60">
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              width={width}
              height={height}
              rowHeight={(params) => cache.rowHeight(params)}
              deferredMeasurementCache={cache}
              rowCount={dishes.length}
              rowRenderer={({ index, key, style, parent }) => {
                const dish = dishes[index]

                return (
                  <CellMeasurer
                    key={key}
                    cache={cache}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                  >
                    {({ measure }) => (
                      <div style={style} className="border-b border-gray-200">
                        <DishRow
                          key={dish.id}
                          dish={dish}
                          active={selectedDish?.id === dish.id}
                          onClick={() => {
                            setSelectedDish(dish)
                            measure()
                          }}
                        />
                      </div>
                    )}
                  </CellMeasurer>
                )
              }}
              overscanRowCount={3}
            />
          )
        }}
      </AutoSizer>
    </div>
  )
}
