import { useQuery } from '@apollo/client'

import { DishType } from 'api'
import { CircularProgress } from 'components'
import { Dropdown } from 'components/newUi/Dropdown'
import { getDishTypesQuery } from 'components/SelectList/graphql'
import { capitaliseEachWord } from 'utils'
interface IProps {
  state: string
  handleChange: (args: { name: string; value: string }) => void
  disabled?: boolean
  includePortion: boolean
  includeCustom: boolean
  error?: boolean
  formHelperText: string | undefined
}

export const SelectDishType = ({ state, handleChange }: IProps) => {
  const { data, loading } = useQuery<{
    dishTypes: DishType[]
  }>(getDishTypesQuery)

  if (loading) return <CircularProgress />

  const records = data?.dishTypes?.map((dishType) => ({
    label: capitaliseEachWord(dishType.name),
    value: dishType.name,
  }))

  return (
    <Dropdown
      label="Type"
      defaultOption={
        state ? { label: capitaliseEachWord(state), value: state } : undefined
      }
      style={{ paddingBottom: 10, width: 'calc(100% - 4px)' }}
      options={records}
      onChange={(val: unknown) => {
        // @ts-ignore
        handleChange({ name: val, value: val })
      }}
    />
  )
}
