import {
  observable,
  action,
  computed,
  makeObservable,
  set,
  get,
  ObservableMap,
  toJS,
} from 'mobx'

import { Recipe, Ingredient, RecipeToRecipe, RecipeToIngredient } from 'api'

export class FormState {
  constructor() {
    makeObservable(this, {
      addIngredient: action,
      addItem: action,
      addRecipe: action,
      ingredients: observable,
      init: action,
      inited: observable,
      isDirty: observable,
      items: computed,
      productList: computed,
      recipes: observable,
      removeIngredient: action,
      removeItem: action,
      update: action,
      updateIngredient: action,
    })

    this.ingredients = new ObservableMap<number, RecipeToIngredient>()
    this.recipes = new ObservableMap<number, RecipeToRecipe>()
    this.inited = false
    this.isDirty = false
  }

  // updateIngredient = (ingredient: Ingredient) => {
  //   set(this.ingredients, ingredient.id, ingredient)
  // }

  // removeIngredient = (ingredient: Ingredient) => {
  //   remove(this.ingredients, ingredient.id)
  // }

  get items(): (Recipe | Ingredient)[] {
    const list: (Recipe | Ingredient)[] = []

    Array.from(this.ingredients.values()).forEach((v) => {
      list.push(toJS(v).ingredient)
    })

    Array.from(this.recipes.values()).forEach((v) => {
      list.push(toJS(v).childRecipe)
    })

    return list
  }

  get productList(): {
    count: number
    name: string
    data: Recipe[] | Ingredient[]
  }[] {
    const list: {
      count: number
      name: string
      data: Recipe[] | Ingredient[]
    }[] = [
      {
        count: this.ingredients.size,
        data: Array.from(this.ingredients.values()).map((v) => v.ingredient),
        name: 'Products',
      },
    ]

    list.push({
      count: this.recipes.size,
      data: Array.from(this.recipes.values()).map((v) => v.childRecipe),
      name: 'Recipes',
    })

    return list
  }

  recipes: ObservableMap<number, RecipeToRecipe>
  ingredients: ObservableMap<number, RecipeToIngredient>

  inited: boolean
  isDirty: boolean

  lastUpdated: Partial<RecipeToRecipe> | Partial<RecipeToIngredient> | undefined

  addItem = (item: Recipe | Ingredient) => {
    if (item.__typename === 'Recipe') {
      return this.addRecipe(item as Recipe)
    } else {
      return this.addIngredient(item as Ingredient)
    }
  }

  addIngredient = (ingredient: Ingredient) => {
    const temp: Partial<RecipeToIngredient> = {
      __typename: 'RecipeToIngredient',
      amount: undefined,
      createdAt: Date.now(),
      ingredient: ingredient,
      unit: ingredient.product.unit,
      unitCost: ingredient.unitPrice,
      unitId: ingredient.product.unit.id,
      updatedAt: Date.now(),
    }

    this.isDirty = true
    this.lastUpdated = temp

    set(this.ingredients, ingredient.id, temp)

    return
  }

  addRecipe = (recipe: Recipe) => {
    this.isDirty = true

    const temp: Partial<RecipeToRecipe> = {
      __typename: 'RecipeToRecipe',
      amount: undefined,
      childRecipe: recipe,
      createdAt: Date.now(),
      unit: recipe.unit,
      unitCost: recipe.unitCost ?? 0,
      unitId: recipe.unit?.id,
      updatedAt: Date.now(),
    }

    this.lastUpdated = temp

    set(this.recipes, recipe.id, temp)

    return
  }

  removeIngredient = (item: RecipeToIngredient | RecipeToRecipe) => {
    this.isDirty = true
    if (item.__typename === 'RecipeToIngredient') {
      this.ingredients.delete(item.ingredient.id)
    } else if (item.__typename === 'RecipeToRecipe') {
      this.recipes.delete(item.childRecipe.id)
    }
  }

  updateIngredient = (
    item: RecipeToIngredient | RecipeToRecipe | Ingredient,
  ) => {
    if (item.__typename === 'RecipeToRecipe') {
      set(this.recipes, item.childRecipe.id, item)
    } else if (item.__typename === 'RecipeToIngredient') {
      set(this.ingredients, item.ingredient.id, item)
    } else if (item.__typename === 'Ingredient') {
      const temp = get(this.ingredients, item.id)

      temp!.ingredient = item
    }
    this.isDirty = true

    return
  }

  init = (
    recipes: RecipeToRecipe[],
    ingredients: RecipeToIngredient[],
    reset = false,
  ) => {
    if (!reset && this.inited) return

    if (reset) {
      this.ingredients.clear()
      this.recipes.clear()
    }

    ingredients.forEach((map) => {
      set(this.ingredients, map.ingredient.id, map)
    })

    recipes.forEach((map) => {
      set(this.recipes, map.childRecipe.id, map)
    })

    this.inited = true
    this.isDirty = false
  }

  removeItem = (item: Recipe | Ingredient) => {
    if (item.__typename === 'Recipe') {
      this.recipes.delete(item.id)
    } else {
      this.ingredients.delete(item.id)
    }
  }

  update = (recipes: RecipeToRecipe[], ingredients: RecipeToIngredient[]) => {
    this.isDirty = true
    ingredients.forEach((map) => {
      set(this.ingredients, map.ingredient.id, map)
    })

    recipes.forEach((map) => {
      set(this.recipes, map.childRecipe.id, map)
    })
  }
}
