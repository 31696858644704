import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store'

export type ReduxInvite = {
  joinCode: string
  inviteCode?: string
}

type State = {
  firstName?: string
  lastName?: string
  email?: string
  countryCode?: string
  phoneNumber?: string
  resetPin?: string
  invite?: ReduxInvite
}

const initialState: State = {}

const userSlice = createSlice({
  initialState,
  name: 'authFlow',
  reducers: {
    clearAuthFlow(state) {
      state.firstName = undefined
      state.lastName = undefined
      state.email = undefined
      state.countryCode = undefined
      state.phoneNumber = undefined
      state.resetPin = undefined
      state.invite = undefined
    },

    setCountryCode(state, action: PayloadAction<string | undefined>) {
      state.countryCode = action.payload
    },

    setEmail(state, action: PayloadAction<string | undefined>) {
      state.email = action.payload
    },

    setFirstName(state, action: PayloadAction<string | undefined>) {
      state.firstName = action.payload
    },

    setInvite(state, action: PayloadAction<ReduxInvite | undefined>) {
      state.invite = action.payload
    },

    setLastName(state, action: PayloadAction<string | undefined>) {
      state.lastName = action.payload
    },

    setPhoneNumber(state, action: PayloadAction<string | undefined>) {
      state.phoneNumber = action.payload
    },

    setResetPin(state, action: PayloadAction<string | undefined>) {
      state.resetPin = action.payload
    },
  },
})

export const {
  clearAuthFlow,
  setFirstName,
  setLastName,
  setEmail,
  setCountryCode,
  setPhoneNumber,
  setResetPin,
  setInvite,
} = userSlice.actions
export const reducer = userSlice.reducer

const selectState = (state: RootState) => state.authFlow

export const selectAuthFlow = () =>
  createSelector(selectState, (state) => state)
