import { gql } from '@apollo/client'

export const signInMutation = gql`
  mutation SignIn(
    $phoneNumber: String!
    $password: String!
    $countryCode: String
    $appVersion: String
    $joinCode: String
    $inviteCode: String
  ) {
    signin(
      phoneNumber: $phoneNumber
      password: $password
      countryCode: $countryCode
      appVersion: $appVersion
      joinCode: $joinCode
      inviteCode: $inviteCode
    ) {
      token
      joinError
    }
  }
`
