import { useMutation } from '@apollo/client'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRoles } from 'store/auth'

import { BlockSelect } from './BlockSelect'

import {
  KitchenToUser,
  Mutation,
  MutationUpdateKitchenToUserArgs,
  Role,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { updateKitchenToUser } from '../graphql'

export function BlockSelectUpdateRole({
  role,
  kitchenToUser,
  updatingKTU,
  setUpdatingKTU,
  setError,
  setSuccess,
}: {
  role: Role
  kitchenToUser: KitchenToUser | null | undefined
  updatingKTU?: number
  setUpdatingKTU: (n?: number) => void
  setError: (s: string) => void
  setSuccess: (s: string) => void
}) {
  const { selectedKitchen } = useKitchen()
  const roles = useSelector(selectRoles())

  const [updateKTU] = useMutation<
    { updateKitchenToUser: Mutation['updateKitchenToUser'] },
    MutationUpdateKitchenToUserArgs
  >(updateKitchenToUser, {
    awaitRefetchQueries: true,
    refetchQueries: ['kitchenToUser'],
  })

  function validateAction(kitchenToUser: KitchenToUser) {
    if (Array.isArray(roles) && roles.includes('admin')) {
      return
    }

    if (
      !selectedKitchen?.userPermissions?.includes(
        'update-connection-kitchen-user',
      )
    ) {
      throw new Error('You do not have permission to do this.')
    }

    if (kitchenToUser.role.id < selectedKitchen?.kitchenToCurrentUser?.roleId) {
      throw new Error("You can't change this users access level.")
    }

    if (role.id < selectedKitchen?.kitchenToCurrentUser?.roleId) {
      throw new Error("You can't grant access greater than your own.")
    }

    if (role.id === kitchenToUser.role.id) {
      throw new Error('')
    }
  }

  async function handleClick() {
    if (!kitchenToUser || updatingKTU) {
      return
    }

    setError('')
    setSuccess('')

    try {
      validateAction(kitchenToUser)
    } catch (e) {
      setError((e as Error).message)
      return
    }

    setUpdatingKTU(role.id)

    try {
      await updateKTU({
        variables: {
          data: {
            id: kitchenToUser.id,
            roleId: role.id,
          },
        },
      })

      setSuccess('Profile has been updated.')
    } catch (e) {
      setError('An error occurred while changing the users role.')
    } finally {
      setUpdatingKTU(undefined)
    }
  }

  const iconName = useMemo(() => {
    if (updatingKTU && updatingKTU === role.id) {
      return 'loader'
    }

    if (!updatingKTU && kitchenToUser?.role.id === role.id) {
      return 'tick'
    }

    return undefined
  }, [updatingKTU, kitchenToUser])

  return (
    <BlockSelect
      onClick={handleClick}
      title={role.name}
      subtitle={role.description}
      iconName={iconName}
    />
  )
}
