import { WelcomePanel } from '@getjelly/jelly-ui/dist'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { VITE_REACT_APP_DEMO_JOIN_CODE } from '../../app'
import { routes } from '../../routes/Paths'
import { selectAuthFlow } from '../../store/authFlow'

export function Welcome() {
  const navigate = useNavigate()

  const authFlow = useSelector(selectAuthFlow())

  useEffect(() => {
    if (!authFlow.invite) {
      navigate(`${routes.Join}/${VITE_REACT_APP_DEMO_JOIN_CODE}`)
    }
  }, [])

  return (
    <WelcomePanel
      next={() => navigate(routes.SignUp)}
      loginLinkClicked={() => navigate(routes.SignIn)}
    />
  )
}
