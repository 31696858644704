import { use100vh } from 'react-div-100vh'

import { makeStyles } from 'styles/newUi'
import { shouldFixIOS15 } from 'utils'

export const useStyles = () => {
  const v100 = use100vh()
  const height = shouldFixIOS15()
    ? 'calc(100vh - env(safe-area-inset-bottom))'
    : `${v100}px` ?? '100vh'
  return makeStyles((theme, isDesktop) => ({
    _root: {
      display: !isDesktop ? 'grid' : undefined,
      gap: '0px 0px',
      gridTemplateAreas: '"." "."',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '96px 1fr',
      height: '100%',
    },
    actionBar: {
      display: isDesktop ? 'flex' : undefined,
      justifyContent: isDesktop ? 'space-between' : undefined,
      marginTop: isDesktop ? theme.spacing(4) : undefined,
      width: isDesktop ? '100%' : undefined,
    },
    arrow: {
      display: 'flex',
    },
    divider: {
      backgroundColor: theme.palette.primary[20].toHexString(),
      display: isDesktop ? 'none' : undefined,
      height: 1,
      marginTop: theme.spacing(1),
      width: '100%',
    },
    info: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[100].toHexString()}`,
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
      width: '100%',
    },
    itemRoot: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[50].toHexString()}`,
      display: 'flex',
      height: 80,
      justifyContent: 'space-between',
      padding: theme.spacing(2),
    },
    itemRootDesktop: {
      backgroundColor: theme.palette.grey[50].toHexString(),
      border: `1px solid ${theme.palette.primary[20].toHexString()}`,
      borderRadius: 10,
      height: 126,
      margin: theme.spacing(1),
      padding: theme.spacing(3),
      width: '100%',
    },
    link: {
      alignItems: 'center',
      color: 'unset',
      display: 'flex',
      justifyContent: 'space-between',
      textDecoration: 'none',
      width: '100%',
    },
    list: {
      height: isDesktop ? `calc(${height} - 130px)` : `calc(${height} - 96px)`,
      overflow: 'hidden',
    },
    listContainer: {},
    listItem: {},
    listItemDesktop: {
      width: '25% !important',
    },
    sectionClass: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    supplierName: {
      paddingBottom: 10,
      textTransform: 'capitalize',
    },
  }))
}
