import { PeriodSelector } from '@getjelly/jelly-ui'
import { IconCalendarMonth } from '@tabler/icons-react'
import { ComponentType } from 'react'

type Props = {
  value: DashboardGroup
  onChange: (period: DashboardGroup) => void
  className?: string
}

export type DashboardGroup = {
  id: string
  label: string
  title: string
  subtitle: string
  icon: ComponentType<{ className?: string }>
}

export const dashboardGroups: DashboardGroup[] = [
  {
    icon: IconCalendarMonth,
    id: 'day',
    label: 'By Calendar Day',
    subtitle: 'Daily price change.',
    title: 'Calendar Day',
  },
  {
    icon: IconCalendarMonth,
    id: 'week',
    label: 'By Calendar Week',
    subtitle: 'Weekly price change.',
    title: 'Calendar Week',
  },
  {
    icon: IconCalendarMonth,
    id: 'month',
    label: 'By Calendar Month',
    subtitle: 'Monthly price change.',
    title: 'Calendar Month',
  },
]

export function CalendarGroupSelector({
  value,
  onChange,
  className = '',
}: Props) {
  return (
    <div
      className={`h-12 w-full flex items-center justify-center bg-primary-50 ${className}`}
    >
      <PeriodSelector
        periods={dashboardGroups}
        value={value}
        onChange={onChange}
        labelExtractor={(p) => p.label}
        titleExtractor={(p) => p.title}
        subtitleExtractor={(p) => p.subtitle}
        iconExtractor={(p) => p.icon}
      />
    </div>
  )
}
