import { useMutation } from '@apollo/client'
import {
  AccurateStockTakeEmptyState,
  AccurateStockTakeNotPaidState,
} from '@getjelly/jelly-ui'
import dayjs from 'dayjs'
import { isEmpty, uniqBy } from 'ramda'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { userTrackingMutation } from 'api/graphql'
import { useStockListQuery, StockFragmentFragment } from 'api/types'
import { DATE_FORMATS } from 'app'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import { Icon, NewButton, VirtualizedSectionList } from 'components/newUi'
import { routes } from 'routes/Paths'
import { NewModal } from 'screens/Create/NewModal'
import {
  selectAllStocktakes,
  StockTakeEntry,
  StockTakeStoreStockTake,
} from 'store/stocktake'

import { Item, StockTakeItemStockList } from './components/StockTakeItem'
import { TemplateSelection } from './components/TemplateSelection'

import { useIsPaidKitchen } from '../../../hooks'
import { selectCounts } from '../../../store/kitchen'

function isStockTakeStoreStockTake(
  entry: StockTakeStoreStockTake | StockFragmentFragment,
): entry is StockTakeStoreStockTake {
  return (entry as StockTakeStoreStockTake).entries !== undefined
}

export const Stocktake = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const { selectedKitchen } = useKitchen(true)
  const isFeatureAvailable = useIsPaidKitchen()

  const { data, loading, fetchMore } = useStockListQuery({
    skip: !selectedKitchen?.id,
    variables: {
      kitchenId: selectedKitchen?.id as number,
      take: 100,
      where: {},
    },
  })

  const [userTracking] = useMutation(userTrackingMutation, {
    variables: {
      data: {
        feature: 'UserFeatureStock',
        kitchenId: selectedKitchen?.id,
      },
    },
  })

  useEffect(() => {
    if (!selectedKitchen) return
    userTracking()
  }, [selectedKitchen, userTracking])

  const storeStockTakes = useSelector(
    selectAllStocktakes(selectedKitchen?.id ?? -1),
  )

  const drafts = storeStockTakes.filter((st) => st.isDraft || st.unsavedChanges)

  const apiStockList = (data?.stockList?.nodes || []) as StockFragmentFragment[]

  const allStockTakes: (StockFragmentFragment | StockTakeStoreStockTake)[] =
    uniqBy(
      (stocktake) => stocktake._cursor,
      [...drafts, ...apiStockList],
    )?.sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1))

  const stocktakes =
    allStockTakes?.reduce((acc: StockTakeItemStockList[], cur) => {
      const month = dayjs(cur.date).format(DATE_FORMATS.DAY_OF_THE_MONTH)

      const currMonthIndex = acc.findIndex(({ label }) => label === month)

      // data coming from the API don't have some props required by type
      const parsedCurrent = isStockTakeStoreStockTake(cur)
        ? cur
        : {
            ...cur,
            entries: [] as StockTakeEntry[],
            kitchenId: selectedKitchen?.id as number,
          }

      if (currMonthIndex !== -1) {
        return acc.map((stockTakeMonth, index) => {
          if (index !== currMonthIndex) return stockTakeMonth

          return {
            ...stockTakeMonth,
            items: [...stockTakeMonth.items, parsedCurrent],
          }
        })
      }
      return [...acc, { items: [parsedCurrent], label: month }]
    }, []) ?? []

  const counts = useSelector(selectCounts())

  if (loading || isFeatureAvailable === null) return <Loader />

  if (!isFeatureAvailable) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <AccurateStockTakeNotPaidState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  if (
    (data?.stockList?.nodes?.length ?? 0) === 0 &&
    counts.stocktakeCount === 0 &&
    isEmpty(drafts)
  ) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <AccurateStockTakeEmptyState
            ctaClicked={() =>
              navigate(routes.Stock + routes.Take + routes.Create)
            }
          />
        </div>
      </div>
    )
  }
  const handleCancel = () => setShow(false)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ flex: 1 }}>
        <VirtualizedSectionList
          ROW_HEIGHT={60}
          loading={loading}
          Item={(props: any) => {
            return <Item {...props} />
          }}
          pageInfo={data?.stockList?.pageInfo}
          data={stocktakes}
          fetchMore={() => {
            if (!loading) {
              fetchMore({
                variables: {
                  skip: data?.stockList?.nodes?.length,
                  take: 20,
                },
              })
            }
          }}
        />
      </div>

      {selectedKitchen?.userPermissions?.includes('create-stock') && (
        <div className="flex justify-center items-center py-4 bg-white">
          <NewButton
            text="Create New"
            onClick={() => setShow(true)}
            rightAdornment={<Icon iconName="add" />}
          />
        </div>
      )}

      <NewModal title="Select Template" handleCancel={handleCancel} show={show}>
        <TemplateSelection
          stockTakeNodes={allStockTakes}
          handleCancel={handleCancel}
        />
      </NewModal>
    </div>
  )
}
