import {
  addMonths,
  addQuarters,
  addWeeks,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  subMonths,
  subQuarters,
  subWeeks,
} from 'date-fns'
import { useMemo } from 'react'

import { useQueryParamsState } from './useQueryParamsState'

import { PeriodEnum } from '../api'

export function useSyncedDatePeriod() {
  const [startDateParam, setStartDateParam] = useQueryParamsState(
    'startDate',
    startOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
  )

  const [periodParam, setPeriodParam] = useQueryParamsState(
    'period',
    PeriodEnum.Week,
  )

  return {
    setPeriodParam,
    setStartDateParam,
    state: useMemo(() => {
      const initialDate = parseISO(startDateParam)

      if (periodParam === PeriodEnum.Month) {
        const startDate = startOfMonth(initialDate)
        return {
          endDate: endOfMonth(initialDate),
          nextStart: addMonths(startDate, 1),
          periodEnum: PeriodEnum.Month,
          previousStart: subMonths(startDate, 1),
          startDate,
        }
      }

      if (periodParam === PeriodEnum.Quarter) {
        const startDate = startOfQuarter(initialDate)
        return {
          endDate: endOfQuarter(initialDate),
          nextStart: addQuarters(startDate, 1),
          periodEnum: PeriodEnum.Quarter,
          previousStart: subQuarters(startDate, 1),
          startDate,
        }
      }

      // If it's not month or quarter, has to be week.
      const startDate = startOfWeek(initialDate, { weekStartsOn: 1 })
      return {
        endDate: endOfWeek(initialDate, { weekStartsOn: 1 }),
        nextStart: addWeeks(startDate, 1),
        periodEnum: PeriodEnum.Week,
        previousStart: subWeeks(startDate, 1),
        startDate,
      }
    }, [startDateParam, periodParam]),
  }
}
