import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { PriceChangeBaseFragment, useGetPriceChangesQuery } from 'api'
import { Loader } from 'components'
import { Typography } from 'components/newUi'
import { PriceHistoryChart } from 'screens/Products/Chart'
import { theme, useTheme } from 'styles/newUi'

type PriceChangeData = { date: string; change: PriceChangeBaseFragment }

export const PriceHistory = () => {
  const { id } = useParams()

  const { data, loading } = useGetPriceChangesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      cursor: id,
    },
  })

  // @ts-ignore it works but I can't figure out how to make typescript happy
  const priceChanges: PriceChangeData[] = useMemo(() => {
    if (!data?.ingredientNode?.priceChangesList.priceChanges) return []

    return data.ingredientNode.priceChangesList.priceChanges.reduce(
      // @ts-ignore
      (acc, pc) => [
        ...acc,
        ...(pc?.changes.map((change) => ({ change, date: pc.date })) ?? []),
      ],
      [] as PriceChangeData[],
    )
  }, [data])

  if (loading) return <Loader />
  return (
    <>
      {priceChanges.length > 1 && (
        <PriceHistoryChart
          data={[...priceChanges]
            .reverse()
            .map((pc) => ({ price: pc.change.price }))}
        />
      )}
      {[...priceChanges].map((pc: PriceChangeData) => (
        <Item
          key={pc.date}
          date={pc?.date}
          change={pc.change.percentageChange ?? 0}
          price={pc.change.price ?? 0}
        />
      ))}
    </>
  )
}

const ROW_HEIGHT = 49

export const changeColor = (value: number) =>
  value > 0
    ? theme.palette.error[100].toHexString()
    : value === 0
    ? theme.palette.primary[60].toHexString()
    : theme.palette.success[100].toHexString()

export const Item = ({
  date,
  change,
  price,
}: {
  date: string
  change: number
  price: number
}) => {
  const { theme } = useTheme()

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        style={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
          display: 'grid',
          gridTemplateColumns: '1.25fr 1fr 1fr',
          height: ROW_HEIGHT,
          paddingLeft: theme.spacing(2),
          paddingRight: 20,
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        <div style={{ overflow: 'hidden' }}>
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.primary[60].toHexString(),
              marginBottom: 4,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
            }}
          >
            {dayjs(date).format('DD MMM YYYY')}
          </Typography>
        </div>

        <Typography
          variant="subtitle1"
          style={{
            alignItems: 'flex-end',
            color: changeColor(change),
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          {change > 0 ? '+' : ''}
          {change ? `${change}%` : '-'}
        </Typography>
        <div
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.secondary[100].toHexString(),
              fontWeight: 500,
            }}
          >
            £{price.toFixed(2)}
          </Typography>
        </div>
      </div>
    </>
  )
}
