import { useMutation, useQuery } from '@apollo/client'
import { DigitalOrderingEmptyState } from '@getjelly/jelly-ui'
import dayjs from 'dayjs'
import { groupBy, isEmpty, isNil } from 'ramda'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  PageInfo,
  PurchaseOrder,
  Query,
  QueryMode,
  QueryPurchaseOrderListArgs,
  SortOrder,
} from 'api'
import { userTrackingMutation } from 'api/graphql'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { CloseIcon, NewTextField, SearchIcon, SupplierSelect } from 'components'
import { Icon, Typography, VirtualizedSectionList } from 'components/newUi'
import { useDebounce } from 'hooks'
import { routes } from 'routes/Paths'
import { getPurchaseOrdersQuery } from 'screens/OrderHistory/graphql'
import { selectSupplier } from 'store/supplier'
import { useTheme } from 'styles/newUi'

const ROW_HEIGHT = 76

export const OrderHistory = () => {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const [search, setSearch] = useState('')
  const [data, setData] = useState<{ label: string; items: PurchaseOrder[] }[]>(
    [],
  )
  const debouncedSearch = useDebounce(search, 500)
  const [pageInfo, setPageInfo] = useState<PageInfo>()

  const selectedSupplier = useSelector(selectSupplier())

  const {
    data: orderQuery,
    loading,
    fetchMore,
  } = useQuery<
    { purchaseOrderList: Query['purchaseOrderList'] },
    QueryPurchaseOrderListArgs
  >(getPurchaseOrdersQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !selectedKitchen?.id,
    variables: {
      orderBy: [{ createdAt: SortOrder.Desc }],
      skip: 0,
      take: 48,
      where: {
        OR: debouncedSearch
          ? [
              {
                supplier: {
                  name: {
                    contains: debouncedSearch,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
              {
                createdBy: {
                  OR: [
                    {
                      firstName: {
                        contains: debouncedSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      lastName: {
                        contains: debouncedSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
              {
                number:
                  debouncedSearch && !isNaN(Number(debouncedSearch))
                    ? {
                        equals: Number(debouncedSearch),
                      }
                    : undefined,
              },
              {
                id:
                  debouncedSearch && !isNaN(Number(debouncedSearch))
                    ? {
                        equals: Number(debouncedSearch),
                      }
                    : undefined,
              },
            ]
          : undefined,
        kitchenId: {
          equals: selectedKitchen?.id ?? -1,
        },
        supplierId: selectedSupplier
          ? { equals: selectedSupplier.id }
          : undefined,
      },
    },
  })

  const [userTracking] = useMutation(userTrackingMutation, {
    variables: {
      data: {
        feature: 'UserFeatureOrder',
        kitchenId: selectedKitchen?.id,
      },
    },
  })

  useEffect(() => {
    if (!selectedKitchen) return
    userTracking()
  }, [selectedKitchen])

  useEffect(() => {
    if (isNil(orderQuery)) return

    const currentYear = dayjs().year()

    const indexedData = groupBy(
      (node) => dayjs(node.createdAt).format('YYYY/MM/DD'),
      [...orderQuery.purchaseOrderList.nodes].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
    )
    const transformedData = Object.keys(indexedData).map((key) => ({
      items: indexedData[key].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
      label: dayjs(indexedData[key][0].createdAt).format(
        dayjs(indexedData[key][0].createdAt).year() === currentYear
          ? 'D MMM'
          : 'D MMM YYYY',
      ),
    }))

    setData(transformedData)
    setPageInfo(orderQuery.purchaseOrderList.pageInfo)
  }, [orderQuery, search])

  return (
    <div className="h-full w-full">
      {!loading && isEmpty(data) && isEmpty(debouncedSearch) ? (
        <div className="w-full h-full bg-primary-200 flex justify-center">
          <div className="max-w-[32rem] p-4">
            <DigitalOrderingEmptyState
              ctaClicked={() => navigate(routes.Order + routes.Supplier)}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="h-12 flex items-center bg-primary-50">
            <SupplierSelect title="Ordering" />
          </div>

          <div className="p-4 bg-white !space-y-3">
            <NewTextField
              className="!m-0 !p-0"
              style={{
                backgroundColor: 'white',
              }}
              inputProps={{
                'data-hj-allow': '',
                style: { borderRadius: 25, flex: 1 },
              }}
              placeholder="Search orders"
              value={search}
              onChange={(value) => setSearch(value as unknown as string)}
              endAdornment={
                isEmpty(search) ? (
                  <SearchIcon />
                ) : (
                  <CloseIcon onClick={() => setSearch('')} />
                )
              }
            />
          </div>

          <VirtualizedSectionList
            heightModification={-113}
            ROW_HEIGHT={ROW_HEIGHT}
            Item={(props: any) => <Item {...props} />}
            data={data}
            loading={loading}
            pageInfo={pageInfo}
            fetchMore={() => {
              if (!loading) {
                fetchMore({
                  variables: {
                    cursor: pageInfo?.endCursor,
                  },
                })
              }
            }}
          />
        </>
      )}
    </div>
  )
}

const Item = (props: any) => {
  const { index, style, data } = props
  const rowData: PurchaseOrder = data.items[index]
  const { theme } = useTheme()
  const navigate = useNavigate()

  return (
    // eslint-disable-next-line
    <div
      data-testid={props.data.label}
      key={rowData._cursor}
      onClick={() =>
        navigate(`${routes.Order}${routes.History}/${rowData._cursor}`)
      }
      style={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
        cursor: 'pointer',
        display: 'grid',
        gap: '0 5px',
        gridTemplateColumns: '1fr 0fr 40px',
        height: ROW_HEIGHT,
        paddingLeft: theme.spacing(2),
        whiteSpace: 'nowrap',
        width: '100%',
        ...style,
      }}
    >
      <div style={{ overflow: 'hidden' }}>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 500,
            marginBottom: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          {rowData.supplier.name.toLowerCase()}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: theme.palette.primary[60].toHexString() }}
        >
          {`#${rowData.number}`}
        </Typography>
      </div>
      <div
        style={{
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 500, marginBottom: 4 }}
        >
          {dayjs(rowData.createdAt).format('HH:mm')}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: theme.palette.primary[60].toHexString() }}
        >
          {`${rowData.createdBy.firstName} ${rowData.createdBy.lastName}`}
        </Typography>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Icon iconName="chevronRight" />
      </div>
    </div>
  )
}
