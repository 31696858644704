import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export function ScrollMenu({ children }: Props) {
  return (
    <div className="bg-primary-50 flex overflow-x-auto w-full shrink-0">
      {children}

      <div className="border-b-[3px] border-primary-200 h-11 flex-1 flex-shrink" />
    </div>
  )
}
