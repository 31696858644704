import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme, isDesktop) => {
    const productGrid = {
      gap: '0px 0px',
      gridTemplateAreas: '". . ."',
      gridTemplateColumns: '1fr 200px auto',
      gridTemplateRows: '1fr',
    }

    return {
      _root: {
        display: !isDesktop ? 'grid' : undefined,
        gap: '0px 0px',
        gridTemplateAreas: '"." "."',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '105px 1fr',
        height: '100vh',
      },
      actionBar: {
        borderBottom: isDesktop
          ? undefined
          : `1px solid ${theme.palette.grey[100].toHexString()}`,
        display: isDesktop ? 'flex' : undefined,
        justifyContent: isDesktop ? 'space-between' : undefined,
        marginTop: isDesktop ? theme.spacing(4) : undefined,
        width: isDesktop ? '100%' : undefined,
      },
      alert: {
        marginBottom: isDesktop ? theme.spacing(2) : undefined,
        marginTop: isDesktop ? theme.spacing(2) : undefined,
      },
      divider: {
        backgroundColor: theme.palette.primary[20].toHexString(),
        display: isDesktop ? 'none' : undefined,
        height: 1,
        marginTop: theme.spacing(1),
        width: '100%',
      },
      info: {
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[100].toHexString()}`,
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
        width: '100%',
      },
      itemRoot: {
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[50].toHexString()}`,
        display: 'flex',
        height: 80,
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        width: '100%',
      },
      itemRootDesktop: {
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[100].toHexString()}`,
        display: 'grid',
        ...productGrid,
        height: 50,
        padding: theme.spacing(2),
        width: '100%',
      },
      link: {
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: `2px solid ${theme.palette.primary[10].toHexString()}`,
        color: 'unset',
        display: 'flex',
        justifyContent: 'space-between',
        textDecoration: 'none',
        width: '100%',
      },
      list: {
        // height: isDesktop ? 'calc(100vh - 230px)' : 'calc(100vh - 140px)',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
      },
      listContainer: {
        flexWrap: 'wrap',
      },
      listItem: {
        paddingLeft: isDesktop ? theme.spacing(2) : undefined,
        paddingRight: isDesktop ? theme.spacing(2) : theme.spacing(1),
      },
      prependPackSize: {
        textAlign: 'center',
      },
      prependRoot: {
        backgroundColor: theme.palette.grey[50].toHexString(),
        color: theme.palette.primary[60].toHexString(),
        display: isDesktop ? 'grid' : 'none',
        ...productGrid,
        paddingBottom: theme.spacing(1) / 2,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1) / 2,
        width: '100%',
      },
      productName: {
        maxWidth: '60vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      search: {},
      subtitle: {
        color: `${theme.palette.primary[40].toHexString()} !important`,
      },
      subtitleDesktop: {
        color: `${theme.palette.primary[40].toHexString()} !important`,
        textAlign: 'center',
      },
      titleTab: {
        backgroundColor: theme.palette.primary[10].toHexString(),
        color: theme.palette.primary[80].toHexString(),
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 600,
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        width: '100%',
      },
    }
  })
