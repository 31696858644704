import nodePackage from '../../package.json'

export const env: {
  BASE_URL: string
  MODE: string
  DEV: boolean
  PROD: boolean
  SSR: boolean
} & { [key: string]: string | undefined } = import.meta.env

export const {
  MODE = 'development',
  NODE_ENV = MODE,
  PUBLIC_URL = '',
  VITE_REACT_APP_API: API,
  VITE_REACT_APP_BETA_URL,
  VITE_REACT_APP_ONE_SIGNAL_ID: ONE_SIGNAL_ID,
  VITE_MIXPANEL_TOKEN,
  VITE_MIXPANEL_DEBUG,
  VITE_REACT_APP_HOTJAR,
  VITE_REACT_APP_ENV,
  VITE_REACT_APP_WEBSOCKETS_URL,
  VITE_REACT_APP_VERBOSE_WEBSOCKETS,
  VITE_REACT_APP_DEMO_JOIN_CODE,
  VITE_REACT_APP_DEMO_KITCHEN_ID,
  VITE_REACT_APP_EPOS_NOW_APP_ID,
} = env

export const IS_DEVELOPMENT = NODE_ENV !== 'production'

export const IS_DEBUG_MIXPANEL = VITE_MIXPANEL_DEBUG === 'true'

export const VERSION =
  localStorage.getItem('installedVersion') || nodePackage.version

export const DEFAULT_COUNTRY_CODE = '44'

export const BOOK_A_CALL_URL = import.meta.env.VITE_BOOK_A_CALL_URL

export const BASE_UNITS = {
  CENTILITRES: 'cl',
  GRAMS: 'g',
  KILOGRAMS: 'kg',
  LITRES: 'l',
  MILLILITRES: 'ml',
  OUNCES: 'oz',
  POUNDS: 'lb',
  US_CUPS: 'cup (US)',
  US_GALLONS: 'gal (US)',
  US_OUNCES: 'oz (US)',
  US_PINTS: 'pint (US)',
  US_QUARTS: 'quart (US)',
}

const PORTION_UNIT = {
  PORTIONS: 'portion',
}

const UNIT_UNIT = {
  UNITS: 'unit',
}

export const UNITS = {
  ...BASE_UNITS,
  ...PORTION_UNIT,
  ...UNIT_UNIT,
}

export const GRAMS_PER_UNIT = {
  [UNITS.GRAMS]: 1,
  [UNITS.KILOGRAMS]: 1000,
  [UNITS.MILLILITRES]: 1,
  [UNITS.CENTILITRES]: 10,
  [UNITS.LITRES]: 1000,
  [UNITS.OUNCES]: 28.3495,
  [UNITS.POUNDS]: 453.592,
  [UNITS.US_OUNCES]: 29.57,
  [UNITS.US_CUPS]: 236.59,
  [UNITS.US_PINTS]: 473.18,
  [UNITS.US_QUARTS]: 946.36,
  [UNITS.US_GALLONS]: 3785,
}

// how many of the following units to make 1 gram?
export const UNITS_PER_GRAM = {
  [UNITS.GRAMS]: 1,
  [UNITS.KILOGRAMS]: 0.001,
  [UNITS.MILLILITRES]: 1,
  [UNITS.CENTILITRES]: 0.1,
  [UNITS.LITRES]: 0.001,
  [UNITS.OUNCES]: 0.035274,
  [UNITS.POUNDS]: 0.00220462,
  [UNITS.US_OUNCES]: 0.0338180588,
  [UNITS.US_CUPS]: 0.0042267213,
  [UNITS.US_PINTS]: 0.0021133607,
  [UNITS.US_QUARTS]: 0.0010566803,
  [UNITS.US_GALLONS]: 0.0002642007926,
}

export const SORTED_BASE_UNITS = [
  BASE_UNITS.GRAMS,
  BASE_UNITS.KILOGRAMS,
  BASE_UNITS.MILLILITRES,
  BASE_UNITS.CENTILITRES,
  BASE_UNITS.LITRES,
  BASE_UNITS.OUNCES,
  BASE_UNITS.POUNDS,
  BASE_UNITS.US_CUPS,
  BASE_UNITS.US_GALLONS,
  BASE_UNITS.US_OUNCES,
  BASE_UNITS.US_PINTS,
  BASE_UNITS.US_QUARTS,
]

export const SORTED_COMPONENT_UNITS = [
  ...SORTED_BASE_UNITS,
  PORTION_UNIT.PORTIONS,
]

export const SORTED_INGREDIENT_UNITS = [...SORTED_BASE_UNITS, UNIT_UNIT.UNITS]

export const DATE_FORMATS = {
  ANOTHER_DAY_FORMAT: 'HH:mm on ddd Do MMM',
  DATE_SIMPLE: 'D MMM YYYY',
  DATE_SIMPLE_SHORT: 'D MMM',
  DATE_TIME: 'DD MMM YY HH:mm',
  DAY_FORMAT: 'ddd Do MMM',
  DAY_OF_THE_MONTH: "MMM 'YY",
  EXCEL: 'DD/MM/YYYY',
  FULL_DATE: 'dddd DD MMMM',
  I_AM_RUNNING_OUT_OF_IDEAS_FOR_NAMES_DATE_FORMAT: 'DD MMM YY',
  MACHINE: 'DD_MM_YYYY',
  ONE_MORE_DATE_FORMAT: 'D MMMM YYYY',
  TIME_ONLY: 'HH:mm',
  YET_ANOTHER_DATE_FORMAT: 'ddd D MMMM YYYY',
}
