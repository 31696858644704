import { gql } from '@apollo/client'

import { userAccountFragment } from 'api/graphql'

/* eslint-disable graphql/required-fields */
export const getMeQuery = gql`
  query userList {
    me {
      ...AccountFields
    }
  }
  ${userAccountFragment}
`
