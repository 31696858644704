import { Button, DetailsSection, Notes, Typography } from '@getjelly/jelly-ui'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'

import { InvoiceNote } from 'api'
import { api } from 'api/rest'
import { NotesModalForm } from 'screens/Spending/Invoices/SingleInvoice/components/NotesForm'

import { useKitchen } from '../../../../../app/contexts/SelectedKitchen'
import { successToast } from '../../../../../components/toasts'

type Props = {
  pendingInvoiceId: number
  invoiceId?: string
}

export function NotesContainer({ pendingInvoiceId, invoiceId }: Props) {
  const { selectedKitchen } = useKitchen()
  const [showNotesModal, setShowNotesModal] = useState(false)
  const [notes, setNotes] = useState<InvoiceNote[]>([])

  const handleAddNote = async (note: string) => {
    const { data: newNote } = await api.post<{ note: InvoiceNote }>(
      `/rest/invoices/${pendingInvoiceId}/notes`,
      {
        note,
      },
    )

    setShowNotesModal(false)
    successToast('Note added')

    if (!note) return
    setNotes([...notes, newNote.note])
  }

  useEffect(() => {
    api
      .get<InvoiceNote[]>(`/rest/invoices/${pendingInvoiceId}/notes`)
      .then((response) => {
        setNotes(response.data)
      })
  }, [pendingInvoiceId])

  return (
    <>
      <DetailsSection title={`Notes (${notes.length})`}>
        <div className="space-y-4">
          {notes.length > 0 ? (
            <Notes
              notes={notes}
              titleExtractor={(note) =>
                `${note.user.firstName} ${note.user.lastName} on ${format(
                  note.createdAt,
                  'E dd/MM/yyyy',
                )} at ${format(note.createdAt, 'hh:mm a')}`
              }
              bodyExtractor={(note) => note.content}
              typeExtractor={() => 'default'}
            />
          ) : (
            <div className="flex justify-center">
              <Typography style="subtitle2" className="text-primary-900">
                There are no notes to show.
              </Typography>
            </div>
          )}

          {selectedKitchen?.userPermissions.includes('update-invoice') && (
            <div className="flex justify-center">
              <Button
                style="secondary"
                onClick={() => setShowNotesModal(true)}
                label="Add Note"
              />
            </div>
          )}
        </div>
      </DetailsSection>

      <NotesModalForm
        id={invoiceId}
        handleAddNote={handleAddNote}
        notes={notes}
        setShowModal={setShowNotesModal}
        showModal={showNotesModal}
      />
    </>
  )
}
