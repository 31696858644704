import { useState } from 'react'

import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { ViewOnlyModal } from '../Button/ViewOnlyModal'
import { Icon } from '../Icon'

export function ViewOnlyBanner() {
  const { selectedKitchen } = useKitchen()
  const [showModal, setShowModal] = useState(false)

  if (selectedKitchen?.kitchenToCurrentUser?.roleId !== 3) {
    return <></>
  }

  return (
    <>
      <ViewOnlyModal
        handleCancel={() => setShowModal(false)}
        show={showModal}
        kitchen={selectedKitchen}
      />

      <div
        className="flex justify-center w-full bg-success-200"
        onClick={() => setShowModal(true)}
      >
        <div className="flex text-gray-700 space-x-2 opacity-50">
          <Icon iconName="eye" />
          <span className="font-rubic font-medium text-[1rem]">View only</span>
        </div>
      </div>
    </>
  )
}
