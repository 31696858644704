import clsx from 'clsx'

import { useStyles } from './styles'
import { Props } from './types'

export const Typography = ({
  children,
  className,
  style,
  testId,
  variant,
}: Props) => {
  const classes = useStyles()

  switch (variant) {
    case 'h1':
      return (
        <h1
          data-testid={testId}
          className={clsx([classes.h1, className])}
          style={style}
        >
          {children}
        </h1>
      )
    case 'h2':
      return (
        <h2
          data-testid={testId}
          className={clsx([classes.h2, className])}
          style={style}
        >
          {children}
        </h2>
      )
    case 'h3':
      return (
        <h3
          data-testid={testId}
          className={clsx([classes.h3, className])}
          style={style}
        >
          {children}
        </h3>
      )
    case 'h4':
      return (
        <h4
          data-testid={testId}
          className={clsx([classes.h4, className])}
          style={style}
        >
          {children}
        </h4>
      )
    case 'h5':
      return (
        <h5
          data-testid={testId}
          className={clsx([classes.h5, className])}
          style={style}
        >
          {children}
        </h5>
      )
    case 'h6':
      return (
        <h6
          data-testid={testId}
          className={clsx([classes.h6, className])}
          style={style}
        >
          {children}
        </h6>
      )
    case 'body1':
      return (
        <div
          data-testid={testId}
          className={clsx([classes.body1, className])}
          style={style}
        >
          {children}
        </div>
      )
    case 'body2':
      return (
        <div
          data-testid={testId}
          className={clsx([classes.body2, className])}
          style={style}
        >
          {children}
        </div>
      )
    case 'subtitle1':
      return (
        <p
          data-testid={testId}
          className={clsx([classes.subtitle1, className])}
          style={style}
        >
          {children}
        </p>
      )
    case 'subtitle2':
      return (
        <p
          data-testid={testId}
          className={clsx([classes.subtitle2, className])}
          style={style}
        >
          {children}
        </p>
      )
    case 'button':
      return (
        <div
          data-testid={testId}
          className={clsx([classes.button, className])}
          style={style}
        >
          {children}
        </div>
      )
    case 'caption':
      return (
        <p
          data-testid={testId}
          className={clsx([classes.caption, className])}
          style={style}
        >
          {children}
        </p>
      )
    case 'label':
      return (
        <label
          data-testid={testId}
          htmlFor={children as string}
          className={clsx([classes.label, className])}
          style={style}
        >
          {children}
        </label>
      )
  }
}
