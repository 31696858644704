import { useMutation } from '@apollo/client'
import { ResetPasswordPanel } from '@getjelly/jelly-ui'
import { ResetPasswordData } from '@getjelly/jelly-ui/dist/components/organisms/onboarding/ResetPasswordPanel'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Mutation, MutationRequestPasswordResetCodeArgs } from 'api'
import { routes } from 'routes/Paths'
import { cleanErrorMessage, logEvent } from 'utils'

import { requestPasswordResetCodeMutation } from './graphql'

import {
  selectAuthFlow,
  setCountryCode,
  setPhoneNumber,
} from '../../store/authFlow'

export function ForgotPassword() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [errors, setErrors] = useState<
    Partial<Record<keyof ResetPasswordData, string>> | undefined
  >(undefined)

  const [resetPasswordData, setResetPasswordData] = useState<
    ResetPasswordData | undefined
  >(undefined)

  const authFlow = useSelector(selectAuthFlow())

  const [requestCodeReset, { loading }] = useMutation<
    { requestCodeReset: Mutation['requestPasswordResetCode'] },
    MutationRequestPasswordResetCodeArgs
  >(requestPasswordResetCodeMutation)

  async function resetPassword(data: ResetPasswordData) {
    logEvent('attempt', {
      event_category: 'password_reset_code_request',
      event_label: 'submit',
    })

    try {
      const { data: response } = await requestCodeReset({
        variables: {
          countryCode: data.countryCode,
          phoneNumber: data.phoneNumber,
        },
      })

      if (!response) return

      logEvent('attempt', {
        event_category: 'password_reset_code_request',
        event_label: 'success',
      })

      dispatch(setCountryCode(data.countryCode))
      dispatch(setPhoneNumber(data.phoneNumber))
      navigate(routes.ResetPassword)
    } catch (e) {
      logEvent('attempt', {
        event_category: 'password_reset_code_request',
        event_label: 'failed',
      })

      return setErrors({ phoneNumber: cleanErrorMessage((e as Error).message) })
    }
  }

  return (
    <ResetPasswordPanel
      countryCode={authFlow.countryCode}
      phoneNumber={authFlow.phoneNumber}
      errors={errors}
      onChange={setResetPasswordData}
      resetPassword={resetPassword}
      loginLinkClicked={() => {
        dispatch(setCountryCode(resetPasswordData?.countryCode))
        dispatch(setPhoneNumber(resetPasswordData?.phoneNumber))
        navigate(routes.SignIn)
      }}
      loading={loading}
    />
  )
}
