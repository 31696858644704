import dayjs from 'dayjs'
import { isNil } from 'ramda'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  UnitFragmentFragment,
  useGetFullKitchenQuery,
  useGetStockNodeQuery,
} from 'api/types'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import { Typography } from 'components/newUi'
import { routes } from 'routes/Paths'
import { StockTakeEntry, updateStocktake } from 'store/stocktake'

const resetStocktakeEntry = (entry: StockTakeEntry) => ({
  ...entry,
  quantity: 0,
  total: 0,
})

export const NewStocktake = () => {
  const { cursor } = useParams()
  const dispatch = useDispatch()
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const [tempStockData] = useSearchParams()

  const date = tempStockData.get('date') ?? new Date()
  const name = tempStockData.get('name') ?? 'New Stocktake'

  const isBlank = cursor === 'blank'
  const yearAgo = useMemo(() => dayjs().subtract(1, 'year').toDate(), [])
  const { data: stockNodeData, loading: stockNodeLoading } =
    useGetStockNodeQuery({
      skip: !cursor || isBlank,
      variables: {
        cursor,
      },
    })

  const { data: fullKitchenData, loading: fullKitchenLoading } =
    useGetFullKitchenQuery({
      skip: !!cursor,
      variables: {
        kitchenId: selectedKitchen?.id as number,
        time: yearAgo,
      },
    })

  const loading = stockNodeLoading || fullKitchenLoading
  const data = isBlank ? true : !cursor ? fullKitchenData : stockNodeData

  useEffect(() => {
    if (loading || !data) return
    const newCursor = `DRAFT-${dayjs().toISOString()}`

    const populateEntries = (): StockTakeEntry[] => {
      const ingredientEntries = fullKitchenData?.ingredientList.nodes
        .filter(
          (ingredient) =>
            !isNil(ingredient.price) && !isNil(ingredient.product.unit.id),
        )
        .map((ingredient) => ({
          ingredient,
          ingredientId: ingredient.id,
          price: ingredient.price,
          unit: ingredient.product.unit,
          unitId: ingredient.product.unit.id,
        }))

      const recipeEntries = fullKitchenData?.recipeList.nodes
        .filter((recipe) => !isNil(recipe.unitCost) && !isNil(recipe.unit?.id))
        .map((recipe) => ({
          price: recipe.unitCost,
          recipe,
          recipeId: recipe.id,
          unit: recipe.unit as UnitFragmentFragment,
          unitId: recipe.unit?.id as number,
        }))

      return [...(ingredientEntries || []), ...(recipeEntries || [])]
    }

    const entries = (() => {
      if (isBlank) return []

      if (!isNil(stockNodeData) && !isNil(stockNodeData.stockNode)) {
        return stockNodeData.stockNode.entryList.nodes.map((entry) => ({
          ...entry,
          price:
            entry.ingredient?.price || entry.recipe?.unitCost || entry.price,
        }))
      }

      return populateEntries()
    })()

    dispatch(
      updateStocktake({
        _cursor: newCursor,
        date,
        entries: entries.map(resetStocktakeEntry),
        isDraft: true,
        kitchenId: selectedKitchen?.id ?? -1,
        name,
        total: 0,
      }),
    )

    navigate(`${routes.Stock}/${newCursor}`, {
      replace: true,
    })
  }, [loading])

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <div>
        <Typography variant="h5">Generating Stocktake</Typography>
        <Loader />
      </div>
    </div>
  )
}
