import { useQuery } from '@apollo/client'
import {
  DetailsSection,
  InfoAlert,
  Typography,
  Button,
} from '@getjelly/jelly-ui'
import dayjs from 'dayjs'
import { isNil } from 'ramda'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { InvoiceStatus, Query, QueryInvoiceArgs } from 'api'
import { DATE_FORMATS } from 'app'
import {
  Alert,
  Icon,
  LeaveConfirmationModal,
  NewLayout,
} from 'components/newUi'
import { routes } from 'routes/Paths'
import { Image } from 'screens/Invoices/Add'
import { pendingInvoiceQuery } from 'screens/Invoices/graphql'
import { useHooks } from 'screens/Invoices/hooks'
import { NotesContainer } from 'screens/Spending/Invoices/SingleInvoice/components/NotesContainer'
import { DARK_GREY } from 'styles/colors'
import { useTheme } from 'styles/newUi'
import formatRejectionReasonAlert from 'utils/invoice/formatRejectionReasonAlert'

import { useKitchen } from '../../../../../app/contexts/SelectedKitchen'
import { Images } from '../RegularInvoice/components/Images'
import { useStyles } from '../styles'

export const ViewPendingInvoice = () => {
  const { selectedKitchen } = useKitchen()
  const classes = useStyles()
  const { id } = useParams()
  const navigate = useNavigate()
  const { deletePendingInvoice } = useHooks()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { theme } = useTheme()

  const { data } = useQuery<
    { pendingInvoice: Query['pendingInvoice'] },
    QueryInvoiceArgs
  >(pendingInvoiceQuery, {
    fetchPolicy: 'cache-and-network',
    skip: isNil(id),
    variables: { where: { id: Number(id) } },
  })

  useEffect(() => {
    if (isNil(data)) return
  }, [data])

  const handleDelete = () => {
    deletePendingInvoice({
      variables: {
        id: parseInt(id),
      },
    })

    setShowDeleteModal(false)

    setTimeout(() => {
      navigate(`${routes.Spending}${routes.Invoice}${routes.History}`)
    }, 2000)
  }

  const handleUpload = () =>
    navigate(`${routes.Spending}${routes.Invoice}${routes.Add}`)

  if (!data?.pendingInvoice) return null

  const {
    status,
    rejectionReason,
    rejectionReasonNotes,
    createdBy,
    createdAt,
  } = data.pendingInvoice

  return (
    <>
      <NewLayout
        hideMenu
        title="Pending Invoice"
        subtitle="Spending"
        bottomContent={
          selectedKitchen?.userPermissions?.includes(
            'delete-pending-invoice',
          ) ? (
            <div className="flex items-center space-x-2 px-2 py-4">
              <Button
                label="Delete Invoice"
                style="delete"
                className="w-full"
                onClick={() => setShowDeleteModal(true)}
              />

              {status === InvoiceStatus.Rejected && (
                <Button
                  label="Upload Another"
                  className="w-full"
                  onClick={handleUpload}
                />
              )}
            </div>
          ) : (
            <></>
          )
        }
      />

      {status === 'rejected' ? (
        <Alert type="error">
          <div className="flex items-center text-primary-900 space-x-2">
            <Icon iconName="notifications" className="text-error-400" />

            <div className="flex items-center text-primary-900 text-base leading-3">
              {formatRejectionReasonAlert(
                rejectionReason,
                rejectionReasonNotes,
              )}
            </div>
          </div>
        </Alert>
      ) : (
        <InfoAlert text="We try to process invoices within 24 hours - check back soon!" />
      )}

      <div
        className={classes.infoContainer}
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {data.pendingInvoice.signedImages && (
          <div style={{ paddingRight: 12 }}>
            {data.pendingInvoice.signedImages.length === 1 && (
              <Image
                imageSize={42}
                localImage={false}
                url={data.pendingInvoice.signedImages[0]}
              />
            )}

            {data.pendingInvoice.signedImages?.length > 1 && (
              <div
                style={{
                  height: 55,
                  position: 'relative',
                  width: 50,
                }}
              >
                <div
                  style={{
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    zIndex: 10,
                  }}
                >
                  <Image
                    imageSize={42}
                    localImage={false}
                    url={data.pendingInvoice.signedImages[0]}
                  />
                </div>

                <div
                  style={{
                    bottom: 0,
                    position: 'absolute',
                    right: 0,
                  }}
                >
                  <Image
                    imageSize={42}
                    localImage={false}
                    url={data.pendingInvoice.signedImages[1]}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col justify-between flex-1 font-rubik">
          <Typography style="subtitle1" className="capitalize text-primary-800">
            ...
          </Typography>

          <div
            style={{
              color: DARK_GREY,
              fontSize: 14,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            ...
          </div>
        </div>

        <div
          className={classes.itemInnerMobile}
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            flex: 0.4,
            flexDirection: 'column',
            marginRight: 8,
          }}
        >
          <p className="text-primary-900 font-medium text-base">£...</p>

          <p className="text-primary-900 font-normal text-base">+ £... VAT</p>
        </div>
      </div>

      <NotesContainer pendingInvoiceId={Number(id)} />

      <DetailsSection
        title="Uploaded invoices"
        rightTitle={`${
          data.pendingInvoice?.signedImages.length ?? 0
        } documents`}
      >
        <Images documents={data.pendingInvoice?.signedImages ?? []} />
      </DetailsSection>

      <div className="w-full flex justify-center">
        <div
          style={{
            color: theme.palette.primary[60].toHexString(),
            marginBottom: 18,
            marginTop: 36,
            textAlign: 'center',
            width: '80%',
          }}
        >
          {`${createdBy.firstName} ${
            createdBy.lastName
          } uploaded this document on
            ${dayjs(createdAt).format(
              DATE_FORMATS.I_AM_RUNNING_OUT_OF_IDEAS_FOR_NAMES_DATE_FORMAT,
            )} at ${dayjs(createdAt).format(DATE_FORMATS.TIME_ONLY)}`}
        </div>
      </div>

      <LeaveConfirmationModal
        action="Yes, delete"
        block={false}
        title="Delete invoice"
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        text={'Are you sure you want to delete this invoice?'}
      />
    </>
  )
}
