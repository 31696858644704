import { useNavigate } from 'react-router-dom'

type Props = {
  text: string
  selected: boolean
  fullWidth: boolean
  route: string | (() => void)
}

export function ScrollMenuItem({
  text,
  selected,
  fullWidth = false,
  route,
}: Props) {
  const navigate = useNavigate()

  const base =
    'border-b-[3px] cursor-pointer px-4 flex items-center justify-center h-11'
  const width = fullWidth ? 'w-full' : 'w-auto'

  const selectedClass = selected
    ? 'text-primary-900 border-primary-900'
    : 'text-primary-600 border-primary-200'

  return (
    <div
      onClick={() => {
        if (typeof route === 'string') {
          navigate(route, { replace: true })
        } else {
          route()
        }
      }}
      className={`${base} ${selectedClass} ${width}`}
    >
      <span className="font-rubik font-medium text-sm whitespace-nowrap">
        {text}
      </span>
    </div>
  )
}
