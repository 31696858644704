import { gql } from '@apollo/client'

export const ingredientsAndRecipesByKitchenQuery = gql`
  query ingredientsAndRecipesByKitchenQuery(
    $orderByName: Boolean
    $take: Int
    $recipeTake: Int
    $searchTerm: String
    $skip: Int
    $ingredientWhere: IngredientWhereInput
    $recipeWhere: RecipeWhereInput
    $ingredientOrderBy: [IngredientOrderByInput!]
    $recipeOrderBy: [RecipeOrderByInput!]
  ) {
    ingredientsCount(where: $ingredientWhere)
    recipesCount(where: $recipeWhere)
    ingredientsAndRecipes(
      orderByName: $orderByName
      searchTerm: $searchTerm
      skip: $skip
      take: $take
      recipeTake: $recipeTake
      recipeWhere: $recipeWhere
      ingredientWhere: $ingredientWhere
      ingredientOrderBy: $ingredientOrderBy
      recipeOrderBy: $recipeOrderBy
    ) {
      ingredients {
        _cursor
        id
        createdAt
        updatedAt
        price
        wastage
        unitPrice
        wasteAdjustedUnitPrice
        conversionUnit
        conversionUnitType {
          _cursor
          id
          name
          type
          gramsPerUnit
        }
        conversionUnitValue
        product {
          _cursor
          id
          code
          name
          packSize
          unitValue
          price
          unit {
            _cursor
            id
            name
            type
          }
          status
          unitValue
          supplier {
            _cursor
            id
            name
          }
        }
      }
      recipes {
        name
        _cursor
        id
        amount
        imageUrl
        instructions
        section
        unit {
          _cursor
          id
          name
          type
        }
        unitCost
        createdAt
        updatedAt
        isDraft
        isCalculatedDraft
      }
    }
  }
`
