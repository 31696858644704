import { gql } from '@apollo/client'

export const kitchenSetupStateQuery = gql`
  query KitchenSetupStateQuery($kitchenId: Int!) {
    kitchenSetupState(kitchenId: $kitchenId) {
      addedOneDish
      addedOneMenu
      addedOneOrder
      addedOneSale
      addedOneStockSheet
      addedOneTeamMember
      addedSixInvoices
      automatedOneInvoice
      checkedPriceChanges
    }
  }
`

export const updateOneKitchenMutation = gql`
  mutation UpdateOneKitchen($data: UpdateOneKitchenInputObject!) {
    updateOneKitchen(data: $data) {
      id
    }
  }
`

export const kitchenTodosQuery = gql`
  query kitchenTodosQuery($kitchenId: Int!, $skip: Int!, $take: Int!) {
    kitchenTodos(kitchenId: $kitchenId, skip: $skip, take: $take) {
      assignee {
        id
        isMe
        firstName
        lastName
      }
      assigneeId
      createdAt
      completedAt
      description
      id
      title
      updatedAt
    }
  }
`

export const createKitchenTodoMutation = gql`
  mutation CreateKitchenTodo($data: CreateKitchenTodoInput!) {
    createOneKitchenTodo(data: $data) {
      id
    }
  }
`
