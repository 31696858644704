import { useApolloClient } from '@apollo/client'
import { createContext, useContext, FC, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Kitchen } from 'api'
import { selectKitchen, setCounts, setKitchen } from 'store/kitchen'

import { getKitchenNodeQuery } from '../../screens/Settings/Locations/graphql'
import { encodeCursor } from '../../utils'

export interface ISelectedKitchen {
  selectedKitchen: Kitchen | undefined
  setSelectedKitchen: (value: Kitchen | undefined) => void
  refetchSelectedKitchen: () => Promise<void>
  selectKitchenById: (id: number) => Promise<Kitchen | undefined>
}

const SelectedKitchenDefaults: ISelectedKitchen = {
  refetchSelectedKitchen: () => Promise.resolve(),
  selectKitchenById: (_: number) => Promise.resolve(undefined),
  selectedKitchen: undefined,
  setSelectedKitchen: () => {
    //
  },
}

export const SelectedKitchenContext = createContext(SelectedKitchenDefaults)

export function useKitchen() {
  return useContext(SelectedKitchenContext)
}

export interface IProps {
  children?: ReactNode
}

export const SelectedKitchenProvider: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const selectedKitchen = useSelector(selectKitchen())
  const client = useApolloClient()

  function setSelectedKitchen(kitchen: Kitchen | undefined) {
    dispatch(setKitchen(kitchen))
  }

  async function refetchSelectedKitchen() {
    if (!selectedKitchen) {
      return
    }

    try {
      await selectKitchenById(selectedKitchen.id)
    } catch (error) {
      console.error('Error refetching the selected kitchen: ', error)
    }
  }

  async function selectKitchenById(id: number): Promise<Kitchen | undefined> {
    try {
      const { data } = await client.query<{ kitchenNode: Kitchen }>({
        query: getKitchenNodeQuery,
        variables: { cursor: encodeCursor('Kitchen', id) },
      })

      dispatch(setKitchen(data.kitchenNode))

      dispatch(
        setCounts({
          dishCount: data.kitchenNode.dishCount,
          ingredientCount: data.kitchenNode.ingredientCount,
          invoiceCount: data.kitchenNode.invoiceCount,
          menuCount: data.kitchenNode.menuCount,
          orderCount: data.kitchenNode.orderCount,
          priceChangeCount: data.kitchenNode.priceChangeCount,
          recipeCount: data.kitchenNode.recipeCount,
          salesCount: data.kitchenNode.salesCount,
          stocktakeCount: data.kitchenNode.stocktakeCount,
          todoCount: data.kitchenNode.todoCount,
        }),
      )

      return data.kitchenNode
    } catch (error) {
      console.error(`Error fetching the given kitchen: ${id}`, error)
    }

    return undefined
  }

  return (
    <SelectedKitchenContext.Provider
      value={{
        refetchSelectedKitchen,
        selectKitchenById,
        selectedKitchen,
        setSelectedKitchen,
      }}
    >
      <>{props?.children}</>
    </SelectedKitchenContext.Provider>
  )
}
