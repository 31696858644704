import { ReactElement } from 'react'

import { Typography } from 'components/newUi/Typography'
import { Theme, useTheme } from 'styles/newUi'

export const HeaderSection: React.FunctionComponent<{
  title: string
  variant?: keyof Theme['typography']
  action?: ReactElement
}> = (props) => {
  const { theme } = useTheme()
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: theme.palette.primary[10].toHexString(),
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        width: '100%',
      }}
    >
      <Typography
        style={{ color: theme.palette.primary[60].toHexString() }}
        variant={props.variant ? props.variant : 'button'}
      >
        {props.title}
      </Typography>
      <div>{props.action}</div>
    </div>
  )
}
