import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme) => {
    return {
      button: {
        borderColor: theme.palette.error[100].toHexString(),
        color: theme.palette.error[100].toHexString(),
        fontWeight: 'bold',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        whiteSpace: 'nowrap',
        width: 'unset',
      },
      startAdornment: {
        color: theme.palette.primary[40].toHexString(),
        left: 10,
        padding: 3,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      },
    }
  })
