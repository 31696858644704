import { Button } from '@getjelly/jelly-ui'
import { useState } from 'react'

import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { useTheme } from '../../../../styles/newUi'
import { ExportInvoicesModal } from '../modals/ExportInvoicesModal'

export function ExportButton() {
  const { selectedKitchen } = useKitchen()
  const { theme } = useTheme()
  const [showExportModal, setShowExportModal] = useState(false)

  return (
    <>
      <ExportInvoicesModal
        show={showExportModal}
        setDisplay={setShowExportModal}
        selectedKitchen={selectedKitchen!}
        theme={theme}
      />

      <Button
        style="secondary"
        label="Export"
        onClick={() => setShowExportModal(true)}
      />
    </>
  )
}
