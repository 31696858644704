import { Formik } from 'formik'
import { memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import { Allergen, Recipe, RecipeToAllergen } from 'api'
import { Checkbox } from 'components/Checkbox'
import {
  Typography,
  NewButton,
  NewLayout,
  LeaveConfirmationModal,
} from 'components/newUi'
import { useUpdateComponent } from 'screens/Create/Ingredients/hooks'
import { theme } from 'styles/newUi'
import { capitaliseEachWord, cleanErrorMessage } from 'utils'

import { useStyles } from './styles'

import { errorToast, successToast } from '../../components/toasts'

export interface IProps {
  allergens: Allergen[]
  recipe: Recipe
  isDish: boolean
  id: number
}

export const Allergens = ({ allergens, recipe, isDish, id }: IProps) => {
  const classes = useStyles()
  // const { gte: isDesktop } = useWindowSize('md')
  const [override, setOverride] = useState(false)

  const [updateComponent] = useUpdateComponent(isDish)
  const navigate = useNavigate()

  const initalValues = {
    allergens: allergens.map((item) => {
      const data = recipe.allergens?.find(
        (data) => data.allergen.id === item.id,
      )

      if (data) return data

      const allergen: Partial<RecipeToAllergen> = {
        allergen: item,
        contains: false,
        removable: false,
      }

      return allergen
    }),
    hasNoAllergens: recipe.hasNoAllergens ?? false,
  }

  const allergenMapper = <T,>(allergens: Partial<RecipeToAllergen>[]) =>
    allergens.map((link) => {
      const { allergen, contains, removable } = link

      // @ts-ignore
      return {
        contains,
        id: allergen!.id,
        removable,
      } as T
    })

  return (
    <Formik
      initialValues={initalValues}
      onSubmit={async (state) => {
        const initialAllergenIds = (recipe.allergens ?? []).map(
          (initial) => initial.allergen.id,
        )

        const allergensToUpdate = state.allergens.filter((allergen) =>
          initialAllergenIds.includes(allergen.allergen!.id),
        )

        const allergensToAdd = state.allergens.filter(
          (allergen) => !initialAllergenIds.includes(allergen.allergen!.id),
        )

        await updateComponent({
          variables: {
            allergens: allergenMapper(allergensToAdd),
            data: {
              hasNoAllergens: state.hasNoAllergens,
              id: id,
            },
            updateAllergens: allergenMapper(allergensToUpdate),
          },
        })
          .then(() => {
            successToast(`Saved allergens`)
            setOverride(true)
            navigate(-1)
          })
          .catch((error) => errorToast(cleanErrorMessage(error.message)))

        return
      }}
    >
      {(formik) => {
        const handleUpdate = (key: string, index: number) => {
          // @ts-ignore
          const newValue = !formik.values.allergens[index][key]

          if (newValue === true) {
            formik.setFieldValue('hasNoAllergens', false)
          }

          formik.setFieldValue(
            `allergens[${index}].${key}`,

            newValue,
          )
        }
        return (
          <form onSubmit={formik.handleSubmit}>
            <LeaveConfirmationModal
              action="leave"
              title="You have unsaved changes, are you sure you want to leave the screen?"
              text=""
              onConfirm={() => {
                formik.resetForm()
                navigate(-1)
              }}
              show={formik.dirty && !formik.isSubmitting && !override}
            />
            <NewLayout
              title="Allergens"
              subtitle="Create Dish"
              bottomContent={
                <div
                  style={{
                    borderTop: `solid 1px ${theme.palette.primary[10].toHexString()}`,
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '5px 0px',
                  }}
                >
                  <NewButton
                    data-testid="create-allergens-button"
                    disabled={!formik.dirty || formik.isSubmitting}
                    text={formik.isSubmitting ? undefined : 'Save Allergens'}
                    disableLoaderStyles
                    loading={formik.isSubmitting}
                    className={classes.button}
                    onClick={formik.handleSubmit}
                  />
                </div>
              }
            />
            <div
              style={{
                backgroundColor: theme.palette.common.light.toHexString(),
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'grid',
                      gridTemplateColumns: '70px 1fr 56px',
                    }}
                  >
                    <div style={{ padding: '0px 10px' }}>
                      <ReactSVG
                        width="50"
                        height="50"
                        aria-label="no-allergens"
                        className={classes.noAllergens}
                        src="https://iinu-pictures-production.s3.eu-west-2.amazonaws.com/96E187F8261B477494CD6B69E5C4B17F/noAllergens.svg"
                      ></ReactSVG>
                    </div>
                    <div>
                      <Typography variant="subtitle1">No Allergens</Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: -5,
                      }}
                    >
                      <Checkbox
                        checked={formik.values.hasNoAllergens}
                        defaultChecked={formik.values.hasNoAllergens}
                        onChange={() => {
                          formik.setFieldValue(
                            'hasNoAllergens',
                            !formik.values.hasNoAllergens,
                          )

                          if (!formik.values.hasNoAllergens === true) {
                            formik.setValues({
                              allergens: [
                                ...formik.values.allergens.map((val) => ({
                                  ...val,
                                  contains: false,
                                  removable: false,
                                })),
                              ],
                              hasNoAllergens: true,
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      backgroundColor: theme.palette.primary[10].toHexString(),
                      display: 'Grid',
                      gridTemplateColumns: '1fr 100px 100px',
                      padding: '8px 12px',
                      textAlign: 'center',
                    }}
                  >
                    <div></div>
                    <div style={{ marginLeft: -5 }}>
                      <Typography
                        variant="button"
                        style={{
                          color: theme.palette.primary[60].toHexString(),
                        }}
                      >
                        Has Allergen
                      </Typography>
                    </div>
                    <div style={{ marginLeft: -5 }}>
                      <Typography
                        variant="button"
                        style={{
                          color: theme.palette.primary[60].toHexString(),
                        }}
                      >
                        Is Removable
                      </Typography>
                    </div>
                  </div>
                </div>

                <div style={{ overflow: 'auto' }}>
                  <div>
                    {formik.values.allergens?.map((item, index) => {
                      let className = classes.allergenNone

                      if (item.contains) {
                        className = classes.allergenContains
                      } else if (item.removable) {
                        className = classes.allergenRemovable
                      }

                      return (
                        <AllergenRow
                          key={item!.allergen!.id}
                          className={className}
                          index={index}
                          handleUpdate={handleUpdate}
                          id={item!.allergen!.id}
                          imageUrl={item!.allergen!.imageUrl!}
                          contains={formik.values.allergens[index].contains!}
                          removable={formik.values.allergens[index].removable!}
                          type={item.allergen!.type}
                          defaultContains={
                            formik.values.allergens[index].contains!
                          }
                          defaultRemovable={
                            formik.values.allergens[index].removable!
                          }
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

const RenderAllergen = ({
  id,
  index,
  className,
  defaultContains,
  contains,
  removable,
  defaultRemovable,
  imageUrl,
  handleUpdate,
  type,
}: {
  id: number
  contains: boolean
  removable: boolean
  index: number
  type: string
  imageUrl: string
  className: string
  defaultContains: boolean
  defaultRemovable: boolean
  handleUpdate: (key: string, index: number) => void
}) => {
  return (
    <div
      key={id}
      style={{
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: '1fr 100px 100px',
        justifyContent: 'space-around',
        padding: '10px 0',
        textAlign: 'center',
      }}
    >
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <div style={{ padding: '0px 10px' }}>
          <ReactSVG
            width="50"
            height="50"
            className={className}
            src={imageUrl}
          ></ReactSVG>
        </div>
        <div>
          <Typography
            variant="subtitle1"
            style={{
              color: defaultContains
                ? theme.palette.error[100].toHexString()
                : theme.palette.primary[100].toHexString(),
            }}
          >
            {capitaliseEachWord(type)}
          </Typography>
        </div>
      </div>
      <div>
        <Checkbox
          color="primary"
          onChange={() => {
            handleUpdate('contains', index)
          }}
          checked={contains}
          defaultChecked={defaultContains}
        />
      </div>
      <div>
        <Checkbox
          color="primary"
          onChange={() => {
            handleUpdate('removable', index)
          }}
          checked={removable}
          defaultChecked={defaultRemovable}
        />
      </div>
    </div>
  )
}

const AllergenRow = memo(RenderAllergen)
