import { useQuery, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { Query, MutationCreateOneDishArgs, Mutation } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { NewTextField, NewButton } from 'components'
import { Icon, NewLayout } from 'components/newUi'
import { Dropdown } from 'components/newUi/Dropdown'
import { buildCreateRoute } from 'routes/Paths'
import { createDishMutation } from 'screens/Create/graphql/dish'
import { cleanErrorMessage } from 'utils'

import { getDishTypesQuery } from './graphql'

import { errorToast } from '../../../components/toasts'

export const CreateDish = () => {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const { data: dishTypesData } = useQuery<{ dishTypes: Query['dishTypes'] }>(
    getDishTypesQuery,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const [createDish] = useMutation<
    { createOneDish: Mutation['createOneDish'] },
    MutationCreateOneDishArgs
  >(createDishMutation, {
    onCompleted: (data) => {
      navigate(buildCreateRoute(data?.createOneDish?.id, true), {
        replace: true,
      })
    },
    onError: (error) => {
      if (error) {
        errorToast(cleanErrorMessage(error.message))
      }
    },
  })

  const handleCreate = (data: {
    name: string
    entity: string | null
    typesection: string | null
  }) => {
    if (!data.typesection) return
    createDish({
      variables: {
        data: {
          name: data.name,
          portion: 1,
          type: data.typesection,
        },
        kitchenId: selectedKitchen!.id,
      },
    })
  }

  const formik = useFormik({
    initialValues: {
      entity: null,
      name: '',
      typesection: null,
    },
    onSubmit: handleCreate,
  })

  const dropdownOptions = dishTypesData?.dishTypes.map((dt) => ({
    label: dt.name,
    value: dt.name,
  }))

  return (
    <>
      <NewLayout
        subtitle="Costing"
        title="Create New Dish"
        hideMenu
        bottomContent={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <NewButton
              disabled={!(formik.values.name && formik.values.typesection)}
              text="Create New Dish"
              onClick={() => formik.handleSubmit()}
              rightAdornment={
                <Icon
                  iconName="chevronRight"
                  style={{ marginLeft: 4, marginRight: -4 }}
                />
              }
            />
          </div>
        }
      />
      <form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 12,
            marginLeft: 12,
            marginRight: 12,
          }}
        >
          <NewTextField
            label="Name"
            required
            style={{ flex: 0.9, paddingRight: 12 }}
            error={!!(formik.touched.name && formik.errors.name)}
            onChange={(value) => formik.setFieldValue('name', value)}
          ></NewTextField>
          <Dropdown
            style={{ flex: 0.9, paddingRight: 12 }}
            label={'Type'}
            options={dropdownOptions}
            onChange={(value) => formik.setFieldValue('typesection', value)}
          ></Dropdown>
        </div>
      </form>
    </>
  )
}
