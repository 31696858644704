import { gql } from '@apollo/client'

export const inviteDetailsQuery = gql`
  query InviteDetails($joinCode: String!, $inviteCode: String) {
    kitchenInvitePublic(joinCode: $joinCode, inviteCode: $inviteCode) {
      isExistingUser
      kitchenId
      kitchenName
    }
  }
`

export const acceptInviteMutation = gql`
  mutation acceptInvite($joinCode: String!, $inviteCode: String) {
    acceptInvite(joinCode: $joinCode, inviteCode: $inviteCode)
  }
`
