import { gql } from '@apollo/client'

import { MutationCreateOneIngredientArgs } from 'api'

const createOneIngredientFragment = gql`
  fragment CreateOneIngredient on Ingredient {
    _cursor
    id
    wastage
    price
    wastage
    unitPrice
    wasteAdjustedUnitPrice
    conversionUnit
    conversionUnitType {
      _cursor
      id
      name
      type
      gramsPerUnit
    }
    conversionUnitValue
    product {
      _cursor
      id
      code
      name
      packSize
      price
      status
      unit {
        _cursor
        id
        name
        type
      }
      unitValue
      supplier {
        _cursor
        id
        name
      }
    }
  }
`

/* eslint-disable graphql/required-fields */
export const createIngredientWithSupplierMutation = gql`
  mutation createIngredient(
    $data: CreateOneIngredientInputObject!
    $kitchenId: Int!
    $supplierId: Int!
    $productId: Int
    $supplierData: CreateOneSupplierInputObject
  ) {
    connectOneSupplier(kitchenId: $kitchenId, supplierId: $supplierId) {
      _cursor
      id
    }
    createOneIngredient(
      data: $data
      kitchenId: $kitchenId
      supplierId: $supplierId
      productId: $productId
      supplierData: $supplierData
    ) {
      ...CreateOneIngredient
    }
  }
  ${createOneIngredientFragment}
`

export const createIngredientMutation = gql`
  mutation createIngredient(
    $data: CreateOneIngredientInputObject!
    $kitchenId: Int!
    $supplierId: Int
    $productId: Int
    $supplierData: CreateOneSupplierInputObject
  ) {
    createOneIngredient(
      data: $data
      kitchenId: $kitchenId
      supplierId: $supplierId
      productId: $productId
      supplierData: $supplierData
    ) {
      ...CreateOneIngredient
    }
  }
  ${createOneIngredientFragment}
`
/* eslint-enable graphql/required-fields */

export const createIngredientMutationVariables = (
  kitchenId: number,
  name: string,
  unitId: number,
  waste: string,
  supplierId: number,
): MutationCreateOneIngredientArgs => ({
  data: {
    name: name,
    packSize: 0,
    price: 0,
    unitId,
    unitPrice: 0,
    unitValue: 0,
    wastage: parseInt(waste),
  },
  kitchenId: kitchenId,
  supplierId: supplierId,
})
