import { gql } from '@apollo/client'

export const recipeList = gql`
  query recipeList(
    $cursor: ID
    $orderBy: [RecipeOrderByInput!]
    $skip: Int
    $take: Int
    $where: RecipeWhereInput
  ) {
    recipeList(
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
        skip
        take
        currentPage
        totalCount
        totalPages
      }
      nodes {
        _cursor
        id
        name
        imageUrl
        section
        isDraft
        status
        isCalculatedDraft
        dishList(take: 1) {
          nodes {
            _cursor
            id
            name
            imageUrl
            type
            isDraft
            isCalculatedDraft
          }
        }
      }
    }
  }
`

export const getDishTypesQuery = gql`
  query DishTypes {
    dishTypes {
      name
    }
  }
`

export const getSectionsQuery = gql`
  query Sections {
    sections {
      name
    }
  }
`
