import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  date: {
    width: 150,
  },
  fontGrey: {
    color: theme.palette.grey[500],
  },
  info: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: '100%',
  },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 3,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: 'unset',
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'unset',
      margin: 0,
    },
  },
  itemContainerMobile: {
    display: 'flex',
    height: '100%',
    paddingRight: theme.spacing(2),
  },
  itemDesktop: {
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  itemInner: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: theme.spacing(3),
    textTransform: 'uppercase',
  },
  itemInnerMobile: {
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  // itemMobile: {
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
  itemsContainer: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 3,
    height: 'calc(100vh - 155px)',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: 'scroll',
    paddingBottom: 1,
    paddingTop: 1,
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'unset',
      borderRadius: 0,
      height: 'calc(100vh - 115px)',
      margin: 0,
      padding: 0,
    },
  },
  root: {
    // @ts-ignore
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 150px)',
    },

    // marginTop: theme.spacing(5),
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
    },
  },

  searchBar: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },
  title: {
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  // eslint-disable-next-line
  grey: {
    backgroundColor: '#F7F7F7',
  },
}))
