import { Pill } from '@getjelly/jelly-ui'
import { useState } from 'react'

import { AutomateInvoiceModal } from '../modals/AutomateInvoiceModal'

export function AutomatedBadge() {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <AutomateInvoiceModal show={showModal} setDisplay={setShowModal} />

      <Pill
        variant="success"
        label="Automated"
        onClick={(e) => {
          e.stopPropagation()
          setShowModal(true)
        }}
      />
    </>
  )
}
