import { Button, Typography, Modal } from '@getjelly/jelly-ui'
import { isBefore } from 'date-fns'

import { Kitchen } from '../../../api'

export interface IProps {
  handleCancel: () => void
  show: boolean
  kitchen: Kitchen
}

export function ViewOnlyModal({ show, handleCancel, kitchen }: IProps) {
  return (
    <Modal open={show} onClose={handleCancel}>
      <div className="text-center">
        {kitchen.permissions?.tier === 'STANDARD' &&
        (!kitchen.permissions.trialExpiresAt ||
          isBefore(kitchen.permissions.trialExpiresAt, new Date())) ? (
          <div className="space-y-8 w-full">
            <div className="space-y-2">
              <Typography style="h6" className="text-primary-900">
                You are a viewing member
              </Typography>

              <Typography style="caption" className="text-primary-600">
                Choose a paid plan that fits your needs.
              </Typography>
            </div>

            <Button
              onClick={() =>
                window.open('https://getjelly.co.uk/upgrade', '_blank')
              }
              label="Upgrade Your Plan"
              className="w-full"
            />
          </div>
        ) : (
          <div className="space-y-2">
            <Typography style="h6" className="text-primary-900">
              You are a viewing member
            </Typography>

            <Typography style="caption" className="text-primary-600">
              Please ask a Manager / Account owner for higher level permission
            </Typography>
          </div>
        )}
      </div>
    </Modal>
  )
}
