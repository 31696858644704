import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { isEmpty, isNil, omit, trim } from 'ramda'

import { RootState } from 'store'

interface State {
  [key: string]: SupplierState
}

interface SupplierState {
  clear?: boolean
  comments: string
  products: {
    [id: string]: {
      editing?: boolean
      quantity?: number
      sizeType?: number
    }
  }
  quantity: number
}

const initialState: State = {}

const pendingOrdersSlice = createSlice({
  initialState,
  name: 'pendingOrders',
  reducers: {
    cleanState(state) {
      const keys = Object.keys(state)

      for (const key in keys) {
        const id = keys[key]
        if (isNil(state[id].products)) {
          state[id].products = {}
        }
        if (isNil(state[id].comments)) {
          state[id].comments = ''
        }
        if (isNil(state[id].quantity)) {
          state[id].quantity = 0
        }
      }
    },
    clearState(state, action: PayloadAction<string>) {
      const id = action.payload
      state[id].clear = true
    },
    deleteOrder(state, action: PayloadAction<string>) {
      const id = action.payload
      return { ...omit([id], state) }
    },
    deleteState() {
      return initialState
    },
    updateComments(
      state,
      action: PayloadAction<{ id: string; comments: string }>,
    ) {
      const { id, comments } = action.payload

      if (!state[id]) return

      state[id].comments = trim(comments ?? '')
    },
    updateEditing(
      state,
      action: PayloadAction<{
        id: string
        productId: number
        editing: boolean
      }>,
    ) {
      const { productId, editing, id } = action.payload
      const product = state[id].products[productId]

      state[id].products[productId] = {
        ...product,
        editing,
        quantity: editing === false ? product.quantity ?? 0 : product.quantity,
      }
    },
    updateQuantity(
      state,
      action: PayloadAction<{
        id: string
        productId: number
        quantity: number
      }>,
    ) {
      const { id, productId, quantity } = action.payload
      const product = state[id]?.products[productId]

      if (!quantity) delete state[id].products[productId]
      else {
        state[id].products[productId] = {
          ...product,
          quantity,
        }
      }
      let newQuantity = 0
      Object.keys(state[id].products).forEach((key) => {
        if ((state[id].products[key]?.quantity ?? 0) > 0) {
          newQuantity += 1
        }
      })
      state[id].quantity = newQuantity
    },
    updateSizeType(
      state,
      action: PayloadAction<{
        id: string
        productId: number
        sizeType: number
      }>,
    ) {
      const { id, productId, sizeType } = action.payload
      const product = state[id].products[productId]

      state[id].products[productId] = {
        ...product,
        sizeType,
      }
    },
    updateState(
      state,
      action: PayloadAction<{ id: string; state: SupplierState }>,
    ) {
      const { id, state: supplierState } = action.payload

      state[id] = supplierState
    },
  },
})

export const {
  updateComments,
  updateQuantity,
  updateSizeType,
  updateState,
  updateEditing,
  cleanState,
  clearState,
  deleteState,
  deleteOrder,
} = pendingOrdersSlice.actions
export const reducer = pendingOrdersSlice.reducer

export const selectPendingOrders = (state: RootState) => state.pendingOrders

export const selectPendingOrder = (id: string) =>
  createSelector(selectPendingOrders, (pendingOrders) => pendingOrders[id])

export const selectAllPendingOrdersForKitchen = (id: string) =>
  createSelector(selectPendingOrders, (pendingOrders) => {
    const supplierIDs = Object.keys(pendingOrders).filter((key) => {
      const notEmpty =
        !isEmpty(trim(pendingOrders[key].comments)) ||
        pendingOrders[key].quantity > 0
      return notEmpty && key.includes(id)
    })
    return supplierIDs.reduce((acc, cur) => {
      acc[cur.replace(id, '').replace(':', '')] = pendingOrders[cur]
      return acc
    }, {} as any)
  })

export const selectComments = (id: string) =>
  createSelector(
    selectPendingOrder(id),
    (pendingOrder) => pendingOrder?.comments,
  )
export const selectQuantity = (id: string) =>
  createSelector(
    selectPendingOrder(id),
    (pendingOrder) =>
      (pendingOrder?.quantity ?? 0) + (pendingOrder?.comments ? 1 : 0),
  )
export const selectProducts = (id: string) =>
  createSelector(
    selectPendingOrder(id),
    (pendingOrder) => pendingOrder?.products,
  )
export const selectProduct = (id: string, productId: number) =>
  createSelector(
    selectPendingOrder(id),
    (pendingOrder) => pendingOrder?.products[productId],
  )
