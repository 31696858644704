import { useMutation } from '@apollo/client'
import { NewPasswordPanel } from '@getjelly/jelly-ui'
import { NewPasswordData } from '@getjelly/jelly-ui/dist/components/organisms/onboarding/NewPasswordPanel'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { setFirstPasswordMutation } from './graphql'

import { Mutation, MutationSetFirstPasswordArgs } from '../../api'
import { useMe } from '../../hooks/auth'
import { routes } from '../../routes/Paths'
import { cleanErrorMessage } from '../../utils'

export function SetPassword() {
  const navigate = useNavigate()

  const { refetch } = useMe()

  const [errors, setErrors] = useState<
    Partial<Record<keyof NewPasswordData, string>> | undefined
  >(undefined)

  const [setFirstPassword, { loading }] = useMutation<
    { user: Mutation['setFirstPassword'] },
    MutationSetFirstPasswordArgs
  >(setFirstPasswordMutation)

  async function newPassword(data: NewPasswordData) {
    try {
      await setFirstPassword({
        variables: {
          password: data.password,
        },
      })
    } catch (e) {
      return setErrors({
        confirmPassword: cleanErrorMessage((e as Error).message),
      })
    }

    await refetch()
    return navigate(routes.Settings + routes.Locations)
  }

  return (
    <NewPasswordPanel
      newPassword={newPassword}
      errors={errors}
      loading={loading}
    />
  )
}
