import { gql } from '@apollo/client'

export const userAccountFragment = gql`
  fragment AccountFields on User {
    _cursor
    id
    firstName
    lastName
    countryCode
    phoneNumberNational
    email
    emailVerificationCode
    imageUrl
  }
`
