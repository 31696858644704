import { color } from 'csx'
import type { ColorHelper } from 'csx'
import { createContext, useState, useContext, useMemo, ReactNode } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'

import { Global } from './Global'

export interface Theme {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl']
    values: Record<Theme['breakpoints']['keys'][number], number>
  }
  elevation: {
    0: string
    1: string
    2: string
    3: string
    4: string
    6: string
    8: string
    9: string
    12: string
    16: string
    24: string
  }
  global: {
    fontSize: number | string
    fontFamily: string
    transitionDuration: string
    transitionProperty: string
    transitionTimingFunction: string
  }
  palette: {
    common: {
      dark: ColorHelper
      light: ColorHelper
    }
    primary: {
      100: ColorHelper
      80: ColorHelper
      60: ColorHelper
      40: ColorHelper
      20: ColorHelper
      10: ColorHelper
      5: ColorHelper
    }
    secondary: {
      100: ColorHelper
      50: ColorHelper
      25: ColorHelper
    }
    tertiary: {
      100: ColorHelper
      50: ColorHelper
      25: ColorHelper
    }
    error: {
      100: ColorHelper
      50: ColorHelper
      25: ColorHelper
    }
    info: {
      100: ColorHelper
      50: ColorHelper
      25: ColorHelper
    }
    success: {
      100: ColorHelper
      50: ColorHelper
      25: ColorHelper
    }
    type: 'light' | 'dark'
    grey: {
      50: ColorHelper
      100: ColorHelper
      500: ColorHelper
    }
    text: {
      light: {
        primary: ColorHelper
        secondary: ColorHelper
        tertiary: ColorHelper
        disabled: ColorHelper
        hint: ColorHelper
      }
      dark: {
        primary: ColorHelper
        secondary: ColorHelper
        tertiary: ColorHelper
        disabled: ColorHelper
        hint: ColorHelper
      }
    }
    divider: {
      light: ColorHelper
      dark: ColorHelper
    }
    background: {
      light: ColorHelper
      dark: ColorHelper
    }
    action: {
      active: ColorHelper
      activeOpacity: number
      hover: ColorHelper
      hoverOpacity: number
      selected: ColorHelper
      selectedOpacity: number
      disabled: ColorHelper
      disabledBackground: ColorHelper
      disabledOpactiy: number
      focus: ColorHelper
      focusOpacity: number
    }
  }
  spacing: (value: number) => number
  typography: {
    h1: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    h2: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    h3: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    h4: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    h5: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    h6: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    body1: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    body2: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    subtitle1: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    subtitle2: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    button: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: 1.2
    }
    caption: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: number
    }
    label: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      margin: 0
      padding: 0
      lineHeight: number
    }
  }
}

interface TThemeProvider {
  theme: Theme
}

export const theme: Theme = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      lg: 1280,
      md: 960,
      sm: 600,
      xl: 1920,
      xs: 0,
    },
  },
  elevation: {
    0: 'unset',
    1: '0px 2px 3px 0px rgba(76, 89, 110, 0.1)',
    12: `0px 12px 17px ${color('#000').fade(0.14).toString()}`,
    16: `0px 16px 24px ${color('#000')
      .fade(0.14)
      .toRGBA()
      .toString()}, 0px 6px 30px ${color('#000')
      .fade(0.12)
      .toRGBA()
      .toString()}, 0px 8px 10px ${color('#000')
      .fade(0.2)
      .toRGBA()
      .toString()}`,
    2: `0px 2px 2px ${color('#000')
      .fade(0.06)
      .toRGBA()
      .toString()}, 0px 1px 5px ${color('#000')
      .fade(0.15)
      .toRGBA()
      .toString()}`,
    24: `0px 24px 38px ${color('#000')
      .fade(0.14)
      .toRGBA()
      .toString()}, 0px 9px 46px ${color('#000')
      .fade(0.12)
      .toRGBA()
      .toString()}, 0px 11px 15px ${color('#000')
      .fade(0.2)
      .toRGBA()
      .toString()}`,
    3: `0px 3px 3px ${color('#000')
      .fade(0.12)
      .toRGBA()
      .toString()}, 0px 1px 8px ${color('#000').fade(0.2).toString()}`,
    4: `0px 4px 5px ${color('#000')
      .fade(0.14)
      .toRGBA()
      .toString()}, 0px 1px 10px ${color('#000')
      .fade(0.12)
      .toRGBA()
      .toString()}, 0px 2px 5px ${color('#000').fade(0.2).toString()}`,
    6: `0px 1px 18px ${color('#000').fade(0.12).toString()}`,
    8: `0px 8px 10px ${color('#000')
      .fade(0.14)
      .toRGBA()
      .toString()}, 0px 3px 14px ${color('#000')
      .fade(0.12)
      .toRGBA()
      .toString()}, 0px 5px 5px ${color('#000').fade(0.2).toString()}`,
    9: `0px 9px 12px ${color('#000')
      .fade(0.14)
      .toRGBA()
      .toString()}, 0px 3px 16px ${color('#000')
      .fade(0.12)
      .toRGBA()
      .toString()}, 0px 5px 6px ${color('#000').fade(0.2).toString()}`,
  },
  global: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '1rem',
    transitionDuration: '0.3s',
    transitionProperty: 'auto',
    transitionTimingFunction: 'ease',
  },
  palette: {
    action: {
      active: color('#000'),
      activeOpacity: 1,
      disabled: color('#000'),
      disabledBackground: color('#000'),
      disabledOpactiy: 1,
      focus: color('#000'),
      focusOpacity: 1,
      hover: color('#000'),
      hoverOpacity: 1,
      selected: color('#000'),
      selectedOpacity: 1,
    },
    background: {
      dark: color('#000'),
      light: color('#FFF'),
    },
    common: {
      dark: color('#000'),
      light: color('#FFF'),
    },
    divider: {
      dark: color('#FFF'),
      light: color('#000'),
    },
    error: {
      100: color('#FF7859'),
      25: color('#FFDDD5'),
      50: color('#FFBCAD'),
    },
    grey: {
      100: color('#F0F0F0'),
      50: color('#F7F7F7'),
      500: color('rgba(121, 131, 146, 1)'),
    },
    info: {
      100: color('#2D9CDB'),
      25: color('#CFE7F6'),
      50: color('#9ECEEE'),
    },
    primary: {
      10: color('#E9EAED'),
      100: color('#1F304A'),
      20: color('#DBDEE2'),
      40: color('#A5ACB7'),
      5: color('#F4F5F6'),
      60: color('#798392'),
      80: color('#4C596E'),
    },
    secondary: {
      100: color('#48B7E3'),
      25: color('#D1EDF8'),
      50: color('#A3DBF1'),
    },
    success: {
      100: color('#A7C242'),
      25: color('#E9F0D0'),
      50: color('#D2E0A5'),
    },
    tertiary: {
      100: color('#F5D94F'),
      25: color('#FCF5D3'),
      50: color('#FAECA7'),
    },
    text: {
      dark: {
        disabled: color('#fff'),
        hint: color('#fff'),
        primary: color('#fff'),
        secondary: color('#fff'),
        tertiary: color('#fff'),
      },
      light: {
        disabled: color('#1F304A'),
        hint: color('#1F304A'),
        primary: color('#1F304A'),
        secondary: color('#1F304A'),
        tertiary: color('#1F304A'),
      },
    },
    type: 'light',
  },
  spacing: (value: number) => value * 8,
  typography: {
    body1: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    body2: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    button: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    caption: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    h1: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '5rem',
      fontWeight: 700,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    h2: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '3.75rem',
      fontWeight: 500,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    h3: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '2.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    h4: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '2.125rem',
      fontWeight: 400,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    h5: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '1.625rem',
      fontWeight: 400,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    h6: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    subtitle1: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
    subtitle2: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.2,
      margin: 0,
      padding: 0,
    },
  },
} as Theme

const themeProviderDefaults: TThemeProvider = {
  theme,
}

const ThemeContext = createContext(themeProviderDefaults)

interface Props {
  children?: ReactNode
}

export const ThemeProvider = ({ children }: Props) => {
  const [theme] = useState<Theme>(themeProviderDefaults.theme)

  const value = useMemo(() => theme, [theme])

  return (
    <SCThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ theme: value }}>
        <Global theme={value} />
        <>{children}</>
      </ThemeContext.Provider>
    </SCThemeProvider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)

  return context
}
