/* eslint-disable sort-keys-fix/sort-keys-fix */
import { gql } from '@apollo/client'

export const getKitchenByIdQuery = gql`
  query kitchen($where: KitchenWhereUniqueInput!) {
    kitchen(where: $where) {
      _cursor
      id
      activatedTime
      activatedPixelFiredTime
    }
  }
`

export const updateOneKitchenMutation = gql`
  mutation updateOneKitchen($data: UpdateOneKitchenInputObject!) {
    updateOneKitchen(data: $data) {
      _cursor
      id
    }
  }
`
