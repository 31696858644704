import { store } from 'store'
import { decode } from 'utils'

export function getToken() {
  const authorization = store.getState().auth.token

  if (!authorization) {
    return null
  }

  const result = decode(authorization)

  if (!result) {
    return null
  }

  return result
}
