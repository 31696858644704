import { gql } from '@apollo/client'

export const updateOneUserMutation = gql`
  mutation SetProfilePicture($data: UpdateOneUserInputObject!) {
    updateOneUser(data: $data) {
      id
    }
  }
`

export const signedProfilePictureQuery = gql`
  query SignedProfilePicture {
    signedProfilePictureUpload {
      unsignedURL
      signedURL
    }
  }
`
