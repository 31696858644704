import { useQuery } from '@apollo/client'
import {
  LiveMenuMarginsEmptyState,
  LiveMenuMarginsNotPaidState,
} from '@getjelly/jelly-ui'
import { CSSProperties, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { AutoSizer, List } from 'react-virtualized'

import {
  Query,
  QueryMenuListArgs,
  Menu,
  Status,
  SortOrder,
  MenuToDish,
} from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import { Typography } from 'components/newUi'
import { routes } from 'routes/Paths'
import { useTheme } from 'styles/newUi'

import { getMenus } from './graphql'

import { useIsPaidKitchen } from '../../hooks'
import { selectCounts } from '../../store/kitchen'

export const Menus = () => {
  const { selectedKitchen } = useKitchen()
  const location = useLocation()
  const listRef = useRef()
  const navigate = useNavigate()

  const {
    data: queryData,
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery<
    {
      menuList: Query['menuList']
    },
    QueryMenuListArgs
  >(getMenus, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      orderBy: [
        {
          name: SortOrder.Asc,
        },
      ],
      take: 500,
      where: {
        kitchenId: {
          equals: selectedKitchen?.id,
        },
        status: {
          equals: Status.Active,
        },
      },
    },
  })

  useEffect(() => {
    // if we change to this tab, refresh
    if (location.pathname === `${routes.Book}${routes.Menu}` && !loading) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, refetch])

  const data = useMemo(() => {
    return queryData?.menuList.nodes ?? []
  }, [queryData])

  const counts = useSelector(selectCounts())

  const isFeatureAvailable = useIsPaidKitchen()

  if (!isFeatureAvailable) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <LiveMenuMarginsNotPaidState
            ctaClicked={() =>
              window.open('https://getjelly.co.uk/upgrade', '_blank')
            }
          />
        </div>
      </div>
    )
  }

  if (!data.length && !counts.menuCount) {
    return (
      <div className="w-full h-full bg-primary-200 flex justify-center">
        <div className="max-w-[32rem] p-4">
          <LiveMenuMarginsEmptyState
            ctaClicked={() =>
              navigate(routes.Book + routes.Menu + routes.Create)
            }
            disabledText={
              counts.recipeCount || counts.dishCount
                ? ''
                : 'Can’t create a menu without dishes.'
            }
          />
        </div>
      </div>
    )
  }

  return (
    <div style={{ backgroundColor: '#F4F5F6', height: '100%' }}>
      <>
        {error}
        {!loading || queryData?.menuList ? (
          <AutoSizer>
            {({ height, width }) => {
              const rowCount = data?.length ?? 0
              return (
                <List
                  // @ts-ignore
                  ref={listRef}
                  containerStyle={{ overflow: 'hidden' }}
                  width={width}
                  height={height}
                  rowCount={rowCount}
                  rowHeight={90}
                  overscanRowCount={5}
                  style={{
                    overflowX: 'hidden',
                    paddingTop: rowCount === 0 ? undefined : 16,
                  }}
                  onRowsRendered={({ overscanStopIndex }) => {
                    if (
                      overscanStopIndex === rowCount - 1 &&
                      !loading &&
                      queryData?.menuList.pageInfo?.hasNextPage &&
                      fetchMore
                    ) {
                      fetchMore({
                        variables: {
                          cursor: queryData?.menuList.pageInfo.endCursor,
                        },
                      })
                      return
                    }
                  }}
                  rowRenderer={(props) => <Item {...props} data={data} />}
                />
              )
            }}
          </AutoSizer>
        ) : (
          <Loader />
        )}
      </>
    </div>
  )
}

export interface IItem {
  data: Menu[]
  index: number
  style: CSSProperties
}

export const calculateAverageGp = (dishList: MenuToDish[]) =>
  dishList
    .map((i) => i.dish.gpTarget)
    .reduce(function (sum: number, a: any, i: number, ar: string | any[]) {
      sum += a
      return i === ar.length - 1 ? (ar.length === 0 ? 0 : sum / ar.length) : sum
    }, 0)

const Item = (props: IItem) => {
  const { theme } = useTheme()
  const navigate = useNavigate()

  const { index, style, data } = props
  const menu: Menu = data[index]

  const averageGP = menu.dishAverageGp

  const positive = (averageGP ?? 0) >= menu.gpTarget

  return (
    <div
      onClick={() =>
        navigate(`${routes.Book}${routes.Menu}${routes.View}/${menu.id}`)
      }
      style={{
        ...style,
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: theme.elevation[1],
        cursor: 'pointer',
        height: 78,
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(2),
        width: `calc(100% - ${theme.spacing(4)}px)`,
      }}
    >
      <div style={{ display: 'grid', gridTemplateColumns: '10fr 1fr' }}>
        <Typography
          variant="h6"
          style={{
            color: 'rgba(72, 183, 227, 1)',
            overflow: 'hidden',
            paddingBottom: 4,
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          {menu.name.toLowerCase()}
        </Typography>

        <Typography
          variant="subtitle1"
          style={{
            color: !positive
              ? theme.palette.error[100].toHexString()
              : theme.palette.success[100].toHexString(),
            fontWeight: 500,
            position: 'relative',
            right: 0,
            textAlign: 'right',
          }}
        >
          {averageGP?.toFixed(2)}%
        </Typography>
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 90px' }}>
        <Typography
          variant="subtitle1"
          style={{ color: 'rgba(121, 131, 146, 1)', paddingBottom: 4 }}
        >
          {menu.dishCount} dishes
        </Typography>
        <Typography
          variant="body2"
          style={{ color: 'rgba(121, 131, 146, 1)', textAlign: 'right' }}
        >
          Average GP
        </Typography>
      </div>
    </div>
  )
}
