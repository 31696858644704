import { useMutation, useQuery } from '@apollo/client'
import { Button, InvoiceRuleModal } from '@getjelly/jelly-ui'
import { InvoiceRuleData } from '@getjelly/jelly-ui/dist/components/organisms/modals/InvoiceRuleModal'
import { useMemo, useState } from 'react'

import {
  Mutation,
  MutationCreateOneKitchenInvoiceRuleArgs,
  MutationUpdateOneKitchenInvoiceRuleArgs,
  Query,
  QueryKitchenInvoiceRuleArgs,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { errorToast } from '../../../../components/toasts'
import {
  createOneKitchenInvoiceRule,
  kitchenInvoiceRule,
  updateOneKitchenInvoiceRule,
} from '../graphql/invoiceRules'

export function RulesButton() {
  const { selectedKitchen } = useKitchen()
  const [showRulesModal, setShowRulesModal] = useState(false)

  const { data } = useQuery<
    { kitchenInvoiceRule: Query['kitchenInvoiceRule'] },
    QueryKitchenInvoiceRuleArgs
  >(kitchenInvoiceRule, {
    skip: !selectedKitchen,
    variables: {
      kitchenId: selectedKitchen?.id ?? 0,
    },
  })

  const [createInvoiceRule] = useMutation<
    { createOneKitchenInvoiceRule: Mutation['createOneKitchenInvoiceRule'] },
    MutationCreateOneKitchenInvoiceRuleArgs
  >(createOneKitchenInvoiceRule, {
    awaitRefetchQueries: true,
    refetchQueries: ['kitchenInvoiceRule'],
  })

  const [updateInvoiceRule] = useMutation<
    { updateOneKitchenInvoiceRule: Mutation['updateOneKitchenInvoiceRule'] },
    MutationUpdateOneKitchenInvoiceRuleArgs
  >(updateOneKitchenInvoiceRule, {
    awaitRefetchQueries: true,
    refetchQueries: ['kitchenInvoiceRule'],
  })

  const { approveAllInvoices, approvalThresholdValue, id } = useMemo(() => {
    if (!data?.kitchenInvoiceRule) {
      return {
        approvalThresholdValue: null,
        approveAllInvoices: false,
        id: null,
      }
    }

    return data.kitchenInvoiceRule
  }, [data])

  async function onSave(saveData: InvoiceRuleData) {
    try {
      if (id) {
        await updateInvoiceRule({
          variables: {
            data: {
              approvalThreshold: saveData.approvalThreshold,
              approveAllInvoices: saveData.approveAllInvoices,
              id,
            },
          },
        })
      } else {
        await createInvoiceRule({
          variables: {
            data: {
              approvalThreshold: saveData.approvalThreshold,
              approveAllInvoices: saveData.approveAllInvoices,
              kitchenId: selectedKitchen?.id ?? 0,
            },
          },
        })
      }

      setShowRulesModal(false)
    } catch {
      errorToast('Unable to save rules.')
    }
  }

  return (
    <>
      <InvoiceRuleModal
        open={showRulesModal}
        onClose={() => setShowRulesModal(false)}
        approvalThreshold={approvalThresholdValue ?? null}
        approveAllInvoices={approveAllInvoices}
        onSave={onSave}
      />

      <Button
        style="secondary"
        label="Rules"
        onClick={() => setShowRulesModal(true)}
      />
    </>
  )
}
