import { makeStyles } from 'styles'

export const useStyles = makeStyles((theme) => ({
  addButton: {
    borderRadius: 20,
    fontSize: 16,
    height: 40,
    marginRight: theme.spacing(2),
    padding: 10,
    width: 190,
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  addButtonMobile: {
    WebkitFontSmoothing: 'subpixel-antialiased',
    borderRadius: 20,
    fontSize: 16,
    height: 40,
    marginRight: theme.spacing(2),
    padding: 10,
    width: 'unset',
    // @ts-ignore
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  contentContainer: {
    maxWidth: 'calc(100% - 190px)',
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },
  createSearchContainer: {
    '@media print': {
      display: 'none',
    },
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  desktopContainer: {
    '@media print': {
      alignItems: 'flex-start',
    },
    display: 'flex',
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      display: 'unset',
    },
  },
  fallbackContainer: {
    width: '100%',
  },
  menusDesktop: {
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menusMobile: {
    // @ts-ignore
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    flex: 1,
    maxWidth: 'calc(100vw - 130px)',
  },
  menusTableContainer: {
    marginTop: theme.spacing(2),
  },
  modal: {
    maxWidth: 500,
  },
  noPrint: {
    '@media print': {
      display: 'none !important',
    },
    display: undefined,
  },
  onlyPrint: {
    '@media print': {
      display: 'block',
    },
    display: 'none',
  },
  rightButtons: {
    '& button': {
      minWidth: 100,
    },
    '@media print': {
      opacity: 0,
    },
  },
  rightButtons2: {
    '& button': {
      width: '45%',
    },
    '@media print': {
      opacity: 0,
    },
    display: 'flex',
    // @ts-ignore
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    justifyContent: 'space-between',
    width: '100%',
  },
  root: {
    '@media print': {
      margin: 0,
      marginTop: 0,
    },
    marginTop: theme.spacing(5),
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  search: {
    flex: 1,
    marginBottom: 'unset',
    marginTop: 'unset',
  },
  searchDesktop: {
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    flex: 1,
  },
  searchMobile: {
    // @ts-ignore
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  tileButton: {
    '&:first-child': {
      marginRight: theme.spacing(1),
    },
    borderRadius: 20,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: 'unset',
    padding: 5,
    textDecoration: 'none',
  },
  title: {
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  titleContainer: {
    // @ts-ignore
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '@media print': {
      display: 'none',
    },
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
  },
}))
