import { useMutation, useQuery } from '@apollo/client'
import {
  AddNoteModal,
  Button,
  DetailsSection,
  Notes,
  Pill,
  TextareaInput,
  TextInput,
  TodoEditModal,
  Typography,
} from '@getjelly/jelly-ui'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  createTodoNoteMutation,
  kitchenTodo,
  updateKitchenTodoMutation,
} from './graphql'

import {
  Mutation,
  MutationCreateOneTodoNoteArgs,
  MutationUpdateOneKitchenTodoArgs,
  Query,
  QueryKitchenTodoArgs,
  QueryUserListArgs,
  User,
} from '../../api'
import { useKitchen } from '../../app/contexts/SelectedKitchen'
import { Loader } from '../../components'
import { NewLayout } from '../../components/newUi'
import { capitaliseEachWord } from '../../utils'
import { getUserListQuery } from '../Settings/Team/graphql'

export function Todo() {
  const { selectedKitchen } = useKitchen()
  const { id } = useParams()

  const [showNoteModal, setShowNoteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  const { data: userData } = useQuery<
    { kitchenNode: Query['kitchenNode'] },
    QueryUserListArgs
  >(getUserListQuery, {
    variables: { cursor: selectedKitchen?._cursor },
  })

  const { data } = useQuery<
    { kitchenTodo: Query['kitchenTodo'] },
    QueryKitchenTodoArgs
  >(kitchenTodo, {
    variables: { id: parseInt(id) },
  })

  const [updateTodo, { loading: savingTodo }] = useMutation<
    {
      updateOneKitchenTodo: Mutation['updateOneKitchenTodo']
    },
    MutationUpdateOneKitchenTodoArgs
  >(updateKitchenTodoMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['kitchenTodo'],
  })

  const [createNote] = useMutation<
    {
      createOneTodoNote: Mutation['createOneTodoNote']
    },
    MutationCreateOneTodoNoteArgs
  >(createTodoNoteMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['kitchenTodo'],
  })

  const todo = useMemo(() => {
    if (!data?.kitchenTodo) {
      return null
    }

    return data.kitchenTodo
  }, [data])

  const assignees = useMemo(() => {
    if (!userData?.kitchenNode?.userList.nodes) {
      return []
    }

    return userData.kitchenNode.userList.nodes.map((n) => n.user)
  }, [userData])

  if (!todo) {
    return (
      <>
        <NewLayout
          title="Task details"
          bottomContent={
            <div className="px-2 py-4 flex space-x-2">
              <Button
                onClick={() => setShowEditModal(true)}
                style="secondary"
                label="Edit Task"
                className="w-full"
              />

              <Button
                onClick={async () => void 0}
                label="Completed"
                className="w-full"
              />
            </div>
          }
        />

        <Loader />
      </>
    )
  }

  return (
    <>
      <AddNoteModal
        title="Add Note"
        open={showNoteModal}
        onClose={() => setShowNoteModal(false)}
        saveNote={async ({ note }) => {
          await createNote({
            variables: {
              data: {
                content: note,
                todoId: todo.id,
              },
            },
          })
        }}
        notes={todo.notes}
        titleExtractor={(note) =>
          `${note.createdBy.firstName} ${note.createdBy.lastName} on ${format(
            note.createdAt,
            'E dd/MM/yyyy',
          )} at ${format(note.createdAt, 'hh:mm a')}`
        }
        bodyExtractor={(n) => n.content}
        typeExtractor={(n) => (n.type === 'system' ? 'system' : 'default')}
      />

      <TodoEditModal<User>
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        onSave={async (saveData) => {
          await updateTodo({
            variables: {
              data: {
                assigneeId: saveData.assignee?.id,
                completed: !!todo.completedAt,
                description: saveData.description,
                id: todo.id,
                title: capitaliseEachWord(saveData.title),
              },
            },
          })

          setShowEditModal(false)
        }}
        assignees={assignees}
        assigneeToId={(u) => u.id}
        assigneeToLabel={(u) => `${u.firstName} ${u.lastName}`}
        title={todo.title}
        description={todo.description || null}
        assignee={todo.assignee || null}
      />

      <NewLayout
        title="Task details"
        bottomContent={
          <div className="px-2 py-4 flex space-x-2">
            {!!todo.completedAt && (
              <Button
                onClick={async () => {
                  await updateTodo({
                    variables: {
                      data: {
                        assigneeId: todo.assigneeId || null,
                        completed: false,
                        description: todo.description || null,
                        id: todo.id,
                        title: todo.title,
                      },
                    },
                  })
                }}
                style="delete"
                label="Undo Completion"
                loading={savingTodo}
                className="w-full"
              />
            )}

            {!todo.completedAt && (
              <>
                <Button
                  onClick={() => setShowEditModal(true)}
                  style="secondary"
                  label="Edit Task"
                  className="w-full"
                />

                <Button
                  onClick={async () => {
                    await updateTodo({
                      variables: {
                        data: {
                          assigneeId: todo.assigneeId || null,
                          completed: true,
                          description: todo.description || null,
                          id: todo.id,
                          title: todo.title,
                        },
                      },
                    })
                  }}
                  label="Completed"
                  loading={savingTodo}
                  className="w-full"
                />
              </>
            )}
          </div>
        }
      />

      <DetailsSection>
        <div className="space-y-2.5">
          <div className="space-y-2">
            <Typography style="caption" className="text-primary-800">
              Title
            </Typography>

            <TextInput
              value={data?.kitchenTodo.title || ''}
              onChange={() => void 0}
              disabled
            />
          </div>

          <div className="space-y-2">
            <Typography style="caption" className="text-primary-800">
              Description
            </Typography>

            <TextareaInput
              value={data?.kitchenTodo.description || ''}
              onChange={() => void 0}
              disabled
            />
          </div>

          <div className="space-y-2">
            <Typography style="caption" className="text-primary-800">
              Assignee (Optional)
            </Typography>

            {data?.kitchenTodo.assignee && (
              <Pill
                variant="secondary"
                label={`${data.kitchenTodo.assignee.firstName} ${data.kitchenTodo.assignee.lastName}`}
              />
            )}
          </div>
        </div>
      </DetailsSection>

      <DetailsSection title="History">
        <div className="space-y-4">
          <Notes
            notes={todo.notes}
            titleExtractor={(note) =>
              `${note.createdBy.firstName} ${
                note.createdBy.lastName
              } on ${format(note.createdAt, 'E dd/MM/yyyy')} at ${format(
                note.createdAt,
                'hh:mm a',
              )}`
            }
            bodyExtractor={(n) => n.content}
            typeExtractor={(n) => (n.type === 'system' ? 'system' : 'default')}
          />

          {!todo.completedAt && (
            <div className="flex justify-center">
              <Button
                onClick={() => setShowNoteModal(true)}
                style="secondary"
                label="Add Note"
              />
            </div>
          )}
        </div>
      </DetailsSection>
    </>
  )
}
