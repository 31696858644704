import { ReactNode } from 'react'

type Props = {
  left: ReactNode
  right: ReactNode
}

export function CostingRow({ left, right }: Props) {
  return (
    <div className="flex justify-between items-center p-3 border-b border-gray-100">
      <div className="font-rubik text-sm">{left}</div>

      <div className="font-medium">{right}</div>
    </div>
  )
}
