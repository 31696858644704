import { useMutation, useQuery } from '@apollo/client'
import { Button, ToggleButton, Typography } from '@getjelly/jelly-ui'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Header } from './components/Header'
import {
  kitchenToPosAccountsQuery,
  posTypeQuery,
  updateKitchenToPosAccountMutation,
} from './graphql'

import {
  Mutation,
  MutationUpdateOneKitchenToPosAccountArgs,
  Query,
  QueryKitchenToPosAccountsArgs,
  QueryPosTypeArgs,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../../components'
import { NewLayout } from '../../../../components/newUi'
import { errorToast } from '../../../../components/toasts'
import { routes } from '../../../../routes/Paths'

export function EposNowFilters() {
  const navigate = useNavigate()
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()

  const [saving, setSaving] = useState(false)
  const [ignoreWet, setIgnoreWet] = useState(true)
  const [ignoreDry, setIgnoreDry] = useState(true)
  const [ignoreOther, setIgnoreOther] = useState(true)

  const { data: { posType } = {} } = useQuery<
    { posType: Query['posType'] },
    QueryPosTypeArgs
  >(posTypeQuery, {
    variables: {
      name: 'Epos Now',
    },
  })

  const { data: { kitchenToPosAccounts } = {} } = useQuery<
    { kitchenToPosAccounts: Query['kitchenToPosAccounts'] },
    QueryKitchenToPosAccountsArgs
  >(kitchenToPosAccountsQuery, {
    variables: {
      kitchenId: selectedKitchen?.id || 0,
    },
  })

  const [updateKtp] = useMutation<
    { updateOneKitchenToPosAccount: Mutation['updateOneKitchenToPosAccount'] },
    MutationUpdateOneKitchenToPosAccountArgs
  >(updateKitchenToPosAccountMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['posLocations'],
  })

  const kitchenToPosAccount = useMemo(() => {
    if (!posType || !kitchenToPosAccounts) return null

    return kitchenToPosAccounts.find(
      (ktp) => ktp.posAccount.posTypeId === posType.id,
    )
  }, [kitchenToPosAccounts, posType])

  return (
    <>
      <NewLayout
        onBack={() => navigate(routes.Settings + routes.Integrations)}
        subtitle="Integrations"
        title="Epos Now"
        bottomContent={
          <div className="px-2 py-4">
            <Button
              loading={saving}
              disabled={ignoreWet && ignoreDry && ignoreOther}
              onClick={async () => {
                if (saving) return

                if (!kitchenToPosAccount) {
                  errorToast('Could not find POS account.')
                  return
                }

                setSaving(true)

                try {
                  const { data } = await updateKtp({
                    variables: {
                      ignoreDry,
                      ignoreOther,
                      ignoreWet,
                      ktpId: kitchenToPosAccount.id,
                    },
                  })

                  if (!data?.updateOneKitchenToPosAccount) {
                    throw new Error('Something went wrong.')
                  }

                  await refetchSelectedKitchen()

                  navigate(
                    routes.Settings +
                      routes.Integrations +
                      routes.EposNow +
                      routes.Installed,
                  )
                } catch {
                  errorToast('Unable to update filters.')
                } finally {
                  setSaving(false)
                }
              }}
              label="Save"
              className="w-full"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6">
        <Header />

        <div className="space-y-4">
          <Typography style="subtitle1" className="text-primary-900">
            Select categories
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            Select only the relevant categories of items you want to connect to{' '}
            {selectedKitchen?.name}
          </Typography>
        </div>

        {kitchenToPosAccount === null ? (
          <Loader />
        ) : (
          <>
            {kitchenToPosAccount === undefined ? (
              <h1>Could not find POS account...</h1>
            ) : (
              <div>
                <div className="flex justify-between items-center w-full p-3 border-b border-primary-100">
                  <Typography
                    style="subtitle1"
                    className="text-primary-900 line-clamp-1 break-words"
                  >
                    Wet
                  </Typography>

                  <div className="shrink-0 flex items-center">
                    <ToggleButton
                      value={!ignoreWet}
                      size="small"
                      onChange={() => setIgnoreWet(!ignoreWet)}
                    />
                  </div>
                </div>

                <div className="flex justify-between items-center w-full p-3 border-b border-primary-100">
                  <Typography
                    style="subtitle1"
                    className="text-primary-900 line-clamp-1 break-words"
                  >
                    Dry
                  </Typography>

                  <div className="shrink-0 flex items-center">
                    <ToggleButton
                      value={!ignoreDry}
                      size="small"
                      onChange={() => setIgnoreDry(!ignoreDry)}
                    />
                  </div>
                </div>

                <div className="flex justify-between items-center w-full p-3 border-b border-primary-100">
                  <Typography
                    style="subtitle1"
                    className="text-primary-900 line-clamp-1 break-words"
                  >
                    Other
                  </Typography>

                  <div className="shrink-0 flex items-center">
                    <ToggleButton
                      value={!ignoreOther}
                      size="small"
                      onChange={() => setIgnoreOther(!ignoreOther)}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
