import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  DropdownInput,
  Modal,
  TextInput,
  Typography,
} from '@getjelly/jelly-ui'
import { useEffect, useMemo, useState } from 'react'

import {
  DishType,
  KitchenToPosProduct,
  Mutation,
  MutationCreateOneDishArgs,
  MutationLinkPosProductArgs,
  Query,
} from '../../../../api'
import { errorToast } from '../../../../components/toasts'
import {
  createOneDishMutation,
  dishTypesQuery,
  linkPosProductMutation,
} from '../graphql'

type Props = {
  open: boolean
  onClose: (created: boolean) => void
  kto: KitchenToPosProduct
}

export function CreateDishModal({ open, onClose, kto }: Props) {
  const [creating, setCreating] = useState(false)
  const [name, setName] = useState(kto.posProduct.name || '')
  const [dishType, setDishType] = useState<DishType | null>(null)

  const { data } = useQuery<{ dishTypes: Query['dishTypes'] }>(dishTypesQuery)

  const [createOneDish] = useMutation<
    { createOneDish: Mutation['createOneDish'] },
    MutationCreateOneDishArgs
  >(createOneDishMutation)

  const [linkPosProduct] = useMutation<
    { linkPosProduct: Mutation['linkPosProduct'] },
    MutationLinkPosProductArgs
  >(linkPosProductMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'kitchenToPosProductsCountQuery',
      'kitchenToPosProductsQuery',
    ],
  })

  const dishTypes = useMemo(() => {
    if (!data?.dishTypes) return []
    return data.dishTypes
  }, [data])

  useEffect(() => {
    setName(kto.posProduct.name || '')
    setDishType(null)
  }, [open, kto])

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <div className="space-y-8">
        <div className="space-y-6">
          <Typography style="h6" className="text-primary-900">
            Create new dish
          </Typography>

          <div className="space-y-3">
            <div className="space-y-2">
              <Typography style="caption" className="text-primary-900">
                Name
              </Typography>

              <TextInput value={name} onChange={setName} placeholder="Name" />
            </div>

            <div className="space-y-2">
              <Typography style="caption" className="text-primary-900">
                Type
              </Typography>

              <DropdownInput
                value={dishType}
                options={dishTypes}
                placeholder="Select..."
                optionToId={(d) => d.name}
                optionToLabel={(d) => d.name}
                onChange={setDishType}
              />
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <Button
            onClick={async () => {
              if (creating) return

              if (!name || !dishType) {
                errorToast('Dish name and dish type are required.')
                return
              }

              setCreating(true)

              try {
                const dishCreate = await createOneDish({
                  variables: {
                    data: {
                      name: name,
                      portion: 1,
                      type: dishType.name,
                    },
                    kitchenId: kto.kitchenId,
                  },
                })

                if (!dishCreate.data?.createOneDish) {
                  const [error] = dishCreate.errors || []
                  throw new Error(error.message || 'Could not create dish.')
                }

                const linked = await linkPosProduct({
                  variables: {
                    data: {
                      dishId: dishCreate.data.createOneDish.id,
                      ktoId: kto.id,
                    },
                  },
                })

                if (!linked.data?.linkPosProduct) {
                  throw new Error('Could not link new dish to POS product.')
                }

                onClose(true)
              } catch (e) {
                errorToast((e as Error).message || 'An unknown error occurred.')
              } finally {
                setCreating(false)
              }
            }}
            label="Create Dish And Link"
            className="w-full"
            loading={creating}
          />

          <Button
            style="secondary"
            onClick={() => onClose(false)}
            label="Cancel"
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  )
}
