import { gql } from '@apollo/client'

export * from './country'

export const meQuery = gql`
  query MeQuery {
    me {
      id
      firstName
      lastName
      email
      imageUrl
      phoneNumberNational
      hasPassword
      jobResponsibilityIds
      jobRoleIds
    }
  }
`
