import { gql } from '@apollo/client'

export const updateOnePendingInvoice = gql`
  mutation updateOnePendingInvoice(
    $data: PendingUpdateOneInvoiceInputObject!
    $id: Int!
  ) {
    updateOnePendingInvoice(data: $data, id: $id) {
      createdAt
      _cursor
      id
      images
      updatedAt
      status
      rejectionReason
      rejectionReasonNotes
    }
  }
`
export const deleteOnePendingInvoice = gql`
  mutation deleteOnePendingInvoice($id: Int!) {
    deleteOnePendingInvoice(id: $id) {
      _cursor
      id
    }
  }
`

export const signMultipleURLS = gql`
  query urls($urls: [SignedS3Input]) {
    MultipleSignedS3Urls(urls: $urls) {
      unsignedURL
      signedURL
    }
  }
`

export const createMultiplePendingInvoice = gql`
  mutation createMultiplePendingInvoice(
    $imageUrls: [String]!
    $kitchenId: Int!
  ) {
    createMultiplePendingInvoice(imageUrls: $imageUrls, kitchenId: $kitchenId) {
      createdAt
      _cursor
      id
      images
      kitchen {
        _cursor
        id
        name
      }
      updatedAt
      status
      rejectionReason
      rejectionReasonNotes
    }
  }
`

export const pendingInvoices = gql`
  query pendingInvoices(
    $orderBy: [PendingInvoiceOrderByInput!]
    $skip: Int
    $take: Int
    $where: PendingInvoiceWhereInput
  ) {
    pendingInvoices(
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      createdAt
      createdBy {
        _cursor
        id
        firstName
        lastName
      }
      _cursor
      id
      images
      signedImages(download: false)
      updatedAt
      updatedBy {
        _cursor
        id
        firstName
        lastName
      }
      status
      rejectionReason
      rejectionReasonNotes
      invoice {
        images
        signedImages(download: false)
        _cursor
        id
        supplier {
          _cursor
          id
          name
        }
        createdBy {
          _cursor
          id
          firstName
          lastName
        }
        updatedBy {
          _cursor
          id
          firstName
          lastName
        }
        invoiceDate
        invoiceId
        invoiceTotal
        vat
        createdAt
      }
    }
  }
`

export const pendingInvoiceQuery = gql`
  query pendingInvoice($where: PendingInvoiceWhereUniqueInput!) {
    pendingInvoice(where: $where) {
      createdAt
      createdBy {
        _cursor
        id
        firstName
        lastName
      }
      _cursor
      id
      images
      signedImages(download: false)
      updatedAt
      updatedBy {
        _cursor
        id
        firstName
        lastName
      }
      status
      rejectionReason
      rejectionReasonNotes
      invoice {
        _cursor
        id
        supplier {
          _cursor
          id
          name
        }
        createdBy {
          _cursor
          id
          firstName
          lastName
        }
        updatedBy {
          _cursor
          id
          firstName
          lastName
        }
        invoiceDate
        invoiceId
        invoiceTotal
        vat
      }
    }
  }
`

export const mergePendingInvoices = gql`
  mutation mergePendingInvoices($ids: [Int!]!) {
    mergePendingInvoices(ids: $ids) {
      createdAt
      updatedBy {
        _cursor
        id
        firstName
        lastName
      }
      _cursor
      id
      images
      kitchen {
        _cursor
        id
      }
      updatedAt
      updatedBy {
        _cursor
        id
        firstName
        lastName
      }
      status
      rejectionReason
      rejectionReasonNotes
    }
  }
`
