import { Box } from '@mui/material'
import styled from 'styled-components'

export const CoverBox = styled(Box)`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
