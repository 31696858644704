import { useQuery } from '@apollo/client'
import { ListSubheader } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { find } from 'ramda'
import { CSSProperties } from 'react'

import { Unit, UnitType } from 'api'
import { MenuItem, CircularProgress } from 'components'
import { getUnitsQuery } from 'components/SelectList/graphql'

import { SelectUnitDropdown } from './SelectUnitDropdown'

interface IProps {
  state: Unit | undefined
  label?: string
  handleChange: (unit: Unit | undefined) => void
  fullWidth?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  disabled?: boolean
  include?: ('all' | 'unit' | 'portion' | Unit)[]
  formColor: string
  required?: boolean
  size?: string
  margin?: Partial<{ marginLeft: number; marginRight: number }>
  additionalStyles?: Partial<CSSProperties>
  formHelperText?: string | undefined
  entity?: string | undefined
  error?: boolean
  placeholder?: JSX.Element | undefined
}

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: theme.palette.background.default,
    color: 'black',
    // backup, just in case
    // color: theme.palette.type === 'dark' ? theme.palette.common.white : 'black',
  },
}))

export const SelectUnit = ({ entity, include, state, ...rest }: IProps) => {
  const { data, loading } = useQuery<{
    units: Unit[]
  }>(getUnitsQuery, {
    fetchPolicy: 'no-cache',
  })

  const classes = useStyles()
  let units = data?.units

  if (loading) return <CircularProgress />

  if (entity === 'Recipe' && data?.units) {
    units = data.units.filter((unit) => {
      if (state?.id === unit.id || unit.type !== UnitType.Custom) {
        return true
      }
      return null
    })
  }

  const generateCustomUnits = () => {
    if (!include) return []

    const customUnits: Unit[] = []

    if (find((key) => key === 'all', include)) {
      customUnits.push(
        ...(data?.units?.filter(
          (unit) =>
            unit.type === UnitType.Custom &&
            unit.name !== 'unit' &&
            unit.name !== 'portion',
        ) ?? []),
      )
    }

    if (find((key) => key === 'portion', include)) {
      customUnits.push(
        ...(data?.units?.filter(
          (unit) => unit.type === UnitType.Custom && unit.name === 'portion',
        ) ?? []),
      )
    }
    if (find((key) => key === 'unit', include)) {
      customUnits.push(
        ...(data?.units?.filter(
          (unit) => unit.type === UnitType.Custom && unit.name === 'unit',
        ) ?? []),
      )
    }

    const customUnit = find((key) => typeof key === 'object', include)

    if (customUnit) {
      // @ts-ignore
      customUnits.push(customUnit)
    }

    return customUnits
  }

  const structuredData = {
    custom: generateCustomUnits(),
    volume: units?.filter((unit) => unit.type === UnitType.Volume),
    weight: units?.filter((unit) => unit.type === UnitType.Weight),
  }

  return (
    <SelectUnitDropdown data={data?.units} {...rest} state={state}>
      <ListSubheader>Weight</ListSubheader>
      {structuredData.weight?.map((unit) => (
        <MenuItem key={unit.id} value={unit.id} className={classes.row}>
          {unit.name}
        </MenuItem>
      ))}
      <ListSubheader>Volume</ListSubheader>
      {structuredData.volume?.map((unit) => (
        <MenuItem key={unit.id} value={unit.id} className={classes.row}>
          {unit.name}
        </MenuItem>
      ))}
      {structuredData?.custom && structuredData?.custom?.length > 0 && (
        <ListSubheader>Custom</ListSubheader>
      )}
      {structuredData.custom?.map((unit) => (
        <MenuItem key={unit.id} value={unit.id} className={classes.row}>
          {unit.name}
        </MenuItem>
      ))}
    </SelectUnitDropdown>
  )
}
