import { useMutation } from '@apollo/client'
import { RegisterPanel } from '@getjelly/jelly-ui'
import { RegisterData } from '@getjelly/jelly-ui/dist/components/organisms/onboarding/RegisterPanel'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Mutation, MutationSignupV2Args } from 'api'
import { routes } from 'routes/Paths'
import { cleanErrorMessage } from 'utils'

import { signUpMutation } from './mutation'

import { VITE_REACT_APP_DEMO_JOIN_CODE } from '../../app'
import {
  setFirstName,
  setLastName,
  setEmail,
  setCountryCode,
  setPhoneNumber,
  selectAuthFlow,
} from '../../store/authFlow'
import { setKitchen } from '../../store/kitchen'

export function SignUp() {
  const [errors, setErrors] = useState<
    Partial<Record<keyof RegisterData, string>> | undefined
  >(undefined)
  const [registerData, setRegisterData] = useState<RegisterData | undefined>(
    undefined,
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authFlow = useSelector(selectAuthFlow())

  useEffect(() => {
    if (!authFlow.invite) {
      navigate(`${routes.Join}/${VITE_REACT_APP_DEMO_JOIN_CODE}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [signup, { loading }] = useMutation<
    { signupV2: Mutation['signupV2'] },
    MutationSignupV2Args
  >(signUpMutation)

  async function submit({
    firstName,
    lastName,
    email,
    countryCode,
    phoneNumber,
  }: RegisterData) {
    if (loading) return

    if (!authFlow.invite) {
      navigate(`${routes.Join}/${VITE_REACT_APP_DEMO_JOIN_CODE}`)
      return
    }

    const { joinCode, inviteCode } = authFlow.invite

    try {
      const { data } = await signup({
        variables: {
          countryCode,
          email,
          firstName,
          inviteCode,
          joinCode,
          lastName,
          phoneNumber,
        },
      })

      if (!data) {
        return setErrors({ phoneNumber: 'An unknown error occurred.' })
      }

      dispatch(setKitchen(undefined))
      dispatch(setFirstName(firstName))
      dispatch(setLastName(lastName))
      dispatch(setEmail(email))
      dispatch(setCountryCode(countryCode))
      dispatch(setPhoneNumber(phoneNumber))
      navigate(routes.VerifyPhone)
    } catch (error) {
      if ((error as Error).message.includes('usr_email')) {
        return setErrors({ email: 'Email already in use.' })
      }

      if ((error as Error).message.includes('Unique constraint failed')) {
        return setErrors({ phoneNumber: 'Phone number already in use.' })
      }

      return setErrors({
        phoneNumber: cleanErrorMessage((error as Error).message),
      })
    }
  }

  function openLink(link: string) {
    return () => window.open(link, '_blank')?.focus()
  }

  return (
    <RegisterPanel
      countryCode={authFlow.countryCode}
      phoneNumber={authFlow.phoneNumber}
      onChange={setRegisterData}
      errors={errors}
      register={submit}
      loginLinkClicked={() => {
        dispatch(setCountryCode(registerData?.countryCode))
        dispatch(setPhoneNumber(registerData?.phoneNumber))
        navigate(routes.SignIn)
      }}
      tacClicked={openLink('https://www.getjelly.co.uk/terms')}
      privacyPolicyClicked={openLink('https://www.getjelly.co.uk/privacy')}
    />
  )
}
