import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Query, QueryIngredientNodeArgs } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { getIngredientQuery } from 'screens/Products/graphql'
import { selectSupplier } from 'store/supplier'

import { Form } from './Form'

export const UpdateIngredient = () => {
  const { selectedKitchen } = useKitchen()
  const { id } = useParams()
  const selectedSupplier = useSelector(selectSupplier())

  const { data } = useQuery<
    { ingredientNode: Query['ingredientNode'] },
    QueryIngredientNodeArgs
  >(getIngredientQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !id,
    variables: {
      cursor: id,
    },
  })

  if (!data?.ingredientNode) return null

  return (
    <Form
      kitchen={selectedKitchen}
      ingredient={data?.ingredientNode}
      supplier={selectedSupplier}
    />
  )
}
