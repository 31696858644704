import { gql } from '@apollo/client'

import { userAccountFragment } from 'api/graphql'

/* eslint-disable graphql/required-fields */
export const updateOneUserMutation = gql`
  mutation updateOneUser($data: UpdateOneUserInputObject!) {
    updateOneUser(data: $data) {
      ...AccountFields
    }
  }
  ${userAccountFragment}
`

export const TRACK_BOOK_A_CALL_MUTATION = gql`
  mutation TrackBookACall {
    trackBookACall
  }
`
