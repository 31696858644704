import { useMutation } from '@apollo/client'

import {
  Mutation,
  MutationUpdateOneDishArgs,
  MutationUpdateOneRecipeArgs,
} from 'api'
import {
  updateRecipeMutation,
  updateDishMutation,
} from 'screens/Create/graphql'

import { RecipeMutation, DishMutation } from './types'

export function useUpdateComponent(
  isDish: boolean,
): [RecipeMutation | DishMutation, boolean] {
  const [updateRecipe, { loading }] = useMutation<
    { updateOneRecipe: Mutation['updateOneRecipe'] },
    MutationUpdateOneRecipeArgs
  >(updateRecipeMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['getRecipeById'],
  })

  const [updateDish, { loading: dishLoading }] = useMutation<
    { updateOneDish: Mutation['updateOneDish'] },
    MutationUpdateOneDishArgs
  >(updateDishMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['getRecipeById'],
  })

  if (isDish) return [updateDish, !!dishLoading]

  return [updateRecipe, !!loading]
}
