import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { Mutation, MutationCreateOneMenuArgs } from 'api'
import { useKitchen } from 'app/contexts/SelectedKitchen'
import { Loader } from 'components'
import { Icon, NewTextField, NewLayout, NewButton } from 'components/newUi'
import { routes } from 'routes/Paths'
import { createMenuMutation } from 'screens/Menu/CreateMenu/graphql'
import { menuValidationSchema } from 'screens/Menu/schema'
import { useTheme } from 'styles/newUi'

import { successToast } from '../../../components/toasts'

export const CreateMenu = () => {
  const navigate = useNavigate()
  const { theme } = useTheme()

  const { selectedKitchen } = useKitchen()

  const [createMenu, { loading }] = useMutation<
    { createOneMenu: Mutation['createOneMenu'] },
    MutationCreateOneMenuArgs
  >(createMenuMutation, {
    onCompleted: (data) => {
      if (data?.createOneMenu) {
        successToast(`Successfully created ${data.createOneMenu.name} `)
        setTimeout(() => {
          navigate(
            `${routes.Book}${routes.Menu}${routes.View}/${data.createOneMenu.id}`,
            {
              replace: true,
            },
          )
        }, 0)
      }
    },
    refetchQueries: ['getMenuByIdQuery', 'dishList'],
  })

  const formik = useFormik({
    initialValues: {
      gpTarget: 0,
      name: '',
    },
    onSubmit: (values) => {
      createMenu({
        variables: {
          data: {
            gpTarget: values.gpTarget,
            name: values.name,
          },
          kitchenId: selectedKitchen!.id as number,
        },
      })
      return
    },
    validationSchema: menuValidationSchema,
  })

  return (
    <>
      <NewLayout
        subtitle="Menus"
        title="Create Menu"
        hideMenu
        bottomContent={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <NewButton
              // This is required to prevent a race condition between Formik validateOnBlur and submit
              onMouseDown={(event) => event.preventDefault()}
              text="Save Menu"
              type="submit"
              onClick={() => formik.handleSubmit()}
              rightAdornment={
                <Icon
                  iconName="chevronRight"
                  style={{ marginLeft: 4, marginRight: -4 }}
                />
              }
            />
          </div>
        }
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 12,
          marginLeft: 12,
          marginRight: 12,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <NewTextField
            label="Name"
            labelStyle={{
              color: theme.palette.primary[80].toHexString(),
              fontSize: 14,
              fontWeight: 700,
            }}
            style={{ flex: 0.9, paddingRight: 12 }}
            {...formik.getFieldProps('name')}
            onChange={(value) => formik.setFieldValue('name', value)}
            error={!!formik.touched.name && !!formik.errors.name}
          />
          <NewTextField
            label="GP Target"
            type="number"
            endAdornment="%"
            labelStyle={{
              color: theme.palette.primary[80].toHexString(),
              fontSize: 14,
              fontWeight: 700,
            }}
            inputProps={{
              inputMode: 'decimal',
              pattern: '[0-9]+\\.{0,1}[0-9]{0,2}',
              style: { textAlign: 'right', width: '90px' },
            }}
            style={{ flex: 0.4, textAlign: 'right' }}
            {...formik.getFieldProps('gpTarget')}
            onChange={(value) => formik.setFieldValue('gpTarget', value)}
            error={formik.touched.gpTarget && formik.errors.gpTarget}
          />
        </div>
        {/* <SearchWrapper menu={menu} refetch={refetch} /> */}
      </div>

      {loading && <Loader />}
    </>
  )
}
