import { makeStyles } from 'styles/newUi'

export const useStyles = () =>
  makeStyles((theme, isDesktop) => ({
    _root: {
      height: '100%',
      overflow: 'hidden',
    },
    actionBar: {
      display: isDesktop ? 'flex' : undefined,
      justifyContent: isDesktop ? 'space-between' : undefined,
      marginTop: isDesktop ? theme.spacing(4) : undefined,
      width: isDesktop ? '100%' : undefined,
    },
    addButton: {
      marginBottom: 0,
      marginTop: 0,
      maxHeight: 34,
      minWidth: 100,
      paddingBottom: 0,
      paddingTop: 0,
      right: 0,
    },
    addButtonDesktop: {
      alignItems: 'center',
      // color: theme.palette.primary[40].toHexString(),
      display: 'flex',
      fontFamily: 'Rubik',
      fontSize: 14,
      fontWeight: 500,
      height: '100%',
      justifyContent: 'flex-end',
      marginLeft: -70,
      paddingRight: 16,
      right: 0,
      textTransform: 'uppercase',
      top: 0,
      width: 70,
    },
    alert: {
      marginBottom: isDesktop ? theme.spacing(2) : undefined,
      marginTop: isDesktop ? theme.spacing(2) : undefined,
    },
    amount: {
      alignItems: 'center',
      columnGap: '10px',
      display: 'grid',
      gridTemplateColumns: '75px 0fr 1fr 35px',
      gridTemplateRows: '1fr',
    },
    arrow: {
      display: 'flex',
      justifySelf: 'flex-end',
    },
    button: {
      height: 44,
      minWidth: 90,
      width: 90,
    },
    detail: {
      alignItems: 'center',
      color: 'unset',
      display: 'flex',
      justifyContent: 'space-between',
      textDecoration: 'none',
      width: '100%',
    },
    divider: {
      backgroundColor: theme.palette.primary[20].toHexString(),
      display: isDesktop ? 'none' : undefined,
      height: 1,
      marginTop: theme.spacing(1),
      width: '100%',
    },
    flexCol: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      height: '100%',
    },
    info: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[100].toHexString()}`,
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
      width: '100%',
    },
    input: {
      marginRight: 10,
      minWidth: 90,
      padding: '0px !important',
      width: 90,
    },
    inputInner: {
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      backgroundColor: 'white',
      border: '2px solid #DBDEE2',
      textAlign: 'right',
    },
    itemRoot: {
      alignItems: 'left',
      // borderBottom: `1px solid ${theme.palette.grey[50].toHexString()}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: 12,
    },
    itemRootDesktop: {
      alignContent: 'center',
      alignItems: 'center',
      borderTop: `1px solid ${theme.palette.primary[20].toHexString()}`,
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyItems: 'center',
      maxHeight: 70,
      width: '100%',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100% - ${isDesktop ? '140px' : '65px'})`,
      overflow: 'scroll',
    },
    listContainer: {},
    listItem: {},
    listItemDesktop: {},
    mobileButtonWrap: {
      backgroundColor: theme.palette.secondary[50].toHexString(),
      display: 'grid',
      gap: '5px 5px',
      gridTemplateColumns: '1fr 1fr',
      height: 76,
      paddingBottom: 3,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 3,
      width: '100%',
    },
    productName: {
      textTransform: 'capitalize',
    },
    row: {
      borderBottom: `1px solid ${theme.palette.grey[50].toHexString()}`,
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
    },
    search: {
      width: isDesktop ? '60%' : undefined,
    },
    sectionClass: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    unitButton: {
      $nest: {
        '&:hover': {
          color: `${theme.palette.secondary[100].toHexString()} !important`,
        },
      },
      backgroundColor: 'transparent !important',
      border: 'none !important',
      color: `${theme.palette.secondary[100].toHexString()} !important`,
      height: 44,
      marginBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
      whiteSpace: 'nowrap',
    },
    unitButtonFooter: {
      backgroundColor: 'white !important',
      border: 'none !important',
      borderRadius: '40px',
      color: `${theme.palette.secondary[100].toHexString()} !important`,
      height: 44,
      marginBottom: 5,
      paddingLeft: '20px !important',
      paddingRight: '20px !important',
      whiteSpace: 'nowrap',
    },
    unitButtonRow: {
      alignItems: 'center',
      background: '#FFFFFF',
      border: '2px solid #48B7E3',
      borderRadius: '40px',
      cursor: 'pointer',
      display: 'flex',
      height: 25,
      justifyContent: 'center',
      marginBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
    unitButtonRowSelected: {
      alignItems: 'center',
      background: '#48B7E3',
      border: '2px solid #48B7E3',
      borderRadius: '40px',
      cursor: 'pointer',
      display: 'flex',
      height: 25,
      justifyContent: 'center',
      marginBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
    unitGroup: {
      columnGap: 8,
      display: 'flex',
      flexWrap: 'wrap',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    unitGroupHeader: {
      alignItems: 'center',
      backgroundColor: '#E9EAED',
      display: 'flex',
      flexDirection: 'row',
      height: 33,
      marginBottom: 16,
      paddingLeft: 12,
      width: '100%',
    },
    unitRow: {
      alignItems: 'center',
      display: 'flex',
      height: 60,
      paddingLeft: 24,
      width: '100vw',
    },
  }))
